import React, { Component, ReactNode } from "react";
import StaffingProjectPricing from "./StaffingProjectPricing.web";
import PlatformProjectPricing from "./PlatformProjectPricing.web";
import TechnologyProjectPricing from "./TechnologyProjectPricing.web";
import ConsultingProjectPricing from "./ConsultingProjectPricing.web";
import { IEstimatedPriceEndPoint, IEstimatedPriceRequestBody, IEstimatedPriceStates } from "./types";
import {styled, Typography} from "@material-ui/core";

type IProps = {
    navigation:any;
    title: string | undefined;
    countryList: string[];
    platformEstimatedPrice: string;
    consultingEstimatedPrice: string;
    staffingEstimatedPrice: string;
    technologyEstimatedPrice: string;
    oneTimeImplementationCost: string;
    onReset: (text: null) => void;
    handleGetEstimatedPrice: (body: IEstimatedPriceRequestBody, endPoint: IEstimatedPriceEndPoint) => void;
    handleResetEstimatedPrice: (value: IEstimatedPriceStates) => void;
};

type IState = {};

class PricingTypeContent extends Component<IProps, IState> {

    render(): ReactNode {
        const {
            navigation,
            title,
            countryList,
            platformEstimatedPrice,
            consultingEstimatedPrice,
            staffingEstimatedPrice,
            technologyEstimatedPrice,
            oneTimeImplementationCost,
            handleGetEstimatedPrice,
            handleResetEstimatedPrice
        } = this.props;

        const ActiveProject = () => {
            switch (title) {
                case "Platform Project":
                    return (
                        <PlatformProjectPricing
                            data-test-id="platform-project-container"
                            navigation={navigation}
                            title={title}
                            countryList={countryList}
                            estimatedPrice={platformEstimatedPrice}
                            handleGetEstimatedPrice={handleGetEstimatedPrice}
                            handleResetEstimatedPrice={handleResetEstimatedPrice}
                        />
                    );
                case "Consulting Project":
                    return (
                        <ConsultingProjectPricing
                            data-test-id="consulting-project-container"
                            navigation={navigation}
                            title={title}
                            countryList={countryList}
                            estimatedPrice={consultingEstimatedPrice}
                            handleGetEstimatedPrice={handleGetEstimatedPrice}
                            handleResetEstimatedPrice={handleResetEstimatedPrice}
                        />
                    );
                case "Staffing Project":
                    return (
                        <StaffingProjectPricing
                            data-test-id="staffing-project-container"
                            navigation={navigation}
                            title={title}
                            countryList={countryList}
                            estimatedPrice={staffingEstimatedPrice}
                            handleGetEstimatedPrice={handleGetEstimatedPrice}
                            handleResetEstimatedPrice={handleResetEstimatedPrice}
                        />
                    );
                case "Technology Project":
                    return (
                        <TechnologyProjectPricing
                            data-test-id="technology-project-container"
                            navigation={navigation}
                            title={title}
                            countryList={countryList}
                            estimatedPrice={technologyEstimatedPrice}
                            oneTimeImplementationCost={oneTimeImplementationCost}
                            handleGetEstimatedPrice={handleGetEstimatedPrice}
                            handleResetEstimatedPrice={handleResetEstimatedPrice}
                        />
                    );
                default:
                    return;
            }
        };

        return (
            <>
                {
                    title !== undefined ?
                    ActiveProject() : <StyledTypography>
                            Please select any project to calculate the pricing
                        </StyledTypography>
                }
            </>
        );
    }
}

const StyledTypography = styled(Typography)({
    "&" :{
        fontSize: "18px",
        color: "#475569",
        marginTop: "30px"
    },
    "@media(max-width: 960px)":{
        "&":{
            fontSize:"12px"
        }
    }
});

export default PricingTypeContent;
