import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { City, Country } from "country-state-city";
import createRequestMessage from "../../../framework/src/create-request-message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { leadSourceWords, stageValuesReverse } from "./constants";
const currencyToSymbolMap = require('currency-symbol-map/map');
const getSymbolFromCurrency = require('currency-symbol-map');
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    match:any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    cityList: string | any[];
    countryIsoCode: string;
    countryDeal: any;
    dealOwner: any;
    websiteDealName: any;
    expectedRevenue: any;
    inrCurrency: any;
    currencySymbol: any;
    notesDeal: any;
    serviceLine: any;
    leadSource: any;
    accountDealName: any;
    contactDealName: any;
    probabilityNumber: any;
    amountDeal: any;
    stageName: string;
    closingDate: any;
    dealFormName: any;
    type: any;
    cityDeal: any;
    closingDateError: any
    dealOpportunityNameError: any
    contactDealNameError: any
    accountDealNameError: any
    probabilityNumberError: any
    amountDealError: any
    stageError: any
    serviceLineError: any
    expectedRevenueError:any
    token: string;
    isSuccessModalOpen: boolean;
    isSaveAndNew: boolean;
    opportunityId:string;
    getData:any,
    editField:boolean
    editHandle:boolean
    dealOppID:any,
    listType:boolean,
    limitedEditField:boolean;
    countryArrayCommon:object[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateOpportiunitiesControllerBlock extends BlockComponent<
    Props,
    S,
    SS
> {
    [x: string]: any;
    // Customizable Area Start
    stageList: any[];
    serviceLineList: any[];
    leadSourceList: any[];
    dealTypeList: any[];
    currencyFormArry: any[];
    getUserinfoAPiID: any;
    createOpportunityApiCallId: string | null;
    getOppDeteailsByIdApiCallId: string | null;

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            // Customizable Area Start
            cityList: [],
            countryIsoCode: "",
            countryDeal: '',
            cityDeal: '',
            dealOwner: '',
            websiteDealName: '',
            expectedRevenue: '',
            inrCurrency: '',
            currencySymbol: '',
            serviceLine: '',
            notesDeal: '',
            type: '',
            leadSource: '',
            accountDealName: '',
            closingDate: '',
            contactDealName: '',
            probabilityNumber: '',
            amountDeal: '',
            stageName: '',
            dealFormName: '',
            closingDateError: '',
            dealOpportunityNameError: '',
            contactDealNameError: '',
            accountDealNameError: '',
            probabilityNumberError: '',
            amountDealError: '',
            expectedRevenueError:'',
            stageError: '',
            serviceLineError: '',
            token: '',
            isSuccessModalOpen: false,
            isSaveAndNew: false,
            opportunityId:"",
            getData:'',
            editField:false,
            editHandle:false,
            dealOppID:'',
            listType:false,
            limitedEditField:false,
            countryArrayCommon:[]
            // Customizable Area End
        };
        this.stageList = stageValuesReverse;
        this.serviceLineList = configJSON.serviceLineArray;
        this.leadSourceList = configJSON.leadSourceArray;
        this.dealTypeList = configJSON.dealType;
        this.currencyFormArry = Object.entries(currencyToSymbolMap).map(([key])=>{return key;})


        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.createOpportunityApiCallId = "";
       this.getOppDeteailsByIdApiCallId="";
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            if (apiRequestCallId) {
                if (this.createOpportunityApiCallId) {
                    this.createOpportunityApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            const dataI= responseJson.data.attributes.opportunity_id ? responseJson.data.attributes.opportunity_id : responseJson.data.attributes.deal_id;
                            this.setState({ isSuccessModalOpen: true, opportunityId:dataI })
                        },
                        onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                    });
                }
           
                if (this.getOppDeteailsByIdApiCallId) {
                    this.getOppDeteailsByIdApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({  getData:responseJson.data.attributes})
                            
                            this.preFieldData(responseJson.data.attributes)
                            this.setState({  getData:responseJson.data.attributes})
                        },
                        onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                    });
                }
            }
        }
        // Customizable Area End
    }

    componentDidUpdate(_prevProps: any, prevState: any) {
        if (
            prevState.countryIsoCode !== this.state.countryIsoCode
        ) {
            if (this.state.countryIsoCode !== null) {
                this.setState({
                  cityList: City.getCitiesOfCountry(this.state.countryIsoCode) ?? []
                });
            } else {
                this.setState( {
                    cityList: []
                });           
            }
        }

    }

    handleOppViewEdit = () => {
        const storedOpportunityData = sessionStorage.getItem('opportunityData');
        if (storedOpportunityData !== "" && storedOpportunityData !== null) {
            let parsedOpportunityData;
            try {
                parsedOpportunityData = JSON.parse(storedOpportunityData);
        
            } catch (error) {
                if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
                } else {
                throw error;
                }
            }

            const opportunityId = parsedOpportunityData?.opportunityId;
            this.setState({dealOppID:opportunityId})
            if (parsedOpportunityData?.pageMode === "Edit") {
                this.setState({ editField : false ,editHandle : true});
            }
            if(parsedOpportunityData?.pageMode === "View"){
                this.setState({ editField : true });
            }
            if (parsedOpportunityData?.status !== "rejected" && parsedOpportunityData?.type === "opportunity") {
                this.setState({ limitedEditField : false ,editHandle : true});
            }else {
                this.setState({ limitedEditField : true });
            }

            this.getOpportunitesById(opportunityId);
        }
    }

    componentDidMount = async () => {
        const price = localStorage.getItem("price");
        const url = window.location.href.split('/')[3];
        this.handleOppViewEdit();
        this.autoPopulateData()

        if(url === "CreateOpportunitiesFromPriceProject"){
            this.setState({amountDeal:price})
        }

        let tabValue = sessionStorage.getItem("DealRegistration");
        if (tabValue === "deal" && !this.state.listType) {
            this.setState({ listType: true });
        }

        this.handleGetAllCountryOpp()  
    };

    autoPopulateData =() => {
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data)
        } catch (error) {
            if (error instanceof SyntaxError) {
            console.error('Invalid JSON:', error.message);
            } else {
            throw error;
            }
        }
        if(!this.state.editField){
            const loginUserFirst_name = id?.account?.data?.attributes?.first_name
            const loginUserlast_name  = id?.account?.data?.attributes?.last_name
            const loginUserFullname = loginUserFirst_name + " " + loginUserlast_name 
            this.setState({
                dealOwner : loginUserFullname, 
            })
        }

        const loginUserCompany_name  = id?.account?.data?.attributes?.company_name

        this.setState({
            token: id?.token,
            accountDealName : loginUserCompany_name
        })
    }
    textCountryPropsDeal = (text: string) => {
        this.setState({
            countryDeal: text
        });
    }

    txtCityPropsDeal = (text: string) => {
        this.setState({
            cityDeal: text,
        });
    }

    dealOwnerInputName = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const firstNAResult = text.target.value.replace(/[^a-z ]/gi, '');
            this.setState({
                dealOwner: firstNAResult,
            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const firstNAResult = text.target.value.replace(/[^a-z ]/gi, '');
            this.setState({
                dealOwner: firstNAResult,
            })
        }
    }
    dealClosingDateInputFname = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const partnerName = text.target.value;
            const errorMsg = partnerName ? "" : "Date is required";

            this.setState({
                closingDate: text.target.value,
                closingDateError: errorMsg,
            });

        },
    };
    txtInputDealName = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = text.target.value;
            let result = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
            result = result.replace(/ +/g, ' ');

            this.setState({
                dealFormName: result,
                dealOpportunityNameError: result !== text.target.value ? 'Please enter alphabet Only' : '',

            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = text.target.value;
            let result = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
            result = result.replace(/ +/g, ' ');

            this.setState({
                dealFormName: result,
                dealOpportunityNameError: '',

            })
        }
    };


    txtInputcontactName = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = text.target.value;
            let result = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
            result = result.replace(/ +/g, ' ');

            this.setState({
                contactDealName: result,
                contactDealNameError: result !== text.target.value ? 'Please enter alphabet Only' : '',

            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = text.target.value;
            let result = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
            result = result.replace(/ +/g, ' ');

            this.setState({
                contactDealName: result,
                contactDealNameError: '',

            })
        }
    };

    accountDealNameText = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = text.target.value;
            let result = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
            result = result.replace(/ +/g, ' ');            
            
            this.setState({
                accountDealName: result,
                accountDealNameError: result !== text.target.value ? 'Please enter alphabet Only' : '',

            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = text.target.value;
            let result = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
            result = result.replace(/ +/g, ' ');

            this.setState({
                accountDealName: result,
                accountDealNameError: '',

            })
        }
    };

    handlestageText = {
        onChange: (event:any) => {
            const selectedMenuItem = event.currentTarget; 
            let selectedProbabilityKey = '' 
            if(selectedMenuItem){
                 selectedProbabilityKey = selectedMenuItem.getAttribute('data-probability-key');
            }
            if( selectedProbabilityKey === "0"){
                this.setState({
                    expectedRevenue: selectedProbabilityKey,
                },this.revenueCalculage);
            }
            this.setState({
                stageName: event.target.value,
                stageError: event.target.value ? '' : ' Stage I Required & Alphabetical Only',
                probabilityNumber:selectedProbabilityKey,
                probabilityNumberError: '',
            },this.revenueCalculage);
        },
    };

    handleserviceline = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                serviceLine: text.target.value,
                serviceLineError: text.target.value ? '' : ' Service Line Is Required & Alphabetical Only'
            });
        },
    };
    txtInpuprobabilityNumber = {
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const result = text.target.value.replace(/\D/g, "")
            this.setState({
                probabilityNumber: result,
                probabilityNumberError: '',

            }, this.revenueCalculage);
        }
    };

    handleleadSource = (text: string) =>  {
        this.setState({
            leadSource: text,
        });
    };
    handleleadAmount = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const result = text.target.value.replace(/\D/g, "")
            this.setState({
                amountDeal: result,
                amountDealError: result !== text.target.value ? 'Please enter number Only' : '',

            }, this.revenueCalculage);
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const result = text.target.value.replace(/\D/g, "")
            this.setState({
                amountDeal: result,
                amountDealError: '',

            }, this.revenueCalculage);
        }

    };

    textTypeInput = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                type: text.target.value,
            });
        },
    };

    textCurrencyInput = {
        onChange: (text: string) => {
            let value=''
            if (typeof text === 'string') {
                value = text.slice(0, 3);
              }
            this.setState({
                inrCurrency: value || "",
                currencySymbol: getSymbolFromCurrency(text) || ''
            });
        },
    };

    textExpectedRevenueInput = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                expectedRevenue: text.target.value,
            });
        },



    };

    revenueCalculage = () => {
        const { probabilityNumber, amountDeal } = this.state
        let expectedRevenueCal = (probabilityNumber  / 100 ) * amountDeal

        if(expectedRevenueCal)
        {
            this.setState({
                expectedRevenue: expectedRevenueCal,
                expectedRevenueError :"",
            });
        }
    }

    txtInputWebsiteName = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                websiteDealName: text.target.value,
            });
        },
    };
    txtInputNotesName = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                notesDeal: text.target.value,
            });
        },
    };

    createOpportuniesHandle = {
        onClick: () => {
            const {
                closingDate, dealFormName, contactDealName, accountDealName,
                stageName, serviceLine, amountDeal ,probabilityNumber} = this.state;
                const currentDate  = new Date().toISOString().split("T")[0];

            this.setState({
                closingDateError: !closingDate ? "Date Is Required" : "",
                dealOpportunityNameError: !dealFormName ? "Opportunity Name Is Required" : "",
                contactDealNameError: !contactDealName ? "Contact Name Is Required" : "",
                accountDealNameError: !accountDealName ? "Account Name Is Required" : "",
                stageError: !stageName ? "Stage Name Is Required" : "",
                serviceLineError: !serviceLine ? "Service Line Is Required" : "",
                amountDealError :!amountDeal ? "Amount Is Required" : "",
                probabilityNumberError :probabilityNumber === ''  ? "Probability Is Required" : "",

            });
            if ( closingDate && closingDate <= currentDate || closingDate == currentDate ) {
                this.setState({
                    closingDateError: "Closing date should be greater than the current date."})
                return    
            }

            const hasRequiredFields = [closingDate, dealFormName, contactDealName, accountDealName, stageName, serviceLine, amountDeal].every(Boolean);
            if (hasRequiredFields  && (probabilityNumber === 0 || probabilityNumber) ) {
                this.createOpportuniesFunc();
            }
        },
    };
    createOpportuniesFunc = () => {
        const {
            dealOwner,
            websiteDealName,
            expectedRevenue,
            inrCurrency,
            serviceLine,
            notesDeal,
            type,
            leadSource,
            accountDealName,
            closingDate,
            contactDealName,
            probabilityNumber,
            amountDeal,
            stageName,
            dealFormName,
            cityDeal,
            countryDeal,
            editHandle,
            currencySymbol
        } = this.state;

        let Data = {
            deal_owner: dealOwner,
            website: websiteDealName,
            expected_revenue: expectedRevenue,
            currency: inrCurrency,
            service_line: serviceLine.toLowerCase(),
            notes: notesDeal,
            bussiness_type: type,
            lead_source: leadSourceWords[leadSource as keyof typeof leadSourceWords],
            account_name: accountDealName,
            closing_date: closingDate,
            contact_name: contactDealName,
            probability: probabilityNumber,
            amount: amountDeal,
            stage: stageName,
            deal_name: dealFormName,
            city: cityDeal,
            country: countryDeal,
            currency_symbol: currencySymbol
        }

        const header = {
            token: this.state.token,
        };

        const body = {
            data: {
                attributes: Data
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        this.createOpportunityApiCallId = requestMessage.messageId;
        const endPoints= editHandle ? configJSON.getByIdDetailsOpportunityEndPoint + this.state.dealOppID :configJSON.createOpportunityEndPoint
        const methodPutPost= editHandle ? configJSON.putAPiMethodType:configJSON.postAPiMethodType

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: endPoints,
            method: methodPutPost,
            body: JSON.stringify(body)
        });
    }
    handleSaveAndNew =()=>{
        this.setState({ isSaveAndNew: true})
        this.createOpportuniesHandle.onClick();
    }
    handleInputFieldsClear = () => {
        this.setState({
            countryIsoCode: "",
            countryDeal: '',
            cityDeal: '',
            websiteDealName: '',
            expectedRevenue: '',
            inrCurrency: '',
            currencySymbol: '',
            serviceLine: '',
            notesDeal: '',
            type: '',
            leadSource: '',
            closingDate: '',
            contactDealName: '',
            probabilityNumber: '',
            amountDeal: '',
            stageName: '',
            dealFormName: '',
            closingDateError: '',
            dealOpportunityNameError: '',
            contactDealNameError: '',
            accountDealNameError: '',
            probabilityNumberError: '',
            amountDealError: '',
            stageError: '',
            serviceLineError: '',
        })
        this.autoPopulateData()
    }

    handleContinue = () => {
        if (this.state.isSaveAndNew) {
            this.setState({ isSaveAndNew: false, isSuccessModalOpen: false,editHandle:false})
            this.handleInputFieldsClear()
            sessionStorage.removeItem('opportunityData');
            this.props.navigation.navigate('CreateOpportunities');

            return
        }
        this.handleNavigateOpportunitiesListPage();
    }

    handleNavigateOpportunitiesListPage = () => {
        this.props.navigation.goBack();
        sessionStorage.removeItem('opportunityData');
    }

    handleCloseModal = () => {
        this.setState({ isSuccessModalOpen: false })
    }

    getOpportunitesById = (OppId:any) => {
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data)
        } catch (error) {
            if (error instanceof SyntaxError) {
            console.error('Invalid JSON:', error.message);
            } else {
            throw error;
            }
        }

        const header = {
            token: id?.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
        this.getOppDeteailsByIdApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.getByIdDetailsOpportunityEndPoint+OppId}`,
            method: configJSON.getApiMethodType
        });
    }
   
    textFormating = (value: string) => {
            return value
            .replace(/_/g, ' ')
            .split(' ')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
    preFieldData =(getDataObj:any) => {
        let serviceLine = '';

        if (getDataObj.service_line) {
            serviceLine = getDataObj.service_line.charAt(0).toUpperCase() + getDataObj.service_line.slice(1);
        }   
        
        const leadSource = getDataObj.lead_source ? this.textFormating(getDataObj.lead_source) : '';
        const bussiness_type = getDataObj.bussiness_type ? this.textFormating(getDataObj.bussiness_type) : '';
        const typeValueNumber = this.dealTypeList.find((item:any) => item.label === bussiness_type);
        let typeOpp=''
        
        if(typeValueNumber){
            typeOpp = typeValueNumber.value
        }
       
        
        this.setState({ 
            dealOwner:getDataObj.opportunity_owner ? getDataObj.opportunity_owner : getDataObj.deal_owner ,
            websiteDealName:getDataObj.website,
            expectedRevenue:getDataObj.expected_revenue,
            inrCurrency:getDataObj.currency,
            serviceLine:serviceLine,
            notesDeal:getDataObj.notes,
            type:typeOpp,
            leadSource:leadSource,
            accountDealName:getDataObj.account_name,
            closingDate:getDataObj.closing_date,
            contactDealName:getDataObj.contact_name,
            probabilityNumber:getDataObj.probability,
            amountDeal:getDataObj.amount,
            stageName:getDataObj.stage,
            dealFormName:getDataObj.opportunity_name ? getDataObj.opportunity_name :  getDataObj.deal_name,
            cityDeal:getDataObj.city,
            countryDeal:getDataObj.country,
            currencySymbol: getDataObj.currency_symbol || '$'
        })
        
    }

    handleGetAllCountryOpp = () => {
        const countryList = Country.getAllCountries();

        countryList.forEach((item) => {
            const countryName = item.name;
            const isoCode = item.isoCode;
            const allCity = City.getCitiesOfCountry(isoCode)
            if (countryName && allCity.length > 0) {
              this.setState((prevState) => ({
                  countryArrayCommon: [...prevState.countryArrayCommon, item],
              }));
            }
        });
      };

    // Customizable Area End
}