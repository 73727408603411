import React, { Component } from "react";
import {withStyles ,Box, Typography, styled, Modal, Button } from "@material-ui/core";
import { DownloadPdf,closeImg , verifyicon } from "../assets";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export const tableStyleCss = require("./tableCssStyle");

type IProps = {
  accordianText:string
  handleDownloadModalOpen:() => void;
  handleDownloadModalClose:() => void;
  isDownloadModalOpen: boolean;
  classes:any,
  toggleAccordion:() => void;
  isAccordionOpen :boolean,
};
type IState = {
  fileName:string,
  isAccordionOpen :boolean,
};

export class AccordionBlock extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      fileName: "sample.pdf",
      isAccordionOpen: props.isAccordionOpen,
    };
  }
  handleDownloadLink = () => {
    const link = document.createElement("a");
    link.href = "https://www.africau.edu/images/default/sample.pdf";
    link.target = "_blank"; 
    link.rel = "noopener noreferrer"; 
    link.download = "sample.pdf";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.props.handleDownloadModalClose();
    this.setState({isAccordionOpen: false})
  }


  render() {

    const {accordianText  ,handleDownloadModalOpen,handleDownloadModalClose ,
     classes }  = this.props;
    return (
      <AccordionContainer expanded={this.props.isAccordionOpen} onChange={this.props.toggleAccordion}>
        <AccordionSummary className="accordion-summary-box" expandIcon={<ExpandMoreIcon />}>
          <Typography>{accordianText} Registration Policy and FAQ’s</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="accordion-details-box" >
            <Box className="accordion-details-text">
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                consequat mi eget massa cursus, et scelerisque nisl fermentum. In
                auctor viverra interdum. Proin vitae risus sapien. Aliquam erat
                volutpat. Nulla facilisi. Suspendisse potenti. Aenean sit amet
                ligula at ipsum tincidunt tempus.
              </Typography>
            </Box>
            <Box className="accordion-details" >
              <Box>
                <Typography className="accordion-details-title">
                  Lorem ipsum dummy text infoline
                </Typography>
                <Typography className="accordion-details-list-text">
                  <img src={verifyicon} alt="" />
                  The standard Lorem Ipsum passage, used since the 1500s
                </Typography>
                <Typography className="accordion-details-list-text">
                  <img src={verifyicon} alt="" />
                  1914 translation by H. Rackham
                </Typography>
              </Box>
              <Box>
                <Typography className="accordion-details-title" >
                  Lorem ipsum dummy text infoline
                </Typography>
                <Typography className="accordion-details-list-text">
                  <img src={verifyicon} alt="" />
                  <Typography data-test-id="openDwnldModalLink" onClick={() => {handleDownloadModalOpen(); }} className="accordion-details-list-text-hyperlink" >
                  {accordianText}  Registration Policy</Typography>
                </Typography>
                <Typography className="accordion-details-list-text">
                  <img src={verifyicon} alt="" />
                  <Typography>
                    <a data-test-id="openDwnldModalLink" onClick={() => {handleDownloadModalOpen(); }} className="accordion-details-list-text-hyperlink">
                    {accordianText}  Registration FAQs
                    </a>
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Modal
            open={this.props.isDownloadModalOpen}
            onClose={handleDownloadModalClose}
            data-test-id='success-modal'
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <Box  className={`${classes.modaladminDownload}`} >
                  <Box className={`${classes.closeadminimg}`} onClick={handleDownloadModalClose}  data-test-id="handleDownloadModalCloseID" >
                      <img src={closeImg} alt="" style={{ width: "24px" }} />
                  </Box>
                  <Box className={`${classes.downloadImageBox}`}>
                      <img src={DownloadPdf} alt="" />
                  </Box>
                  <Box className={`${classes.centeradminTxt}`}>
                      <Box className={`${classes.centeradminTxt}`}>
                          <Typography className={`${classes.deleteTxtadmin}`}>Do you want to download this file? </Typography>
                          <Typography className={`${classes.descriptionTxtadmin}`}><Typography className={`${classes.nameText}`}>  Name &nbsp;: </Typography>   <Typography className={`${classes.opportunityIdText}`}>  {this.state.fileName}  </Typography></Typography>
                      </Box>
                  </Box>
                  <Box className={`${classes.countinueBtnContainer}`}>
                      <Button className={`${classes.downloadBtnadmin}`}  onClick={this.handleDownloadLink}    data-test-id="handleDownloadLinkID" >Download</Button>
                      <Button className={`${classes.cancelBtnadmin}`}  onClick={handleDownloadModalClose}   >Cancel</Button>
                  </Box>
              </Box>
          </Modal>

        </AccordionDetails>
      </AccordionContainer>
    )
  }
} 

export const AccordionContainer = styled(Accordion)({
  "&": {
    marginTop: "0",
    marginBottom: "0px",
    margin: "0px 0",
    bordeStyle: "solid",
    borderColor: "rgba(203, 213, 225, 1)",
    border: "1px",

    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px 0",
    },
  },
  "& .accordion-summary-box": {
    paddingLeft: "23px"
  },
  "& .accordion-details-box": {
    diplay: "flex",
    justifyContent: "column",
    paddingLeft: "10px"
  },
  "& .accordion-details-text": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    color: 'rgba(71, 85, 105, 1)'
  },
  "& .accordion-details": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    gap: "142px",
    marginTop: "38px",
    marginBottom: "17px",
    paddingLeft: "1px"
  },
  "& .accordion-details-title": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: 400,
    color: 'rgba(10, 5, 40, 1)'
  },
  "& .accordion-details-list-text": {
    display: 'flex',
    alignItems: 'center',
    gap: '11px',
    marginTop: '11px',
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
    color: 'rgba(71, 85, 105, 1)'
  },
  "& .accordion-details-list-text img": {
    width: "24px",
    height: "24px"
  },
  "& .accordion-details-list-text-hyperlink":{
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",

  },
   /* Section 1: Mobile and Tablet */
 "@media (max-width: 768px)": {
  "& .accordion-details-box": {
    width:"100%"
  },

  "& .accordion-details": {
    marginTop: "38px",
    marginBottom: "17px",
    paddingLeft: "1px",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    gap: "42px",
   
  },
 },
})

export default withStyles(tableStyleCss.stylecss)(AccordionBlock);
