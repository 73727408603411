// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"
import { chevronDown } from "./assets";
import { IRole } from "./types";

type IProps = {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    selectedRole:IRole[];
};
type IState = {};
// Customizable Area End

class RoleSelect extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { onClick, selectedRole } = this.props;
        const selctedRoleSorted = selectedRole.filter(item => item.checked).map(item => item.title).join(', ')
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <Box sx={webStyles.labelRow}>
                    <StyledTypographyLabel>
                        Role
                    </StyledTypographyLabel>
                    <StyledTypographyReq variant="subtitle1">*</StyledTypographyReq>
                </Box>
                <StyledSelectBox data-test-id="select-role-button" onClick={onClick}>
                    <StyledTypographySelectBox>
                        { selctedRoleSorted.length > 0 ? selctedRoleSorted : "Select Role"}
                    </StyledTypographySelectBox>
                    <img src={chevronDown} alt="" />
                </StyledSelectBox>
            </Box>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    labelRow: {
        display: "flex",
    }
};

const StyledTypographyLabel = styled(Typography)({
    "&": {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledTypographyReq = styled(Typography)({
    "&": {
        color: "red",
    }
});

const StyledTypographySelectBox = styled(Typography)({
    "&": {
        fontSize: "16px",
        color: "rgba(100, 116, 139, 1)"
    }
});

const StyledSelectBox = styled(Box)({
    "&": {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box"
    },
    "& img": {
        width: "10px"
    },
    "@media(max-width: 960px)": {
        "&": {
            height: "40px"
        }
    }
});

export default RoleSelect;
// Customizable Area End