import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Layout from "../../../components/src/Layout.web";
import PricingTypeBox from "./PricingTypeBox.web";
// Customizable Area End

import CfleadmanagementandopportunityController, {
  Props,
  configJSON,
} from "./CfleadmanagementandopportunityController.web";
import { PricingProjectBreadcrumb, PricingTypes } from "./constants";
import PricingTypeContent from "./PricingTypeContent.web";

export default class Cfleadmanagementandopportunity extends CfleadmanagementandopportunityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout
        breadcrumb={PricingProjectBreadcrumb}
        navigation={this.props.navigation}
        title="SELECT TYPE OF PROJECT"
      >
        <>
          <Grid container spacing={4}>
            {
              PricingTypes.map(pricingType => (
                <PricingTypeBox
                  key={pricingType}
                  data-test-id="pricing-type-box"
                  onClick={this.handlePricingTypeChange}
                  title={pricingType}
                  checked={this.state.activePricingType === pricingType}
                />
              ))
            }
          </Grid>
          <PricingTypeContent
            data-test-id="pricing-type-content"
            navigation={this.props.navigation}
            title={this.state.activePricingType?.split(" ").slice(0, 2).join(" ")}
            countryList={this.state.countryList}
            platformEstimatedPrice={this.state.platformEstimatedPrice}
            consultingEstimatedPrice={this.state.consultingEstimatedPrice}
            staffingEstimatedPrice={this.state.staffingEstimatedPrice}
            technologyEstimatedPrice={this.state.technologyEstimatedPrice}
            oneTimeImplementationCost={this.state.oneTimeImplementationCost}
            onReset={this.handlePricingTypeChange}
            handleGetEstimatedPrice={this.handleGetEstimatedPrice}
            handleResetEstimatedPrice={this.handleResetEstimatedPrice}
          />
        </>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
