import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"

type IProps = {
    description?:string;
    label?: string;
    image: string;
};
type IState = {};

class EmptyData extends Component<IProps, IState>{

    render() {
        const { description, label, image } = this.props;
        return (
            <StyledContainer>
                <img src={image} alt="" />
                <StyledLabel>
                    {label ?? "Nothing to show"} 
                </StyledLabel>
                {
                    description &&
                    <StyledDescription>
                        {description}
                    </StyledDescription>
                }
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)({
    "&": {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        gap:"4px",
        boxSizing:"border-box",
        marginTop:"50px",
        height:"60%"
    },
    "@media(max-width: 960px)": {
        "&": {
            margin:"30px 0"
        },
        "& img" :{
            width: "100px"
        }
    }
});

const StyledLabel = styled(Typography)({
    "&": {
        fontSize:"16px",
        lineHeight:"24px",
        color:"#0A0528"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize:"12px",
            lineHeight:"18px"
        }
    }
});

const StyledDescription = styled(Typography)({
    "&": {
        fontSize:"14px",
        lineHeight:"22px",
        color:"#475569"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize:"10px",
            lineHeight:"16px"
        }
    }
});

export default EmptyData;