// Customizable Area Start
import { Box } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
export const baseURLJSON = require("../../../framework/src/config.js");
import React from "react";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  emailError: boolean;
  passwordError: boolean;
  validationMsgShow: string;
  loginImage: string | HTMLImageElement;
  // Customizable Area End 
}

interface SS {
  // Customizable Area Start
  // Customizable Area End 
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  //Properties from config
  labelTitle: string = configJSON.labelTitle;
  labelHeader: string = configJSON.labelHeader;
  labelOr: string = configJSON.labelOr;
  rememberText: string = configJSON.remember;
  btnTxtLogin: string = configJSON.btnTxtLogin;
  dontHaveAccount = configJSON.dontHaveAccount;
  SignUplink: string = configJSON.SignUplink;
  passwordloginText: string = "password";
  passwordTypeloginText: string = "text";

  constructor(props: Props) {
    super(props);


    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      emailError: false,
      passwordError: false,
      loginImage: "",
      validationMsgShow: '',
    };
  }

  // Web Event Handling

  txtEmailAddressProps = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        email: text.target.value,
        emailError: false,
        validationMsgShow: "",
      });
    },
    onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key == 'Enter') {
        this.doEmailLogIn();
      }
    }
  }

  loginPassword = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        password: text.target.value,
        passwordError: configJSON.password_regex.test(text.target.value) ? false : true,
        validationMsgShow: "",
      });
    },
    onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key == 'Enter') {
        this.doEmailLogIn();
      }
    }
  }

  inputTxtEmailError = () => {
    return this.state.emailError ? `${this.props.classes.redInputMain}` : `${this.props.classes.inputMain}`
  }

  passwordHideShow = {
    onClick: () => {
      this.setState({
        enablePasswordField: !this.state.enablePasswordField,
      });
    }
  }
  txtRemeberMe = {
    onClick: () => {
      this.setState({ checkedRememberMe: !this.state.checkedRememberMe });

      let email = this.state.email;
      let password = this.state.password;
      if (this.state.checkedRememberMe) {
        document.cookie = "EmailAddress=" + ''
        document.cookie = "Password=" + ''
      } else {
        document.cookie = "EmailAddress=" + email
        document.cookie = "Password=" + password
      }

    }
  }

  validationEmailAndPsw = () => {
    return (
      <>
        {(this.state.emailError || this.state.validationMsgShow) && (
          <Box className={this.props.classes.validationBox}>
            <Box>{this.state.validationMsgShow || 'Please Enter Valid Email Address'}</Box>
          </Box>
        )}
      </>
    )
  }

  componentDidMount = async () => {
    this.getCookieData();
    this.getLoginBannerData();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (
      prevState.loginImage !== this.state.loginImage
  ) {console.log("loginImage",this.state.loginImage) }
  }

  getCookieData = () => {
    this.setState({
      email: document.cookie?.split(';')?.[1]?.split('=')?.[0] == ' EmailAddress' ? document.cookie?.split(';')?.[1]?.split('=')?.[1] : "",
      password: document.cookie?.split(';')?.[2]?.split('=')?.[0] == ' Password' ? document.cookie?.split(';')?.[2]?.split('=')?.[1] : "",
    }, () => {
      this.setState({
        checkedRememberMe: (this.state.email && this.state.password) ? true : false
      })
    })
  }

  forgotbuttonNavigate = {
    onClick: () => this.props.navigation.navigate('ForgotPassword'),
    onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key == 'Enter') {
        this.props.navigation.navigate('ForgotPassword')
      }
    }
  }

  signInBtn = {
    onClick: () => {
      this.setState({
        emailError: configJSON.email_regex.test(this.state.email) ? false : true,
      })
      if (configJSON.email_regex.test(this.state.email)) {
        this.doEmailLogIn();
      }
    }
  }

  checkValidationClass = () => {
    return this.state.validationMsgShow == "Couldn't find your account. Please check your email id" ? this.props.classes.redInputMain  : this.props.classes.inputEmailMain
  }

  cheackPasswordValidation = () => {
    return  this.state.validationMsgShow.toString().toLowerCase().includes('Password'.toLowerCase()) ? this.props.classes.redInputMain : `${this.props.classes.inputMain}`
  }

  passwordType = () => {
    return this.state.enablePasswordField ? this.passwordloginText : this.passwordTypeloginText
  }

  visibleinvisibleIcon = () => {
    return !this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />
  }

  NavigatetoSignUp = {
    onClick: () => {
      this.props.navigation.navigate('EmailAccountRegistration')
    }
  }

  doEmailLogIn = async () => {
    try {
      const response = await fetch(`${baseURLJSON.baseURL}/bx_block_login/logins`, {
        method: "POST",
        body: JSON.stringify({
          "data": {
            "attributes": {
              "email": this.state.email,
              "password": this.state.password,
            },
            "type": "email_account"
          }
        }),
        headers: {
          "Content-Type": "application/json"
        },
      })
      const responseData = await response.json();
      if (response.status == 200) {
        sessionStorage.setItem("token", JSON.stringify(responseData.meta));
        if(responseData?.meta?.account?.data?.attributes?.temporary_password){
          this.props.navigation.navigate("NewPassword");
        } else {
          this.props.navigation.navigate("Dashboard");
      }
    }
      else {
        this.setState({
          validationMsgShow: responseData.errors[0].failed_login || responseData.errors[0] == 'Account not found, or not activated' ? "Couldn't find your account. Please check your email id" : responseData.errors[0]
        })
      }
    } catch (error) {
      console.error(error);
    }
  };

  getLoginBannerData = async () => {
    try {
      const response = await fetch(`${baseURLJSON.baseURL}/bx_block_attachment/banners/show?image_type=login`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      })
      const responseAllData = await response.json();
      if (response.status == 200) {
        this.setState({
          loginImage: responseAllData.image_url
        })
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Customizable Area End 
}