import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2/dist';
import GraphLabel from "../../blocks/dashboard/src/GraphLabel.web";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

let formatter = Intl.NumberFormat('en', { notation: 'compact' });
const minimizeAmount = (value: string | number) => {
    let valueToNumber: number | string = parseInt(value.toString());
    return `$${formatter.format(valueToNumber)}`
}

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
        }
    },
    scales: {
        x: {
            ticks:{
                font:{
                  size: 12,
                  color: "#64748B",
                  family:"'Inter', sans-serif"
                }
              },
            grid: {
                drawBorder: false,
                display: false,
            },
            border: {
                display: false
            }
        },
        y: {
            stacked: true,
            ticks: {
                callback: (val:number | string) => {
                    return minimizeAmount(val)
                },
                font:{
                    size: 12,
                    color: "#64748B",
                    family:"'Inter', sans-serif"
                }
            },
            title: {
                display: true,
                text: 'Deal rating',
                color: '#64748B',
                font: {
                  family: 'Inter',
                  size: 12,
                  lineHeight: "18px"
                }
            }
        }
    },
};


const donutOptions = {
    cutoutPercentage: 30,
    cutout: 42,
    responsive: true,
    maintainAspectRatio: false,
}

const graphLabels = [
    {
        id:"1",
        label:"Active",
        additional: "1,236",
        color:"#2A0066"
    },
    {
        id:"2",
        label:"Won",
        additional: "800",
        color:"#059669"
    },
    {
        id:"3",
        label:"Lost",
        additional: "300",
        color:"#DC2626"
    },
    {
        id:"4",
        label:"Pending",
        additional: "136",
        color:"#F59E0B"
    }
]


type IProps = {
    opportunitiesCircle:any
};
type IState = {};

class OpportunitiesCard extends Component<IProps, IState>{

    render() {
        const {opportunitiesCircle} = this.props
        const valuesArray: number[] = Object.values(opportunitiesCircle);
        const data = {
            datasets: [
              {
                data: valuesArray,
                backgroundColor: [
                      '#2A0066',
                      '#059669',
                      '#DC2626',
                      '#F59E0B',
                ],
                borderWidth:1
              },
            ],
            
        };
        return (
            <StyledContainer>
                <Box className="header">
                    <Typography className="header-title">Opportunities</Typography>
                </Box>
                <Box className="graph-wrapper">
                    <Box className="graph-container">
                        <Doughnut options={donutOptions} data={data} />
                    </Box>
                    <Box className="graph-labels-container">
                        <Box className="graph-label-item">
                            <GraphLabel
                                key={"1"}
                                label={"Active"}
                                backgroundColor={"#2A0066"}
                            />
                            <Typography className="additional-text">{opportunitiesCircle?.active}</Typography>
                        </Box>
                            <Box className="graph-label-item">
                            <GraphLabel
                                key={"2"}
                                label={"Won"}
                                backgroundColor={"#059669"}
                            />
                            <Typography className="additional-text">{opportunitiesCircle?.won}</Typography>
                            </Box>
                            <Box className="graph-label-item">
                            <GraphLabel
                                key={"3"}
                                label={"Lost"}
                                backgroundColor={"#DC2626"}
                            />
                            <Typography className="additional-text">{opportunitiesCircle?.lost}</Typography>
                            </Box>
                            <Box className="graph-label-item">
                            <GraphLabel
                                key={"4"}
                                label={"Pending"}
                                backgroundColor={"#F59E0B"}
                            />
                            <Typography className="additional-text">{opportunitiesCircle?.pending}</Typography>
                        </Box>
                    </Box> 
                </Box>
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)({
    "&":{
        padding:"20px 16px",
        borderRadius:"8px",
        border:"1px solid #E2E8F0",
        boxShadow:"0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",
        display:"flex",
        flexDirection:"column",
        gap:"20px",
        boxSizing:"border-box",
        minHeight:"230px",
        "& .graph-wrapper": {
            display:"flex",
            justifyContent:"flex-end",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            "& .graph-container":{
                "& canvas":{
                    maxWidth:"170px"
                }
            }
        },
        "& .header-title": {
            fontSize: "20px",
            lineHeight: "28px",
            color: "#000000"
          },
        "& .graph-labels-container":{
            display:"flex",
            flexDirection:"column",
            margin:"0 auto",
            gap:"20px",
            "& .graph-label-item":{
                display:"flex",
                alignItems:"center",
                gap:"8px",
                "& .additional-text":{
                    fontSize:"12px",
                    fontWeight:700,
                    lineHeight:"16px",
                    color:"#0F172A"
                }
            }
        }
    },
    "@media(max-width: 576px)": {
        "& .graph-labels-container":{
            gap:"10px!important"
        },
        "& .graph-wrapper":{
            flexDirection:"column",
            gap:"20px",
            "& .graph-container":{
                "& canvas":{
                    minHeight:"130px"
                }
            }
        },
        "& .header-title": {
            fontSize: "16px!important"
        },

    },
});

export default OpportunitiesCard;