//@ts-nocheck
import { Box, Typography, withStyles } from "@material-ui/core";
import React from "react";
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import { backArrow, envelop } from "./assets";

export class EmailVerification extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
        <Box className={classes.mainotpBoxFlex}>
          <Box className={classes.mainotpBox}>
            <Box>
              <img src={envelop} alt="envelop" />
            </Box>
            <Box className={classes.textotpMainBox}>
              <Typography className={classes.OtpheadingText}>{this.state.checkEmailText}</Typography>
              <Typography className={classes.otpDescriptionText}>{this.state.cheackEmailDescText}</Typography>
            </Box>

            <Box className={classes.dontotpAccountText}>
              <Typography className={classes.otpAccountText} >{this.state.dontreciveMailtext}{" "}</Typography>
              <Typography className={classes.resenotpLinkText} {...this.resendPassword} >{this.state.clikToResendtext}</Typography>
            </Box>

            <Box data-test-id="btnEmailLogIn" className={classes.backtologinComBox} onClick={() => this.props.navigation.navigate("EmailAccountLoginBlock")}>
              <img src={backArrow} alt="backArrow" />
              <Typography className={classes.backtologin}>{this.state.backToLogin}</Typography>
            </Box>
          </Box>
        </Box>
      // Customizable Area End
    )
  }
}


// Customizable Area Start
export const EmailVerificationStyles = (theme: any) => ({
  mainotpBox: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: "22px",
    width: "460px",
    height: "388px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  OtpheadingText: {
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    fontFamily: "'Inter', sans-serif",
  },
  textotpMainBox: {
    marginTop: "24px",
  },
  mainotpBoxFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E5E5E5",
    height: "100vh",
  },
  otpDescriptionText: {
    color: "#667085",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    textAlign: "center",
    width: "338px",
    marginTop: "12px",
    fontFamily: "'Inter', sans-serif",
  },
  backtologin: {
    color: "#2E17B5",
    fontWeight: "400",
    fontSize: "14px",
    paddingLeft: "5px",
    fontFamily: "'Inter', sans-serif",
  },
  backtologinComBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "24px",
  },
  dontotpAccountText: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "32px",
    color: "#475569",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
  },
  otpAccountText: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#475569",
    textAlign: "center",
    fontFamily: "'Inter', sans-serif",
  },
  resenotpLinkText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    color: "#2E17B5",
    cursor: "pointer",
    paddingLeft: "5px",
    fontFamily: "'Inter', sans-serif",
  },
})
//@ts-ignore
export default withStyles(EmailVerificationStyles)(EmailVerification);

// Customizable Area End
