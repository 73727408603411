// Customizable Area Start
// @ts-nocheck
import { Grid, Box, Button, Input, InputLabel, TextField, Typography, withStyles, styled, Paper } from "@material-ui/core";
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import React from "react";
import { SuccessToast } from "../../../components/src/SuccessTost.web";
import { Autocomplete } from "@material-ui/lab";
import { Country, City } from 'country-state-city';
import Layout from "../../../components/src/Layout.web";
export const configJSON = require("./config");

// Customizable Area End

export class UserOnBoard extends UserProfileBasicController {
    constructor(props: Props) {
        super(props)
    }

    getUserCityLabel = (option: any) => {
        return option.name  ?? this.state.cityNameA
    };

    handleUserCityLabel = (_, e) => {
        this.textInputadminCity((e && e.name) || "")
    }

    handleOptions = (option, value) => { return option.name === value.name }

    handleOptionsDial = (option, value) => { return option === value }

    renderCityInput = (params) => (
        <TextField className={this.props.classes.searchInputIunder}
          data-test-id="txtInputCityName"
          sx={{ fontSize: "12px" }}
          {...params}
          placeholder="City"
          value={this.state.cityNameA}
        />
      )

    // Country

    getUserCountryLabel = (option: any) => {
        return option.name
    };

    handleUserCountryLabel = (_, e) => {
        this.textInputadminCountry((e && e.name) || "")
        this.setState({
            countryIsoCode: e && e.isoCode,
            cityNameA:""
        })
    }

    renderUserCountryInput = (params) => (
        <TextField className={this.props.classes.searchInputIunder}
          data-test-id="inputtxtCountryName"
          sx={{ fontSize: "12px" }}
          {...params}
          placeholder="Country"
          value={this.state.countryA}
        />
      )

    renderDialInput = (params) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id="txtInputCountryCode"
            sx={{ fontSize: "12px" }}
            {...params}
            value={this.state.countryDialCode}
        />
    )

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const pageData  = configJSON.UserOnBoard
        // Customizable Area End
        return (
            // Customizable Area Start
            
                    <Layout
                        navigation={this.props.navigation}
                        breadcrumb={pageData}
                        title="CREATE USER ACCOUNT"
                        style={{height:'93.033%'}}
                        >         
                            <Grid container className={`${classes.adminFieldMainBox}`}>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            First Name
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtFirstName" placeholder={"First Name"} fullWidth={true}
                                           disableUnderline
                                           className={`${classes.admininputTxtMain}`}
                                           {...this.txtInputadminFname}
                                           value={this.state.firstNameA}
                                           inputProps={{
                                               maxLength: 256,
                                           }}
                                    />
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.firstName}</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            Last Name
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtLastName" placeholder={"Last name"} fullWidth={true}
                                           {...this.txtInputadminLname}
                                           inputProps={{
                                               maxLength: 256,
                                           }}
                                           value={this.state.lastNameA}
                                           disableUnderline
                                           className={`${classes.admininputTxtMain}`}

                                    />
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.lastName}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container className={`${classes.adminFieldMainBox}`}>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            Company Name
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtCompanyName" placeholder={"company"} fullWidth={true}
                                           {...this.txtInputadminComapnyName}
                                           value={this.state.companyA}
                                           disableUnderline
                                           readOnly
                                           inputProps={{
                                               maxLength: 256,
                                           }}
                                           className={`${classes.admininputTxtMain}`}
                                    />
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.company}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            Job Title
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtJobTitle" placeholder={"Job Title"} fullWidth={true}
                                           {...this.txtInputadminjobTitle}
                                           value={this.state.jobTitleA}
                                           inputProps={{
                                               maxLength: 256,
                                           }}
                                           disableUnderline
                                           className={`${classes.admininputTxtMain}`}

                                    />
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.jobTitle}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container className={`${classes.adminFieldMainBox}`}>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            Work Email
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtWorkEmail" placeholder={"Work Email"} fullWidth={true}
                                           disableUnderline
                                           className={`${classes.admininputTxtMain}`}
                                           {...this.txtInputadminworkEmail}
                                           inputProps={{
                                               maxLength: 256,
                                           }}
                                           value={this.state.workEmailA}

                                    />
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.email}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            Mobile Number
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Box className={`${classes.mobileInputWrapper}`}>
                                        {/* <FormControl className={classes.countryCodeMainBox}>
                                            <Select
                                                value={this.state.countryDialCode}
                                                {...this.textCountryDialProps}
                                                disableUnderline
                                                className={`${classes.inputMainMobileSelect}`}
                                                displayEmpty
                                                data-test-id="txtInputCountryCode"
                                                renderValue={this.renderCountryCode}
                                                MenuProps={{
                                                    classes:{paper:classes.root},
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                      }
                                                }}
                                            >
                                                {this.CountryDialCodeList.map((data) => (
                                                    <MenuItem key={data} value={data}>{`${data}`}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> */}
                                        <Autocomplete
                                            key={0}
                                            disableUnderline
                                            className={`dial-autocomplete ${classes.countryonBoardinputTxtMain}`}
                                            data-test-id="txtInputCountryCode"
                                            sx={{ fontSize: "12px" }}
                                            limitTags={1}
                                            options={this.CountryDialCodeList}
                                            getOptionLabel={(option)=>option}
                                            onChange={(_,value)=>this.textCountryDialProps.onChange(value)}
                                            renderInput={this.renderDialInput}
                                            getOptionSelected={this.handleOptionsDial}
                                            PaperComponent={(props) => {
                                                return (
                                                    <PaperStyled
                                                      {...props}
                                                    />
                                                  )
                                            }}
                                            value={this.state.countryDialCode}
                                        />
                                        <Input data-test-id="txtMobileNo" placeholder={"12345 67890"} fullWidth={true}
                                               {...this.txtInputadminMobileNo}
                                               value={this.state.mobileNumberA}
                                               disableUnderline
                                               className={`${classes.inputMobile}`}
                                               inputProps={{
                                                   maxLength: this.state.countryDialCode=='+91'?10:15,
                                               }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.phoneNumber}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container className={`${classes.adminFieldMainBox}`}>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            Country
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Autocomplete
                                        key={this.state.clearInput ? 0 : 1}
                                        disableUnderline
                                        className={`${classes.countryonBoardinputTxtMain}`}
                                        sx={{ fontSize: "12px" }}
                                        data-test-id="txtCountryName"
                                        options={this.state.countryList}
                                        limitTags={1}
                                        getOptionLabel={this.getUserCountryLabel}
                                        onChange={this.handleUserCountryLabel}
                                        getOptionSelected={this.handleOptions}
                                        renderInput={this.renderUserCountryInput}
                                        PaperComponent={(props) => {
                                            return (
                                                <PaperStyled
                                                  {...props}
                                                />
                                              )
                                        }}
                                    />
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.country}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} className={`${classes.adminFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                            City
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                    </Box>
                                    <Autocomplete
                                        key={this.state.clearInput ? 0 : 1}
                                        disableUnderline
                                        limitTags={1}
                                        className={`${classes.countryonBoardinputTxtMain}`}
                                        sx={{ fontSize: "12px" }}
                                        data-test-id="txtCityName"
                                        options={this.state.cityList}
                                        getOptionLabel={this.getUserCityLabel}
                                        onChange={this.handleUserCityLabel}
                                        renderInput={this.renderCityInput}
                                        getOptionSelected={this.handleOptions}
                                        PaperComponent={(props) => {
                                            return (
                                                <PaperStyled
                                                  {...props}
                                                />
                                              )
                                        }}
                                        value={this.state.cityNameA}
                                    />
                                    <Box>
                                        <Typography className={`${classes.errormassagetext}`}>{this.state.errors.city}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        
                        <Box className={`${classes.buttonFlex}`}>
                            <Box>
                                <Button id="createNewUserClick" className={`${classes.saveuserbtn}`} {...this.createnewUser}>Save</Button>
                            </Box>
                            <Box>
                                <Button className={`${classes.saveandnew}`} {...this.createnewUser}>Save and New</Button>
                            </Box>
                            <Box>
                                <Button className={`${classes.cancelbtn}`} {...this.cancelFormBtn}>Cancel</Button>
                            </Box>
                        </Box>
                   
                        {this.state.openuserToast && <SuccessToast text={'New user has been successfully added.'} />}
                    </Layout>
            // Customizable Area End
        )
    }

    componentDidMount = async () => {
        const companyDataString: string | null = sessionStorage.getItem('token');
        if (companyDataString !== null) {
            let companyData;
            try {
                companyData = JSON.parse(companyDataString);
                this.setState({
                    companyA: companyData.account.data.attributes?.company_name,
                });
            } catch (error) {
                if (error instanceof SyntaxError) {
                    console.error('Invalid JSON:', error.message);
                } else {
                    throw error;
                }
            }
        }
        this.getCompanyListData();
        this.handleGetAllCountryUserProfile();
    };
}

// Customizable Area Start

export const UserOnBoardStyle = (theme: any) => ({
    root:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"5px"
        },
        "& ul li":{
            borderRadius:"8px",
            fontSize: "14px",
            padding:"9px 20px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)"
        },
    },
    errormassagetext: {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: '400',
        marginTop: '5px',
    },
    countryonBoardinputTxtMain: {
        width:'96.725%',
        border: "1px solid #dfdfdf",
        padding: "5px 7px 5px 0px",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
        "&.dial-autocomplete":{
            width:"150px!important",
            borderRight:0,
            borderTopRightRadius:0,
            borderBottomRightRadius:0
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
            paddingRight: "25px",
            fontSize: "16px",
            borderBottom: "none",
            paddingLeft: "10px",
        },
        "& .MuiAutocomplete-endAdornment":{
            right:"6px"
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInput-underline::before': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline::after': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: "0px",
        },
        '&. MuiInputBase-fullWidth': {
            width: '95%'
        }
    },
    adminFieldMainBox: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        // gap: "20px",
    },
    adminFiledGroupBox: {
        paddingBottom: "15px",
        width: "50%",
    },
    emailadminLabelText: {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    admininputTxtMain: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "98.278%",
        fontFamily: "'Inter', sans-serif",
    },
    searchInputIunder: {
        padding: "0 10px",
        '& .MuiFilledInput-underline:after': {
            borderBottom: '0px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderBottom: '0px',
        },
    },
    adminCompanyinputTxtMain: {
        width: '97%',
        border: "1px solid #dfdfdf",
        padding: "5px 10px",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
            paddingRight: "25px",
            fontSize: "16px",
            borderBottom: "none",
            paddingLeft: "10px",
        },
        "& .MuiAutocomplete-endAdornment":{
            right:"6px"
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInput-underline::before': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline::after': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: "0px",
        },
    },
    inputMainCountrySelect: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100%",
        fontFamily: "Inter, sans-serif",
    },
    fieldMainBox: {
        padding: '32px'
    },
    requireColor: {
        color: '#DC2626',
    },
    createUserTitleTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    createtitleContainer: {
        marginLeft: '32px',
        marginTop: '38px',
        display: 'flex',
        paddingTop: '24px',
        marginRight: '32px',
        justifyContent: 'space-between',
    },
    saveuserbtn: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: "700",
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: '7px 12px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        width:'71px',
        height:'44px'
    },
    saveandnew: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '10px',
        color: "#000000",
        fontWeight: "700",
        textTransform: "none",
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        height:"44px",
        width:"142px"
    },
    cancelbtn:{
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '7px 12px',
        color: "#000000",
        fontWeight: "700",
        textTransform: "capitalize",
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        height:"44px",
        width:"87px"
    },
    buttonFlex: {
        display: 'flex',
        padding: '0 32px 32px 0px',
        gap: '16px',
    },
    countryprofileplaceholder: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#64748B',
    },
    mobileInputWrapper: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #dfdfdf',
        borderRadius: '8px',
        height: '42px'
    },
    inputMainMobileSelect: {
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
        height: '45px',
        paddingBlock: '10px',
        width: 'fit-content',
        paddingLeft: '10px',
    },
    countryCodeMainBox: {
        width: "auto"
    },
    inputMobile: {
        borderRadius: "8px",
        height: "44px",
        width: "85%",
        fontFamily: "'Inter', sans-serif",
        padding: '5px'
    },
})

const PaperStyled = styled(Paper)({
    "&": {
        padding:"10px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& ul":{
        padding:"10px 5px"
    },
    "& ul li":{
        borderRadius:"8px",
        fontSize: "14px",
        padding:"9px 20px"
    },
    "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
        backgroundColor:"rgba(241, 245, 249, 1)"
    },
});

//@ts-ignore
export default withStyles(UserOnBoardStyle)(UserOnBoard);
// Customizable Area End