import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
export const baseURLJSON = require("../../../framework/src/config.js");
import { City, Country } from 'country-state-city';
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;

  firstNameA: string;
  lastNameA: string;
  companyA: string;
  jobTitleA: string;
  workEmailA: string;
  cityNameA: string;
  mobileNumberA: string;
  countryA: string;
  tabValue: number;
  searchUser: string;
  userActivInActive: boolean;
  open: boolean;
  openToast: boolean;
  openuserToast: boolean;
  countryDialCode: string;
  countryList: any[],
  clearInput:boolean;
  cityList: any[] | string,
  countryIsoCode: string;
  errors: {
    city: string,
    company: string,
    country: string,
    email: string,
    firstName: string,
    phoneNumber: string,
    jobTitle: string,
    lastName: string,
    countryDialCode: string
  }
  manageUserAllData: any[],
  profileDetailsData: any,
  profileId: string | number,
  companyListAllData: any[],
  pageNumbers:any,
  noNanageUserData: boolean;
  ifnoManageUserData: boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;

  countryAdminList: any[];
  CountryDialCodeList: any[];
  pageNumbers:any
  getManageUserViewMoreApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      countryDialCode: "+91",
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,

      firstNameA: "",
      lastNameA: "",
      companyA: "",
      jobTitleA: "",
      workEmailA: "",
      cityNameA: "",
      mobileNumberA: "",
      countryA: "",
      tabValue: 0,
      searchUser: "",
      userActivInActive: false,
      open: false,
      openToast: false,
      openuserToast: false,
      countryList: [],
      clearInput:false,
      cityList: [],
      countryIsoCode: '',
      errors: {
        city: '',
        company: '',
        country: '',
        email: '',
        firstName: '',
        phoneNumber: '',
        jobTitle: '',
        lastName: '',
        countryDialCode: ''
      },
      manageUserAllData: [],
      profileDetailsData: "",
      profileId: '',
      companyListAllData: [],
      pageNumbers:2,
      noNanageUserData:true,
      ifnoManageUserData:false
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;


    this.countryAdminList = configJSON.CountryListArry;
    this.CountryDialCodeList = configJSON.CountryDialCodeArry;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    this.getManageUserViewMoreApiCall(message);
    
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  txtInputadminFname = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = text.target;
      const firstNAResult = value.replace(/[^a-zA-Z ]/, '');
      this.setState(prevState => ({
        firstNameA: firstNAResult,
        errors: {
            ...prevState.errors,
            firstName: firstNAResult !== value ? 'Please enter alphabet' : ''
        }
      }))
    },
    onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
      const firstNAResult = text.target.value.replace(/[^a-z]/gi, '');
      this.setState(prevState => ({
        firstNameA: firstNAResult,
        errors: {
            ...prevState.errors,
            firstName: ''
        }
      }))
    }
  }

  txtInputadminLname = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = text.target;
      const lastNameAResult = value.replace(/[^a-zA-Z ]/, '');
      this.setState(prevState => ({
        lastNameA: lastNameAResult,
        errors: {
            ...prevState.errors,
            lastName: lastNameAResult !== value ? 'please enter alphabet' : ''
        }
      }))
    },
    onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
      const lastNameAResult = text.target.value.replace(/[^a-z]/gi, '');
      this.setState(prevState => ({
        lastNameA: lastNameAResult,
        errors: {
            ...prevState.errors,
            lastName: ''
        }
      }))
    }
  }

  txtInputadminComapnyName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ companyA: text.target.value })
    }
  }

  txtInputadminjobTitle = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = text.target;
      const jobTitleAResult = value.replace(/[^a-zA-Z ]/, '');
      this.setState(prevState => ({
        jobTitleA: jobTitleAResult,
        errors: {
            ...prevState.errors,
            jobTitle: jobTitleAResult !== value ? 'please enter alphabet' : ''
        }
      }))
    },
    onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
      const jobTitleAResult = text.target.value.replace(/[^a-z]/gi, '');
      this.setState(prevState => ({
        jobTitleA: jobTitleAResult,
        errors: {
            ...prevState.errors,
            jobTitle: ''
        }
      }))
    }
  }

  setSearchUser = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ searchUser: text.target.value })
    }
  }

  txtInputadminworkEmail = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = text.target;
      this.setState(prevState => ({
        workEmailA: value,
        errors: {
            ...prevState.errors,
            email: configJSON.email_user_regex.test(value) ? "" : 'Please enter a valid email address. '
        }
      }))
    }
  }

  txtInputadminMobileNo = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const value = text.target.value.replace(/\D/g, "")
      this.setState(prevState => ({
        mobileNumberA: value,
        errors: {
            ...prevState.errors,
            phoneNumber:''
        }
      }))
      
    },
    onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {

      const value = text.target.value.replace(/\D/g, "")

      this.setState(prevState => ({
        mobileNumberA: value,
        errors: {
            ...prevState.errors,
            phoneNumber:value == "" || value.length<10 ? "Please enter valid number" : '',
        }
      }))
    }
  }

  textCountryDialProps = {
    onChange: (text: string) => {
      this.setState(prevState => ({
        countryDialCode: text || "",
        errors: {
            ...prevState.errors,
            countryDialCode: text === "" ? 'Please select country dial code' : "",
        }
      }))
    },
    onBlur: (text: string) => {

      this.setState(prevState => ({
        countryDialCode: text || "",
        errors: {
            ...prevState.errors,
            countryDialCode: "",
        }
      }))
    }
  }

  textInputadminCity = (text: string) => {
    this.setState(prevState => ({
      cityNameA: text,
      errors: {
          ...prevState.errors,
          city: "",
      }
    }))
  }

  textInputadminCountry = (text: string) => {
    this.setState(prevState => ({
      countryA: text,
      errors: {
          ...prevState.errors,
          country: "",
      }
    }))
  }

  handleClose = {
    onClose: () => {
      this.setState({
        open: false,
      })
    }
  }

  handleCloseClick = {
    onClick: () => {
      this.setState({
        open: false,
        userActivInActive: this.state.userActivInActive
      })
    }
  }

  handelactivInActive = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        open: true,
        openToast: false,
      })
    }
  }

  handleShowToastlick = {
    onClick: () => {
      const dataId = sessionStorage.getItem('ProfileOnBoardUserId')
      //@ts-ignore
      const userId = JSON.parse(dataId)
      userId !== null && this.setuserActiveInactive(userId)
    }
  }

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({
      tabValue: newValue
    });
    this.getManageUserlist(newValue)
    this.setState({ noNanageUserData:true , pageNumbers:2, ifnoManageUserData:false})
  };

  countryadminPlaceholder = () => {
    return this.state.countryA !== "" ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Country</div>
  }

  checkErrors(obj: any) {
    for (let key in obj) {
        if (obj[key] == "")
            return false;
    }
    return true;
}

  checkAllFieldValidation = () => {
    const {countryA, cityNameA, firstNameA, lastNameA, companyA, jobTitleA, mobileNumberA, workEmailA} = this.state;
    if(firstNameA.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            firstName: "Please enter valid first name"
        }
      }))
    }
    if(lastNameA.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            lastName: "Please enter valid last name"
        }
      }))
    }
    if(companyA?.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            company: "Please enter valid company name"
        }
      }))
    }
    if(jobTitleA.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            jobTitle: "Please enter valid job title"
        }
      }))
    }
    if(workEmailA.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            email: "Please enter a valid email address"
        }
      }))
    }
    if(mobileNumberA.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            phoneNumber: "Please enter valid mobile number"
        }
      }))
    }
    if(cityNameA.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            city: "Please select city"
        }
      }))
    }
    if(countryA.length == 0){
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            country: "Please select country"
        }
      }))
    }
    let ifEmpty: boolean = this.checkErrors({countryA, cityNameA, firstNameA, lastNameA, companyA, jobTitleA, mobileNumberA, workEmailA})
    return ifEmpty;
  }

  createnewUser = {
    onClick: () => {
      if(this.checkAllFieldValidation()){
        this.createonBoardUset()
      }
    }
  }

  createnewOnBoadrdUser = {
    onClick: () => {
      if(this.checkAllFieldValidation()){
        this.createonBoardUset()
      }
    }
  }

  cancelFormBtn = {
    onClick: () => {
      this.setState({
        firstNameA: "",
        lastNameA: "",
        jobTitleA: "",
        workEmailA: "",
        mobileNumberA: "",
        countryA: "",
        cityNameA: "",
      });

      this.toggleClearInput();
      this.props.navigation.navigate("ManageUser")
    }
  }

  toggleClearInput = () => {
    this.setState((prevState) => ({
      clearInput: !prevState.clearInput, 
    }));
  }

  renderCountryCode = (selected: any) => {
    return selected;
  }


  createonBoardUset = async () => {
    try {
      const response = await fetch(`${baseURLJSON.baseURL}/account_block/accounts`, {
        method: "POST",
        body: JSON.stringify({
          "data": {
            "attributes": {
              "first_name": this.state.firstNameA,
              "last_name": this.state.lastNameA,
              "company_name": this.state.companyA,
              "job_title": this.state.jobTitleA,
              "user_type": "organization_user",
              "email": this.state.workEmailA,
              "phone_number": this.state.mobileNumberA,
              "country": this.state.countryA,
              "city": this.state.cityNameA,
              "activated": true,
              "country_code": this.state.countryDialCode
            },
            "type": "email_account"
          }
        }),
        headers: {
          "Content-Type": "application/json"
        },
      })
      const responseAllData = await response.json();
      if (response.status == 201) {
        this.toggleClearInput()
        this.setState({
          firstNameA: "",
          lastNameA: "",
          jobTitleA: "",
          workEmailA: "",
          mobileNumberA: "",
          countryA: "",
          cityNameA: "",
          errors: {
            company: "",
            email: "",
            country: "",
            city: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            jobTitle: "",
            countryDialCode: ""
          }
        }, () => {
          this.setState({
          openuserToast: true,
          })
      });
      }
      else {
        const { city, company_name, country, email, first_name, phone_number, job_title, last_name, country_dial_code } = responseAllData && responseAllData.errors
        this.setState({
          errors: {
            company: company_name && "Please enter valid company name",
            email: responseAllData.errors[0]?.account,
            country: country && "Please select country",
            city: city && "Please select city",
            firstName: first_name && "Please enter valid first name",
            lastName: last_name && "Please enter valid last name",
            phoneNumber: phone_number && "Please enter valid mobile number",
            jobTitle: job_title && "Please enter valid job title",
            countryDialCode: country_dial_code && "Please select country code"
          }
        })
      }
    } catch (error) {
      console.error(error);
    }
    this.setState({ openuserToast: false })
  }

  handelbNavigateProfileDetails = (id: number) => {
    sessionStorage.setItem('ProfileOnBoardUserId', JSON.stringify(id))
    this.setState({
      profileId: id
    })
    this.props.navigation.navigate(`ProfileDetails`)
    this.getProfileDetailsDataList(id);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Typical usage (don't forget to compare props):
    if (this.state.searchUser !== prevState.searchUser) {
      this.getManageUserlist(this.state.tabValue);

    }
    if (
      prevState.countryIsoCode !== this.state.countryIsoCode
    ) {
      this.setState({
        cityList: City.getCitiesOfCountry(this.state.countryIsoCode || 'IN')
      })
    }
  }

  getManageUserlist = async (newValue: number) => {

    const data = sessionStorage.getItem('token')
    //@ts-ignore
    const id = JSON.parse(data)

    try {
      const response = await fetch(`${baseURLJSON.baseURL}/account_block/accounts?activated=${newValue == 0 ? true : false}&name=${this.state.searchUser}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": id.token
        },
      })
      const responseAllData = await response.json();
      if (response.status == 200) {
        this.setState({
          manageUserAllData: responseAllData.meta.org_user_accounts
        })
      }
      else {
        if (responseAllData.errors[0].token == "Token has Expired") {
          this.props.navigation.navigate('EmailAccountLoginBlock')
          sessionStorage.clear()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }


  getCompanyListData = async () => {

    try {
      const response = await fetch(`${baseURLJSON.baseURL}/bx_block_profile/companies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const responseComapnyData = await response.json();

      if (response.status == 200) {
        // @ts-ignore
        const convertedArray = responseComapnyData.meta.company_ids.map(([id, name]) => ({ id, name }));
        this.setState({
          companyListAllData: convertedArray
        })
      }
      else {
        this.showAlert("Alert", responseComapnyData.errors[0].token)
        if (responseComapnyData.errors[0].token == "Token has Expired") {
          this.props.navigation.navigate('EmailAccountLoginBlock')
          sessionStorage.clear()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getProfileDetailsDataList = async (userid: number) => {
    const data = sessionStorage.getItem('token')
    //@ts-ignore
    const id = JSON.parse(data)
    try {
      const response = await fetch(`${baseURLJSON.baseURL}//account_block/accounts/${userid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": id.token
        },
      })
      const responseProfileData = await response.json();

      if (response.status == 200) {
        this.setState({
          profileDetailsData: responseProfileData.data,
          userActivInActive: responseProfileData.data.attributes.activated
        })
      }
      else {
        this.showAlert("Alert", responseProfileData.errors[0].token)
        if (responseProfileData.errors[0].token == "Token has Expired") {
          this.props.navigation.navigate('EmailAccountLoginBlock')
          sessionStorage.clear()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  setuserActiveInactive = async (Id: number) => {

    const data = sessionStorage.getItem('token')
    //@ts-ignore
    const id = JSON.parse(data)
    try {
      const response = await fetch(`${baseURLJSON.baseURL}/account_block/accounts/${Id}`, {
        method: "PUT",
        body: JSON.stringify({
          "account": {
            "activated": !this.state.userActivInActive,
          }
        }),
        headers: {
          "Content-Type": "application/json",
          "token": id.token
        },
      })
      const responseActiveDataData = await response.json();
      if (response.status == 200) {
        this.setState({
          open: false,
          openToast: true,
          userActivInActive: !this.state.userActivInActive
        })
      }
      else {
        this.showAlert("Alert", responseActiveDataData.errors[0].token)
        if (responseActiveDataData.errors[0].token == "Token has Expired") {
          this.props.navigation.navigate('EmailAccountLoginBlock')
          sessionStorage.clear()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  handleNavigateToHome = () => {
    this.props.navigation.navigate('Dashboard')
  };

  handleNavigateToUsers = () => {
    this.props.navigation.navigate('ManageUser')
  };

  handleNavigateToUserProfile = () => {
    this.props.navigation.navigate('ProfileDetails')
  };

  getManageUserlistViewMore = async (newValue: number)=> {

    const data: any = sessionStorage.getItem('token')
    const id = JSON.parse(data)
    let pageNo = this.state.pageNumbers;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getManageUserViewMoreApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetViewMore+`activated=${newValue == 0 ? true : false}&page=${pageNo}`
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token:  id.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodTypeApiCommon
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getManageUserViewMoreApiCall = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getManageUserViewMoreApiCallId != null &&
      this.getManageUserViewMoreApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if(!responseJson.meta.org_user_accounts.length)
          {
            this.setState((prevState) => ({
              noNanageUserData: true,
            }));
          }
        let pageNo = this.state.pageNumbers;
        let page = pageNo + 1;

        this.setState((prevState) => ({
          manageUserAllData: [...prevState.manageUserAllData, ...responseJson.meta.org_user_accounts],
          pageNumbers: page,
          ifnoManageUserData:true,
          noNanageUserData:responseJson.meta.is_view_more

        }));
      }
    }
  }

  handleGetAllCountryUserProfile = () => {
    const countryListData = Country.getAllCountries();
    countryListData.forEach((item: { name: any; isoCode: any; }) => {
      const countryName = item.name;
      const isoCode = item.isoCode;
      const allCity = City.getCitiesOfCountry(isoCode)
      if (countryName && allCity.length > 0) {
        this.setState((prevState) => ({
          countryList: [...prevState.countryList, item],
        }));
      }
    });
  };
  
  // Customizable Area End

}
