import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"

type IProps = {
    label:string;
    backgroundColor:string;
};
type IState = {};

class GraphLabel extends Component<IProps, IState>{

    render() {
        const { label, backgroundColor } = this.props;
        return (
            <StyledContainer>
                <Box style={{backgroundColor}} sx={webStyles.circle}/>
                <StyledLabel>
                    {label}
                </StyledLabel>
            </StyledContainer>
        )
    }
}

const webStyles = {
    circle:{
        height:"12px",
        width:"12px",
        borderRadius:"50%",
        overflow:"hidden"
    }
}

const StyledContainer = styled(Box)({
    "&": {
        display:"flex",
        alignItems:"center",
        gap:"6px",
        boxSizing:"border-box"
    },
    "@media(max-width: 960px)": {
        "&": {}
    }
});

const StyledLabel = styled(Typography)({
    "&": {
        fontSize:"12px",
        lineHeight:"18px",
        color:"#64748B"
    },
    "@media(max-width: 960px)": {
        "&": {}
    }
});

export default GraphLabel;