export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const chevronDown = require("../assets/chevronDown.svg");
export const trash = require("../assets/trash.svg");
export const plusCircle = require("../assets/plusCircle.svg");
export const downloadIcon = require("../assets/download.svg");
export const calendarIcon = require("../assets/calendar.svg");
export const leftArrowIcon = require("../assets/leftArrow.svg");
export const rightArrowIcon = require("../assets/rightArrow.svg");
export const greenTickIcon = require("../assets/greenTick.svg");
export const rejectIcon = require("../assets/reject.svg");
export const filterUpIcon = require("../assets/filterUp.svg");
export const filterDownIcon = require("../assets/filterDown.svg");
export const filterDownActiveIcon = require("../assets/filterDownActive.svg");
export const filterUpActiveIcon = require("../assets/filterUpActive.svg");
export const sortingIcon = require("../assets/sorting.svg");
export const emptyTableIcon = require("../assets/emptyTable.svg");
export const addNewService = require("../assets/AddService.svg");
export const deleteService = require("../assets/DeleteService.svg");
