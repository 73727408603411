export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const viewAllBgImage = require("../assets/grupemeet.jpg")
export const grupemeetBgEffect = require("../assets/grupemeetBgEffect.png")
export const PresentationImage = require("../assets/marketingPresentation.jpeg")
export const arrowDropDown = require("../assets/arrow_drop_down_24px.svg")
export const opportunitiesIon = require("../assets/opportunitiesIon.png")
export const verifyicon = require("../assets/icon_.png")
export const imagewithoutApi = require ("../assets/imagewithoutApi.svg")
export const closeImg = require("../assets/close.svg");
export const SuccessImg = require("../assets/success.svg");
export const DownloadPdf = require("../assets/DownloadPdf.png");
export const cheadRadio = require("../assets/radioButton.svg");
export const uncheadRadio = require("../assets/radiobutton_.svg");
export const cheackBox = require("../assets/checkbox_.svg");
export const cheackCheckbox = require("../assets/checked.svg");

