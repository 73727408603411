import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2/dist';
import SelectWithoutLabel from "./SelectWithoutLabel.web";
import { configJSON } from "../../blocks/dashboard/src/DashboardController";
import EmptyData from "./EmptyData.web";
import { emptySalesVolumeIcon } from "../../blocks/dashboard/src/assets";
import { formatMoney } from "../../blocks/dashboard/src/constants";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

type IProps = {
    getSalesPipelineFun: (item: string,event:string) => void,
    monthLabelProp:any[];
    monthNumberProp:any[];
    title:string;
    graphType:boolean;
};

type IState = {
    filter: string;
};

class SalesPipelineCard extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props)
        this.state = {
            filter: "monthly"
        }
    }

    render() {
        const { filter } = this.state;
        const { graphType, title, getSalesPipelineFun, monthLabelProp, monthNumberProp} = this.props
        const labels = monthLabelProp
        const dataset = monthNumberProp
        const data = {
            labels,
            datasets: [
                {
                    label: 'Dataset 1',
                    data: dataset,
                    backgroundColor: "#C399FF21",
                    borderColor: '#C399FF',
                    tension: 0.4,
                    pointRadius: 0,
                    hoverPointRadius: 0
                },
            ],
        };
        const minimizeAmount = (value: string | number) => {
            const numericValue: number = typeof value === 'string' ? parseFloat(value) : value;
            const doller =  graphType ? "$" : ""
            const valueTemp = numericValue < 0 ? 0 : numericValue;
            return  doller + formatMoney(valueTemp)
        }



        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                }
            },
            scales: {
                x: {
                    ticks:{
                        font:{
                        size: 12,
                        color: "#64748B",
                        family:"'Inter', sans-serif"
                        }
                    },
                    grid: {
                        drawBorder: false,
                        display: false,
                    },
                    border: {
                        display: false
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        callback: (val:number | string) => {
                            return minimizeAmount(val)
                        },
                        font:{
                            size: 12,
                            color: "#64748B",
                            family:"'Inter', sans-serif"
                        }
                    },
                    grid: {
                        // drawBorder: false
                    },
                    title: {
                        display: true,
                        color: '#64748B',
                        font: {
                        family: 'Inter',
                        size: 12,
                        lineHeight: "18px"
                        }
                    }
                }
            },
        };

        return (
            <StyledContainer>
                <Box className="header">
                    <Typography className="sales-pipeline-header-title">{title}</Typography>
                    <Box className="select-container">
                        <SelectWithoutLabel
                            data={configJSON.periodsValue}
                            value={filter}
                            onChange={(text)=> { getSalesPipelineFun(text,"Select"),
                                this.setState({ filter: text }) 
                            }}
                        />
                    </Box>
                </Box>
                {
                    monthLabelProp === undefined && monthLabelProp === undefined ? <EmptyData description={`You don't have any ${title.toLowerCase()} data.`}  image={emptySalesVolumeIcon} /> :
                    <>
                        <Box className="graph-container">
                            <Line options={options} data={data} />
                        </Box>
                    </>
                }
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)({
    "&": {
        border: "1px solid rgba(226, 232, 240, 1)",
        borderRadius: "8px",
        padding: "24px",
        width: "calc(50% - 12px)",
        boxSizing: "border-box",
        maxHeight:"369px"
    },
    "& .header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    "& .sales-pipeline-header-title": {
        fontSize: "20px",
        lineHeight: "28px",
        color: "#000000"
    },
    "& .header .view-all-button": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        textTransform: "capitalize",
        color: "#64748B"
    },
    "& .select-container": {
        width: "115px",
        height: "40px"
    },
    "& .graph-labels-container" : {
        marginBottom:"20px"
    },
    "& .graph-container":{
        display:"flex",
        alignItems:"center"
    },
    "& canvas" : {
        // maxHeight:"260px"
        height:"220px!important"
    },
    "@media(max-width: 960px)": {
        "&": {
            padding:"12px",
            width: "calc(50% - 6px)"
        },
        "& .sales-pipeline-header-title": {
            fontSize: "14px",
            lineHeight: "20px"
        },
        "& .select-container": {
            height: "32px"
        },
        "& .header .view-all-button": {
            fontSize: "12px",
            lineHeight: "16px"
        },
    },
    "@media(max-width: 768px)": {
        "&": {
            width: "100%"
        }
    }
});

export default SalesPipelineCard;