import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { toast } from "react-toastify";
import createRequestMessage from "../../../framework/src/create-request-message";
import { capitalizeWords, mapQuarterKeysToMonths } from "./constants";
import {RevenueData} from "./types"

const ErrorToastStyles = {
    toast: {
        width: 'max-content',
        maxHeight: '42px !important',   
        minHeight: '42px',
        color: '#0A0528',
        borderRadius: '8px',
        fontSize: '16px'
    }
}

const ErrorToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    style: ErrorToastStyles.toast,
    hideProgressBar: true,
    closeButton: false
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    salesPipelineData:any;
    dealGeneratedData:any;
    stageData:any;
    leadSourceData:any;
    topTenLabels:any[];
    isLoading:boolean;
    dealTypeData:any;
    dealTypeLabelData:string[];
    dealTypeNumberData:any[];
    addNewClientsData:any;
    totalCountsCardsData:any;
    apiError:any[];
    displayedMessages:any;
    onSelectErrorShow:boolean;
    role:string;
    isPartnerUser:boolean;
    totalNumberOfClients: any[]
    revenuAllData:RevenueData;
    shownItems:string;
    filterType:string;
    pageNumberSet:number
    totalPageNumber: number;
    currentPage:number;
    isTableLoading:boolean;
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    salesPipelineChartApiCallId: string | null;
    dealGeneratedChartApiCallId: string | null;
    stageChartApiCallId: string | null;
    leadSourceChartApiCallId: string | null;
    dealTypeChartApiCallId: string | null;
    addNewClientsChartApiCallId: string | null;
    totalCardsNumberApiCallId: string | null;
    totalNumberOfClientsApiCallId: string | null;
    revenueByEeachUserApiCallId: string | null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            salesPipelineData:{},
            dealGeneratedData:{},
            stageData:{},
            leadSourceData:{},
            topTenLabels:[],
            isLoading:false,
            dealTypeData:{},
            dealTypeLabelData:[],
            dealTypeNumberData:[],
            addNewClientsData:[],
            totalCountsCardsData:{},
            apiError:[],
            displayedMessages: new Set(),
            onSelectErrorShow:false,
            role:'',
            isPartnerUser:false,
            totalNumberOfClients:[],
            revenuAllData: {
                message: "",
                org_user_accounts: [],
                total_page: 1
            },
            shownItems:"5",
            filterType:'',
            pageNumberSet:1,
            totalPageNumber:2,
            currentPage:1,
            isTableLoading:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start
    async receive(_from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.salesPipelineChartApiCallId: {
                    this.salesPipelineChartApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ salesPipelineData: responseJson.data})  
                        },
                        onFail: () => {
                            const error =responseJson?.errors[0]?.account ?? responseJson?.errors[0]?.commisions
                            this.handleRunToaster(error)
                        },                            
                
                    });
                    break;
                }
                case this.dealGeneratedChartApiCallId: {
                    this.dealGeneratedChartApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ dealGeneratedData: responseJson.data})  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 2500); 
                        },
                        onFail: () => {
                            setTimeout(() => {
                                if (responseJson.errors && responseJson.errors[0]) {
                                    const error = responseJson.errors[0].account ?? responseJson.errors[0].deals;
                                    this.handleRunToaster(error);
                                }
                            }, 2500);        
                        },                  
                    });
                    break;
                }
                case this.stageChartApiCallId: {
                    this.stageChartApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ stageData: responseJson.data})  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 2500); 
                        },
                        onFail: () => {
                            setTimeout(() => {
                                const error =responseJson?.errors[0]?.account ?? responseJson?.errors[0]?.commisions
                                this.handleRunToaster(error)
                                this.setState({ isLoading: false })
                            }, 2500);        
                        },
                    });
                    break;
                }
                case this.leadSourceChartApiCallId: {
                    this.leadSourceChartApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ leadSourceData: responseJson.data})  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 2500); 
                        },
                        onFail: () => {
                            setTimeout(() => {
                                const error =responseJson?.errors[0]?.account ?? responseJson?.errors[0]?.commisions
                                this.handleRunToaster(error)
                                this.setState({ isLoading: false })
                            }, 2500);        
                        },
                    });
                    break;
                }
                case this.dealTypeChartApiCallId: {
                    this.dealTypeChartApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ dealTypeData: responseJson.data},()=> {
                                this.manageDealTypeData() 
                            })  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 1000); 
                          
                        },
                        onFail: () => {
                                const error = responseJson?.errors[0]?.account ?? responseJson?.errors[0]?.deals
                                setTimeout(() => {
                                    this.handleRunToaster(error)
                                    this.setState({ isLoading: false })

                                }, 1000);        
                        },                  
                    });
                    break;
                }
                case this.addNewClientsChartApiCallId: {
                    this.addNewClientsChartApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ addNewClientsData: responseJson.data})  
                        },
                        onFail: () => {
                            const error =responseJson?.errors[0]?.account ?? responseJson?.errors[0]?.commisions
                            this.handleRunToaster(error)
                        },                  
                    });
                    break;
                }
                case this.totalCardsNumberApiCallId: {
                    this.totalCardsNumberApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ totalCountsCardsData: responseJson.data})  
                        },
                        onFail: () => {
                            const error =responseJson?.errors[0]?.account
                            this.handleRunToaster(error)
                        },                  
                    });
                    break;
                }
                case this.totalNumberOfClientsApiCallId: {
                    this.totalNumberOfClientsApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            const outputArray: number[] = [responseJson.data.active, responseJson.data.deactive];
                            this.setState({ totalNumberOfClients: outputArray ? outputArray : []})  

                        },
                        onFail: () => {
                            const error =responseJson?.errors[0]?.account
                            this.handleRunToaster(error)
                        },                  
                    });
                    break;
                }
                case this.revenueByEeachUserApiCallId: {
                    this.revenueByEeachUserApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ 
                                revenuAllData: responseJson.data,
                                totalPageNumber:responseJson.data.total_page
                            })
                              
                            setTimeout(() => {
                                this.setState({isTableLoading:false})
                            }, 2500); 
                        },
                        onFail: () => {
                            const error = responseJson?.errors[0]?.commisions
                            this.handleRunToaster(error)
                            this.setState({
                                isTableLoading:false,
                                revenuAllData: {
                                    message: "",
                                    org_user_accounts: [],
                                    total_page: 1
                                },
                                totalPageNumber: 2 })
                        },
                    });
                    break;
                }
            }
        }
    }
   
    handleGetToken= ()=> {
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data);
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            }
            else {
                throw error;
            }
        }
        return  id?.token 
    }

    handleGetSalesPipelineData = (item:string,event:string)=> {
        this.handleToCheckDropdownSelect(event)
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.salesPipelineChartApiCallId = requestMessage.messageId;
        
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.endPointApiGetSalesPipeline}?timeslot=${item}`,
            method: configJSON.dashboarApiMethodType,
        });
     
    }
    handleGetdealTypeData = (item:string,event:string)=> {
        this.handleToCheckDropdownSelect(event)
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.dealTypeChartApiCallId = requestMessage.messageId;
        
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.endPointApiGetDealType}?timeslot=${item}`,
            method: configJSON.dashboarApiMethodType,
        });
    }
    handleGetDealGeneratedData = (item:string)=> {
        this.setState({isLoading:true})
      
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.dealGeneratedChartApiCallId = requestMessage.messageId;
        
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint:`${configJSON.endPointApiGetDealGenerated}`,
            method: configJSON.dashboarApiMethodType,
        });
    }

    manageDealTypeData = ( ) => {
        const { dealTypeData } = this.state
        let monthLabel:any
        let monthLabelTemp:any

        if (dealTypeData !== null && dealTypeData !== undefined) {
            monthLabel = Object.keys(dealTypeData).map(capitalizeWords);
            monthLabelTemp = Object.keys(dealTypeData);
            if(monthLabel !== undefined &&  monthLabel !== null )
            {
                this.setState({dealTypeLabelData :mapQuarterKeysToMonths(monthLabel), 
                    dealTypeNumberData: monthLabelTemp?.map((key: any) => Object.values(dealTypeData?.[key] || {}))
                })
            }
        }
    }

    handleGetStageAndLeadSourceData = ( key:string )=> {
        this.setState({isLoading:true})
      
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.stageChartApiCallId = requestMessage.messageId;
        if( key === "lead_source"){
            this.leadSourceChartApiCallId = requestMessage.messageId;
        }
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.endPointApiGetStageAndLeadSource}?field_name=${key}`,
            method: configJSON.dashboarApiMethodType,
        });
    }
    handleGetAddNewClientsData = (item:string,event:string)=> {
        this.handleToCheckDropdownSelect(event)
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addNewClientsChartApiCallId = requestMessage.messageId;
        
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.endPointApiGetAddNewClients}?timeslot=${item}`,
            method: configJSON.dashboarApiMethodType,
        });
    }

    handleGetTotalCardsNumber = ( )=> {
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.totalCardsNumberApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.endPointApiGetTotalCard,
            method: configJSON.dashboarApiMethodType,
        });
     
    }
    handleToCheckDropdownSelect = (event:string) => {
        if(event === "Select"){
            this.setState({onSelectErrorShow:true})
        }
    }
    handleToaster = () => {
        const uniqueMessages = Array.from(new Set(this.state.apiError));
        if (uniqueMessages.length > 0) {
            uniqueMessages.forEach((errorMessage) => {
              if (!this.state.displayedMessages.has(errorMessage)) {
                if(errorMessage !== "Record not found" ){
                    toast.error(errorMessage,ErrorToastOptions);
                }
                this.setState((prevState) => ({
                  displayedMessages: new Set([...prevState.displayedMessages, errorMessage]),
                }));
              }
            });
        }
    }
    handleOnSelectToaster = (error:string) => {
        toast.error(error,ErrorToastOptions);
    }  
    handleRunToaster = (error:string)  =>{
        if(this.state.onSelectErrorShow){
            this.handleOnSelectToaster(error)
        }
        this.setState(prevState => ({
            apiError: [...prevState.apiError, error],
          }), () => {
            if(error !== "Record not found"){
                this.handleToaster();
            }
        });
    }

    handleGetLogingUserType= ()=> {
        let token = sessionStorage.getItem("token");
        this.setState({
            role: JSON.parse(token as string)?.account.data.attributes.user_type
        }, ()=> {
            this.handleTocheckUserType()
            this.handleGetDealGeneratedData(" ")

        });
    }
   
    handleTocheckUserType=()=> {
        const {role}= this.state
        if(role === "organization_user"){
            this.setState({isPartnerUser:true})
        }
    }

    handleTotalNumberOfClients = ( )=> {
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.totalNumberOfClientsApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.endPointApiTotalNumberOfClients,
            method: configJSON.dashboarApiMethodType,
        });
     
    }

    handleGetRevenueByEachUser = (item:string,event:string)=> {
        this.setState({isTableLoading:true})
        const { currentPage,shownItems } =this.state  
        this.handleToCheckDropdownSelect(event)
        const header = {
            token:this.handleGetToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.revenueByEeachUserApiCallId = requestMessage.messageId;
        this.setState({filterType:item})
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.endPointApiRevanueByEachUser}?timeslot=${item}&page=${currentPage}&per_page=${shownItems}`,
            method: configJSON.dashboarApiMethodType,
        });
    }
    
    handlePerPageSelectionRevenueByEU = (value:string) => {
        const { filterType } = this.state
        this.setState({shownItems:value},()=>this.handleGetRevenueByEachUser(filterType,""))
    }

    handlePaginationButtonRevenueByEU  = (value:number) => {
        const { filterType } = this.state
        if(value < 1 || value > this.state.totalPageNumber) return
        this.setState({currentPage:value, pageNumberSet:value},()=>this.handleGetRevenueByEachUser(filterType,""))
    }

    async componentDidMount() {
        this.handleGetLogingUserType()
        this.handleGetStageAndLeadSourceData("lead_source")
        this.handleGetStageAndLeadSourceData("stage")
        this.handleGetSalesPipelineData('monthly',"")
        this.handleGetAddNewClientsData('monthly',"")
        this.handleGetdealTypeData('monthly',"")
        if(this.state.role !== "organization_user"){
            this.handleGetTotalCardsNumber()
            this.handleGetRevenueByEachUser('monthly',"")
        }
        this.handleTotalNumberOfClients()
    }
    // Customizable Area End
}