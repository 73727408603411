import { TableCell, TableRow, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { IRegisteredOpportunity } from "./types";
import { StyledTableBody } from "./RegisteredOpportunitiesCard.web";
import { opportunityFieldStyles, renderStatus } from "../../cfpipelinemanagement/src/Components/OpportunitiesTable.web";
import { dateFormatOppAllList, formatString } from "../../cfpipelinemanagement/src/constants";

type IProps = {
  data: IRegisteredOpportunity[];
  classes:any
};
type IState = {};

class StatisticsTableBody extends Component<IProps, IState> {
  render() {
    const { data ,classes} = this.props;
    return (
      
        <StyledTableBody className="statistics-table-body">
          {data?.map(row => (
            <TableRow key={row.attributes.opportunity_id}>
              <TableCell style={{ width: "11.44%" }} align="center">
                {row.attributes.opportunity_id}
              </TableCell>
              <TableCell
                style={{ width: "19.61%" }}
                className="opportunity-name"
                align="left"
              >
                {row.attributes.opportunity_name}
              </TableCell>
              <TableCell style={{ width: "10.13%" }} align="left">
                  <Typography  className="status" style={{ ...opportunityFieldStyles[row.attributes.status as keyof typeof opportunityFieldStyles] }}>
                    { 
                        renderStatus(row.attributes.status,row.attributes.rejection_reason,classes)
                    }
                </Typography>
              </TableCell>
              <TableCell style={{ width: "16.34%" }} align="left">
                <Typography style={{ ...opportunityFieldStyles[row.attributes.stage as keyof typeof opportunityFieldStyles] }}>{formatString(row.attributes.stage)}</Typography>
              </TableCell>
              <TableCell style={{ width: "14.7%" }} align="left">
                {row.attributes.currency_symbol||'$'}{row.attributes.expected_revenue}
              </TableCell>
              <TableCell style={{ width: "13.89%" }} align="left">
                {dateFormatOppAllList(row.attributes.created_at,false)}
              </TableCell>
              <TableCell style={{ width: "13.89%" }} align="left">
                {dateFormatOppAllList(row.attributes.closing_date,true)}
              </TableCell>
            </TableRow>
          ))}
        </StyledTableBody>
    );
  }
}

export default StatisticsTableBody;
