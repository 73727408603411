import { IValidation } from "./types";

export const CommissionCalculatorBreadcrumb = {
    currentPage: {
        text: "Commission",
        link: "/Cfcommissioncalculator2"
    }
};

export const CommissionStatusBreadcrumb = {
    currentPage: {
        text: "Commission",
        link: "/CommissionStatus"
    }
};

export const EmptyClient = {
    id: "1",
    name: "",
    startDate: "",
    serviceType: [],
    consultingQuantity: "1",
    staffQuantity: "1",
    dealAmount: "",
    platformPackage:"",
    staffRole: [],
    consultingRole: [],
    partnerCommission: "",
    totalIncome: "",
    validation: "standby" as IValidation
};

export const EmptyPayloadClient = {
    client_name: '',
    start_date: '', 
    client_roles_attributes: [],
    total_income: 0
}

export const serviceTypes = [
    "Platform", "Technology", "Consulting", "Staff Augmentation"
]

export const PlatformPackages = [
    "Starter",
    "Starter Plus",
    "Growth",
    "Advanced",
    "Enterprise"
];

export const ServiceTypePayloadValues = {
    "Platform" : "platform",
    "Technology" : "technology",
    "Consulting" : "consulting",
    "Staff Augmentation" : "staff_augmentation"
}

export const RoleTypePayloadValues = {
    "Analyst" : "analyst",
    "Associate Consultant" : "associate_consultant",
    "Consultant" : "consultant"
}

export const PlatformPayloadValues = {
    "Starter" : "Starter",
    "Starter Plus" : "Starter_Plus",
    "Growth" : "Growth",
    "Advanced" : "Advanced",
    "Enterprise" : "Enterprise"
}