import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import {
  decreasingArrowIcon,
  decreasingGraphIcon,
  increasingArrowIcon,
  increasingGraphIcon
} from "./assets";
import { formatMoney, numberFormat } from "./constants";

type IProps = {
  label: string;
  value: string;
  isIncreasing: boolean;
  percentage: string;
};
type IState = {};

class StatisticCard extends Component<IProps, IState> {
  render() {
    const { label, value, percentage, isIncreasing } = this.props;
    return (
      <StyledContainer>
        <Typography className="label-text">{label}</Typography>
        <Box className="graph-container">
          <Box className="percentage-wrapper">
            <Typography className="value-text">
               {  label === "Opportunities" ? (
                    <>
                      {numberFormat(value)}
                    </>
                   ):
                  ( <> 
                      ${formatMoney(value)}
                    </> )
                }
               </Typography>
            <Box className="percentage-container">
              <img
                src={isIncreasing ? increasingArrowIcon : decreasingArrowIcon}
                alt="increase-decrease"
              />
              <Typography
                className={`${
                  isIncreasing ? "yellow-text" : "red-text"
                } percentage-text`}
              >
                {percentage}%
              </Typography>
              <Typography className="last-month-text">last mth</Typography>
            </Box>
          </Box>
          <img
            src={isIncreasing ? increasingGraphIcon : decreasingGraphIcon}
            alt="increase-decrease-graph"
          />
        </Box>
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(Box)({
  "&": {
    width: "100%",
    padding: "20px 16px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    boxShadow: "0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
    boxSizing: "border-box",
    maxHeight: "168px",
    "& .label-text": {
      fontSize: "20px",
      lineHeight: "28px",
      color: "#000000"
    },
    "& .graph-container": {
      display: "flex",
      justiyContent: "space-between",
      alignItems: "flex-end"
    },
    "& .percentage-wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      flex: 1,
      "& .value-text": {
        fontSize: "30px",
        lineHeight: "40px",
        fontWeight: 700,
        color: "#000000"
      }
    },
    "& .percentage-container": {
      display: "flex",
      gap: "8px",
      "& .percentage-text, & .last-month-text": {
        fontSize: "16px",
        lineHeight: "24px",
        color: "#64748B",
        "&.yellow-text": {
          color: "#059669"
        },
        "&.red-text": {
          color: "#DC2626"
        }
      }
    }
  },
  "@media(max-width: 576px)": {
    "&": {
      gap: 0,
      "& .label-text": {
        fontSize: "16px!important"
      },
      "& .percentage-wrapper": {
        "& .value-text": {
          fontSize: "22px!important"
        }
      },
      "& .percentage-container": {
        "& .percentage-text, & .last-month-text": {
          fontSize: "12px!important"
        }
      }
    },
    "& .graph-container>img": {
      maxWidth: "70px"
    }
  }
});

export default StatisticCard;
