// Customizable Area Start
import { Box, Checkbox, Input, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"
import { IRole } from "./types";

type IProps = {
    checked: boolean;
    title: string;
    quantity: string;
    handleRoleUpdate: (value: IRole) => void;
};
type IState = {};
// Customizable Area End

class RoleSelectItem extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleFieldChange = (fieldType: "checkbox" | "input", value?: string) => {
        const sanitizedValue = value ? value.replace(/\D/g, '') : '';
        const { checked, title, quantity } = this.props;
        let currentRole = { checked, title, quantity }
        if (fieldType === "checkbox") {
            currentRole = { ...currentRole, checked: !currentRole.checked }
        }
        else {
            currentRole = { ...currentRole, quantity: sanitizedValue}
        }
        this.props.handleRoleUpdate(currentRole);
    }

    onBlur = (value:string) => {
        const { checked, title, quantity } = this.props;
        let currentRole = { checked, title, quantity }

        if(value !== ""){
            currentRole = {...currentRole,quantity:value}
        }
        else{
            currentRole = {...currentRole, quantity:"1"}
        }
        this.props.handleRoleUpdate(currentRole);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { checked, title, quantity } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <Box sx={webStyles.checkboxRow}>
                    <Box data-test-id="checkbox-button" onClick={() => this.handleFieldChange("checkbox")} sx={webStyles.titleRow}>
                        <StyledCheckbox
                            disableRipple
                            checked={checked}
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />
                        <StyledTitle>
                            {title}
                        </StyledTitle>
                    </Box>
                    <StyledTitle>
                        ({quantity})
                    </StyledTitle>
                </Box>
                {
                    checked &&
                    <Box sx={webStyles.selectRow}>
                        <StyledQuantityText>
                            Add Quantity :
                        </StyledQuantityText>
                        <StyledInput
                            data-test-id="quantity-select"
                            onChange={(event) => this.handleFieldChange("input", event.target.value)}
                            onBlur={(event) => this.onBlur(event.target.value)}
                            disableUnderline
                            type="text"
                            fullWidth={true}
                            value={quantity}
                        />
                    </Box>
                }
            </Box>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyles = {
    checkboxRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    titleRow: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    selectRow: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        marginTop: "10px"
    },
    selectWidth: {
        width: "351px"
    }
}

const StyledCheckbox = styled(Checkbox)({
    "& .MuiSvgIcon-root": {
        height: "20px",
        width: "20px"
    },
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${require("../../../components/src/assets/svg/Unchecked.svg")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    "&.Mui-checked .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${require("../../../components/src/assets/svg/Checked.svg")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    "&": {
        justifyContent: "flex-start",
        padding: 0,
        color: "#0A0528!important"
    },
    "&:hover": {
        backgroundColor: "transparent!important",
        borderRadius: " 6px"
    }
});

const StyledTitle = styled(Typography)({
    "&": {
        fontSize: "14px",
        lineHeight: "22px"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledQuantityText = styled(Typography)({
    "&": {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "10px"
        }
    }
});

const StyledInput = styled(Input)({
    "&": {
        border: "1px solid #dfdfdf",
        padding: "10px 8px",
        borderRadius: "8px",
        height: "36px",
        width: "351px",
        fontFamily: "'Inter', sans-serif",
    },
    "@media(max-width: 960px)": {
        "&": {
            height: "40px"
        }
    }
});

export default RoleSelectItem;
// Customizable Area End