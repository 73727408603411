//@ts-nocheck
import React from "react";
import { Box, Button, Input, InputLabel, Typography } from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { imgPasswordKey, backArrow } from './assets';
import {
  withStyles
} from "@material-ui/core/styles";


export class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={`${classes.mainBoxFlex}`}>
        <Box className={`${classes.mainBox}`}>
          <Box>
            <img src={imgPasswordKey} alt="imgPasswordKey" />
          </Box>
          <Box className={`${classes.textMainBox}`}>
            <Typography className={`${classes.headingText}`}>{this.forgotPasswordLable}</Typography>
            <Typography className={`${classes.forgotDescriptionText}`}>{this.forgotDescriptionText}</Typography>
          </Box>

          { this.errorModal()}

          <Box {...this.resetEnterPassword} sx={{ paddingBottom: '15px', width: '78.261%', marginTop: '32px' }}>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <InputLabel className={`${classes.emailLabelText}`}>
                Email
              </InputLabel>
              <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
            </Box>
            <Input
              data-test-id="txtInputEmail"
              placeholder={"Enter your email"}
              fullWidth={true}
              value={this.state.email}
              {...this.textEmailprops}
              disableUnderline
              className={`${classes.inputMain}`}
            />
          </Box>
          <Box className={`${classes.signIpBtn}`} >
            <Button
              id={"resetPasswordButton"}
              variant="contained"
              className={`${classes.signupText}`}
              fullWidth
              {...this.resetPasswordApi}
            >
              Reset password
            </Button>
          </Box>
          <Box className={`${classes.bcktomainBox}`} data-test-id='backtologinbtn' {...this.nvigationtologin}>
            <img src={backArrow} alt="backArrow" />
            <Typography className={`${classes.backtologinText}`}>{this.backToLoginText}</Typography>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    )
  }
}

// Customizable Area Start

export const ForgotPasswordStyles = (theme: any) => ({
  mainBox: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '22px',
    width: '460px',
    height: '472px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "@media(max-width: 387px)": {
    mainBox: {
      height:'500px'
    }
  },
  headingText: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '30px',
    letterSpacing: '-0.005em',
    textAlign: 'center',
    fontFamily: 'Inter',
  },
  textMainBox: {
    marginTop: '24px',
    width:'87%'
  },
  mainBoxFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E5E5E5',
    height: '100vh',
  },
  forgotDescriptionText: {
    color: '#667085',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '12px',
    letterSpacing: '0',
    fontFamily: 'Inter',
  },
  newDescriptionText: {
    width: '340px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    color: '#667085',
    textAlign: 'center',
    fontFamily: 'Inter',
  },
  inputMain: {
    border: '2px solid #dfdfdf',
    padding: '10px',
    borderRadius: '8px',
    height: '44px',
    fontFamily: 'Inter',
  },
  emailLabelText: {
    marginBottom: '5px',
    color: '#334155',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '22px',
  },
  requireColor: {
    color: 'red',
  },
  signIpBtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '78.261%',
    marginTop: '24px',
  },
  signupText: {
    background: 'linear-gradient(90deg, rgba(51,60,92,1) 36%, rgba(10,12,35,1) 100%)',
    color: 'white',
    fontWeight: '700',
    textTransform: 'none',
    height: '44px',
    padding:'10px 16px 10px 16px',
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontSize:"16px",
    // width:"97.83%"
  },
  backtologinText: {
    color: '#2E17B5',
    fontWeight: '400',
    fontSize: '14px',
    paddingLeft: '5px',
    fontFamily: 'Inter',
  },

  bcktomainBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '24px',
  },
  redInputMain: {
    border: '2px solid #F87171',
    padding: '10px',
    borderRadius: '10px',
    height: '45px',
    fontFamily: 'Inter',
  },
  aerltBorder: {
    marginTop: '10px',
    width:'78.261%'
  },
  alertTextDesign: {
    background: '#FEE2E2',
    borderLeft: '4px solid #DC2626',
    borderRadius: '4px',
    color: '#DC2626',
    fontWeight: '400',
    fontSize: '12px',
    width: '100%',
    height: '42px',
    display: 'flex',
    justifyContent: 'flexStart',
    alignItems: 'center',
    marginTop: '15px',
    paddingLeft: '10px',
    fontFamily: 'Inter',
  },
})

//@ts-ignore
export default withStyles(ForgotPasswordStyles)(ForgotPassword);
// Customizable Area End