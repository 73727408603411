import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const downloadAction = (title:string) => {
    setTimeout(()=>{
        const input = document.getElementById("pdfView")!;
        html2canvas(input,{scale: 2})
            .then((canvas:HTMLCanvasElement) => {
                const componentWidth = input.offsetWidth || 0
                const componentHeight = input.offsetHeight || 0

                const orientation = componentWidth >= componentHeight ? 'l' : 'p'

                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF({
                    orientation,
                    unit: 'px',
                })

                pdf.internal.pageSize.width = componentWidth
                pdf.internal.pageSize.height = componentHeight

                pdf.addImage(imgData, 'JPEG', 0, 0, componentWidth, componentHeight)
                
                const splitText = title.split(" ");
                const capitalizedText = splitText.map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase());
                const titleTemp = capitalizedText.join("_");
                pdf.save(titleTemp+'.pdf')
            })
    },1000)
}