import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { AllFieldKeysToRemove, PricingTypes } from "./constants";
import createRequestMessage from "../../../framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { IEstimatedPriceEndPoint, IEstimatedPriceRequestBody, IEstimatedPriceStates } from "./types";

const countries = require("./countries.json")

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    isApproved: boolean;
    activePricingType: string | null;
    pricingTypes: string[];
    platformEstimatedPrice: string;
    consultingEstimatedPrice: string;
    staffingEstimatedPrice: string;
    technologyEstimatedPrice: string;
    oneTimeImplementationCost: string;
    countryList: string[];
    token: string;
}

interface SS {
    id: any;
}

export default class CfleadmanagementandopportunityController extends BlockComponent<
    Props,
    S,
    SS
> {

    platformEstimatedCostApiCallId: string | null;
    consultingEstimatedCostApiCallId: string | null;
    staffingEstimatedCostApiCallId: string | null;
    technologyEstimatedCostApiCallId: string | null;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            isApproved: true,
            activePricingType: null,
            pricingTypes: PricingTypes,
            platformEstimatedPrice: "",
            consultingEstimatedPrice: "",
            staffingEstimatedPrice: "",
            technologyEstimatedPrice: "",
            oneTimeImplementationCost: "",
            countryList:[],
            token: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.platformEstimatedCostApiCallId = "";
        this.consultingEstimatedCostApiCallId = "";
        this.staffingEstimatedCostApiCallId = "";
        this.technologyEstimatedCostApiCallId = "";
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.platformEstimatedCostApiCallId: {
                    this.platformEstimatedCostApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ platformEstimatedPrice: responseJson.estimated_cost })
                        },
                        onFail: () => this.showAlert(`${responseJson.error[0].message}`, "Please retry!"),
                    });
                    break;
                }
                case this.consultingEstimatedCostApiCallId: {
                    this.consultingEstimatedCostApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ consultingEstimatedPrice: responseJson.estimated_cost })
                        },
                        onFail: () => this.showAlert(`${responseJson.error[0].message}`, "Please retry!"),
                    });
                    break;
                }
                case this.staffingEstimatedCostApiCallId: {
                    this.staffingEstimatedCostApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ staffingEstimatedPrice: responseJson.estimated_cost })
                        },
                        onFail: () => this.showAlert(`${responseJson.error[0].message}`, "Please retry!"),
                    });
                    break;
                }
                case this.technologyEstimatedCostApiCallId: {
                    this.technologyEstimatedCostApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ technologyEstimatedPrice: responseJson.estimated_cost, oneTimeImplementationCost: responseJson.one_estimated_cost })
                        },
                        onFail: () => this.showAlert(`${responseJson.error[0].message}`, "Please retry!"),
                    });
                    break;
                }
            }
        }
    }

    handleResetEstimatedPrice = (name: IEstimatedPriceStates) => {
        this.setState({
            [name]: ""
        } as unknown as Pick<S, keyof S>);
    }

    handlePricingTypeChange = (text: string | null) => {
        this.setState({ activePricingType: text })
    }

    handleGetEstimatedPrice = (body: IEstimatedPriceRequestBody, endPoint: IEstimatedPriceEndPoint) => {
        const estimatedPriceEndPoints = {
            "platform": `${configJSON.platformEstimatedCostApiEndpoint}`,
            "consulting": `${configJSON.consultingEstimatedCostApiEndpoint}`,
            "staffing": `${configJSON.staffingEstimatedCostApiEndpoint}`,
            "technology": `${configJSON.technologyEstimatedCostApiEndpoint}`
        }

        const header = {
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        switch (endPoint) {
            case "platform":
                this.platformEstimatedCostApiCallId = requestMessage.messageId;
                break;
            case "consulting":
                this.consultingEstimatedCostApiCallId = requestMessage.messageId;
                break;
            case "staffing":
                this.staffingEstimatedCostApiCallId = requestMessage.messageId;
                break;
            case "technology":
                this.technologyEstimatedCostApiCallId = requestMessage.messageId;
                break;
        }

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: estimatedPriceEndPoints[endPoint],
            method: configJSON.postApiMethodType,
            body: JSON.stringify(body)
        });
    }

    async componentDidMount() {
        AllFieldKeysToRemove.forEach(k => localStorage.removeItem(k));
        let token = sessionStorage.getItem("token");
        this.setState({
            token: JSON.parse(token as string).token,
            countryList: countries.countries
        });
    }
}
