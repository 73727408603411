import {
    Box,
    Breadcrumbs,
    Typography,
    styled,
    createTheme,
    Theme,
    ThemeProvider, isWidthDown, withWidth, Fab, Link
} from "@material-ui/core";
import SideBarWeb from "./SideBar.web";
import React, { Component, ReactNode } from "react";
import { ContactSupport,Close, NavigateNext as NavigateNextIcon} from '@material-ui/icons';

interface ILink {
    text: string;
    link: string;
}

type IBreadcrumb = {
    links?: ILink[];
    currentPage: ILink;
};

type IProps = {
    children: React.ReactChild;
    title?: string;
    navigation: any;
    breadcrumb?: IBreadcrumb;
    width: Parameters<Theme["breakpoints"]["only"]>[0];
    isDefultCard?: boolean;

};

type IState = {
    theme: Theme,
    openHelp: boolean
};

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "'Inter', sans-serif"
        }
    }
});

class Layout extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            theme: theme,
            openHelp: false
        }
    };
    static defaultProps = {
        isDefultCard: true, 
      };
    handleNavigate = (link: string) => {
        this.props.navigation.navigate(link);
    };
    handleHelpModalClose=()=>{
        this.setState({openHelp: false})
    }

    render(): ReactNode {
        const { children, title, breadcrumb , isDefultCard} = this.props;
        const { theme } = this.state;
        const isMobileView = isWidthDown("sm", this.props.width, true);
        return (
            <ThemeProvider theme={theme}>
                <Box sx={webStyles.mainContainer} style={{backgroundColor: (breadcrumb || (!breadcrumb && title=='')) ? "#E5E5E5" : "#FFF"}}>
                    <SideBarWeb id="id" {...this.props} />
                    <Box className="right-area" sx={{ ...webStyles.rightMainContainer, padding: isMobileView ? webStyles.rightMainContainerMobilePadding.padding : webStyles.rightMainContainerWebPadding.padding }}>
                        {
                            breadcrumb &&
                            <Box className="breadcrumb-container" sx={webStyles.breadcrumbContainer}>
                                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
                                    <StyledTypographyBreadcrumbLink onClick={() => this.handleNavigate("Dashboard")}>
                                        Home
                                    </StyledTypographyBreadcrumbLink>
                                    {
                                        breadcrumb?.links && breadcrumb?.links?.length > 0 &&
                                        breadcrumb?.links.map(d => (
                                            <StyledTypographyBreadcrumbLink onClick={() => this.handleNavigate(d.link)} key={d.link}>
                                                {d.text}
                                            </StyledTypographyBreadcrumbLink>
                                        ))
                                    }
                                    <StyledTypographyBreadcrumb >
                                        {breadcrumb.currentPage.text}
                                    </StyledTypographyBreadcrumb>
                                </Breadcrumbs>
                            </Box>
                        }
                        { isDefultCard ? (
                        <Box sx={webStyles.leftMainContainer}>
                            {
                                title &&
                                <StyledTitleBox>
                                    <StyledTypographyTitle>{title}</StyledTypographyTitle>
                                </StyledTitleBox>
                            }
                            
                            <StyledBox sx={webStyles.fieldMainBox}>
                                {children}
                            </StyledBox>
                        </Box>
                         ):(  
                            <Box sx={webStyles.leftMainContainerDefaultCard}>
                               <>{children}</>
                           </Box>
                           )
                         }

                    </Box>
                    <Fab
                    style={{
                        margin: "0px",
                        right: isMobileView ? "3%" : " 10px",
                        bottom: "10px",
                        position: "fixed",
                        height:'60px',
                        width:'60px',
                        backgroundColor:'#0A0528',
                        zIndex: 99
                    }}
                    onClick={() => {
                    this.setState({
                        openHelp: !this.state.openHelp
                      });
                    }}
                >
                    {this.state.openHelp ? 
                    <Close style={webStyles.iconStyle}/>
                    :
                    <ContactSupport style={webStyles.iconStyle}/>
                    }
                </Fab>{this.state.openHelp && <StyledIframeWrapper>
                <iframe style={{ ...webStyles.dialogChild, width: isMobileView ? webStyles.dialogChildMobile.width : webStyles.dialogChildWeb.width, paddingInline: isMobileView ? webStyles.dialogChildMobile.paddingInline : webStyles.dialogChildWeb.paddingInline,}} src='https://forms.zohopublic.in/sustainextdigitalpvtltd/form/ContactUs/formperma/rSZHJ-rScyNzKFibNtnSflhXXX4bR26U96SXpquV_6Q'></iframe>
                </StyledIframeWrapper>}
                </Box>
            </ThemeProvider>
        )
    }
}

const StyledIframeWrapper = styled(Box)({
        zIndex: 999,
        position:'fixed',
        bottom:'80px',
        height:'85%',
        right:'10px',
        '@media (min-height: 800px)': {
                height:'700px',
        },
        '@media (max-width: 959px)': {
            right:'3%'
    },
});

const webStyles = {
    mainContainer: {
        display: 'flex',
        minWidth: 'min-content',
        width:'100%'
    },
    breadcrumbContainer: {
        margin: "20px 0 38px 0"
    },
    leftMainContainer: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '100%',
        paddingBottom: '24px',
        marginTop: "38px",
    },
    leftMainContainerDefaultCard: {
        minHeight: '908px',
        marginBottom: '24px',
        marginTop: "32px"
    },
    rightMainContainer: {
        borderRadius: '8px',
        flex: 1
    },
    rightMainContainerWebPadding: {
        padding: '0 48px 48px',
    },
    rightMainContainerMobilePadding: {
        padding: '0 16px 16px',
    },
    homeBreadcrumb: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#475569',
        '&.MuiLink-underlineHover:hover': {
            textDecoration: 'none',
        }
    },
    profileBreadcrumb: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#475569',
        '&.MuiLink-underlineHover:hover': {
            textDecoration: 'none',
        }
    },
    onBoardBreadcrumb: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        color: '#0F172A',
    },
    createUserTitleTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    fieldMainBox: {
        padding: '32px'
    },
    breadcrumbIcon: {
        fontSize: "24px"
    },
    iconStyle : {
        height: "40px",
        width: "40px",
        color:'#fff'
    },
    iframeWrapper: {zIndex: 999, position:'fixed', bottom:'80px'},
    dialogChild: { 
        border:'none',
        borderRadius:'8px',
        height:'100%',
    },
    dialogChildMobile: {
        paddingInline: '0px',
        width:'350px',
    },
    dialogChildWeb: {
        paddingInline: '5px',
        width:'400px',
    },
};

const StyledTypographyTitle = styled(Typography)({
    "&": {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "16px"
        }
    }
});

const StyledTypographyBreadcrumb = styled(Typography)({
    "&": {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        color: '#0F172A',
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "16px"
        }
    }
});

const StyledTypographyBreadcrumbLink = styled(Typography)({
    "&": {
        fontSize: '24px',
        color: "#475569",
        cursor: "pointer",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "16px",
            cursor: "pointer"

        }
    }
});

const StyledTitleBox = styled(Box)({
    "&": {
        paddingTop: '24px',
        margin: "0 32px"
    },
    "@media(max-width: 960px)": {
        "&": {
            margin: "20px 16px 0 16px"
        }
    }
});

const StyledBox = styled(Box)({
    "&": {
        padding: "32px"
    },
    "@media(max-width: 960px)": {
        "&": {
            padding: "16px"
        }
    }
});

export default withWidth()(Layout);
