import { Select, MenuItem, styled, withStyles } from "@material-ui/core";
import React, { Component, ReactNode } from "react";

type ClassNameMap<ClassKey extends string = string> = Record<ClassKey, string>;

type IProps = {
    data: any[];
    value: string;
    onChange: (value: string) => void;
    classes: ClassNameMap;
    isLarge?: boolean;
};
type IState = {};

class SelectWithoutLabel extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
    }
    render(): ReactNode {
        const { classes, data, value, onChange, isLarge } = this.props;

        return (
            <StyledSelect
                onChange={(event) => onChange(event.target.value as string)}
                value={value}
                disableUnderline
                displayEmpty
                MenuProps={{
                    classes: { paper: classes.root },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                }}
            >
                {data.map((d) => (
                    <MenuItem disabled={d.value === "Choose"} key={d.value} value={d.value}>{d.label}</MenuItem>
                ))}
            </StyledSelect>
        )
    }
};

const DropdownStyles = {
    root: {
        marginTop: "6px",
        marginLeft: "9px",
        borderRadius: "8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul": {
            padding: "10px",
            minWidth: "230px"
        },
        "& ul li": {
            borderRadius: "8px",
            fontSize: "14px",
            padding: "9px 20px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "rgba(241, 245, 249, 1)"
        },
        "@media(max-width: 960px)": {
            "&" :{
                marginTop:"10px"
            },
            "& ul li": {
                fontSize: "12px",
                padding: "6px 14px",
                minHeight:"32px"
            },
        }
    }
}

const StyledSelect = styled(Select)({
    "&": {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "100%",
        width: "100%",
        fontFamily: "'Inter', sans-serif!important",
        fontSize: "14px!important",
        fontWeight: "500!important",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent"
    },
    "& ul" : {
        backgroundColor:"#000"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px!important",
        },
        "& .menu-wrapper .MuiPaper-root": {
            marginTop: "36px"
        }
    }
});

export default withStyles(DropdownStyles)(SelectWithoutLabel);