// Customizable Area Start
//@ts-nocheck
import React from "react";
import DocumentstorageController, { Props } from "./DocumentstorageController";
import { Box, Button, Chip, Tab, Tabs, Typography, styled, withStyles, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { pdfButtonImg } from "./assets";
import MarketingRequestForm from "./MarketingRequestForm.web";
export const baseURLJSON = require("../../../framework/src/config.js");
import Layout from "../../../components/src/Layout.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {StyledAccordion} from "../../termsconditions/src/AccordionItem.web"
// Customizable Area End

// Customizable Area Start
const AntTabsDeal = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-flexContainer': {
      gap: '32px !important',
      height:"44px"
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
      border: '2px solid transparent',
      height: '44px',
      background: 'linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box',
      borderBottom: 0,
      borderRadius: '8px 8px 0px 0px',
      width: '200px',
    },
    '& .MuiTabs-root': {
        height:"44px"
    }
  });
  
  export const AntTabDeal = styled(Tab)({
    textTransform: 'none',
    minWidth: 0,
    // width: "9.311%",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    zIndex: 999,
    color: '#0F172A',
    minHeight: '44px',
    '&.Mui-selected': {
      color: '#0A0528',
    },
    '& .MuiTab-root': {
        height: '44px'
    }
  });

// Customizable Area End

export class SalesEnablements extends DocumentstorageController {
    constructor(props: Props) {
        super(props);
    }

    renderNoBlog = () =>{
        return <Typography className={`${this.props.classes.noBlogTxt}`}>{this.state.isNoData ? 'No blogs':'Loading...'}</Typography>
    }

    renderCards = (data: any) =>{
        return <a className={`${this.props.classes.anchorStyle}`} key={data.i} href={data?.attributes?.attachment_url ? baseURLJSON.baseURL + data?.attributes?.attachment_url: null} target="_blank"
                                        rel="noreferrer">  
                                        <Box key={data.i} className={`${ this.state.isEdge ? this.props.classes.edgeCardContainer : this.props.classes.cardContainer}`}>
                                            <Box>
                                                <img src={pdfButtonImg} className={`${this.props.classes.cardBodypdfImg}`} alt="" />
                                                <Box className={`${this.props.classes.cardMainBoxImge}`}>
                                                    {data?.attributes?.image_url &&  
                                                        <>
                                                            <img src={baseURLJSON.baseURL + data?.attributes?.image_url} alt="card" />
                                                            <Chip className={`${this.props.classes.chipButton}`} label={data?.attributes?.category_name} />
                                                        </>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box style={{ marginTop: '24px' }}>
                                                <Box>
                                                    <Typography className={`${this.props.classes.titletxt}`}>{data?.attributes?.headline}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className={`${this.props.classes.descriptiontxt}`}>{data?.attributes?.content}</Typography>
                                                </Box>
                                            </Box>
                </Box></a>
    }

    renderAccordian = (i:number,item:any)=> {
        const {classes} =this.props
        const { openAccordionIndex } = this.state
        return <StyledAccordion 
                data-test-id="accordion-toggle"  
                className="accordion-container accordion-container-se" 
                defaultExpanded={i === 0}
                expanded={openAccordionIndex === i}
                onChange={() => this.handleAccordionChange(i)}
                >   
                <AccordionSummary className="accordion-title" expandIcon={<ExpandMoreIcon />}>
                    <Typography>{item?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={`${classes.cardMainContainer}`}>
                        <Box className={`${classes.cardSubMainContainer}`} style={{width:"100%"}}>
                            <Box className={`${classes.cardMainContainer}`} >
                                {item?.contents?.data.map((data, i) => (this.renderCards(data)))}
                                <Box  className={`${classes.viewMoreWrapperBox}`}>
                                    {(item?.is_view_more) && 
                                        <Button onClick={()=>{ this.viewMoreSubCategory(item?.sub_category_id)}} data-test-id="viewMoreSubCategoryID" className={`${classes.viewMoreBtn}`}>
                                            <Typography className={`${classes.viewMoreTxt}`}>
                                                View More
                                            </Typography>
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </AccordionDetails>
        </StyledAccordion>   
    }

    render() {
        // Customizable Area Start 
        const { classes } = this.props
        // Customizable Area End

        return (
            // Customizable Area Start 
            <Layout
                navigation={this.props.navigation}
                title={""} 
                isDefultCard={false}
                >
                <Box className={`${classes.adminleftSideContainer}`}>
                    <Box className={`${classes.HeaderImage}`}>
                        <Box style={{ position: 'absolute', right:"24px" }}>
                            <Typography className={classes.hadeingTxt}>Sales Enablement Dashboard</Typography>
                            {/* <Typography className={classes.descriptionTxt}>Get information, read customer stories, and events.</Typography> */}
                        </Box>
                        <Box className={classes.headerBgImg}>
                            <img src={this.handelChangeImage()} alt="" />
                        </Box>
                    </Box>
                    <Box className={`${classes.bottomMainContainer}`}>
                    {this.state.valueA == 5 ? (
                                <MarketingRequestForm {...this.props} />
                            ) : (<>
                        <Box>
                            <Box sx={{ bgcolor: '#fff', padding: '32px 32px 0 32px', borderRadius: '8px' }}>
                                <AntTabsDeal variant="scrollable" scrollButtons={false} aria-label="scrollable prevent tabs example" value={this.state.valueA} data-test-id="adminCompanytabs" onChange={this.handleTabChange}>
                                    <AntTabDeal style={{minWidth: "9.311%"}} disableRipple label="All" />
                                    <AntTabDeal style={{minWidth: "19.656%"}} disableRipple label="Corporate Presentation" />
                                    <AntTabDeal style={{minWidth: "17.242%"}} disableRipple label="Sales Presentations" />
                                    <AntTabDeal style={{minWidth:"15.345%"}} disableRipple label="Digital Marketing" />
                                    <AntTabDeal style={{minWidth:'10.949%'}} disableRipple label="Resources" />
                                </AntTabsDeal>
                            </Box>
                        </Box>
                        <Box style={{ margin: '32px', paddingBottom: '32px' }}>
                            {(this.state.valueA !== 0) ? 
                                 <Box className={`${classes.accordionMainBox}`} >
                                    {(this.state.filteredList && this.state.filteredList.length>0) ? this.state.filteredList.map((item, i) => (
                                        <Box key={i} className={`${classes.accordionBox}`}>
                                            { item.name  && this.renderAccordian(i,item)}
                                        </Box>
                                        
                                    ))  : <Box style={{width:"100%"}}>{this.renderNoBlog()}</Box>}
                                </Box> :
                            <Box>
                                <Box className={`${classes.cardMainContainer}`} >
                                     {(this.state.filteredList && this.state.filteredList.length>0) ? 
                                     this.state.filteredList.map((item, i) => (this.renderCards(item))) : <Box style={{width:"100%"}}>{this.renderNoBlog()}</Box>}
                                </Box> 
                                <Box  className={`${classes.viewMoreWrapperBox}`}>
                                    {(this.state.viewMore && this.state.filteredList.length>0) && 
                                        <Box className={`${classes.viewMoreWrapper}`}>
                                            <Button onClick={this.viewMoreCategory} className={`${classes.viewMoreBtn}`}>
                                                <Typography className={`${classes.viewMoreTxt}`}>
                                                    View More
                                                </Typography>
                                            </Button>
                                            
                                        </Box>
                                    }   
                                </Box>
                                
                            </Box>}
                                
                        </Box>
                        </>
                        )}
                    </Box>
                </Box>
            </Layout>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export const SalesEnablementsStyle = (theme: any) => ({
    salesBoxContainer: {
        display: 'flex',
        gap: '48px',
        background: '#E5E5E5'
    },
    adminleftSideContainer: {
        width: '100%',
        paddingTop: '32px',
        backgroundColor:'#E5E5E5',
    },
    "@media (max-width:601px)": {
        adminleftSideContainer: {
            width: "448px",
        }
    },
    "@media (max-width:500px)": {
        adminleftSideContainer: {
            width: "375px",
        }
    },
    HeaderImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative'
    },
    hadeingTxt: {
        fontSize: '36px',
        fontWeight: '500',
        fontFamily: 'Inter',
        color: '#fff',
        marginTop: "4rem"
    },
    descriptionTxt: {
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Inter',
        color: '#fff'
    },
    bottomMainContainer: {
        backgroundColor: '#fff',
        height: 'auto',
        borderRadius: '8px',
    },
    cardContainer: {
        position: 'relative',
        width: '299px',
    },
    edgeCardContainer: {
        position: 'relative',
        width: '281px',
    },
    "@media only screen and (min-width: 1100px) and (max-width: 1200px)": {
        body: {
          zoom: "110%"
        },
        edgeCardContainer: {
            position: 'relative',
            width: '245px',
        },
        cardContainer: {
            position: 'relative',
            width: '260px',
        },
    },
    cardBodypdfImg: {
        position: 'absolute',
        zIndex: 1,
        left: '15px',
        top: '10px'
    },
    anchorStyle:{
        textDecoration: "none"
    },
    headingWrapper:{
        marginBottom: '15px',
        justifyContent: 'space-between',
        display: 'flex'
    },
    heading : {
        fontFamily: 'Inter',
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: '26px',
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    noBlogTxt:{
        justifyContent: 'center',
        display: 'flex',
        fontSize: '16px',
        width: '100%',
        fontWeight: 500
    },
    viewAllTxt :{
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#2E17B5'
    },
    cardMainContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
        gap: '32px',
        width:'100%',
        // [theme.breakpoints.down("sm")]: {
        //     justifyContent: 'space-between',
        // },
    },
    cardSubMainContainer:{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "26px",
        // justifyContent: 'center',
    },
    viewMoreWrapperBox:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%"
    },
    accordionBox:{
        width:"100%"
    },
    accordionMainBox:{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
    },
    "@media (min-width:1280px) and (max-width:1295px)": {
        cardMainContainer: {
            gap: "22px"
        }
    },
    cardMainBoxImge: {
        width: '100%',
        height: '180px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        }
    },
    chipButton: {
        position: 'absolute',
        top: '144px',
        left: '7px',
        background: '#2E17B5',
        color: '#fff',
        height:"26px",
        fontSize: "12px",
        fontWeight: '400'
    },
    titletxt: {
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: 'Inter',
        color: '#475569',
        marginBottom: '16px',
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical"
    },
    descriptiontxt: {
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'Inter',
        color: '#475569',
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    viewMoreBtn : {
        width: "116px",
        height: "44px",
        padding: "6px",
        borderRadius: "8px",
        border: "1px solid #364161",
        gap: "8px",
    },
    viewMoreTxt: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: "#364161",
        textTransform:'none'
    },
    viewMoreWrapper: {
        width: "100%",
        height: '44px',
        gap: '10px',
        textAlign: 'center',
        marginTop: '25px'
    },
    headerBgImg: {
        width: '100%',
        height: '347px',
        '& img': {
            width: '100%',
            height: '89.34%',
            borderRadius: '8px',
        }
    },
    mainmarketRequest: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        marginBottom: '15px',
        color:'#0A0528'
    },
    TextAreainputMain: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        width: "100%",
        height:'200px !important',
        fontFamily: "'Inter', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
        color: '#64748B',
        outline: 'none',
        '&::placeholder': {
            color: '#94A3B8',
        },
        '&:focus': {
            border: "1px solid #dfdfdf",
        },
        '&:focus-visible': {
            border: "1px solid #dfdfdf",
            outline: 'none'
        }
    },
    inputMain: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        color:"#64748B",
        '& ::-webkit-calendar-picker-indicator':{
            filter: "invert(46%) sepia(8%) saturate(1108%) hue-rotate(176deg) brightness(94%) contrast(89%)"
        }
    },
    emailadminLabelText: {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },

    dragging: {
        // backgroundColor: theme.colors.gray[0],
    },
    
    dragAndDropTitle: {
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Inter',
        color: '#64748B',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: 'center',
        width: '229px',
        lineHeight: '22px'
    },
    uploadButton: {
        color: '#2E17B5',
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Inter',
    },
    imagePreview: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: '20px',
        border: `3px dashed red`,
        borderRadius: '8px',
        marginBottom: '10px',
        height: "100%",
    },
    dragAndDropAfterContainer: {
        fontSize: '18px',
        fontWeight: 600,
        color: 'red',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: '30px',
        width: "50%",
    },
    dragAndGropText: {
        overflowX:"auto",
        scrollbarWidth: "none",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        '& img': {
            width: '40px',
            height: '40px'
        }
    },
    progressBar: {
        '& .mantine-Progress-bar': {
            backgroundColor: 'red',
        },
            backgroundColor: `red !important`
    },
    uplodaedFileName: {
        color: 'red',
        fontSize: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 500,
        marginBottom: '10px',
        '& img': {
            width: '40px'
        }
    },
    fileUploadError: {
        color: 'red',
        fontSize: '15px',
        fontWeight: 500,
        marginBottom: '10px',
    },
    filePreviewDesign: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Inter'
    },
    filePreviewName: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Inter',
        overflow: 'hidden',
        width: '85%',
        textOverflow: 'ellipsis'
    },
    filePreviewSize: {
        color: '#64748B',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Inter',
    },
    previewFileMainBox: {
        display: 'flex',
        border: '1px solid #E2E8F0',
        padding: '10px',
        gap: '16px',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBlock: '15px',
        width: "92.273%",
        borderRadius: '8px',
    },
    savebuttonText: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: "700",
        textTransform: "capitalize",
        borderRadius: "8px",
        height: '44px',
        width:'88px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
    },
    cancelbuttonText: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #364161',
        height: '44px',
        width:'87px',
        padding: '10px 16px',
        color:"#364161",
        textTransform: 'none',
        fontSize:'16px',
        fontWeight: '700'
    },
    descriptionLimit: {
        textAlign: "right",
        display: "block",
        fontSize: '12px',
        color: "#334155"
    },
    errortxt: {
        color: 'red',
        fontSize: '14px'
    },
    descriptionError : {
        display:"flex",
        width:"100%"
    },
    closeimg: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
    },
    successImgStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',
    },
    countinueBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
    },
    marketContinueBtn: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '104px',
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },
    marketModalContainer: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '441px',
        height: '312px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        padding: '16px 17px'
    },
    marketCenterTxt: {
        display: 'flex',
        justifyCOntent: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
    },
    marketSuccessTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
        color: '#0A0528',
    },
    marketDescriptionModalTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        color: '#64748B',
        marginTop: '12px',
        textAlign: 'center'
    },
})

//@ts-ignore
export default withStyles(SalesEnablementsStyle)(SalesEnablements);
// Customizable Area End