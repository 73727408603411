// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Box, Button, Modal, Typography, withStyles } from "@material-ui/core";
// Customizable Area End

interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    classes: any;
    userActivInActive: boolean;
    open: boolean;
    handleClose: () => void;
    handleCloseClick: () => void;
    handleShowToastlick: () => void;
    // Customizable Area End
}

// Customizable Area Start
interface S {
    // Customizable Area Start
    open: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

class OnBoardActiveInActiveUser extends BlockComponent<Props, S, SS> {

    static instance: OnBoardActiveInActiveUser;
    // Customizable Area Start
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        OnBoardActiveInActiveUser.instance = this;

        this.state = {
            // Customizable Area Start
            open: false,
        };

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, userActivInActive, open, handleClose, handleCloseClick, handleShowToastlick } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Modal
                open={open}
                {...handleClose}
                data-test-id='closemodal'
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={`${classes.modalContainer}`}>
                    <Box className={`${classes.closeimg}`} data-test-is='closeMOdalBtn' {...handleCloseClick}>
                        <img src={require("./close.svg")} alt="" />
                    </Box>
                    <Box className={`${classes.centerTxt}`}>
                        <Typography className={`${classes.descriptionTxt}`}>Are you sure you want to {userActivInActive ? 'Deactivate' : 'Activate'} this user?</Typography>
                    </Box>
                    <Box className={`${classes.cpuntinueBtnContainer}`}>
                        <Button className={`${classes.continueBtnYes}`} data-test-id='countinueCilckBtn' {...handleShowToastlick}>yes</Button>
                        <Button className={`${classes.continueBtn}`} data-test-id='countinueCilckBtn' {...handleCloseClick}>No</Button>
                    </Box>
                </Box>
            </Modal>
            // Customizable Area End
        )
    }

}

// Customizable Area Start
export const OnBoardActiveInActiveUserStyles = (theme: any) => ({
    modalContainer: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '441px',
        height: '236px',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
    },
    centerTxt: {
        display: 'flex',
        justifyCOntent: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
    },
    successTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
        color: '#0A0528',
    },
    descriptionTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        color: '#0A0528',
        margin: '12px 51px',
        textAlign: 'center',
    },
    closeimg: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
        paddingRight: "11px",
        paddingTop: "6px",
        "& img":{
            height: "24px"
        }
    },
    cpuntinueBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
        gap: '16px'
    },
    continueBtn: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '54px !important',
        minWidth: "54px",
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },
    continueBtnYes: {
        background: '#FFFFFF',
        borderRadius: '8px',
        minWidth: "61px",
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },
    
})

//@ts-ignore
export default withStyles(OnBoardActiveInActiveUserStyles)(OnBoardActiveInActiveUser);

// Customizable Area End