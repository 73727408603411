import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IDateFilterValue, IRegisteredOpportunity } from "./types";

// Customizable Area Start
type ISorting = "asc" | "desc" | null
import { Message } from "../../../framework/src/Message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { toast } from "react-toastify";
import createRequestMessage from "../../../framework/src/create-request-message";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { dateFormatOppAllList, formatString } from "../../cfpipelinemanagement/src/constants";

const ErrorToastStyles = {
    toast: {
        width: 'max-content',
        maxHeight: '42px !important',   
        minHeight: '42px',
        color: '#0A0528',
        borderRadius: '8px',
        fontSize: '16px'
    }
}

const ErrorToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    style: ErrorToastStyles.toast,
    hideProgressBar: true,
    closeButton: false
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isLoading:boolean;
    opportunityList:IRegisteredOpportunity[];
    opportunity_name:ISorting;
    status: ISorting;
    expected_revenue: ISorting;
    created_date: ISorting;
    close_date: ISorting;
    dateFilterValue: IDateFilterValue;
    searchValue: string;
    currentPage: number;
    shownItems: string | any;
    numberOfAllItems: number; 
    totalPageNumber: number;
    listSearchValue:string;
    listSearchValueError:string | boolean;
    numberOfButtons:string |number;
    fromDate:string;
    toDate:string;
    pageNumberSet:any;
    pagneNumberValidation:boolean;
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class StatisticsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    OpportunityListApiCallId:string | null;
    exportsListApiCallId: string | null;
    isFilterActivate:boolean;
    timeout: ReturnType<typeof setTimeout>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading:false,
            opportunityList:[],
            opportunity_name:null,
            created_date:null,
            expected_revenue:null,
            close_date:null,
            status: null,
            dateFilterValue:"View All",
            searchValue: "",
            currentPage: 1,
            shownItems: "10",
            numberOfAllItems: 10,
            totalPageNumber: 2,
            listSearchValue:"",
            listSearchValueError:"",
            numberOfButtons:2,
            fromDate:'',
            toDate:'',
            pageNumberSet:1,
            pagneNumberValidation:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.timeout = setTimeout(() => { });
        this.isFilterActivate =false
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(_from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.OpportunityListApiCallId: {
                    this.OpportunityListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                          setTimeout(() => {
                            this.setState({
                                isLoading: false,
                                opportunityList: responseJson?.data,
                                totalPageNumber:responseJson?.meta.total_opportunity_count  
                            });
                              this.handleNumberOfButtonST()
                          }, 2000); 
                        },
                        onFail: () => {
                            toast.error(`${responseJson?.meta?.message}`, ErrorToastOptions)
                            this.stopLoading();
                        },
                    });
                    break;
                }
                case this.exportsListApiCallId: {
                    this.exportsListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            const finalArray: (string|number)[][] = []
                            responseJson.data.map((item: IRegisteredOpportunity) => {
                                let arr:any[] = []
                                if( item.attributes.opportunity_id && 
                                    item.attributes.opportunity_name && 
                                    item.attributes.status  && 
                                    item.attributes.stage && 
                                    item.attributes.expected_revenue  && 
                                    item.attributes.created_at && 
                                    item.attributes.closing_date ) 
                                {   
                                    arr.push(item.attributes.opportunity_id, 
                                        item.attributes.opportunity_name, 
                                        formatString(item?.attributes?.status), 
                                        formatString(item.attributes.stage), 
                                        item.attributes.currency_symbol + " " + item.attributes.expected_revenue, 
                                        dateFormatOppAllList(item.attributes.created_at,false), 
                                        dateFormatOppAllList (item.attributes.closing_date,true) 
                                    )
                                    finalArray.push(arr)
                                }
                                
                            })
                            const doc = new jsPDF()
                            autoTable(doc, {
                            head: [['Opportunity Id', 'Opportunity Name', 'Status','Stage','Expected Revenue','Created On','Closed On' ]],
                            body: finalArray,
                            })
                        
                            doc.save('Statistics & Reports.pdf');
                            this.stopLoading();
                        },
                        onFail: () => {
                            toast.error(`${responseJson?.meta?.message}`, ErrorToastOptions)
                            this.stopLoading();
                        },
                    });
                    break;
                }
            }
        }
    }
    handleDateFilter = (value: IDateFilterValue) => {
        this.setState({dateFilterValue:value},()=> {
            if(value === "between"){
                const currentYear = new Date().getFullYear();
                const fromDate = `${currentYear}-01-01`;
                const toDate = `${currentYear}-12-31`;
                this.setState({fromDate:fromDate, toDate:toDate},()=>{
                    this.handleGetFilterData()
                    this.isFilterActivate=true
                    if(this.state.dateFilterValue === "View All"){
                        this.isFilterActivate =false
                        if(this.isFilterActivate === false){
                            this.handleGetOpportunityList()
                        }                   
                    }    
                })
            }
            else {
                this.handleGetFilterData()
                this.isFilterActivate=true
                if(this.state.dateFilterValue === "View All"){
                    this.isFilterActivate=false
                    if(this.isFilterActivate === false){
                        this.handleGetOpportunityList()
                    }
                }    
            }
              
        })
        
    }
    handleGetFilterData = () => {
        this.setState({ isLoading: true})
        const header = {
            token: this.handleGetTokenST(),
        };
        let filterObj = {}
       
        if(this.state.dateFilterValue === "between"){
            filterObj = {
                "created_at": {
                    "operator": this.state.dateFilterValue,
                    "from":this.state.fromDate,
                    "to":this.state.toDate
                }
            }
        } else if(this.state.dateFilterValue === "this_month" || this.state.dateFilterValue === "today") {
            filterObj = {
                "created_at": {
                    "operator": this.state.dateFilterValue
                }
            }
        }
        const OppBodyType: { 
            type: string; 
            page:  number;
            per_page: number | string;
            filters?: any 
        } = {
          type: "opportunity",
          page: this.state.currentPage,
          per_page: this.state.shownItems,
          filters: filterObj
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
      
        this.OpportunityListApiCallId = requestMessage.messageId;
        const endPointType = configJSON.opportunityListEndPoint
    
         createRequestMessage({
          header,
          requestMessage: requestMessage,
          endPoint:endPointType ,
          method: configJSON.dashboarApiMethodTypePost,
          body: JSON.stringify(OppBodyType)
        });
    }

    checkSorting = (sortingType: keyof S) => {
        let updateSorting:ISorting = "asc"
        let currentState = this.state[sortingType]
        
        if(currentState === "asc"){
            updateSorting = "desc";
        }
        return updateSorting
    }

    handleSortingST = (sortingType:any) => {
        this.setState({[sortingType]: this.checkSorting(sortingType) } as unknown as Pick<S, keyof S>)
        this.getSortedDataST(sortingType,this.checkSorting(sortingType))
    }

    getSortedDataST = (sortBy:string,sortOrder:string | null) => {
        const header = {
            token: this.handleGetTokenST(),
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.OpportunityListApiCallId = requestMessage.messageId;
  
        const oppBodyType: {
          sort: object | any;
          type: string;
          page: number;
          per_page: number;
          filters ? : any;
      } = {
          sort: {
              column: sortBy,
              order: sortOrder,
          },
          type: "opportunity",
          page: this.state.currentPage,
          per_page: this.state.shownItems,
      };
   
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.opportunityListEndPoint,
            method: configJSON.dashboarApiMethodTypePost,
            body: JSON.stringify(oppBodyType)
        });
    };
    
    handleGetTokenST= ()=> {
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data);
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            }
            else {
                throw error;
            }
        }
        return  id?.token 
    }
    handleGetOpportunityList = ( ) => {
        this.setState({ isLoading: true})
        const header = {
            token: this.handleGetTokenST(),
        };
        let OppBodyType: {
            type: "opportunity";
            page: number;
            per_page: number | string;
            filters?: any;
          } = {
            type: "opportunity",
            page: this.state.currentPage,
            per_page: this.state.shownItems,
          };

          if (this.isFilterActivate === true) {
            OppBodyType = {
              type: "opportunity",
              page: this.state.currentPage,
              per_page: this.state.shownItems,
              filters: {
                "created_at": {
                  "operator": this.state.dateFilterValue,
                  "from": this.state.fromDate,
                  "to": this.state.toDate,
                },
              },
            };
          }
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
      
        this.OpportunityListApiCallId = requestMessage.messageId;
        const endPointType = configJSON.opportunityListEndPoint
    
         createRequestMessage({
          header,
          requestMessage: requestMessage,
          endPoint:endPointType ,
          method: configJSON.dashboarApiMethodTypePost,
          body: JSON.stringify(OppBodyType)
        });
    }

    handleBlurEventST = () => {
        this.setState({
           listSearchValueError: "",
        })
        
    }
    handleSearchST = (value: string) => {
        let filteredText = value.replace(/^\s+/, '').replace(/\s+/g, ' ');
        this.setState({listSearchValue :filteredText ,
           listSearchValueError :filteredText !== value ? true : false,
        })
        
        let condition = filteredText !== value ? true : false
        if ( condition   )  return 
    
        this.setState({listSearchValue :filteredText ,
          isLoading:true ,
          currentPage:1,shownItems:"10",
        })
    
        if (this.timeout ) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const header = {
                token: this.handleGetTokenST(),
            };
    
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
          );
          this.OpportunityListApiCallId = requestMessage.messageId;
          const searchBody: {
            search: object | any;
            type: string;
            page: number;
            per_page: number | string;
        } = {
            search: {
                query: filteredText.trim(),
            },
            type: "opportunity",
            page: this.state.currentPage,
            per_page: this.state.shownItems,
        };

        createRequestMessage({
              header,
              requestMessage: requestMessage,
              endPoint: configJSON.opportunityListEndPoint,
              method: configJSON.dashboarApiMethodTypePost,
              body: JSON.stringify(searchBody)
          });
        }, 1000)
      }
    handleNumberOfButtonST = () => {
        const { totalPageNumber ,shownItems } = this.state;
        const numberOfButtons = Math.ceil(totalPageNumber / shownItems);
        this.setState({ numberOfButtons });
    };

    handlePaginationST = (event:any) => {
        const perpage = event;
        this.setState({ shownItems: perpage, pageNumberSet:1, currentPage:1, isLoading: true }, () => {
          this.handleGetOpportunityList()
        })
    };
    handlePaginationButtonST = (value:number) => {
        if(value < 1 || value > this.state.totalPageNumber) return
        this.setState({currentPage:value, pageNumberSet:value},()=>this.handleGetOpportunityList())
    }

    handleExport = () => {
        const header = {
            token: this.handleGetTokenST(),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
        this.exportsListApiCallId = requestMessage.messageId;
        const searchBody: {
            type: string;
            page: number;
            per_page: number | string;
            download:boolean;
        } = {
            type: "opportunity",
            page: this.state.currentPage,
            per_page: this.state.opportunityList?.length,
            download:true
        };
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.opportunityListEndPoint,
            method: configJSON.dashboarApiMethodTypePost,
            body: JSON.stringify(searchBody)
        });
    }
    
    handlePageNumberInputST = (event: any) => {
        const { numberOfButtons } = this.state;
        const inputValue = event.target.value.replace(/\D/g, ""); 
        const isWithinRange = inputValue !== null && inputValue > 0 && inputValue <= numberOfButtons;
        this.setState(
          {
            pageNumberSet: inputValue,
            pagneNumberValidation: !isWithinRange, 
          },
          () => {
            this.handleGetPageByInputNumberST();
          }
        );
    };

    handleGetPageByInputNumberST = () => {
        const { numberOfButtons ,pagneNumberValidation } = this.state;
        const inputValue = this.state.pageNumberSet;
        
        setTimeout(() => {
            if ( !pagneNumberValidation &&  
                this.state.pageNumberSet  !== null && 
                this.state.pageNumberSet  > 0 && 
                this.state.pageNumberSet <= numberOfButtons && this.state.pageNumberSet === inputValue ) {
                this.setState(
                    {
                        currentPage: inputValue,
                    },
                    () => {
                        this.handleGetOpportunityList();
                    }
                );
            } 
        },1500)
    };

    startLoading = () => {
        this.setState({isLoading:true})
    }
    stopLoading = () => {
        this.setState({isLoading:false})
    }
    async componentDidMount ()  {
        this.handleGetOpportunityList()  
    }
    // Customizable Area End
}