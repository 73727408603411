// Customizable Area Start
import { Box, Typography, styled, Button } from "@material-ui/core";
import DashboardAnalyticsController, {
  Props, configJSON
} from "./DashboardAnalyticsController.web";
import React from "react";
import LayoutWeb from "../../../components/src/Layout.web";
import { chevronRightIcon, notificationsIcon } from "./assets";
import StatisticCard from "./StatisticCard.web";
import RateCard from "./RateCard.web";
import OpportunitiesByRevenueCard from "../../../components/src/OpportunitiesByRevenueCard.web";
import OpportunitiesCard from "../../../components/src/OpportunitiesCard.web.";
import RegisteredOpportunitiesCard from "./RegisteredOpportunitiesCard.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css"

const statisticCards = [
  {
    id: "1",
    label: "Revenue Won",
    value: "$634.92K",
    percentage: "15",
    isIncreasing: true
  },
  {
    id: "2",
    label: "Average Deal Value",
    value: "$6.92K",
    percentage: "10",
    isIncreasing: false
  },
  {
    id: "3",
    label: "Opportunities",
    value: "15K+",
    percentage: "20",
    isIncreasing: true
  }
];
// Customizable Area End

export default class DashboardAnalytics extends DashboardAnalyticsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const {dateRangeValue, isLoading, analyticsCardsData,registeredOpportunities } = this.state
    const topTenRevenueLabel = analyticsCardsData?.top_10?.top_10_revenue.map( (items:any)=> {
      return items.owner_name
    } )
    const topTenRevenueAmount = analyticsCardsData?.top_10?.top_10_revenue.map( (items:any)=> {
      return items.revenue
    } )
    // Customizable Area End

    return (
      // Customizable Area Start
      <StyledBox>
        <LayoutWeb
          title=""
          isDefultCard={false}
          navigation={this.props.navigation}
        >
        { isLoading ? (
            <Box className="globle-loader" >
                <CircularProgress />
            </Box>
          ) : (
            <>
            <Box className="header-container">
              <Box className="filter-wrapper">
                <Typography className="header-title">Analytics</Typography>
                <Box className="filter-container">
                  <Typography className="filter-title">
                    {dateRangeValue}
                  </Typography>
                  <DateRangePicker
                    data-test-id="dashboard-date-rang"
                    toggleAs={()=><Button data-test-id="dashboard-date-range-button" className="dashboard-date-filter-button"><img src={chevronRightIcon} alt="calendar-filter" /></Button>}
                    showOneCalendar
                    ranges={[]}
                    placement="bottomEnd"
                    onOk={(value)=>this.handleDateRangeSelect(value)}
                    locale={configJSON.datePickerLocale}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="cards-container">
              <Box className="statistic-cards-container">
                <StatisticCard
                    label={"Revenue Won"}
                    value={analyticsCardsData?.revenue_won?.revenue_won_count}
                    percentage={analyticsCardsData?.revenue_won?.change_percentage}
                    isIncreasing={true}
                  />
                  <StatisticCard
                    label={"Average Deal Value"}
                    value={analyticsCardsData?.average_deal_value?.average_deal_value_count}
                    percentage={analyticsCardsData?.average_deal_value?.change_percentage}
                    isIncreasing={false}
                  />
                  <StatisticCard
                    label={"Opportunities"}
                    value={analyticsCardsData?.opportunities?.opportunities_count}
                    percentage={analyticsCardsData?.opportunities?.change_percentage}
                    isIncreasing={true}
                  />
                <OpportunitiesCard  opportunitiesCircle={analyticsCardsData?.opportunities_circle} />
              </Box>
              <Box className="graphs-container">
                <Box className="circular-progress-container">
                  <RateCard
                    title="Opportunities Conversion Rate"
                    graphValue={analyticsCardsData?.opportunities_conversion_rate?.conversion_percentage}
                    from="#C399FF"
                    to="#6200EA"
                  />
                  <RateCard
                    title="Win Rate"
                    graphValue={analyticsCardsData?.win_rate?.win_percentage}
                    from="#00AEEF"
                    to="#6ADF23"
                  />
                </Box>
                <Box className="opportunities-by-revenue-container">
                  <OpportunitiesByRevenueCard 
                    top10={analyticsCardsData?.top_10} 
                    labels={topTenRevenueLabel} 
                    amounts={topTenRevenueAmount}
                  />
                </Box>
              </Box>
            </Box>
            <RegisteredOpportunitiesCard  registerOppTableData={registeredOpportunities} navigation={this.props.navigation} />
          </>
          )}
        </LayoutWeb>
      </StyledBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledBox = styled(Box)({
  "& .right-area": {
    backgroundColor: "#fff",
    padding: 0,
    maxWidth: "calc(100vw - 120px)",
    "&>div": {
      marginTop: 0
    }
  },
  "& .header-container": {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 48px",
    "& .header-title": {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.005em"
    },
    "& .filter-container": {
      display: "flex",
      alignItems: "center",
      "& .filter-title": {
        color: "#475569",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px"
      },
      "& img": {
        transform: "rotate(90deg)"
      }
    },
    "& .dashboard-date-filter-button":{
      color: 'rgba(0, 0, 0, 0.87)',
      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: '23px',
      boxSizing: 'border-box',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: '4px',
      letterSpacing: '0.02857em',
      textTransform: 'uppercase'
    },
    "& .filter-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: "16px"
    }
  },
  "& .cards-container": {
    display: "flex",
    gap: "24px",
    padding: "0px 48px",
    width: "100%"
  },
  "& .statistic-cards-container": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "calc(33.018% - 12px)",
    maxWidth: "592px"
  },
  "& .circular-progress-container": {
    display: "flex",
    gap: "24px",
    width: "100%",
    marginBottom: "4px",
    "&>div": {
      width: "50%"
    },
    "& text": {
      fontSize: "11px!important"
    }
  },
  "& .graphs-container": {
    width: "calc(66.994% - 12px)",
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    maxWidth: "808px"
  },
  "& .opportunities-by-revenue-container": {
    width: "100%",
    maxHeight: "405px",
    "&>div": {
      boxSizing: "content-box"
    }
  },
  "& .globle-loader":{
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    marginTop: "10px",
    alignItems: "center",
    height:"70%"

},
  "@media(max-width: 1200px)": {
    "& .cards-container": {
      flexDirection: "column"
    },
    "& .statistic-cards-container, & .graphs-container": {
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    "& .statistic-cards-container>div": {
      width: "calc(50% - 12px)"
    },
    "& .circular-progress-container": {
      marginBottom: 0
    }
  },
  "@media(max-width: 960px)": {
    "& .header-container": {
      padding: "18px 24px"
    },
    "& .cards-container,& .registered-opportunities-container": {
      padding: "0 24px"
    }
  },
  "@media(max-width: 768px)": {
    "& .statistic-cards-container>div": {
      width: "100%"
    },
    "& .circular-progress-container": {
      flexDirection: "column",
      "&>div": {
        width: "100%!important"
      }
    }
  },
  "@media(max-width: 576px)": {
    "& .header-container": {
      padding: "10px 12px",
      "& .header-title": {
        fontSize: "18px!important"
      },
      "& .filter-wrapper": {
        flexDirection: "column",
        alignItems: "flex-start!important"
      },
      "& .filter-container": {
        "& .filter-title": {
          fontSize: "13px!important"
        }
      }
    },
    "& .cards-container,& .registered-opportunities-container": {
      padding: "0 12px"
    }
  }
});
// Customizable Area End
