import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IClientNameSorting, IDateSorting, IStatus, ISaveClientBody, IclientRole } from "./types";
import createRequestMessage from "../../../framework/src/create-request-message";
import { Message } from "../../../framework/src/Message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { toast } from "react-toastify";
import { formatString } from "../../cfpipelinemanagement/src/constants";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    isRangePickerOpen: boolean;
    statusList:IStatus[];
    currentPage:number;
    shownItems:string;
    numberOfAllItems:number;
    totalPageNumber:number;
    isAllChecked: boolean;
    sortingClientName: IClientNameSorting;
    sortingDate: IDateSorting;
    clientNameAnchorEl: HTMLElement | null;
    dateAnchorEl: HTMLElement | null;
    searchValue: string;
    dateFilterValue: string[];
    isLoading: boolean;
    acceptRejectIds: string[];
    csvArray:any[];
    csvArrayHeaders:any[];
    // Customizable Area End
}

const ErrorToastStyles = {
    toast: {
        width: 'max-content',
        maxHeight: '42px !important',   
        minHeight: '42px',
        color: '#0A0528',
        borderRadius: '8px',
        fontSize: '16px'
    }
}

const ErrorToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    style: ErrorToastStyles.toast,
    hideProgressBar: true,
    closeButton: false
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CommissionStatusController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    statusListApiCallId: string | null;
    downloadListApiCallId: string | null;
    acceptRejectCommID: string | null;
    timeout: ReturnType<typeof setTimeout>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            isRangePickerOpen: false,
            statusList: [],
            currentPage: 1,
            shownItems:"10",
            numberOfAllItems:0,
            totalPageNumber:0,
            isAllChecked:false,
            sortingClientName: null,
            clientNameAnchorEl: null,
            sortingDate:null,
            dateAnchorEl: null,
            searchValue:"",
            dateFilterValue:[],
            isLoading: true,
            acceptRejectIds:[],
            csvArray:[],
            csvArrayHeaders:[]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        this.statusListApiCallId = "";
        this.downloadListApiCallId = "";
        this.timeout = setTimeout(() => { });
        this.acceptRejectCommID="";
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            if (apiRequestCallId) {
                if (this.statusListApiCallId) {
                    this.statusListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({statusList:responseJson.data.map((item:IStatus)=> { return {...item,checked:false} }),numberOfAllItems:responseJson.meta.total_incomes_count,totalPageNumber:responseJson.meta.total_page},()=>{
                                this.getCVSData(responseJson.meta.pdf_records)
                                this.stopLoading()
                            })
                        },
                        onFail: () => {
                            this.showAlert(`${responseJson.errors}`, "Please retry!");
                            this.stopLoading();
                        },
                    });
                }
                else if (this.downloadListApiCallId) {
                    this.downloadListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                    },
                    onFail: () => {
                        this.showAlert(`${responseJson.errors}`, "Please retry!")
                        this.stopLoading();
                    },
                    });
                } else if (this.acceptRejectCommID) {
                    this.acceptRejectCommID = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            toast.success(`${responseJson?.message}`, ErrorToastOptions)
                            this.getStatusList();
                            this.setState({acceptRejectIds:[],isAllChecked:false})
                        },
                        onFail: () => {
                            toast.error(`${responseJson?.message}`, ErrorToastOptions)
                            this.stopLoading();
                        },
                    });
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    startLoading = () => {
        this.setState({isLoading:true})
    }

    stopLoading = () => {
        this.setState({isLoading:false})
    }
    
    handleToggleCheck = (id:string,checked:boolean) => {
        this.setState({statusList: this.state.statusList.map(item => { return item.id === id ? {...item, checked: !checked} : item }),
    },
        ()=>    {
            this.handleGetIdsAcceptRejectAll(this.state.statusList)
        })

    }

    handlePagination = (value:number) => {
        if(value < 1 || value > this.state.totalPageNumber) return
        this.startLoading();
        this.setState({currentPage:value},()=>this.getStatusList())
    }

    handlePerPageSelection = (value:string) => {
        this.startLoading();
        this.setState({shownItems:value, currentPage:1},()=>this.getStatusList())
    }

    handleDateFilter = (value: Date[]) => {
        this.startLoading();
        this.setState({dateFilterValue: value.map(item=>{return item.toLocaleDateString('en-GB')})}, ()=>this.getStatusList())
    }

    handleSearch = (value:string) => {
        this.startLoading();
        this.setState({searchValue:value, currentPage:1}, ()=> {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getStatusList()
            }, 1500)
        })
    }

    handleSorting = (sortingType: keyof S, anchorType: keyof S, value: string) => {
        this.startLoading();
        this.setState({[sortingType]:value, [anchorType]:null } as unknown as Pick<S, keyof S>, ()=>this.getStatusList())
    }

    handleToggleIsAllSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({isAllChecked:event.target.checked, statusList: this.state.statusList.map(item=> { return {...item,checked:event.target.checked}})},
        ()=>{
            const penddingStatus = this.state.statusList.filter((items)=> items.attributes.status === 'pending')
            this.handleGetIdsAcceptRejectAll(penddingStatus)
            
        })

    }

    handleFilterMenuOpen = (filter:keyof S, event:React.MouseEvent<HTMLElement>) => {
        this.setState({
            [filter]: event.currentTarget
        } as unknown as Pick<S, keyof S>);
    }

    handleFilterMenuClose = (anchorType: keyof S) => {
        this.setState({[anchorType]:null} as unknown as Pick<S, keyof S>)
    }

    handleRemoveFilters = (filter : "all" | "date") => {
        this.startLoading();
        if(filter === "all") this.setState({sortingClientName: null, sortingDate: null, searchValue: "", dateFilterValue: []}, ()=>this.getStatusList())
        else this.setState({dateFilterValue:[]}, ()=>this.getStatusList())
    }   

    getStatusList = () => {
        this.startLoading();
        const {currentPage,sortingClientName,sortingDate,searchValue,dateFilterValue,shownItems} = this.state;

        let endPoint = `${configJSON.statusListEndPoint}?page=${currentPage}&per_page=${shownItems}&download=true`

        if(sortingClientName !== null) endPoint = endPoint + `&sort[client_name]=${configJSON.clientNameSortingOptionsApi[sortingClientName]}`
        if(sortingDate !== null) endPoint = endPoint + `&sort[date]=${sortingDate.toLowerCase()}`
        if(searchValue) endPoint = endPoint + `&search=${searchValue}`
        if(dateFilterValue.length > 0) endPoint = endPoint + `&date_filter[start_date]=${dateFilterValue[0]}&date_filter[end_date]=${dateFilterValue[1]}`

        const header = {
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        this.statusListApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint,
            method: configJSON.getApiMethod,
        });
    }

    handleGetIdsAcceptRejectAll = (statusList:IStatus[])=> {
        const penddingStatus = statusList.filter((items)=> items.attributes.status === 'pending')
        const ids = penddingStatus?.filter((item: { checked: boolean; }) => item.checked === true).map(item => item.id);
        this.setState({
            acceptRejectIds:[...ids]
        },
        ()=>{
            if(this.state.acceptRejectIds.length === 0){
                this.setState({isAllChecked:false})
            }
        })    
    }

    handleAcceptRejectAction = (id:string,checked:boolean,status:string,statusAll:string)=> {
        let ids: number[] = []; 
        if (statusAll === "acceptedAll" || statusAll === "rejectedAll") {
            ids = this.state.acceptRejectIds.map(id => parseInt(id));
        } else {
            ids.push(parseInt(id));
        }
        const body = {
            "clients":{
                "income_ids": ids,
                "status": status
             }
        }
        const header = { token: this.state.token};
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
        this.acceptRejectCommID = requestMessage.messageId;
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint:`${configJSON.acceptRejectEndPoint}`,
            method: configJSON.PutApiMethod,
            body:JSON.stringify(body)
        });
    }
    getCVSData = (data:any)=> {
        const transformedData = data.map((item: { client_name: any; total_income: any; start_date: any; recurring_payment: any; status: any; }) => ({
            'client_name': item.client_name,
            'partner_commission': item.total_income,
            'start_date': item.start_date,
            'recurring_payment': item.recurring_payment ? 'Yes' : 'No',
            'status': formatString(item.status) || ''
        }));

        const  headersData =  [
            { label: 'Client Name', key: 'client_name' },
            { label: 'Commission Amount', key: 'partner_commission' },
            { label: 'Commission Cycle', key: 'partner_commission' },
            { label: 'Date', key: 'start_date' },
            { label: 'Recurring', key: 'recurring_payment' },
            { label: 'Action', key: 'status' }
        ]
        this.setState({
            csvArrayHeaders: headersData,
            csvArray: transformedData
        });
    }
    
    async componentDidMount() {
        let token = await sessionStorage.getItem("token") || "";
        this.setState({
            token: JSON.parse(token).token
        });
        this.getStatusList();
    }

    // Customizable Area End
}
