import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IRole, IClient,IPayloadClient, IServiceType } from "./types";
import { EmptyClient, EmptyPayloadClient } from "./constants";
import { getRandomValue } from "./helper";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import createRequestMessage from "../../../framework/src/create-request-message";
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    partnerName: string;
    month: string;
    newClientsNumber: string;
    totalClients: string;
    clients: IClient[];
    payloadClients: IPayloadClient[];
    adminCommission: string;
    totalCommission: number;
    token: string;
    isClientNumberMatch: boolean;
    isRoleGloble:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class Cfcommissioncalculator2Controller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    partnerDetailsApiCallId: string | null;
    saveClientApiCallId: string | null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            partnerName: "",
            month: "2023-Sep",
            newClientsNumber: "",
            totalClients: "",
            clients: [EmptyClient],
            payloadClients:[EmptyPayloadClient],
            adminCommission: "10",
            totalCommission: 0,
            token: "",
            isClientNumberMatch:true,
            isRoleGloble:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.partnerDetailsApiCallId = "";
        this.saveClientApiCallId = "";
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.partnerDetailsApiCallId: {
                    this.partnerDetailsApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            const details = responseJson.data.attributes
                            const date = new Date(details.month)
                            const dateString = `${date.getFullYear()}-${this.handleTrueCondition(date.getMonth() < 10, "0", "")}${date.getMonth() + 1}-${this.handleTrueCondition(date.getDate() < 10, "0", "")}${date.getDate()}`
                            this.setState({ partnerName: details.partner_name, totalClients: details.total_clients, adminCommission: details.admin_commission, month: dateString })
                        },
                        onFail: () => this.showAlert(`${responseJson.errors}`, "Please retry!"),
                    });
                    break;
                }
                case this.saveClientApiCallId: {
                  this.saveClientApiCallId = null;
                  handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.props.navigation.navigate("CommissionStatus")
                        },
                        onFail: () => this.showAlert(`${responseJson.errors}`, "Please retry!"),
                    });
                  break;
              }
            
          }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleTrueCondition = (condition: boolean, trueValue: string, falseValue: string) => {
        return condition ? trueValue : falseValue
    }

    handleFieldChange = (name: string, value: string) => {
        if(name === "newClientsNumber" && value === "0") return
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>);
    }

    handleAddNewClient = () => {
        const randomValue = getRandomValue();
        this.setState(prevState => ({
            clients: [...prevState.clients, { ...EmptyClient, id: randomValue }]
        }));
    }

     processRolesAndServiceTypes = (client: any): any[] => {
        if (!Array.isArray(client.serviceType)) return [];
      
        let roleArray: any[] = [];
        let resultArray: any[] = [];
      
        client.serviceType.forEach((elm: IServiceType) => {
          let resultItem: any;
          if (elm.serviceType === 'Staff Augmentation' || elm.serviceType === 'Consulting') {
            roleArray = [...roleArray, ...this.processRoles(client, elm)];
          } else if (elm.serviceType === 'Platform') {
            resultItem = {
              service_type: elm.serviceType.toLowerCase(),
              deal_amount: elm.dealAmount,
              quantity: 1,
              role_type: elm.serviceType.toLowerCase(),
              partner_commission: elm.partnerCommission,
              platform_value: elm.platformPackage === "Starter Plus" ? "Starter_Plus" : elm.platformPackage,
            };
          } else if (elm.serviceType === 'Technology') {
            resultItem = {
              service_type: elm.serviceType.toLowerCase(),
              deal_amount: elm.dealAmount,
              quantity: 1,
              role_type: elm.serviceType.toLowerCase(),
              partner_commission: elm.partnerCommission,
            };
          }
      
          if (resultItem) {
            resultArray.push(resultItem);
          }
        });
      
        return [...resultArray, ...roleArray];
      };
      
    processRoles = (client: any, serviceType: IServiceType): any[] => {
        let roleArray: any[] = [];
        const roles = serviceType.serviceType === 'Staff Augmentation' ? client.staffRole : client.consultingRole;
      
        roles.forEach((item: any) => {
          if (item.checked) {
            const newItem = {
              service_type: serviceType.serviceType.toLowerCase().replace(/ /g, "_"),
              deal_amount: serviceType.dealAmount,
              quantity: item.quantity,
              role_type: item.title.toLowerCase().replace(/ /g, "_"),
              partner_commission: serviceType.partnerCommission,
            };
            roleArray.push(newItem);
          }
        });
      
        return roleArray;
      };
      
    buildPayload = (client: any, processedData: any[]): any => {
        return {
          "client_name": client.name,
          "start_date": client.startDate,
          "client_roles_attributes": processedData,
          "total_income": Number(client.totalIncome),
        };
      };
      
    updatePayloadClient = (): any[] => {
        const clientArray: any[] = [];
      
        this.state.clients.forEach((client: any) => {
          const processedData = this.processRolesAndServiceTypes(client);
          const payloadClient = this.buildPayload(client, processedData);
          clientArray.push(payloadClient);
        });
      
        return clientArray;
    }

    handleUpdateClient = async(client: IClient) => {
        let updateTotalCommission = 0;
        this.setState(prevState => ({
            clients: prevState.clients.map(item => {
                return item.id === client.id ? client : item
            })
        }), () => {
            this.state.clients.forEach(item => {
                if (!isNaN(parseInt(item.partnerCommission))) {
                    updateTotalCommission = updateTotalCommission + parseFloat(item.partnerCommission)
                }
            })
            this.setState({ totalCommission: updateTotalCommission })
        })
    }

    handleDeleteClient = (id: string) => {
        let updateTotalCommission = 0;
        this.setState(prevState => ({
            clients: prevState.clients.filter(item => item.id !== id)
        }),()=>{
            this.state.clients.forEach(item => {
                if (!isNaN(parseInt(item.partnerCommission))) {
                    updateTotalCommission = updateTotalCommission + parseFloat(item.partnerCommission)
                }
            })
            this.setState({ totalCommission: updateTotalCommission })
        });
    }

    handleCheckServiceValidation = (client: any): boolean => {
        const checkPlatform = (item: any): boolean => {
            if (item.serviceType === "Platform") {
                const isValid = item.platformPackage !== "";
                this.setState({ isRoleGloble: !isValid });
                return isValid;
            }
            return true;
        };
    
        const checkRole = (roleType: string, roles: any[]): boolean => {
            const checkedRoles = roles.filter((item: any) => item.checked);
            const isValid = checkedRoles.length > 0;
            this.setState({ isRoleGloble: !isValid });
            return isValid;
        };
    
        const checkServiceType = (item: any): boolean => {
            if (item.serviceType === "Staff Augmentation") {
                return checkRole(item.serviceType, client.staffRole);
            } else if (item.serviceType === "Consulting") {
                return checkRole(item.serviceType, client.consultingRole);
            } else {
                return true;
            }
        };
    
        const isValidServiceType = client.serviceType?.every((item: any) => {
            const isValid = checkPlatform(item) && checkServiceType(item);
            return isValid;
        });
    
        return !!(client.serviceType?.length === 0 || isValidServiceType);
    };
    

    handleSaveClient = () => {
       let saveForm = false
        this.setState({clients:this.state.clients.map(item=> {
            const { name, startDate } = item;
            const isNameValid = name !== "";
            const isStartDateValid = startDate !== "";
            const isServiceValidationValid = this.handleCheckServiceValidation(item);
            const isClientNumberMatch = this.state.clients.length === parseInt(this.state.newClientsNumber);
            const isValid = isNameValid && isStartDateValid && isServiceValidationValid && isClientNumberMatch;
            const validation = isValid ? "valid" : "error";
            saveForm = isValid
            return { ...item, validation };
        })})
        if(saveForm){
            this.handleApiHit()
        }
        if(this.state.clients.length !== parseInt(this.state.newClientsNumber)){
            this.setState({isClientNumberMatch:false})
            return
        }
    }
    handleApiHit=()=> {
       
        let payloadData = this.updatePayloadClient();
        const header = {
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        this.saveClientApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.saveClientApiEndPoint,
            method: configJSON.postApiMethod,
            body: JSON.stringify({incomes: payloadData})
        });
    }

    handleGetPartnerDetails = () => {
        const header = {
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );


        this.partnerDetailsApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.partnerDetailsApiEndPoint,
            method: configJSON.getApiMethod
        });
    }

    async componentDidMount() {
        let token = await sessionStorage.getItem("token") || "";
        this.setState({
            token: JSON.parse(token).token
        });
        this.handleGetPartnerDetails();
    }

    // Customizable Area End
}
