import { Box, Paper, TextField, Typography, styled } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Component, ReactNode } from "react";

type IProps = {
    className?:string;
    label: string;
    data: string[];
    value: string;
    onChange: (value: string) => void;
    isRequired?: boolean;
};
type IState = {};

class AutocompleteWithLabel extends Component<IProps,IState>{
    render(): ReactNode {
        const { className, label, data, value, onChange, isRequired } = this.props;
        return(
            <Box className={className}>
                <Box sx={webStyles.labelRow}>
                    <StyledTypographyLabel>
                        {label}
                    </StyledTypographyLabel>
                    {
                        isRequired &&
                        <StyledTypographyReq variant="subtitle1">*</StyledTypographyReq>
                    }
                </Box>
                <StyledAutocomplete
                    key="0"
                    data-test-id="country-select-box"
                    options={data}
                    limitTags={1}
                    onChange={(_,value)=>onChange(value as string || "")}
                    renderInput={(params)=>
                        (<StyledTextField className="autocomplete-input"
                            data-test-id="country-select-box"
                            {...params}
                            placeholder="Country"
                            value={value}
                        />)
                    }
                    PaperComponent={(props) => {
                        return (
                            <PaperStyled
                              {...props}
                            />
                          )
                    }}
                    value={value}
                />
            </Box>
        )
    }
}

const webStyles = {
    labelRow: {
        display: "flex"
    }
};

const StyledTypographyLabel = styled(Typography)({
    "&": {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledTypographyReq = styled(Typography)({
    "&": {
        color: "red",
        lineHeight: 1.65
    }
});

export const StyledAutocomplete = styled(Autocomplete)({
    border: "1px solid #dfdfdf",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    display:"flex",
    alignItems:"center",
    boxSizing:"border-box",
    width:"100%",
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        paddingRight: "25px",
        fontSize: "16px",
        borderBottom: "none",
        paddingLeft: "10px",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInput-underline::before': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline::after': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: "0px",
    },
    '&. MuiInputBase-fullWidth': {
        width: '95%'
    },
    "@media(max-width: 960px)": {
        "&": {
            width:"100%",
            height: "40px"
        }
    }
});

const PaperStyled = styled(Paper)({
    "&": {
        padding:"10px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& ul":{
        padding:"10px 5px"
    },
    "& ul li":{
        borderRadius:"8px",
        fontSize: "14px",
        padding:"9px 20px"
    },
    "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
        backgroundColor:"rgba(241, 245, 249, 1)"
    },
});

export const StyledTextField = styled(TextField)({
    fontSize: "12px",
    '& .MuiFilledInput-underline:after': {
        borderBottom: '0px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderBottom: '0px',
    },
});

export default AutocompleteWithLabel;