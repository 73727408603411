import { Box, Theme, Typography, isWidthDown, styled, withWidth } from "@material-ui/core";
import React, { Component } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';
import { Bar } from 'react-chartjs-2/dist';
import EmptyData from "./EmptyData.web";
import { emptyDealTypeIcon } from "../../blocks/dashboard/src/assets";
ChartJS.defaults.font = {
  family: 'Inter',
  size: 12,
  lineHeight: "18px"
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

let formatter = Intl.NumberFormat('en', { notation: 'compact' });
const minimizeAmount = (value: string | number) => {
    let valueToNumber: number | string = parseInt(value.toString());
    return `$${formatter.format(valueToNumber)}`
}

type IProps = {
  width: Parameters<Theme["breakpoints"]["only"]>[0];
  nameLabels:any,
  BarNumber:any,
  title:string,
};
type IState = {
  filter:string;
};

class DealGeneratedCard extends Component<IProps, IState>{
    constructor(props:IProps){
      super(props)
      this.state = {
        filter: "Choose"
      }
    }
    render() {
        const {filter} = this.state;
        const isMobileView = isWidthDown("sm", this.props.width, true);
        const {title,nameLabels,BarNumber} =this.props
        const options = {
          responsive: true,
          // maintainAspectRatio:false,
          events: [],
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false,
            }
          },
          scales: {
            x: {
              stacked:true,
              ticks:{
                font:{
                  size:isMobileView ? 9 : 12,
                  color: "#fff",
                  family:"'Inter', sans-serif"
                },
                autoSkip: false,
                maxRotation: isMobileView ? 30 : 90,
                minRotation: isMobileView ? 30 : 90,
              },
              grid: {
                drawBorder: false,
                display: false,
              }
            },
            y:{
              stacked:true,
              font:{
                size:isMobileView ? 9 : 12,
                color: "#fff",
                family:"'Inter', sans-serif"
              },
              callback: (val:number | string) => {
                return minimizeAmount(val)
               }
            }
          },
        };
        const labels = nameLabels
        const data = {
          labels,
          datasets: [
            {
              label: 'Dataset 1',
              data:BarNumber,
              backgroundColor: '#C399FF',
              borderRadius:{topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0},
              barThickness: isMobileView ? 14 : 32
            },
          ],
        };
               
        return (
            <StyledContainer>
                <Box className="content">
                  <Box className="header">
                      <Typography className="dashbard-main-header-title">{title}</Typography>
                  </Box>
                  {
                    nameLabels === undefined  ||  BarNumber === undefined ? <EmptyData description={`You don't have any ${title.toLowerCase()} data.`}  image={emptyDealTypeIcon}/> :
                    <>
                      <Bar options={options} data={data} />
                    </>
                  }
                </Box>
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)({
    "&":{
        border:"1px solid rgba(226, 232, 240, 1)",
        borderRadius:"8px",
        padding:"24px",
        width: "calc(50% - 12px)",
        boxSizing:"border-box",
        maxHeight:"369px"
    },
    "& .header":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"20px",
    },
    "& .dashbard-main-header-title":{
        fontSize: "20px",
        lineHeight: "28px",
        color: "#000000"
    },
    "& .header .view-all-button":{
        fontSize:"14px",
        fontWeight:500,
        lineHeight:"20px",
        textTransform:"capitalize",
        color:"#64748B"
    },
    "& .select-container": {
      width:"115px",
      height:"40px"
    },
    "& canvas" : {
      maxHeight:"260px"
    },
    "& .graph-labels-container" : {
      marginTop:"30px"
    },
    "& .empty-gap" :{
      height:"38px",
      width:"100%"
    },
    "@media(max-width: 960px)": {
      "&": {
          padding:"12px",
          width: "calc(50% - 6px)"
      },
      "& .header":{
        marginBottom:"12px",
      },
      "& .dashbard-main-header-title":{
        fontSize:"14px",
        lineHeight:"20px"
      },
      "& .header .view-all-button":{
        fontSize:"12px",
        lineHeight:"16px"
      },
      "& .select-container": {
        height: "32px"
      },
      "& .graph-labels-container" : {
        marginTop:"10px"
      },
    },
    "@media(max-width: 768px)": {
      "&": {
          width: "100%"
      },
      "& canvas" : {
        width: "100%!important"
      },
    }
});

export default  withWidth()(DealGeneratedCard);