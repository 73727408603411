Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";

exports.endPointApiGetdashboardAnalytics = "bx_block_statisticsreports2/statistics/analytics"
exports.endPointApiGetRegisteredOpportunities= "bx_block_cfleadmanagementandopportunity/deals/list"
exports.dashboarApiMethodTypePost = "POST";

exports.endPointApiGetSalesPipeline= "bx_block_dashboard/dashboards/sales_pipeline"
exports.endPointApiGetDealGenerated= "bx_block_dashboard/dashboards/top_ten_partners_deals"
exports.endPointApiGetStageAndLeadSource= "bx_block_dashboard/dashboards/deals_stages"
exports.endPointApiGetDealType="bx_block_dashboard/dashboards/deal_type"
exports.endPointApiGetAddNewClients= "bx_block_dashboard/dashboards/new_clients_added_by_user"
exports.endPointApiGetTotalCard = "bx_block_dashboard/dashboards/partner_dashboard_display_blocks"
exports.opportunityListEndPoint = "bx_block_cfleadmanagementandopportunity/deals/list"
exports.endPointApiTotalNumberOfClients="bx_block_dashboard/dashboards/total_clients_added"
exports.endPointApiRevanueByEachUser= "bx_block_dashboard/dashboards/revenue_earned_by_each_user"

exports.periods = [
  "Choose", "Today", "Monthly", "Weekly", "Yearly"
]
exports.periodsValue =[
  { label: "Choose", value:"Choose" },
  { label: "Monthly", value:"monthly" },
  { label: "Quarterly", value:"quarterly" },
  { label: "Half Yearly", value:"half_yearly" },
  { label: "Yearly", value:"yearly" },
]

exports.activePartnersFilterOptions = [
  "Current Years" , "Last Years"
]

exports.paginationSelectOptions = [
  { label:"10" , value:10},
  { label:"20" , value:20},
  { label:"50" , value:50},
  { label:"100", value:100}
]
exports.paginationSelectOptionsCustom = [
  { label:"5" , value:5},
  { label:"10" , value:10},
  { label:"20" , value:20},
]

exports.periodicReportBreadcrumb = {
  currentPage: {
    text:"Periodic Report",
    link: "/PeriodicReport"
  },
  links:[
    {
      text:"Users",
      link:"/ManageDetails"
    },
    {
      text:"Aliyah Lane",
      link:"/ProfileDetails"
    }
  ]
}

exports.periodicReportFilterOptions = [
  "Last 7 Days", "Last 15 Days", "Last 30 Days", "Last 3 Months", "Last 6 Months", "Year Till date"
]

exports.opportunityList = [
  {
    attributes:{
      opportunity_id:"VAL2604-001",
      opportunity_name: "Sustainext Digital - RPA",
      status:"Accepted",
      stage:"Agreement Signed",
      expected_revenue:"$6,540.00",
      created_on:"3/22/2023 10:44 AM",
      closed_on:"4/21/2023"
    }
  },
  {
    attributes:{
      opportunity_id:"VAL2604-002",
      opportunity_name: "Sustainext Digital - RPA",
      status:"Proposed",
      stage:"Proposal Sent",
      expected_revenue:"$6,540.00",
      created_on:"3/22/2023 10:44 AM",
      closed_on:"4/21/2023"
    }
  },
  {
    attributes:{
      opportunity_id:"VAL2604-003",
      opportunity_name: "Sustainext Digital - RPA",
      status:"Rejected",
      stage:"Did not Proceed",
      expected_revenue:"$6,540.00",
      created_on:"3/22/2023 10:44 AM",
      closed_on:"4/21/2023"
    }
  }
]

exports.StatisticsBreadcrumb = {
  currentPage:{
    text:"",
    value:""
  }
}

exports.dateFilterOptions = [
  { value: "View All", label: "View All" },
  { value: "between", label: "Year" },
  { value: "this_month", label: "Month" },
  { value: "today", label: "Day" },]

exports.datePickerLocale = {
  sunday: 'Sun',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  ok: 'Apply'
};


// Customizable Area End