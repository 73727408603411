import { Box, Popover, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"

type IProps = {
    children:React.ReactChild;
    anchorEl:HTMLElement | null;
    menuWidth?: string;
    onClose: ()=>void;
};
type IState = {};

class StyledMenuComponent extends Component<IProps, IState>{

    render() {
        const { children, anchorEl, menuWidth, onClose } = this.props;
        return (
            <StyledMenu
                data-test-id="filter-menu"
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin = {{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={webStyles.menuPosition}
                PaperProps={{
                    style: { width: menuWidth ?? webStyles.menuWidth.width },
                }}
            >
                <Box className="filter-menu-container">
                    {children}
                </Box>
            </StyledMenu>
        )
    }
}

const webStyles = {
    tableRow: {
      textAlign:"center"
    },
    menuPosition: {
      marginTop: "-45px"
    },
    menuWidth: {
        width: "192px"
    }
  }

export const StyledMenu = styled(Popover)({
    "& .MuiPaper-root": {
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        border: "1px solid rgba(226, 232, 240, 1)",
        padding: "4px",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginTop: "50px"
    },
    "& .MuiPaper-root>div:not(:last-child)": {
        marginBottom: "20px"
    },
    "& .filter-menu-item":{
        width:"94%",
        fontFamily:"'Inter', sans-serif",
        fontSize:"14px",
        padding:"7px 20px",
        cursor:"pointer",
        borderRadius: "8px"
    },
    "& .filter-menu-item:hover, & .filter-menu-item.selected":{
        backgroundColor: "rgba(241, 245, 249, 1)",
        fontWeight: "400",
        color: "black"
    },
    "& .filter-menu-container":{
        width: "194px"
    },
    "@media(max-width: 960px)": {
        "& .filter-menu-item":{
            fontSize:"12px",
            padding:"6px 14px",
        },
    }
  })

export default StyledMenuComponent;