// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
export const baseURLJSON = require("../../../framework/src/config.js");
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import { rightImg, wrongImg } from "../../email-account-registration/src/assets";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  password: string;
  confirmPassword: string;
  massageText: string;
  passwordError: boolean;
  emailError: boolean;
  emailErrorText: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  confirmPasswordError: string;
  openF: boolean;
  lowerPasswordRegex: boolean;
  upperPasswordRegex: boolean;
  numberPasswordRegex: boolean;
  specialPasswordRegex: boolean;
  lengthPasswordRegex: boolean;
  showError: boolean;
  passwordBlurred:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  //Properties from config
  newdescriptionText: string = configJSON.newdescription;
  newPasswordLable: string = configJSON.newPasswordText;
  forgotPasswordLable: string = configJSON.forgotPasswordText;
  forgotDescriptionText: string = configJSON.forgotdescription;
  backToLoginText: string = configJSON.backToLogin;
  passwordTypeText: string = configJSON.PasswordTxtType;
  TextTypeText: string = configJSON.NewPasswordtypeTxt;
  strongText: string;
  weakText: string;
  createAccountText: string;
  countryList: any[];
  passwordText: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      emailErrorText: "",
      massageText: "",
      emailError: false,
      passwordError: false,
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      confirmPasswordError: '',
      lowerPasswordRegex: false,
      upperPasswordRegex: false,
      numberPasswordRegex: false,
      specialPasswordRegex: false,
      lengthPasswordRegex: false,
      showError: false,
      passwordBlurred:false,
      openF: false,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  //Web Event Handling

  textEmailprops = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        email: text.target.value,
        emailError: false,
        emailErrorText: "",
      })
    }
  }

  nvigationtologin = {
    onClick: () => this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  navigatetodashboard = {
    onClick: () => this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  setNewPassword = (text: string, initial: boolean) => {
    this.setState({
        password: text,
        passwordError: initial,
        lowerPasswordRegex: configJSON.lower_regex.test(text) ? true : false,
        upperPasswordRegex: configJSON.upper_regex.test(text) ? true : false,
        numberPasswordRegex: configJSON.number_regex.test(text) ? true : false,
        specialPasswordRegex: configJSON.special_regex.test(text) ? true : false,
        lengthPasswordRegex: configJSON.length_regex.test(text) ? true : false,
    });
};

  txtConfirmPassword = {
    onBlur: () => {
      const confirmedPassword = this.state.confirmPassword;
      let passwordMatchError = '';
      if (this.state.password !== confirmedPassword) {
        passwordMatchError = configJSON.passwordNotMatchText;
      }

      this.setState({
        confirmPasswordError: passwordMatchError
      });
    },
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const confirmedPassword = event.target.value.trim();
      this.setState({
        confirmPassword: confirmedPassword
      });
    },
    onPaste: (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
    }
  }
  
  ClickShowPassword = {
    onClick: () => {
      this.setState({
        enablePasswordField: !this.state.enablePasswordField,
      });
    }
  }

  confirmPasswordClasses = () => {
    return `${this.props.classes.inputBorderMain}`
  }

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  }
  resetPasswordApi = {
    onClick: () => {
      if (this.state.email == "" || !configJSON.email_regex.test(this.state.email)) {
        this.setState({
          // emailError: true,
          showError: true,
          emailError: true,
          emailErrorText: 'Please enter a valid email address'
        })
      } else {
        !this.state.emailError && this.doResetPassword()
      }
    }
  }

  NewResetPassword = {
    onClick: () => {
      if(this.state.password !== this.state.confirmPassword){
        this.setState({
          confirmPasswordError:"Password and Confirm password should be same"
        })
      } else {
      this.doNewResetPassword()
    }
    }
  }

  errorModal = () => {
    return this.state.emailError && (
      <Box className={`${this.props.classes.aerltBorder}`}>
        <Box>
          <Box className={`${this.props.classes.alertTextDesign}`}>
            {this.state.emailErrorText}
          </Box>
        </Box>
      </Box>
    )
  }

  handlePasswordBlur = () => {
    this.setState({ passwordBlurred: true, passwordError:false   });
  }
  
  handlePasswordFocus = () => {
    this.setState({ passwordBlurred: false,  });
  }
  
  errorNewPasswordModal = () => {
    const isInvalidPassword = (
      this.state.passwordBlurred && 
      this.state.password.length &&
      !this.state.passwordError && (
        !this.state.lowerPasswordRegex ||
        !this.state.upperPasswordRegex ||
        !this.state.numberPasswordRegex ||
        !this.state.specialPasswordRegex ||
        !this.state.lengthPasswordRegex
      )
    );

    const isInvalidConfirmPassword = (
      this.state.confirmPasswordError &&
      this.state.confirmPassword.length > 0
    );

    if (isInvalidPassword || isInvalidConfirmPassword )  {
      return (
        <Box className={`${this.props.classes.aerltErrorBorder}`}>
          <Box className={`${this.props.classes.alertErrorTextDesign}`}>
            {this.state.confirmPasswordError || 'Invalid password'}
          </Box>
        </Box>
      );
    }
    return null;
  };


  passwordErrorClasses = () => {
    return this.state.passwordError ? `${this.props.classes.redInputBorderMain}` : `${this.props.classes.inputBorderMain}`
  }

  NewdhideShowIcon = () => {
    return this.state.enablePasswordField ? <VisibilityOff /> : <Visibility />
  }

  NewpasswordTxtType = () => {
    return this.state.enablePasswordField ? this.passwordTypeText : this.TextTypeText
  }

  NewconfirmPsswordTxt = () => {
    return this.state.enableConfirmPasswordField ? this.passwordTypeText : this.TextTypeText
  }

  NewvisibleinvisibleIcon = () => {
    return this.state.enableConfirmPasswordField ? <VisibilityOff /> : <Visibility />
  }

  resetEnterPassword = {
    onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key == 'Enter') {
        this.doResetPassword();
      }
    }
  }

  isValid = () => {
    return this.state.lowerPasswordRegex &&
      this.state.upperPasswordRegex &&
      this.state.numberPasswordRegex &&
      this.state.specialPasswordRegex &&
      this.state.lengthPasswordRegex ? true : false
  }


  weekStrongPasswordText = () => {
    return this.isValid() ? this.strongText : this.weakText
  }

  passwordNewInputClass = () => {
    let result;

    if (this.state.passwordError) {
      if (
        this.state.lowerPasswordRegex &&
        this.state.upperPasswordRegex &&
        this.state.numberPasswordRegex &&
        this.state.specialPasswordRegex &&
        this.state.lengthPasswordRegex
      ) {
        result = `${this.props.classes.inputTxtMain}`;
      } else {
        result = `${this.props.classes.redPasswordinputMain}`;
      }
    } else {
      result = `${this.props.classes.inputTxtMain}`;
    }
    return result;
  };

  newImageSource = () => {
    return this.isValid() ? rightImg : wrongImg;
  }

  newspecialSource = () => {
    return this.state.specialPasswordRegex ? rightImg : wrongImg;
  }

  newlowerSource = () => {
    return this.state.lowerPasswordRegex && this.state.upperPasswordRegex ? rightImg : wrongImg
  }

  newlengthSource = () => {
    return this.state.lengthPasswordRegex ? rightImg : wrongImg
  }

  newstrongSource = () => {
    return this.isValid() ? rightImg : wrongImg
  }

  passwordErrorsContent = () => {
    const numberNewTextClass = this.state.numberPasswordRegex ? this.props.classes.greenupperTxtBox : this.props.classes.reduppertxtBox;
    const specialNewTextClass = this.state.specialPasswordRegex ? this.props.classes.greenupperTxtBox : this.props.classes.reduppertxtBox;
    const lowerNewTextClass = this.state.lowerPasswordRegex && this.state.upperPasswordRegex ? this.props.classes.greenupperTxtBox : this.props.classes.reduppertxtBox;
    const lengthNewTextClass = this.state.lengthPasswordRegex ? this.props.classes.greenupperTxtBox : this.props.classes.reduppertxtBox;
    const weekNewStrongP = this.isValid() ? this.props.classes.greenupperTxtBox : this.props.classes.reduppertxtBox;
  
    return this.state.passwordError && (
      <Box component="span" className={`confirm-box ${this.props.classes.paswordMainTxtBox}`}>
          <Box className={`${this.props.classes.uperLowerTxtBox}`}>
              <img width={'14.85px'} height={'16px'} src={this.newImageSource()} alt="" />
              <Typography component="div" className={numberNewTextClass}>At least one number (0-9)</Typography>
          </Box>
          <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxtBox}`}>
              <img width={'14.85px'} height={'16px'} src={this.newspecialSource()} alt="" />
              <Typography component="div" className={specialNewTextClass}>At least one special character (Eg. @#%$)</Typography>
          </Box>
          <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxtBox}`}>
              <img width={'14.85px'} height={'16px'} src={this.newlowerSource()} alt="" />
              <Typography component="div" className={lowerNewTextClass}>At least one uppercase & one lowercase letter</Typography>
          </Box>
          <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxtBox}`}>
              <img width={'14.85px'} height={'16px'} src={this.newlengthSource()} alt="" />
              <Typography component="div" className={lengthNewTextClass}>Minimum 8 characters</Typography>
          </Box>
          <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxtBox}`}>
              <img width={'14.85px'} height={'16px'} src={this.newstrongSource()} alt="" />
              <Typography component="div" className={weekNewStrongP}>{`Password strength:${this.weekStrongPasswordText()}`}</Typography>
          </Box>
      </Box>
    );
  }

  handleClosePopup = {
    onClose: () => {
      this.setState({
        openF: false,
      })
    }
  }

  handleClosePopupClick = {
    onClick: () => {
      this.setState({
        openF: false,
      })
    }
  }

  doResetPassword = async () => {
    try {
      const response = await fetch(`${baseURLJSON.baseURL}/bx_block_forgot_password/generate_password_link`, {
        method: "PUT",
        body: JSON.stringify({
          "data": {
            "email": this.state.email,
          }
        }),
        headers: {
          "Content-Type": "application/json"
        },
      })
      const responseData = await response.json();
      if (response.status == 200) {
        sessionStorage.setItem("changesEmail", this.state.email)
        this.props.navigation.navigate("OTPInputAuth")
      }
      else {
        this.setState({
          emailError: true,
          emailErrorText: responseData.errors || responseData.error
        })
      }
    } catch (error) {
      console.error(error);
    }
  };

  doNewResetPassword = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    //@ts-ignore
    const tokenFromURL = urlParams.get('token');
    const tokenFromSessionStorage = sessionStorage.getItem('token');
    const token = tokenFromURL ?? (tokenFromSessionStorage ? JSON.parse(tokenFromSessionStorage).token : null);
    try {
      const response = await fetch(`${baseURLJSON.baseURL}/bx_block_forgot_password/create_password?token=${token}&password=${this.state.password}&password_confirmation=${this.state.confirmPassword}`, {
        method: "PUT",
      })
      const responseData = await response.json();
      if (response.status == 200) {
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }
      else {
        if (responseData.errors) {
          if (responseData.errors.token == 'Token has Expired') {
            this.props.navigation.navigate('EmailAccountLoginBlock')
          }
          this.setState({
            openF: true,
            massageText: responseData.errors.token || responseData.errors
          })
        }
        this.setState({
          openF: true,
          massageText: responseData.status || responseData.errors
        })
      }
    } catch (error) {
      console.error(error);
    }
  };


  // Customizable Area End
  // Customizable Area End
}