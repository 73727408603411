export const PricingTypes = [
    "Platform Project Pricing", "Consulting Project Pricing", "Staffing Project Pricing", "Technology Project Pricing"
];

export const PricingProjectBreadcrumb = {
    currentPage: {
        link: "cfleadmanagementandopportunity",
        text: "Project Price"
    }
};

export const EmployeeHeadcountOptions = [
    "0-50",
    "50-200",
    "200-1000",
    "1000-5000",
    "5000 +"
];

export const RevenueOptions = [
    "0 - .5 million",
    ".5 - 3 million",
    "3 - 50 million",
    "50 - 500 million",
    "500 + million"
];

const PlatformPackagesFullOptions = [
    "Starter",
    "Starter Plus",
    "Growth",
    "Advanced",
    "Enterprise"
];

const PlatformPackagesSecondOptions = PlatformPackagesFullOptions.slice(1, 5);
const PlatformPackagesThirdOptions = PlatformPackagesFullOptions.slice(2, 5);
const PlatformPackagesFourthOptions = PlatformPackagesFullOptions.slice(3, 5);
const PlatformPackagesFifthOptions = PlatformPackagesFullOptions.slice(4, 5);

export const PlatformPackagesOptions = {
    "": PlatformPackagesFullOptions,
    "0 - .5 million": PlatformPackagesFullOptions,
    ".5 - 3 million": PlatformPackagesSecondOptions,
    "3 - 50 million": PlatformPackagesThirdOptions,
    "50 - 500 million": PlatformPackagesFourthOptions,
    "500 + million": PlatformPackagesFifthOptions
};

export const SoftwareBotsOptions = [
    "Dedicated Bot",
    "Shared Bot"
];

export const BotTimeOptions = [
    "1 hrs/day",
    "3 hrs/day"
];

export const StaffingConsultantTypeOptions = [
    "Analyst",
    "Associate Consultant",
    "Consultant"
];

export const WorkingTimeOptions = [
    "80 hrs/week",
    "160 hrs/week"
];

export const ConsultingConsultantTypeOptions = [
    "Junior Consultant",
    "Mid level Consultant",
    "Senior Consultant"
];

export const PlatformProjectPricingFieldKeysToRemove = [
    "employeeHeadcount", "revenue", "platformPackages", "platformCountry", "numberOfOperatingLocations"
];

export const ConsultingProjectPricingFieldKeysToRemove = [
    "consultingConsultantType", "consultingCountry", "totalEstimateHours"
];

export const StaffingProjectPricingFieldKeysToRemove = [
    "staffingConsultantType", "staffingCountry", "workingTime"
];

export const TechnologyProjectPricingFieldKeysToRemove = [
    "softwareBot", "technologyCountry", "botTime"
];

export const AllFieldKeysToRemove = PlatformProjectPricingFieldKeysToRemove.concat(ConsultingProjectPricingFieldKeysToRemove).concat(StaffingProjectPricingFieldKeysToRemove).concat(TechnologyProjectPricingFieldKeysToRemove);
