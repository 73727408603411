export const stageValues = {
    "1st_meeting_complete": "1st Meeting Complete",
    "2nd_meeting_complete": "2nd Meeting Complete",
    "agreement_signed": "Agreement Signed",
    "closed_won": "Closed Won",
    "closed_lost": "Closed Lost",
    "did_not_proceed": "Did not Proceed",
    "issue_agreement": "Issue Agreement",
    "proposal_sent": "Proposal Sent",
    "closed_future_prospect": "Closed Future Prospect",
    "proposal_accepted": "Proposal accepted"
}

export const stageValuesReverse = [
  { stage: "1st Meeting Complete", key: "1st_meeting_complete", probabilityKey: "10" },
  { stage: "2nd Meeting Complete", key: "2nd_meeting_complete", probabilityKey: "20" },
  { stage: "Proposal Sent", key: "proposal_sent", probabilityKey: "35"  },
  { stage: "Proposal accepted", key: "proposal_accepted", probabilityKey: "55"  },
  { stage: "Issue Agreement", key: "issue_agreement", probabilityKey: "75"  },
  { stage: "Agreement Signed", key: "agreement_signed" , probabilityKey: "100" },
  { stage: "Closed Won", key: "closed_won" , probabilityKey: "100" },
  { stage: "Closed Lost", key: "closed_lost" , probabilityKey: "0" },
  { stage: "Closed Future Prospect", key: "closed_future_prospect" , probabilityKey: "0" },
  { stage: "Did not Proceed", key: "did_not_proceed" , probabilityKey: "0" }
];

export const leadSourceWords = {
  "Google Ad Words": "google_ad_words",
  "Social Media": "social_media",
  "Events/Trade Shows": "events_trade_shows",
  "Technology Partners": "technology_partners",
  "Consulting Partners": "consulting_partners",
  "Customer Referral": "customer_referral",
  "PR Campaigns": "pr_campaigns"
}

export const  bussinessType = {
    "Existing Bussiness": "existing_bussiness",
    "Social Media": "social_media"
}

export const dateFormatOppAllList = (value: string, dateType: boolean) => {

  if(value !== undefined && value !== null ){
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-GB');
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  
    if (dateType) {
      return `${formattedDate}`;
    }
  
    return `${formattedDate} ${formattedTime}`;
  }
   
};
  

  export const formatString = (inputString: string) => {

    if( inputString !== null && inputString !== undefined ){
        const words = inputString.split('_');
        const formattedString = words
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
        if(formattedString === "Approve")
        {
          return "Approved"
        } else if( formattedString === "Submit For Review" ){
          return "Submitted for review"
        }
        else {
          return formattedString;
        }

    }
    
  };

  export const  minDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1); 
    return today.toISOString().split('T')[0];
};