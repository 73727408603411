  export const formatMoney = (number: any) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(1) + 'T';
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + 'B';
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + 'K';
    }
    return number;
  }

  export const formatLabelString = (inputString: string) => {
    if( inputString !== null && inputString !== undefined ){
        const words = inputString.split('_');
        const formattedString = words
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
        return formattedString;
    }
  };

  export const capitalizeWords = (input: string): string => {
    const capitalizedWords = input.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    if (capitalizedWords.length <= 11) {
      return capitalizedWords;
    } else {
      return capitalizedWords.slice(0, 9) + '...';
    }
  }

  export const  numberCommaSeparator = (number:string | number) => {
    if( number !== null && number !== undefined ){
      return number.toLocaleString();
    }else {
      return  "0"
    }
  };
  
  export const numberFormat = (number: any) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(1) + 'T+';
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + 'B+';
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + 'M+';
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + 'K+';
    }
    return number;
  }

  export const replaceEmptyKeyWithAnonymous = (deals: Record<string, any>): void  =>  {
    if ("" in deals) {
        deals["Anonymous"] = deals[""];
        delete deals[""];
        const dealKeys = Object.keys(deals);
        const anonymousIndex = dealKeys.indexOf("Anonymous");
        if (anonymousIndex !== -1) {
            dealKeys.splice(anonymousIndex, 1);
            dealKeys.push("Anonymous");
        }
      }
  }

  export const replaceQuarters = (originalObject: any): any => {
    const replacementValues: Record<string, string> = {
      "first_quarter": "Jan-Mar",
      "second_quarter": "Apr-Jun",
      "third_quarter": "Jul-Sep",
      "fourth_quarter": "Oct-Dec",
      "first_half":"Jan-Jun",
      "second_half":"Jul-Dec",
    };

    if (!originalObject) {
      return originalObject;
    }

    const replacedObject: any = {};
    type QuarterMapping = {
      [key: string]: string;
    };
    
    const quarterMapping: QuarterMapping = {
      first_quarter: "Jan-Mar",
      second_quarter: "Apr-Jun",
      third_quarter: "Jul-Sep",
      fourth_quarter: "Oct-Dec",
    };
    
    type HalfMapping = {
      [key: string]: string;
    };
    
    const halfMapping: HalfMapping = {
      first_half: "Jan-Jun",
      second_half: "Jul-Dec",
    };
    
    Object.keys(originalObject).forEach((key) => {
      if (quarterMapping.hasOwnProperty(key)) {
        replacedObject[quarterMapping[key]] = replacementValues[key];
      } else if (halfMapping.hasOwnProperty(key)) {
        replacedObject[halfMapping[key]] = replacementValues[key];
      }
    });

    Object.keys(originalObject).forEach(key => {
      if (key !== "third_quarter" && 
        key !== "fourth_quarter" && 
        key !== "first_quarter" && 
        key !== "second_quarter"  && 
        key !== "first_half"  && 
        key !== "second_half"  ) 
      {
        replacedObject[key] = originalObject[key];
      }
    });
    return replacedObject;
  };

  export const mapQuarterKeysToMonths = (originalKeys: string[]): string[] => {
    const keyMapping: Record<string, string> = {
      "Quarter 1": "Jan-Mar",
      "Quarter 2": "-Apr-Jun",
      "Quarter 3": "Jul-Sep",
      "Quarter 4": "Oct-Dec",
      "First Half":"Jan-Jun",
      "Second Half":"Jul-Dec",
    };

    return originalKeys.map((key) => keyMapping[key] || key);
  }