//@ts-nocheck
import { Box, Button, IconButton, Input, InputAdornment, InputLabel, styled, Typography } from "@material-ui/core";
import React from "react";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { backArrow, imgPasswordKey } from "./assets";
import {
  withStyles
} from "@material-ui/core/styles";
import { CommonPopUp } from "../../../components/src/CommonPopup";

export class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box className={`${classes.mainBoxFlexContainer}`}>
        <Box className={`${classes.mainBoxContainer}`}>
          <Box>
            <img src={imgPasswordKey} alt="imgPasswordKey" />
          </Box>
          <Box className={`${classes.HeadertextMainBox}`}>
            <Typography className={`${classes.headingNewText}`}>{this.newPasswordLable}</Typography>
            <Typography className={`${classes.newPassDescriptionText}`}>{this.newdescriptionText}</Typography>
          </Box>
          {this.errorNewPasswordModal()}

          <StyledContainer>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <InputLabel className={`${classes.emailInputLabelText}`}>
                Password
              </InputLabel>
              <Typography component="div" variant="subtitle1" className={`${classes.requireInputColor}`}>*</Typography>
            </Box>
            <Input
              disableUnderline
              className={this.passwordNewInputClass()}
              data-test-id="txtInputPassword"
              type={this.NewpasswordTxtType()}
              placeholder={"Password"}
              fullWidth={true}
              value={this.state.password}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                this.setNewPassword(trimmedValue, true);
              }}
              onBlur={(e) => {
                const trimmedValue = e.target.value.trim();
                this.setNewPassword(trimmedValue, false);
                this.handlePasswordBlur();
              }}
              onFocus={this.handlePasswordFocus}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    {...this.ClickShowPassword}
                    edge="end"
                  >
                    {this.NewdhideShowIcon()}
                  </IconButton>
                </InputAdornment>
              }
            />
            {this.passwordErrorsContent(classes.paswordMainTxtBox, classes.greenupperTxtBox, classes.reduppertxtBox, classes.uperLowerTxtBox)}
          </StyledContainer>
          <StyledContainer>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              <InputLabel className={`${classes.emailInputLabelText}`}>
                Confirm password
              </InputLabel>
              <Typography component="div" variant="subtitle1" className={`${classes.requireInputColor}`}>*</Typography>
            </Box>

            <Input
              disableUnderline
              className={this.confirmPasswordClasses()}
              data-test-id="txtInputConfirmPassword"
              type={this.NewconfirmPsswordTxt()}
              placeholder={"Password"}
              fullWidth={true}
              value={this.state.confirmPassword}
              {...this.txtConfirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {this.NewvisibleinvisibleIcon()}
                  </IconButton>
                </InputAdornment>
              }
            />
          </StyledContainer>

          <Box className={`${classes.signIpBtnMain}`} >
            <Button
              id={"resetNewPasswordButton"}
              variant="contained"
              className={`${classes.resetPasswordText}`}
              fullWidth
              {...this.NewResetPassword}
            >
              Reset password {/*UI Engine::From Sketch*/}
            </Button>
          </Box>
          {!(JSON.parse(sessionStorage.getItem('token'))?.account?.data?.attributes?.temporary_password) && <Box className={`${classes.bcktomain}`} data-test-id='navigateLoginPage' {...this.navigatetodashboard}>
            <img src={backArrow} alt="backArrow" />
            <Typography className={`${classes.backtologinPageText}`}>{this.backToLoginText}</Typography>
          </Box>}
        </Box>
        <CommonPopUp {...this.props} massageText={this.state.massageText} open={this.state.openF} handleCloseClick={this.handleClosePopupClick} handleClose={this.handleClosePopup} />
      </Box>
      // Customizable Area End
    )
  }

}

// Customizable Area Start
export const NewPasswordStyles = (theme: any) => ({
  mainBoxContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '22px',
    width: '460px',
    height: '598px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "@media(max-width: 576px)": {
      width: "100%",
      margin: "0 8px",
      height: "500px",
      maxWidth: "460px"
    }
  },
  headingNewText: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '30px',
    letterSpacing: '-0.005em',
    textAlign: 'center',
    fontFamily: 'Inter',
    "@media(max-width: 576px)": {
      fontSize: "24px"
    }
  },
  HeadertextMainBox: {
    marginTop: '15px',
    "@media(max-width: 576px)": {
      marginTop: '18px',
    }
  },
  mainBoxFlexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E5E5E5',
    height: '100vh',
  },
  forgotDescriptionText: {
    color: '#667085',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '12px',
    letterSpacing: '0',
    fontFamily: 'Inter',
    "@media(max-width: 576px)": {
      fontSize: "12px"
    }
  },
  newPassDescriptionText: {
    width: '303px',
    height: "56px",
    marginTop: '5px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    color: '#667085',
    textAlign: 'center',
    fontFamily: 'Inter',
    "@media(max-width: 576px)": {
      fontSize: "12px",
      width: "65%",
      margin: "5px auto 0 auto"
    }
  },
  inputBorderMain: {
    border: '1px solid rgba(203, 213, 225, 1)',
    padding: '10px',
    width: '100%',
    radius: '8px',
    borderRadius: '8px',
    height: '44px',
    fontFamily: 'Inter',
    "@media(max-width: 576px)": {
      height: "36px"
    }
  },
  emailInputLabelText: {
    marginBottom: '5px',
    color: '#334155',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '22px',
    "@media(max-width: 576px)": {
      fontSize: "12px"
    }
  },
  passwordMailnput: {
    width: '360px',
    height: '44px',
    radius: '8px'
  },
  requireInputColor: {
    color: 'red',
  },
  signIpBtnMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    marginTop: '24px',
    "@media(max-width: 576px)": {
      width: '85%',
      marginTop: '18px',
    }
  },
  resetPasswordText: {
    background: 'linear-gradient(90deg, rgba(51,60,92,1) 36%, rgba(10,12,35,1) 100%)',
    color: 'white',
    fontWeight: '600',
    textTransform: 'capitalize',
    height: '44px',
    width: '100%',
    borderRadius: '8px',
    fontFamily: 'Inter',
    padding: '10px 16px !important',
    "@media(max-width: 576px)": {
      height: "36px"
    }
  },
  backtologinPageText: {
    color: '#2E17B5',
    fontWeight: '400',
    fontSize: '14px',
    paddingLeft: '5px',
    fontFamily: 'Inter',
  },

  bcktomain: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '24px',
    "@media(max-width:576px)": {
      marginTop: "18px"
    }
  },
  redInputBorderMain: {
    border: '2px solid #F87171',
    padding: '10px',
    borderRadius: '8px',
    height: '44px',
    fontFamily: 'Inter',
  },
  aerltErrorBorder: {
    marginTop: '10px',
    width: "100%"
  },
  alertErrorTextDesign: {
    background: '#FEE2E2',
    borderLeft: '4px solid #DC2626',
    borderRadius: '4px',
    color: '#DC2626',
    fontWeight: '400',
    fontSize: '12px',
    width: '360px',
    height: '42px',
    display: 'flex',
    justifyContent: 'flexStart',
    alignItems: 'center',
    paddingLeft: '10px',
    fontFamily: 'Inter',
    margin: "0 auto",
    "@media(max-width:576px)": {
      width: "85%"
    }
  },
  paswordMainTxtBox: {
    background:
      "linear-gradient(90deg, rgba(51,60,92,1) 36%, rgba(10,12,35,1) 100%)",
    borderRadius: "8px !important",
    width: "283.85px !important",
    position: "absolute",
    zIndex: 999,
    padding: "12px"
  },
  inputTxtMain: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    "@media(max-width: 576px)": {
      height: "36px"
    }
  },
  redPasswordinputMain: {
    border: "1px solid red",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  greenupperTxtBox: {
    color: "#fff !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    paddingLeft: "9px",
    fontFamily: "'Inter', sans-serif",
  },
  reduppertxtBox: {
    color: "#F87171 !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    paddingLeft: "9px",
    fontFamily: "'Inter', sans-serif",
  },

  // popupStyle

  popupcenterTxt: {
    justifyCOntent: 'center',
    display: 'flex',
    marginTop: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  popupContainer: {
    background: '#FFFFFF',
    borderRadius: '8px',
    width: '443px',
    boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    padding: '16px 17px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
  },
  "@media(max-width: 500px)": {
    popupContainer: {
      width:'85%'
    }
  },
  popupdescriptionTxt: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '18px',
    color: '#0A0528',
    margin: '12px 51px',
    textAlign: 'center',
  },
  successTxt: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '26px',
    color: '#0A0528',
  },
  popupcloseimg: {
    display: 'flex',
    justifyContent: 'end',
    cursor: 'pointer',
  },
  okBtn: {
    background: '#FFFFFF',
    width: '61px',
    height: '44px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
    fontWeight: '700',
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#000000',
    fontStyle: 'normal',
    textTransform: 'capitalize',
  },
  popupBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
    gap: '16px',
    "@media(max-width: 576px)": {
      marginTop: "16px"
    }
  },
  uperLowerTxtBox: {
    display: "flex",
    alignItems: "center",
  },

  "@media only screen and (min-width: 1100px) and (max-width: 1200px)": {
    body: {
      zoom: "110%"
    },
    mainBoxContainer: {
      background: '#FFFFFF',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
      borderRadius: '22px',
      width: '460px',
      height: '510px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      "@media(max-width: 576px)": {
        width: "100%",
        margin: "0 8px",
        height: "500px",
        maxWidth: "460px"
      },
      paddingTop: "5px",
      paddingBottom: "5px"
    },
  }
})

const StyledContainer = styled(Box)({
  "&": {
    marginTop: "23px",
    width: "78.264%",
    position: "relative"
  },
  "& .confirm-box": {
      right:"-318px",
      top:"-35px"
  },
  '& .confirm-box::after': {
    content: '""',
    position: "absolute",
    top: "50%",
    right: "100%",
    marginTop: "-5px",
    borderWidth: "8px",
    borderStyle: "solid",
    borderColor: "transparent #0A0528 transparent transparent",
  },
  "@media(max-width: 1024px)": {
    "& .confirm-box": {
      top: "90px",
      left: 0,
      right: 0
    },
    "& .confirm-box::after": {
      borderColor: "transparent transparent #0A0528 transparent",
      top: "-11px",
      left: "15px"
    }
  },
  "@media(max-width: 576px)": {
    "&": {
      marginTop: "16px",
      width: "85%"
    }
  }
});

//@ts-ignore
export default withStyles(NewPasswordStyles)(NewPassword);
// Customizable Area End