import { Box, IconButton, Input, InputAdornment, Typography, styled } from "@material-ui/core";
import React, { Component, ReactNode } from "react";

type IProps = {
    containerStyle: object;
    className?: string;
    inputType: string;
    label: string;
    inputValue: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    hasIcon?: ReactNode;
    iconAction?: () => void;
    isRequired?: boolean;
    placeholder?: string;
    disabled?: boolean;
};
type IState = {};

class InputWithLabel extends Component<IProps, IState>{
    render(): ReactNode {
        const { containerStyle, className, inputType, label, inputValue, onChange, onBlur, onFocus, hasIcon, iconAction, isRequired, placeholder, disabled } = this.props;
        return (
            <Box className={className} sx={containerStyle}>
                <Box sx={webStyles.labelRow}>
                    <StyledTypographyLabel>
                        {label}
                    </StyledTypographyLabel>
                    {
                        isRequired &&
                        <StyledTypographyReq variant="subtitle1">*</StyledTypographyReq>
                    }
                </Box>
                <Input
                    data-test-id="input-with-label"
                    placeholder={placeholder}
                    onChange={(event) => onChange(event.target.value)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    disableUnderline
                    style={webStyles.input}
                    type={inputType}
                    fullWidth={true}
                    value={inputValue}
                    disabled={disabled}
                    endAdornment={
                        hasIcon ? <InputAdornment data-test-id="icon-button" onClick={iconAction} position="end">
                            <IconButton
                                edge="end">
                                {hasIcon}
                            </IconButton>
                        </InputAdornment> : <></>
                    }
                    inputProps={{
                        min: "1900-01-31",
                        max: "3000-12-31"
                    }}
                />
            </Box>
        )
    };
};

const webStyles = {
    input: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
    },
    labelRow: {
        display: "flex",
        alignItems: "baseline"
    }
};

const StyledTypographyLabel = styled(Typography)({
    "&": {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledTypographyReq = styled(Typography)({
    "&": {
        color: "red",
    }
});

export default InputWithLabel;
