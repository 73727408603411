import { Box, Theme, Typography, isWidthDown, styled, withWidth } from "@material-ui/core";
import React, { Component } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';
import { Bar } from 'react-chartjs-2/dist';
import GraphLabel from "../../blocks/dashboard/src/GraphLabel.web";
import { configJSON } from "../../blocks/dashboard/src/DashboardController";
import SelectWithoutLabelWeb from "./SelectWithoutLabel.web";
import EmptyData from "./EmptyData.web";
import { emptyDealTypeIcon } from "../../blocks/dashboard/src/assets";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
  
const graphLabels = [
  {label:"Pending",backgroundColor:"#FEF3C7"},
  {label:"Lost",backgroundColor:"#F87171"},
  {label:"Won",backgroundColor:"#34D399"}
]

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

const minimizeAmount = (value: string | number) => {
    let valueToNumber: number | string = parseInt(value.toString());
    return `$${formatter.format(valueToNumber)}`
}

type IProps = {
  width: Parameters<Theme["breakpoints"]["only"]>[0];
  handleGetdealTypeData: (item: string,event:string) => void,
  dealTypeLabelData:string[],
  dealTypeNumberData:any[]
};
type IState = {
  filter:string;
};

class DealTypeCard extends Component<IProps, IState>{
    constructor(props:IProps){
      super(props)
      this.state = {
        filter: "monthly"
      }
    }
    render() {
        const {filter} = this.state;
        const isMobileView = isWidthDown("sm", this.props.width, true);
        const { dealTypeNumberData, dealTypeLabelData, handleGetdealTypeData } = this.props 
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          events: [],
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false,
            }
          },
          scales: {
            x: {
              stacked:true,
              ticks:{
                font:{
                  size: isMobileView ? 10 : 12,
                  color: "#64748B",
                  family:"'Inter', sans-serif"
                },
                autoSkip: false,
                maxRotation: isMobileView ? 30 : 0, 
                minRotation: isMobileView ? 30 : 0
              },
              grid: {
                drawBorder: false,
                display: false,
              }
            },
            y:{
              stacked:true,
              font:{
                size:isMobileView ? 9 : 12,
                color: "#fff",
                family:"'Inter', sans-serif"
              },
              callback: (val:number | string) => {
                return minimizeAmount(val)
               }
            }
          },
        };

      let labels = dealTypeLabelData
      const won: number[] = [];
      const rejected: number[] = [];
      const pending: number[] = [];

      dealTypeNumberData.forEach((item) => {
        const [wonValue, rejectedValue, pendingValue] = item;
        won.push(wonValue);
        rejected.push(rejectedValue);
        pending.push(pendingValue);
      });

      const data = {
          labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: won,
              backgroundColor: '#34D399',
              borderRadius:{topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0},
              barThickness: isMobileView ? 14 : 32
            },
            {
              label: 'Dataset 2',
              data: rejected,
              backgroundColor: '#F87171',
              borderRadius:{topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0},
              barThickness: isMobileView ? 14 : 32
            },
            {
              label: 'Dataset 3',
              data: pending,
              backgroundColor: '#FEF3C7',
              borderRadius:{topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0},
              barThickness: isMobileView ? 14 : 32
            },
          ],
        };
        return (
            <StyledContainer>
                <Box className="header">
                    <Typography className="header-title">Number of deals/opportunities type</Typography>
                    <Box className="select-container">
                      <SelectWithoutLabelWeb
                          data-test-id="period-select-box"
                          data={configJSON.periodsValue}
                          value={filter}
                          onChange={(text)=> { 
                            handleGetdealTypeData(text,"Select"),
                            this.setState({ filter: text }) 
                          }}
                      />
                    </Box>
                </Box>
                {
                  dealTypeLabelData === undefined ||  dealTypeNumberData.length === 0 || dealTypeNumberData === undefined  ? <EmptyData description="You don't have any deals/opportunities type data." image={emptyDealTypeIcon}/> :
                  <>
                    <Bar options={options} data={data} />
                    <Box className="graph-labels-container">
                      {
                        graphLabels.map(item=>(
                          <GraphLabel
                            key={item.label}
                            label={item.label}
                            backgroundColor={item.backgroundColor}
                          />
                        ))
                      }
                    </Box>
                  </>
                }
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)({
    "&":{
        border:"1px solid rgba(226, 232, 240, 1)",
        borderRadius:"8px",
        padding:"24px",
        width:"calc(50% - 12px)",
        boxSizing:"border-box",
        maxHeight:"369px"
    },
    "& .header":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"20px",
    },
    "& .header-title":{
        fontSize:"20px",
        lineHeight:"28px",
        color: "#000000"
    },
    "& .header .view-all-button":{
        fontSize:"14px",
        fontWeight:500,
        lineHeight:"20px",
        textTransform:"capitalize",
        color:"#64748B"
    },
    "& .select-container": {
      width:"115px",
      height:"40px"
    },
    "& canvas" : {
      height: "200px!important"
    },
    "& .graph-labels-container" : {
      marginTop:"30px"
    },
    "@media(max-width: 1224px)": {
      "&": {
          width: "calc(50% - 24px)"
      }
    },
    "@media(max-width: 960px)": {
      "&": {
          padding:"12px",
      },
      "& .header":{
        marginBottom:"12px",
      },
      "& .header-title":{
        fontSize:"14px",
        lineHeight:"20px"
      },
      "& .header .view-all-button":{
        fontSize:"12px",
        lineHeight:"16px"
      },
      "& .select-container": {
        height: "32px"
      },
      "& .graph-labels-container" : {
        marginTop:"10px"
      },
    },
    "@media(max-width: 768px)": {
      "&": {
          width: "100%"
      },
      "& canvas" : {
        width: "100%!important"
      },
    }
});

export default  withWidth()(DealTypeCard);