// Customizable Area Start
// @ts-nocheck
import { Box, FormControlLabel, FormGroup, Switch, SwitchProps, Typography, styled, withStyles } from "@material-ui/core";
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import React from "react";
import SuccessToast from "../../../components/src/SuccessTost.web";
import OnBoardActiveInActiveUser from "../../../components/src/OnBoardActiveInActiveUser.web";
import Layout from "../../../components/src/Layout.web";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start

export const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 48,
    height: 28,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 24,
        height: 24,
    },
    '& .MuiSwitch-track': {
        borderRadius: 28 / 2,
        backgroundColor: '#E2E8F0',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

// Customizable Area End

export class ProfileDetails extends UserProfileBasicController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const pageData  = configJSON.UserProfileBasicBlock

        // Customizable Area End
        return (
            // Customizable Area Start
                <>
                <Layout
                    navigation={this.props.navigation}
                    breadcrumb={pageData}
                    // title="Profile Details"
                >
                   
                        <Box className={`${classes.profileTitleContainer}`}>
                            <Typography className={`${classes.profileDetailsTxt}`}>Profile Details</Typography>
                            <FormGroup>
                                <FormControlLabel
                                    data-test-id="triggercheackBox"
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                    {...this.handelactivInActive}
                                    checked={this.state.userActivInActive} />
                            </FormGroup>
                        </Box>
                        {this.state.profileDetailsData !== "" && (
                            <Box className={`${classes.bottomSideContainer}`}>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>First Name</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.first_name}</Typography>
                                </Box>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>Last Name</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.last_name}</Typography>
                                </Box>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>Company Name</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.company_name}</Typography>
                                </Box>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>Job Title</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.job_title}</Typography>
                                </Box>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>Work Email</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.email}</Typography>
                                </Box>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>Mobile Number</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.full_phone_number}</Typography>
                                </Box>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>City</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.city}</Typography>
                                </Box>
                                <Box className={`${classes.bottomTxtBox}`}>
                                    <Typography className={`${classes.bottomLeftTxt}`}>Country</Typography>
                                    <Typography className={`${classes.bottomLeftTxtValue}`}>{this.state.profileDetailsData.attributes.country}</Typography>
                                </Box>
                            </Box>
                        )}
                    {/* </Box> */}
                <OnBoardActiveInActiveUser {...this.props} userActivInActive={this.state.userActivInActive} open={this.state.open} handleClose={this.handleClose} handleCloseClick={this.handleCloseClick} handleShowToastlick={this.handleShowToastlick} />
                {this.state.openToast && <SuccessToast text={!this.state.userActivInActive ? 'User Deactivated Successfully' : 'User Activated Successfully'} />}
                </Layout>
                </>
            // Customizable Area End
        )
    }

    componentDidMount = async () => {
        const dataId = sessionStorage.getItem('ProfileOnBoardUserId')
        //@ts-ignore
        const userId = JSON.parse(dataId)
        userId !== null && this.getProfileDetailsDataList(userId)
    };
}

// Customizable Area Start

export const ProfileDetailstyle = (theme: any) => ({
    profileDetailsTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    profileTitleContainer: {
        display: 'flex',
        marginRight: '32px',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E2E8F0',
        paddingBottom: '20px'
    },
    bottomSideContainer: {
        margin: '0px'
    },
    bottomTxtBox: {
        display: 'flex',
        height: '56px',
        alignItems: 'center',
    },
    bottomLeftTxt: {
        width: '407px',
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'uppercase',
        color: '#64748B',
    },
    bottomLeftTxtValue: {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: '16px',
        color: '#334155',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
})


//@ts-ignore
export default withStyles(ProfileDetailstyle)(ProfileDetails);
// Customizable Area End