import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2/dist';
import EmptyData from "./EmptyData.web";
import { emptyDealTypeIcon } from "../../blocks/dashboard/src/assets";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

type IProps = {
  totalNumberOfClientsProps:any
};

type IState = {};

class TotalNumberOfClients extends Component<IProps, IState>{
    render() {
        const {totalNumberOfClientsProps} = this.props
        const options = {
          responsive: true,
          maintainAspectRatio: false,
        };
        const data = {
          labels: ['Active', 'Inactive'],
          datasets: [
            {
              data: totalNumberOfClientsProps,
              backgroundColor: ['#3366CC','#DC2626'],
              borderWidth: 1,
            },
          ],
        };

        return (
            <MainStyledContainer>
                <Box className="header">
                    <Typography className="header-title">Total Clients</Typography>
                </Box>
                {
                  totalNumberOfClientsProps.length  <= 0   ||  totalNumberOfClientsProps === undefined 
                  ? <EmptyData description="You don't have any total clients data." image={emptyDealTypeIcon}/> 
                  : <>
                      <Box className="graph-wrapper">
                        <Pie data={data} options={options} />
                    </Box>
                  </>
                }
            </MainStyledContainer>
        )
    }
}

const MainStyledContainer = styled(Box)({
    "&":{
        width: "calc(50% - 12px)",
        padding:"20px 16px",
        borderRadius:"8px",
        border:"1px solid #E2E8F0",
        display:"flex",
        flexDirection:"column",
        gap:"20px",
        boxSizing:"border-box",
        minHeight:"230px",
        
        "& .graph-wrapper": {
            display:"flex",
            justifyContent:"flex-end",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            height: "calc(80% - 15px)",
            "& .graph-container":{
                "& canvas":{
                    maxWidth:"170px"
                }
            }
        },
        "& .header-title": {
            fontSize: "20px",
            lineHeight: "28px",
            color: "#000000"
          },
        "& .graph-labels-container":{
            display:"flex",
            flexDirection:"column",
            margin:"0 auto",
            gap:"20px",
            "& .graph-label-item":{
                display:"flex",
                alignItems:"center",
                gap:"8px",
                "& .additional-text":{
                    fontSize:"12px",
                    fontWeight:700,
                    lineHeight:"16px",
                    color:"#0F172A"
                }
            }
        }
    },
    "@media(max-width: 576px)": {
        "&":{
            width: "calc(100% - 12px)"
        },
        "& .graph-labels-container":{
            gap:"10px!important"
        },
        "& .graph-wrapper":{
            flexDirection:"column",
            gap:"20px",
            "& .graph-container":{
                "& canvas":{
                    minHeight:"130px"
                }
            }
        },
        "& .header-title": {
            fontSize: "16px!important"
        },

    },
});

export default TotalNumberOfClients;