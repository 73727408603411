// Customizable Area Start
//@ts-nocheck
import {Grid, Box, Button, TextField, IconButton, Input, InputAdornment, InputLabel, Typography, withStyles, styled, Paper } from
  "@material-ui/core";
import React from "react";
import { suatainextLogo } from "./assets";
import EmailAccountRegistrationController, { Props } from './EmailAccountRegistrationController.web';
import { Autocomplete } from "@material-ui/lab";
import { CommonPopUp } from "../../../components/src/CommonPopup";
// Customizable Area End

export class EmailAccountResistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  getCountryLabel = (option: any) => {
    return option.name ?? this.state.age
};

  getCityLabel = (option: any) => {
    return option.name ?? this.state.cityName
  };
  industryLabel = (option: any) => {
    return option && option?.attributes?.industry_name
  }

  handleIndustry = (_, e) => {
    return this.textIndustryProps(e?.id || "")
  }

  handleCountryLabel = (event: any, e: string | null) => {
    this.textCountryProps((e && e.name) || "")
    this.setState({
        countryIsoCode: e && e.isoCode,
        cityName:''
    })
}

  handleCityLabel = (_, e) => {
    this.txtCityProps((e && e.name) || "")
  }
 
  renderIndustry = (params) => (
      <TextField className={this.props.classes.searchInputIunder}
      data-test-id="inputtxtCompanyName"
      sx={{ fontSize: "12px" }}
      {...params}
      placeholder="Industry"
      value={this.state.industry}
    />
    )

  renderCountryInput = (params) => (
    <TextField className={this.props.classes.searchInputIunder}
      data-test-id="inputtxtCountryName"
      sx={{ fontSize: "12px" }}
      {...params}
      placeholder="Country"
      value={this.state.age}
    />
  )

  renderCityInput = (params) => (
    <TextField className={this.props.classes.searchInputIunder}
      data-test-id="txtInputCityName"
      sx={{ fontSize: "12px" }}
      {...params}
      placeholder="City"
      value={this.state.cityName}
    />
  )

  renderDialInput = (params) => (
    <TextField className={this.props.classes.searchInputIunder}
      data-test-id="countrySignUpDialCode"
      sx={{ fontSize: "12px" }}
      {...params}
      value={this.state.age}
    />
  )

  renderCountryCode = (selected: any) => {
    return selected;
  }

  handleOptions = (option, value) => { return option.name === value.name }

  handleOptionsIndustry = (option, value) => { return option.attributes.industry_name === value.attributes.industry_name }

  handleOptionsDial = (option, value) => { return option === value }

  render() {
    // Customizable Area Start
    const { classes } = this.props

    // Customizable Area End
    return (
      // Customizable Area Start
      <Grid container className={`${classes.mainBoxFlex}`}>
        <Grid item xs={12} md={7} order={{ xs: 1, md: 1 }} className={`${classes.leftSideMainBox}`}>
          <Box sx={{ width: '66.072%', margin:"auto", paddingBottom: "40px" }}>
            <Box sx={{marginBottom: "7%"}}>
              <img src={suatainextLogo} alt="suatainextLogo" />
            </Box>
            <Box className={`${classes.mainLabletxtBox}`}>
              <Typography className={`${classes.lableText}`}>{this.createAccountText}</Typography>
            </Box>
            <Box
              component='form'
              {...this.formonkeyevent}
            >
              <Box className={`${classes.inputFieldMain}`}>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      First Name
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Input data-test-id="txtInputFirstName" placeholder={"First name"} fullWidth={true}
                    disableUnderline
                    className={`${classes.inputMain}`}
                    {...this.txtFnamePrpos}
                    value={this.state.firstName}
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.errors.firstName}</Typography>
                  </Box>
                </Box>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Last Name
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Input data-test-id="txtInputLastName" placeholder={"Last name"} fullWidth={true}
                    {...this.txtLnamePrpos}
                    value={this.state.LastName}
                    disableUnderline
                    className={`${classes.inputMain}`}
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.errors.lastName}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className={`${classes.inputFieldMain}`}>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Company Name
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Input data-test-id="txtInputCompanyName" placeholder={"Company name"} fullWidth={true}
                    disableUnderline
                    className={`${classes.inputMain}`}
                    {...this.txtCompanynamePrpos}
                    value={this.state.company}
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.errors.company}</Typography>
                  </Box>
                </Box>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Job Title
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Input data-test-id="txtInputJobTitle" placeholder={"Job title"} fullWidth={true} {...this.txtJobTitle}
                    value={this.state.jobTitle}
                    disableUnderline
                    className={`${classes.inputMain}`}
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.errors.jobTitle}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.inputFieldMain}`}>
                <Box className={`${classes.inputFiledWork}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Work Email
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Input data-test-id="txtInputWorkEmail" placeholder={"Enter your email"} fullWidth={true} value={this.state.workEmail}
                    {...this.txtWorkMail}
                    disableUnderline
                    className={`${classes.inputWorkMain}`}
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.errors.email}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.inputFieldMain}`}>
                <Box sx={{ width: '100%', paddingBottom: '15px !important' }}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Password
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Input data-test-id="txtInputPassword" disableUnderline
                    className={this.passwordInputClasses()}
                    type={this.passwordTxtType()} placeholder={"Password"} fullWidth={true}
                    value={this.state.password} 
                    onChange={(e) => this.setPasswordData(e.target.value.trim(), true)}
                    onBlur={(e)=> this.setPasswordDataDisappear()}
                    endAdornment={
                      <InputAdornment testID={"btnPasswordShowHide"} position="end">
                        <IconButton aria-label="toggle password visibility" {...this.btnPasswordShow} edge="end">
                          {this.passwordhideShowIcon()}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.passwordErrorMessage} </Typography>
                  </Box>
                </Box>
                {this.passwordTxtErrorsContent()}
                <Box sx={{ width: '100%', paddingBottom: '15px !important' }}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Confirm Password
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Box>
                    <Input data-test-id="txtInputConfirmPassword" disableUnderline
                      className={this.confirmpasswordClasses()}
                      type={this.confirmPsswordTxt()} placeholder={"Password"}
                      fullWidth={true} value={this.state.confirmPassword} {...this.txtConfirmPasswordProps}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton data-test-id="passwordvisibility" aria-label="toggle password visibility" {...this.handleClickConfirmPassword}
                            edge="end">
                            {this.passvisibleinvisibleIcon()}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.confirmErrorMessage}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className={`${classes.inputFieldMain}`}>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Mobile Number
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Box className={`${classes.mobileInputWrapper}`}>
                    <Autocomplete
                        key={0}
                        disableUnderline
                        className={`dial-autocomplete ${classes.countrylogininputTxtMain}`}
                        data-test-id="txtInputCountryCode"
                        sx={{ fontSize: "12px" }}
                        limitTags={1}
                        options={this.countryDialCodeList}
                        getOptionLabel={(option)=>option}
                        onChange={(_,value)=>this.textSignUpCountryDialProps.onChange(value)}
                        renderInput={this.renderDialInput}
                        getOptionSelected={this.handleOptionsDial}
                        PaperComponent={(props) => {
                          return (
                              <CustomPaper
                                {...props}
                              />
                            )
                        }}
                        value={this.state.countrySignUpDialCode}
                    />
                    <Input data-test-id="txtInputMobileNo" placeholder={"12345 67890"}
                      inputProps={{
                        maxLength: this.state.countrySignUpDialCode == '+91' ? 10 : 15,
                      }}
                      value={this.state.mobileNumber} {...this.txtMobileNo}
                      disableUnderline
                      className={`${classes.inputMobile}`}
                    />
                                     </Box>
                  <Typography className={`${classes.errormassagetext}`}>{this.state.errors.phoneNumber}</Typography>
                  <Typography className={`${classes.errormassagetext}`}>{this.state.errors.countrySignUpDialCode}</Typography>
                </Box>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Country
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Autocomplete
                    data-test-id="txtInputCountry"
                    key={this.state.clearInput}
                    disableUnderline
                    className={`${classes.countrylogininputTxtMain}`}
                    sx={{ fontSize: "12px" }}
                    limitTags={1}
                    options={this.state.countryList}
                    getOptionLabel={this.getCountryLabel}
                    fullWidth
                    onChange={this.handleCountryLabel}
                    getOptionSelected={this.handleOptions}
                    renderInput={this.renderCountryInput}
                    PaperComponent={(props) => {
                        return (
                            <CustomPaper
                              {...props}
                            />
                          )
                    }}
                      value={this.state.age}
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.errors.country}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.inputFieldMain}`}>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      City 
                    </InputLabel>
                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                  </Box>
                  <Autocomplete
                      key={this.state.clearInput}
                      disableUnderline
                      className={`${classes.countrylogininputTxtMain}`}
                      data-test-id="txtInputCity"
                      sx={{ fontSize: "12px" }}
                      limitTags={1}
                      options={this.state.cityList}
                      getOptionLabel={this.getCityLabel}
                      fullWidth
                      onChange={this.handleCityLabel}
                      getOptionSelected={this.handleOptions}
                      renderInput={this.renderCityInput}
                      PaperComponent={(props) => {
                          return (
                              <CustomPaper
                                {...props}
                              />
                            )
                      }}
                      value={this.state.cityName}
                  />
                  <Box>
                    <Typography className={`${classes.errormassagetext}`}>{this.state.errors.city}</Typography>
                  </Box>
                </Box>
                <Box className={`${classes.inputFiledGroup}`}>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <InputLabel className={`${classes.emailLabelText}`}>
                      Industry
                    </InputLabel>
                  </Box>
                  <Autocomplete
                    key={this.state.clearInput}
                    disableUnderline
                    className={`${classes.countrylogininputTxtMain}`}
                    data-test-id="txtInputIndustry"
                    sx={{ fontSize: "12px" }}
                    limitTags={1}
                    options={this.state.industryList}
                    getOptionLabel={this.industryLabel}
                    fullWidth
                    onChange={this.handleIndustry}
                    getOptionSelected={this.handleOptionsIndustry}
                    renderInput={this.renderIndustry}
                    PaperComponent={(props) => {
                      return (
                          <CustomPaper
                            {...props}
                          />
                        )
                    }}
                  />
                </Box>
              </Box>
              <Box className={`${classes.signIpBtn}`}>
                <Button
                  id="resetPasswordButton"
                  variant="contained"
                  className={`${classes.signupText}`}
                  onClick={() => this.createAccountMail()}
                >
                  Create account
                </Button>
              </Box>
            </Box>
            <Box className={`${classes.dontHaveAccountText}`}>
              <Typography className={`${classes.dontAccountText}`}>Already have an account?{" "}</Typography>
              <Typography data-test-id="btnloginProps" className={`${classes.signLinkText}`} {...this.btnloginProps}>Log In</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} order={{ xs: 2, md: 2 }} style={{width: "97.26%"}} className={`${classes.rightSideImg}`}>
          <img src={this.state.signUpImage} alt="banner" />
        </Grid>
        <CommonPopUp {...this.props} massageText='Your Sign Up Request has been sent to the Admin for approval. You should be able to login into the portal once the request is approved.' open={this.state.open} handleCloseClick={this.handleCloseClick} handleClose={this.handleClose} />
      </Grid>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
export const EmailAccountResistrationStyles = (theme: any) => ({
  root:{
    marginTop:"6px",
    borderRadius:"8px",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    "& ul":{
        padding:"10px"
    },
    "& ul li":{
        borderRadius:"8px",
        fontSize: "14px",
        padding:"9px 20px"
    },
    "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
        backgroundColor:"rgba(241, 245, 249, 1)"
    },
  },
  mainBoxFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  errormassagetext: {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: '400',
    marginTop: '5px',
  },
  countryloginCodeBox: {
    width: "auto"
  },
  rightSideImg: {
    minHeight: '100vh',
    // width: "97.26%",
    display: "flex",
    overflowX: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    justifyContent: 'end',
    '& img': {
      width: "100%%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }
  },
  leftSideMainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "38.542%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: '80px'
  },
  lableText: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "36px",
    lineHeight: "44px",
    fontFamily: "Inter, sans-serif",
  },
  mainLabletxtBox: {
    marginBlock: "32px",
  },
  emailLabelText: {
    marginBottom: "5px",
    color: "#334155",
    fontFamily: "'Inter', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "22px",
  },

  countrylogininputTxtMain: {
    width: '97% !important',
    border: "1px solid #dfdfdf",
    padding: "5px 7px 5px 0px",
    borderRadius: "8px",
    fontFamily: "'Inter', sans-serif",
    "&.dial-autocomplete":{
      width: "230px !important",
      border:0,
      borderTopRightRadius:0,
      borderBottomRightRadius:0
    },
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      paddingRight: "20%",
      fontSize: "16px",
      borderBottom: "none",
      paddingLeft: "10px",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInput-underline::before': {
      borderBottom: "0px",
    },
    '& .MuiInput-underline::after': {
      borderBottom: "0px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: "0px",
    },
    '&. MuiInputBase-fullWidth': {
      width: '95%'
    }
  },
  searchInputIunder: {
    padding: "0 5px",
    '& .MuiFilledInput-underline:after': {
      borderBottom: '0px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderBottom: '0px',
    },
    '&. MuiInputBase-fullWidth': {
      width: '95%'
    }
  },
  requireColor: {
    color: "red",
  },
  inputMain: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  inputMobile: {
    borderRadius: "8px",
    height: "44px",
    width: "auto",
    fontFamily: "'Inter', sans-serif",
    padding: '5px'
  },
  inputMainPlaceholder: {
    fontFamily: "'Inter', sans-serif",
  },
  redinputMain: {
    border: "1px solid red",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",

  },
  placeholderText: {
    color: "#aaa",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    fontFamily: "'Inter', sans-serif",
  },
  inputMainSelect: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  inputMainMobileSelect: {
    borderRadius: "8px",
    fontFamily: "'Inter', sans-serif",
    height: '44px',
    paddingBlock: '10px',
    width: 'fit-content',
    paddingLeft: '10px'
  },
  inputMainSelectFocused: {
    backgroundColor: "transparent !important",
  },
  inputWorkMain: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100% !important",
    fontFamily: "'Inter', sans-serif",
  },
  inputFieldMain: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
  "@media (max-width: 768px)": {
    inputFieldMain:{
      flexDirection:'column',
      gap:'0px'
    },
    inputFiledGroup: {
      paddingBottom: "0px"
    }
  },
  inputFiledGroup: {
    paddingBottom: "15px",
    width: "100%",
  },
  inputFiledWork: {
    paddingBottom: "15px",
    width: "100%",
  },
  signupText: {
    background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
    color: "white",
    fontWeight: "700",
    fontSize: '16px',
    textTransform: "none",
    height: "44px",
    borderRadius: "8px",
    marginTop: "24px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  dontAccountText: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#475569',
  },
  signLinkText: {
    fontFamily: "'Inter', sans-serif",
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    color: '#2E17B5',
    paddingLeft: "5px",
    cursor: "pointer",
  },
  dontHaveAccountText: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "32px",
    width: "100%",
  },
  greenTxt: {
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "#FFFFFF !important",
    fontFamily: "'Inter', sans-serif",
  },
  redtxt: {
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "#F87171 !important",
    fontFamily: "'Inter', sans-serif",
  },
  uperLowerTxt: {
    display: "flex",
    alignItems: "center",
    marginTop:'auto'
  },
  paswordMainBox: {
    background:
      "linear-gradient(90deg, rgba(51,60,92,1) 36%, rgba(10,12,35,1) 100%)",
    borderRadius: "8px !important",
    width: "auto",
    height: "auto",
    position: "absolute",
    left: "30%",
    zIndex: 999,
    padding: "12px",
    '&::after': {
      content: '""',
      position: "absolute",
      top: "50%",
      right: "100%",
      marginTop: "-5px",
      borderWidth: "8px",
      borderStyle: "solid",
      borderColor: "transparent #0A0528 transparent transparent",
    },
  },
  greenupperTxt: {
    color: "#fff !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    paddingLeft: "9px",
    fontFamily: "'Inter', sans-serif",
  },
  reduppertxt: {
    color: "#F87171 !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    paddingLeft: "9px",
    fontFamily: "'Inter', sans-serif",
  },
  mobileInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #dfdfdf',
    borderRadius: '8px',
    height: '42px'
  },
  popupContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    width: '443px',
    height: 'auto',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    padding: '16px 17px'
  },
  popupcenterTxt: {
    display: 'flex',
    justifyCOntent: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
  },
  successTxt: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '26px',
    color: '#0A0528',
  },
  popupdescriptionTxt: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    color: '#0A0528',
    margin: '12px 51px',
    textAlign: 'center',
  },
  popupcloseimg: {
    display: 'flex',
    justifyContent: 'end',
    cursor: 'pointer',
  },
  popupBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
    gap: '16px'
  },
  okBtn: {
    background: '#FFFFFF',
    borderRadius: '8px',
    width: '61px',
    height: '44px',
    border: '1px solid',
    borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    color: '#000000',
    textTransform: 'capitalize',
  }
})

const CustomPaper = styled(Paper)({
  "&": {
      padding:"10px",
      borderRadius:"8px",
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  },
  "& ul":{
      padding:"10px 5px"
  },
  "& ul li":{
      borderRadius:"8px",
      fontSize: "14px",
      padding:"9px 20px"
  },
  "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
      backgroundColor:"rgba(241, 245, 249, 1)"
  },
});

//@ts-ignore
export default withStyles(EmailAccountResistrationStyles)(EmailAccountResistrationBlock);

// Customizable Area End