Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethodType = "POST";
exports.getApiMethodType = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpipelinemanagement";
exports.labelBodyText = "cfpipelinemanagement Body";

exports.opportunityListEndPoint = "bx_block_cfleadmanagementandopportunity/deals/list"
exports.proposedAmountEndPoint = "bx_block_cfleadmanagementandopportunity/deals"
exports.createOpportunityEndPoint = "bx_block_cfleadmanagementandopportunity/deals"
exports.getByIdDetailsOpportunityEndPoint = "bx_block_cfleadmanagementandopportunity/deals/"
exports.putAPiMethodType = "PUT";
exports.deleteAPiMethodType = "DELETE";

exports.btnExampleTitle = "CLICK ME";

exports.CreateOpportunityBreadcrumb = {
  currentPage:{
    link:"CreateOpportunities",
    text:"Create Opportunity"
  },
  links:[
    {
      link:"OpportunityRegistration",
      text:"Opportunity Registration"
    }
  ]
}
exports.ViewOty = {
  currentPage:{
    link:"CreateOpportunities",
    text:"View Opportunity"
  },
  links:[
    {
      link:"OpportunityRegistration",
      text:"Opportunity Registration"
    }
  ]
}

exports.UpdateOty = {
  currentPage:{
    link:"CreateOpportunities",
    text:"Update Opportunity"
  },
  links:[
    {
      link:"OpportunityRegistration",
      text:"Opportunity Registration"
    }
  ]
}
exports.DealView = {
  currentPage:{
    link:"CreateOpportunities",
    text:"Deal View"
  },
  links:[
    {
      link:"OpportunityRegistration",
      text:"Deal"
    }
  ]
}

exports.stageArray =[
    '1st Meeting Complete',
    '2nd Meeting Complete',
    'Proposal Sent',
    'Issue Agreement',
    'Agreement Signed',
    'Closed Won',
    'Closed Lost',
    'Closed Future Prospect',
    'Did not Proceed',
    'Proposal accepted'
]

exports.serviceLineArray = [
  'Platform',
  'Staffing',
  'Consulting',
  'Technology'
];
exports.leadSourceArray = [
  'Google Ad Words',
  'Social Media',
  'Events/Trade Shows',
  'Technology Partners',
  'Consulting Partners',
  'Customer Referral',
  'PR Campaigns'
];
exports.dealType = [
  { value: 0, label: 'Existing Business' },
  { value: 1, label: 'New Business' },
];
exports.currencyFormListArry = [
  "AED United Arab Emirates dirham",
  "AFN Afghan afghani",
  "ALL Albanian lek",
  "AMD Armenian dram",
  "ANG Netherlands Antillean guilder",
  "AOA Angolan kwanza",
  "ARS Argentine peso",
  "AUD Australian dollar",
  "AWG Aruban florin",
  "AZN Azerbaijani manat",
  "BAM Bosnia and Herzegovina convertible mark",
  "BBD Barbados dollar",
  "BDT Bangladeshi taka",
  "BGN Bulgarian lev",
  "BHD Bahraini dinar",
  "BIF Burundian franc",
  "BMD Bermudian dollar",
  "BND Brunei dollar",
  "BOB Boliviano",
  "BRL Brazilian real",
  "BSD Bahamian dollar",
  "BTN Bhutanese ngultrum",
  "BWP Botswana pula",
  "BYN New Belarusian ruble",
  "BYR Belarusian ruble",
  "BZD Belize dollar",
  "CAD Canadian dollar",
  "CDF Congolese franc",
  "CHF Swiss franc",
  "CLF Unidad de Fomento",
  "CLP Chilean peso",
  "CNY Renminbi|Chinese yuan",
  "COP Colombian peso",
  "CRC Costa Rican colon",
  "CUC Cuban convertible peso",
  "CUP Cuban peso",
  "CVE Cape Verde escudo",
  "CZK Czech koruna",
  "DJF Djiboutian franc",
  "DKK Danish krone",
  "DOP Dominican peso",
  "DZD Algerian dinar",
  "EGP Egyptian pound",
  "ERN Eritrean nakfa",
  "ETB Ethiopian birr",
  "EUR Euro",
  "FJD Fiji dollar",
  "FKP Falkland Islands pound",
  "GBP Pound sterling",
  "GEL Georgian lari",
  "GHS Ghanaian cedi",
  "GIP Gibraltar pound",
  "GMD Gambian dalasi",
  "GNF Guinean franc",
  "GTQ Guatemalan quetzal",
  "GYD Guyanese dollar",
  "HKD Hong Kong dollar",
  "HNL Honduran lempira",
  "HRK Croatian kuna",
  "HTG Haitian gourde",
  "HUF Hungarian forint",
  "IDR Indonesian rupiah",
  "ILS Israeli new shekel",
  "INR Indian rupee",
  "IQD Iraqi dinar",
  "IRR Iranian rial",
  "ISK Icelandic króna",
  "JMD Jamaican dollar",
  "JOD Jordanian dinar",
  "JPY Japanese yen",
  "KES Kenyan shilling",
  "KGS Kyrgyzstani som",
  "KHR Cambodian riel",
  "KMF Comoro franc",
  "KPW North Korean won",
  "KRW South Korean won",
  "KWD Kuwaiti dinar",
  "KYD Cayman Islands dollar",
  "KZT Kazakhstani tenge",
  "LAK Lao kip",
  "LBP Lebanese pound",
  "LKR Sri Lankan rupee",
  "LRD Liberian dollar",
  "LSL Lesotho loti",
  "LYD Libyan dinar",
  "MAD Moroccan dirham",
  "MDL Moldovan leu",
  "MGA Malagasy ariary",
  "MKD Macedonian denar",
  "MMK Myanmar kyat",
  "MNT Mongolian tögrög",
  "MOP Macanese pataca",
  "MRO Mauritanian ouguiya",
  "MUR Mauritian rupee",
  "MVR Maldivian rufiyaa",
  "MWK Malawian kwacha",
  "MXN Mexican peso",
  "MXV Mexican Unidad de Inversion",
  "MYR Malaysian ringgit",
  "MZN Mozambican metical",
  "NAD Namibian dollar",
  "NGN Nigerian naira",
  "NIO Nicaraguan córdoba",
  "NOK Norwegian krone",
  "NPR Nepalese rupee",
  "NZD New Zealand dollar",
  "OMR Omani rial",
  "PAB Panamanian balboa",
  "PEN Peruvian Sol",
  "PGK Papua New Guinean kina",
  "PHP Philippine peso",
  "PKR Pakistani rupee",
  "PLN Polish złoty",
  "PYG Paraguayan guaraní",
  "QAR Qatari riyal",
  "RON Romanian leu",
  "RSD Serbian dinar",
  "RUB Russian ruble",
  "RWF Rwandan franc",
  "SAR Saudi riyal",
  "SBD Solomon Islands dollar",
  "SCR Seychelles rupee",
  "SDG Sudanese pound",
  "SEK Swedish krona",
  "SGD Singapore dollar",
  "SHP Saint Helena pound",
  "SLL Sierra Leonean leone",
  "SOS Somali shilling",
  "SRD Surinamese dollar",
  "SSP South Sudanese pound",
  "STD São Tomé and Príncipe dobra",
  "SVC Salvadoran colón",
  "SYP Syrian pound",
  "SZL Swazi lilangeni",
  "THB Thai baht",
  "TJS Tajikistani somoni",
  "TMT Turkmenistani manat",
  "TND Tunisian dinar",
  "TOP Tongan pa'anga",
  "TRY Turkish lira",
  "TTD Trinidad and Tobago dollar",
  "TWD New Taiwan dollar",
  "TZS Tanzanian shilling",
  "UAH Ukrainian hryvnia",
  "UGX Ugandan shilling",
  "USD United States dollar",
  "UYI Uruguay Peso en Unidades Indexadas",
  "UYU Uruguayan peso",
  "UZS Uzbekistan som",
  "VEF Venezuelan bolívar",
  "VND Vietnamese đồng",
  "VUV Vanuatu vatu",
  "WST Samoan tala",
  "XAF Central African CFA franc",
  "XCD East Caribbean dollar",
  "XOF West African CFA franc",
  "XPF CFP franc",
  "XXX No currency",
  "YER Yemeni rial",
  "ZAR South African rand",
  "ZMW Zambian kwacha",
  "ZWL Zimbabwean dollar"
]


exports.OpportunitiesList = {
  currentPage: {
    link: "OpportunitiesList",
    text: "Opportunity Listing"
  },
  
  links:[
    {
      link: "OpportunityRegistration",
      text: "Opportunity Registration"
    }
  ]
};
exports.DealList = {
  currentPage: {
    link: "OpportunitiesList",
    text: "Deal Listing"
  },
  
  links:[
    {
      link: "OpportunityRegistration",
      text: "Deal"
    }
  ]
};

exports.OpportunitieRegistration = {
  currentPage: {
      link: "OpportunityRegistration",
      text: "Opportunity Registration"
  },

};
exports.DealRegistration = {
  currentPage: {
      link: "OpportunityRegistration",
      text: "Deal"
  },

};

exports.listofFilter = [
  { index: 0, key: 'account_name', label: 'Account Name' },
  { index: 1, key: 'amount', label: 'Amount' },
  { index: 2, key: 'closing_date', label: 'Closing Date' },
  { index: 3, key: 'created_at', label: 'Created Time' },
  { index: 4, key: 'currency', label: 'Currency' },
  { index: 5, key: 'deal_name', label: 'Opportunity/Deal Name' },
  { index: 6, key: 'lead_source', label: 'Lead source' },
  { index: 7, key: 'stage', label: 'Stage' },
  { index: 8, key: 'deal_owner', label: 'Opportunity/Deal Owner' },
  { index: 9, key: 'expected_revenue', label: 'Expected Revenue' },
  { index: 10, key: 'probability', label: 'Probability(%)' },
  { index: 11, key: 'bussiness_type', label: 'Type' },
];

exports.tableData = [
  {
    id: 'VAL260344-001',
    partnerOpp: 'Sustainext Digital - RPA',
    status: 'Accepted',
    stage: 'Agreement Signed',
    partner: 'Afex AI Pvt Ltd India',
    commonText: 'Sustainext digital',
    amount: '$7,5340.00',
  },
  {
    id: 'VAL260344-002',
    partnerOpp: 'Sustainext Digital - RPB',
    status: 'Rejected',
    stage: 'Agreement',
    partner: 'Afex AI Pvt Ltd Dubai',
    commonText: 'Sustainext digital New',
    amount: '$7,765340.00',
  },
  {
    id: 'VAL260344-003',
    partnerOpp: 'Sustainext Digital - RPC',
    status: 'Process',
    stage: 'Proposal Sent ',
    partner: 'Afex AI Pvt Ltd Africa',
    commonText: 'Sustainext',
    amount: '$9,5990440.00',
  },
];

exports.perPageView = [
  10,20,50,100 
];

exports.AmountRange =[
  { key: 'range-1', label: '$ 0 To $ 100', value: '0-100' },
  { key: 'range-2', label: '$ 100 To $ 1000', value: '100-1000' },
  { key: 'range-3', label: '$ 1000 To $ 10,000', value: '1000-10000' },
  { key: 'range-4', label: '$ 10,000 To $ 1,00,000', value: '10000-100000' },
]


exports.AmountOperator = [
{ operator: '=', value: '=',},
{ operator: '!=', value: '!=', },
{ operator: '<',  value: '<',},
{ operator: '<=', value: '<=', },
{ operator: '>', value: '>' },
{ operator: '>=', value: '>=' },
{ operator: 'between', value: 'between' },
{ operator: 'not between', value: 'not_between' },
{ operator: 'is empty', value: 'is_empty' },
{ operator: 'is not empty', value: 'is_not_empty' },

];


exports.ClosingDate = [
  { value: 'in_the_last', label: 'In the Last' },
  { value: 'due_in', label: 'Due In' },
  { value: 'on', label: 'On' },
  { value: 'before', label: 'Before' },
  { value: 'after', label: 'After' },
  { value: 'between', label: 'Between' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'this_week', label: 'This Week' },
  { value: 'this_month', label: 'This Month' },
];

exports.ClosingMonth = [
{ value: 'days', label: 'Day' },
{ value: 'weeks' , label: 'Week' },
{ value: 'month', label: 'Month' },

];

exports.ProbabiltiyRange = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '35', label: '35' },
  { value: '55', label: '55' },
  { value: '75', label: '75' },
  { value: '100', label: '100' },
];
// Customizable Area End