// Customizable Area Start
import React, { Component } from "react";
import {
    withWidth,
    isWidthDown,
    Theme,
} from "@material-ui/core";
import InputWithLabel from "../../../components/src/InputWithLabel.web";
import { StyledBox, projectPricingContentStyles } from "../../cfleadmanagementandopportunity/src/StaffingProjectPricing.web";
import { StyledValidationTypography } from "./Cfcommissioncalculator2.web";
import { FieldWrapper } from "./ClientItem.web";

type IProps = {
    partnerName: string;
    month: string;
    newClientsNumber: string;
    totalClients: string;
    handleFieldChange: (name: string, value: string) => void;
    isClientNumberMatch:boolean;
    clientsLength: number;
    width: Parameters<Theme["breakpoints"]["only"]>[0];
};
type IState = {};
// Customizable Area End

class PartnerInformation extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { partnerName, month, newClientsNumber, totalClients, isClientNumberMatch, clientsLength, handleFieldChange } = this.props;
        const isMobileView = isWidthDown("sm", this.props.width, true);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <StyledBox>
                    <InputWithLabel
                        data-test-id="partner-name-input"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        inputType="text"
                        inputValue={partnerName}
                        label="Partner Name"
                        onChange={(value) => handleFieldChange("partnerName", value)}
                    />
                    <InputWithLabel
                        data-test-id="month-input"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        inputType="date"
                        inputValue={month}
                        label="Month"
                        onChange={(value) => handleFieldChange("month", value)}
                    />
                </StyledBox>
                <StyledBox>
                    <FieldWrapper sx={{width:isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}}>
                        <InputWithLabel
                            data-test-id="new-clients-input"
                            containerStyle={projectPricingContentStyles.fullWidth}
                            inputType="number"
                            inputValue={newClientsNumber}
                            label="New Clients"
                            onChange={(value) => handleFieldChange("newClientsNumber", value)}
                        />
                        {
                            clientsLength !== parseInt(newClientsNumber) && !isClientNumberMatch &&
                            <StyledValidationTypography>New clients field value and added client's number does not match</StyledValidationTypography>
                        }
                    </FieldWrapper>
                    <InputWithLabel
                        data-test-id="total-clients-input"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        inputType="number"
                        inputValue={totalClients}
                        label="Total Clients"
                        onChange={(value) => handleFieldChange("totalClients", value)}
                    />
                </StyledBox>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export default withWidth()(PartnerInformation)
// Customizable Area End
