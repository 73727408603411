// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import EmailVerification from "../../blocks/otp-input-confirmation/src/EmailVerification.web";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfleadmanagementandopportunity from "../../blocks/cfleadmanagementandopportunity/src/Cfleadmanagementandopportunity";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import Download from "../../blocks/download/src/Download";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Notes2 from "../../blocks/notes2/src/Notes2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Organisationhierarchy from "../../blocks/organisationhierarchy/src/Organisationhierarchy";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailLoginBlock from "../../blocks/email-account-login/src/EmailLoginBlock.web"
import Cfcommissioncalculator2 from "../../blocks/cfcommissioncalculator2/src/Cfcommissioncalculator2";
import CommissionStatus from "../../blocks/cfcommissioncalculator2/src/CommissionStatus";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ContactList from "../../blocks/contactlist/src/ContactList";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Cfpipelinemanagement from "../../blocks/cfpipelinemanagement/src/Cfpipelinemanagement";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Forecastingandbudgeting from "../../blocks/forecastingandbudgeting/src/Forecastingandbudgeting";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import Multipageforms2 from "../../blocks/multipageforms2/src/Multipageforms2";
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web'
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import EmailAccountResistration from "../../blocks/email-account-registration/src/EmailAccountResistration.web";
import DashBoardBlock from "../../blocks/dashboard/src/Dashboard.web";
import PeriodicReport from "../../blocks/dashboard/src/PeriodicReport.web";
import DashboardAnalytics from "../../blocks/dashboard/src/DashboardAnalytics.web";
import Statistics from "../../blocks/dashboard/src/Statistics.web";
import UserOnBoard from '../../blocks/user-profile-basic/src/UserOnBoard.web';
import ManageUser from '../../blocks/user-profile-basic/src/ManageUser.web';
import UpdatePassword from '../../blocks/user-profile-basic/src/UpdatePassword.web';
import ProfileBio from "../../blocks/educational-user-profile/src/ProfileBio.web";
import AdminProfile from "../../blocks/educational-user-profile/src/AdminProfile.web";
import ProfileDetails from '../../blocks/user-profile-basic/src/ProfileDetails.web'
import SalesEnablements from "../../blocks/documentstorage/src/SalesEnablement.web"
// import CommissionBlock from "../../blocks/cfcommissioncalculator2/src/CommissionCalculator.web";
import PrivacyPolicyBlock from "../../blocks/termsconditions/src/PrivacyPolicy.web"
import TermsAndConditions from "../../blocks/termsconditions/src/TermsAndConditions.web"
import OpportunityRegistration from "../../blocks/cfpipelinemanagement/src/OpportunitiesAndDeals.web";
import CreateOpportunities from "../../blocks/cfpipelinemanagement/src/CreateOpportunities.web"
import OpportunitiesList from "../../blocks/cfpipelinemanagement/src/OpportunitiesListing.web"
import MyRequests from "../../blocks/user-profile-basic/src/MyRequest.web"
import RequestDetail from "../../blocks/user-profile-basic/src/RequestDetails.web"

const routeMap = {
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OTPInputAuth: {
    component: EmailVerification,
    path: "/OTPInputAuth"
  },
  ProfileBio: {
    component: ProfileBio,
    path: "/ProfileBio"
  },
  AdminProfile: {
    component: AdminProfile,
    path: "/AdminProfile"
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: "/Contentmanagement3"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Cfleadmanagementandopportunity: {
    component: Cfleadmanagementandopportunity,
    path: "/Cfleadmanagementandopportunity"
  },
  MultipleCurrencySupport: {
    component: MultipleCurrencySupport,
    path: "/MultipleCurrencySupport"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Notes2: {
    component: Notes2,
    path: "/Notes2"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Organisationhierarchy: {
    component: Organisationhierarchy,
    path: "/Organisationhierarchy"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailLoginBlock,
    path: "/Login"
  },
  Cfcommissioncalculator2: {
    component: Cfcommissioncalculator2,
    path: "/Cfcommissioncalculator2"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/bx_block_forgot_password/create_password"
  },
  ContactList: {
    component: ContactList,
    path: "/ContactList"
  },
  Dashboard: {
    component: DashBoardBlock,
    path: "/Dashboard"
  },
  CommissionStatus:{
    component:CommissionStatus,
    path:"/CommissionStatus"
  },
  PrivacyPolicy: {
    component: PrivacyPolicyBlock,
    path: "/PrivacyPolicy"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndconditions"
  },
  Cfpipelinemanagement: {
    component: Cfpipelinemanagement,
    path: "/Cfpipelinemanagement"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Forecastingandbudgeting: {
    component: Forecastingandbudgeting,
    path: "/Forecastingandbudgeting"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  EmailAccountRegistration: {
    component: EmailAccountResistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: "/PhotoLibrary"
  },
  Multilevelapproval2: {
    component: Multilevelapproval2,
    path: "/Multilevelapproval2"
  },
  Multipageforms2: {
    component: Multipageforms2,
    path: "/Multipageforms2"
  },
  // Customizable Area Start
  PeriodicTable: {
    component: PeriodicReport,
    path: "/PeriodicReport"
  },
  DashboardAnalytics: {
    component: DashboardAnalytics,
    path: "/DashboardAnalytics"
  },
  Statistics: {
    component: Statistics,
    path: "/Statistics"
  },
  UserOnBoard: {
    component: UserOnBoard,
    path: "/UserOnBoard"
  },
  ManageUser: {
    component: ManageUser,
    path: "/ManageUser"
  },
  ProfileDetails: {
    component: ProfileDetails,
    path: "/ProfileDetails"
  },
  SalesEnablements: {
    component: SalesEnablements,
    path: "/SalesEnablements",
  },
  OpportunityRegistration: {
    component: OpportunityRegistration,
    path: "/OpportunityRegistration"
  },
  UpdatePassword: {
    component: UpdatePassword,
    path: "/UpdatePassword"
  },
  CreateOpportunities:{
    component:CreateOpportunities,
    path:"/CreateOpportunities"
  },
  CreateOpportunitiesFromPriceProject:{
    component:CreateOpportunities,
    path:"/CreateOpportunitiesFromPriceProject"
  },
  ViewOpportunity:{
    component:CreateOpportunities,
    path:"/ViewOpportunity"
  },
  OpportunitiesList:{
    component:OpportunitiesList,
    path:"/OpportunitiesList"
  },
  EditOpportunity:{
    component:CreateOpportunities,
    path:"/EditOpportunity"
  },
  MyRequests: {
    component: MyRequests,
    path: "/MyRequests"
  },
  RequestDetail: {
    component: RequestDetail,
    path: "/RequestDetail"
  },
  // Customizable Area End

  Home: {
    component: EmailLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        {/* <TopNav /> */}
        <ToastContainer toastStyle={{
          width: '270px',
          height: '42px',
        }} />
        {WebRoutesGenerator({ routeMap, isAuthenticated: sessionStorage.getItem('token') ? true : false })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
