// Customizable Area Start
//@ts-nocheck
import {
    Box,
    Button,
    Input,
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
    InputLabel,
    Grid
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { suatainextLogo, cheackBox, cheackCheckbox } from "./assets";
import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController.web";
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const BpIcon = styled('span')(({ theme }) => ({
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
            `url(${cheackBox})`,
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: 'transparent',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
            `url(${cheackCheckbox})`,
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: 'transparent',
    },
});

export class EmailLoginBlock extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <Grid container className={`${classes.containerFlex}`} maxWidth="sm">
                    <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }} className={`${classes.mainBoxFlex}`} >
                        <Box sx={{margin:'auto', height:'53.117%'}}>
                            <Box sx={{ marginBottom: '80px' }}>
                            <img src={suatainextLogo} alt="suatainextLogo" />
                        </Box>
                        <Box className={`${classes.maintext}`}>
                            <Typography className={`${classes.lableText}`} variant="h4" component="h2">{this.labelTitle}</Typography>
                            <Typography className={`${classes.descText}`} variant="subtitle1" component="div">{this.labelHeader}</Typography>
                        </Box>
                        <Box sx={{ marginTop: '20px', width: '100%' }}>
                        {this.validationEmailAndPsw()}
                        </Box>
                        <Box component='form' sx={{ width: "100%", paddingTop: "20px" }}>
                            <Box sx={{ paddingBottom: '15px' }}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailLabelText}`}>Email</InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input
                                    data-test-id="txtInputEmail"
                                    placeholder={"Enter your email"}
                                    fullWidth={true}
                                    value={this.state.email}
                                    {...this.txtEmailAddressProps}
                                    disableUnderline
                                    className={ 
                                        this.state.emailError || this.state.validationMsgShow
                                        ? `${this.props.classes.redInputMain} ` : this.checkValidationClass()
                                    } 
                                />
                            </Box>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailLabelText}`}>Password</InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input
                                    disableUnderline
                                    className={this.cheackPasswordValidation()}
                                    data-test-id="txtInputPassword"
                                    type={this.passwordType()}
                                    placeholder={"Password"}
                                    fullWidth={true}
                                    value={this.state.password}
                                    {...this.loginPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                {...this.passwordHideShow}
                                                edge="end"
                                                data-test-id="txtInputPasswordhideShow"
                                            >
                                                {this.visibleinvisibleIcon()}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                            <Box className={`${classes.forgotPassword}`}>
                                <Box
                                    className={`${classes.mainremenberText}`}
                                >
                                    <Checkbox
                                        data-test-id={"btnRememberMe"}
                                        {...this.txtRemeberMe}
                                        icon={<BpIcon />}
                                        checkedIcon={<BpCheckedIcon />}
                                        checked={this.state.checkedRememberMe}
                                        className={`${classes.checkedColor}`}
                                    />{" "}
                                    <Typography component="div" className={`${classes.remenberText}`} >{this.rememberText}</Typography>
                                </Box>
                                <Box
                                    data-test-id={"btnForgotPassword"}
                                    {...this.forgotbuttonNavigate}
                                    tabindex="0"
                                >
                                    <Typography component="div" className={`${classes.forgottext}`} >Forgot password?</Typography>
                                </Box>
                            </Box>
                            <Box className={`${classes.signIpBtn}`} >
                                <Button
                                    id={"resetPasswordButton"}
                                    variant="contained"
                                    className={`${classes.signupText}`}
                                    fullWidth
                                    {...this.signInBtn}
                                >
                                    Sign In {/*UI Engine::From Sketch*/}
                                </Button>
                            </Box>
                            <Box className={`${classes.dontHaveAccountText}`}>
                                <Typography className={`${classes.dontAccountText}`} >{this.dontHaveAccount}{" "}</Typography>
                                <Typography data-test-id="txtsignUptxt" className={`${classes.signLinkText}`} {...this.NavigatetoSignUp}>{this.SignUplink}</Typography>
                            </Box>
                        </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} order={{ xs: 2, md: 2 }} className={`${classes.MainImageFlex}`}>
                        {this.state.loginImage=='' ?
                        <Skeleton variant='rectangular' width='100%' height='100%'/>
                            :
                        <img id="bannerImage" src={this.state.loginImage} alt=" "/>
                        }
                    </Grid>
                </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const EmailAccountLoginBlockStyles = (theme: any) => ({
    containerFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '100%',
    },
    mainBoxFlex: {
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '10% 0px',
        margin: 'auto',
        justifyContent: 'center',
        height:'min-content'
    },
    MainImageFlex: {
        display: 'flex',
        minHeight: '100vh',
        width: '50%',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        '& img': {
            width: '100%',
            height: '100%'
        }
    },
    loginImge: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: '27% 27%'
    },
    lableText: {
        letterSpacing: '0px !important',
        color: '#000',
        textAlign: 'left',
        fontFamily: 'Inter !important',
        fontStyle: 'normal',
        fontWeight: '700 !important',
        fontSize: '36px !important',
    },
    descText: {
        color: '#475569',
        textAlign: 'left',
        fontFamily: 'Inter !important',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        marginTop: '12px !important',
    },
    maintext: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '360px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    inputMain: {
        border: '1px solid #dfdfdf',
        padding: '10px',
        borderRadius: '8px',
        height: '45px',
        width: '360px',
        fontFamily: 'Inter !important',
        '&::placeholder': {
            fontFamily: 'Inter !important',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%', 
        },
    },
    inputEmailMain: {
        border: '1px solid #dfdfdf',
        padding: '10px',
        borderRadius: '8px',
        height: '45px',
        width: '360px',
        fontFamily: 'Inter !important',
        '&::placeholder': {
            fontFamily: 'Inter !important',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%', 
        },
    },
    redInputMain: {
        border: '1px solid #F87171',
        padding: '10px',
        borderRadius: '8px',
        height: '45px',
        fontFamily: 'Inter !important',
        '&::placeholder': {
            fontFamily: 'Inter !important',
        },
        width:"360px"
    },
    emailLabelText: {
        marginBottom: '5px',
        color: '#334155 !important',
        fontFamily: 'Inter !important',
        fontStyle: 'normal',
        fontWeight: '700 !important',
        fontSize: '14px !important',
        lineHeight: '22px',
    },
    requireColor: {
        color: 'red',
    },
    forgotPassword: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '24px',
        marginBottom: '24px',
        width: '360px',
        [theme.breakpoints.down('md')]: {
            width: '100%', 
            flexDirection: "column"
        },
    },
    forgottext: {
        fontSize: '14px !important',
        color: '#2E17B5 !important',
        fontWeight: '400 !important',
        cursor: 'pointer !important',
        fontFamily: 'Inter !important',
    },
    remenberText: {
        fontSize: '14px !important',
        color: '#0F172A',
        fontWeight: '400 !important',
        marginLeft: '8px !important',
        fontFamily: 'Inter !important',
    },
    mainremenberText: {
        display: 'flex',
        alignItems: 'center',
    },
    checkedColor: {
        padding: '0px !important',
        color: '#8833FF !important',
        fontFamily: "'Inter' !important",
        borderRadius: "6px",
    },
    signIpBtn: {
        display: 'flex',
        flexDirection: 'column',
    },
    signupText: {
        background: 'linear-gradient(90deg, rgba(51,60,92,1) 36%, rgba(10,12,35,1) 100%)',
        color: 'white !important',
        fontWeight: 700,
        textTransform: 'capitalize',
        height: '44px',
        borderRadius: '8px !important',
        fontFamily: "'Inter' !important",
        padding: "10px 16px 10px 16px",
        width: '360px',
        [theme.breakpoints.down('md')]: {
            width: '100%', 
        },
        fontSize:"16px"

    },
    dontAccountText: {
        fontWeight: 400,
        fontSize: 14,
        color: '#475569',
        textAlign: 'center',
        fontFamily: "'Inter' !important"
    },
    signLinkText: {
        fontFamily: "'Inter' !important",
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        color: '#2E17B5',
        cursor: 'pointer',
        paddingLeft: 5
    },
    dontHaveAccountText: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: 32,
        width: '360px',
        [theme.breakpoints.down('md')]: {
            width: '100%', 
        },
    },
    LogoSize: {
        '& img': {
            marginBottom: '80px !important'
        }
    },
    aerltBorder: {
        marginTop: 10
    },
    alertTextDesign: {
        background: '#FEE2E2',
        borderLeft: '4px solid #DC2626',
        borderRadius: 4,
        color: '#DC2626',
        fontWeight: 400,
        fontSize: 12,
        width: 360,
        height: 42,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 15,
        paddingLeft: 16
    },
    validationBox: {
        width: '100%',
        height: '42px',
        borderRadius: '6px',
        borderLeft: '4px solid #DC2626',
        backgroundColor: '#FEE2E2',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'inter',
        fontSize: '12px',
        color: '#DC2626',
        paddingLeft: '10px',
    }
})

//@ts-ignore
export default withStyles(EmailAccountLoginBlockStyles)(EmailLoginBlock);

  // Customizable Area End