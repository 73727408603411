import { Box, FormControl, Select, MenuItem, Typography, styled, withStyles} from "@material-ui/core";
import React, { Component, ReactNode } from "react";

type ClassNameMap<ClassKey extends string = string> = Record<ClassKey, string>;

type IProps = {
    containerStyle: object;
    label: string;
    data: string[];
    value: string;
    onChange: (value: string) => void;
    className?: string;
    isRequired?: boolean;
    classes: ClassNameMap
};
type IState = {};

class SelectWithLabel extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
    }
    render(): ReactNode {
        const { containerStyle, className, classes, label, data, value, onChange, isRequired } = this.props;
        
        return (
            <Box className={className} sx={containerStyle}>
                <Box sx={webStyles.labelRow}>
                    <StyledTypographyLabel>
                        {label}
                    </StyledTypographyLabel>
                    {
                        isRequired &&
                        <StyledTypographyReq variant="subtitle1">*</StyledTypographyReq>
                    }
                </Box>
                <FormControl fullWidth>
                    <StyledSelect
                        data-test-id="generic-select"
                        onChange={(event) => onChange(event.target.value as string)}
                        value={value}
                        disableUnderline
                        displayEmpty
                        MenuProps={{
                            classes:{paper:classes.root},
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              }
                        }}
                    >
                        {data.map((d) => (
                            <MenuItem key={d} value={d}>{d}</MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
            </Box>
        )
    }
};

const webStyles = {
    labelRow: {
        display: "flex",
        alignItems: "baseline"
    }
};

const DropdownStyles = {
    root:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"10px"
        },
        "& ul li":{
            borderRadius:"8px",
            fontSize: "14px",
            padding:"9px 20px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)"
        },
    }
}

const StyledTypographyLabel = styled(Typography)({
    "&": {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledTypographyReq = styled(Typography)({
    "&": {
        color: "red",
    }
});

const StyledSelect = styled(Select)({
    "&": {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
    },
    "& .MuiSelect-select:focus":{
        backgroundColor:"transparent"
    },
    "@media(max-width: 960px)": {
        "&": {
            height: "40px"
        },
        "& .menu-wrapper .MuiPaper-root":{
            marginTop:"36px"
        }
    }
});

export default withStyles(DropdownStyles)(SelectWithLabel);