import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { PresentationImage, Resources, digitalMarketing, imgPasswordInVisible, imgPasswordVisible, sealsPresentation, viewAllBgImage } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  valueA: number;
  valueB: number;
  title: string;
  category: any | null;
  subCategory: any | null;
  selectedRequestDate: string;
  selectedDeliverydate: string;
  descriptionText: string;
  setPreviewUrl: string | null;
  setAttachedPreviewUrl: string | null;
  setSelectedFile: any | null;
  setAttachedFile: any | null;
  setIsDraggingAttach: boolean;
  setIsDragging: boolean;
  fileSizeLimit: string;
  attachedFileSizeLimit: string;
  categoryList: any[];
  subCategoryList: any[];
  filteredList: any[];
  isNoData: boolean;
  viewMore: boolean;
  showMore: number;
  token: string | null;
  errors: any;
  openPopup: boolean;
  messageText: string | null;
  isEdge:boolean;
  openAccordionIndex: number | null;
  subCategoryIdForViewMore:number | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DocumentstorageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestCategoryCallId: any;
  categoryAPICallId: any;
  contentAPICallId: any;
  postMarketingRequestId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      valueA: 0,
      valueB: 0,
      title:'',
      category: null,
      subCategory:null,
      selectedRequestDate: '',
      selectedDeliverydate: '',
      descriptionText: '',
      setPreviewUrl: null,
      setAttachedPreviewUrl: null,
      setSelectedFile: null,
      setAttachedFile: null,
      setIsDraggingAttach: false,
      setIsDragging: false,
      fileSizeLimit : '',
      attachedFileSizeLimit:'',
      categoryList: [],
      subCategoryList:[],
      filteredList: [],
      isNoData: false,
      viewMore: true,
      showMore: 6,
      token: '',
      errors: {
        title: false,
        description: false,
        category: false,
        subCategory: false,
        banner: false,
        attachment: false,
        requestDate: false,
        deliveryDate: false
      },
      openPopup: false,
      messageText: '',
      isEdge: typeof window !== 'undefined' && window.navigator.userAgent.includes('Edg'),
      openAccordionIndex: 0,
      subCategoryIdForViewMore:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    let token : any =await JSON.parse(sessionStorage.getItem('token') || '').token;
    let subcategoryId: number = JSON.parse(sessionStorage.getItem('routeDetails')||'').value;
    this.setState({token : token, valueA: subcategoryId-5})
    super.componentDidMount();
    this.categoryRequest();
    this.contentRequest(`/contents/?category=${subcategoryId}`)
    if (this.state.isEdge) {
      document.documentElement.classList.add('edge');
    }
  }

  componentWillReceiveProps(nextProps: any) {
    let subcategoryId: number = JSON.parse(sessionStorage.getItem('routeDetails')||'').value;
    this.setState({valueA: subcategoryId - 5})
    this.contentRequest(`/contents/?category=${subcategoryId}`)
  }

  categoryRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" : this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoryAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  contentRequest = (data: string) => {
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" : this.state.token
    };
    const endPoint = configJSON.getAllContentEndPoint + data + `?page=1&per_page=${this.state.showMore}`;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.contentAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.categoryAPICallId &&
      this.categoryAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        categoryList: responseJson1.data
      })
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.contentAPICallId &&
      this.contentAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      
      await this.handleContentData(responseJson2)
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.postMarketingRequestId &&
    this.postMarketingRequestId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ){
      let responseJson3 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson3?.success) {
        this.setState({
          openPopup: true,
          messageText: 'Your request has been received, please check my request section for updates'
        })
    } else {
      this.setState({
        openPopup: true,
        messageText: "Something went wrong, try again"
      })
    }
    }
  }
  handleClosePopup = {
    onClose: () => {
      this.setState({
        openPopup: false,
      })
    this.handleCancel()
    }
  }

  handleClosePopupClick = {
    onClick: () => {
      this.setState({
        openPopup: false,
      })
    this.handleCancel()
    }
  }

  handleContentData = async(responseJson: any) =>{
    const { subCategoryIdForViewMore } = this.state
    if(this.state.valueA !== 5){
      if(this.state.valueA !== 0){
        this.handleRenderSubCatList(subCategoryIdForViewMore,responseJson.data[0]?.sub_categories)
      } else {
        this.setState({
          filteredList: responseJson.data ?? [],
          viewMore: responseJson.meta?.view_more_contents,
          isNoData: responseJson?.data?.length > 0 ? false : true
        })
      }
    }
  }

  handleRenderSubCatList=(subCategoryIdForViewMore:number | null,responseJson:any)=>{
    const { filteredList } = this.state;
    if (subCategoryIdForViewMore) {
      if (responseJson) {
          const foundSubCatogorysData = responseJson.filter((item: { sub_category_id: number; }) => {
          return item.sub_category_id === subCategoryIdForViewMore;
        });
        if (foundSubCatogorysData.length > 0) {
            const foundIndexOf = filteredList?.findIndex((item) => item?.sub_category_id === subCategoryIdForViewMore);
            if (foundIndexOf !== -1) {
              filteredList[foundIndexOf] = foundSubCatogorysData[0];
              this.setState({ filteredList: filteredList },()=>{
                this.setState({subCategoryIdForViewMore:null})
              });
            }
        }
      }
    }
    else {
      this.setState({
        filteredList: responseJson ?? [],
        isNoData: responseJson.length > 0 ? false : true
      })
  }
  }

  removeAttachment = {
    onClick: (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({setAttachedFile: null})
    }
  };

  removeBanner = {
    onClick: (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({setSelectedFile: null})
    }
  };

  viewMoreCategory = () =>{
    this.setState({
      showMore: this.state.showMore + 6,
    },()=>{
      let endPoint = this.state.valueA == 0 ? `/all_contents` : `/contents/?category=${this.state.valueA+5}`;
      this.contentRequest(endPoint);
    })
   
  }

  txtInputWebProps1 = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps1 = {
    ...this.txtInputWebProps1,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps1 = this.isPlatformWeb()
    ? this.txtInputWebProps1
    : this.txtInputMobileProps1;

  btnShowHideProps1 = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps1.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps1.source = this.txtInputProps1.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps1 = {
    source: this.txtInputProps1.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps1 = {
    onPress: () => this.doButtonPressed1(),
  };

  doButtonPressed1() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleTitle = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if(event.target.value.length > 0){
        this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            title: false
        }
      }))
    }
      this.setState({title: event.target.value})
    },
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = event.target.value.trim();
      if(newValue.length == 0){
      this.setState({title: ''})
        this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            title: true
        }
      }))
    } else {
      this.setState({title: newValue})
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            title: false
        }
      }))
    }
    }
  };

  handleCreateContent= ()=>{

    const header = {
      "token" : this.state.token
    };
    const formData = new FormData();
    formData.append('category_id', this.state.category?.id);
    formData.append('sub_category_id', this.state.subCategory);
    formData.append('headline', this.state.title);
    formData.append('content', this.state.descriptionText);
    formData.append('user_request_date', moment(this.state.selectedRequestDate).format("ddd, DD MMM YYYY"));
    formData.append('user_delivery_date', moment(this.state.selectedDeliverydate).format("ddd, DD MMM YYYY"));
    formData.append('image', this.state.setSelectedFile);
    formData.append('attachment', this.state.setAttachedFile);
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
    );

    this.postMarketingRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addMarketingRequest
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleSubmit = async()=>{
    let { title, descriptionText, category, subCategory, setSelectedFile, setAttachedFile, selectedRequestDate, selectedDeliverydate} = this.state;
    this.setState({
      attachedFileSizeLimit: '',
      fileSizeLimit:'',
      errors: {
      title: title.trim().length> 0 ? false : true,
      description: descriptionText.trim().length> 0 ? false : true,
      category: category !== null ? false : true,
      subCategory: subCategory !== null ? false : true,
      banner: setSelectedFile == null ? true : false,
      attachment: setAttachedFile == null ? true : false,
      requestDate: selectedRequestDate.length> 0 ? false : true,
      deliveryDate: selectedDeliverydate.length> 0 ? false : true
    }},  () => {
      if(Object.values(this.state.errors)
    .some(item => item === true)){
      return;
    } else {
      this.handleCreateContent()
    }
  })
  }

  handleCancel =()=> {
    this.setState({
      selectedDeliverydate:'',
      selectedRequestDate:'',
      title:'',
      category:null,
      subCategory:null,
      descriptionText:'',
      setPreviewUrl: null,
      setAttachedPreviewUrl: null,
      setSelectedFile: null,
      setAttachedFile: null,
      setIsDraggingAttach: false,
      setIsDragging: false,
      fileSizeLimit : '',
      attachedFileSizeLimit: ''
    })
  } 

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({
      valueA: newValue,
      showMore : 6,
      isNoData: false,
      openAccordionIndex:0,
      subCategoryIdForViewMore:null
    },()=>{
      this.contentRequest(newValue == 0 ? "/all_contents" : `/contents/?category=${newValue+5}`);
    });
  };

  textInputCategory = (event: any)=> {
      this.setState({
        category: event?.target.value,
        subCategoryList: [...new Map(event?.target.value?.attributes?.sub_categories.map((item: any) => [item.id, item])).values()]
      });
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            category: false
        }
      }))
  };

  textInputSubcategory = (event: any)=> {
    this.setState({
      subCategory: event?.target.value
    });
    this.setState(prevState => ({
      errors: {
          ...prevState.errors,
          subCategory: false
      }
    }))
};

  handelRequestedDate = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        selectedRequestDate: event.target.value
      });
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            requestDate: false
        }
      }))
    }
  };

  handelDeliverydate = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        selectedDeliverydate: event.target.value
      });
      this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            deliveryDate: false
        }
      }))
    }
  };

  handelDescription = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if(event.target.value.length > 0){
        this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            description: false
        }
      }))
    }
      this.setState({descriptionText: event.target.value});
    },
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = event.target.value.trim();
      if(newValue.trim().length == 0){
      this.setState({descriptionText: ''})
        this.setState(prevState => ({
        errors: {
            ...prevState.errors,
            description: true
        }
      }))
    } else {
      this.setState({descriptionText: newValue})
      this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        description: false
    }
  }))
    }
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.valueA !== prevState.valueA) {
      this.handelChangeImage();
    }
  }

  handelChangeImage = () => {
    if (this.state.valueA == 0) {
      return viewAllBgImage
    } else if (this.state.valueA == 1 || this.state.valueA == 5) {
      return PresentationImage
    } else if (this.state.valueA == 2) {
      return sealsPresentation
    } else if (this.state.valueA == 3) {
      return digitalMarketing
    } else if (this.state.valueA == 4) {
      return Resources
    }
  }

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.files?.length == 0){
      return;
    } else {
    let fileType = event.target.files?.[0]?.type;
    if((fileType === 'image/png') || (fileType === 'image/jpg') || (fileType === 'image/jpeg')){
    const file: any = event.target.files?.[0];
    if (file?.size > 5000000) {
      this.setState({
        fileSizeLimit: 'File size should not exceed 5MB.'
      })
      return;
    }
    const fileName = file?.name;
    this.setState({
      setPreviewUrl : fileName,
      setSelectedFile: file
    })
    this.setState(prevState => ({
      errors: {
          ...prevState.errors,
          banner: false
      }
    }))
    this.setState({
      fileSizeLimit: ''
    })
  } else {
      this.setState({
        fileSizeLimit:"Please select valid file type"
      })
    }
  }
  };

  handleAttachChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileType = event.target.files?.[0]?.type;
    if(event.target.files?.length == 0 ){
      return;
    } else {
    if((fileType === 'image/png') || (fileType === 'image/jpg') || (fileType === 'image/jpeg') || (fileType === 'application/pdf')|| (fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') || (fileType === 'application/ppt') || (fileType === 'application/doc' || (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'))){
      const file: any = event.target.files?.[0];
    if (file?.size > 5000000) {
      this.setState({
        attachedFileSizeLimit: 'File size should not exceed 5MB.'
      })
      return;
    }
    const fileName = file?.name;
    this.setState({
      setAttachedPreviewUrl : fileName,
      setAttachedFile: file
    })
    this.setState(prevState => ({
      errors: {
          ...prevState.errors,
          attachment: false
      }
    }))
    this.setState({
      attachedFileSizeLimit: ''
    })
      } else {
        this.setState({
          attachedFileSizeLimit:"Please select valid file type"
        })
      }
    }
  };

  handleDragLeave = () => {
    this.setState({
      setIsDragging: false
    })
  };

  handleDragAttachLeave = () => {
    this.setState({
      setIsDraggingAttach: false
    })
  };

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({
      setIsDragging: true
    })
  };

  handleAttachDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({
      setIsDraggingAttach: true
    })
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({
      setIsDragging: false
    })
    const file = event.dataTransfer.files?.[0];
    let fileType = event.dataTransfer.files?.[0]?.type;
    if((fileType === 'image/png') || (fileType === 'image/jpg') || (fileType === 'image/jpeg')){

    if (file?.size > 5000000) {
      this.setState({
        fileSizeLimit: 'File size should not exceed 5MB.'
      })
    } else {
      this.setState({
        setSelectedFile: file,
        fileSizeLimit: ''
      })
    }
    } else {
      this.setState({
        fileSizeLimit: 'Please select valid file type'
      })
      return;
  }
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          setPreviewUrl: reader.result as string
        })
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        setPreviewUrl: null
      })
    }
  };

  handleAttachDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({
      setIsDraggingAttach: false
    })

    const file = event.dataTransfer.files?.[0];
    let fileType = event.dataTransfer.files?.[0]?.type;
    if((fileType === 'image/png') || (fileType === 'image/jpg') || (fileType === 'image/jpeg') || (fileType === 'application/pdf')|| (fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') || (fileType === 'application/ppt') || (fileType === 'application/doc' || (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'))){

      if (file?.size > 5000000) {
        this.setState({
          attachedFileSizeLimit: 'File size should not exceed 5MB.'
        })
      } else {
        this.setState({
          setAttachedFile: file,
          attachedFileSizeLimit: ''
        })
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({
            setAttachedPreviewUrl: reader.result as string
          })
        };
        reader.readAsDataURL(file);
      } else {
        this.setState({
          setAttachedPreviewUrl: null
        })
      }
    } else {
      this.setState({ attachedFileSizeLimit:"Please select valid file type"})
    }
  };

  handleAccordionChange = (index: number) => {
    this.setState((prevState) => ({
        openAccordionIndex: prevState.openAccordionIndex === index ? null : index,
        subCategoryIdForViewMore:null
    }));
  };

  viewMoreSubCategory = (subCategoryId:number) =>{
    const { filteredList } = this.state
    this.setState({
      showMore: this.state.showMore + 6,
      subCategoryIdForViewMore:subCategoryId,
    },()=>{
      let endPoint = this.state.valueA == 0 ? `/all_contents` : `/contents/?category=${this.state.valueA+5}`;
      this.contentRequest(endPoint);
    })
  }
  
  // Customizable Area End
}
