import React, { Component, ReactNode } from "react";

import {
    Box,
    Checkbox,
    Grid,
    Typography,
    styled, isWidthDown, Theme, withWidth
} from "@material-ui/core";

type IProps = {
    title: string;
    checked: boolean;
    onClick: (text: string) => void;
    width:Parameters<Theme["breakpoints"]["only"]>[0];
};

type IState = {};

class PricingTypeBox extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render(): ReactNode {
        const { title, checked, onClick } = this.props;
        const isMobileView = isWidthDown("sm", this.props.width, true);

        return (
            <Grid item xs={isMobileView ? 12 : 6}>
                <Box data-test-id="pricing-type-box" onClick={() => onClick(title)}>
                    <Box sx={webStyles.wrapper}>
                        <BoxContainer className={checked ? "active-border" : "inactive-border"} sx={webStyles.container}>
                            <Box sx={webStyles.pricingTypeColumn}>
                                <CustomCheckbox
                                    disableRipple
                                    checked={checked}
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                />
                                <StyledTypography>
                                    {title}
                                </StyledTypography>
                            </Box>
                        </BoxContainer>
                    </Box>
                </Box>
            </Grid>
        );
    }
}

const webStyles = {
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
        "& *": {
            boxSizing: "border-box",
        }
    },
    container: {
        borderRadius: "8px",
        width: "100%",
        height: "130px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "32px",
        cursor: "pointer",
    },
    pricingTypeColumn: {
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    }
}

const BoxContainer = styled(Box)({
    "&.inactive-border": {
        border: "1px solid #CBD5E1",
        background: "#FFFFFF",
    },
    "&.active-border, &:hover": {
        border: "1px solid transparent",
        background: "linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box",
        backgroundColor: "transparent"
    },
    "@media(max-width: 960px)":{
        "&":{
            padding: "16px",
            height: "110px"
        }
    }
});

const CustomCheckbox = styled(Checkbox)({
    "& .MuiSvgIcon-root" : {
        height:"20px",
        width: "20px"
    },
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${require("../../../components/src/assets/svg/Unchecked.svg")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    "&.Mui-checked .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${require("../../../components/src/assets/svg/Checked.svg")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    "&": {
        justifyContent: "flex-start",
        padding: 0,
        color: "#0A0528!important"
    },
    "&:hover": {
        backgroundColor: "transparent!important",
        borderRadius: " 6px"
    }
});

const StyledTypography = styled(Typography)({
    "&": {
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "16px"
    },
    "@media(max-width: 960px)":{
        "&":{
            fontSize:"14px"
        }
    }
});

export default withWidth()(PricingTypeBox);
