import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import createRequestMessage from "../../../framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { IMyRequestListAttributeAll } from "./types";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
const ErrorToastStyles = {
    toast: {
        width: 'max-content',
        maxHeight: '42px !important',   
        minHeight: '42px',
        color: '#0A0528',
        borderRadius: '8px',
        fontSize: '16px'
    }
}

const ErrorToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    style: ErrorToastStyles.toast,
    hideProgressBar: true,
    closeButton: false
}

interface S {
    token: string,
    myRequestArray:IMyRequestListAttributeAll[],
    currentPage: number;
    perPage:number;
    isLoading: boolean,
    myRequestArrayMeta:any,
    numberOfButtons:any,
    pageNumberSet:any;
    pagneNumberValidation:boolean;
    listSearchValue:string
    listSearchError:string;
    listSearchValueError:boolean;
    sortBy:string;
    requestDetailsData:any,
    myRequestId:string | number,
    requestDetailsDataMeta:any
}

interface SS {
    id: any;
}

export default class MyRequestController extends BlockComponent<
    Props,
    S,
    SS
> {

    myRequestListApiCallId: string | null;
    timeout: ReturnType<typeof setTimeout>;
    isSortActivate:boolean
    isSearchActivate:boolean
    requestDetailApiCallId: string | null;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            token: "",
            myRequestArray:[],
            currentPage: 1,
            perPage:10,
            isLoading: false,
            myRequestArrayMeta:'',
            numberOfButtons:[],
            pageNumberSet:1,
            pagneNumberValidation:false,
            listSearchError:'',
            listSearchValue:'',
            listSearchValueError:false,
            sortBy:'desc',
            requestDetailsData:null,
            myRequestId:'',
            requestDetailsDataMeta:''

            };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.myRequestListApiCallId = "";
        this.isSortActivate = false
        this.isSearchActivate = false
        this.timeout = setTimeout(() => { });
        this.requestDetailApiCallId= ''
    }

    async receive(_from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.myRequestListApiCallId: {
                    this.myRequestListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({
                                myRequestArray: responseJson.data,
                                myRequestArrayMeta: responseJson.meta,
                                pagneNumberValidation: false,
                              });
                            this.handleLoadingStop()
                            this.handleNumberOfButtonMr()

                        },
                        onFail: () => toast.error(`${responseJson.errors[0].profile}`, ErrorToastOptions),
                    });
                    break;
                }
                case this.requestDetailApiCallId: {
                  this.requestDetailApiCallId = null;
                  handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            
                            this.setState({
                                requestDetailsData:responseJson.data,
                                requestDetailsDataMeta:responseJson.meta,
                            });
                            this.handleLoadingStop()
                            this.handleNumberOfButtonMr()
                        },
                        onFail: () => toast.error(`${responseJson.errors[0].profile}`, ErrorToastOptions),
                    
                    });
                  break;
              }
            
          }
        }
    }
   
    

    handleGetMyRequestList = (currentPage:number,perpage:number) => {
        this.setState({ isLoading: true})
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data);
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            }
            else {
                throw error;
            }
        }
        const header = {
            token:id?.token 
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
        this.myRequestListApiCallId = requestMessage.messageId;
        let endPintType = `${configJSON.endPointApiGetMyRequestList}?page=${currentPage}&per_page=${perpage}`;

        if (this.isSortActivate) {
          endPintType = `${endPintType}&sort=${this.state.sortBy}`;
        }
        
        if (this.state.listSearchValue.length > 0) {
          endPintType = `${endPintType}&search=${this.state.listSearchValue}`;
        }

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: endPintType,
            method: configJSON.methodTypeApiGetMyRequestList,
        });
    }

    handleDefultPagesMr =() => {
        this.setState({
            perPage:10,
            currentPage:1,
            pageNumberSet:1,
            pagneNumberValidation:false
        })
      }
    
      handlePaginationMr = (event:any) => {
        const perpage = event.target.value;
        this.setState({pageNumberSet:1 , perPage: perpage, currentPage:1, isLoading: true }, () => {
            
            this.handleGetMyRequestList(this.state.currentPage,perpage)
            this.handleNumberOfButtonMr()
        })
    };

    handlePageNextPreviousBtnMr = (isNext:any) => {
        const { currentPage, perPage ,numberOfButtons} = this.state;
        let updatedPage:any
        if (isNext === "nextPage" && currentPage < numberOfButtons  ) {
            updatedPage = currentPage + 1;
        }
        else if (isNext === "previousPage" && currentPage > 1) {
            updatedPage = currentPage - 1;
        }
        if (updatedPage !== undefined) {
            this.setState({  pageNumberSet : updatedPage,currentPage: updatedPage, isLoading: true }, () => {
                this.handleGetMyRequestList(updatedPage, perPage);
            });
        }
    };

    handleNumberOfButtonMr = () => {
        const { myRequestArrayMeta ,perPage } = this.state;
        const totalRecords = parseInt(myRequestArrayMeta?.total_contents_count)
        const numberOfButtons = Math.ceil(totalRecords / perPage);
        this.setState({ numberOfButtons });
      };


      handlePageNumberInputMr = (event: any) => {
        const { numberOfButtons } = this.state;
        const inputValue = event.target.value.replace(/\D/g, ""); 
        const isWithinRange = inputValue !== null && inputValue > 0 && inputValue <= numberOfButtons;
        this.setState(
          {
            pageNumberSet: inputValue,
            pagneNumberValidation: !isWithinRange, 
          },
          () => {
            this.handleGetPageByInputNumberMr();
          }
        );
    };

    handleGetPageByInputNumberMr = () => {
        const { numberOfButtons, pagneNumberValidation } = this.state;
        const inputValue = this.state.pageNumberSet;
      
        setTimeout(() => {
          if (
            !pagneNumberValidation &&
            inputValue !== null &&
            inputValue > 0 &&
            inputValue <= numberOfButtons &&
            this.state.pageNumberSet === inputValue

          ) {
            this.setState(
              {
                currentPage: inputValue,
              },
              () => {
                this.handleGetMyRequestList(this.state.currentPage, this.state.perPage);
              }
            );
          }
        }, 1500);
    };

    handleBlurEvent = () => {
        this.setState({
           listSearchValueError:false,
        })
    }

    handleSearchMr = (value:string) => {
        const { sortBy } = this.state;
        let filteredText = value.replace(/^\s+/, '').replace(/\s+/g, ' ');
        let condition = filteredText !== value 
        
        this.setState({ listSearchValue: filteredText,
            listSearchValueError: condition,
        });
        if(value.length > 0){
            this.isSearchActivate= true
        }
        if (condition){
            return;
        }
        this.setState({ listSearchValue: filteredText,
            isLoading: true,
            currentPage: 1, pageNumberSet: 1,
        });
        if (this.timeout)
            clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const data:any = sessionStorage.getItem('token')
            let id;
            try {
                id = JSON.parse(data);
            }
            catch (error) {
                if (error instanceof SyntaxError) {
                    console.error('Invalid JSON:', error.message);
                }
                else {
                    throw error;
                }
            }
            const header = {
                token: id === null || id === void 0 ? void 0 : id.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.myRequestListApiCallId = requestMessage.messageId;
            let endPintType = `${configJSON.endPointApiGetMyRequestList}?sort=${sortBy}&page=${this.state.currentPage}&per_page=${this.state.perPage}`;
           
            if (this.state.listSearchValue.length > 0) {
                  endPintType = `${endPintType}&search=${this.state.listSearchValue}`;
            }

            createRequestMessage({
                header,
                requestMessage: requestMessage,
                endPoint: endPintType,
                method: configJSON.methodTypeApiGetMyRequestList,
            });
        }, 1000);
    };

    handleSortByMr = (text:any) => {
        this.isSortActivate= true
        this.setState({sortBy:text.target.value},()=>{
            this.handleSearchMr(this.state.listSearchValue)
        })

    }
    async componentDidMount() {
        const {currentPage,perPage}= this.state
       
        const data:any = sessionStorage.getItem('token')
            let id;
            try {
                id = JSON.parse(data);
            }
            catch (error) {
                if (error instanceof SyntaxError) {
                    console.error('Invalid JSON:', error.message, id);
                }
                else {
                    throw error;
                }
            }

        this.handleGetMyRequestList(currentPage,perPage)
        const dataType = sessionStorage.getItem("myRequestId");
        if ( dataType !== null ){
            this.handleGetRequestDetialById(dataType)
        }

    }
  

    handleViewDetailNavigation = (id:string | number ) => {
        this.setState({myRequestId:id})
        this.props.navigation.navigate('RequestDetail');
        sessionStorage.setItem('myRequestId', JSON.stringify(id));

    };  

    handleGetRequestRecordsByNav = (id:string)=> {
        this.handleGetRequestDetialById(id)
    }
    handleGetRequestDetialById =( myRequestid:string | number) => {

        this.setState({ isLoading: true})
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data);
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            }
            else {
                throw error;
            }
        }
        const header = {
            token:id?.token 
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
        this.requestDetailApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.endPointApiGetRequestDetailById+myRequestid,
            method: configJSON.methodTypeApiGetMyRequestList,
        });
    }

    handleLoadingStop =() => {
        setTimeout(() => {
            this.setState({ isLoading:false });
          }, 1500);
    }
    
}
