import React from "react";
import { Box, Button, Tab, Tabs, Typography, styled } from "@material-ui/core";
import { grupemeetBgEffect } from "./assets";
import AccordionBlock from '../src/Components/AccordionOpp.web'
import OpportunitiesTable from '../src/Components/OpportunitiesTable.web'
import DealRegistrationBlock from '../src/Components/DealRegistrationTable.web'
import Layout from "../../../components/src/Layout.web";
import { configJSON } from "./CfpipelinemanagementController";

import CfpipelinemanagementController, { Props } from "./CfpipelinemanagementController.web";

const AntTabsDeal = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-flexContainer': {
    gap: '32px !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    border: '2px solid transparent',
    height: '44px',
    background: 'linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box',
    borderBottom: 0,
    borderRadius: '8px 8px 0px 0px',
    width: '200px',
  },
  "@media(max-width: 798px)": {
    "& .MuiTabs-flexContainer": {
        gap:'0px !important'
    }
},
});

export const AntTabDeal = styled(Tab)({
  textTransform: 'none',
  minWidth: 0,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  zIndex: 999,
  color: '#4b515f',
  '&.Mui-selected': {
    color: '#232527',
  },
});

export class OpportunitiesAndDeals extends CfpipelinemanagementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const breadcrumbChange =  this.state.valueA == 0 ? configJSON.OpportunitieRegistration : configJSON.DealRegistration
    const accordianText =  this.state.valueA == 0 ? "Opportunities" : "Deal"
    const headingText =  this.state.valueA == 0 ? "Partnership Opportunities" : "Deals Dashboard"

    return (
      <>
      <Layout
      breadcrumb={breadcrumbChange}
      navigation={this.props.navigation}
      title={""} 
      isDefultCard={false}
     >
      <DealManagementField>
          <Box className="header-image">
            <Box className="header-image-text-button" style={{ position: 'absolute', paddingLeft: '24px' }}>
              <Typography className="heading-text">{headingText}</Typography>
              <Box>
                <Button data-test-id="navigateToListPage" onClick={() => this.handleNavigationDeal('CreateOpportunities')} className="register-your-opportunity-button" >Register Your Opportunity</Button>
              </Box>
            </Box>
            <Box className="header-background-image-container">
              <Box className="header-background-image">
                <img src={this.handleChangeImage()} alt="" />
              </Box >
              <Box className="header-background-shadow" >
                <img src={grupemeetBgEffect} alt="" />
              </Box >

            </Box>
          </Box>
          <Box className="container-Box">
            <AccordionBlock accordianText={accordianText}
              isDownloadModalOpen={this.state.isDownloadModalOpen}
              handleDownloadModalClose={this.handleDownloadModalClose}
              handleDownloadModalOpen={this.handleDownloadModalOpen}
              classes={undefined}
              toggleAccordion={this.toggleAccordion}
              isAccordionOpen={this.state.isAccordionOpen} 
            />
          </Box>

          <Box className="bottom-main-container">
            <Box>
              <Box className="tabs-container">
                <AntTabsDeal value={this.state.valueA} data-test-id="adminCompanytabs" onChange={this.handleTabChange} aria-label="ant example">
                  <AntTabDeal disableRipple label="Opportunities"  className="tabs-style"/>
                  <AntTabDeal disableRipple label="Deals" className="tabs-Deal-style" />
                </AntTabsDeal>
              </Box>
            </Box>

            <Box style={{ margin: '32px', paddingBottom: '32px' }}>
              {this.state.valueA == 1 ? (
                <Box className="card-main-container">
                  <DealRegistrationBlock
                    data-test-id="deal-table"
                    deal={this.state.dealList}
                    handleInputChange={this.handleSearch}
                    handleDealCommonNavigation={this.handleNavigationDeal}
                    handleSortDeal={this.handleSortClick}
                    sortByDeal={this.state.sortBy}
                    sortDirectionDeal={this.state.sortDirection} 
                    handleGetDealRecord={this.handleGetOpportunitesRecord}
                    isLoading={this.state.isLoading}
                    listSearchValueErrorDeal = {this.state.listSearchValueError}
                    listSearchValueDeal={this.state.listSearchValue}
                    handleBlurEventDeal={this.handleBlurEvent}
                  />
                </Box>
              ) : (
                <Box className="card-main-container">
                  <OpportunitiesTable
                    data-test-id="opportunity-table"
                    Opportunites={this.state.opportunityList}
                    handleInputChange={this.handleSearch}
                    handleDealCommonNavigation={this.handleNavigationDeal}
                    handleSort={this.handleSortClick}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    handleGetOpportunitesRecord={this.handleGetOpportunitesRecord}
                    isOpportunityAction={this.state.isDealRegistraion}
                    OpportunityActionAnchorEl={this.state.dealRegisrationAnchorEl}
                    OpportunityAction={this.dealAction}
                    handleDealActionMenuClose={this.handleDealActionMenuClose}
                    handleProposedAmountModalOpen={this.handleProposedAmountOpenCommon}
                    handleCloseModalOpp={this.handleCloseModalOppCommon}
                    handleProposedAmount={this.handleProposedAmountOpp}
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    isLoading={this.state.isLoading}
                    listSearchValueErrorOpp = {this.state.listSearchValueError}
                    listSearchValueOpp={this.state.listSearchValue}
                    handleBlurEventOpp={this.handleBlurEvent}
                  />
                </Box>
              )}
            </Box>
          </Box>
      </DealManagementField>
    </Layout>
    
    </>
    )
  }
}
export const OpportunitiesStyle = ({
  HeaderImageTextButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "173px",
    width: "96%",
    zIndex: 4,
  },
  registrationOpBtn: {
    background: "linear-gradient(90deg, #007EEF 0%, #2AE4FF 100%)",
    color: "white",
    fontWeight: "700",
    textTransform: "capitalize",
    borderRadius: "8px",
    padding: '7px 12px',
    fontSize: '16px',
    fontFamily: "Inter, sans-serif",
    height: '44px'
  },
  headerBgImgEffect: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    height: "100%"
  },
  headerBgImgEffectFront: {
    position: 'relative',
    zIndex: 1,
  },
  headerBgImg: {
    width: '100%',
    height: '347px',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
    },
    posititon: "relative"
  }
  , oppDealBoxContainer: {
    display: 'flex',
    gap: '48px',
    background: '#E5E5E5',
  },
  adminleftSideContainer: {
    width: '85%'
  },
  HeaderImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  hadeingTxt: {
    fontSize: '36px',
    fontWeight: '700',
    fontFamily: 'Inter',
    color: '#fff',
    width: '50%'
  },
  descriptionTxt: {
    fontSize: '10px',
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#fff'
  },
  bottomMainContainer: {
    backgroundColor: '#fff',
    height: 'auto',
    borderRadius: '8px',
    margin: '32px 0',
  },
  cardMainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '32px'
  },
  descriptiontxt: {
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#475569',
  },
})

export const DealManagementField = styled(Box)({
  "&": {
    width: 'calc(92vw - 120px)'
  },
  "& .left-side-container": {
    width: 'calc(92vw - 120px)'
  },
  "& .header-image": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  "& .header-image-text-button": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "173px",
    width: "96%",
    zIndex: 4
  },
  "& .heading-text": {
    fontSize: '36px',
    fontWeight: '700',
    fontFamily: 'Inter',
    color: '#fff',
    width: '50%'
  },
  "& .register-your-opportunity-button": {
    background: "linear-gradient(90deg, #007EEF 0%, #2AE4FF 100%)",
    color: "white",
    fontWeight: "700",
    textTransform: "capitalize",
    borderRadius: "8px",
    padding: '7px 12px',
    fontSize: '16px',
    fontFamily: "Inter, sans-serif",
    height: '44px'
  },
  "& .header-background-image-container": {
    width: '100%',
    height: '347px',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
    },
    posititon: "relative"
  },
  "& .header-background-shadow": {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    height: "100%"
  },
  "& .header-background-image": {
    position: 'relative',
    zIndex: 1,
  },
  "& .bottom-main-container": {
    backgroundColor: '#fff',
    height: 'auto',
    borderRadius: '8px',
    margin: '32px 0',
    marginTop: "23px",
  },
  "& .card-main-container": {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '32px',  
  },
  "& .tabs-container": {
    bgcolor: '#fff',
    padding: '32px 32px 0 32px',
    borderRadius: '8px'
  },
  "& .tabs-style":{
    width:"154px",
    height:"44px"
  },
  "& .tabs-Deal-style":{
    width:"179px",
    height:"44px"
  },
  "& .container-Box":{
    marginTop: '38px'
  },
  "@media(max-width: 960px)": {
    "&": {
      fontSize: "14px"
    },
    
  },
  /* Section 1: Mobile and Tablet */
 "@media (max-width: 768px)": {
  "& .heading-text":{
    fontSize:"10px"
  },
  "& .register-your-opportunity-button": {
    fontSize: '10px',
    height: '32px'
  },
  "& .header-image-text-button": {
    marginTop: "90px",
  },
  "& .header-background-image-container": {
    height: "210px",
  },
  "& .tabs-style":{
    width:"94px",
    fontSize: "12px"
  },
  "& .tabs-Deal-style":{
    width:"62px",
    height:"44px",
    fontSize: "12px"
  },
},
'@media (max-width: 388px)': {
  "&": {
    width: 'calc(91vw - 120px)'
  },
  "& .actions .actionchild": {
    display:'block'
  },
  "& .filter-button": {
    marginTop:'10px'
  },
  "& .header-background-image-container": {
    height: "175px",
  },
},
/* Section 2: Desktop */
"@media (min-width: 769px)": {

  }

});

export default OpportunitiesAndDeals;