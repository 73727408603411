// Customizable Area Start
import React from "react";
import Layout from "../../../components/src/Layout.web";

import { CommissionStatusBreadcrumb } from "./constants";
import CommissionStatusController, {Props, configJSON} from "./CommissionStatusController.web";
import { Box, Button, CircularProgress, Input, InputAdornment, Popover, Table, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@material-ui/core";
import Search from '@material-ui/icons/Search';
import { calendarIcon, downloadIcon, emptyTableIcon, filterDownActiveIcon, filterDownIcon, filterUpActiveIcon, filterUpIcon, greenTickIcon, rejectIcon, sortingIcon } from "./assets";
import EmptyData from "../../../components/src/EmptyData.web";
import { IClientNameSorting } from "./types";
import StyledMenuComponent from "../../../components/src/StyledMenuComponent.web";

import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css"
import FilterTag from "../../../components/src/FilterTag.web";
import StatusTableBody, { CustomCheckbox } from "./StatusTableBody.web";
import PaginationContainer from "../../../components/src/PaginationContainer.web";
import { CSVLink } from "react-csv";
// Customizable Area End

export default class CommissionStatus extends CommissionStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnTrueElement = (condition: boolean,firstElement: React.ReactNode, secondElement:React.ReactNode) => {
    if(condition) return firstElement
    return secondElement
  }

  handleReturnTrueString = (condition:boolean, firstImage:string, secondImage:string) =>{
    if(condition) return firstImage
    return secondImage
  }

  acceptRejectAll = ()=> {
    const {acceptRejectIds, isAllChecked } = this.state
    let buttonShowHide :boolean = false
    if(isAllChecked && acceptRejectIds.length === 1 ){
      buttonShowHide = true
    }
    return acceptRejectIds.length > 1  || buttonShowHide ? (
      <>
        <Button
          onClick={()=> {this.handleAcceptRejectAction("0",false,"accepted","rejectedAll")}}
          data-test-id="acceptdAll-button"
          variant="outlined"
          className="acceptRejectAll-button"
          startIcon={<img src={greenTickIcon} alt="accept" />}
        >
          Accept All
        </Button>
        <Button
          onClick={()=> {this.handleAcceptRejectAction("0",false,"rejected","rejectedAll")}}
          data-test-id="rejectedAll-button"
          variant="outlined"
          className="acceptRejectAll-button"
          startIcon={<img src={rejectIcon} alt="reject" />}
        >
            Reject All
        </Button>
        </>
    ) : null
  }

    checkAllRecords =()=> {
      const {isAllChecked,statusList} =this.state
      const pendingEntries = statusList.some(entry => entry.attributes.status === 'pending');
      return pendingEntries ?  (
        <>
          <CustomCheckbox
            data-test-id="toggle-all-checkboxes"
            disableRipple
            checked={isAllChecked}
            onChange={this.handleToggleIsAllSelected}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </>
      ) : null
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      isLoading, 
      statusList,
      currentPage,
      shownItems, 
      numberOfAllItems, 
      totalPageNumber, 
      sortingClientName, 
      sortingDate, 
      searchValue, 
      dateFilterValue, 
      dateAnchorEl, 
      clientNameAnchorEl, 
      isAllChecked,
      csvArray,
      csvArrayHeaders
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledWrapper>
        <Layout
          breadcrumb={CommissionStatusBreadcrumb}
          navigation={this.props.navigation}
        >
          <>
            <Box className="header">
              <Typography className="header-title">
                COMMISSION STATUS
              </Typography>
              <Box className="filter-container">
                <Input
                    data-test-id="search-input"
                    value={searchValue}
                    onChange={(event)=>this.handleSearch(event.target.value)}
                    placeholder="Search"
                    fullWidth={true}
                    disableUnderline
                    className="search-input"
                    startAdornment={<InputAdornment position="start">
                        <Search style={webStyles.colorGray} />
                    </InputAdornment>}
                />
                <Button
                    disabled={numberOfAllItems === 0 }
                    data-test-id="download-button"
                    variant="outlined"
                    className="download-button"
                    startIcon={<img src={downloadIcon} alt="download" />}
                >
                  <CSVLink className="download-button-text" data={csvArray} filename={"commission-status.csv"} headers={csvArrayHeaders}>Download</CSVLink>
                </Button> 
                { this.acceptRejectAll() }                
                <DateRangePicker
                  data-test-id="date-picker"
                  toggleAs={()=><Button data-test-id="date-filter-button" className="date-filter-button"><img src={calendarIcon} alt="calendar-filter" /></Button>}
                  showOneCalendar
                  ranges={[]}
                  placement="bottomEnd"
                  onOk={(value)=>this.handleDateFilter(value)}
                  locale={configJSON.datePickerLocale}
                />
              </Box>
            </Box>
            {
              (sortingClientName !== null || sortingDate !== null || dateFilterValue.length > 0) &&
              <Box className="filter-tags-container">
                <FilterTag
                  data-test-id="remove-all-filters-button"
                  label="Clear All Filter"
                  labelColor="#DC2626"
                  backgroundColor="#FEE2E2"
                  onClick={()=>this.handleRemoveFilters("all")}
                />
                {
                  dateFilterValue.length > 0 &&
                  <FilterTag
                    data-test-id="remove-date-filter-button"
                    label={dateFilterValue[0] + " - " + dateFilterValue[1]}
                    labelColor="#0A0528"
                    backgroundColor="#E2E8F0"
                    onClick={()=>this.handleRemoveFilters("date")}
                  />
                }
              </Box>
            }
            {
              isLoading ? <Box className="loading-container"><CircularProgress /></Box> :
              this.handleReturnTrueElement(
                statusList.length > 0,
                <StyledTableContainer>
                  <>
                      <Table aria-label="Closing Deals">
                          <StyledTableHead>
                              <TableRow>
                                  <TableCell align="center">
                                   {this.checkAllRecords()} 
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box className="cell-with-filter"> 
                                      <>Client Name</>
                                      <Box onClick={(event)=>this.handleFilterMenuOpen("clientNameAnchorEl",event)} data-test-id="client-name-sort" className="filter-icons-container">
                                          <img src={this.handleReturnTrueString(sortingClientName === "A to Z",filterUpActiveIcon,filterUpIcon)} alt="filter-up" />
                                          <img src={this.handleReturnTrueString(sortingClientName === "Z to A",filterDownActiveIcon,filterDownIcon)} alt="filter-down" />
                                      </Box>
                                      <StyledMenuComponent
                                        anchorEl={clientNameAnchorEl}
                                        onClose={()=>this.handleFilterMenuClose("clientNameAnchorEl")}
                                      >
                                        {
                                            configJSON.clientNameSortingOptions.map((item:IClientNameSorting)=>(
                                                <Box key={this.handleReturnTrueString(item !== null,item as string,"key")} onClick={()=> this.handleSorting("sortingClientName", "clientNameAnchorEl", item as string)} data-test-id="client-name-sort-menu-item" className={`filter-menu-item ${this.handleReturnTrueString(item === sortingClientName,"selected","")} `}>{item}</Box>
                                            ))
                                        } 
                                      </StyledMenuComponent>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box className="cell-with-filter"> 
                                      <>Commission Amount</>
                                      <Box data-test-id="commission-amount-filter">
                                        <img src={sortingIcon} alt="commission-amount-filter" />
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box className="cell-with-filter"> 
                                      <>Commission Cycle</>
                                      <Box data-test-id="commission-cycle-filter">
                                        <img src={sortingIcon} alt="commission-cycle-filter" />
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box className="cell-with-filter"> 
                                      <>Date</>
                                      <Box onClick={(event)=>this.handleFilterMenuOpen("dateAnchorEl",event)} data-test-id="date-sort" className="filter-icons-container">
                                          <img src={this.handleReturnTrueString(sortingDate === "Asc",filterUpActiveIcon,filterUpIcon)} alt="filter-up" />
                                          <img src={this.handleReturnTrueString(sortingDate === "Desc",filterDownActiveIcon,filterDownIcon)} alt="filter-down" />
                                      </Box>
                                      <StyledMenuComponent
                                        anchorEl={dateAnchorEl}
                                        onClose={()=>this.handleFilterMenuClose("dateAnchorEl")}
                                      >
                                        {
                                            configJSON.dateSortingOptions.map((item:IClientNameSorting)=>(
                                                <Box key={this.handleReturnTrueString(item !== null,item as string,"key")} onClick={()=> this.handleSorting("sortingDate", "dateAnchorEl", item as string)} data-test-id="date-sort-menu-item" className={`filter-menu-item ${this.handleReturnTrueString(item === sortingDate,"selected","")} `}>{item}</Box>
                                            ))
                                        } 
                                      </StyledMenuComponent>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Box className="cell-with-filter"> 
                                      <>Recurring</>
                                    </Box>
                                  </TableCell>
                                  <TableCell align="left">Action</TableCell>
                              </TableRow>
                          </StyledTableHead>
                          <StatusTableBody
                            data-test-id="table-body"
                            data={statusList}
                            handleToggleCheck={this.handleToggleCheck}
                            handleAcceptRejectAction={this.handleAcceptRejectAction}
                            isAllChecked={isAllChecked}
                          />
                      </Table>
                      <PaginationContainer
                        data-test-id="pagination-container"
                        shownItems={shownItems}
                        onChange={(value:string)=>this.handlePerPageSelection(value)}
                        viewPerPageOptions={configJSON.paginationSelectOptions}
                        currentPage={currentPage}
                        from={currentPage*10-9}
                        to={(currentPage-1)*10 + statusList.length}
                        numberOfAllItems={numberOfAllItems}
                        totalPageNumber={totalPageNumber}
                        onNext={()=>this.handlePagination(currentPage+1)}
                        onPrev={()=>this.handlePagination(currentPage-1)}
                        onEnter={()=>{}}
                        pageNumberSet={1}
                        isPaginationButton={false}
                        pagneNumberValidation={false}
                      />
                  </>
                </StyledTableContainer>,
                <EmptyData label="No result found" description="We couldn't find any matches. please try another filters!" image={emptyTableIcon}/>
              )
            }
          </>
        </Layout>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = {
  colorGray: { color: "gray" },
  tableRow: {
    textAlign:"center"
  },
  menuPosition: {
    marginTop: "-45px"
  },
  menuWidth: {
      width: "230px"
  }
}

const StyledWrapper = styled(Box)({
  "& .right-area": {
    maxWidth: "calc(100vw - 120px)"
  },
  "& .breadcrumb-container ~ div":{
    maxWidth:"calc(100vw - 120px)"
  },
  "& .header":{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    "& .header-title":{
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '28px',
      color: '#0A0528',
    },
    "& .filter-container":{
      display:"flex",
      alignItems:"center",
      gap:"12px"
    },
  },
  "& .download-button-text":{
    color: "black !important",
    textDecoration: "none !important"
  },
  "& .download-button":{
      padding: '5px 15px',
      width: '143px',
      height: '44px',
      border: '1px solid transparent',
      background: "linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box",
      borderRadius: '8px',
      textTransform: 'capitalize'
  },
  "& .acceptRejectAll-button":{
    padding: '5px 15px',
    width: '121px',
    height: '44px',
    border: '1px solid black',
    borderRadius: '8px',
    textTransform: 'capitalize'
},
  "& .date-filter-button":{
    height:"44px",
    width:"44px",
    minWidth:"44px",
    borderRadius:"8px",
    border:"1px solid",
    background: "linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #364161, #06081F) border-box"
  },
  "& .search-input": {
    border: "1px solid #dfdfdf",
    borderRadius: "8px",
    padding: "10px 8px 10px  8px",
    height: "44px",
    fontFamily: "'Inter', sans-serif",
    width: "360px"
  },
  "& .filter-tags-container": {
    display:"flex",
    alignItems:"center",
    gap:"10px",
    marginTop:"20px",
    flexWrap:"wrap"
  },
  "& .actions-container":{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:"10px"
  },
  "& .accept-button, .reject-button":{
    color:"#0A0528",
    fontSize:"12px",
    lineHeight:"18px",
    fontFamily:"'Inter', sans-serif",
    height:"24px",
    minWidth:"unset",
    border:"1px solid #CBD5E1",
    borderRadius:"4px",
    textTransform:"capitalize",
    padding:"3px 8px 3px 6px",
    "& .MuiButton-startIcon":{
      marginRight:"4px",
      marginLeft:0
    },
  },
  "& .reject-button":{
    width:"70px",
    "& img":{
      width:"13px",
      height:"13px"
    },
    "&:hover":{
      borderColor:"#DC2626"
    }
  },
  "& .accept-button":{
    width:"75px",
    "& img":{
      width:"11.17px",
      height:"8.38px"
    },
    "&:hover":{
      borderColor:"#059669"
    }
  },
  "& table":{
      borderBottom:"1px solid rgba(203, 213, 225, 1)",
  },
  "& .loading-container": {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    height:"30vh"
  },
  "@media(max-width: 1121px)": {
    "& table":{
      display:"block",
      overflowX:"auto",
      whiteSpace:"nowrap"
    }
  },
  "@media(max-width: 1100px)": {
    "& .search-input": {
      width:"250px"
    }
  },
  "@media(max-width: 960px)": {
      "& .search-input": {
        width:"200px",
        height:"36px"
      },
      "& .date-filter-button":{
        height:"32px!important",
        width:"32px!important",
        minWidth:"32px!important"
      },
      "& .download-button":{
          width:"120px",
          height:"36px",
          fontSize:"12px!important"
      },
      "& .acceptRejectAll-button":{
        width:"120px",
        height:"36px",
        fontSize:"12px!important"
       },
      "& .header":{
        flexDirection:"column",
        gap:"20px",
        "& .header-title":{
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
  },
  "@media(max-width: 576px)": {
    "& .filter-container" : {
      flexWrap:"wrap",
      flexDirection:"column",
      alignItems:"center",
      width:"100%",
      "& .search-input": {
        width:"100%"
      }
    },
    "& .right-area>div:last-child>div":{
      padding:"12px"
    }
  }
});

const StyledTableContainer = styled(TableContainer)({
  "&": {
      marginTop: "32px",
      boxShadow: "none!important",
      border: "1px solid rgba(203, 213, 225, 1)",
      borderRadius:"8px"
  },
  "& tr th, & tr td":{
      fontSize:"12px!important",
      fontWeight:"400!important",
      lineHeight:"18px!important",
      whiteSpace: "nowrap"
  },
  "&>div:first-child": {
      margin:"30px"
  },
  "@media(max-width: 960px)": {
      "&": {
          marginTop: "18px"
      },
      "& tr th, & tr td":{
          fontSize:"10px!important",
          lineHeight:"16px!important"
      },
  }
});

const StyledTableHead = styled(TableHead)({
  "& tr":{
      backgroundColor:"#F8FAFC",
      color: "#475569"
  },
  "& tr th": {
      borderColor: "rgba(203, 213, 225, 1)",
      borderRight: "1px solid rgba(203, 213, 225, 1)",
      color: "#475569",
      padding:"8px 12px",
      fontFamily: "'Inter', sans-serif",
      fontSize:"14px!important",
      lineHeight:"18px!important",
      fontWeight:"700!important",
      height:"40px"
  },
  "& tr th:last-child": {
      borderRight: "none"
  },
  "& .cell-with-filter" : {
    display:"flex",
    justifyContent:"space-between",
    gap:"20px",
    alignItems:"center",
    width:"100%"
  },
  "& .filter-icons-container": {
    display:"flex",
    flexDirection:"column",
    cursor:"pointer"
  },
  "@media(max-width: 960px)": {
      "& tr th": {
          fontSize:"12px!important"
          // padding:"11px 6px"
      },
  }
});

export const StyledMenu = styled(Popover)({
  "& .MuiPaper-root": {
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
      border: "1px solid rgba(226, 232, 240, 1)",
      padding: "4px",
      boxSizing: "border-box",
      borderRadius: "8px",
      marginTop: "50px"
  },
  "& .MuiPaper-root>div:not(:last-child)": {
      marginBottom: "20px"
  },
  "& .filter-menu-item":{
      width:"100%",
      fontFamily:"'Inter', sans-serif",
      fontSize:"14px",
      padding:"9px 20px",
      cursor:"pointer"
  },
  "& .filter-menu-item:hover, & .filter-menu-item.selected":{
      backgroundColor: "rgba(241, 245, 249, 1)"
  },
  "@media(max-width: 960px)": {
      "& .filter-menu-item":{
          fontSize:"12px",
          padding:"6px 14px",
      },
  }
})
// Customizable Area End