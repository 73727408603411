import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled
} from "@material-ui/core";
import React, { Component } from "react";
import {
  emptyUserDashboardIcon,
  filterDownActiveIcon,
  filterDownIcon,
  filterUpActiveIcon,
  filterUpIcon
} from "./assets";
import EmptyData from "../../../components/src/EmptyData.web";
import { StyledTableContainer, StyledTableHead } from "./Statistics.web";
import { formatString } from "../../cfpipelinemanagement/src/constants";
import { opportunityFieldStyles } from "../../cfpipelinemanagement/src/Components/OpportunitiesTable.web";

type sorting = "up" | "down" | null;
type IProps = {
  registerOppTableData:any[],
  navigation: any;
};
type IState = {
  sortingOpportunityName: sorting;
  sortingStatus: sorting;
  sortingEndCustomerAccount: sorting;
};

const tableData = [
  {
    id: 1,
    opportunityId: "VAL2604-001",
    opportunityName: "Sustainext Digital - RPA",
    status: "Accepted",
    stage: "Agreement Signed",
    tierToPartner: "Valenta AI Pvt Ltd India",
    endCustomerAccount: "Sustainext digital",
    amount: "$7,540.00"
  },
  {
    id: 2,
    opportunityId: "VAL2604-001",
    opportunityName: "Sustainext Digital - RPA",
    status: "Proposed",
    stage: "Proposal Sent ",
    tierToPartner: "Valenta AI Pvt Ltd India",
    endCustomerAccount: "Sustainext digital",
    amount: "$7,540.00"
  },
  {
    id: 3,
    opportunityId: "VAL2604-001",
    opportunityName: "Sustainext Digital - RPA",
    status: "Rejected",
    stage: "Did not Received",
    tierToPartner: "Valenta AI Pvt Ltd India",
    endCustomerAccount: "Sustainext digital",
    amount: "$7,540.00"
  }
];

class RegisteredOpportunitiesCard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      sortingOpportunityName: null,
      sortingStatus: null,
      sortingEndCustomerAccount: null
    };
  }

  checkSorting = (value: sorting) => {
    let updateSorting: sorting = null;
    if (value === null) {
      updateSorting = "up";
    } else if (value === "up") {
      updateSorting = "down";
    }
    return updateSorting;
  };

  handleSorting = (
    value: "opportunityName" | "status" | "endCustomerAccount"
  ) => {
    if (value === "opportunityName") {
      const currentSorting = this.state.sortingOpportunityName;
      this.setState({
        sortingOpportunityName: this.checkSorting(currentSorting)
      });
    } else if (value === "status") {
      const currentSorting = this.state.sortingStatus;
      this.setState({ sortingStatus: this.checkSorting(currentSorting) });
    } else {
      const currentSorting = this.state.sortingEndCustomerAccount;
      this.setState({
        sortingEndCustomerAccount: this.checkSorting(currentSorting)
      });
    }
  };

  handleViewMore = () => {
    sessionStorage.setItem('DealRegistration', 'opportunity');
    this.props.navigation.navigate('OpportunitiesList');
  }

  render() {
    const {
      sortingOpportunityName,
      sortingStatus,
      sortingEndCustomerAccount
    } = this.state;
    const { registerOppTableData } = this.props;
    return (
      <StyledContainer className="registered-opportunities-container">
        <Box className="header">
          <Typography className="header-title">
            Registered Opportunities
          </Typography>
          <Button onClick={()=>{this.handleViewMore()}} data-test-id="da-View-More-id" className="view-all-button">
            View All
          </Button>
        </Box>
        <Box className="table-wrapper">
          <StyledTableContainer className="registered-opportunities-table-container">
            {registerOppTableData?.length > 0 ? (
              <>
                <Table aria-label="Recent Onboard Details">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="left">Opportunity ID</TableCell>
                      <TableCell align="left">
                        <Box className="cell-with-filter">
                          <>Opportunity Name</>
                          <Box
                            data-test-id="opportunity-name-sort"
                            onClick={() =>
                              this.handleSorting("opportunityName")
                            }
                            className="filter-icons-container"
                          >
                            <img
                              src={
                                sortingOpportunityName === "up"
                                  ? filterUpActiveIcon
                                  : filterUpIcon
                              }
                              alt="filter-up"
                            />
                            <img
                              src={
                                sortingOpportunityName === "down"
                                  ? filterDownActiveIcon
                                  : filterDownIcon
                              }
                              alt="filter-down"
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box className="cell-with-filter">
                          <>Status</>
                          <Box
                            data-test-id="status-sort"
                            onClick={() => this.handleSorting("status")}
                            className="filter-icons-container"
                          >
                            <img
                              src={
                                sortingStatus === "up"
                                  ? filterUpActiveIcon
                                  : filterUpIcon
                              }
                              alt="filter-up"
                            />
                            <img
                              src={
                                sortingStatus === "down"
                                  ? filterDownActiveIcon
                                  : filterDownIcon
                              }
                              alt="filter-down"
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">Stage</TableCell>
                      <TableCell align="left">Tier to Partner</TableCell>
                      <TableCell align="left">
                        <Box className="cell-with-filter">
                          <>End Customer Account</>
                          <Box
                            data-test-id="end-customer-account-sort"
                            onClick={() =>
                              this.handleSorting("endCustomerAccount")
                            }
                            className="filter-icons-container"
                          >
                            <img
                              src={
                                sortingEndCustomerAccount === "up"
                                  ? filterUpActiveIcon
                                  : filterUpIcon
                              }
                              alt="filter-up"
                            />
                            <img
                              src={
                                sortingEndCustomerAccount === "down"
                                  ? filterDownActiveIcon
                                  : filterDownIcon
                              }
                              alt="filter-down"
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">Amount</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <StyledTableBody>
                    {registerOppTableData?.map(row => (
                      <TableRow key={row.id}>
                        <TableCell style={{ width: "11.44%" }} align="left">
                          {row?.attributes?.opportunity_id}
                        </TableCell>
                        <TableCell
                          style={{ width: "19.61%" }}
                          align="left"
                        >
                        {row?.attributes?.opportunity_name}
                        </TableCell>
                        <TableCell style={{ width: "10.13%" }} align="center">
                          {/* @ts-ignore */}
                          <Box style={{ ...opportunityFieldStyles[row?.attributes?.status]}}>
                            { formatString(row?.attributes?.status) }
                          </Box>
                        </TableCell>
                        <TableCell style={{ width: "16.34%" }} align="left">
                            {/* @ts-ignore */}
                          <Box style={{ ...opportunityFieldStyles[row?.attributes?.stage]}}>
                             { formatString(row?.attributes?.stage) }
                          </Box>
                        </TableCell>
                        <TableCell
                          className="db-blue-text"
                          align="left"
                        >
                            {row?.attributes?.account_name}
                        </TableCell>
                        <TableCell style={{ width: "17%" }} align="left">
                           {row?.attributes?.contact_name}
                        </TableCell>
                        <TableCell style={{ width: "9.14%" }} align="left">
                           {row?.attributes?.currency_symbol||'$'} {row?.attributes?.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </StyledTableBody>
                </Table>
              </>
            ) : (
              <Box className="db-emptyDataStyle">
                <EmptyData
                  description="There are no entries created for now"
                  image={emptyUserDashboardIcon}
                />
              </Box>
            )}
          </StyledTableContainer>
        </Box>
      </StyledContainer>
    );
  }
}

export const StyledContainer = styled(Box)({
  "&.registered-opportunities-container": {
    width: "100%",
    padding: "0 48px",
    boxSizing: "border-box",
    marginTop: "20px"
  },
  "&": {
    boxSizing: "border-box"
  },
  "&.recent-onboard-container,&.closing-deals-container": {
    width: "calc(50% - 12px)"
  },
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .header-title": {
    fontSize: "20px",
    lineHeight: "28px",
    color: "#000"
  },
  "& .header .view-all-button": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#2E17B5"
  },
  "& table": {
    borderBottom: "1px solid rgba(203, 213, 225, 1)"
  },
  "& .db-blue-text": {
    color: "rgba(35, 17, 136, 1)",
    width: "16.34%" 
  },
  "& .db-emptyDataStyle":{
    height:"20% !important "
  },
  "@media(max-width: 960px)": {
    "& .header-title": {
      fontSize: "14px",
      lineHeight: "20px"
    },
    "& .header .view-all-button": {
      fontSize: "12px",
      lineHeight: "16px"
    },
    "& .buttons-container": {
      gap: "10px",
      "& .pagination-text": {
        fontSize: "12px"
      }
    }
  },
  "@media(max-width: 768px)": {
    "&.recent-onboard-container,&.closing-deals-container": {
      width: "100%"
    },
    "&": {
      width: "100%"
    }
  }
});

export const StyledTableBody = styled(TableBody)({
  "&.periodic-table-body": {
    display: "block",
    width: "100%",
    maxHeight: "598px",
    overflowY: "scroll"
  },
  "&.periodic-table-body tr": {
    width: "100%",
    display: "table",
    tableLayout: "fixed",
    height: "46px"
  },
  "& tr th": {
    color: "#475569"
  },
  "& tr td": {
    color: "#0A0528"
  },
  "& tr td, & tr th": {
    borderColor: "rgba(203, 213, 225, 1)",
    padding: "9px 12px!important",
    fontFamily: "'Inter', sans-serif",
    height: "46px",
    whiteSpace: "nowrap"
  },
  "&.statistics-table-body tr td, &.statistics-table-body tr th": {
    padding: "8px 10px!important",
    height: "64px",
    fontSize: "14px!important",
    lineHeight: "18px!important"
  },
  "& tr:last-child th, & tr:last-child td": {
    borderBottom: "none"
  },
  "&.periodic-table-body tr:last-of-type": {
    borderBottom: "1px solid rgba(203, 213, 225, 1)"
  },
  "& .status": {
    borderRadius: "16px",
    padding: "4px 8px",
    textAlign: "center"
  },
  "& .status.accepted": {
    color: "#059669",
    backgroundColor: "#D1FAE5",
    width: "71px",
    fontSize: "12px!important",
    lineHeiht: "18px!important"
  },
  "& .status.pending_review": {
    color: 'rgba(71, 85, 105, 1)',
    background: 'rgba(241, 245, 249, 1)',
  },
  "& .status.proposed": {
    color: "#D97706",
    backgroundColor: "#FEF3C7",
    width: "71px",
    fontSize: "12px!important",
    lineHeiht: "18px!important"
  },
  "& .status.rejected": {
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    width: "66px",
    fontSize: "12px!important",
    lineHeiht: "18px!important"
  },
  "& .stage.accepted": {
    color: "#059669"
  },
  "& .stage.proposed": {
    color: "#D97706"
  },
  "& .stage.rejected": {
    color: "#DC2626"
  },
  "&.earned-commission-table-body .view-button": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    textTransform: "capitalize",
    color: "#2E17B5",
    fontFamily: "Inter, sans-serif"
  },
  "&.statistics-table-body .opportunity-name": {
    color: "rgba(35, 17, 136, 1)"
  },
  "@media(max-width: 960px)": {
    "&.periodic-table-body": {
      display: "table-row-group"
    },
    "&.periodic-table-body tr": {
      display: "table-row"
    },
    "&.earned-commission-table-body .view-button": {
      fontSize: "12px",
      lineHeight: "16px"
    }
  }
});

export default RegisteredOpportunitiesCard;
