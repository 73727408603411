// Customizable Area Start
import { Box, CircularProgress, Typography, styled } from "@material-ui/core";
import DashboardController, { Props } from "./DashboardController.web";
import React from "react";
import LayoutWeb from "../../../components/src/Layout.web";
import InfoCard from "./InfoCard.web";
import {
  annualOpportunityIcon,
  closedDealsIcon,
  incomeGreenIcon,
  openDealsIcon
} from "./assets";
import SalesPipelineCard from "../../../components/src/SalesPipelineCard.web";
import DealGeneratedCard from "../../../components/src/DealGeneratedCard.web";
import { capitalizeWords, formatMoney, numberCommaSeparator, replaceEmptyKeyWithAnonymous, replaceQuarters } from "./constants";
import DealTypeCard from "../../../components/src/DealTypeCard.web"
import TotalNumberOfClients from "../../../components/src/TotalNumberOfClients.web";
import RevenuEarnedByEachUser from "./RevenuEarnedByEachUser.web"

// Customizable Area End

export default class DashBoardBlock extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  renderDealGeneratedData = (nameLabels:any)=> {
    return nameLabels?.map((items: any) => {
      const fullName = `${items.first_name} ${items.last_name}`;
      const capitalizedFullName = fullName.charAt(0).toUpperCase() + fullName.slice(1);
      
      if (capitalizedFullName.length <= 11) {
        return capitalizedFullName;
      } else {
        return capitalizedFullName.slice(0, 11) + '...';
      }
    });
  }

  render() {
    // Customizable Area Start
    const {totalNumberOfClients, totalCountsCardsData, addNewClientsData, dealTypeNumberData, dealTypeLabelData , isLoading, stageData, leadSourceData, salesPipelineData, dealGeneratedData, role }= this.state
    let salesData:any
    let monthLabel:any
    let monthNumber :any
    let stageLabel :any
    let stageNumber :any
    let leadSourceLabel :any
    let leadSourceNumber :any
    let addNewClientmonthLabel:any
    let addNewClientNumber :any
    let addNewClientData:any
    let labels:string []
    let numbers:number[]  

    salesData = replaceQuarters(salesPipelineData?.commisions)
    addNewClientData = replaceQuarters(addNewClientsData?.commisions);

    if (salesData !== null && salesData !== undefined) {
        let emptyMonthLabel: string[] = [""];
        let emptyMonthNumber: number[] = [0];
        labels = Object.keys(salesData).map(capitalizeWords);
        monthLabel = [...emptyMonthLabel, ...labels];
        numbers = Object.values(salesPipelineData?.commisions);
        monthNumber = [...emptyMonthNumber, ...numbers];
        
    }
    if (addNewClientData !== null && addNewClientData !== undefined) {
        let emptyMonthLabel: string[] = [""];
        let emptyMonthNumber: number[] = [0];
        let labels:any
        let numbers :any
        labels = Object.keys(addNewClientData).map(capitalizeWords);
        addNewClientmonthLabel = [...emptyMonthLabel, ...labels];
        numbers = Object.values(addNewClientsData?.commisions);
        addNewClientNumber = [...emptyMonthNumber, ...numbers];
    }

    const nameLabels = this.renderDealGeneratedData(dealGeneratedData?.org_user_accounts)

    const BarNumber= dealGeneratedData?.org_user_accounts?.map((items:any) => {
        return items?.opportunity_count;
    })
    
    if (stageData?.deals !== null && stageData?.deals !== undefined) {
      replaceEmptyKeyWithAnonymous(stageData.deals);
      stageLabel = Object.keys(stageData?.deals).map(capitalizeWords);
      stageNumber = Object.values(stageData?.deals);
    }

    if (leadSourceData?.deals !== null && leadSourceData?.deals !== undefined) {
      replaceEmptyKeyWithAnonymous(leadSourceData.deals);
      leadSourceLabel = Object.keys(leadSourceData?.deals).map(capitalizeWords);
      leadSourceNumber = Object.values(leadSourceData?.deals);
    }
    const dashboardCards = [
      {
        id: "1",
        label: "Active Opportunities",
        value: totalCountsCardsData?.deals_open ?? "0",
        icon: <img className="icon" src={openDealsIcon} alt="Active Opportunities" />,
        backgroundColor: "#EBE8FC"
      },
      {
        id: "2",
        label: "Deals Won",
        value: totalCountsCardsData?.deals_closed ?? "0",
        icon: <img className="icon" src={closedDealsIcon} alt="Deals Won" />,
        backgroundColor: "#FEF3C7"
      },
      {
        id: "3",
        label: "Total Clients",
        value: numberCommaSeparator(totalCountsCardsData?.annual_opportunity) + "%" || "0",
        icon: (
          <img
            className="icon"
            src={annualOpportunityIcon}
            alt="Total Clients"
          />
        ),
        backgroundColor: "#FEE2E2"
      },
      {
        id: "4",
        label: "Total Revenue Earned",
        value: "$"+ formatMoney(totalCountsCardsData?.total_income ?? "0" ) ,
        icon: <img className="icon" src={incomeGreenIcon} alt="Total Revenue Earned" />,
        backgroundColor: "#D1FAE5"
      }
    ];

    const  chartTitile = role !== "organization_user" ? "Number of deals/opportunities added by different users": "Number of deals/opportunities added"
    // Customizable Area End

    return (
      // Customizable Area Start
      <StyledBox>
        <LayoutWeb isDefultCard={false} navigation={this.props.navigation}>
        { isLoading ? (
            <Box className="loader-dashbard" >
                <CircularProgress />
            </Box>
          ) : (
            <>
            <Box className="header-container">
              <Typography className="header-title">Dashboard</Typography>
            </Box>
            { role !== "organization_user"  && 
                 <Box className="info-card-container">
                 {dashboardCards.map(item => (
                   <Box className="info-card-item" key={item.label}>
                     <InfoCard
                       label={item.label}
                       value={item.value}
                       icon={item.icon}
                       backgroundColor={item.backgroundColor}
                     />
                   </Box>
                 ))}
               </Box>
            }
           
            <Box className="cards-container">
              <TotalNumberOfClients
                totalNumberOfClientsProps={totalNumberOfClients}
              />
              <SalesPipelineCard 
                title={"Topline Revenue"}
                getSalesPipelineFun={this.handleGetSalesPipelineData} 
                monthLabelProp = { monthLabel}
                monthNumberProp ={monthNumber}
                graphType={true}
              />
               <SalesPipelineCard 
                title={"Number of new clients added"}
                getSalesPipelineFun={this.handleGetAddNewClientsData} 
                monthLabelProp = { addNewClientmonthLabel}
                monthNumberProp ={addNewClientNumber}
                graphType={false}

              />
              <DealGeneratedCard
                title={chartTitile}
                nameLabels={nameLabels}
                BarNumber={BarNumber}
              />
               <DealGeneratedCard
                title={"Number of deals/opportunities in each stage"}
                nameLabels={stageLabel}
                BarNumber={stageNumber}
              />
               <DealGeneratedCard
                title={"Deals/opportunity based on lead type"}
                nameLabels={leadSourceLabel}
                BarNumber={leadSourceNumber}
              />
                <DealTypeCard
                  handleGetdealTypeData={this.handleGetdealTypeData}
                  dealTypeLabelData={dealTypeLabelData}
                  dealTypeNumberData={dealTypeNumberData}
              />
              { role !== "organization_user"  && 
                <RevenuEarnedByEachUser
                  data-test-id="revenu-earned-by-each-user-id"
                  handleGetRevenueByEachUser={this.handleGetRevenueByEachUser}
                  revenuAllData={this.state.revenuAllData} 
                  handlePerPageSelectionRevenueByEU={this.handlePerPageSelectionRevenueByEU}
                  shownItems={this.state.shownItems}
                  pageNumberSet={this.state.pageNumberSet}
                  handlePaginationButtonRevenueByEU={this.handlePaginationButtonRevenueByEU}
                  currentPage={this.state.currentPage}
                  isTableLoading={this.state.isTableLoading}
                />
              }
            </Box>
            <Box className="cards-container">
             
            </Box>
          </>
          )
        }
        </LayoutWeb>
      </StyledBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const StyledBox = styled(Box)({
  "& .right-area": {
    maxWidth: "calc(100vw - 120px)",
    padding: "0 48px"
  },
  "& .header-container": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px"
  },
  "& .header-container .header-title": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.005em"
  },
  "& .notifications-button": {
    width: "44px",
    height: "44px",
    backgroundColor: "rgba(241, 245, 249, 1)",
    fontFamily: "Inter, sans-serif"
  },
  "& .info-card-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "24px"
  },
  "& .info-card-item": {
    width: "calc(25% - 18px)"
  },
  "& .cards-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
    marginTop: "20px"
  },
  "& .graph-labels-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "8px"
  },
  "& .loader-dashbard": {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    background: 'white',
    marginTop: "10px",
    height:"70%"
  },
  "@media(max-width: 960px)": {
    "& .info-card-container,& .cards-container": {
      gap: "12px"
    },
    "& .info-card-item": {
      width: "calc(50% - 6px)"
    },
    "& table": {
      display: "block",
      overflowX: "auto",
      whiteSpace: "nowrap"
    },
    "& .right-area": {
      // maxWidth: "calc(100vw - 136px)",
      padding: "0 8px"
    }
  },
  "@media(max-width: 768px)": {
    "& .info-card-item": {
      width: "100%"
    }
  }
});
// Customizable Area End
