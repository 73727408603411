// Customizable Area Start
import React from "react";
import { Paper, Box, Button, Checkbox, Chip, IconButton, Input, InputAdornment, MenuItem, RadioGroup, Select, Typography, withStyles, TextField } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Search from '@material-ui/icons/Search';
import OpportunitiesListingController, { Props } from "../OpportunitiesListingController.web";
import { styled } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { uncheadRadio, cheadRadio ,cheackBox ,cheackCheckbox} from "../assets";
export const configJSON = require("../../src/config");
export const tableStyleCss = require("./tableCssStyle");
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab";


const BpIconDeal = styled('span')(() => ({
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      `url(${cheackBox})`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
}));

const BpCheckedIconDeal = styled(BpIconDeal)({
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      `url(${cheackCheckbox})`,
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'transparent',
  },
});

const CustomRadioSIRevenuDeal = styled(Radio)({
  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
    color: "transparent",
    backgroundImage: `url(${uncheadRadio})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  "&.Mui-checked .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
    color: "transparent",
    backgroundImage: `url(${cheadRadio})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
    color: "transparent",
    backgroundImage: `url(${cheadRadio})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
});



export class OpportunitiesFilterBlock extends OpportunitiesListingController {
 
  constructor(props: Props) {
    super(props);
  }

 
  handleDelete = (e: React.MouseEvent, value: string, keyState: string) => {
    e.preventDefault();
    if ( Array.isArray(this.props.parentState[keyState])) {
        const updatedFilter = this.props.parentState[keyState].filter((item: any) => item !== value);
        this.props.updateTypeFilter(keyState, updatedFilter);
    } 
  };
  

  handleDeleteType = (e: React.MouseEvent, value: string) => {
    this.handleDelete(e, value, 'typeFilter');
  };

   handleDeleteStage = (e: React.MouseEvent, value: string) => {
    this.handleDelete(e, value, 'stageNameFilter');
  };
  
   handleDeleteLeadSource = (e: React.MouseEvent, value: string) => {
    this.handleDelete(e, value, 'leadSourceFilter');
  };

   handleRemoveAllChips = (e: React.MouseEvent,keyState:any) => {
    e.preventDefault();
    this.props.updateTypeFilter(keyState, []);
  };

  handleValidation =(value:any )  => {
    const { parentState } = this.props
    if(parentState.globleInputFieldValCheck) {
      
      return value ? true : false 
    }
    return true
  }

   renderCurrencyInput = (params:any) => (
    <TextField  className={this.props.classes.searchInpUnd}
        data-test-id="txtInputCurrency"
        sx={{ fontSize: "12px" }}
        {...params}
        placeholder="Currency"
        value={this.props.parentState.inrCurrencyFilterValue}
    />
)
 renderChips = (selectedItems:any, config:any, handleDelete:any,handleDeleteAll:any,typeFilter:string) => {
  return (
    <Box className={`${this.props.classes.drawerchipBox}`}>
      {selectedItems.map((value:any, index:any) => {
       const selectedLabel =
       typeFilter === "typeFilter"
         ? config.find((item:any) => item.value === value)?.label || ""
         : config[value] || "";

        return (
          <Chip
            key={value}
            label={selectedLabel || ''}
            clickable
            deleteIcon={
              <CloseIcon
                onMouseDown={(event) => event.stopPropagation()}
              />
            }
            onDelete={(e) => handleDelete(e, value)}
            data-test-id="handleDeleteTypeId"
            className={`${this.props.classes.chipStype}`}
          />
        );
      })}
       {selectedItems.length > 0 && (
          <IconButton
            size="small"
            onMouseDown={(event:any) => event.stopPropagation()}
            onClick={handleDeleteAll}
            className={`${this.props.classes.drawerMultipleRemoveBtn}`}
            data-test-id="handleDeleteAllLeadSourceId"
          >
            <CloseIcon />
          </IconButton>
        )}
    </Box>
  );
};

  renderFilterList = () => {
    const { parentState, classes }: any = this.props
    const { listofFilterArray }: any = this.state;
    
    return (
      <List>
            {listofFilterArray.map((ftrLabel: any) => (
              <div key={ftrLabel.index}>
                <ListItem style={{ height: "50px" }}>
                  <ListItemText className={`${classes.drawerListBox}`}>
                    <Checkbox className={`${classes.drawerCheckBox}`}
                      icon={<BpIconDeal />}
                      checkedIcon={<BpCheckedIconDeal />}
                      checked={ftrLabel && parentState?.isChecked?.[ftrLabel?.index]}
                      onChange={() => this.props.handleFilterFieldCheck(ftrLabel.index, ftrLabel)}
                      data-test-id="Filter-CheckId"
                    />
                    <Typography     
                      data-test-id="handleFilterFieldCheckLableId" 
                      className={`${classes.drawerListText}`} 
                      onClick={() => this.props.handleFilterFieldCheck(ftrLabel.index, ftrLabel)} 
                    >
                      {ftrLabel.label}
                    </Typography>
                  </ListItemText>
                </ListItem>

                {  this.renderCreatedTime(ftrLabel)  }
                {  this.renderClosingDate(ftrLabel)  }
                {  this.renderOppType(ftrLabel)      }
                {  this.renderAmount(ftrLabel)       }  
                {  this.renderStage(ftrLabel)        } 
                {  this.renderExpectedRevenue(ftrLabel) } 
                {  this.renderLeadSource(ftrLabel)  } 
                {  this.renderAccountName(ftrLabel) } 
                {  this.renderCurrency(ftrLabel)    } 
                {  this.renderDealOwne(ftrLabel)    } 
                {  this.renderDealName(ftrLabel)    }
                {  this.renderProbability(ftrLabel) }
                
                <Divider />
              </div>
            ))}
          </List>
    )
  }
  
  renderProbability = (ftrLabel:any)=> {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && ftrLabel.key === "probability" && (
        <><Box className={`${classes.drawerInputFieldsCheckBox}`}>
          <Select
            data-test-id="probabilityFilterID"
            fullWidth
            disableUnderline
            className={this.handleValidation(parentState.probabilityFilter) ? classes.admininputTxtMain :
              classes.reddrawerInputSelect}
            value={parentState.probabilityFilter}
            onChange={this.props.handleSelectInputChange("probabilityFilter","probability")}
            inputProps={{ id: 'txtInputFirstName' }}
            MenuProps={{
              classes:{paper:classes.root},
              getContentAnchorEl: null,
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              }
            }}>
            {parentState.probabilityArray?.map((data: any) => (
              <MenuItem key={data.value} value={data.value}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
          </Box>
          <Box>
            {parentState.probabilityFilterError && (
              <Typography className={`${classes.errormassagetext}`}>
                {parentState.probabilityFilterError}
              </Typography>
            )}
          </Box>
        </>
      )
      
    )}

  renderDealName= (ftrLabel:any)=> {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "deal_name") && (
        <><Box className={`${classes.drawerInputFieldsBoxText}`}>
          <Input
            data-test-id="dealNameFilterID"
            placeholder={"Type Here"}
            fullWidth={true}
            disableUnderline
            value={parentState.dealNameFilter}
            onChange={this.props.handleTextInputChange("dealNameFilter")}
            className={this.handleValidation(parentState.dealNameFilter)? `${classes.drawerinputTxtMain}` :
              `${classes.redInputMain}`} inputProps={{ maxLength: 256 }} />
          </Box>
          <Box>
            {parentState.dealNameFilterError && (
              <Typography className={`${classes.errormassagetext}`}>
                {parentState.dealNameFilterError}
              </Typography>
            )}
          </Box>
        </>
      )
    )
  }
  renderDealOwne =(ftrLabel:any)=> {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "deal_owner") && (
        <>
          <Box className={`${classes.drawerInputFieldsBoxText}`}>
            <Input
              data-test-id="txtFtrFirstName"
              value={parentState.dealOwnerNameFilter}
              onChange={this.props.handleTextInputChange("dealOwnerNameFilter")}
              placeholder={"Type Here"}
              fullWidth={true}
              disableUnderline
              className={this.handleValidation(parentState.dealOwnerNameFilter) ? `${classes.drawerinputTxtMain}` :
                `${classes.redInputMain}`}
              inputProps={{ maxLength: 256 }} />
          </Box>
          <Box>
            {parentState.dealOwnerNameFilterError && (
              <Typography className={`${classes.errormassagetext}`}>
                {parentState.dealOwnerNameFilterError}
              </Typography>
            )}
          </Box>
          </>
      )
    )
  }

  renderCurrency =(ftrLabel:any)=> {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "currency") && (
        <Box className={`${classes.drawerInputFieldsBoxText}`}>
          <Autocomplete
            className={this.handleValidation(parentState.inrCurrencyFilter) ? `${classes.countryLoginInput}` : `${classes.redcountryLoginInput}` }
            data-test-id="txtInputCurrency"
            style={{ fontSize: "12px" }}
            limitTags={1}
            options={parentState.currencyFormArry}
            getOptionLabel={(option:any)=>option}
            fullWidth
            onChange={(_,value)=>this.props.handleCurrencySelect(value)}
            renderInput={this.renderCurrencyInput}
            PaperComponent={(props:any) => {
                return (
                    <StyledPaper
                      {...props}
                    />
                  )
            }}
            value={parentState.inrCurrencyFilterValue}
          />
        </Box>
      )
    )
  }
  

  renderAccountName =(ftrLabel:any)=> {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "account_name") && (
        <><Box className={`${classes.drawerInputFieldsBoxText}`}>
          <Input
            data-test-id="txtFtrFirstName"
            value={parentState.firstNameFilter}
            onChange={this.props.handleTextInputChange("firstNameFilter")}
            placeholder={"Type Here"}
            fullWidth={true}
            disableUnderline
            className={this.handleValidation(parentState.firstNameFilter) ? `${classes.drawerinputTxtMain}` :
              `${classes.redInputMain}`}
            inputProps={{ maxLength: 256 }} />

        </Box>
          <Box>
            {parentState.firstNameFilterError && (
              <Typography className={`${classes.errormassagetext}`}>
                {parentState.firstNameFilterError}
              </Typography>
            )}
          </Box>
        </>

      )
    )
  }
  

  renderLeadSource =(ftrLabel:any)=> {
    const { parentState, classes }: any = this.props
    const isLeadSourceFilterArray = Array.isArray(parentState.leadSourceFilter);
    const className = isLeadSourceFilterArray && this.handleValidation(parentState.leadSourceFilter.length)
    ? classes.admininputTxtMainCustom
    : classes.redAdmininputTxtMainCustom;
    
    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "lead_source") && (
        <Box className={`${classes.drawerInputFieldsBoxText}`}>
          <Select
            fullWidth
            disableUnderline
            className={className}
            value={parentState.leadSourceFilter ||[]}
            onChange={this.props.handleSelectInputChange("leadSourceFilter","lead_source")}
            data-test-id="leadSourceFilterID"
            multiple
            MenuProps={{
              classes:{paper:classes.root},
              getContentAnchorEl: null,
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              }
            }}
            renderValue={(selected) => 
              this.renderChips(selected, configJSON.leadSourceArray, 
              this.handleDeleteLeadSource,
              (e:any) => this.handleRemoveAllChips(e, 'leadSourceFilter'),
              "leadSourceFilter"
            )}
            >
            {configJSON.leadSourceArray.map((data: any, index: any) => (
              <MenuItem key={data} value={index}>
                <Checkbox
                  className={`${classes.drawerMultipleCheckBox}`}
                  icon={<BpIconDeal />}
                  checkedIcon={<BpCheckedIconDeal />}
                  checked={Array.isArray(parentState.leadSourceFilter) && parentState.leadSourceFilter.includes(index)}
                  onChange={() => this.handleCheckboxChange(index)} 
                  data-test-id="Filter-Field-CheckId"
                />
                {data}
              </MenuItem>
            ))}

          </Select>
        </Box>
      )
    )
  }

  renderExpectedRevenue =(ftrLabel:any)=> {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "expected_revenue") && (
        <>
          <Box className={`${classes.drawerInputFieldsBoxText}`}>
            <FormControl>
              <RadioGroup>
                {configJSON.AmountRange.map((amountExp: any, index: any) => (
                  <Box key={index} className={`${classes.drawerRadioMain}`}>
                    <FormControlLabel id="expected_revenueId" 
                      data-test-id="handleExpectedRevanuFilterID"  
                      onChange={() => this.props.handleExpectedRevanuFilter(amountExp,index)}
                      value={amountExp.value} 
                      control={<CustomRadioSIRevenuDeal/>}
                      checked={index === parentState.expectedRevenueCheck} 
                      label={amountExp.label} />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box className={`${classes.drawerInputFieldsBoxText}`}>
            <Input
              data-test-id="minRevenueFilterID"
              placeholder={"Min."}
              fullWidth={true}
              disableUnderline
              className={this.handleValidation(parentState.minRevenueFilter) ? `${classes.drawerinputTxtMain}` :
                `${classes.redInputMain}`} inputProps={{ maxLength: 256 }}
              type="text"
              value={parentState.minRevenueFilter}
              onChange={this.props.handleNumberInputChange("minRevenueFilter","expected_revenue")}
            />
            <Input
              data-test-id="maxRvenueFilterID"
              placeholder={"Max."}
              fullWidth={true}
              disableUnderline
              className={ this.handleValidation(parentState.maxRvenueFilter) ? `${classes.drawerinputTxtMain}` :
                `${classes.redInputMain}`} inputProps={{ maxLength: 256 }}
              type="text"
              value={parentState.maxRvenueFilter}
              onChange={this.props.handleNumberInputChange("maxRvenueFilter","expected_revenue")}
            />
           
          </Box>
          <Box>
            {["minRevenueFilterError", "maxRvenueFilterError"].map((errorKey) => {
              const error = parentState[errorKey];
              return error && (
                <Typography key={errorKey} className={`${classes.errormassagetextAm}`}>
                  {error}
                </Typography>
              );
            }).find(Boolean)} 
          </Box>
        </>
      )
    )
  }

  renderStage =(ftrLabel:any)=> {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "stage") && (
        <Box className={`${classes.drawerInputFieldsBoxText}`}>
          <Select
            fullWidth
            disableUnderline
            className={
              Array.isArray(parentState.stageNameFilter) && this.handleValidation(parentState.stageNameFilter.length)
                ? classes.admininputTxtMainCustom
                : classes.redAdmininputTxtMainCustom
            }
            value={parentState.stageNameFilter || []}
            onChange={this.props.handleSelectInputChange("stageNameFilter","stage")} 
            data-test-id="stageNameFilterID"
            multiple
            MenuProps={{
              classes:{paper:classes.root},
              getContentAnchorEl: null,
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              }
            }}
            renderValue={(selected) => 
              this.renderChips(selected, configJSON.stageArray, 
              this.handleDeleteStage,
              (e:any) => this.handleRemoveAllChips(e, 'stageNameFilter'),
              "stageNameFilter"
            )}
            
            >
            {configJSON.stageArray.map((data: any, index: any) => (
              <MenuItem key={data} value={index}>
                <Checkbox
                  className={`${classes.drawerMultipleCheckBox}`}
                  icon={<BpIconDeal />}
                  checkedIcon={<BpCheckedIconDeal />}
                  checked={Array.isArray(parentState.stageNameFilter) && parentState.stageNameFilter.includes(index)}
                  onChange={() => this.handleCheckboxChange(index)} 
                  data-test-id="stageNameFilterId"
                />
                {" "}{data}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    )
  }

  renderAmount = (ftrLabel:any) => {
    const { parentState, classes }: any = this.props

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "amount") && (
        <>
          <Box className={`${classes.drawerInputFieldsBoxText}`}>
            <Select fullWidth disableUnderline
              data-test-id="operatorAMFilterID" 
              inputProps={{}}
              className={this.handleValidation(parentState.operatorAMFilter) ? classes.admininputTxtMain :
              classes.redInputMainNumber}
              value={parentState.operatorAMFilter}
              onChange={this.props.handleSelectInputChange("operatorAMFilter","amount")}
              MenuProps={{
                classes:{paper:classes.root},
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                }
            }}>
              {configJSON.AmountOperator.map((option: any, index: any) => (
                <MenuItem key={option} value={option.value}>
                  {option.operator}
                </MenuItem>
              ))}
           </Select>
          {['=','!=','<','<=','>','>='].includes(parentState.operatorAMFilter) && (                      
            <Input
              data-test-id="amountFilterID"
              placeholder={"0"}
              fullWidth={true}
              value={parentState.amountFilter}
              onChange={this.props.handleNumberInputChange("amountFilter","")}
              disableUnderline
              className={ this.handleValidation(parentState.amountFilter) ? `${classes.drawerinputTxtMain}` :
                `${classes.redInputMain}`} inputProps={{ maxLength: 256 }} 
            />
          )}

          {["between","not_between"].includes(parentState.operatorAMFilter) && (
            <Input
            data-test-id="amountFilterFromID"
            placeholder={"0"}
            fullWidth={true}
            value={parentState.amountFilterFrom}
            onChange={this.props.handleNumberInputChange("amountFilterFrom","")}
            disableUnderline
            className={this.handleValidation(parentState.amountFilterFrom) ? `${classes.drawerinputTxtMain}` :
              `${classes.redInputMain}`} inputProps={{ maxLength: 256 }} />
          )}  
          
          {["between","not_between"].includes(parentState.operatorAMFilter) && (
            <Input
            data-test-id="amountFilterToID"
            placeholder={"0"}
            fullWidth={true}
            value={parentState.amountFilterTo}
            onChange={this.props.handleNumberInputChange("amountFilterTo","")}
            disableUnderline
            className={this.handleValidation(parentState.amountFilterTo) ? `${classes.drawerinputTxtMain}` :
              `${classes.redInputMain}`} inputProps={{ maxLength: 256 }} />
          )} 
        </Box>

          <Box>
            {["amountFilterError", "amountFilterFromError", "amountFilterToError"].map((errorKey) => {
              const error = parentState[errorKey];
              return error && (
                <Typography key={errorKey} className={`${classes.errormassagetextAm}`}>
                  {error}
                </Typography>
              );
            }).find(Boolean)} 
          </Box>


          </>
      
    ))
  }

  renderOppType =(ftrLabel:any) => {
    const { parentState, classes }: any = this.props
    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "bussiness_type") && (
        <Box className={`${classes.drawerInputFieldsCheckBox}`}>
          <Select
            fullWidth
            disableUnderline
            className={
              Array.isArray(parentState.typeFilter) && this.handleValidation(parentState.typeFilter.length)
                ? classes.admininputTxtMainCustom
                : classes.redAdmininputTxtMainCustom
            }
            value={parentState.typeFilter || []} 
            onChange={this.props.handleMulipleSelectInputChange("typeFilter")}
            data-test-id="typeFilterID"
            multiple
            MenuProps={{
              classes:{paper:classes.root},
              getContentAnchorEl: null,
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              }
            }}
            renderValue={(selected) => 
                this.renderChips(selected, configJSON.dealType, 
                this.handleDeleteType,
                (e:any) => this.handleRemoveAllChips(e, 'typeFilter'),
                "typeFilter"
              )}
            
            >
            {configJSON.dealType.map((data: any, index: any) => (
              <MenuItem key={index} value={data.value}>
                <Checkbox
                  className={`${classes.drawerMultipleCheckBox}`}
                  icon={<BpIconDeal />}
                  checkedIcon={<BpCheckedIconDeal />}
                  checked={Array.isArray(parentState.typeFilter) && parentState.typeFilter.includes(data.value)}
                  onChange={() => this.handleCheckboxChange(data.value)} 
                  data-test-id="Revanue-CheckId"
                />
                {" "}{data.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    )
  }

  renderClosingDate = (ftrLabel:any)=> {
    const { parentState, classes }: any = this.props
    const validOperators = ["between", "on", "before", "after"];
    const inputClass = validOperators.includes(parentState.operatorCDateFilter);

    return  (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "closing_date") && (
        <><Box className={inputClass ? classes.drawerInputFieldsBoxTextCustom : classes.drawerInputFieldsBoxText}>
          <Select
            data-test-id="operatorCDateFilterID"
            fullWidth
            disableUnderline
            className={this.handleValidation(parentState.operatorCDateFilter) ? classes.admininputTxtMain :
              classes.reddrawerInputSelect}
            value={parentState.operatorCDateFilter}
            onChange={this.props.handleSelectInputChange("operatorCDateFilter","closing_date")}
            inputProps={{ id: 'txtInputFirstName' }}
            MenuProps={{
              classes:{paper:classes.root},
              getContentAnchorEl: null,
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              }
            }}>
            {parentState.ClosingDateArray.map((data: any) => (
              <MenuItem key={data.value} value={data.value}>
                {data.label}
              </MenuItem>
            ))}
          </Select>
          {["in_the_last","due_in"].includes(parentState.operatorCDateFilter) && (
            <>
              <Input
                data-test-id="numberCDFilterID"
                fullWidth={true}
                disableUnderline
                className={this.handleValidation(parentState.numberCDFilter) ? `${classes.drawerinputTxtMainCustom}` :
                  `${classes.redDrawerinputTxtMainCustom}`}
                inputProps={{ maxLength: 256 }}
                value={parentState.numberCDFilter}
                onChange={this.props.handleNumberInputChange("numberCDFilter","")}
                placeholder={"0"} 
              />
              <Select fullWidth disableUnderline
                data-test-id="durationCdFilterID"
                className={this.handleValidation(parentState.durationCdFilter) ? classes.admininputTxtMain :
                  classes.reddrawerInputSelect}
                inputProps={{}}
                value={parentState.durationCdFilter}
                onChange={this.props.handleSelectInputChange("durationCdFilter"," ")}
                MenuProps={{
                  classes:{paper:classes.root},
                  getContentAnchorEl: null,
                  anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                  }
                }}>
                {configJSON.ClosingMonth.map((data: any) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}    
          
          {["on","before","after","between"].includes(parentState.operatorCDateFilter) && (
              <Input placeholder={"Last name"} fullWidth={true}
                type="date"
                disableUnderline
                className={this.handleValidation(parentState.ClosingDateFirst) ? classes.admininputTxtMain :
                  classes.redInputMainNumber}
                value={parentState.ClosingDateFirst}
                onChange={this.props.handleSelectInputChange("ClosingDateFirst"," ")}
                data-test-id="txtInputClosingDate"
                inputProps={{
                    min: "1900-01-01",
                    max: "3000-12-31",
                }}
             />
          )}

          { parentState.operatorCDateFilter  === "between" && (
              <Input placeholder={"Last name"} fullWidth={true}
                type="date"
                disableUnderline
                className={this.handleValidation(parentState.ClosingDateSecund) ? classes.admininputTxtMain :
                  classes.redInputMainNumber}
                value={parentState.ClosingDateSecund}
                onChange={this.props.handleSelectInputChange("ClosingDateSecund"," ")}
                data-test-id="txtInputClosingDate"
                inputProps={{
                    min: "1900-01-01",
                    max: "3000-12-31",
                }}
                disabled={this.state.editField}
             />
          )}
          
        </Box>
          <Box>
            {parentState.numberCDFilterError && (
              <Typography className={`${classes.errormassagetext}`}>
                {parentState.numberCDFilterError}
              </Typography>
            )}
          </Box></>
      )
    )
  }

  renderCreatedTime = (ftrLabel:any)=> {
    const { parentState, classes }: any = this.props
    const validOperatorsCA = ["between", "on", "before", "after"];
    const inputClassCreateAt = validOperatorsCA.includes(parentState.operatorCAateFilter);

    return (
      parentState?.isChecked?.[ftrLabel?.index] && (ftrLabel.key === "created_at") && (
        <><Box className={inputClassCreateAt ? classes.drawerInputFieldsBoxTextCustom : classes.drawerInputFieldsBoxText}>
          <Select
            fullWidth
            disableUnderline
            className={this.handleValidation(parentState.operatorCAateFilter) ? classes.admininputTxtMain :
              classes.reddrawerInputSelect}
            value={parentState.operatorCAateFilter}
            onChange={this.props.handleSelectInputChange("operatorCAateFilter","created_at")}
            inputProps={{ id: 'txtInputFirstName' }}
            data-test-id="operatorCAateFilterID"
            MenuProps={{
              classes:{paper:classes.root},
              getContentAnchorEl: null,
              anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
              }
            }}>
            {parentState.ClosingDateArray.map((data: any) => (
              <MenuItem key={data.value} value={data.value}>
                {data.label}
              </MenuItem>
            ))}
          </Select>

          {["in_the_last","due_in"].includes(parentState.operatorCAateFilter) && (
            <>
              <Input
                data-test-id="numberCAFilterID"
                fullWidth={true}
                disableUnderline
                className={this.handleValidation(parentState.numberCAFilter) ? `${classes.drawerinputTxtMainCustom}` :
                  `${classes.redDrawerinputTxtMainCustom}`}
                inputProps={{ maxLength: 256 }}
                value={parentState.numberCAFilter}
                onChange={this.props.handleNumberInputChange("numberCAFilter","")}
                placeholder={"0"} 
              />
              <Select fullWidth disableUnderline
                data-test-id="durationCAFilterID"
                inputProps={{}}
                className={this.handleValidation(parentState.durationCAFilter) ? classes.admininputTxtMain :
                  classes.reddrawerInputSelect}
                value={parentState.durationCAFilter}
                onChange={this.props.handleSelectInputChange("durationCAFilter"," ")}
                MenuProps={{
                  classes:{paper:classes.root},
                  getContentAnchorEl: null,
                  anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                  }
                }}>
                {configJSON.ClosingMonth.map((data: any) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {["on","before","after","between"].includes(parentState.operatorCAateFilter) && (
              <Input placeholder={"Last name"} fullWidth={true}
                type="date"
                disableUnderline
                className={this.handleValidation(parentState.createAtDateFirst) ? classes.admininputTxtMain :
                  classes.redInputMainNumber}
                value={parentState.createAtDateFirst}
                onChange={this.props.handleSelectInputChange("createAtDateFirst"," ")}
                data-test-id="txtInputClosingDate"
                inputProps={{
                    min: "1900-01-01",
                    max: "3000-12-31",
                }}
                disabled={this.state.editField}
             />
          )}

          { parentState.operatorCAateFilter  === "between" && (
              <Input placeholder={"Last name"} fullWidth={true}
                type="date"
                disableUnderline
                className={this.handleValidation(parentState.createAtDateSecund) ? classes.admininputTxtMain :
                  classes.redInputMainNumber}
                value={parentState.createAtDateSecund}
                onChange={this.props.handleSelectInputChange("createAtDateSecund","")}
                data-test-id="txtInputClosingDate"
                inputProps={{
                    min: "1900-01-01",
                    max: "3000-12-31",
                }}
                disabled={this.state.editField}
             />
          )}
        </Box>
          <Box>
            {parentState.numberCAFilterError && (
              <Typography className={`${classes.errormassagetext}`}>
                {parentState.numberCAFilterError}
              </Typography>
            )}
          </Box></>
      )
    )
  }

  render() {
    // Customizable Area Start 
   
    const { parentState, classes, handlefilterApply }: any = this.props
    
    // Customizable Area End

    return (
      <Box className={`${classes.drawerFlexBox}`}>
        <Box>
          <Typography className={`${classes.drawerTitile}`} variant="h6">  Filter Deals by  </Typography>
        </Box>
        <Divider></Divider>
        <Box className={classes.drawerSearchBox}>
          <Input data-test-id="handleDrawerSarchID" value={this.state.filterListValue} placeholder="Search"
            fullWidth={true} disableUnderline
            className={this.state.listSearchValueError ? classes.reddrawerSearchField : classes.drawerSearchField}
            startAdornment={<InputAdornment position="start">
              <Search style={{ color: "gray" }} />
            </InputAdornment>}
            type="text"
            onChange={this.handleDrawerSarch}
          />
        </Box>
        {this.state.listofFilterArrayError && (
          <Box className={classes.errormassagetextBox} >
            <Typography className={classes.errormassagetext}>
              {this.state.listofFilterArrayError}
            </Typography>
          </Box>
        )}
        <Box>
          {
            this.renderFilterList()

          }
        </Box>
        {parentState.filterErrorMsg && (
          <Box className={classes.errormassagetextBox} >
            <Typography className={classes.errormassagetext}>
              {parentState.filterErrorMsg}
            </Typography>
          </Box>
        )}
        <Box className={`${classes.drawerButtons}`} >
          <Button variant="outlined" className={`${classes.savebuttonTextDT}`}
            data-test-id="handlefilterApplyD"
            onClick={handlefilterApply}
          >
            Apply Filter
          </Button>
          <Button onClick={() => this.props.handleClearfilter("remove_all","RemoveCheckedOnly")} data-test-id="handleClearAllfilterID" className={`${classes.cancelbtnDT}`} >Clear All</Button>
        </Box>
      </Box>
    )
  }
}

const StyledPaper = styled(Paper)({
  "&": {
      padding:"10px",
      borderRadius:"8px",
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  },
  "& ul":{
      padding:"10px 5px"
  },
  "& ul li":{
      borderRadius:"8px",
      fontSize: "14px",
      padding:"9px 20px"
  },
  "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
      backgroundColor:"rgba(241, 245, 249, 1)"
  },
});

// Customizable Area Start
export default withStyles(tableStyleCss.stylecss,)(OpportunitiesFilterBlock);
// Customizable Area End