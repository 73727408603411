import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Layout from "../../../components/src/Layout.web";
import { CommissionCalculatorBreadcrumb } from "./constants";
import ClientItem from "./ClientItem.web";
import { plusCircle } from "./assets";
import PartnerInformation from "./PartnerInformation.web";
import { projectPricingContentStyles } from "../../cfleadmanagementandopportunity/src/StaffingProjectPricing.web";
// Customizable Area End

import Cfcommissioncalculator2Controller, {
  Props,
} from "./Cfcommissioncalculator2Controller.web";

export default class Cfcommissioncalculator2 extends Cfcommissioncalculator2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { partnerName, month, newClientsNumber, totalClients, clients, adminCommission, totalCommission, isClientNumberMatch } = this.state;

    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return (
      // Customizable Area Start
      <StyledWrapper>
        <Layout
          title="COMMISSION CALCULATOR"
          breadcrumb={CommissionCalculatorBreadcrumb}
          navigation={this.props.navigation}
        >
          <Box sx={projectPricingContentStyles.flexColumn}>
            <PartnerInformation
              data-test-id="partner-field"
              partnerName={partnerName}
              month={month}
              newClientsNumber={newClientsNumber}
              totalClients={totalClients}
              handleFieldChange={this.handleFieldChange}
              isClientNumberMatch={isClientNumberMatch}
              clientsLength={clients.length}
            />
            <StyledTitle>CLIENT COMMISSION INFORMATION</StyledTitle>
            {
              clients.map((item, index) => (
                <ClientItem
                  data-test-id="client-item"
                  key={item.id}
                  client={item}
                  index={index}
                  handleDeleteClient={this.handleDeleteClient}
                  handleSaveClient={this.handleSaveClient}
                  handleUpdateClient={this.handleUpdateClient}
                  commissionRate={adminCommission}
                  totalCommission={totalCommission}
                  isRoleGloble={this.state.isRoleGloble}
                />
              ))
            }
            {
              clients.length !== parseInt(newClientsNumber) && !isClientNumberMatch &&
              <StyledValidationTypography className="center">New clients field value and added client's number does not match</StyledValidationTypography>
            }
            <TotalCommissionField data-test-id="total-commission-field">
              <Box className="commission-currency-row">
                <TotalCommissionText>Total Commission </TotalCommissionText>
                <TotalCommissionCurrency>= {totalCommission !== 0 ? currencyFormatter.format(totalCommission) : `$0`}</TotalCommissionCurrency>
              </Box>
              <SaveButton data-test-id="save-button" onClick={this.handleSaveClient}>Save</SaveButton>
            </TotalCommissionField>
            <AddNewClientButton data-test-id="add-new-client-button" onClick={this.handleAddNewClient}>
              <img src={plusCircle} alt="" />
              Add New Client
            </AddNewClientButton>
          </Box>
        </Layout>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  "& .right-area": {
    maxWidth: "calc(100vw - 120px)"
  },
  "@media(max-width:576px)":{
    "& .accordion-wrapper":{
      padding:"8px 12px!important"
    }
  }
})

const StyledTitle = styled(Typography)({
  "&": {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "0em",
    marginBottom: "20px",
    color: "#0A0528"
  },
  "@media(max-width: 960px)": {
    "&": {
      fontSize: "16px"
    }
  }
});

const SaveButton = styled(Button)({
  "&": {
    background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
    textTransform: "capitalize",
    color: "white",
    fontWeight: "700",
    borderRadius: "8px",
    width: '150px',
    height: '44px',
    fontSize: '16px',
    fontFamily: "Inter, sans-serif",
  },
  "&.Mui-disabled": {
    color: "#fff"
  },
  "@media(max-width: 960px)": {
    "&": {
      width: "120px",
      height: "32px",
      fontSize: "12px"
    }
  }
});

const TotalCommissionField = styled(Box)({
  "&": {
    width: "100%",
    height: "148px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    backgroundColor: "#F1F5F9",
    borderRadius: "8px",
    margin: "20px 0 8px 0"
  },
  "& .commission-currency-row": {
      display:"flex",
      alignItems:"center"
  },
  "@media(max-width: 960px)": {
    "&": {
      width: "100%",
      height: "102px"
    }
  },
  "@media(max-width: 576px)": {
    "&": {
      padding:"10px",
      height: "unset"
    },
    "& .commission-currency-row": {
      flexDirection:"column"
    }
  }
});

const TotalCommissionText = styled(Typography)({
  "&": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#475569",
  },
  "@media(max-width: 960px)": {
    "&": {
      fontSize: "12px"
    }
  }
});

const TotalCommissionCurrency = styled(Typography)({
  "&": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#0A0528",
    marginLeft: "5px"
  },
  "@media(max-width: 960px)": {
    "&": {
      fontSize: "14px"
    }
  }
});

const AddNewClientButton = styled(Button)({
  "&": {
    border: "1px solid #0F172A",
    borderRadius: "8px",
    width: "320px",
    height: "54px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#0A0528",
    margin: "10px auto 0 auto"
  },
  "& img": {
    marginRight: "16px"
  },
  "@media(max-width: 960px)": {
    "&": {
      width: "100%",
      height: "45px",
      fontSize: "14px"
    }
  }
});

export const StyledValidationTypography = styled(Typography)({
  "&": {
      color: '#DC2626',
      fontSize: '12px',
      fontWeight: '400',
      marginTop: '-10px'
  },
  "&.center":{
    textAlign: 'center'
  }
});
// Customizable Area End
