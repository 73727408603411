import { Box, Button, Typography, styled } from "@material-ui/core";
import React, { Component, ReactNode } from "react";

type IProps = {
    title: string | undefined;
    handleReset: () => void;
    handleEnable: boolean;
};

type IState = {};

class PricingTypeContentHeader extends Component<IProps, IState>{
    render(): ReactNode {
        const { title, handleReset, handleEnable } = this.props;
        return (
            <>
                {title !== undefined ?
                    <Box sx={webStyles.createTitleContainer}>
                        <StyledTypography>{title + " Pricing"}</StyledTypography>
                        <StyledRefreshButton data-test-id="reset-pricing-selection-button" onClick={handleReset} disabled={handleEnable}><img src={require("../../../components/src/assets/svg/Refresh.svg")} alt="refresh" /> Reset</StyledRefreshButton>
                    </Box> :
                    <Typography style={webStyles.selectPricingToCalculateText}>
                        Please select any project to calculate the pricing
                    </Typography>
                }
            </>
        )
    }
}

const webStyles = {
    createTitleContainer: {
        marginTop: "38px",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px"
    },
    selectPricingToCalculateText: {
        fontSize: "18px",
        marginTop: "30px"
    }
};


const StyledRefreshButton = styled(Button)({
    "&": {
        border: "1px solid",
        borderImageSource: "linear- gradient(90deg, #364161 0 %, #06081F 100 %)",
        borderRadius: "8px",
        textTransform: "none",
        color: "#64748B",
        fontWeight: 700,
        fontSize: "16px",
        padding: "6px 10px",
        width: "95px",
        height: "32px"
    },
    "& img": {
        marginRight: "6px"
    },
    "@media(max-width: 960px)": {
        "&": {
            padding: "4px 8px",
            fontSize: "12px",
            width: "75px"
        },
        "& img": {
            width: "12px"
        },
    }
});

const StyledTypography = styled(Typography)({
    "&": {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase'
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "16px"
        }
    }
});

export default PricingTypeContentHeader;
