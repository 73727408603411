import React from "react";
import Layout from "../../../components/src/Layout.web";
import TermsAndConditionsController, { Props, configJSON } from "./TermsAndConditionsController.web";
import CommonBody from "./CommonBody.web";
import { downloadAction } from "./helper";

export class TermsAndConditions extends TermsAndConditionsController {
    constructor(props: Props) {
        super(props);
    }
    handleDownload = () => {
        this.setState({termsAndConditionsList: this.state.termsAndConditionsList.map(item=>{
            return {...item, isExpanded:item.title}
        })},()=>{
            downloadAction(configJSON.TermsAndConditionsBreadcrumb.currentPage.text)
        })
    }
    render() {
        const {termsAndConditionsList,headerContent} = this.state;
        return (
            <Layout
                navigation={this.props.navigation}
                breadcrumb={configJSON.TermsAndConditionsBreadcrumb}
            >
                <CommonBody
                    data-test-id="common-body"
                    title="TERMS OF USE"
                    onDownload={this.handleDownload}
                    data={termsAndConditionsList}
                    headerData={headerContent}
                    toggleAccordionStatus={this.toggleAccordionStatus}
                />
            </Layout>
        )
    }
}

export default TermsAndConditions;