import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import createRequestMessage from "../../../framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { IPasswordStates, IPasswordVisibilityStates } from "./types";
import { toast } from "react-toastify";

export const configJSON = require("./config");

const ErrorToastStyles = {
    toast: {
        width: 'max-content',
        maxHeight: '42px !important',   
        minHeight: '42px',
        color: '#0A0528',
        borderRadius: '8px',
        fontSize: '16px'
    }
}

const ErrorToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    style: ErrorToastStyles.toast,
    hideProgressBar: true,
    closeButton: false
}

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    currentPasswordValidation: boolean;
    newPasswordValidation: boolean;
    currentPasswordVisible: boolean;
    newPasswordVisible: boolean;
    confirmPasswordVisible: boolean;
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
    token: string,
    lowercaseRule: boolean;
    uppercaseRule: boolean;
    numberRule: boolean;
    specialCharRule: boolean;
    lengthRule: boolean;
    controlField: boolean;
    passwordNotMatch: boolean | null;
    showToast: boolean;
    role: string;
}

interface SS {
    id: any;
}

export default class UpdatePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    updatePasswordApiCallId: string | null;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            currentPasswordValidation: true,
            newPasswordValidation: true,
            currentPasswordVisible: false,
            newPasswordVisible: false,
            confirmPasswordVisible: false,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            token: "",
            lowercaseRule: false,
            uppercaseRule: false,
            numberRule: false,
            specialCharRule: false,
            lengthRule: false,
            controlField: false,
            passwordNotMatch: null,
            showToast: false,
            role: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.updatePasswordApiCallId = "";
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            if (apiRequestCallId) {
                if (this.updatePasswordApiCallId) {
                    this.updatePasswordApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({
                                currentPasswordVisible: false,
                                newPasswordVisible: false,
                                confirmPasswordVisible: false,
                                currentPassword: "",
                                newPassword: "",
                                confirmPassword: "",
                                lowercaseRule: false,
                                uppercaseRule: false,
                                numberRule: false,
                                specialCharRule: false,
                                lengthRule: false,
                                controlField: false,
                                passwordNotMatch: null,
                                showToast: true
                            });
                        },
                        onFail: () => toast.error(`${responseJson.errors[0].profile}`, ErrorToastOptions),
                    });
                }
            }
        }
    }

    handleCancelButtonAction = async () => {
        this.props.navigation.navigate(this.state.role === "organization_user" ? "ProfileBio" : "AdminProfile");
    }

    handlePasswordVisibilityChange = (name: IPasswordVisibilityStates, value: boolean) => {
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>);
    }

    handlePasswordChange = (name: IPasswordStates, value: string) => {
        this.setState({
            [name]: value.trim(), showToast: false
        } as unknown as Pick<S, keyof S>, () => {
            if (name === "newPassword") {
                this.setState({
                    lowercaseRule: configJSON.lowerRegex.test(value),
                    uppercaseRule: configJSON.upperRegex.test(value),
                    numberRule: configJSON.numberRegex.test(value),
                    specialCharRule: configJSON.specialRegex.test(value),
                    lengthRule: configJSON.lengthRegex.test(value),
                    controlField: true
                }, () => {
                    const { lowercaseRule, uppercaseRule, numberRule, specialCharRule, lengthRule } = this.state;
                    this.setState({ controlField: !(lowercaseRule && uppercaseRule && numberRule && specialCharRule && lengthRule) });
                });
            }
        });
    }

    controlValidPassword = (value: string) => {
        if (configJSON.lowerRegex.test(value) && configJSON.upperRegex.test(value) && configJSON.numberRegex.test(value) && configJSON.specialRegex.test(value) && configJSON.lengthRegex.test(value)) {
            return true
        }
        return false
    }

    handlePasswordValidation = (inputType: string) => {
        if (inputType === "currentPassword") {
            this.setState({ currentPasswordValidation: this.controlValidPassword(this.state.currentPassword) })
            return
        }
        else if (inputType === "newPassword") {
            this.setState({ newPasswordValidation: this.controlValidPassword(this.state.newPassword), controlField: false })
            return
        }
        this.setState({ passwordNotMatch: (this.state.newPassword !== this.state.confirmPassword) })
    }

    handleConfirmFieldVisibilityVisible = () => {
        const { lowercaseRule, uppercaseRule, numberRule, specialCharRule, lengthRule } = this.state
        if (!lowercaseRule || !uppercaseRule || !numberRule || !specialCharRule || !lengthRule) {
            this.setState({ controlField: true })
        }
    }

    handleUpdatePassword = () => {
        const { currentPassword, newPassword, confirmPassword } = this.state;

        const header = {
            token: this.state.token,
        };

        const body = {
            data: {
                current_password: currentPassword,
                new_password: newPassword,
                new_password_confirmation: confirmPassword
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        this.updatePasswordApiCallId = requestMessage.messageId;

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.updatePasswordApiEndpoint,
            method: configJSON.putApiMethodType,
            body: JSON.stringify(body)
        });
    }

    async componentDidMount() {
        let token = sessionStorage.getItem("token");
        this.setState({
            token: JSON.parse(token as string).token,
            role: JSON.parse(token as string).account.data.attributes.user_type
        });
    }
}
