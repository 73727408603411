export const stylecss = (theme) => ({
    titleSearchBox: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItem: "center"
    },
    titleSearchBoxAllListTable: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItem: "center",
      // marginTop: "33px"
      paddingBottom: "34px"
  },
    titleSearchFilterBox: {
        display: "flex",
        justifyContent: "space-around",
        gap: "16px",
    },
    filterBtn: {
        height: 'fit-content',
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid',
        gap: '10px',
        minWidth: "44px"
    },
  
    inputTxtMain: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "32px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        minWidth: "32px",
    },
    inputTxtSearchMain: {
        border: "1px solid #dfdfdf",
        borderRadius: "8px",
        padding: "10px 8px 10px  8px",
        height: "44px",
        fontFamily: "'Inter', sans-serif",
        width: "360px"
    },
    lableText: {
        fontSize: '18px',
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: '0.00735em',
        paddingBottom: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px"
    },
  
    tableMainBox: {
        background: 'white',
        overflowX: "auto"
    },
    table: {
        minWidth: "700",
        borderColor: "1px solid #CBD5E1",
        width: "100%",
        display:'block',
        overflowX:'auto',
        whiteSpace:'no-wrap'
    },
    tableCell: {
        backgroundColor: "rgba(248, 250, 252, 1)",
        width: "100%"
    },
  
  
    // searchInput: {
    //     marginRight: theme.spacing.unit * 2
    // },
    filterIconButton: {
        width: '44px',
        height: '44px',
        borderRadius: '8px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        borderImageSlice: 1,
  
    },
    filterButton: {
        gap: '10px',
        display: "flex",
        alignItems: "center",
        background: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        borderRadius: '8px',
        width: '134px',
        height: '44px',
        '& img': {
            marginRight: '10px'
        }
    },
    filterIcon: {
        background: "White",
        borderRadius: "8px",
        gap: '12px',
        display: "flex",
        alignItems: "center",
  
    },
    searchInput: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
    },
  
    filterArrow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "17px",
  
        "& img": {
            height: "12px"
        }
    },
    tableCellText: {
        textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        borderLeft: "1px solid grey" 
  
    },
    tableCellopId: {
        textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        width: "140px"
    },
    tableBodyCellText: {
        textAlign: "left",
        fontSize: "14px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1.235,
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
    },
    tableRow: {
        background: "rgb(248, 250, 252)",
        height: "20px",
        borderBottom: "1px solid #CBD5E1"
    },
    rejectedStatus:
    {
        // Rejected
        background: 'rgba(254, 226, 226, 1)',
        borderRadius: '16px',
        color: 'red',
        // width: '66px',
        // height: '26px',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'right',
        textAlign: 'center',
    },
    acceptedStatus:
    {
        // Accepted
        background: 'rgba(209, 250, 229, 1)',
        borderRadius: '16px',
        color: 'green',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'right',
        textAlign: 'center',
  
    },
    processStatus:
    {
        // Process
        background: 'rgba(254, 243, 199, 1)',
        borderRadius: '16px',
        color: 'rgba(217, 119, 6, 1)',
        // width: '66px',
        // height: '26px',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'right',
        textAlign: 'center',
  
    },
    acceptedStage: {
        color: "rgba(5, 150, 105, 1)",
        fontSize: "14px"
  
    },
    rejectedStage: {
        color: "rgba(220, 38, 38, 1)",
        fontSize: "14px"
    },
    processStage: {
        color: "rgba(217, 119, 6, 1)",
        fontSize: "14px"
    },
    tiertoPartnAndOpp: {
        color: "rgba(35, 17, 136, 1)",
        fontSize: "14px",
    },
    tableCommonText: {
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 400,
        color: 'rgba(10, 5, 40, 1)',
    },
    viewMoreDealBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: '38px',
        width: "100%",
        "& p":
        {
            borderBottom: "1px solid",
            width: "119px"
        }
    },
  
    savebuttonTextDT: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: '10px 16px 10px 16px',
        height: '44px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        width: "134px"
    },
    cancelbuttonText: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        height: '44px',
        padding: '0 20px',
    },
    
  pageViewCount: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      color: "rgba(71, 85, 105, 1)",
      fontSize: "12px",
      alignItems: "center",
      gap: "9px"
  },
  pageViewCountBtn: {
      display: "flex",
      justifyContent: "flex-end",
      color: "rgba(71, 85, 105, 1)",
      fontSize: "12px",
      alignItems: "center",
      gap: "9px",
  },
  pageViewCounttotal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "rgba(71, 85, 105, 1)",
  },
  paginatuionBtn: {
      borderRadius: "8px",
      height:"32px",
      minWidth:"32px",
      padding: "8px"
  },
  tableRecordsNotMainBox:{
  
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: "column",
      width: "100%",
      height:"272px",
      paddingTop: "8px",
      border: "1px solid",
      borderColor: "rgba(226, 232, 240, 1)",
      borderRadius: "8px"
  },
  tableRecordsNotTitile:{
  
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      // fontWeight: 'bold',
  
      fontWeight: 400,
      textTransform: "capitalize",
      padding: '10px 16px 10px 16px',
      fontSize: '16px',
      fontFamily: "Inter, sans-serif",
      paddingBottom:"0px"
  },
  tableRecordsNotMesg:{
   
      fontWeight: 400,
      textTransform: "capitalize",
      padding: '10px 16px 10px 16px',
      fontSize: '14px',
      fontFamily: "Inter, sans-serif",
      color:"rgba(71, 85, 105, 1)",
  },
  bodyTableRow:{
      background: 'rgba(255, 255, 255, 1)',
      height: '40px',
      borderBottom: '1px solid #CBD5E1',
      textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)"
  },
  tableStyleMain:{
      width: '109%',
        border: '1px solid rgba(203, 213, 225, 1)',
        borderRadius: '8px',
  },
  colSpanStyle:{
      display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '26px'
  },
  paginationTableRow:{
      // background: "rgba(248, 250, 252, 1)", 
       borderBottom: "1px solid #CBD5E1" 
  },
  pageEntries:{
      fontSize: "14px",
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: '0.00735em',
      color: "rgba(71, 85, 105, 1)"
  },
  drawerClass:{
      width:"400px",
      background: 'rgba(255, 255, 255, 1)',
  },
  drawerMainBox:{
    display: 'flex'
  },
  drawerMainLeftBox:{
    background: '#fffdfd',
    width: '57px',
    marginTop: "43px",
    height: '50px',
    fontSize: "24px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    cursor: "pointer"
  },
  drawerMainRightBox:{
    background: 'white',
    width: '100%',
    fontSize: '30px',
    height: '100%',
  },
  drawerTitile:{
      fontWeight: 700,
      textTransform: "capitalize",
      padding: '12px 20px 9px 16px',
      fontSize: '14px',
      fontFamily: "Inter, sans-serif",
  },
  drawerFlexBox:{
      color:"rgba(10, 5, 40, 1)",
      display: "flex",
      flexDirection: "column"
  
  },
  drawerSearchBox:{
      padding: "19px 18px 10px 23px",
      position:"relative"
  },
  drawerButtons:{
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      padding:"15px 16px 15px 16px",
      gap: "18px"
  },
  drawerERButtonsBox:{
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    padding:"15px 0px 15px 0px",
    gap: "18px"
},
drawerERButtons:{
    background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
    color: "white",
    fontWeight: 700,
    textTransform: "capitalize",
    borderRadius: "8px",
    padding: '10px 16px 10px 16px',
    height: '44px',
    fontSize: '16px',
    fontFamily: "Inter, sans-serif",
    width: "76px"
},
  drawerListText:{
   
      fontWeight: 400,
      textTransform: "capitalize",
      padding: '10px 16px 10px 16px',
      fontSize: '14px',
      fontFamily: "Inter, sans-serif",
      color:"rgba(15, 23, 42, 1)",
  },
  drawerListBox:{
      "& span":{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          cursor: "pointer"
      }
       
  },
  cancelbtnDT: {
      background: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid',
      borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
      padding: '7px 12px',
      color: "#000000",
      fontWeight: "700",
      textTransform: "capitalize",
      fontSize: '16px',
      fontFamily: "Inter, sans-serif",
      height: "44px",
    },
    drawerSearchField: {
      border: "1px solid #dfdfdf",
      borderRadius: "8px",
      padding: "10px 8px 10px  8px",
      height: "44px",
      fontFamily: "'Inter', sans-serif",
      maxWidth: "344px"
  },
  '@media (max-width: 798px)': {
    drawerSearchField: {
      marginBlock:'10px',
      maxWidth: '100%'
    },
    drawerMainBox:{
        display: 'flex',
        justifyContent:"flex-end"
      },
      drawerMainRightBox:{
        background: 'white',
        width: '55%',
        fontSize: '30px',
        height: '100%',
      },
      savebuttonTextDT: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: '10px 16px 10px 16px',
        height: '33px',
        fontSize: '12px',
        fontFamily: "Inter, sans-serif",
        width: "135px"
    },
    cancelbtnDT: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '7px 12px',
        color: "#000000",
        fontWeight: "700",
        textTransform: "capitalize",
        fontSize: '12px',
        fontFamily: "Inter, sans-serif",
        height: "33px",
        width: "104px"
      },
      drawerButtons:{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        padding:"15px 16px 15px 16px",
        gap: "4px"
    },
    drawerInputFieldsBoxText:{
        display: "flex",
        gap: "17px",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"10px 17px",
        flexDirection:"column"
      },
      redDrawerinputTxtMainCustom: {
        border: '1px solid #F87171',
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100% !important",
        fontFamily: "'Inter', sans-serif",
      },
      drawerinputTxtMainCustom: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100% !important",
        fontFamily: "'Inter', sans-serif",
      },
      modaladminDownload: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '62% !important',
        height: '420px',
        position: 'absolute',
        top: '50%',
        left: '57% !important',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
    },
    deleteTxtadmin:{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '11px !important',
        lineHeight: '26px',
        color: '#0A0528',
        width: "310px" ,
        textAlign: "center"
    },
    nameText:{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '11px !important',
        lineHeight: "22px",
        color: '#585c65',
        marginTop: '1px',
        display: "flex",
        alignItems: "center"
    },
    downloadBtnadmin: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: '10px 16px 10px 16px',
        height: '35px !important',
        fontSize: '13px !important',
        fontFamily: "Inter, sans-serif",
        width: "90px !important"
    },
    cancelBtnadmin: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '90px !important',
        height: '35px !important',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '13px !important',
        color: '#000000',
        textTransform: 'capitalize',
    },
  },
  reddrawerSearchField: {
    border: "1px solid red",
    borderRadius: "8px",
    padding: "10px 8px 10px  8px",
    height: "44px",
    fontFamily: "'Inter', sans-serif",
    maxWidth: "344px"
},
  drawerSearchBoxClose:{
      position: "absolute",
      left: "-24px"
  },
  drawerCheckBox:{
      color: 'black', 
      padding: '0px !important',
      fontFamily: "'Inter' !important",
      radius: "6px",
  },
  drawerMultipleCheckBox:{
    color: 'black', 
    padding: '0px !important',
    fontFamily: "'Inter' !important",
    radius: "6px",
    marginRight: "16px"
  },
  drawerMultipleRemoveBtn:{
    position: "absolute",
    right: "20px",
    top: "18px"
  },
  drawerchipBox:{
    display: 'flex',
    gap: '3px',
    flexWrap: 'wrap'
  },
  countryLoginInput: {
    width: '100% !important',
    boxSizing:"border-box",
    border: "1px solid #dfdfdf",
    padding: "5px 7px 5px 0px",
    borderRadius: "8px",
    fontFamily: "'Inter', sans-serif",
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        paddingRight: "25px",
        fontSize: "16px",
        borderBottom: "none",
        paddingLeft: "10px",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInput-underline::before': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline::after': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: "0px",
    },
    '&. MuiInputBase-fullWidth': {
        width: '95%'
    }
  },
  redcountryLoginInput: {
    width: '100% !important',
    boxSizing:"border-box",
    border: "1px solid red",
    padding: "5px 7px 5px 0px",
    borderRadius: "8px",
    fontFamily: "'Inter', sans-serif",
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        paddingRight: "25px",
        fontSize: "16px",
        borderBottom: "none",
        paddingLeft: "10px",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInput-underline::before': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline::after': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: "0px",
    },
    '&. MuiInputBase-fullWidth': {
        width: '95%'
    }

  },
  drawerInputFieldsBoxText:{
    display: "flex",
    gap: "17px",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"10px 17px"
  },
  
  drawerInputFieldsBoxTextCustom:{
    display: "flex",
    gap: "17px",
    justifyContent: "space-between",
    alignItems: "center",
    padding:"10px 17px",
    flexDirection: "column"
  },
  drawerInputFieldsCheckBox:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding:"10px 17px",
    flexDirection:"column"
  },
  drawerinputTxtMain: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  drawerinputTxtMainCustomPR: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  drawerInputMultiSelect: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
  },
  admininputTxtMain: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    boxSizing:"border-box",

        " & .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded ":{
            opacity: 1,
            transform: 'none',
            minWidth: '467px',
            transition: 'opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '169px',
            left: '202px',
            transformOrigin: '0px 26px',
            color:"red"
        }   
  },
  admininputTxtMainCustom: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    boxSizing:"border-box",

        " & .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded ":{
            opacity: 1,
            transform: 'none',
            minWidth: '467px',
            transition: 'opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '169px',
            left: '202px',
            transformOrigin: '0px 26px',
            color:"red"
        } ,
        " & .MuiInput-input.MuiInputBase-input.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu":{
            backgroundColor:"white"
        },
  },
  redAdmininputTxtMainCustom: {
    border: "1px solid red",
    padding: "10px",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    boxSizing:"border-box",
        " & .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded ":{
            opacity: 1,
            transform: 'none',
            minWidth: '467px',
            transition: 'opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '169px',
            left: '202px',
            transformOrigin: '0px 26px',
            color:"red"
        } ,
        " & .MuiInput-input.MuiInputBase-input.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu":{
            backgroundColor:"white"
        },
  },
  
  searchInpUnd: {
    padding: "0 5px",
    '& .MuiFilledInput-underline:after': {
        borderBottom: '0px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderBottom: '0px',
    },
    '&. MuiInputBase-fullWidth': {
        width: '95%'
    }
},
  drawerinputTxtMainCustom: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "30%",
    fontFamily: "'Inter', sans-serif",
  },
  redDrawerinputTxtMainCustom: {
    border: '1px solid #F87171',
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "30%",
    fontFamily: "'Inter', sans-serif",
  },
    drawerInputSelect: {
    border: "1px solid #dfdfdf",
    padding: "10px",
    borderRadius: "8px",
    height: "44px",
    width: "100%",
    fontFamily: "'Inter', sans-serif",
    minWidth: "32px",
  },
  reddrawerInputSelect: {
    border: '1px solid #F87171',
    padding: '10px',
    borderRadius: '8px',
    height: "44px",
    fontFamily: 'Inter !important',
    '&::placeholder': {
        fontFamily: 'Inter !important',
    },
    minWidth: "32px",

},
  drawerRadioBox: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginLeft: "-16px",
    lineHeight: "16px",
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    color: 'black',
  },
  drawerRadioMain: {
    display: 'flex',
    gap: '6px',
  },
  drawerCheckboxList:{
    display: 'flex',
     flexDirection: 'row', 
     flexWrap: 'wrap'
  },
  drawerProbabiltyCB:{
    display: 'flex',
    flexWrap: 'wrap' 
  },
  drawerProbabiltyItemCB:{
    width: '50%',
    boxSizing: 'border-box',
    padding: '0px'
  },
  errormassagetext: {
    color: '#DC2626',
    fontSize: '13px',
    fontWeight: '400',
    marginTop: '-6px',
    marginLeft: "19px"

  },
  errormassagetextAm: {
    color: '#DC2626',
    fontSize: '13px',
    fontWeight: '400',
    marginTop: '-6px',
    marginLeft: "19px",
  },
  mainBoxDrawer:{
    background: 'transparent',
    boxShadow: 'none', 
    width: "465px",
    // maxWidth: "73%"
  },
  errormassagetextBox:{
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: "22px"
  },
  redInputMain: {
    border: '1px solid #F87171',
    padding: '10px',
    borderRadius: '8px',
    height: '45px',
    fontFamily: 'Inter !important',
    '&::placeholder': {
        fontFamily: 'Inter !important',
    },
    width:"200%"
},
redInputMainPR: {
    border: '1px solid #F87171',
    padding: '10px',
    borderRadius: '8px',
    height: '45px',
    fontFamily: 'Inter !important',
    '&::placeholder': {
        fontFamily: 'Inter !important',
    },
    width:"100%"
},
inputMainNumber: {
    border: "1px solid #dfdfdf",
    padding: '10px',
    borderRadius: '8px',
    height: '45px',
    fontFamily: 'Inter !important',
    '&::placeholder': {
        fontFamily: 'Inter !important',
    },
    width:"100%"
},
redInputMainNumber: {
    border: '1px solid #F87171',
    padding: '10px',
    borderRadius: '8px',
    height: '45px',
    fontFamily: 'Inter !important',
    '&::placeholder': {
        fontFamily: 'Inter !important',
    },
    width:"100%"
},
  tableCellSort:{
     display: 'flex', alignItems: 'center',justifyContent: "space-between" 
  },
  tableCellSortIcon:{
      display: 'flex', flexDirection: 'column' 
   }
    ,
    //For modal 
    modaladminContainer: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '443px',
        height: '240px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
    },
    modaladminDownload: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '550px',
        height: '420px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
    },
    closeadminimg: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
        position: "absolute",
        top: "20px",
        right: "20px"
    },
    successImgStyleadmin: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
    },
    countinueBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
        gap: "14px",
        marginRight:"9px"
    },
    continueBtnadmin: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '104px',
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },
    downloadBtnadmin: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: '10px 16px 10px 16px',
        height: '44px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        width: "134px"
    },
    cancelBtnadmin: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '87px',
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },
    nameText:{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '15px',
        lineHeight: "22px",
        color: '#585c65',
        marginTop: '1px',
        display: "flex",
        alignItems: "center"
    },
    centeradminTxt: {
        display: 'flex',
        justifyCOntent: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '18px',
        marginLeft: "1px"
    },
    successTxtadmin: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
        color: '#0A0528',
        width: "231px",
        textAlign: "center"
    },
    deleteTxtadmin:{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '26px',
        color: '#0A0528',
        width: "310px",
        textAlign: "center"
    },
    downloadImageBox:{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "74px",
        "& img":{
            width:"172px",
            height: "128px"
        },
        marginRight: "9px",


    },
    descriptionTxtadmin: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: "22px",
        color: '#64748B',
        marginTop: '12px',
        display: "flex",
        alignItems: "center"
    },
    opportunityIdText: {
        color: "rgba(0, 0, 0, 1)",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: "22px",
        marginLeft: "5px"
    },
    root:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"10px"
        },
        "& ul li":{
            borderRadius:"8px",
            fontSize: "14px",
            padding:"9px 20px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)"
        },
    },
    admininputTxtStge: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        boxSizing:"border-box",
        position:"relative",     
        " & .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded ":{
            opacity: 1,
            transform: 'none',
            minWidth: '467px',
            transition: 'opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '169px',
            left: '202px',
            transformOrigin: '0px 26px',
            color:"red"
        }   
        
    },
    rootMenuItem:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"1px"
        },
        "& ul li":{
            fontSize: "14px",
            padding:"5px 14px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)"
        },
    },
    chipStype:{
        backgroundColor:"rgb(242,243,249)"
    }
    ,
    tooltipTitle: {
        color: 'white',      
        backgroundColor: 'black', 
        height:"27px",
        fontSize:"13px",
        borderRadius:"8px"

    },
    arrow: {
        color: 'black',       
    },  

})
