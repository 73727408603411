import React, { Component, ReactNode } from "react";
import SelectWithoutLabel from "./SelectWithoutLabel.web";
import { Box, styled, Typography, Button, Input } from "@material-ui/core";
import { leftArrowIcon, rightArrowIcon } from "../../blocks/dashboard/src/assets";

type IProps = {
    shownItems:string;
    currentPage:number;
    from?:number;
    to?:number;
    numberOfAllItems?:number;
    totalPageNumber:number| any;
    viewPerPageOptions: string[];
    onChange: (value:string)=> void;
    onNext: ()=>void;
    onPrev: ()=>void;
    onEnter:(event: any)=> void;
    pageNumberSet:any;
    isPaginationButton:boolean;
    pagneNumberValidation:boolean;
};
type IState = {};

class PaginationContainer extends Component<IProps, IState>{
    render(): ReactNode {
        const {pagneNumberValidation, isPaginationButton, pageNumberSet, onEnter, shownItems,currentPage,from,to,numberOfAllItems,totalPageNumber, viewPerPageOptions, onChange,onNext,onPrev} = this.props;
        return(
            <StyledTableContainer className="pagination-container">
                <Box className="per-page-container">
                    <Typography className="select-text">View</Typography>
                    <Box className="select-wrapper">
                        <SelectWithoutLabel
                            data-test-id="view-per-page-select"
                            onChange={(value:string)=>onChange(value)}
                            value={shownItems}
                            data={viewPerPageOptions}
                        />
                    </Box>
                    <Typography className="select-text">per page</Typography>
                </Box>
                {
                    from && to && numberOfAllItems &&
                    <Typography className="showing-items-info">Showing {from} to {to} of {numberOfAllItems} entries</Typography>
                }
                <Box className="actions-container">
                    <Button onClick={onPrev} className="pagination-arrow" data-test-id="pagination-down-button">
                        <img src={leftArrowIcon} alt="left-arrow" />
                    </Button>
                    <Box className="pagination-info-container">
                        <Typography className="pagination-text">Page</Typography>
                        {isPaginationButton ? 
                            <Input
                                data-test-id="pageNumberId"
                                placeholder={"0"}
                                fullWidth={true}
                                value={ pageNumberSet }
                                onChange={ onEnter }
                                disableUnderline
                                className={ pagneNumberValidation ? "reddrawerinputTxtMainST":"drawerinputTxtMainST"} 
                            /> : 
                            <Button className="current-page pagination-text">{currentPage}</Button>
                            }
                        <Typography className="pagination-text">of {totalPageNumber}</Typography>
                    </Box>
                    <Button onClick={onNext} className="pagination-arrow" data-test-id="pagination-up-button">
                        <img src={rightArrowIcon} alt="right-arrow" />
                    </Button>
                </Box>
            </StyledTableContainer>
        )
    }
}

const StyledTableContainer = styled(Box)({
    "&":{
        display: "flex",
        justifyContent:"space-between",
        alignItems:"center",
        padding:"10px 20px"
    },
    "& .showing-items-info":{
        color: "#475569",
        fontSize: "12px",
        lineHeight:"18px"
    },
    "& .per-page-container":{
        display:"flex",
        alignItems:"center",
        gap:"10px",
        height:"32px",
        "& .select-text":{
            color: "#475569",
            fontSize: "12px",
            lineHeight:"18px"
        },
        "& .select-wrapper":{
            width:"44px",
            height:"32px",
            "& .MuiInput-root": {
                fontSize:"12px!important",
                padding:"7px 4px 7px 8px",
                borderRadius:"4px",
                "& svg":{
                    width:"0.8em"
                }
            }
        }
    },
    "& .actions-container" : {
        display:"flex",
        alignItems:"center",
        gap:"30px",
        "& .current-page, & .pagination-arrow": {
            height:"32px",
            width: "32px",
            borderRadius: "8px",
            border: "1px solid #E2E8F0",
            minWidth:"unset"
        },
        "& .pagination-info-container": {
            display:"flex",
            alignItems:"center",
            gap:"10px",
            "& .pagination-text":{
                fontSize:"14px",
                lineHeight:"22px",
                color: "#475569"
            }
        },
    },
    "& .reddrawerinputTxtMainST":{
        border: "1px solid red",
        padding: "10px",
        borderRadius: "8px",
        height: "32px",
        width: "42px",
        fontFamily: "'Inter', sans-serif",
    },
    "& .drawerinputTxtMainST":{
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "32px",
        width: "42px",
        fontFamily: "'Inter', sans-serif",
    },
    "@media(max-width: 960px)": {
        "&":{
            flexDirection:"column",
            gap:"20px",
            padding:"6px 12px",
        },
        "& .actions-container" : {
            whiteSpace:"nowrap",
            gap:"6px!important",
            "& .pagination-text":{
                fontSize:"12px!important"
            },
            "& .pagination-info-container": {
                gap:"6px!important"
            }
        },
        
    },
});

export default PaginationContainer;