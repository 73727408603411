Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.createAccount = "Create your account";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.mobileNumberError = "Please enter valid mobile number";
exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.email_regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
exports.password_regex = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
exports.lower_regex = /[a-z]+/;
exports.upper_regex = /[A-Z]+/;
exports.number_regex = /\d+/;
exports.special_regex = /[!@#\$%\^&*]+/;
exports.length_regex = /(?=.{8,})/;
exports.PasswordTxt = "password";
exports.PasswordtypeTxt = "text";
exports.StrongTxt = "strong";
exports.WeakTxt = "weak";
exports.CountryDialCodeArry = ['+93',
'+358',
'+355',
'+213',
'+1684',
'+376',
'+244',
'+1264',
'+672',
'+1268',
'+54',
'+374',
'+297',
'+61',
'+43',
'+994',
'+1242',
'+973',
'+880',
'+1246',
'+375',
'+32',
'+501',
'+229',
'+1441',
'+975',
'+591',
'+387',
'+267',
'+55',
'+246',
'+673',
'+359',
'+226',
'+257',
'+855',
'+237',
'+1',
'+238',
'+ 345',
'+236',
'+235',
'+56',
'+86',
'+57',
'+269',
'+242',
'+243',
'+682',
'+506',
'+225',
'+385',
'+53',
'+357',
'+420',
'+45',
'+253',
'+1767',
'+1849',
'+593',
'+20',
'+503',
'+240',
'+291',
'+372',
'+251',
'+500',
'+298',
'+679',
'+33',
'+594',
'+689',
'+241',
'+220',
'+995',
'+49',
'+233',
'+350',
'+30',
'+299',
'+1473',
'+590',
'+1671',
'+502',
'+44',
'+224',
'+245',
'+595',
'+509',
'+379',
'+504',
'+852',
'+36',
'+354',
'+91',
'+62',
'+98',
'+964',
'+353',
'+972',
'+39',
'+1876',
'+81',
'+962',
'+77',
'+254',
'+686',
'+850',
'+82',
'+965',
'+996',
'+856',
'+371',
'+961',
'+266',
'+231',
'+218',
'+423',
'+370',
'+352',
'+853',
'+389',
'+261',
'+265',
'+60',
'+960',
'+223',
'+356',
'+692',
'+596',
'+222',
'+230',
'+262',
'+52',
'+691',
'+373',
'+377',
'+976',
'+382',
'+1664',
'+212',
'+258',
'+95',
'+264',
'+674',
'+977',
'+31',
'+599',
'+687',
'+64',
'+505',
'+227',
'+234',
'+683',
'+1670',
'+47',
'+968',
'+92',
'+680',
'+970',
'+507',
'+675',
'+51',
'+63',
'+872',
'+48',
'+351',
'+1939',
'+974',
'+40',
'+7',
'+250',
'+290',
'+1869',
'+1758',
'+508',
'+1784',
'+685',
'+378',
'+239',
'+966',
'+221',
'+381',
'+248',
'+232',
'+65',
'+421',
'+386',
'+677',
'+252',
'+27',
'+211',
'+34',
'+94',
'+249',
'+597',
'+268',
'+46',
'+41',
'+963',
'+886',
'+992',
'+255',
'+66',
'+670',
'+228',
'+690',
'+676',
'+1868',
'+216',
'+90',
'+993',
'+1649',
'+688',
'+256',
'+380',
'+971',
'+598',
'+998',
'+678',
'+58',
'+84',
'+1284',
'+1340',
'+681',
'+967',
'+260',
'+263']
exports.CountryListArry = [
  { label: "Afghanistan" },
  {label: ""},
  { label: "American Samoa" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Anguilla" },
  { label: "Antigua &amp; Barbuda" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Aruba" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bermuda" },
  { label: "Bhutan" },
  { label: "Bolivia" },
  { label: "Bosnia &amp; Herzegovina" },
  { label: "Botswana" },
  { label: "Brazil" },
  { label: "British Virgin Islands" },
  { label: "Brunei" },
  { label: "Bulgaria" },
  { label: "Burkina Faso" },
  { label: "Burundi" },
  { label: "Cambodia" },
  { label: "Cameroon" },
  { label: "Cape Verde" },
  { label: "Cayman Islands" },
  { label: "Chad" },
  { label: "Chile" },
  { label: "China" },
  { label: "Colombia" },
  { label: "Congo" },
  { label: "Cook Islands" },
  { label: "Costa Rica" },
  { label: "Cote D Ivoire" },
  { label: "Croatia" },
  { label: "Cruise Ship" },
  { label: "Cuba" },
  { label: "Cyprus" },
  { label: "Czech Republic" },
  { label: "Denmark" },
  { label: "Djibouti" },
  { label: "Dominica" },
  { label: "Dominican Republic" },
  { label: "Ecuador" },
  { label: "Egypt" },
  { label: "El Salvador" },
  { label: "Equatorial Guinea" },
  { label: "Estonia" },
  { label: "Ethiopia" },
  { label: "Falkland Islands" },
  { label: "Faroe Islands" },
  { label: "Fiji" },
  { label: "Finland" },
  { label: "France" },
  { label: "French Polynesia" },
  { label: "French West Indies" },
  { label: "Gabon" },
  { label: "Gambia" },
  { label: "Georgia" },
  { label: "Germany" },
  { label: "Ghana" },
  { label: "Gibraltar" },
  { label: "Greece" },
  { label: "Greenland" },
  { label: "Grenada" },
  { label: "Guam" },
  { label: "Guatemala" },
  { label: "Guernsey" },
  { label: "Guinea" },
  { label: "Guinea Bissau" },
  { label: "Guyana" },
  { label: "Haiti" },
  { label: "Honduras" },
  { label: "Hong Kong" },
  { label: "Hungary" },
  { label: "Iceland" },
  { label: "India" },
  { label: "Indonesia" },
  { label: "Iran" },
  { label: "Iraq" },
  { label: "Ireland" },
  { label: "Isle of Man" },
  { label: "Israel" },
  { label: "Italy" },
  { label: "Jamaica" },
  { label: "Japan" },
  { label: "Jersey" },
  { label: "Jordan" },
  { label: "Kazakhstan" },
  { label: "Kenya" },
  { label: "Kuwait" },
  { label: "Kyrgyz Republic" },
  { label: "Laos" },
  { label: "Latvia" },
  { label: "Lebanon" },
  { label: "Lesotho" },
  { label: "Liberia" },
  { label: "Libya" },
  { label: "Liechtenstein" },
  { label: "Lithuania" },
  { label: "Luxembourg" },
  { label: "Macau" },
  { label: "Macedonia" },
  { label: "Madagascar" },
  { label: "Malawi" },
  { label: "Malaysia" },
  { label: "Maldives" },
  { label: "Mali" },
  { label: "Malta" },
  { label: "Mauritania" },
  { label: "Mauritius" },
  { label: "Mexico" },
  { label: "Moldova" },
  { label: "Monaco" },
  { label: "Mongolia" },
  { label: "Montenegro" },
  { label: "Montserrat" },
  { label: "Morocco" },
  { label: "Mozambique" },
  { label: "Namibia" },
  { label: "Nepal" },
  { label: "Netherlands" },
  { label: "Netherlands Antilles" },
  { label: "New Caledonia" },
  { label: "New Zealand" },
  { label: "Nicaragua" },
  { label: "Niger" },
  { label: "Nigeria" },
  { label: "Norway" },
  { label: "Oman" },
  { label: "Pakistan" },
  { label: "Palestine" },
  { label: "Panama" },
  { label: "Papua New Guinea" },
  { label: "Paraguay" },
  { label: "Peru" },
  { label: "Philippines" },
  { label: "Poland" },
  { label: "Portugal" },
  { label: "Puerto Rico" },
  { label: "Qatar" },
  { label: "Reunion" },
  { label: "Romania" },
  { label: "Russia" },
  { label: "Rwanda" },
  { label: "Saint Pierre &amp; Miquelon" },
  { label: "Samoa" },
  { label: "San Marino" },
  { label: "Satellite" },
  { label: "Saudi Arabia" },
  { label: "Senegal" },
  { label: "Serbia" },
  { label: "Seychelles" },
  { label: "Sierra Leone" },
  { label: "Singapore" },
  { label: "Slovakia" },
  { label: "Slovenia" },
  { label: "South Africa" },
  { label: "South Korea" },
  { label: "Spain" },
  { label: "Sri Lanka" },
  { label: "St Kitts &amp; Nevis" },
  { label: "St Lucia" },
  { label: "St Vincent" },
  { label: "St. Lucia" },
  { label: "Sudan" },
  { label: "Suriname" },
  { label: "Swaziland" },
  { label: "Sweden" },
  { label: "Switzerland" },
  { label: "Syria" },
  { label: "Taiwan" },
  { label: "Tajikistan" },
  { label: "Tanzania" },
  { label: "Thailand" },
  { label: "Timor L'Este" },
  { label: "Togo" },
  { label: "Tonga" },
  { label: "Trinidad &amp; Tobago" },
  { label: "Tunisia" },
  { label: "Turkey" },
  { label: "Turkmenistan" },
  { label: "Turks &amp; Caicos" },
  { label: "Uganda" },
  { label: "Ukraine" },
  { label: "United Arab Emirates" },
  { label: "United Kingdom" },
  { label: "Uruguay" },
  { label: "Uzbekistan" },
  { label: "Venezuela" },
  { label: "Vietnam" },
  { label: "Virgin Islands (US)" },
  { label: "Yemen" },
  { label: "Zambia" },
  { label: "Zimbabwe" }
]
exports.InsustryListArry = [
  'ASP (Application Service Provider)',
  'Data/Telecom OEM',
  'ERP (Enterprise Resource Planning)',
  'Government/Military',
  'Large Enterprise',
  'ManagementISV',
  'MSP (Management Service Provider)',
  'Network Equipment Enterprise',
  'Non-management ISV',
  'Optical Networking',
  'Service Provider',
  'Small/Medium Enterprise',
  'Storage Equipment',
  'Storage Service Provider',
  'Systems Integrator',
  'Wireless Industry',
  'ERP',
  'Management ISV',
  'Education',
  'Agriculture',
  'Business Services',
  'Manufacturing',
  'Energy, Utilities & Waste',
  'Food Service',
  'Finance',
  'Hospitality',
  'Aviation',
  'Automobile',
  'Retail',
  'Telecom',
  'Transportation',
  'Chemical and Pharmaceutical',
  'Consulting',
  'Civil Aviation',
  'Infrastructure',
  'Utilities',
  'Human Resource',
  'Legal',
  'Healthcare',
  'Brewery and Spirits',
]
// Customizable Area End
