import React, { Component, ReactNode } from "react";

type IProps = {
    from:string;
    to:string;
    id:string;
};
type IState = {};

class GradientSVG extends Component<IProps,IState>{
    render(): ReactNode {
        const {from,to,id} = this.props;
        return(
            <svg style={{ height: 0 }}>
                <defs>
                    <linearGradient id={id}>
                        <stop stop-color={from} />
                        <stop offset="1" stop-color={to} />
                    </linearGradient>
                </defs>
            </svg>
        );
    }
}

export default GradientSVG