import { Box, Button, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import { downloadIcon } from "./assets";
import { IAccordionItem } from "./types.web";
import AccordionItem from "./AccordionItem.web";

type IProps = {
    title: string;
    onDownload:()=>void;
    data: IAccordionItem[];
    headerData?: IAccordionItem[];
    toggleAccordionStatus: (expanded: boolean, title:string)=>void;
};
type IState = {
    expanded:string | false;
};

class CommonBody extends Component<IProps,IState>{
    constructor(props: IProps) {
        super(props);
    }
    render(): React.ReactNode {
        const { title, onDownload, data, headerData, toggleAccordionStatus } = this.props;
        return(
            <CustomContainer>
                <Box className="center-details-box">
                    <Box className="header-container">
                        <Typography className="header-title">{headerData && headerData.length > 0 ? headerData[0].title : title} </Typography>
                        <Button
                            data-test-id="download-button"
                            onClick={onDownload}
                            variant="outlined"
                            className="download-button"
                            startIcon={<img src={downloadIcon} alt="" />}
                        >
                            Download
                        </Button>
                    </Box>
                    {
                        headerData && headerData.length > 0 &&
                        <Typography className="paragraph" dangerouslySetInnerHTML={{__html:headerData[0].description} }/>
                    }
                    <div id="pdfView">
                        {
                            data.map((item) => (
                                <AccordionItem
                                    data-test-id="accordion-item"
                                    handleChange={toggleAccordionStatus}
                                    key={item.id}
                                    item={item}
                                />
                            ))
                        }
                    </div>
                </Box>
            </CustomContainer>
        )
    }
}

const CustomContainer = styled(Box)({
    "&": {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '34px',
        paddingRight: "36px"
    },
    "& .center-details-box":{
        background: '#FFFFFF',
        borderRadius: '8px',
    },
    "& .header-container":{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "32px",
    },
    "& .header-title":{
        fontSize: '24px',
        fontFamily: '"Inter"',
        fontWeight: 700,
        lineHeight: "32px",
        paddingBottom: "26px",
        letterSpacing: "-0.005em",
        color: '#0A0528'
    },
    "& .download-button":{
        padding: '5px 15px',
        width: '143px',
        height: '44px',
        border: '1px solid transparent',
        background: "linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box",
        borderRadius: '8px',
        textTransform: 'capitalize',
        color: '#0A0528',
        fontSize: '16px',
        fontWeight: 700
    },
    "& .paragraph p":{
        fontFamily: '"Inter"',
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        letterSpacing: "0em",
        textAlign: "left",
        color:"rgba(71, 85, 105, 1)"
    },
    "@media(max-width: 960px)": {
        "&": {
            gap:"20px",
            width: "calc(91vw - 153px)"
        },
        "& .header-container":{
            marginBottom: "20px!important",
            width: "115%"
        },
        "& .header-title":{
            fontSize: '16px!important',
            width: '100%',
        },
        "& .download-button":{
            width:"172px",
            height:"36px",
            fontSize:"12px!important"
        },
        "& .paragraph p":{
            fontSize: "13px!important",
        },
    }
});

export default CommonBody;