import React, { Component, ReactNode } from "react";
import EstimatedPriceBox from "./EstimatedPriceBox.web";
import SaveExistingDealContainer from "./SaveExistingDealContainer.web";
import { Box, isWidthDown, Theme, withWidth } from "@material-ui/core";
import SelectWithLabel from "../../../components/src/SelectWithLabel.web";
import { ConsultingConsultantTypeOptions, ConsultingProjectPricingFieldKeysToRemove } from "./constants";
import InputWithLabel from "../../../components/src/InputWithLabel.web";
import { projectPricingContentStyles, StyledBox } from "./StaffingProjectPricing.web";
import { IConsultingPricingFormStates, IEstimatedPriceEndPoint, IEstimatedPriceRequestBody, IEstimatedPriceStates } from "./types";
import PricingTypeContentHeader from "./PricingTypeContentHeader.web";
import AutocompleteWithLabel from "../../../components/src/AutocompleteWithLabel.web";

type IProps = {
    navigation:any;
    title: string | undefined;
    countryList: string[];
    estimatedPrice: string;
    handleGetEstimatedPrice: (body: IEstimatedPriceRequestBody, endPoint: IEstimatedPriceEndPoint) => void;
    handleResetEstimatedPrice: (value: IEstimatedPriceStates) => void;
    width: Parameters<Theme["breakpoints"]["only"]>[0];
};

type IState = {
    isApproved: boolean;
    consultingConsultantType: string;
    consultingCountry: string;
    totalEstimateHours: string;
    isAllSelected: boolean;
};

class ConsultingProjectPricing extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            isApproved: true,
            consultingConsultantType: "",
            consultingCountry: "",
            totalEstimateHours: "",
            isAllSelected: true
        };
    }

    handleResetFields = () => {
        this.setState({ consultingConsultantType: "", consultingCountry: "", totalEstimateHours: "", isAllSelected: false });
        this.props.handleResetEstimatedPrice("consultingEstimatedPrice");

        ConsultingProjectPricingFieldKeysToRemove.forEach(k => localStorage.removeItem(k));
    }

    handleFieldChange = (name: IConsultingPricingFormStates, value: string) => {
        this.setState({
            [name]: value
        } as unknown as Pick<IState, keyof IState>, () => {
            const { consultingConsultantType, consultingCountry, totalEstimateHours } = this.state;
            if (consultingConsultantType !== "" && consultingCountry !== "" && totalEstimateHours !== "") {
                this.props.handleGetEstimatedPrice({ consultant_type: consultingConsultantType, country: consultingCountry, total_estimate_effort_hours: totalEstimateHours }, "consulting")
                this.setState({ isAllSelected: true })
            }
        });
        localStorage.setItem(name, value);
    }

    handleInputFieldChange = (data: string) => {
        const value = data?.toString().replace(/\D/g, "")
        if (value) {
            this.handleFieldChange("totalEstimateHours", value);
        }
        else {
            this.setState({ totalEstimateHours: value })
            this.props.handleResetEstimatedPrice("consultingEstimatedPrice");
            localStorage.removeItem("totalEstimateHours");
        }
    }

    handleSaveExistingDeal = (value: boolean) => {
        if(value){
            localStorage.setItem("price",this.props.estimatedPrice);
            this.props.navigation.navigate("CreateOpportunitiesFromPriceProject");
            return
        }
        this.setState({ isApproved: false });
    }

    componentDidMount = () => {
        let consultingConsultantType = localStorage.getItem("consultingConsultantType") as string;
        let consultingCountry = localStorage.getItem("consultingCountry") as string;
        let totalEstimateHours = localStorage.getItem("totalEstimateHours") as string;

        if (consultingConsultantType !== "" && consultingConsultantType !== null) {
            this.setState({ consultingConsultantType })
        }
        if (consultingCountry !== "" && consultingCountry !== null) {
            this.setState({ consultingCountry })
        }
        if (totalEstimateHours !== "" && totalEstimateHours !== null) {
            this.setState({ totalEstimateHours })
        }
    }

    render(): ReactNode {

        const { title, countryList, estimatedPrice } = this.props;
        const { isApproved, consultingConsultantType, consultingCountry, totalEstimateHours } = this.state;
        const isMobileView = isWidthDown("sm", this.props.width, true);

        return (
            <Box sx={projectPricingContentStyles.flexColumn}>
                <PricingTypeContentHeader
                    data-test-id="consulting-pricing-type-content-header"
                    title={title}
                    handleReset={this.handleResetFields}
                    handleEnable={consultingConsultantType === "" && consultingCountry === "" && totalEstimateHours === ""}
                />
                <SelectWithLabel
                    data-test-id="consultant-type-select-box"
                    containerStyle={projectPricingContentStyles.fullWidth}
                    label="Consultant Type"
                    data={ConsultingConsultantTypeOptions}
                    value={consultingConsultantType}
                    onChange={(value) => this.handleFieldChange("consultingConsultantType", value)}
                    isRequired
                />
                <StyledBox>
                    <AutocompleteWithLabel
                        data-test-id="country-select-box"
                        className="autocomplete"
                        label="Country"
                        data={countryList}
                        value={consultingCountry}
                        onChange={(value)=>this.handleFieldChange("consultingCountry",value)}
                        isRequired
                    />
                    <InputWithLabel
                        data-test-id="total-estimate-hours-input"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        inputType="text"
                        inputValue={totalEstimateHours}
                        label="Total Estimate Effort Hours"
                        onChange={(value) => this.handleInputFieldChange(value)}
                        isRequired
                    />
                </StyledBox>
                {consultingCountry !== "" &&  estimatedPrice !== "" && totalEstimateHours !== "" && <EstimatedPriceBox
                    title={title}
                    estimatedPrice={estimatedPrice}
                />}
                {
                    consultingCountry !== "" && isApproved && estimatedPrice !== "" && totalEstimateHours !== "" &&
                    <SaveExistingDealContainer
                        data-test-id="save-existing-deal-buttons-container"
                        handleSaveExistingDeal={this.handleSaveExistingDeal}
                    />
                }
            </Box>
        )
    }
}

export default withWidth()(ConsultingProjectPricing);
