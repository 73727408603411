import React from "react";
import Layout from "../../../components/src/Layout.web";

import StatisticsController, {
  Props,
  configJSON
} from "./StatisticsController.web";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import {
  downloadWhiteIcon,
  emptyTableIcon,
  filterDownActiveIcon,
  filterDownIcon,
  filterUpActiveIcon,
  filterUpIcon,
} from "./assets";
import EmptyData from "../../../components/src/EmptyData.web";
import StatisticsTableBody from "./StatisticsTableBody.web";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import PaginationContainer from "../../../components/src/PaginationContainer.web";

export default class Statistics extends StatisticsController {
  constructor(props: Props) {
    super(props);
  }

  handleReturnTrueElement = (
    condition: boolean,
    firstElement: React.ReactNode,
    secondElement: React.ReactNode
  ) => {
    if (condition) {
      return firstElement;
    }
    return secondElement;
  };

  handleReturnTrueString = (
    condition: boolean,
    firstImage: string,
    secondImage: string
  ) => {
    if (condition) {
      return firstImage;
    }
    return secondImage;
  };

  render() {
    const {
      isLoading,
      opportunityList,
      opportunity_name,
      created_date,
      expected_revenue,
      close_date,
      status,
      currentPage,
      shownItems,
      totalPageNumber,
      dateFilterValue,
      numberOfButtons,
      pageNumberSet,
      pagneNumberValidation
    } = this.state;

    return (
      <StyledWrapper>
        <Layout
          // breadcrumb={configJSON.StatisticsBreadcrumb}
          title=""
          navigation={this.props.navigation}
          isDefultCard={false}
        >
          <>
            <Box className="header-container">
              <Box className="breadcrumb-container">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon />}
                >
                  <Typography
                    data-test-id="home-button"
                    className="breadcrumb-home"
                    onClick={() => this.props.navigation.navigate("Dashboard")}
                  >
                    Dashboard
                  </Typography>
                  <Typography className="breadcrumb-active">
                    Statistics & Reports
                  </Typography>
                </Breadcrumbs>
              </Box>
            </Box>
            <Box className="table-wrapper">
              <Box className="table-container">
                <Box className="filter-wrapper">
                  <Box className="date-filter-container">
                    {configJSON.dateFilterOptions.map(
                      (item:any) => (
                        <Button
                          data-test-id="date-filter-button"
                          onClick={() => this.handleDateFilter(item.value)}
                          className={`${
                            dateFilterValue === item.value ? "active" : ""
                          } date-filter-button`}
                         key={item.value}>
                          {item.label}
                        </Button>
                      )
                    )}
                  </Box>
                  <Box className="filter-container">
                    <Input
                      data-test-id="search-input"
                      value={this.state.listSearchValue}
                      onChange={(event)=>this.handleSearchST(event.target.value)}
                      onBlur={this.handleBlurEventST}
                      placeholder="Search"
                      fullWidth={true}
                      disableUnderline
                      className="search-input"
                      startAdornment={
                        <InputAdornment position="start">
                          <Search style={webStyles.colorGray} />
                        </InputAdornment>
                      }
                    />
                    <Button
                      disabled={opportunityList?.length === 0 || opportunityList?.length === undefined }
                      onClick={this.handleExport}
                      data-test-id="download-button"
                      variant="outlined"
                      className="download-button"
                      startIcon={<img src={downloadWhiteIcon} alt="download" />}
                    >
                      Export
                    </Button>
                  </Box>
                </Box>
                {isLoading ? (
                  <Box className="loading-container">
                    <CircularProgress />
                  </Box>
                ) : (
                  this.handleReturnTrueElement(
                    opportunityList?.length > 0,
                    <StyledTableContainer className="statistics-table-container">
                      <>
                        <Table aria-label="Closing Deals">
                          <StyledTableHead className="statistic-head">
                            <TableRow>
                              <TableCell align="center">
                                Opportunity ID
                              </TableCell>
                              <TableCell align="left">
                                <Box className="cell-with-filter">
                                  <>Opportunity Name</>
                                  <Box
                                    onClick={() =>
                                      this.handleSortingST(
                                        "opportunity_name"
                                      )
                                    }
                                    data-test-id="opportunity-name-sort"
                                    className="filter-icons-container"
                                  >
                                    <img
                                      src={this.handleReturnTrueString(
                                        opportunity_name === "asc",
                                        filterUpActiveIcon,
                                        filterUpIcon
                                      )}
                                      alt="filter-up"
                                    />
                                    <img
                                      src={this.handleReturnTrueString(
                                        opportunity_name === "desc",
                                        filterDownActiveIcon,
                                        filterDownIcon
                                      )}
                                      alt="filter-down"
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box className="cell-with-filter">
                                  <>Status</>
                                  <Box
                                    onClick={() =>
                                      this.handleSortingST("status")
                                    }
                                    data-test-id="status-sort"
                                    className="filter-icons-container"
                                  >
                                    <img
                                      src={this.handleReturnTrueString(
                                        status === "asc",
                                        filterUpActiveIcon,
                                        filterUpIcon
                                      )}
                                      alt="filter-up"
                                    />
                                    <img
                                      src={this.handleReturnTrueString(
                                        status === "desc",
                                        filterDownActiveIcon,
                                        filterDownIcon
                                      )}
                                      alt="filter-down"
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">Stage</TableCell>
                              <TableCell align="left">
                                <Box className="cell-with-filter">
                                  <>Expected Revenue</>
                                  <Box
                                    onClick={() =>
                                      this.handleSortingST(
                                        "expected_revenue"
                                      )
                                    }
                                    data-test-id="expected-revenue-sort"
                                    className="filter-icons-container"
                                  >
                                    <img
                                      src={this.handleReturnTrueString(
                                        expected_revenue === "asc",
                                        filterUpActiveIcon,
                                        filterUpIcon
                                      )}
                                      alt="filter-up"
                                    />
                                    <img
                                      src={this.handleReturnTrueString(
                                        expected_revenue === "desc",
                                        filterDownActiveIcon,
                                        filterDownIcon
                                      )}
                                      alt="filter-down"
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box className="cell-with-filter">
                                  <>Created On</>
                                  <Box
                                    onClick={() =>
                                      this.handleSortingST("created_date")
                                    }
                                    data-test-id="created-on-sort"
                                    className="filter-icons-container"
                                  >
                                    <img
                                      src={this.handleReturnTrueString(
                                        created_date === "asc",
                                        filterUpActiveIcon,
                                        filterUpIcon
                                      )}
                                      alt="filter-up"
                                    />
                                    <img
                                      src={this.handleReturnTrueString(
                                        created_date === "desc",
                                        filterDownActiveIcon,
                                        filterDownIcon
                                      )}
                                      alt="filter-down"
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box className="cell-with-filter">
                                  <>Closed On</>
                                  <Box
                                    onClick={() =>
                                      this.handleSortingST("close_date")
                                    }
                                    data-test-id="closed-on-sort"
                                    className="filter-icons-container"
                                  >
                                    <img
                                      src={this.handleReturnTrueString(
                                        close_date === "asc",
                                        filterUpActiveIcon,
                                        filterUpIcon
                                      )}
                                      alt="filter-up"
                                    />
                                    <img
                                      src={this.handleReturnTrueString(
                                        close_date === "desc",
                                        filterDownActiveIcon,
                                        filterDownIcon
                                      )}
                                      alt="filter-down"
                                    />
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </StyledTableHead>
                          <StatisticsTableBody classes={""} data={opportunityList} />
                        </Table>
                        <PaginationContainer
                          data-test-id="pagination-container"
                          shownItems={shownItems}
                          onChange={(value:string)=>this.handlePaginationST(value)}
                          viewPerPageOptions={
                            configJSON.paginationSelectOptions
                          }
                          currentPage={currentPage}
                          from={currentPage * 10 - 9}
                          to={(currentPage - 1) * 10 + opportunityList?.length}
                          numberOfAllItems={totalPageNumber}
                          totalPageNumber={numberOfButtons}
                          onNext={()=>this.handlePaginationButtonST(currentPage+1)}
                          onPrev={()=>this.handlePaginationButtonST(currentPage-1)}
                          onEnter={this.handlePageNumberInputST}
                          pageNumberSet={pageNumberSet}
                          isPaginationButton={true}
                          pagneNumberValidation={pagneNumberValidation}
                        />
                      </>
                    </StyledTableContainer>,
                    <EmptyData
                      description="We couldn't find any matches. please try another filters!"
                      image={emptyTableIcon}
                    />
                  )
                )}
              </Box>
            </Box>
          </>
        </Layout>
      </StyledWrapper>
    );
  }
}

const webStyles = {
  colorGray: { color: "gray" }
};

const StyledWrapper = styled(Box)({
  "& .right-area": {
    backgroundColor: "#F8FAFC",
    padding: 0,
    maxWidth: "calc(100vw - 120px)",
    "&>div": {
      marginTop: 0
    }
  },
  "& .breadcrumb-container ~ div": {
    maxWidth: "calc(100vw - 120px)"
  },
  "& .header-container": {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 48px"
  },
  "& .breadcrumb-container": {
    "& .breadcrumb-home": {
      "&": {
        fontSize: "24px",
        color: "#475569",
        cursor: "pointer"
      },
      "@media(max-width: 960px)": {
        "&": {
          fontSize: "16px",
          cursor: "pointer"
        }
      }
    },
    "& .breadcrumb-active": {
      "&": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "24px",
        color: "#0F172A"
      },
      "@media(max-width: 960px)": {
        "&": {
          fontSize: "16px"
        }
      }
    }
  },
  "& .table-container": {
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",
    margin: "0 48px"
  },
  "& .filter-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "30px 16px",
    "& .date-filter-container": {
      display: "flex",
      alignItems: "center",
      border: "1px solid #D0D5DD",
      boxShadow: "0px 1px 2px 0px #1018280D",
      borderRadius: "8px",
      overflow: "hidden",
      "& .date-filter-button": {
        borderRadius: 0,
        padding: "10px 16px",
        textTransform: "capitalize",
        minWidth: "unset",
        backgroundColor: "#FFFFFF",
        color: "#344054",
        height: "44px",
        fontSize:"16px",
        fontWeight:400
      },
      "& .date-filter-button.active": {
        backgroundColor: "#F9FAFB"
      },
      "& .date-filter-button:not(:last-child)": {
        borderRight: "1px solid #1018280D"
      },
      "& .date-filter-button:first-child": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      },
      "& .date-filter-button:last-child": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    "& .filter-container": {
      display: "flex",
      alignItems: "center",
      gap: "12px"
    }
  },
  "& .download-button": {
    padding: "10px 16px",
    width: "114px",
    height: "44px",
    border: "1px solid transparent",
    background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
    color: "#fff",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontSize:"16px",
    "& .MuiButton-startIcon": {
      marginLeft: 0
    }
  },
  "& .search-input": {
    border: "1px solid #dfdfdf",
    borderRadius: "8px",
    padding: "10px 8px 10px  8px",
    height: "44px",
    fontFamily: "'Inter', sans-serif",
    width: "344px"
  },
  "& .filter-tags-container": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
    flexWrap: "wrap"
  },
  "& table": {
    borderBottom: "1px solid rgba(203, 213, 225, 1)"
  },
  "& .loading-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30vh"
  },
  "@media(max-width: 1100px)": {
    "& .search-input": {
      width: "250px"
    }
  },
  "@media(max-width: 960px)": {
    "& table": {
      display: "block",
      overflowX: "auto",
      whiteSpace: "nowrap"
    },
    "& .filter-wrapper": {
      flexDirection: "column",
      gap: "20px"
    },
    "& .header-container": {
      padding: "12px 24px"
    },
    "& .table-container": {
      margin: "0 24px"
    },
    "& .search-input": {
      width: "200px",
      height: "36px"
    },
    "& .date-filter-button": {
      height: "32px!important"
    },
    "& .download-button": {
      width: "120px",
      height: "36px",
      fontSize: "12px!important"
    }
  },
  "@media(max-width: 576px)": {
    "& .header-container": {
      padding: "8px 12px"
    },
    "& .table-container": {
      margin: "0 12px"
    },
    "& .filter-wrapper": {
      "& .filter-container": {
        flexDirection: "column"
      }
    },
    "& .date-filter-button": {
      fontSize: "12px",
      padding: "12px 8px!important",
      height: "44px!important",
      "& span": {
        whiteSpace: "nowrap"
      }
    }
  }
});

export const StyledTableContainer = styled(TableContainer)({
  "&": {
    boxShadow: "none!important",
    borderRadius: "8px"
  },
  "&.registered-opportunities-table-container, &.recent-onboard-table-container, &.earned-commission-table-container, &.closing-deals-table-container, &.periodic-table-container": {
    border: "1px solid rgba(203, 213, 225, 1)"
  },
  "&.statistics-table-container": {
    borderTop: "1px solid rgba(203, 213, 225, 1)",
    borderRadius: 0
  },
  "&.periodic-table-container": {
    margin: "0 auto",
    width: "100%",
    maxWidth: "640px",
    boxSizing: "content-box"
  },
  "&.earned-commission-table-container": {
    marginTop: "24px"
  },
  "&.registered-opportunities-table-container, &.recent-onboard-table-container, &.closing-deals-table-container": {
    marginTop: "18px"
  },
  "&.registered-opportunities-table-container>div:first-child, &.statistics-table-container>div:first-child, &.recent-onboard-table-container>div:first-child, &.closing-deals-table-container>div:first-child": {
    margin: "30px"
  },
  "& tr th, & tr td": {
    fontSize: "14px!important",
    fontWeight: "400!important",
    lineHeight: "18px!important"
  },
  "@media(max-width: 960px)": {
    "&": {
      marginTop: "18px"
    },
    "& tr th, & tr td": {
      fontSize: "10px!important",
      lineHeight: "16px!important"
    }
  },
  "@media(max-width: 576px)": {
    "&.periodic-table-container table": {
      display: "block"
    }
  }
});

export const StyledTableHead = styled(TableHead)({
  "&.periodic-head": {
    borderTop: "1px solid rgba(203, 213, 225, 1)",
    display: "table",
    tableLayout: "fixed",
    width: "100%"
  },
  "&.periodic-head tr": {
    height: "46px!important",
    whiteSpace: "nowrap"
  },
  "&.periodic-head tr th": {
    padding: "14px 8px",
    whiteSpace: "nowrap"
  },
  "&.statistic-head": {
    fontSize: "14px!important",
    lineHeight: "18px!important",
    fontWeight: "400!important"
  },
  "& tr": {
    backgroundColor: "#F8FAFC",
    color: "#475569"
  },
  "& tr th": {
    borderColor: "rgba(203, 213, 225, 1)",
    borderRight: "1px solid rgba(203, 213, 225, 1)",
    color: "#475569",
    fontFamily: "'Inter', sans-serif",
    height: "40px",
    padding: "8px 12px",
    width:"12%"
  },
  "& tr th:last-child": {
    borderRight: "none"
  },
  "& .cell-with-filter": {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "center",
    width: "100%"
  },
  "& .filter-icons-container": {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer"
  },
  "@media(max-width: 960px)": {
    "&.periodic-head": {
      display: "table-header-group"
    },
    "& tr th": {
      // padding:"11px 6px"
    }
  }
});
