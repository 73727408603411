// Customizable Area Start
// @ts-nocheck
import {  Grid, Box,  Button, IconButton, Input, InputAdornment, Tab, Tabs, Typography, styled, withStyles } from "@material-ui/core";
import UserProfileBasicController, { Props } from "./UserProfileBasicController";
import React from "react";
import { envelopImg } from "./assets";
import SearchIcon from '@material-ui/icons/Search';
import Layout from "../../../components/src/Layout.web";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
const ManageAntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-flexContainer': {
        gap: '16px !important',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
        border: '2px solid transparent',
        height: '44px',
        background: 'linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box',
        borderBottom: 0,
        borderRadius: '8px 8px 0px 0px',
        width: '144px',
        radius:'8px 8px 0px 0px'
    },
});

const ManageAntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        zIndex: '999',
        color: '#0F172A',
        '& .Mui-selected': {
            color: '#0A0528',
        },
    }),
);

// Customizable Area End

export class ManageUser extends UserProfileBasicController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { ifnoManageUserData } = this.state;

        const leftMainContainerStyle = {
          height: ifnoManageUserData ?   'auto' :  '942px',
        }
        const pageData  = configJSON.ManageUser

        // Customizable Area End
        return (
            // Customizable Area Start
                    <Layout
                      navigation={this.props.navigation}
                      breadcrumb={pageData}
                      // title=""
                      >  
                    <Box style={leftMainContainerStyle} >
                        <SearchBox className={`${classes.createtitleContainer}`}>
                            <Typography className={`all-user-text ${classes.createUserTitleTxt}`}>All Users</Typography>
                            <Box className="input-box">
                                <Input data-test-id="txtInputsearch" disableUnderline className={`search-input ${classes.searchInput}`}
                                    type={'text'} placeholder={"Search by name"} fullWidth={true}
                                    value={this.state.searchUser} {...this.setSearchUser}
                                    startAdornment={
                                        <InputAdornment className="search-icon" disable testID={"btnPasswordShowHide"} position="start">
                                            <IconButton aria-label="toggle password visibility" edge="start">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </SearchBox>
                        <Box sx={{ bgcolor: "#fff", margin: "20px 0px  0px" }}>
                            <ManageAntTabs value={this.state.tabValue} data-test-id="ManageUsertabs" onChange={this.handleTabChange} aria-label="ant example">
                                <ManageAntTab label="Active Users"   className={`${classes.activeUsersTab}`} />
                                <ManageAntTab label="Inactive Users" className={`${classes.inactiveUsersTab}`} />
                            </ManageAntTabs>
                        </Box>
                        <Box className={`${classes.mainActiveBoxContainer}`}>
                            {this.state.manageUserAllData.map((item, i) => (
                              <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Box data-test-id="navigateOtherComponent" key={i} className={`${classes.bottomMainContainer}`} onClick={() => this.handelbNavigateProfileDetails(item.id)}>
                                    <Box>
                                        <Typography className={`${classes.usernamestyle}`}>{item.first_name}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={`${classes.userfieldstyle}`}>{item.job_title}</Typography>
                                    </Box>
                                    <Box className={`${classes.emailMainBox}`}>
                                        <img src={envelopImg} alt="" />
                                        <Typography className={`${classes.emailTxtContainer}`}> {item.email}</Typography>
                                    </Box>
                                </Box>
                              </Grid>
                            ))}
                       

                        {this.state.manageUserAllData.length >  15 && (
                            <Box className={`${classes.buttonFlex}`}>
                                 {this.state.noNanageUserData && (
                                    <Box>
                                        <Button
                                            className={`${classes.saveuserbtn}`}
                                            onClick={() => this.getManageUserlistViewMore(this.state.tabValue)}
                                            id="viewMoreButton1"
                                        >
                                            View More
                                        </Button>
                                    </Box>
                                  )}
                            </Box>
                        )}

                        {this.state.manageUserAllData.length == 0 && (
                            <Typography>User not found</Typography>
                        )}
                       </Box>
                    </Box>
                    </Layout>
            // Customizable Area End
        )
    }

    componentDidMount = async () => {
        this.getManageUserlist(this.state.tabValue);
    };
}

// Customizable Area Start

export const ManageUserStyle = (theme: any) => ({
    createUserTitleTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
          width: "200px"
        }
    },
    createtitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
          fontSize: "13px"
        },
        alignItems: "center"
      },
    saveuserbtn: {
        backgroundColor: 'transparent',
        border: '2px solid transparent',
        background: 'linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box',
        color: "#0A0528",
        fontWeight: "700",
        textTransform: "capitalize",
        borderRadius: "10px",
        padding: '6px 13px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
    },
    buttonFlex: {
        display: 'flex',
        margin: '0 32px 0 32px',
        paddingBottom: '32px',
        paddingTop: '8px',
        gap: '16px',
        justifyContent: 'center',
        width: '100%'
    },
    bottomMainContainer: {
        background: '#FFFFFF',
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        width: "90%",
        height: "142px",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '30px',
        marginBottom: '32px',
        cursor: 'pointer',
        [theme.breakpoints.down("md")]: {
          width: "194px"
        },
        '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid transparent',
            background: 'linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box',
            '& $usernamestyle': {
                color: '#0A0528',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
    },
    mainActiveBoxContainer: {
        display: 'flex',
        flexWrap: 'wrap',   
        padding: "32px 0px 0",
        justifyContent: 'flex-start',
        '& *': {
            boxSizing: 'border-box',
        },
        [theme.breakpoints.down("md")]: {
            overflowX: "auto",
            maxHeight: "700px",
            marginTop: "22px"
          }
    },
    usernamestyle: {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: '#475569',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    userfieldstyle: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '18px',
        color: '#64748B',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    emailMainBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '& img': {
            marginTop: '2px',
        },
    },
    emailTxtContainer: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '14px',
        color: '#64748B',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    searchInput: {
      border: "1px solid #dfdfdf",
      padding: "10px",
      borderRadius: "8px",
      height: "44px",
      minWidth: "360px",
      fontFamily: "'Inter', sans-serif",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        minWidth: "0px"
      }
    },
    inactiveUsersTab:{
        width:"100%",
        maxWidth:"156px",
        height: "44px !important",
        minHeight:" 44px !important",
        borderRadius: "8px",
        
    },
    activeUsersTab: {
      width: "144px",
      height: "100%",
      minHeight: " 44px !important",
      borderRadius: "8px"
    }
})

const SearchBox = styled(Box)({
    "& .input-box":{
        width:"360px"
    },
    "@media(max-width: 960px)": {
        "& .all-user-text": {
            width: "165px"
        },
        "& .search-icon": {
            marginLeft: "-4px",
            padding: "5px",
            marginRight:"-8px"
        },
        "& .search-icon svg": {
            width: "0.8em",
            height: "0.8em"
        },
        "& input": {
            fontSize: "12px"
        },
        "& .search-input": {
            height:"36px"
        },
        "& .search-input button":{
            padding:"6px"
        },
        "& .input-box":{
            width:"220px"
        },
    },
    "@media(max-width: 546px)": {
        "& .input-box":{
            width:"92%"
        },
    }
});

//@ts-ignore
export default withStyles(ManageUserStyle)(ManageUser);
// Customizable Area End