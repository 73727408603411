import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { PresentationImage, viewAllBgImage } from "./assets";
import { IDealListAttributes, IOpportunityListAttributes } from "./types";
import createRequestMessage from "../../../framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    valueA: number;
    token: string;
    opportunityList: IOpportunityListAttributes[];
    dealList: IDealListAttributes[];
    currentPage: number;
    sortBy: string | null;
    sortDirection: 'asc' | 'desc';
    isDealRegistraion: boolean;
    dealRegisrationAnchorEl: null | HTMLElement;
    anchorEl: null | HTMLElement;
    opportunityId:string;
    isDeleteModalOpen: boolean;
    opportunityDealIdName:string;
    isDownloadModalOpen:boolean;
    isAccordionOpen :boolean,
    isLoading: boolean,
    ProposedAmountData:any
    listType:boolean,
    listSearchValue:any,
    listSearchValueError:any

}

interface SS {
    id: any;
}

export default class CfpipelinemanagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    getOpportunityListApiCallId: string | null;
    getDealListApiCallId: string | null;
    searchOpportunityListApiCallId: string | null;
    searchDealListApiCallId: string | null;
    timeout: ReturnType<typeof setTimeout>;
    proposedAmountApiCallIdOpp:string | null;
    deleteListApiCallIdDeal:string | null;



    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            valueA: 0,
            sortDirection: 'asc',
            token: "",
            opportunityList: [],
            dealList: [],
            currentPage: 1,
            sortBy: "opportunityName",
            isDealRegistraion:false,
            dealRegisrationAnchorEl:null,
            anchorEl: null,
            opportunityId:'',
            isDeleteModalOpen: false,
            isDownloadModalOpen:false,
            opportunityDealIdName:'',
            isAccordionOpen: false,
            isLoading: true,
            ProposedAmountData:'',
            listType:true,
            listSearchValue:'',
            listSearchValueError:''


        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.getOpportunityListApiCallId = "";
        this.getDealListApiCallId = "";
        this.searchOpportunityListApiCallId = "";
        this.searchDealListApiCallId = "";
        this.timeout = setTimeout(() => { });
        this.proposedAmountApiCallIdOpp =''
        this.deleteListApiCallIdDeal =''

    }

    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.getOpportunityListApiCallId: {
                    this.getOpportunityListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ opportunityList: responseJson.data})  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 2500); 
                        },
                        onFail: () => this.showAlert(`${responseJson?.errors[0]?.message}`, "Please retry!"),
                    });
                    break;
                }
                case this.getDealListApiCallId: {
                    this.getDealListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ dealList: responseJson.data})  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 2500); 
                        },
                        onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                    });
                    break;
                }
                case this.searchOpportunityListApiCallId: {
                    this.searchOpportunityListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ opportunityList: responseJson.data  ,isLoading: false});
                        },
                        onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                    });
                    break;
                }
                case this.searchDealListApiCallId: {
                    this.searchDealListApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ dealList: responseJson.data ,isLoading: false });
                        },
                        onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                    });
                    break;
                }
                case this.deleteListApiCallIdDeal: {
                    this.deleteListApiCallIdDeal = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.getOpportunityList();
                        },
                        onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                    });
                    break;
                }
                case this.proposedAmountApiCallIdOpp: {
                    this.proposedAmountApiCallIdOpp = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.getOpportunityList()
                            this.setState({  isLoading:false});
                        },
                        onFail: () => {
                            this.setState({  isLoading:false });
                            this.showAlert(`${errorJson.errors[0].message}`, "Please retry!");
                        } 
                    });
                    break;
                }
            }
        }
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (newValue) {
            this.setState({listType:false,isLoading:true},()=>{
                sessionStorage.setItem("DealRegistration", "deal");
                this.getDealList();
            })
        } else {
            this.setState({listType:true,isLoading:true},()=>{
                sessionStorage.setItem("DealRegistration", "opportunity");
                this.getOpportunityList();
            })
        }

        this.setState({
            valueA: newValue,
            isAccordionOpen:false 
        });
    };

    handleChangeImage = () => {
        if (this.state.valueA == 0) {
            return viewAllBgImage
        } else if (this.state.valueA == 1) {
            return PresentationImage
        }
    }

    getOpportunityList = () => {
        const header = {
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        this.getOpportunityListApiCallId = requestMessage.messageId;
         const OppBodyType= {
            type:"opportunity"
         }   

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.opportunityListEndPoint}`,
            method: configJSON.postAPiMethodType,
            body: JSON.stringify(OppBodyType)
        });
    }

    getDealList = () => {
        const header = {
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        this.getDealListApiCallId = requestMessage.messageId;
        const dealBodyType= {
            type:"deal"
        }  

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.opportunityListEndPoint}`,
            method: configJSON.postAPiMethodType,
            body: JSON.stringify(dealBodyType)

        });
    }

    handleBlurEvent = () => {
        this.setState({
           listSearchValueError: "",
         })
    }
    handleSearch = (dataType: string, value: string) => {

        let filteredText = value.replace(/^\s+/, '').replace(/\s+/g, ' ');
        this.setState({listSearchValue :filteredText ,
           listSearchValueError :filteredText !== value ? true : false,
        })
        
        let condition = filteredText !== value ? true : false
        if ( condition   )  return 

        this.setState({isLoading:true})
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const header = {
                token: this.state.token,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage),
            );

            if (dataType === "opportunity") this.searchOpportunityListApiCallId = requestMessage.messageId;
            else this.searchDealListApiCallId = requestMessage.messageId;
            
            const searchBody = {
                "search":{
                  query: value
                },
                "page":this.state.currentPage,
                "per_page":3,
                "type":dataType
              }
              
              createRequestMessage({
                  header,
                  requestMessage: requestMessage,
                  endPoint: `${configJSON.opportunityListEndPoint}`,
                  method: configJSON.postAPiMethodType,
                  body: JSON.stringify(searchBody)
              });
              this.setState({ isLoading: false });
        }, 1500)
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.valueA !== prevState.valueA) {
            this.handleChangeImage();
        }

        let tabValue = sessionStorage.getItem("DealRegistration");
        if (tabValue === "opportunity" && this.state.valueA !== 0) {
            this.setState({ valueA: 0 , isAccordionOpen:false ,isLoading:true },()=>{
                this.getOpportunityList();
            });
            
        } else if (tabValue === "deal" && this.state.valueA !== 1) {
            this.setState({ valueA: 1 , isAccordionOpen:false ,isLoading:true },()=>{
                this.getDealList();
            });
            this.setState({listType:false})

        }
    }

    async componentDidMount() {
        const dataString:any = sessionStorage.getItem('token')
        let data;
        try {
            data = JSON.parse(dataString)
        } catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            } else {
                throw error;
            }
        }

        this.setState({
            token: data?.token,
        }, () => {
            this.getOpportunityList();
        });
        
    }
      handleNavigationDeal = (path:string) => {
        this.props.navigation.navigate(path);
        sessionStorage.removeItem('opportunityData');
      };


    handleSortClick = (column: any) => {
        const isColumnSameAsSortBy = this.state.sortBy === column;
        const currSortDir = this.state.sortDirection === 'asc' ? 'desc' : 'asc'
        const newSortDirection = isColumnSameAsSortBy ? currSortDir : 'asc';   
        
        this.setState({
            sortBy: column,
            sortDirection: newSortDirection,
        } , ()=> {
            this.getSortedDataCommon(column,newSortDirection)
          });
    };

    handleGetOpportunitesRecord =  (opportunityData: any)=> {
        const editViewData ={
            opportunityId :opportunityData.id,
            pageMode:"View",
            status:opportunityData?.attributes?.status,
            type:opportunityData?.type
        } as any
        sessionStorage.setItem('opportunityData', JSON.stringify(editViewData));
        
        let path = "ViewOpportunity" 
        if (opportunityData?.attributes?.status !== "rejected" && opportunityData?.type === "opportunity") {
            path = "EditOpportunity"
        }
        this.props.navigation.navigate(path);
        
    }

    dealAction =(event:any)=> {
        this.setState({
            isDealRegistraion:true,
            dealRegisrationAnchorEl: event.currentTarget
        })
    }

    handleDealActionMenuClose= ()=> {
        this.setState({
            isDealRegistraion:false,
            dealRegisrationAnchorEl: null
       })
     }
  
    handleProposedAmountOpenCommon = ( ) => {
        this.setState({isDeleteModalOpen:true})
    }
    handleDownloadModalOpen = ( ) => {
        this.setState({isDownloadModalOpen:true})
    }
    handleDownloadModalClose = ( ) => {
        this.setState({isDownloadModalOpen:false,isAccordionOpen:false})

    }
    
    
    handleCloseModalOppCommon = () => {
    this.setState({ isDeleteModalOpen: false })
    this.handleDealActionMenuClose()
    }
    
    handleProposedAmountOpp = (oppDealId:any,amount:number) => {
        this.setState({isLoading: true})
        const data:any = sessionStorage.getItem('token')
          let id;
          try {
            id = JSON.parse(data)
          } catch (error) {
            if (error instanceof SyntaxError) {
              console.error('Invalid JSON:', error.message);
            } else {
              throw error;
            }
          }
          
          const header = {
            token: id?.token,
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );
      
        this.proposedAmountApiCallIdOpp = requestMessage.messageId;
        const proposedAmountDO ={
            "opportunity": {
              "proposed_amount" : amount,
              "status" : "accepted"
            }
        }
        createRequestMessage({
          header,
          requestMessage: requestMessage,
          endPoint: `${configJSON.proposedAmountEndPoint}/`+ oppDealId +`/set_proposed_amount` ,
          method: configJSON.putAPiMethodType,
          body: JSON.stringify(proposedAmountDO)
        });
        this.setState({ isDeleteModalOpen: false , })
        this.handleDealActionMenuClose()
    
    }

    toggleAccordion = () => {
        this.setState((prevState) => ({ isAccordionOpen: !prevState.isAccordionOpen }));
    };

    getSortedDataCommon = (sortBy:string,sortOrder:string) => {
        const { token ,listType} = this.state;
        const header = {
            token        
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this[listType === false ? "getDealListApiCallId" : "getOpportunityListApiCallId"] = requestMessage.messageId;

        const oppDealType= listType ? "opportunity" : "deal"
        console.log(listType," on sort");
        
        const oppBodyType = {
            sort: {
                "column": sortBy,
                "order": sortOrder,
              },
              "type":oppDealType
        };
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.opportunityListEndPoint,
            method: configJSON.postAPiMethodType,
            body: JSON.stringify(oppBodyType)
        });
    };  
}