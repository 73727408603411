// Customizable Area Start
import { Button, TableCell, Box, TableRow, styled, TableBody, Checkbox } from "@material-ui/core";
import React, { Component } from "react"
import { IStatus, IclientRole } from "./types";
import { greenTickIcon, rejectIcon } from "./assets";
import { formatString } from '../../cfpipelinemanagement/src/constants';

type IProps = {
    data: IStatus[],
    handleToggleCheck: (id:string, checked:boolean) => void
    handleAcceptRejectAction: (id:string, checked:boolean,status:string,statusAll:string) => void
    isAllChecked:boolean
};
type IState = {};
// Customizable Area End

class StatusTableBody extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const {data, handleToggleCheck, handleAcceptRejectAction, isAllChecked} = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
                <StyledTableBody>
                    {data.map((row) => {
                        const date = row.attributes.start_date.split("-")
                        const clientRolesObj = row.attributes?.client_roles ?? []; 
                        const partnerCommissionRawData: number[] = clientRolesObj?.map((items: IclientRole) => items.partner_commission);
                        const sumOfPartnerCommission: number = partnerCommissionRawData?.reduce((acc: number, currentValue: number) => acc + currentValue, 0);
                        const penddingStatus = data.filter((items)=> items.attributes.status === 'pending')
                        const checkedCount = penddingStatus.filter(item => item.checked === true).length;
                        let buttonDisabled :boolean = false
                        if(isAllChecked && checkedCount === 1 ){
                            buttonDisabled = true
                        }
                        return(
                            <TableRow key={row.id}>
                                <TableCell style={{width:"4.3%"}} align="center">
                                    { row.attributes.status === "pending" ? (
                                        <CustomCheckbox
                                        data-test-id="checkbox"
                                        disableRipple
                                        checked={row.checked}
                                        onChange={()=>handleToggleCheck(row.id,row.checked)}
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                    />  ): 
                                        null
                                    }
                                </TableCell>
                                <TableCell style={{width:"27.59%"}} align="left">{row.attributes.client_name}</TableCell>
                                <TableCell style={{width:"17.24%"}} align="left">{sumOfPartnerCommission}</TableCell>
                                <TableCell style={{width:"17.24%"}} align="left">{sumOfPartnerCommission}</TableCell>
                                <TableCell style={{width:"17.24%"}} align="left">{date[2] + "/" + date[1] + "/" + date[0]}</TableCell>
                                <TableCell style={{width:"17.24%"}} align="left"><span>{row.attributes.recurring_payment}</span></TableCell>
                                <TableCell style={{width:"16.38%"}} align="left">
                                    { row.attributes.status === "pending" ? (
                                        <Box className="actions-container">
                                            <Button
                                                disabled={checkedCount >= 2 || buttonDisabled}
                                                data-test-id="accept-button"
                                                variant="outlined"
                                                className="accept-button"
                                                startIcon={<img src={greenTickIcon} alt="accept" />}
                                                onClick={()=>handleAcceptRejectAction(row.id,row.checked,"accepted","single")}
                                            >
                                                Accept
                                            </Button>
                                            <Button
                                                disabled={checkedCount >= 2 || buttonDisabled }
                                                data-test-id="reject-button"
                                                variant="outlined"
                                                className="reject-button"
                                                startIcon={<img src={rejectIcon} alt="reject" />}
                                                onClick={()=>handleAcceptRejectAction(row.id,row.checked,"rejected","single")}
        
                                            >
                                                Reject
                                            </Button>
                                        </Box> ): 
                                        ( <span>{formatString(row.attributes.status)}</span> )
                                     }
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </StyledTableBody>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export const StyledTableBody = styled(TableBody)({
    "& tr th":{
        color:"#475569"
    },
    "& tr td":{
        color: "#0A0528"
    },
    "& tr td, & tr th": {
        borderColor: "rgba(203, 213, 225, 1)",
        padding:"8px 10px!important",
        fontFamily: "'Inter', sans-serif",
        height:"64px",
        fontSize:"14px!important",
        lineHeight:"18px!important"
    },
    "& tr:last-child th, & tr:last-child td": {
        borderBottom: "none"
    },
    "@media(max-width: 576px)":{
        "& tr td, & tr th": {
            height:"44px"
        }
    }
  });
  
  export const CustomCheckbox = styled(Checkbox)({
    "& .MuiSvgIcon-root" : {
        height:"20px",
        width: "20px"
    },
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${require("../../../components/src/assets/svg/Unchecked.svg")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    "&.Mui-checked .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root), & .MuiSvgIcon-root + .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${require("../../../components/src/assets/svg/Checked.svg")})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },
    "&": {
        justifyContent: "flex-start",
        padding: 0,
        color: "#0A0528!important"
    },
    "&:hover": {
        backgroundColor: "transparent!important",
        borderRadius: " 6px"
    }
  });

export default StatusTableBody;
// Customizable Area End