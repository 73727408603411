import React, { Component } from "react";
import { toast } from 'react-toastify';

type IProps = {
    text: string;
};

type IState = {};


export class SuccessToast extends Component<IProps,IState> {

    showToastmassage = () => {
        toast.success(this.props.text, {
            position: toast.POSITION.TOP_CENTER,
            style: SuccessToastStyles.toast,
            hideProgressBar: true,
            icon: () => <img src={require('./toastSuccess.png')}/>,
            closeButton: false
        })
    }

    render() {
        return (
            <>
                {this.showToastmassage()}
            </>
        )
    }
}

export const SuccessToastStyles = {
    toast: {
        width: 'max-content',
        height:'42px !important',
        maxHeight: '42px !important',
        minHeight: '42px',
        color: '#0A0528',
        borderRadius: '8px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
    }
}
export default SuccessToast;