import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type ISorting = "up" | "down" | null
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  filterAnchorEl: null | HTMLElement;
  dateFilter: string;
  sortingDate: ISorting;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PeriodicTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      filterAnchorEl:null,
      dateFilter:"",
      sortingDate: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleFilterMenuClose = () => {
    this.setState({filterAnchorEl:null})
  }

  handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      this.setState({filterAnchorEl: event.currentTarget})
  }

  handleFilter = (value:string) => {
    this.setState({dateFilter:value, filterAnchorEl:null})
  }

  checkSorting = (value:ISorting) => {
    let updateSorting:ISorting = null
    if(value === null){
        updateSorting = "up";
    }
    else if(value === "up"){
        updateSorting = "down";
    }
    return updateSorting
  }

  handleSorting = () => {
    const currentSorting = this.state.sortingDate
    this.setState({sortingDate:this.checkSorting(currentSorting)})
  }
  // Customizable Area End
}
