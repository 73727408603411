// Customizable Area Start
import React from "react";
import { Box, MenuItem, Typography } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { imgPasswordInVisible, imgPasswordVisible, wrongImg, rightImg } from "./assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Country, City } from 'country-state-city';
export const baseURLJSON = require("../../../framework/src/config.js");
import createRequestMessage from "../../../framework/src/create-request-message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface Errorstype {
    // Customizable Area Start
    city: string,
    company: string,
    country: string,
    email: string,
    firstName: string,
    phoneNumber: string,
    jobTitle: string,
    lastName: string,
    countrySignUpDialCode: string
    // Customizable Area End
}
export interface S {
    // Customizable Area Start
    firstName: string;
    LastName: string;
    company: string;
    jobTitle: string;
    workEmail: string;
    cityName: string;
    mobileNumber: string;
    email: string;
    age: string;
    industry: string;
    userType: string;
    password: string;
    confirmPassword: string;
    passwordHelperText: string;
    confirmErrorMessage: string;
    enablePasswordField: boolean;
    enableReTypePasswordField: boolean;
    enableConfirmPasswordField: boolean;
    passwordError: boolean;
    confirmPasswordError: boolean;
    countryIsoCode: string;
    lowerRegex: boolean;
    upperRegex: boolean;
    numberRegex: boolean;
    specialRegex: boolean;
    lengthRegex: boolean;
    industryList: any[];
    countryList: any[];
    cityList: string | any[];
    countrySignUpDialCode: string;
    errors: Errorstype;
    signUpImage: HTMLImageElement | string;
    showPhoneError: boolean;
    open: boolean;
    clearInput: boolean;
    passwordErrorMessage:string
    clearInputCity:boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    countryDialCodeList: any[];
    imgPasswordVisible: HTMLImageElement;
    imgPasswordInVisible: HTMLImageElement;
    LastName: string;
    createAccountText: string;
    strongText: string;
    passwordText: string;
    passwordTypeText: string;
    weakText: string;
    createAccountApiCallId: string | null;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.createAccountApiCallId = ""

        this.state = {
            // Customizable Area Start
            firstName: "",
            LastName: "",
            email: "",
            company: "",
            jobTitle: "",
            workEmail: "",
            mobileNumber: "",
            cityName: "",
            password: "",
            age: "",
            industry: "",
            confirmPassword: "",
            passwordHelperText: "",
            confirmErrorMessage: '',
            userType: "organization_admin",
            enablePasswordField: true,
            enableReTypePasswordField: true,
            enableConfirmPasswordField: true,
            passwordError: false,
            confirmPasswordError: false,
            lowerRegex: false,
            upperRegex: false,
            numberRegex: false,
            specialRegex: false,
            lengthRegex: false,
            countryIsoCode: "",
            signUpImage: '',
            open: false,
            industryList: [],
            countryList: [],
            cityList: [],
            showPhoneError: false,
            countrySignUpDialCode: '+91',
            errors: {
                city: '',
                company: '',
                country: '',
                email: '',
                firstName: '',
                phoneNumber: '',
                jobTitle: '',
                lastName: '',
                countrySignUpDialCode: ''
            },
            clearInput: false,
            passwordErrorMessage:'',
            clearInputCity:false
            // Customizable Area End
        };

        // Customizable Area Start

        this.imgPasswordVisible = imgPasswordVisible;
        this.imgPasswordInVisible = imgPasswordInVisible;

        this.LastName = configJSON.LastName;
        this.passwordText = configJSON.PasswordTxt;
        this.strongText = configJSON.StrongTxt;
        this.weakText = configJSON.WeakTxt;
        this.passwordTypeText = configJSON.PasswordtypeTxt;
        this.createAccountText = configJSON.createAccount;
        this.countryDialCodeList = configJSON.CountryDialCodeArry;

        // Customizable Area End
    }
    // Customizable Area Start
    txtFnamePrpos = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const result = text.target.value.replace(/[^a-z]/gi, '');
            this.setState({
                firstName: result,
                errors: {
                    ...this.state.errors,
                    firstName: result !== text.target.value ? 'Please enter alphabet' : '',
                  },
            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const result = text.target.value.replace(/[^a-z]/gi, '');
            this.setState({
                firstName: result,
                errors: {
                    ...this.state.errors,
                    firstName: '',
                  },
            })
        }
    }

    txtLnamePrpos = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const lNameresult = text.target.value.replace(/[^a-z]/gi, '');
            this.setState({
                LastName: lNameresult,
                errors: {
                    ...this.state.errors,
                    lastName: lNameresult !== text.target.value ? 'please enter alphabet' : '',
                },
            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const lNameresult = text.target.value.replace(/[^a-z]/gi, '');
            this.setState({
                LastName: lNameresult,
                errors: {
                    ...this.state.errors,
                    lastName: '',
                  },
            })
        }
    }

    txtCompanynamePrpos = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => { 
            const CNameresult = text.target.value.replace(/[^a-zA-Z. ]/, '');

            this.setState({
                company: CNameresult,
                errors: {
                    ...this.state.errors,
                    company: CNameresult !== text.target.value ? 'Please enter alphabet' : '',
                  },
            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const CNameresult = text.target.value.replace(/[^a-z. ]/gi, '');
            this.setState({
                company: CNameresult,
                errors: {
                    ...this.state.errors,
                    company: '',
                  },
            })
        }
    }

    txtJobTitle = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const jobResult = text.target.value.replace(/[^a-zA-Z ]/, '');

            this.setState({
                jobTitle: jobResult,
                errors: {
                    ...this.state.errors,
                    jobTitle: jobResult !== text.target.value ? 'please enter alphabet' : '',
                  },
            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const jobResult = text.target.value.replace(/[^a-z ]/gi, '');
            this.setState({
                jobTitle: jobResult,
                errors: {
                    ...this.state.errors,
                    jobTitle: '',
                  },
            })
        }
    }

    txtWorkMail = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                workEmail: text.target.value,
            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.checkValidEmail(text.target.value)
        }
    }

    txtMobileNo = {
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            const value = text.target.value.replace(/\D/g, "")
            this.setState({
                mobileNumber: value,
                errors: {
                    ...this.state.errors,
                    phoneNumber: value !== text.target.value ? configJSON.mobileNumberError : "",
                  },
            })
        },
        onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
            const value = text.target.value.replace(/\D/g, "")
            this.setState({
                mobileNumber: value,
                errors: {
                    ...this.state.errors,
                    phoneNumber: value.length < 10 ? 'Please enter valid mobile number' : "",
                  },
            })
        }
    }

    txtConfirmPasswordProps = {
        onBlur: () => {
            const confirmPwd = this.state.confirmPassword;
            const confirmError = this.state.password !== confirmPwd;
            const confirmPasswordErrorTemp = confirmError ? "Password does not match" : '';

            this.setState({
                confirmPasswordError: confirmError,
                confirmErrorMessage: confirmPasswordErrorTemp
            });
        },
        onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                confirmPassword: text.target.value.trim()
            });
        }
    }

    txtCityProps = (text: string) => {
        this.setState({
            cityName: text,
            errors: {
                ...this.state.errors,
                city: ""
              },
        });
    }
    textCountryProps = (text: string) => {
        this.setState({
            age: text,
        });
    }

    textIndustryProps = (text: string) => {
        this.setState({
            industry: text
        });
    }

    textSignUpCountryDialProps = {
        onChange: (text: string) => {
            this.setState({
                countrySignUpDialCode: text || "",
                errors: {
                    ...this.state.errors,
                    countrySignUpDialCode: text === "" ? 'Please select country dial code' : "",
                  }
            });
        },
        onBlur: (text: string) => {
            this.setState({
                countrySignUpDialCode: text || "",
                errors: {
                    ...this.state.errors,
                    countrySignUpDialCode: "",
                  },
            });
        }
    }

    btnloginProps = {
        onClick: () => this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    btnPasswordShow = {
        onClick: () => {
            this.setState({
                enablePasswordField: !this.state.enablePasswordField,
            });
        }
    }

    checkValidEmail = (text: string) => {
        if(/^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,3})+$/gm.test(text)){
            this.setState({
                errors: {
                  ...this.state.errors,
                  email: ""
                },
              });
            return true;
        } else {
            return this.setState({
                errors: {
                  ...this.state.errors,
                  email: "Please enter a valid email address"
                },
              });
        }
    }

    formonkeyevent = {
        onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key == 'Enter') {
                this.doEmailLogIn();
            }
    }
    }

    handleClickConfirmPassword = {
        onClick: () => {
            this.setState({
                enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
            });
        }
    }

    isValid = () => {
        return this.state.lowerRegex &&
            this.state.upperRegex &&
            this.state.numberRegex &&
            this.state.specialRegex &&
            this.state.lengthRegex ? true : false
    }

    componentDidMount = async () => {
        this.getIndustryData();
        this.getBannerData();
        this.handleGetAllCountrySignUp()
    };

    componentDidUpdate(_prevProps: any, prevState: any) {
        if (
            prevState.countryIsoCode !== this.state.countryIsoCode
        ) {
            if (this.state.countryIsoCode !== null) {
                this.setState({
                  cityList: City.getCitiesOfCountry(this.state.countryIsoCode) ?? []
                });
            } 
        }
    }

    getIndustryData = async () => {
        try {
            const response = await fetch(`${baseURLJSON.baseURL}/bx_block_profile/user_industries`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            })
            const responseData = await response.json();
            if (response.status == 200) {
                this.setState({
                    industryList: responseData.data,
                });
            }
            else {
                this.showAlert("Alert", responseData.errors[0].account);
            }
        } catch (error) {
            return
        }
    }

    setPasswordData = (text: string, initial: boolean) => {
        this.setState({
            password: text,
            passwordError: (configJSON.lower_regex.test(text) && configJSON.upper_regex.test(text) && configJSON.number_regex.test(text)
                && configJSON.special_regex.test(text) && configJSON.length_regex.test(text)
            ) ? false : true,
            lowerRegex: configJSON.lower_regex.test(text) ? true : false,
            upperRegex: configJSON.upper_regex.test(text) ? true : false,
            numberRegex: configJSON.number_regex.test(text) ? true : false,
            specialRegex: configJSON.special_regex.test(text) ? true : false,
            lengthRegex: configJSON.length_regex.test(text) ? true : false,
            passwordErrorMessage:''
        });
    };
    setPasswordDataDisappear = ()=> {
        this.setState({passwordError:false})
    }

    weekStrong = () => {
        return this.isValid() ? this.strongText : this.weakText
    }

    passwordTxtType = () => {
        return this.state.enablePasswordField ? this.passwordText : this.passwordTypeText
    }

    passwordhideShowIcon = () => {
        return this.state.enablePasswordField ? <VisibilityOff /> : <Visibility />
    }

    confirmPsswordTxt = () => {
        return this.state.enableConfirmPasswordField ? this.passwordText : this.passwordTypeText
    }

    passvisibleinvisibleIcon = () => {
        return this.state.enableConfirmPasswordField ? <VisibilityOff /> : <Visibility />
    }

    countryPlaceholderTxt = () => {
        return this.state.age !== "" ? undefined : () => <div className={`${this.props.classes.placeholderText}`}>Country</div>
    }

    cityPlaceholderTxt = () => {
        return this.state.cityName !== "" ? undefined : () => <div className={`${this.props.classes.placeholderText}`}>City</div>
    }


    industryPlaceholderTxt = () => {
        return this.state.industry !== "" ? undefined : () => <div className={`${this.props.classes.placeholderText}`}>Industry</div>
    }

    industryMapData = () => {
        return (
            this.state.industryList.map((data) => (
                <MenuItem key={data?.id} value={data?.id}>{data?.attributes?.industry_name}</MenuItem>
            ))
        )
    }


    passwordInputClasses = () => {
        let result;

        if (this.state.passwordError) {
            if (
                this.state.lowerRegex &&
                this.state.upperRegex &&
                this.state.numberRegex &&
                this.state.specialRegex &&
                this.state.lengthRegex
            ) {
                result = `${this.props.classes.inputMain}`;
            } else {
                result = `${this.props.classes.redinputMain}`;
            }
        } else {
            result = `${this.props.classes.inputMain}`;
        }

        return result;
    };

    confirmpasswordClasses = () => {
        let result;
        if (this.state.confirmPasswordError) {
            result = `${this.props.classes.redinputMain}`;
        } else {
            result = `${this.props.classes.inputMain}`;
        }
        return result;
    };
    numImageSource = () => {
        return this.state.numberRegex ? rightImg : wrongImg
    }

    specialImageSource = () => {
        return this.state.specialRegex ? rightImg : wrongImg;
    }

    lowerImageSource = () => {
        return this.state.lowerRegex && this.state.upperRegex ? rightImg : wrongImg
    }

    lengthImageSource = () => {
        return this.state.lengthRegex ? rightImg : wrongImg
    }

    strongImageSource = () => {
        return this.isValid() ? rightImg : wrongImg
    }

    passwordTxtErrorsContent = () => {
        const numberTextClass = this.state.numberRegex ? this.props.classes.greenupperTxt : this.props.classes.reduppertxt;
        const specialTextClass = this.state.specialRegex ? this.props.classes.greenupperTxt : this.props.classes.reduppertxt;
        const lowerTextClass = this.state.lowerRegex && this.state.upperRegex ? this.props.classes.greenupperTxt : this.props.classes.reduppertxt;
        const lengthTextClass = this.state.lengthRegex ? this.props.classes.greenupperTxt : this.props.classes.reduppertxt;
        const weekStrongP = this.isValid() ? this.props.classes.greenupperTxt : this.props.classes.reduppertxt;
        return this.state.passwordError && (
            <Box component="span" className={`${this.props.classes.paswordMainBox}`}>
                <Box className={`${this.props.classes.uperLowerTxt}`}>
                    <img width={'14.85px'} height={'16px'} src={this.numImageSource()} alt="" />
                    <Typography component="div" className={numberTextClass}>At least one number (0-9)</Typography>
                </Box>
                <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxt}`}>
                    <img width={'14.85px'} height={'16px'} src={this.specialImageSource()} alt="" />
                    <Typography component="div" className={specialTextClass}>At least one special character (Eg. @#%$)</Typography>
                </Box>
                <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxt}`}>
                    <img width={'14.85px'} height={'16px'} src={this.lowerImageSource()} alt="" />
                    <Typography component="div" className={lowerTextClass}>At least one uppercase & one lowercase letter</Typography>
                </Box>
                <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxt}`}>
                    <img width={'14.85px'} height={'16px'} src={this.lengthImageSource()} alt="" />
                    <Typography component="div" className={lengthTextClass}>Minimum 8 characters</Typography>
                </Box>
                <Box sx={{ marginTop: '5px' }} className={`${this.props.classes.uperLowerTxt}`}>
                    <img width={'14.85px'} height={'16px'} src={this.strongImageSource()} alt="" />
                    <Typography component="div" className={weekStrongP}>{`Password strength:${this.weekStrong()}`}</Typography>
                </Box>
            </Box>
        );
    }

    handleClose = {
        onClose: () => {
            this.setState({
                open: false,
            })
        }
    }

    handleCloseClick = {
        onClick: () => {
            this.setState({
                open: false,
            })
        }
    }

    apiElseCondition = (responseAllData: any) => {
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                city: (responseAllData.city?.[0] || !this.state.cityName) && "Please select city",
                company: (responseAllData.company_name?.[0] || !this.state.company) && "Please enter company",
                country: (responseAllData.country?.[0] || !this.state.age) && "Please select country",
                firstName: (responseAllData.first_name?.[0] || !this.state.firstName) && "Please enter first name",
                phoneNumber: (responseAllData.phone_number?.[0] || !this.state.mobileNumber) && "Please enter mobile number",
                jobTitle: (responseAllData.job_title?.[0] || !this.state.jobTitle) && "Please enter job title",
                lastName: (responseAllData.last_name?.[0] || !this.state.LastName) && "Please enter last name",
                countrySignUpDialCode: ""
            }
          }))
    }

    checkPasswordValidation = () => {
        if(!this.state.password){
            this.setState({
                passwordError: true
            })
        }
        if(this.state.password == this.state.confirmPassword){
            return true;
        } else {
            this.setState({
                confirmPasswordError: true,
                confirmErrorMessage: 'Password does not match'
            })
            return false;
        }
    }

    getBannerData = async () => {
        try {
            const response = await fetch(`${baseURLJSON.baseURL}/bx_block_attachment/banners/show?image_type=signup`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            })
            const responseAllData = await response.json();
            if (response.status == 200) {
                this.setState({
                    signUpImage: responseAllData.image_url
                })
            }
            else { 
                return 
            }
        } catch (error) {
            return
        }
    }

    async receive(_from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.createAccountApiCallId: {
                    this.createAccountApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                           console.log(responseJson,"working find");
                           this.handleClearInput() 
                        },
                        onFail: () => {
                            console.log(responseJson,"error find");
                            if(responseJson && responseJson?.errors){
                                const  email  = responseJson?.errors[0]?.account;
                                const updatedErrors = {
                                    ...this.state.errors,
                                    email,
                                };
                                this.setState({
                                errors: updatedErrors,
                                });
                            }

                        },                            
                
                    });
                    break;
                }
            
            }
        }
    }

    getToken= ()=> {
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data);
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            }
            else {
                throw error;
            }
        }
        return  id?.token 
    }
    createAccountMail = () => {
        this.setState({
            errors: {
                city: !this.state.cityName ? "Please select city" : "",
                company: !this.state.company ?  "Please enter company" : "",
                country: !this.state.age ?  "Please select country" : "",
                firstName: !this.state.firstName ?  "Please enter first name" : "",
                phoneNumber: !this.state.mobileNumber ?  "Please enter mobile number" : "",
                jobTitle: !this.state.jobTitle ?  "Please enter job title" : "",
                lastName: !this.state.LastName ?  "Please enter last name" : "",
                countrySignUpDialCode: !this.state.countrySignUpDialCode ?  "Please select country dial code" : "",
                email: !this.state.workEmail ?  "Please enter email" : "", 
            },
            confirmErrorMessage: !this.state.confirmPassword ? "Please enter conformed password" : "",
            passwordErrorMessage :  !this.state.password ? "Please enter password" : ""
        })
        if (this.state.cityName
            && this.state.company
            && this.state.age
            && this.state.firstName
            && this.state.mobileNumber
            && this.state.jobTitle
            && this.state.LastName
            && this.state.countrySignUpDialCode
            && this.state.workEmail
            && this.checkValidEmail(this.state.workEmail)
            && !this.state.confirmPasswordError
            && this.checkPasswordValidation()
            && this.isValid()) {

            this.doEmailLogIn();
        }
        else if (
                !this.state.lowerRegex ||
                !this.state.upperRegex ||
                !this.state.numberRegex ||
                !this.state.specialRegex ||
                !this.state.lengthRegex
            ) {
                this.setState({ passwordError: true });
            }
      };
    
    doEmailLogIn = ()=> {
        const header = {
            token:this.getToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createAccountApiCallId = requestMessage.messageId;
        
        const body = {
            "data": {
                "attributes": {
                    "first_name": this.state.firstName,
                    "last_name": this.state.LastName,
                    "company_name": this.state.company,
                    "job_title": this.state.jobTitle,
                    "user_type": "guest_user",
                    "password": this.state.password,
                    "password_confirmation": this.state.confirmPassword,
                    "email": this.state.workEmail,
                    "country": this.state.age,
                    "city": this.state.cityName,
                    "user_industry_id": this.state.industry,
                    "country_code": this.state.countrySignUpDialCode,
                    "phone_number": this.state.mobileNumber,
                },
                "type": "email_account"
            }
        } as any

        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `/account_block/accounts`,
            method: "POST",
            body: JSON.stringify(body)
        });
     
    }
    handleClearInput = ()=> {
        this.setState({
            open: true,
            firstName: "",
            LastName: "",
            company: "",
            jobTitle: "",
            password: '',
            confirmPassword: "",
            workEmail: "",
            age: "",
            cityName: "",
            industry: "",
            mobileNumber: "",
            errors: {
                city: '',
                company: '',
                country: '',
                email: '',
                firstName: '',
                phoneNumber: '',
                jobTitle: '',
                lastName: '',
                countrySignUpDialCode: '',
            },
            confirmErrorMessage:'',
            clearInput: true,
            passwordErrorMessage:''
            
        })
    }

    handleGetAllCountrySignUp = () => {
        const getAllCountryList = Country.getAllCountries();

        getAllCountryList.forEach((item) => {
            const countryName = item.name;
            const isoCode = item.isoCode;
            const allCitySignup = City.getCitiesOfCountry(isoCode)
            if (countryName && allCitySignup.length > 0) {
              this.setState((prevState) => ({
                countryList: [...prevState.countryList, item],
              }));
            }
        });
      };
    // Customizable Area End

    // Customizable Area End
}