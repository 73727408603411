import React, { Component, ReactNode } from "react";
import EstimatedPriceBox from "./EstimatedPriceBox.web";
import SaveExistingDealContainer from "./SaveExistingDealContainer.web";
import { Box, isWidthDown, Theme, withWidth } from "@material-ui/core";
import SelectWithLabel from "../../../components/src/SelectWithLabel.web";
import { BotTimeOptions, SoftwareBotsOptions, TechnologyProjectPricingFieldKeysToRemove } from "./constants";
import { projectPricingContentStyles, StyledBox } from "./StaffingProjectPricing.web";
import { IEstimatedPriceEndPoint, IEstimatedPriceRequestBody, IEstimatedPriceStates, ITechnologyPricingFormStates } from "./types";
import PricingTypeContentHeader from "./PricingTypeContentHeader.web";
import AutocompleteWithLabel from "../../../components/src/AutocompleteWithLabel.web";

type IProps = {
    navigation:any;
    title: string | undefined;
    countryList: string[];
    estimatedPrice: string;
    oneTimeImplementationCost: string;
    handleGetEstimatedPrice: (body: IEstimatedPriceRequestBody, endPoint: IEstimatedPriceEndPoint) => void;
    handleResetEstimatedPrice: (value: IEstimatedPriceStates) => void;
    width: Parameters<Theme["breakpoints"]["only"]>[0];
};

type IState = {
    isApproved: boolean;
    softwareBot: string;
    technologyCountry: string;
    botTime: string;
    isAllSelected: boolean;
};

class TechnologyProjectPricing extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            isApproved: true,
            softwareBot: "",
            technologyCountry: "",
            botTime: "",
            isAllSelected: false
        };
    }

    handleResetFields = () => {
        this.setState({ softwareBot: "", technologyCountry: "", botTime: "", isAllSelected: false });
        this.props.handleResetEstimatedPrice("technologyEstimatedPrice");

        TechnologyProjectPricingFieldKeysToRemove.forEach(k => localStorage.removeItem(k));
    }

    handleFieldChange = (name: ITechnologyPricingFormStates, value: string) => {
        this.setState({
            [name]: value
        } as unknown as Pick<IState, keyof IState>, () => {
            const { softwareBot, technologyCountry, botTime } = this.state;
            if (softwareBot !== "" && technologyCountry !== "" && botTime !== "") {
                this.props.handleGetEstimatedPrice({ software_bots: softwareBot, country: technologyCountry, bot_time_day: botTime }, "technology")
                this.setState({ isAllSelected: true })
            }
        });
        localStorage.setItem(name, value)
    }

    handleSaveExistingDeal = (value: boolean) => {
        if(value){
            localStorage.setItem("price",this.props.estimatedPrice);
            this.props.navigation.navigate("CreateOpportunitiesFromPriceProject");
            return
        }
        this.setState({ isApproved: false });
    }

    componentDidMount = () => {
        let softwareBot = localStorage.getItem("softwareBot") as string;
        let technologyCountry = localStorage.getItem("technologyCountry") as string;
        let botTime = localStorage.getItem("botTime") as string;

        if (softwareBot !== "" && softwareBot !== null) {
            this.setState({ softwareBot })
        }
        if (technologyCountry !== "" && technologyCountry !== null) {
            this.setState({ technologyCountry })
        }
        if (botTime !== "" && botTime !== null) {
            this.setState({ botTime })
        }
    }

    render(): ReactNode {

        const { title, countryList, estimatedPrice, oneTimeImplementationCost } = this.props;
        const { isApproved, softwareBot, technologyCountry, botTime } = this.state;
        const isMobileView = isWidthDown("sm", this.props.width, true);

        return (
            <Box sx={projectPricingContentStyles.flexColumn}>
                <PricingTypeContentHeader
                    data-test-id="technology-pricing-type-content-header"
                    title={title}
                    handleReset={this.handleResetFields}
                    handleEnable={softwareBot == "" && technologyCountry == "" && botTime == ""}
                />
                <SelectWithLabel
                    data-test-id="software-bots-select-box"
                    containerStyle={projectPricingContentStyles.fullWidth}
                    label="Software Bots"
                    data={SoftwareBotsOptions}
                    value={softwareBot}
                    onChange={(value) => this.handleFieldChange("softwareBot", value)}
                    isRequired
                />
                <StyledBox>
                    <AutocompleteWithLabel
                        data-test-id="country-select-box"
                        className="autocomplete"
                        label="Country"
                        data={countryList}
                        value={technologyCountry}
                        onChange={(value)=>this.handleFieldChange("technologyCountry",value)}
                        isRequired
                    />
                    <SelectWithLabel
                        data-test-id="bot-time-select-box"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        label="Bot time/day"
                        data={BotTimeOptions}
                        value={botTime}
                        onChange={(value) => this.handleFieldChange("botTime", value)}
                        isRequired
                    />
                </StyledBox>
                {technologyCountry !== "" &&  estimatedPrice !== "" && <EstimatedPriceBox
                    title={title}
                    estimatedPrice={estimatedPrice}
                    oneTimeImplementationCost={oneTimeImplementationCost}
                />}
                {
                   technologyCountry !== "" &&  isApproved && estimatedPrice !== "" &&
                    <SaveExistingDealContainer
                        data-test-id="save-existing-deal-buttons-container"
                        handleSaveExistingDeal={this.handleSaveExistingDeal}
                    />
                }
            </Box>
        )
    }
}

export default withWidth()(TechnologyProjectPricing);
