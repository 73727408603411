Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";
// Customizable Area Start
exports.backToLoginText = "Back to log in";
exports.cheackEmail = "Check your email";
exports.cheachEmailDesc = "We've sent a password reset link to your Registered email.";
exports.dontreciveMail = "Didn't receive the email?";
exports.clikToResend = "Click to resend";
// Customizable Area End