import React, { ReactNode } from 'react';
import Layout from '../../../components/src/Layout.web'
import { UpdatePasswordBreadcrumb } from './constants';
import UpdatePasswordController from './UpdatePasswordController.web';
import InputWithLabel from '../../../components/src/InputWithLabel.web';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Box, Button, Typography, styled } from '@material-ui/core';
import { rejected, approved } from "./assets";
import { SuccessToast } from '../../../components/src/SuccessTost.web';

export default class UpdatePassword extends UpdatePasswordController {
    handleColorCondition = (value: boolean) => {
        return value ? "#fff" : "#F87171"
    }
    handleTrueConditionAsString = (condition: boolean, rightConditionValue: string, wrongConditionValue: string) => {
        return condition ? rightConditionValue : wrongConditionValue;
    }
    render(): ReactNode {
        const {
            currentPasswordValidation,
            newPasswordValidation,
            currentPasswordVisible,
            newPasswordVisible,
            confirmPasswordVisible,
            currentPassword,
            newPassword,
            confirmPassword,
            controlField,
            numberRule,
            specialCharRule,
            lengthRule,
            lowercaseRule,
            uppercaseRule,
            passwordNotMatch,
            showToast
        } = this.state;
        return (
            <Layout
                breadcrumb={UpdatePasswordBreadcrumb}
                navigation={this.props.navigation}
                title="UPDATE PASSWORD"
            >
                <UpdatePasswordContainer>
                    <InputWithLabel
                        data-test-id="current-password-input"
                        containerStyle={webStyles.fullWidth}
                        inputType={this.handleTrueConditionAsString(currentPasswordVisible, "text", "password")}
                        inputValue={currentPassword}
                        label="Current Password"
                        placeholder="Password"
                        onChange={(value) => this.handlePasswordChange("currentPassword", value)}
                        onBlur={() => this.handlePasswordValidation("currentPassword")}
                        iconAction={() => this.handlePasswordVisibilityChange("currentPasswordVisible", !currentPasswordVisible)}
                        hasIcon={currentPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    />
                    {
                        !currentPasswordValidation &&
                        <Typography className="password-match-text">Current password {currentPassword === "" ? "can't be blank" : "is not valid"} </Typography>
                    }
                    <Box className="relative">
                        <InputWithLabel
                            data-test-id="new-password-input"
                            containerStyle={webStyles.fullWidth}
                            inputType={this.handleTrueConditionAsString(newPasswordVisible, "text", "password")}
                            inputValue={newPassword}
                            label="New Password"
                            placeholder="Password"
                            onChange={(value) => this.handlePasswordChange("newPassword", value)}
                            onBlur={() => this.handlePasswordValidation("newPassword")}
                            onFocus={this.handleConfirmFieldVisibilityVisible}
                            iconAction={() => this.handlePasswordVisibilityChange("newPasswordVisible", !newPasswordVisible)}
                            hasIcon={newPasswordVisible ? <Visibility /> : <VisibilityOff />}
                        />
                        {
                            !newPasswordValidation && this.state.newPassword.length <= 0  && 
                            <Typography className="password-match-text">New password can't be blank</Typography>
                        }
                        {
                            !newPasswordValidation && this.state.newPassword.length > 0  && 
                            <Typography className="password-match-text">Please enter correct passowrd</Typography>
                        }
                        {
                            controlField &&
                            <Box className="password-control-field" component="span">
                                <Box className="password-control-field-item" color={this.handleColorCondition(numberRule)}>
                                    <img src={this.handleTrueConditionAsString(numberRule, approved, rejected)} alt="" />
                                    <Typography className="confirm-text">At least one number (0-9)</Typography>
                                </Box>
                                <Box className="password-control-field-item" color={this.handleColorCondition(specialCharRule)}>
                                    <img src={this.handleTrueConditionAsString(specialCharRule, approved, rejected)} alt="" />
                                    <Typography className="confirm-text">At least one special character (Eg. @#%$)</Typography>
                                </Box>
                                <Box className="password-control-field-item" color={this.handleColorCondition(lowercaseRule && uppercaseRule)}>
                                    <img src={this.handleTrueConditionAsString(lowercaseRule && uppercaseRule, approved, rejected)} alt="" />
                                    <Typography className="confirm-text">At least one uppercase & one lowercase letter</Typography>
                                </Box>
                                <Box className="password-control-field-item" color={this.handleColorCondition(lengthRule)}>
                                    <img src={this.handleTrueConditionAsString(lengthRule, approved, rejected)} alt="" />
                                    <Typography className="confirm-text">Minimum 8 characters</Typography>
                                </Box>
                                <Box className="password-control-field-item" color="#F87171">
                                    <img src={rejected} alt="" />
                                    <Typography className="confirm-text">Password strength:weak</Typography>
                                </Box>
                            </Box>
                        }
                    </Box>
                    <InputWithLabel
                        data-test-id="confirm-password-input"
                        containerStyle={webStyles.fullWidth}
                        inputType={this.handleTrueConditionAsString(confirmPasswordVisible, "text", "password")}
                        inputValue={confirmPassword}
                        label="Confirm New Password"
                        placeholder="Password"
                        onChange={(value) => this.handlePasswordChange("confirmPassword", value)}
                        onBlur={() => this.handlePasswordValidation("confirmPassword")}
                        iconAction={() => this.handlePasswordVisibilityChange("confirmPasswordVisible", !confirmPasswordVisible)}
                        hasIcon={confirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    />
                    {
                        passwordNotMatch &&
                        <Typography className="password-match-text">Password does not match</Typography>
                    }
                    <Box className="button-container">
                        <Button className="update-password-button action-button" disabled={passwordNotMatch || (!passwordNotMatch as boolean && currentPassword === "") || !this.controlValidPassword(newPassword) || !this.controlValidPassword(currentPassword) || (newPassword !== confirmPassword)} data-test-id="update-password-button" onClick={this.handleUpdatePassword}>Update Password</Button>
                        <Button className="cancel-button action-button" data-test-id="cancel-button" onClick={this.handleCancelButtonAction}>Cancel</Button>
                    </Box>
                    {
                        showToast &&
                        <SuccessToast
                            text={'Your password has been successfully updated'}
                        />
                    }
                </UpdatePasswordContainer>
            </Layout>
        )
    }
}

const webStyles = {
    fullWidth: {
        paddingBottom: "15px",
        width: "100%",
    },
}

const UpdatePasswordContainer = styled(Box)({
    "& .password-match-text": {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: '400',
        marginTop: '-10px',
    },
    "& .button-container": {
        display: "flex",
        gap: "16px",
        marginTop: "20px"
    },
    "& .action-button": {
        borderRadius: "8px",
        height: '44px',
        fontSize: '16px',
        fontWeight: "700",
        fontFamily: "Inter, sans-serif",
        textTransform: "capitalize"
    },
    "& .update-password-button": {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        width: '170px',
    },
    "& .update-password-button.Mui-disabled": {
        color: "#fff"
    },
    "& .cancel-button": {
        border: '1px solid',
        width: "87px",
        backgroun: 'linear-gradient(90deg,  #364161,  #364161) padding-box, linear-gradient(90deg, #06081F 0%, #06081F 100%) border-box',
        color: "#000000",
    },
    "& .relative": {
        position: "relative"
    },
    "& .password-control-field": {
        background:
            "linear-gradient(90deg, rgba(51,60,92,1) 36%, rgba(10,12,35,1) 100%)",
        borderRadius: "8px !important",
        width: "283.85px !important",
        position: "absolute",
        zIndex: 999,
        padding: "12px",
    },
    '& .password-control-field::after': {
        content: '""',
        position: "absolute",
        top: 0,
        right: "50%",
        marginTop: "-15px",
        borderWidth: "8px",
        borderStyle: "solid",
        borderColor: "transparent transparent #0A0528 transparent",
    },
    "& .password-control-field-item": {
        display: "flex",
        alignItems: "center",
        marginTop: 'auto',
        "& img": {
            width: "14.85px",
            height: "16px"
        }
    },
    "& .password-control-field-item:not(first-child)": {
        marginTop: "5px"
    },
    "& .confirm-text": {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        paddingLeft: "9px",
        fontFamily: "'Inter', sans-serif",
    },
    "@media(max-width: 960px)": {
        "& .password-match-text": {
            fontSize: "10px"
        }
    },
    "@media(max-width: 576px)": {
        "& .button-container": {
            flexDirection: "column",
            "& .action-button": {
                width: "100%!important",
                fontSize: "14px"
            }
        },
        "& .password-control-field": {
            "& .confirm-text": {
                fontSize: "10px"
            }
        }
    },
    "@media(max-width: 440px)": {
        "& .password-control-field": {
            width: "unset!important",
        }
    }
});