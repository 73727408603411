import React, { Component } from "react";
import { InputAdornment, Input, Box, Button, Typography, IconButton, withStyles  } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { imagewithoutApi, opportunitiesIon } from "../assets";
import FilterList from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import {  ArrowDropDown, ArrowDropUp  } from "@material-ui/icons";
import { IDealListAttributes } from "../types";
import { stageValues, dateFormatOppAllList } from "../constants";
import { OpportunitiesField, opportunityFieldStyles } from "./OpportunitiesTable.web";
export const tableStyleCss = require("./tableCssStyle");
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import CircularProgress from '@material-ui/core/CircularProgress';

type IProps = {
    deal: IDealListAttributes[];
    handleInputChange: (dataType: string, value: string) => void;
    handleDealCommonNavigation: (path: string) => void;
    handleSortDeal:(column:string) => void;
    sortByDeal:string | null
    sortDirectionDeal:string,
    handleGetDealRecord: (path: IDealListAttributes) => void;
    classes:any
    isLoading:boolean,
    listSearchValueErrorDeal:boolean,
    listSearchValueDeal:any,
    handleBlurEventDeal:() => void;
};

type IState = {
    dealId:string;
    DealIdName:string;

};

export class DealRegistrationBlock extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            dealId: '',
            DealIdName: ''
        };
    }
    
    handleSortDeal = (column: string) => {
        this.props.handleSortDeal(column);
    };
    renderSortingIconDeal(column:any, direction:any) {
        const isActive = this.props.sortByDeal === column && this.props.sortDirectionDeal === direction;
        return (
          <IconButton
            size="small"
            data-test-id="icon-handleSortDealClickID"
            onClick={() => this.handleSortDeal(column)}
            style={{ color: isActive ? '#000' : '#999', marginBottom: "-11px" ,marginTop: "-12px"}}>
            {direction === 'asc' ? <ArrowDropUp  style={{ fontSize: "22px" }} /> : <ArrowDropDown   style={{ fontSize: "22px" }}  />}
          </IconButton>
        );
      }
    handleNavigation = (path: string) => {
        this.props.handleDealCommonNavigation(path);
    };

    handleGetOpportunitesRecord =(data:IDealListAttributes)=> {
        this.props.handleGetDealRecord(data)
    }

    

    renderTableData = (deal: any) => {
        return (
            <>
                <Box className="table-wrapper">
                    <Box className="table-container">
                        <Table className="table">
                            <TableHead  >
                                <TableRow className="table-header-row">
                                    <Box className="table-header-cell-text">
                                        <TableCell className="table-cell">
                                            <Typography>Deal ID</Typography>
                                        </TableCell>
                                    </Box> 
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId"   className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSortDeal("deal_name")}}  data-test-id="nameIdDeal"> 
                                                Deal Name
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box className="table-header-cell-text"> Stage </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box className="table-header-cell-text">Tier to Partner  </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId" data-test-id="handleTableSearchId"   className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSortDeal("end_customer_name")}}  data-test-id="endCustomerAccountIdDl"> 
                                                 End Customer Account
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId"   className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSortDeal("amount")}}  data-test-id="amountIdDl"> 
                                                 Amount
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId"  className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSortDeal("proposed_amount")}}  data-test-id="proposedAmountAllOppIdDl"> 
                                                Proposed Amount
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId" className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSortDeal("expected_revenue")}}  data-test-id="expectedRevenueIdDl"> 
                                                 Expected Revenue
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId" className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSortDeal("created_date")}}  data-test-id="CloseAtOppIdOPDl"> 
                                                 Created On
                                            </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId" className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSortDeal("close_on")}}  data-test-id="CloseOnOppIdDl"> 
                                                 Close On
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box className="table-header-cell-text">City </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    deal.slice(0, 3).map((item: any) => (
                                        <TableRow key={item.attributes.deal_id} className="table-body-row">
                                            <TableCell>
                                                <Typography data-test-id="viewOppDetailsLink" onClick={() => this.handleGetOpportunitesRecord(item)}  className="opportunity-id">
                                                        {item.attributes.deal_id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="blue-text">{item.attributes.deal_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ ...opportunityFieldStyles[item.attributes.stage as keyof typeof opportunityFieldStyles] }}>{stageValues[item.attributes.stage as keyof typeof stageValues] }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="blue-text">{item.attributes.account_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="common-text-deals">{item.attributes.contact_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="common-text-deals">{item.attributes.currency_symbol||'$'}{item.attributes.amount}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {item.attributes.proposed_amount != null && (
                                                    <Typography id="expected_revenueDeal" className="common-text-deals">
                                                        {parseInt(item.attributes.proposed_amount) > 0 ? `${item.attributes.currency_symbol||'$'}  ${item.attributes.proposed_amount || " "}` : ""}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                            <Typography id="expected_revenueDeal" className="common-text-deals">{item.attributes.currency_symbol||'$'}{item.attributes.expected_revenue}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography id="created_atDeal" className="common-text-deals">{dateFormatOppAllList( item.attributes.created_at,false)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography id="closing_dateDeal" className="common-text-deals">{ dateFormatOppAllList( item.attributes.closing_date,true) }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography id="cityDeal"  className="common-text-deals">{item.attributes.city}</Typography>
                                            </TableCell>
                                            
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
                <Box onClick={() => this.handleNavigation('OpportunitiesList')} data-test-id="handleNavigationOppID" className="view-more-box">
                <Typography>View More Deal </Typography> <ArrowRightAlt></ArrowRightAlt>
                </Box>
            </>
        )
    }

    render() {
        const { deal, handleInputChange,handleBlurEventDeal, classes, isLoading } = this.props
        const notthingToShowDeal = " Nothing to show"
        const noRecordsDeal ="There are no entries created for now"
        
       
          
        return (
            <OpportunitiesField>
                <Box className="table-actions-row">
                    <Typography className="view-all-title" variant="h4" component="h2">
                        <img src={opportunitiesIon} alt="" style={{ width: "40px", height: "40px", }} />
                        Deal Registration
                    </Typography>
                    <Box className="actions">
                        <Input
                            onChange={(event) => handleInputChange("deal", event.target.value)}
                            data-test-id="search-input"
                            placeholder="Search"
                            fullWidth={true}
                            disableUnderline
                            className={this.props.listSearchValueErrorDeal ? classes.reddrawerSearchField : classes.drawerSearchField}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search style={opportunityFieldStyles.colorGray} />
                                </InputAdornment>
                            } 
                            onBlur={handleBlurEventDeal}
                            value={this.props.listSearchValueDeal}
                            />
                    </Box>
                </Box>
                {isLoading ? (
                    <Box className="table-loader" >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        { deal && deal.length > 0
                            ? this.renderTableData(deal)
                            : (
                                <Box className="tableRecordsNotMainBox">
                                    <img src={imagewithoutApi} alt="" />
                                    <Typography className="tableRecordsNotTitile" >
                                        {notthingToShowDeal}                    
                                    </Typography>
                                    <Typography className="tableRecordsNotMesg"  >
                                        {noRecordsDeal}
                                    </Typography>
                                </Box> 
                            )
                        }
                    </>
                )}
            </OpportunitiesField>
        )
    }
}
export default withStyles(tableStyleCss.stylecss)(DealRegistrationBlock);
