import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "../../../components/src/GradientSVG.web";

type IProps = {
  title: string;
  graphValue: number;
  from: string;
  to: string;
};
type IState = {};

class RateCard extends Component<IProps, IState> {
  render() {
    const { title, graphValue, from, to } = this.props;
    return (
      <StyledContainer>
        <Box className="header">
          <Typography className="header-title">{title}</Typography>
        </Box>
        <GradientSVG from={from} to={to} id={graphValue.toString()} />
        <Box className="circular-progress-container">
          <CircularProgressbar
            strokeWidth={8}
            background
            text={graphValue?.toString() + "%"}
            backgroundPadding={1}
            value={graphValue}
            styles={buildStyles({
              pathColor: `url(#${graphValue})`,
              trailColor: "#E2E8F0",
              backgroundColor: "#fff",
              textColor: "#101828",
              textSize: "14px"
            })}
          />
        </Box>
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(Box)({
  "&": {
    maxHeight: "369px",
    flex: 1,
    padding: "20px 16px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    boxShadow: "0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box"
  },
  "& .header-title": {
    fontSize: "20px",
    lineHeight: "28px",
    color: "#000000"
  },
  "& .circular-progress-container": {
    width: "100%!important",
    margin: "20px auto 0 auto",
    display: "flex",
    justifyContent: "center",
    fontFamily: "'Inter'",
    fontSize: "13.7px!important",
    letterSpacing: "-0.005em",
    fontWeight: 700,
    maxWidth: "271px"
    // "& path":{
    //     height:"271px"
    // }
  },
  "@media(max-width: 1224px)": {
    "&": {
      width: "calc(50% - 24px)"
    }
  },
  "@media(max-width: 576px)": {
    "& .header-title": {
      fontSize: "14px",
      color: "#000000"
    }
  }
});

export default RateCard;
