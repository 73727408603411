import { Box, Button, styled } from "@material-ui/core";
import React, { Component, ReactNode } from "react";

type IProps = {
    handleSaveExistingDeal: (value: boolean) => void;
};

type IState = {};

class SaveExistingDealContainer extends Component<IProps, IState>{
    render(): ReactNode {
        const { handleSaveExistingDeal } = this.props;
        return (
            <StyledWrapperBox>
                <StyledBox>
                    <StyledBoxTextArea sx={webStyles.saveExistingDealConfirmationArea}>
                        <StyledCurrencyBox>$</StyledCurrencyBox>
                        <StyledApprovalTextBox>Do you want to map this amount to your existing opportunities?</StyledApprovalTextBox>
                    </StyledBoxTextArea>
                    <Box sx={webStyles.saveExistingDealButtons}>
                        <StyledSaveExistingDealButton data-test-id="save-existing-deal-button" onClick={() => handleSaveExistingDeal(true)}>Yes</StyledSaveExistingDealButton>
                        <StyledSaveExistingDealButton className="cancel-button" data-test-id="cancel-existing-deal-button" onClick={() => handleSaveExistingDeal(false)}>No</StyledSaveExistingDealButton>
                    </Box>
                </StyledBox>
            </StyledWrapperBox>
        )
    }
}

const webStyles = {
    saveExistingDealConfirmationArea: {
        display: "flex",
        gap: "17px"
    },
    saveExistingDealButtons: {
        display: "flex",
        alignItems: "center",
        gap: "16px"
    },
};

const StyledSaveExistingDealButton = styled(Button)({
    "&": {
        border: "1px solid",
        borderImageSource: "linear- gradient(90deg, #364161 0 %, #06081F 100 %)",
        borderRadius: "8px",
        textTransform: "none",
        color: "#0A0528",
        fontWeight: 700,
        fontSize: "16px",
        padding: "6px 10px",
        minWidth: "61px",
        width: "61px",
        height: "44px"
    },
    "&.cancel-button": {
        width: "54px",
        minWidth: "54px"
    },
    "@media(max-width: 960px)": {
        "&": {
            minWidth: "50px",
            width: "50px",
            height: "32px",
            fontSize: "13px",
            marginTop: "12px"
        },
        "&.cancel-button": {
            minWidth: "45px",
            width: "45px"
        },
    }
});

const StyledWrapperBox = styled(Box)({
    "&": {
        backgroundColor: "#F8FAFC",
        borderRadius: "8px",
        padding: "30px",
        marginTop: "30px"
    },
    "@media(max-width: 960px)": {
        "&": {
            padding: "12px"
        }
    }
});

const StyledBox = styled(Box)({
    "&": {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        display: "flex",
        justifyContent: "space-between"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "14px",
            flexDirection: "column"
        }
    }
});

const StyledCurrencyBox = styled(Box)({
    "&": {
        fontWeight: 700,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0A0528",
        color: "#fff",
        height: "44px",
        width: "44px",
        borderRadius: "50%",
        fontFamily: "'Inter', sans-serif"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "14px",
            height: "24px",
            width: "24px"
        }
    }
});

const StyledApprovalTextBox = styled(Box)({
    "&": {
        fontSize: "18px",
        fontWeight: 700,
        fontFamily: "'Inter', sans-serif"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "10px",
            lineHeight: "18px",
            marginLeft: "10px",
            width: "80%"
        }
    }
});

const StyledBoxTextArea = styled(Box)({
    "&": {
        alignItems: "center"
    },
    "@media(max-width: 960px)": {
        "&": {
            alignItems: "flex-start"
        }
    }
});

export default SaveExistingDealContainer;
