import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
export const baseURLJSON = require("../../../framework/src/config.js");
import { State ,Country,City}  from 'country-state-city';
import { ICity, IState } from "country-state-city/dist/lib/interface";
const currencyToSymbolMap = require('currency-symbol-map/map');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisible: boolean;
  apiToken: any;
  newValue: string;
  educationQualification: string[];
  projectList: string[];
  modalProject: any;
  awardList: any;
  patentList: any;
  loadingEQ: boolean;
  activeTab: number;
  loadingProject: boolean;
  loadingAwards: boolean;
  loadingPub: boolean;
  isModalOpen: boolean;
  modalItem: any;

  // ProfileBio
  firstNameP: string;
  lastNameP: string;
  company: string;
  jobTitle: string;
  workEmail: string;
  cityName: string;
  mobileNumber: string;
  emailP: string;
  country: string;
  proPermission: string;
  role: string;
  street: string;
  birthDate: string;
  website: string;
  stateName: string;
  password: string;
  userType: string;
  currency: string,
  timeZone: string,
  value: number;
  dateFormate: string,
  timeFormate: string,
  language: string,
  zipCode: string;
  confirmPassword: string;
  phone: string;
  editField: boolean;
  open: boolean;
  noneEditField: boolean;
  mobileError: string;
  showMoobileError: boolean;
  companyMobileError: string;
  CompanyshowMoobileError: boolean;
  employeeError: string;
  showEmployeErrorError: boolean;
  companyPhoneError: string;
  showCompanyPhoneError: boolean;
  languageError: string;
  showlanguageError: boolean;

  firstNameA: string;
  lastNameA: string;
  companyA: string;
  jobTitleA: string;
  workEmailA: string;
  cityNameA: string;
  mobileNumberA: string;
  mobileNumberError: boolean;
  mobileProfileError: string;
  companycurrency: string;
  countryA: string;
  proPermissionA: string;
  roleA: string;
  streetA: string;
  birthDateA: string;
  websiteA: string;
  stateNameA: string;
  passwordA: string;
  userTypeA: string;
  currencyA: string,
  timeZoneA: string,
  valueA: number;
  dateFormateA: string,
  timeFormateA: string,
  languageA: string,
  zipCodeA: string;
  confirmPasswordA: string;
  phoneA: string;
  fax: string;
  businesshours: string;
  desciptionadmin: string;
  companymobile: string;
  companywebsite: string;
  companyCountry: string;
  companyzipCode: string;
  finacialYear: string;
  employee: string;
  companystreet: string;
  companycityName: string;
  companystateName: string;
  companytimeZone: string;
  companyName: string;
  editFieldA: boolean;
  openA: boolean;
  noneEditFieldA: boolean;
  countryDialCode: string;
  companyDialCode: string;
  cityListAll:any;
  countryListAll:any;
  stateAdminListAll:any;
  cityList:any[];
  cityNameAError:string;
  birthDateAError:string;
  stateNameAError:string;
  countryNameAError:string;
  stateArray:string[];
  countryListUA:string[];
  cityListUA:string[];
  stateArrayCommon:string[];
  cityArrayCommon:string[];
  isCountryCode:string | undefined;
  isStateCode:string;
  countryArrayCommon:string[];
  timeZoneAError:string;
  companyFaxError:string
  companyTimeZoneError:string;
  companyCountryError:string;
  companyStateError:string;
  companyCityError:string;
  companyStateArrayCommon:string[];
  companyCityArrayCommon:string[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EducationalUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelTitle: string = "";
  getEducationCallId: string = "";
  getProjectCallId: string = "";
  getAwardsCallId: string = "";
  getPatentCallId: string = "";
  currencyFormArry: any[];

  // Profile Bio
  countryList: any[];
  profilerole: any[];
  permission: any[];
  cirtList: any[];
  stateList: any[];
  languageList: any[];
  timeZoneArray: any[];
  timeFormats: [];
  dateFormats: [];
  currencyArry: any[];

  countryAdminList: any[];
  profileAdminrole: any[];
  adminPermission: any[];
  cityList: any[];
  stateAdminList: any[];
  languageAdminList: any[];
  timeZoneAdminArray: any[];
  currencyAdminArry: any[];
  slectgendertypeStudent: string;
  slectgendertypeInstructor: string;
  slectgendertypeRecruiter: string;
  dialCodeList: any[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isVisible: false,
      apiToken: null,
      newValue: "1",
      educationQualification: [],
      projectList: [],
      modalProject: [],
      awardList: [],
      patentList: [],
      loadingEQ: true,
      activeTab: 1,
      loadingProject: true,
      loadingAwards: true,
      loadingPub: true,
      isModalOpen: false,
      modalItem: null,

      // ProfileBio

      firstNameP: "",
      lastNameP: "",
      emailP: "",
      company: "",
      jobTitle: "",
      workEmail: "",
      mobileNumber: "",
      cityName: "",
      password: "",
      country: "",
      street: "",
      birthDate: "",
      website: "",
      proPermission: "",
      value: 0,
      stateName: "",
      role: "",
      userType: "",
      zipCode: "",
      currency: "",
      timeZone: "",
      dateFormate: "",
      timeFormate: "",
      language: "",
      confirmPassword: "",
      phone: "",
      open: false,
      editField: true,
      noneEditField: true,
      mobileError: '',
      showMoobileError: false,
      companyMobileError: "",
      CompanyshowMoobileError: false,
      employeeError: "",
      showEmployeErrorError: false,
      companyPhoneError: "",
      showCompanyPhoneError: false,
      languageError: "",
      showlanguageError: false,


      firstNameA: "",
      lastNameA: "",
      companyA: "",
      jobTitleA: "",
      workEmailA: "",
      mobileNumberA: "",
      mobileNumberError: false,
      mobileProfileError: "",
      companycurrency: "",
      companytimeZone: "",
      cityNameA: "",
      passwordA: "",
      countryA: "",
      streetA: "",
      birthDateA: "",
      companywebsite: "",
      companystateName: "",
      websiteA: "",
      proPermissionA: "",
      companycityName: "",
      valueA: 0,
      stateNameA: "",
      roleA: "",
      userTypeA: "",
      businesshours: "",
      companyCountry: "",
      companyzipCode: "",
      zipCodeA: "",
      currencyA: "",
      timeZoneA: "",
      dateFormateA: "",
      timeFormateA: "",
      languageA: "",
      confirmPasswordA: "",
      desciptionadmin: "",
      employee: "",
      companyName: "",
      phoneA: "",
      finacialYear: "",
      companymobile: "",
      fax: "",
      companystreet: "",
      openA: false,
      editFieldA: true,
      noneEditFieldA: true,
      countryDialCode: "+91",
      companyDialCode: "+91",
      cityListAll:[],
      countryListAll:[],
      stateAdminListAll:[],
      cityList:[],
      cityNameAError:"",
      birthDateAError:"",
      stateNameAError:"",
      countryNameAError:"",
      stateArray:[],
      countryListUA:[],
      cityListUA:[],
      stateArrayCommon:[],
      cityArrayCommon:[],
      isCountryCode:"",
      isStateCode:"",
      countryArrayCommon:[],
      timeZoneAError:'',
      companyFaxError:'',
      companyTimeZoneError:'',
      companyCountryError:'',
      companyStateError:'',
      companyCityError:'',
      companyStateArrayCommon:[],
      companyCityArrayCommon:[]
    };

    this.labelTitle = configJSON.labelTitle;

    this.countryList = configJSON.CountryListArry;
    this.profilerole = configJSON.profileroleArry;
    this.permission = configJSON.permissionArry;
    this.cirtList = configJSON.CityListArry;
    this.stateList = configJSON.StateListArry;
    this.timeZoneArray = configJSON.timeZoneListArry;
    this.timeFormats = configJSON.timeFormats;
    this.dateFormats = configJSON.dateFormats;
    this.currencyArry = configJSON.currencyListArry;
    this.languageList = configJSON.laguagesListArry;

    this.countryAdminList = configJSON.CountryListArry;
    this.profileAdminrole = configJSON.profileroleArry;
    this.adminPermission = configJSON.permissionArry;
    this.cityList = configJSON.CityListArry;
    this.stateAdminList = configJSON.StateListArry;
    this.timeZoneAdminArray = configJSON.timeZoneListArry;
    this.currencyAdminArry = configJSON.currencyListArry;
    this.languageAdminList = configJSON.laguagesListArry;
    this.slectgendertypeStudent = 'Male';
    this.slectgendertypeInstructor = 'Female';
    this.slectgendertypeRecruiter = 'Other';
    this.dialCodeList = configJSON.CountryDialCodeArry;
    this.currencyFormArry = Object.entries(currencyToSymbolMap).map(([key])=>{return key;})
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start

    this.getAdminData()
    this.getAdminProfileData()
    this.getCompanyData()
    this.getStateList()
    this.getCountryListUpdated()
    this.getCityList()
    this.handleGetAllCountry()
    // this.getToken();
    // if (this.isPlatformWeb() === false) {
    //   this.props.navigation.addListener("willFocus", () => {
    //     this.getToken();
    //   });
    // }
    
    this.setState({
      cityListAll : configJSON.CityListArry,
      countryListAll: configJSON.CountryListArry,
      stateAdminListAll: configJSON.StateListArry,
      cityList:configJSON.CityListArry
    })

   

    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getEducationQualification();
          this.getProjectList();
          this.getAwardList();
          this.getPatentList();
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getStateList = ()=> {
    const stateList = State.getAllStates()
    const stateSorted = stateList?.map(item => {
      return item.name
    })
    this.setState({stateArray:stateSorted})
  } 
  getCountryListUpdated = ()=> {
    const countryList = Country.getAllCountries()
    const countrySorted = countryList?.map(item => {
      return item.name
    })
    this.setState({countryListUA:countrySorted})
  } 

  getCityList = ()=> {
    const cityList = City.getAllCities()
    const citySorted = cityList?.map(item => {
      return item.name
    })
    this.setState({cityListUA:citySorted})
  } 

  async getEducationQualification() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getEducationContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEducationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEducationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getEducationApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getProjectList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getProjectContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProjectApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getAwardList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getAwardsContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAwardsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAwardsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAwardsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getPatentList() {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.getPatentsContextType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPatentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPatentsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPatentsApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  showModal = (modalProject: any) => {
    console.log(modalProject);
    this.setState({ modalProject: modalProject });
    this.setState({ isVisible: !this.state.isVisible });
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({
      newValue: newValue,
    });
  };

  handleMobileModalClose = () => {
    this.setState({
      isModalOpen: false,
      modalItem: null,
    });
  };



  // Profile Bio

  txtInputFname = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ firstNameP: text.target.value })
    }
  }

  txtInputLname = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ lastNameP: text.target.value })
    }
  }

  txtInputComapnyName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ company: text.target.value })
    }
  }

  txtInputWebsiteName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ website: text.target.value })
    }
  }

  txtInputDateOfBirthName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const currentDate  = new Date().toISOString().split("T")[0];
      this.setState({ birthDate: text.target.value })
      if ( text.target.value <= currentDate || text.target.value == currentDate) {
        this.setState({ birthDateAError: "" })
      }
    }
  }

  txtInputStreetName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ street: text.target.value })
    }
  }

  txtInputjobTitle = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ jobTitle: text.target.value })
    }
  }

  txtInputworkEmail = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ emailP: text.target.value })
    }
  }

  txtInputMobileNo = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const value = text.target.value.replace(/\D/g, "")
      this.setState({ mobileNumber: value, mobileNumberError:  value !== text.target.value ? true :false, mobileProfileError: ""})
    },
  onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
      const value = text.target.value.replace(/\D/g, "")
      this.setState({ mobileNumber: value, mobileNumberError:  value.length < 10 ? true :false, mobileProfileError: ''})
  }
  }

  txtInputZipCode = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ zipCode: text.target.value })
    }
  }


  textInputprofileCountry = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        country: text.target.value as string
      });
    }
  }

  textRoleProps = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        role: text.target.value as string
      });
    }
  }

  textStateProps = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        stateName: text.target.value as string
      });
    }
  }

  textInputprofileCity = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        cityName: text.target.value as string
      });
    }
  }

  textPermissionProps = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        proPermission: text.target.value as string
      });
    }
  }

  textInputprofilelanguage = {
    onChange: (text: string) => {
      this.setState({
        language: text || "",
        languageError:''
      });
    }
  }

  textTimeZoneProps = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        timeFormate: text.target.value as string
      });
    }
  }

  textInputprofileTimeZone = {
    onChange: (text: string) => {
      this.setState({
        timeZone: text,
        timeZoneAError:''
      });
    }
  }

  textInputprofilecurrency = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        currency: text.target.value as string
      });
    }
  }

  selectedType = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        userType: e.target.value
      });
    }
  }

  txtInputDateFormate = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        dateFormate: e.target.value
      });
    }
  }

  showEditableForm = {
    onClick: () => {
      this.setState({
        editField: false,
        noneEditField: false,
      })
      this.handleSetStateAndCityCom()
    }
  }

  cancleEditField = {
    onClick: () => {
      this.setState({
        editField: true,
        noneEditField: true,
        mobileProfileError: "",
        mobileError:"",
        birthDateAError:"",
        mobileNumberError:false,
        languageError:"",
        showlanguageError:false,
        cityNameAError:"",
        countryNameAError:"",
        timeZoneAError:"",
        stateNameAError:""

      })
      this.getAdminData()
    }
  }

  handleClose = {
    onClose: () => {
      this.setState({
        open: false,
      })
    }
  }

  handleCloseClick = {
    onClick: () => {
      this.setState({
        open: false,
      })
    }
  }

  saveEditField = {
    onClick: () => {
      const currentDate = new Date().toISOString().split("T")[0];
      const {
        mobileNumber,language,timeZone,birthDate,
        cityName,country,stateName} = this.state;
        this.setState({
          mobileError: !mobileNumber || mobileNumber.length < 10 ? "Please enter a valid mobile number" : "",
          languageError: !language ? "Please select a language" : "",
          timeZoneAError: !timeZone ? "Please select a time zone" : "",
          birthDateAError: birthDate >= currentDate ? "Please enter a valid Date of Birth" : "",
          cityNameAError: !cityName ? "Please select a city" : "",
          countryNameAError: !country ? "Please select a country" : "",
          stateNameAError: !stateName ? "Please select a state" : "",
        })

        if (!mobileNumber ||  mobileNumber.length < 10  || !language || birthDate >= currentDate  || !cityName || !timeZone || !country || !stateName) {
          return; 
        }
     
          this.EditOrganazationAdmin();
  }
}
  
  

  countinueClick = {
    onClick: () => {
      this.setState({
        open: false,
        editField: true,
        noneEditField: true,
      })
    }
  }

  editNonEditDieldClass = () => {
    let result;

    if (this.state.noneEditField) {
      result = `${this.props.classes.profileinputTxtMain}`
    } else {
      result = `${this.props.classes.profileinputdark}`
    }

    return result;
  }

  editNonEditDSelectClass = () => {
    let result;

    if (this.state.noneEditField) {
      result = `${this.props.classes.inputMainprofileCountrySelect}`
    } else {
      result = `${this.props.classes.inputMaindarkCountrySelect}`
    }

    return result;
  }

  countryprofilePlaceholder = () => {
    return this.state.country !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Country</div>
  }

  rolePlaceholder = () => {
    return this.state.role !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Role (For Data Sharing - Hierarchy)</div>
  }

  profilePermissionPlaceholder = () => {
    return this.state.proPermission !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Profile (For Permissions)</div>
  }

  cityPlaceholder = () => {
    return this.state.cityName !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>State</div>
  }

  statePlaceholder = () => {
    return this.state.stateName !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>state</div>
  }

  LanguagePlaceholder = () => {
    return this.state.language !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Language</div>
  }

  dateFormatPlaceholder = () => {
    return this.state.dateFormate !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>dd/mm/yyyy</div>
  }

  timeFormatPlaceholder = () => {
    return this.state.timeFormate !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Time Format</div>
  }

  timeZonePlaceholder = () => {
    return this.state.timeZone !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>(GMT +00:00) UTC</div>
  }

  currencyPlaceholder = () => {
    return this.state.currency !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>$ Dollar</div>
  }

  getAdminData = async () => {
    const data: string | null = sessionStorage.getItem('token')
    //@ts-ignore
    const id = JSON.parse(data)

    try {
      const adminResponse = await fetch(`${baseURLJSON.baseURL}/account_block/accounts/${id.account.data.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": `${id.token}`
        },
      })
      const adminResponseData = await adminResponse.json();
     if (adminResponse.status === 200) {
        const { first_name, last_name, company_name, job_title, email, phone_number, website, country, date_of_birth, gender, street, date_format,
          time_format, time_zone, preffered_currency, role_for_data_sharing, profile_for_permission, city, state, zip_code, language
        } = adminResponseData.data.attributes;

        let gendersType;
        if (gender === 'male') {
            gendersType = '0';
        } else if (gender === 'female') {
            gendersType = '1';
        } else {
            gendersType = '2';
        }
      const updatedCirtList = [...configJSON.CityListArry, city];
      const updatedCountryListArry = [...configJSON.CountryListArry, country];
      const updatedstateAdminListAll = [...configJSON.StateListArry, state];

        this.setState({
          firstNameP: first_name,
          lastNameP: last_name,
          company: company_name,
          jobTitle: job_title,
          emailP: email,
          mobileNumber: phone_number ? phone_number : "",
          website: website,
          country: country,
          birthDate: date_of_birth !== null ? date_of_birth : "",
          userType: gendersType,
          role: role_for_data_sharing,
          proPermission: profile_for_permission,
          street: street,
          cityName: city,
          stateName: state,
          zipCode: zip_code,
          language: language,
          dateFormate: date_format,
          timeFormate: time_format,
          timeZone: time_zone,
          currency: preffered_currency,
          cityListAll:updatedCirtList,
          countryListAll:updatedCountryListArry,
          stateAdminListAll:updatedstateAdminListAll
        });

      if (time_zone) {
        this.timeZoneArray.push(time_zone);
      }
    } else if (['Token has Expired', 'Invalid token'].includes(adminResponseData.errors[0].token)) {
      sessionStorage.clear();
      this.props.navigation.navigate('EmailAccountLoginBlock');
    }

    } catch (error) {
      console.error(error);
    }
  }

  EditOrganazationAdmin = async () => { 
       
    const data = sessionStorage.getItem('token')
    //@ts-ignore
    const id = JSON.parse(data)

    try {
      const response = await fetch(`${baseURLJSON.baseURL}/account_block/accounts/${id.account.data.id}`, {
        method: "PUT",
        body: JSON.stringify({
          "account": {
            "phone_number": this.state.mobileNumber,
            "date_of_birth": this.state.birthDate,
            "gender": parseInt(this.state.userType),
            "language": this.state.language,
            "country_code": this.state.countryDialCode,
            "city": this.state.cityName,
            "street" : this.state.street,
            "state" : this.state.stateName,
            "country" : this.state.country,
            "zip_code" : this.state.zipCode,
            "time_format":this.state.timeFormate,
            "time_zone":this.state.timeZone,
            "date_format":this.state.dateFormate
          }
        }),
        headers: {
          "Content-Type": "application/json",
          "token": `${id.token}`
        },
      })
      const responseData = await response.json();
      if (response.status == 200) {
        const { first_name, last_name, company_name, job_title, email, phone_number, website, country, date_of_birth, gender, street, date_format,
          time_format, time_zone, preffered_currency, role_for_data_sharing, profile_for_permission, city, state, zip_code, language
        } = responseData.data.attributes;

        let admingenderType;
        if (gender === 'male') {
          admingenderType = '0';
        } else if (gender === 'female') {
          admingenderType = '1';
        } else {
          admingenderType = '2';
        }


        this.setState({
          open: true,
          firstNameP: first_name,
          lastNameP: last_name,
          company: company_name,
          jobTitle: job_title,
          workEmail: email,
          mobileNumber: phone_number,
          website: website,
          country: country,
          birthDate: date_of_birth,
          userType: admingenderType,
          role: role_for_data_sharing,
          proPermission: profile_for_permission,
          street: street,
          cityName: city,
          stateName: state,
          zipCode: zip_code,
          language: language,
          dateFormate: date_format,
          timeFormate: time_format,
          timeZone: time_zone,
          currency: preffered_currency,
          birthDateAError:'',
        });
      }
      else {
        this.setState({
          mobileProfileError: responseData.errors[0]
        })
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Admin&Company Profile
  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({
      valueA: newValue
    });
    if(newValue){
      this.getCompanyData()
      return
    }
    this.getAdminProfileData()
  };

  txtInputadminFname = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ firstNameA: text.target.value })
    }
  }

  textCountryDialProps = {
    onChange: (text: string) => {
      this.setState({ countryDialCode: text || "" })
    }
  }

  textCompanyDialProps = {
    onChange: (text: string) => {
      this.setState({ companyDialCode: text || ""})
    }
  }

  txtInputadminLname = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ lastNameA: text.target.value })
    }
  }

  txtInputadminComapnyName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ companyA: text.target.value })
    }
  }

  txtInputadminWebsiteName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ websiteA: text.target.value })
    }
  }

  txtInputfaxNo = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const faxNo = text.target.value.replace(/\D/g, "");
      this.setState({ fax: faxNo,
        companyFaxError:'' 
      })
    }
  }

  txtInputadminFinacialYear = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ finacialYear: text.target.value })
    }
  }

  txtInputadminDateOfBirthName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const currentDate  = new Date().toISOString().split("T")[0];
      this.setState({ birthDateA: text.target.value })
      if ( text.target.value <= currentDate || text.target.value == currentDate) {
        this.setState({ birthDateAError: "" })
      }
    }
  }

  txtInputadminStreetName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ streetA: text.target.value })
    }
  }

  txtInputadminjobTitle = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ jobTitleA: text.target.value })
    }
  }

  txtInputadminworkEmail = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ workEmailA: text.target.value })
    }
  }

  txtInputadminMobileNo = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const value = text.target.value.replace(/\D/g, "")
      this.setState({
        mobileNumberA: value,
        mobileError: "",
        companyMobileError:""
      })
    },
    onBlur: (text: React.ChangeEvent<HTMLInputElement>) => {
      const value = text.target.value.replace(/\D/g, "")
      this.setState({
        mobileNumberA: value,
        mobileError: ""
      })
    }
  }

  txtInputcompanyWebsiteName = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ companywebsite: text.target.value })
    }
  }

  txtInputcompanyMobileNo = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const mobilevalue = text.target.value.replace(/\D/g, "")
      this.setState({
        companymobile: mobilevalue
      });
    }
  }

  txtInputadminZipCode = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ zipCodeA: text.target.value })
    }
  }

  textInputadminCountry = {
    onChange: (text: string) => {
     
      this.setState({
        countryA: text || "",
        countryNameAError:"",
        country:text || "",
        stateArrayCommon:this.handleReturnState(text),
        cityNameA:'',
        stateNameA:'',
        cityName:'',
        stateName:'',
        cityArrayCommon:[],
        timeZoneAError:''
      });
    }    
  }
  textadminRoleProps = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        roleA: text.target.value as string
      });
    }
  }

  textStateadminProps = {
    onChange: (text: string) => {
      this.setState({
        stateNameA: text || "",
        stateNameAError:"",
        stateName:text || "",
        cityArrayCommon:this.handleReturnCitys(this.state.countryA,text),        
        cityNameA:'',
        cityName:'',
      }); 
    }
    
  }

  textInputadminCity = {
    onChange: (text: string) => {
      this.setState({
        cityNameA: text || "",
        cityName:text || "",
        cityNameAError:""
      });
    }
  }

  textadminPermission = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        proPermissionA: text.target.value as string
      });
    }
  }

  textInputadminlanguage = {
    onChange: (text: string) => {
      this.setState({
        languageA: text || "",
        languageError: ""
      });
    }
  }

  txtInputPhoneNumber = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const phonevalue = text.target.value.replace(/\D/g, "")
      this.setState({
        phone: phonevalue,
        companyPhoneError:''
      });
    }
  }

  txtInputadminbusinessHr = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        businesshours: text.target.value
      });
    }
  }

  txtInputadminDescription = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        desciptionadmin: text.target.value as string
      });
    }
  }

  textStateCompanyProps = {
    onChange: (text:string) => {
      this.setState({
        companystateName: text,
        companyCityArrayCommon:this.handleReturnCitys(this.state.companyCountry,text), 
        companycityName:'',  
        companyStateError:"",
      });
    }
  }

  textInputCompanyCountry = {
    onChange: (text:string) => {
      this.setState({
        companyCountry: text,
        companyStateArrayCommon:this.handleReturnState(text),
        companystateName:'',
        companycityName:'',
        countryNameAError:'',
        companyCityArrayCommon:[],
        companyCountryError:''
      });
    }
  }

  textInputcompnaycurrency = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        companycurrency: text.target.value as string
      });
    }
  }

  txtInputcompanyName = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        companyName: text.target.value as string
      });
    }
  }

  txtInputemployCount = {
    onChange: (text: React.ChangeEvent<HTMLInputElement>) => {
      const employevalue = text.target.value.replace(/\D/g, "")
      this.setState({
        employee: employevalue,
        employeeError:''
      });
    }
  }

  textInputcompanyTimeZone = {
    onChange: (text: string) => {
      this.setState({
        companytimeZone: text  || "",
        companyTimeZoneError:''
      });
    }
  }

  textCurrencyInputAprofile = {
    onChange: (text: string) => {
        let value=''
        if (typeof text === 'string') {
            value = text.slice(0, 3);
          }
        this.setState({
          companycurrency: value || "",
            // currencySymbol: getSymbolFromCurrency(text) || ''
        });
    },
};

  txtInputcompanyZipCode = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        companyzipCode: text.target.value as string
      });
    }
  }

  textInputCompanyCity = {
    onChange: (text:string) => {
      this.setState({
        companycityName: text,
        companyCityError:''
      });
    }
  }

  txtInputcompanyStreetName = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        companystreet: text.target.value as string
      });
    }
  }

  textInputadminTimeFormat = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        timeFormateA: text.target.value as string
      });
    }
  }

  textInputadminTimeZone = {
    onChange: (text: string) => {
      this.setState({
        timeZoneA: text || "",
        timeZoneAError: ""
      });
    }
  }

  textInputadmincurrency = {
    onChange: (text: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({
        currencyA: text.target.value as string
      });
    }
  }

  adminselectedType = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        userTypeA: e.target.value
      });
    }
  }

  txtInputadminDateFormate = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        dateFormateA: e.target.value
      });
    }
  }

  showAdminEditableForm = {
    onClick: () => {
      this.setState({
        editFieldA: false,
        noneEditFieldA: false,
      })
      this.handleSetStateAndCityCom()
    }
  }

  handleGetAllCountry = () => {
    const countryList = Country.getAllCountries();

    countryList.forEach((item) => {
      const countryName = item.name;
      const isoCode = item.isoCode;
      const statesOfCountry = State.getStatesOfCountry(isoCode) || [];
      const allCity = City.getCitiesOfCountry(isoCode)
      if (countryName && statesOfCountry.length > 0 && allCity.length > 0) {
        this.setState((prevState) => {
            const updatedArray = [...prevState.countryArrayCommon, countryName];
            updatedArray.sort();
            return {
                countryArrayCommon: updatedArray,
            };
        });
      }
    });
  };

  handleReturnState = (countryName: string): string[] => {
    const countryList = Country.getAllCountries();
    const findIsoCode: string | undefined = countryList.find(item => item.name === countryName)?.isoCode;
    const getAllState: IState[] = State.getStatesOfCountry(findIsoCode!) ?? [];
    const stateNames: string[] = getAllState
    .map(item => {
      const cities = City.getCitiesOfState(item.countryCode, item.isoCode);
      if (cities.length > 0) {
        return item.name;
      }
      return null;
    })
    .filter(name => name !== null) as string[];
  return stateNames;
};

  handleReturnCitys = (countryName: string, stateName: string) => {
    const countryList = Country.getAllCountries();
    const findIsoCodeForCity: string | undefined = countryList.find(item => item.name === countryName)?.isoCode;
    const getAllState: IState[] = State.getStatesOfCountry(findIsoCodeForCity!) ?? [];
    const findStateCode = getAllState?.find(item => item.name === stateName)?.isoCode;
    const getCityByState = City.getCitiesOfState(findIsoCodeForCity!, findStateCode!) ?? [];
    const getSortedCity = Array.isArray(getCityByState)
      ? getCityByState.map((item: ICity) => item.name).sort()
      : [];
    return getSortedCity;
  };

  handleSetStateAndCityCom=()=>{
    this.setState({
      stateArrayCommon:this.handleReturnState(this.state.countryA),
      cityArrayCommon:this.handleReturnCitys(this.state.countryA,this.state.stateNameA),
      companyStateArrayCommon:this.handleReturnState(this.state.companyCountry),
      companyCityArrayCommon:this.handleReturnCitys(this.state.companyCountry,this.state.companystateName),

    })
        
  }

  admincancleEditField = {
    onClick: () => {
      this.setState({
        editFieldA: true,
        noneEditFieldA: true,
        mobileError: '',
        companyMobileError: '',
        employeeError: '',
        companyPhoneError: '',
        birthDateAError:"",
        languageError:"",
        showlanguageError:false,
        stateNameAError:"",
        countryNameAError:"",
        timeZoneAError:"",
        companyFaxError:'',
        companyTimeZoneError:'',
        companyCountryError:'',
        companyStateError:"",
        companyCityError:"",
      })
      this.getAdminProfileData()
      this.getCompanyData()
    }
  }

  handleAdminClose = {
    onClose: () => {
      this.setState({
        openA: false,
      })
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Typical usage (don't forget to compare props):
    if (this.state.valueA !== prevState.valueA) {
      this.setState({
        editFieldA: true,
        noneEditFieldA: true,
        mobileError: '',
        companyMobileError: '',
        employeeError: '',
        companyPhoneError: '',
      })
    }
  }

  adminhandleCloseClick = {
    onClick: () => {
      this.setState({
        openA: false,
      })
    }
  }

  adminsaveEditField = {
    onClick: () => {
      const currentDate = new Date().toISOString().split("T")[0];
      const {timeZoneA, stateNameA, countryA ,mobileNumberA, languageA, birthDateA, cityNameA, valueA } = this.state;
      const errors = {
        mobileError: !mobileNumberA || mobileNumberA.length < 10 ? "Please enter a valid mobile number" : "",
        languageError: !languageA ? "Please enter a valid language" : "",
        birthDateAError: birthDateA >= currentDate ? "Please enter a valid Date of Birth" : "",
        cityNameAError: !cityNameA ? "Please select a city" : "",
        stateNameAError: !stateNameA ? "Please select a state" : "",
        timeZoneAError: !timeZoneA ? "Please select a time zone" : "",
        countryNameAError :!countryA  ? "Please select a country" : "",
      };
      this.handleValidationCheck(valueA, errors)
    }
  }
  handleValidationCheck = (valueA: number, errors: any) => {
    const {companymobile, employee, phone, fax, companytimeZone, companyCountry, companystateName, companycityName } = this.state;
    
    if (valueA === 0 && Object.values(errors).every(error => !error)) {
      this.EditOrganazationAdminData();
    }
  
    this.setState({
      ...errors,
      showMoobileError: !!errors.mobileError,
      showlanguageError: !!errors.languageError,
      companyMobileError: !companymobile || companymobile.length < 10 ? "Please enter valid mobile number" : "",
      companyPhoneError: !phone || phone.length < 10 ? "Please enter valid phone number" : "",
      companyFaxError: !fax ? "Please enter valid fax number" : "",
      companyTimeZoneError: !companytimeZone ? "Please select a time zone" : "",
      companyCountryError: !companyCountry ? "Please select a country" : "",
      companyStateError: !companystateName ? "Please select a state" : "",
      companyCityError: !companycityName ? "Please select a city" : "",
      employeeError:!employee ? "Please enter employee count": ""
    });
    
    if (!companymobile || employee === "" || phone === "" || phone.toString().length !== 10 || !fax || !companytimeZone || !companyCountry || !companystateName || !companycityName) {
      return; 
    }
  
    if (valueA === 1) {
      this.EditOrganazationCompany();
    }
  }

  admincountinueClick = {
    onClick: () => {
      this.setState({
        openA: false,
        editFieldA: true,
        noneEditFieldA: true,
      })
    }
  }

  editNonEditDAdminClass = () => {
    let adminresult;

    if (this.state.noneEditFieldA) {
      adminresult = `${this.props.classes.admininputTxtMain}`
    } else {
      adminresult = `${this.props.classes.admininputdark}`
    }

    return adminresult;
  }

  editNonEditAdminSelectClass = () => {
    let adminresult;

    if (this.state.noneEditFieldA) {
      adminresult = `${this.props.classes.inputMainCountrySelect}`
    } else {
      adminresult = `${this.props.classes.inputadmindarkCountrySelect}`
    }

    return adminresult;
  }

  countryadminPlaceholder = () => {
    return this.state.countryA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Country</div>
  }

  companyCountryadminPlaceholder = () => {
    return this.state.companyCountry !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Country</div>
  }

  roleadminPlaceholder = () => {
    return this.state.roleA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Role (For Data Sharing - Hierarchy)</div>
  }

  adminPermissionPlaceholder = () => {
    return this.state.proPermissionA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Profile (For Permissions)</div>
  }

  admincityPlaceholder = () => {
    return this.state.cityNameA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>City</div>
  }

  adminstatePlaceholder = () => {
    return this.state.stateNameA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>State</div>
  }

  adminLanguagePlaceholder = () => {
    return this.state.languageA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Language</div>
  }

  adminDateFormatPlaceholder = () => {
    return this.state.dateFormateA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>dd/mm/yyyy</div>
  }

  admintimeZonePlaceholder = () => {
    return this.state.timeZoneA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>(GMT +00:00) UTC</div>
  }

  admintimeFormatPlaceholder = () => {
    return this.state.timeFormateA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>Time Format</div>
  }

  admincurrencyPlaceholder = () => {
    return this.state.currencyA !== ("" || null) ? undefined : () => <div className={`${this.props.classes.countryprofileplaceholder}`}>$ Dollar</div>
  }


  getAdminProfileData = async () => {
    const datas = sessionStorage.getItem('token')
    // @ts-ignore
    const id = JSON.parse(datas)

    try {
      const profileResponse = await fetch(`${baseURLJSON.baseURL}/account_block/accounts/${id.account.data.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": `${id.token}`
        },
      })
      const profileResponseData = await profileResponse.json();
      if (profileResponse.status == 200) {
        const { first_name, last_name, company_name, job_title, email, phone_number, website, country, date_of_birth, gender, street, date_format,
          time_format, time_zone, preffered_currency, role_for_data_sharing, profile_for_permission, city, state, zip_code, language, country_code
        } = profileResponseData.data.attributes;

        let genderType;
        if (gender === 'male') {
          genderType = '0';
        } else if (gender === 'female') {
          genderType = '1';
        } else {
          genderType = '2';
        }
        const updatedCirtList = [...this.cityList, city]; 
        this.setState({
          firstNameA: first_name,
          lastNameA: last_name,
          companyA: company_name,
          jobTitleA: job_title,
          workEmailA: email,
          mobileNumberA: phone_number,
          websiteA: website,
          countryA: country,
          birthDateA: date_of_birth !== null ? date_of_birth : "",
          userTypeA: genderType,
          roleA: role_for_data_sharing,
          proPermissionA: profile_for_permission,
          streetA: street,
          cityNameA: city,
          stateNameA: state,
          zipCodeA: zip_code,
          languageA: language,
          dateFormateA: date_format,
          timeFormateA: time_format,
          timeZoneA: time_zone,
          currencyA: preffered_currency,
          countryDialCode: country_code,
          employeeError: '',
          companyPhoneError: '',
          mobileError: '',
          companyMobileError: '',
          cityList:updatedCirtList,
          cityNameAError:''

        });
        if(time_zone){
          const updatedTimeZoneAdminArray = [...this.timeZoneAdminArray, time_zone];
          this.timeZoneAdminArray = updatedTimeZoneAdminArray;
        }
      }
      else {
        this.showAlert("Alert", profileResponseData.errors[0].token);
        if (profileResponseData.errors[0].token == 'Token has Expired') {
          sessionStorage.clear();
          this.props.navigation.navigate('EmailAccountLoginBlock')
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getCompanyData = async () => {
    const data = sessionStorage.getItem('token')
    // @ts-ignore
    const id = JSON.parse(data)

    try {
      const companyResponse = await fetch(`${baseURLJSON.baseURL}/bx_block_profile/companies/show_company`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "token": `${id.token}`
        },
      })
      const companyResponseData = await companyResponse.json();
      if (companyResponse.status == 200) {
        const { employee_count, country, phone_number, company_name, website, financial_year, fax, mobile_number, business_hours, description, street,
          time_zone, preffered_currency, city, state, zip_code,country_code
        } = companyResponseData.data.attributes;


        this.setState({
          employee: employee_count ? employee_count : '',
          phone: phone_number,
          companyName: company_name,
          companywebsite: website,
          fax: fax,
          companymobile: mobile_number,
          finacialYear: financial_year,
          businesshours: business_hours ? business_hours : '',
          desciptionadmin: description ? description : '',
          companyCountry: country,
          companystreet: street,
          companycityName: city,
          companystateName: state,
          companyzipCode: zip_code,
          companytimeZone: time_zone,
          companycurrency: preffered_currency,
          employeeError: '',
          companyPhoneError: '',
          mobileError: '',
          companyDialCode: country_code,
          companyMobileError: '',
        });
      }
      else {
        this.showAlert("Alert", companyResponseData.errors[0].token);
        if (companyResponseData.errors[0].token == 'Token has Expired') {
          sessionStorage.clear();
          this.props.navigation.navigate('EmailAccountLoginBlock')
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  EditOrganazationAdminData = async () => {
    const data = sessionStorage.getItem('token')
    const id = JSON.parse(data as string)

    try {
      const response = await fetch(`${baseURLJSON.baseURL}/account_block/accounts/${id.account.data.id}`, {
        method: "PUT",
        body: JSON.stringify({
          "account": {
            "phone_number": this.state.mobileNumberA,
            "date_of_birth": this.state.birthDateA,
            "gender": parseInt(this.state.userTypeA),
            "language": this.state.languageA,
            "country_code": this.state.countryDialCode,
            "city": this.state.cityNameA,
            "time_format" : this.state.timeFormateA,
            "street" : this.state.streetA,
            "state" : this.state.stateNameA,
            "country" : this.state.countryA,
            "zip_code" : this.state.zipCodeA,
            "time_zone":this.state.timeZoneA,
            "date_format":this.state.dateFormateA
          }
        }),
        headers: {
          "Content-Type": "application/json",
          "token": `${id.token}`
        },
      })
      const responseData = await response.json();
      if (response.status == 200) {
        const { first_name, last_name, company_name, job_title, email, phone_number, website, country, date_of_birth, gender, street, date_format,
          time_format, time_zone, preffered_currency, role_for_data_sharing, profile_for_permission, city, state, zip_code, language, country_code
        } = responseData.data.attributes;

        let genderAdminType;
        if (gender === 'male') {
          genderAdminType = '0';
        } else if (gender === 'female') {
          genderAdminType = '1';
        } else {
          genderAdminType = '2';
        }

        this.setState({
          openA: true,
          firstNameA: first_name,
          lastNameA: last_name,
          companyA: company_name,
          jobTitleA: job_title,
          workEmailA: email,
          mobileNumberA: phone_number,
          websiteA: website,
          countryA: country,
          birthDateA: date_of_birth,
          userTypeA: genderAdminType,
          roleA: role_for_data_sharing,
          proPermissionA: profile_for_permission,
          streetA: street,
          cityNameA: city,
          stateNameA: state,
          zipCodeA: zip_code,
          languageA: language,
          dateFormateA: date_format,
          timeFormateA: time_format,
          timeZoneA: time_zone,
          currencyA: preffered_currency,
          countryDialCode: country_code,
          employeeError: '',
          companyPhoneError: '',
          mobileError: '',
          companyMobileError: '',
          birthDateAError:'',
          cityNameAError:'',
          languageError:'',
          stateNameAError:""
        });
        const updatedTimeZoneAdminArray = [...this.timeZoneAdminArray, time_zone];
        this.timeZoneAdminArray = updatedTimeZoneAdminArray;
      }
      else {
        if(responseData?.errors[0].city){
          this.setState({ cityNameAError: "Please select city" })
        }
        this.setState({ birthDateAError : responseData?.errors[0].date_of_birth})        
        if (responseData.errors[0].token == "Token has Expired") {
          this.props.navigation.navigate('EmailAccountLoginBlock')
          sessionStorage.clear()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  EditOrganazationCompany = async () => {
    const data = sessionStorage.getItem('token')
    // @ts-ignore
    const id = JSON.parse(data)

    try {
      const OrganazationCresponse = await fetch(`${baseURLJSON.baseURL}/bx_block_profile/companies/update_company`, {
        method: "PUT",
        body: JSON.stringify({
          "companies": {
            "employee_count": this.state.employee,
            "phone_number": this.state.phone,
            "mobile_number": this.state.companymobile,
            "business_hours": this.state.businesshours,
            "description": this.state.desciptionadmin,
            "country_code": this.state.companyDialCode,
            "fax":this.state.fax,
            "website":this.state.companywebsite,
            "financial_year":this.state.finacialYear,
            "preffered_currency":this.state.companycurrency,
            "time_zone":this.state.companytimeZone,
            "country":this.state.companyCountry,
            "state":this.state.companystateName,
            "city":this.state.companycityName,
            "street":this.state.companystreet,
            "zip_code":this.state.companyzipCode

          }
        }),
        headers: {
          "Content-Type": "application/json",
          "token": `${id.token}`
        },
      })
      const OrganazationCresponseData = await OrganazationCresponse.json();
      if (OrganazationCresponse.status == 200) {
        const { employee_count, country, phone_number, company_name, website, financial_year, fax, mobile_number, business_hours, description, street,
          time_zone, preffered_currency, city, state, zip_code, country_code
        } = OrganazationCresponseData.data.attributes;

        this.setState({
          openA: true,
          employee: employee_count,
          phone: phone_number,
          companyName: company_name,
          companywebsite: website,
          fax: fax,
          companymobile: mobile_number,
          finacialYear: financial_year,
          businesshours: business_hours,
          desciptionadmin: description,
          companyCountry: country,
          companystreet: street,
          companycityName: city,
          companystateName: state,
          companyzipCode: zip_code,
          companytimeZone: time_zone,
          companycurrency: preffered_currency,
          companyDialCode: country_code || '+91',
          employeeError: '',
          companyPhoneError: '',
          mobileError: '',
          companyMobileError: '',
        });
      }
      else {
        this.showAlert("Alert", OrganazationCresponseData?.errors[0]?.token);
        if (OrganazationCresponseData?.errors[0]?.token == "Token has Expired") {
          this.props.navigation.navigate('EmailAccountLoginBlock')
          sessionStorage.clear()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
