export const peopleIcon = require("../assets/people.svg");
export const morePeopleIcon = require("../assets/morePeople.svg");
export const incomeIcon = require("../assets/income.svg");
export const happyFaceIcon = require("../assets/happyFace.svg");
export const notificationsIcon = require("../assets/notifications.svg");
export const personIcon = require("../assets/person.svg");
export const moreIcon = require("../assets/more.svg");
export const emptyDealTypeIcon = require("../assets/emptyDealType.svg");
export const emptySalesVolumeIcon = require("../assets/emptySalesVolume.svg");
export const emptyTopFiveCommissionEarnedIcon = require("../assets/emptyTopFiveCommissionEarned.svg");
export const emptyTopFivePartnersIcon = require("../assets/emptyTopFivePartners.svg");
export const openDealsIcon = require("../assets/openDeals.svg");
export const closedDealsIcon = require("../assets/closedDeals.svg");
export const annualOpportunityIcon = require("../assets/annualOpportunity.svg");
export const incomeGreenIcon = require("../assets/incomeGreen.svg");
export const leftArrowIcon = require("../assets/leftArrow.svg");
export const rightArrowIcon = require("../assets/rightArrow.svg");
export const filterUpIcon = require("../assets/filterUp.svg");
export const filterDownIcon = require("../assets/filterDown.svg");
export const filterDownActiveIcon = require("../assets/filterDownActive.svg");
export const filterUpActiveIcon = require("../assets/filterUpActive.svg");
export const emptyUserDashboardIcon = require("../assets/emptyUserDashboard.svg");
export const chevronRightIcon = require("../assets/chevronRight.svg");
export const chevronLeftIcon = require("../assets/chevronLeft.svg");
export const downloadIcon = require("../assets/download.svg");
export const downloadWhiteIcon = require("../assets/downloadWhite.svg");
export const calendarIcon = require("../assets/calendar.svg");
export const increasingGraphIcon = require("../assets/increasingGraph.svg");
export const decreasingGraphIcon = require("../assets/decreasingGraph.svg");
export const increasingArrowIcon = require("../assets/increasingArrow.svg");
export const decreasingArrowIcon = require("../assets/decreasingArrow.svg");
export const emptyTableIcon = require("../assets/emptyTable.svg");
