import { Box, Theme, Typography, isWidthDown, styled, withWidth } from "@material-ui/core";
import React, { Component } from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';
import { Bar } from 'react-chartjs-2/dist';
ChartJS.defaults.font = {
  family: 'Inter',
  size: 12,
  lineHeight: "18px"
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

let formatter = Intl.NumberFormat('en', { notation: 'compact' });
const minimizeAmount = (value: string | number) => {
    let valueToNumber: number | string = parseInt(value.toString());
    return `$${formatter.format(valueToNumber)}`
}
  
const labels = ["Aliah", "Steiner", "Cand...", "Ava", "Koray", "Okum...", "Lana", "Augty", "Wuhan", "Murm.."];

type IProps = {
  width: Parameters<Theme["breakpoints"]["only"]>[0];
  top10:any,
  labels:any[],
  amounts:any[]
};
type IState = {
  filter:string;
};

class OpportunitiesByRevenueCard extends Component<IProps, IState>{
    constructor(props:IProps){
      super(props)
      this.state = {
        filter: "Choose"
      }
    }
    render() {
        const isMobileView = isWidthDown("sm", this.props.width, true);
        const isSmallView = isWidthDown("xs", this.props.width, true);
        const options = {
          responsive: true,
          // maintainAspectRatio:false,
          events: [],
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false,
            }
          },
          scales: {
            x: {
              stacked:true,
              ticks:{
                font:{
                  size:isMobileView ? 10 : 12,
                  color: "#fff",
                  family:"'Inter', sans-serif"
                },
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90
              },
              grid: {
                drawBorder: false,
                display: false,
              }
            },
            y:{
              stacked:true,
              ticks:{
                font:{
                  size:isMobileView ? 9 : 12,
                  color: "#fff",
                  family:"'Inter', sans-serif"
                },
                callback: (val:number | string) => {
                    return minimizeAmount(val)
                },
              }
            }
          },
        };
        const { amounts,labels }=this.props

        const data = {
          labels,
          datasets: [
            {
              label: 'Dataset 1',
              data:amounts,
              backgroundColor: '#F87171',
              borderRadius:{topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0},
              barThickness: isMobileView ? (isSmallView ? 10 : 14) : 32
            },
          ],
        };
        return (
            <StyledContainer>
                <Box className="content">
                    <Box className="header">
                        <Typography className="header-title">Top 10 Won Opportunities by Revenue</Typography>
                    </Box>
                    <Bar options={options} data={data} />
                </Box>
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)({
    "&":{
        border:"1px solid #E2E8F0",
        boxShadow:"0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",
        borderRadius:"8px",
        padding:"24px",
        boxSizing:"border-box"
    },
    "& .header":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"20px",
    },
    "& .header-title":{
      fontSize: "20px",
      lineHeight: "28px",
      color: "#000000"
    },
    "& .header .view-all-button":{
        fontSize:"14px",
        fontWeight:500,
        lineHeight:"20px",
        textTransform:"capitalize",
        color:"#64748B"
    },
    "& .select-container": {
      width:"115px",
      height:"40px"
    },
    "& canvas" : {
      maxHeight:"300px",
      height:"313px"
    },
    "& .graph-labels-container" : {
      marginTop:"30px"
    },
    "& .empty-gap" :{
      height:"38px",
      width:"100%"
    },
    "@media(max-width: 960px)": {
      "&": {
          padding:"12px",
          width: "calc(50% - 6px)"
      },
      "& .header":{
        marginBottom:"12px",
      },
      "& .header-title":{
        fontSize:"14px",
        lineHeight:"20px",
        color: "#000000"
      },
      "& .header .view-all-button":{
        fontSize:"12px",
        lineHeight:"16px"
      },
      "& .select-container": {
        height: "32px"
      },
      "& .graph-labels-container" : {
        marginTop:"10px"
      },
    },
    "@media(max-width: 768px)": {
      "&": {
          width: "90%"
      },
      "& canvas" : {
        width: "100%!important"
      },
    }
});

export default  withWidth()(OpportunitiesByRevenueCard);