import { Box, Button, Dialog, DialogContent, IconButton, Table, TableCell, TableRow, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"
import { Close } from "@material-ui/icons";
import { emptyTopFiveCommissionEarnedIcon } from "./assets";
import EmptyData from "../../../components/src/EmptyData.web";
import { StyledTableContainer, StyledTableHead } from "./Statistics.web";
import { StyledTableBody } from "./RegisteredOpportunitiesCard.web";

type IProps = {};
type IState = {
    isOpen:boolean;
};

const mockRows = [
    {
        id: 1,
        partnerName: "Alliyah Lane",
        earned: "$3,000.00"
    },
    {
        id: 2,
        partnerName: "Lane Steiner",
        earned: "$2,000.00"
    },
    {
        id: 3,
        partnerName: "Candice Wu",
        earned: "$1,000.00"
    },
    {
        id: 4,
        partnerName: "Ava Wright",
        earned: "$500.00"
    },
    {
        id: 5,
        partnerName: "Koray Okumus",
        earned: "$250.00"
    }
];

const periodicReportTable = [
    {
        id:1,
        date:"2023-01-01",
        salesAmount:"$1,000.00",
        commissionEarned:"$100,00"
    },
    {
        id:2,
        date:"2023-01-01",
        salesAmount:"$10,000.00",
        commissionEarned:"$1,000.00"
    },
    {
        id:3,
        date:"2023-01-01",
        salesAmount:"$5,000.00",
        commissionEarned:"$500.00"
    },
    {
        id:4,
        date:"2023-01-01",
        salesAmount:"$8,000.00",
        commissionEarned:"$800.00"
    },
    {
        id:5,
        date:"2023-01-01",
        salesAmount:"$16,000.00",
        commissionEarned:"$1,600.00"
    }
]

class EarnedCommissionsCard extends Component<IProps, IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            isOpen:false
        }
    }

    handleModalAction = (value:boolean) => {
        this.setState({isOpen:value})
    }

    render() {
        const {isOpen} = this.state;
        return (
            <StyledContainer>
                <Box className="header">
                    <Typography className="header-title">Top 5 Commission Earned</Typography>
                    <Button data-test-id="view-all-button" className="view-all-button">View All</Button>
                </Box>
                {
                    periodicReportTable.length > 0 ? 
                    <StyledTableContainer className="earned-commission-table-container">
                        <Table aria-label="Pricing Table">
                            <StyledTableHead>
                                <TableRow>
                                    <TableCell align="center">Rank</TableCell>
                                    <TableCell align="left">Partner Name</TableCell>
                                    <TableCell align="left">Earned</TableCell>
                                    <TableCell align="center">Report</TableCell>
                                </TableRow>
                            </StyledTableHead>
                            <StyledTableBody className="earned-commission-table-body">
                                {mockRows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell style={{width:"11%"}} align="center" component="th" scope="row">
                                            <Box sx={webStyles.tableRow}>
                                                {row.id}
                                            </Box>
                                        </TableCell>
                                        <TableCell style={{width:"45%"}} align="left">
                                            <Box>{row.partnerName}</Box>
                                        </TableCell>
                                        <TableCell style={{width:"26%"}} align="left">{row.earned}</TableCell>
                                        <TableCell style={{width:"18%"}} align="left">
                                            <Box>
                                                <Button data-test-id="view-button" onClick={()=>this.handleModalAction(true)} className="view-button">
                                                    View
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </StyledTableBody>
                        </Table>
                    </StyledTableContainer> :
                    <EmptyData description="You don't have any partners data." image={emptyTopFiveCommissionEarnedIcon}/>
                }
                <StyledDialog
                    data-test-id="periodic-report-dialog"
                    open={isOpen}
                    scroll="body"
                    onClose={()=>this.handleModalAction(false)}
                    maxWidth={false}
                >
                    <DialogContent className="dialog-container">
                        <IconButton data-test-id="close-dialog-button" onClick={()=>this.handleModalAction(false)} disableRipple className="close-dialog-button">
                            <Close htmlColor="#334155" />
                        </IconButton>
                        <Box className="dialog-wrapper">
                            <Box className="dialog-header">
                                <Typography className="dialog-title">Periodic Report - Aliyah Lane</Typography>
                            </Box>
                            <Box className="periodic-report-table-container">
                                <StyledTableContainer className="earned-commission-table-container">
                                    <Table aria-label="Pricing Table">
                                        <StyledTableHead>
                                            <TableRow>
                                                <TableCell align="center">Sr.</TableCell>
                                                <TableCell align="left">Date</TableCell>
                                                <TableCell align="left">Sales Amount</TableCell>
                                                <TableCell align="center">Commission Earned</TableCell>
                                            </TableRow>
                                        </StyledTableHead>
                                        <StyledTableBody className="earned-commission-table-body">
                                            {periodicReportTable.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell style={{width:"8%"}} align="center" component="th" scope="row">
                                                        <Box sx={webStyles.tableRow}>
                                                            {row.id}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell style={{width:"25%"}} align="left">
                                                        <Box>{row.date}</Box>
                                                    </TableCell>
                                                    <TableCell style={{width:"42%"}} align="left">{row.salesAmount}</TableCell>
                                                    <TableCell style={{width:"25%"}} align="left">{row.commissionEarned}</TableCell>
                                                </TableRow>
                                            ))}
                                        </StyledTableBody>
                                    </Table>
                                </StyledTableContainer>
                            </Box>
                        </Box>
                        <StyledAmountBoxContainer>
                            <Box className="amount-box">
                                <Typography className="label">Total Sales Amount</Typography>
                                <Typography className="amount">$40,000.00</Typography>
                            </Box>
                            <Box className="amount-box">
                                <Typography className="label">Total Commission Earned</Typography>
                                <Typography className="amount">$4,000.00</Typography>
                            </Box>
                        </StyledAmountBoxContainer>
                        <Box sx={webStyles.buttonContainer}>
                            <StyledButton data-test-id="view-more-button">View More</StyledButton>
                        </Box>
                    </DialogContent>
                </StyledDialog>
            </StyledContainer>
        )
    }
}

const webStyles = {
    tableRow: {
        textAlign:"center"
    },
    buttonContainer: {
        textAlign:"center",
        marginTop:"24px"
    }
};

const StyledContainer = styled(Box)({
    "&": {
        border:"1px solid rgba(226, 232, 240, 1)",
        borderRadius:"8px",
        padding:"24px",
        width:"calc(37% - 16px)",
        boxSizing:"border-box"
    },
    "& .header":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    "& .header-title":{
        fontSize:"18px",
        lineHeight:"26px"
    },
    "& .header .view-all-button":{
        fontSize:"14px",
        fontWeight:500,
        lineHeight:"20px",
        textTransform:"capitalize",
        color:"#2E17B5"
    },
    "@media(max-width: 1224px)": {
        "&": {
            width: "calc(50% - 24px)"
        }
    },
    "@media(max-width: 960px)": {
        "&": {
            padding:"12px",
        },
        "& .header-title":{
            fontSize:"14px",
            lineHeight:"20px"
        },
        "& .header .view-all-button":{
            fontSize:"12px",
            lineHeight:"16px"
        },
    },
    "@media(max-width: 768px)": {
        "&": {
            width: "100%"
        }
    }
});

const StyledDialog = styled(Dialog)({
    "& .dialog-container":{
        position:"relative"
    },
    "& .MuiDialogContent-root": {
        padding:0
    },
    "& .MuiPaper-root":{
        borderRadius:"8px!important",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
        padding:"0 0 24px 0",
        minWidth:"640px"
    },
    "& .close-dialog-button":{
        position:"absolute",
        top:"16px",
        right:"8px"
    },
    "& .MuiBackdrop-root":{
        backgroundColor:"#00000099"
    },
    "& .dialog-title":{
        fontSize:"24px",
        fontWeight:700,
        lineHeight:"32px",
        letterSpacing: "-0.005em",
        padding:"24px 16px 24px 40px"
    },
    "& .periodic-report-table-container":{
        padding:"0 24px 24px 24px",
        borderBottom:"1px solid #E2E8F0",
        borderTop:"1px solid #E2E8F0",
    },
    "& .periodic-report-table-container tr td, & .periodic-report-table-container tr th": {
        padding:"12px!important"
    },
    "@media(max-width: 960px)": {
        "& .MuiPaper-root":{
            borderRadius:"8px!important",
            boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
            padding:"0 0 18px 0",
            width:"85%",
            minWidth:"unset"
        },
        "& .close-dialog-button":{
            top:"7px"
        },
        "& .dialog-title":{
            fontSize:"20px",
            lineHeight:"26px",
            padding:"18px 12px 18px 32px"
        },
        "& .periodic-report-table-container":{
            padding:"0 18px 18px 18px",
        },
        "& .periodic-report-table-container tr td, & .periodic-report-table-container tr th": {
            padding:"9px!important"
        },
    }
});

const StyledButton = styled(Button)({
    "&": {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
        width: '116px',
        height: '44px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
    },
    "&.Mui-disabled": {
        color: "#fff"
    },
    "@media(max-width: 960px)": {
        "&": {
            width: '94px',
            height: '36px',
            fontSize: '12px'
        },
    }
});

export const StyledAmountBoxContainer = styled(Box)({
    "&": {
        display:"flex",
        gap:"16px",
        padding:"16px",
        boxSizing:"border-box",
        borderBottom:"1px solid #E2E8F0",
        borderRadius: "16px"
    },
    "& .amount-box":{
        width:"50%",
        backgroundColor:"#F1F5F9",
        height:"72px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        gap:"8px"
    },
    "& .label":{
        fontSize:"14px",
        lineHeight:"22px"
    },
    "& .amount":{
        fontSize:"16px",
        fontWeight:700,
        lineHeight:"24px"
    },
    "@media(max-width: 960px)": {
        "&": {
            padding:"12px",
            gap:"3px"
        },
        "& .amount-box":{
            height:"54px"
        },
        "& .label":{
            fontSize:"12px",
            lineHeight:"18px"
        },
        "& .amount":{
            fontSize:"14px",
            lineHeight:"20px"
        },
    }
});

export default EarnedCommissionsCard;