// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { toast } from 'react-toastify';
import { Box, Button, Modal, Typography, withStyles } from "@material-ui/core";
// Customizable Area End

interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    massageText: string;
    open: boolean;
    handleCloseClick: () => void;
    handleClose: () => void;
    // Customizable Area End
}

// Customizable Area Start
interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export class CommonPopUp extends BlockComponent<Props, S, SS> {

    static instance: CommonPopUp;
    // Customizable Area Start
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        CommonPopUp.instance = this;

        this.state = {
            // Customizable Area Start
            // Customizable Area End
        };
    }

    render() {
        // Customizable Area Start
        const {classes, massageText, open, handleCloseClick, handleClose } = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
               <Modal
                open={open}
                {...handleClose}
                data-test-id='closemodal'
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={`${classes.popupContainer}`}>
                    <Box className={`${classes.popupcloseimg}`} data-test-is='closeMOdalBtn' {...handleCloseClick}>
                        <img src={require("./close.svg")} alt="" />
                    </Box>
                    <Box className={`${classes.popupcenterTxt}`}>
                        <Typography className={`${classes.descriptionTxt}`}>{massageText}</Typography>
                    </Box>
                    <Box className={`${classes.popupBtnContainer}`}>
                        <Button className={`${classes.okBtn}`} data-test-id='countinueCilckBtn' {...handleCloseClick}>Ok</Button>
                    </Box>
                </Box>
            </Modal>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export const popUpStyles = (theme: any) => ({})

//@ts-ignore
export default withStyles(popUpStyles)(CommonPopUp);

// Customizable Area End