import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import createRequestMessage from "../../../framework/src/create-request-message";
import { Message } from "../../../framework/src/Message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { toast } from "react-toastify";

const ErrorToastStyles = {
    toast: {
        width: 'max-content',
        maxHeight: '42px !important',   
        minHeight: '42px',
        color: '#0A0528',
        borderRadius: '8px',
        fontSize: '16px'
    }
}

const ErrorToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    style: ErrorToastStyles.toast,
    hideProgressBar: true,
    closeButton: false
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    analyticsCardsData:any
    registeredOpportunities:any[],
    topTenLabels:any[],
    isLoading:boolean,
    dateRangeValue: string;
    dateFrom:string;
    dateTo:string;
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardAnalyticsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    analyticsCardsApiCallId: string | null;
    registeredOpportunitiesApiCallId: string | null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            analyticsCardsData:[],
            registeredOpportunities:[],
            topTenLabels:[],
            isLoading:false,
            dateRangeValue:'',
            dateFrom:'',
            dateTo:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.analyticsCardsApiCallId=''
        // Customizable Area End
    }


    // Customizable Area Start
    async receive(_from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage),
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage),
            );
            const errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage),
            );
            switch (apiRequestCallId) {
                case this.analyticsCardsApiCallId: {
                    this.analyticsCardsApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ analyticsCardsData: responseJson.statistics})  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 2500); 
                        },
                        onFail: () => {
                            setTimeout(() => {
                                toast.error(`${responseJson.errors[0].account}`, ErrorToastOptions)
                                this.setState({ isLoading: false })
                            }, 2500);        
                        },                      });
                    break;
                }
                case this.registeredOpportunitiesApiCallId: {
                    this.registeredOpportunitiesApiCallId = null;
                    handleResponseMessage({
                        responseJson,
                        errorJson,
                        onSuccess: () => {
                            this.setState({ registeredOpportunities: responseJson.data})  
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                            }, 2500); 
                        },
                        onFail: () => {
                            setTimeout(() => {
                                toast.error(`${responseJson.errors[0].account}`, ErrorToastOptions)
                                this.setState({ isLoading: false })
                            }, 2500);        
                        },                      });
                    break;
                }
            
            }
        }
    }

    handleGetAnalyticsCards= (from:string,to:string)=> {
        this.setState({isLoading:true})
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data);
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            }
            else {
                throw error;
            }
        }
        const header = {
            token:id?.token 
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.analyticsCardsApiCallId = requestMessage.messageId;
        
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: `${configJSON.endPointApiGetdashboardAnalytics}?from=${from}&to=${to}`,
            method: configJSON.dashboarApiMethodType,
        });
      }

    handleGetRegisteredOpportunities= (from:string,to:string)=> {
        this.setState({isLoading:true})
        const data:any = sessionStorage.getItem('token')
        let id;
        try {
            id = JSON.parse(data);
        }
        catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Invalid JSON:', error.message);
            }
            else {
                throw error;
            }
        }
        const header = {
            token:id?.token 
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.registeredOpportunitiesApiCallId = requestMessage.messageId;
        const body = {
            filters: {
                created_at:{
                    operator: "between",
                    from: from,
                    to: to
                }
            },
            "page":1,
            "per_page":3,
            "type":"opportunity"
        }
        createRequestMessage({
            header,
            requestMessage: requestMessage,
            endPoint: configJSON.endPointApiGetRegisteredOpportunities,
            method: configJSON.dashboarApiMethodTypePost,
            body: JSON.stringify(body)
        });
      }

    handleGetDataInitial = ()=> {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const lastDay = new Date(y, m + 1, 0);
        
        const firstDayFormatted = `${y}-${(m + 1).toString().padStart(2, '0')}-01`;
        const lastDayFormatted = `${y}-${(m + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

        const firstDayForChart = `01/${(m + 1).toString().padStart(2, '0')}/${y}`;
        const lastDayForChart = `${lastDay.getDate().toString().padStart(2, '0')}/${(m + 1).toString().padStart(2, '0')}/${y}`;

        this.handleGetAnalyticsCards(firstDayForChart,lastDayForChart)
        this.handleGetRegisteredOpportunities(firstDayFormatted,lastDayFormatted)
        
        const startMonth = date.toLocaleString('default', { month: 'short' });
        const endMonth = lastDay.toLocaleString('default', { month: 'short' });
        const formattedDateRange = `${startMonth} 1 - ${endMonth} ${lastDay.getDate()}`;
       
        this.setState({dateRangeValue: formattedDateRange})
    }

    async componentDidMount() {
        this.handleGetDataInitial()
    }

    handleDateRangeSelect = (value: Date[]) => {
        if (value.length === 2) {
            const [startDate, endDate] = value;
            const startMonth = startDate.toLocaleDateString('en-GB', { month: 'short' });
            const endMonth = endDate.toLocaleDateString('en-GB', { month: 'short' });
            const result = `${startMonth} ${startDate.getDate()} - ${endMonth} ${endDate.getDate()}`;
            this.setState({ dateRangeValue: result });
            const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
            const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
           
            const fromFormatted = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
            const toFormatted = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
            
            this.handleGetAnalyticsCards(formattedStartDate,formattedEndDate)
            this.handleGetRegisteredOpportunities(fromFormatted,toFormatted)
        }
    }
    // Customizable Area End
}