// Customizable Area Start
//@ts-nocheck
import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import { Box, Button, FormControl, FormControlLabel, MenuItem, Input, InputLabel, Modal, Radio, RadioGroup, Select, Tab, Tabs, TextareaAutosize, Typography, styled, withStyles, Paper, TextField } from "@material-ui/core";
import Layout from "../../../components/src/Layout.web";
import { SuccessImg, cheadRadio, closeImg, uncheadRadio } from "./assets";
import EducationalUserProfileController, { Props, configJSON } from "./EducationalUserProfileController";
import { StyledContainer } from "./ProfileBio.web";
import { Autocomplete } from "@material-ui/lab";
// Customizable Area End

// Customizable Area Start
const CustomRadioSI = styled(Radio)({
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
        color: "transparent",
        backgroundImage: `url(${uncheadRadio})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    "&.Mui-checked .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
        color: "transparent",
        backgroundImage: `url(${cheadRadio})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${cheadRadio})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
});

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-flexContainer': {
        gap: '16px !important',
    },
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
        border: '2px solid transparent',
        height: '44px',
        background: 'linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box',
        borderBottom: 0,
        borderRadius: '8px 8px 0px 0px',
        width: '200px',
    },
});

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        zIndex: '999',
        color: '#0F172A',
        '&.Mui-selected': {
            color: '#64748B',
        },
    }),
);

// Customizable Area End


export class AdminProfile extends EducationalUserProfileController {
    constructor(props: Props) {
        super(props);
    }
    renderCountryCode = (selected: any) => {
        return selected;
    }
    
    errorTextShow = (state: string | number) => {
        if (state) {
          return (
            <Typography style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}>
              {state}
            </Typography>
          );
        }
    };

    renderCurrencyInputAprofile = (params) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id="txtInputCurrency"
            sx={{ fontSize: "12px" }}
            {...params}
            placeholder="Preferred Currency"
            value={this.state.companycurrency}
        />
    )

    mobileNumberLabel = () =>{
        return(<Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <InputLabel className={`${this.props.classes.emailadminLabelText}`}>
                Mobile Number
            </InputLabel>
            <Typography component="div" variant="subtitle1" className={`${this.props.classes.requiredRedColor}`} >*</Typography>
        </Box>)}

    handleOptions = (option, value) => { return option === value }

    renderInput = (params,state,testId) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id={testId}
            sx={{ fontSize: "12px" }}
            {...params}
            value={state}
        />
    )
    getCurrencyLabelAProfile = (option: any) => {
        return option ?? this.state.inrCurrency
    };

    renderLabelName =(labelName:string)=> {
        return (
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <InputLabel className={`${this.props.classes.emailadminLabelText}`}>
                    {labelName}
                </InputLabel>
                <Typography component="div" variant="subtitle1" className={`${this.props.classes.requiredRedColor}`} >*</Typography>
            </Box>
        )
    }
    render() {
        // Customizable Area Start
        const { classes } = this.props

        const menuProps = {
            classes:{paper:classes.root},
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
                }
        }
        // Customizable Area End

        return (
            // Customizable Area Start
            <StyledContainer className={`${classes.BoxadminContainer}`}>
                {/* <SideBar {...this.props} /> */}
                <Layout
                title=""
                breadcrumb={configJSON.AdminProfileBreadcrumb}
                navigation={this.props.navigation}
                >
                <Box className={`right-side-container ${classes.adminleftSideContainer}`}>

                    <Box className={`${classes.centeradminBgColor}`}>
                        <Box sx={{ bgcolor: '#fff', margin: "0px" }}>
                            <AntTabs value={this.state.valueA} data-test-id="adminCompanytabs" onChange={this.handleTabChange} aria-label="ant example">
                                <AntTab label="Partner Admin"  className={`${classes.organizationCompany}`} />
                                <AntTab label="Partner Company" className={`${classes.organizationCompany}`} />
                            </AntTabs>
                        </Box>
                        <Box className={`${classes.infotitleContainer}`}>
                            <Typography className={`title ${classes.genralTitleTxt}`}>General Information</Typography>
                            <Button className={`${classes.adminbtnEdit}`} disabled={!this.state.editFieldA} data-test-id='editCilckBtnboth' {...this.showAdminEditableForm} variant="contained">
                                <EditIcon style={{ color: "#64748B" }} />
                                <Typography>Edit</Typography>
                            </Button>
                        </Box>
                        <Box >
                            {this.state.valueA == 0 && (
                                <Box>
                                    <Box className={`${classes.mainadminBottomPart}`}>
                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        First Name
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtFirstName" placeholder={"First Name"} fullWidth={true}
                                                    disableUnderline
                                                    className={this.editNonEditDAdminClass()}
                                                    {...this.txtInputadminFname}
                                                    value={this.state.firstNameA}
                                                    disabled
                                                    inputProps={{
                                                        maxLength: 256,
                                                    }}
                                                />
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Last Name
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtLastName" placeholder={"Last name"} fullWidth={true}
                                                    {...this.txtInputadminLname}
                                                    value={this.state.lastNameA}
                                                    disableUnderline
                                                    className={this.editNonEditDAdminClass()}
                                                    disabled
                                                    inputProps={{
                                                        maxLength: 256,
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Company Name
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtCompanyName" placeholder={"Company Name"} fullWidth={true}
                                                    disableUnderline
                                                    className={this.editNonEditDAdminClass()}
                                                    {...this.txtInputadminComapnyName}
                                                    value={this.state.companyA}
                                                    disabled
                                                    inputProps={{
                                                        maxLength: 256,
                                                    }}
                                                />
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Job Title
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtJobTitle" placeholder={"Job Title"} fullWidth={true}
                                                    {...this.txtInputadminjobTitle}
                                                    value={this.state.jobTitleA}
                                                    disableUnderline
                                                    className={this.editNonEditDAdminClass()}
                                                    disabled
                                                    inputProps={{
                                                        maxLength: 256,
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Work Email
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtWorkEmail" placeholder={"Work Email"} fullWidth={true}
                                                    disableUnderline
                                                    className={this.editNonEditDAdminClass()}
                                                    {...this.txtInputadminworkEmail}
                                                    value={this.state.workEmailA}
                                                    disabled
                                                    inputProps={{
                                                        maxLength: 256,
                                                    }}
                                                />
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                {this.mobileNumberLabel()}
                                                <Box className={`phone-input ${classes.mobileInputWrapper}`}>
                                                    <StyledDropdown
                                                        key={0}
                                                        disableUnderline
                                                        className={`dial-select`}
                                                        data-test-id="txtInputCountryCode"
                                                        sx={{ fontSize: "12px" }}
                                                        limitTags={1}
                                                        options={this.dialCodeList}
                                                        getOptionLabel={(option)=>option}
                                                        onChange={(_,value)=>this.textCountryDialProps.onChange(value)}
                                                        renderInput={(params)=>this.renderInput(params,this.state.countryDialCode,"txtInputCountryCode")}
                                                        getOptionSelected={this.handleOptions}
                                                        disabled={this.state.editFieldA}
                                                        PaperComponent={(props) => {
                                                            return (
                                                                <PaperStyled
                                                                    className="dial-paper"
                                                                    {...props}
                                                                />
                                                            )
                                                        }}
                                                        value={this.state.countryDialCode}
                                                    />
                                                    <Input data-test-id="txtMobileNo" placeholder={"12345 67890"}
                                                        {...this.txtInputadminMobileNo}
                                                        value={this.state.mobileNumberA}
                                                        disableUnderline
                                                        className={`${classes.inputMobile}`}
                                                        disabled={this.state.editFieldA}
                                                        inputProps={{
                                                            maxLength: this.state.countryDialCode=='+91'?10:15,
                                                        }}
                                                    />
                                                </Box>
                                                {this.state.mobileError && (
                                                    <Typography
                                                        style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                                    >
                                                        {" "}
                                                        {this.state.mobileError}{" "}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Country
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" style={{ color: 'red' }}>*</Typography>
                                                </Box>
                                                <FormControl fullWidth>
                                                    <Select
                                                        {...this.textInputadminCountry}
                                                        value={this.state.countryA}
                                                        disableUnderline
                                                        className={this.editNonEditAdminSelectClass()}
                                                        renderValue={this.countryadminPlaceholder()}
                                                        MenuProps={menuProps}
                                                        displayEmpty
                                                        data-test-id="txtCountryName"
                                                        disabled
                                                    >
                                                        {this.countryAdminList.map((data) => (
                                                            <MenuItem key={data} value={data}>{data}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Date of Birth
                                                    </InputLabel>
                                                </Box>
                                                <Input data-test-id="txtDateofBirth" placeholder={"Company Name"} fullWidth={true}
                                                    disableUnderline
                                                    className={`${classes.admininputTxtMain}`}
                                                    {...this.txtInputadminDateOfBirthName}
                                                    value={this.state.birthDateA}
                                                    disabled={this.state.editFieldA}
                                                    type="date"
                                                    inputProps={{
                                                        min: "1900-01-01",
                                                        max: configJSON.maxDate()
                                                    }}
                                                    onFocus={e => e.target.showPicker && e.target.showPicker()}
                                                />
                                                { this.state.birthDateAError && (
                                                    <Typography style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}>
                                                        {" "} {this.state.birthDateAError}{" "}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Gender
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <FormControl>
                                                    <RadioGroup
                                                        id='user-type-radio-group'
                                                        value={this.state.userTypeA}
                                                        {...this.adminselectedType}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                    >
                                                        <Box className={`${classes.radioadmin}`}>
                                                            <FormControlLabel
                                                                value="0"
                                                                control={<CustomRadioSI />}
                                                                label
                                                                disabled={this.state.editFieldA}
                                                            />{" "}
                                                            <Box className={`${classes.adminradioBox}`}>
                                                                {this.slectgendertypeStudent}
                                                            </Box>
                                                        </Box>
                                                        <Box className={`${classes.radioadmin}`}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<CustomRadioSI />}
                                                                label
                                                                disabled={this.state.editFieldA}
                                                            />
                                                            <Box className={`${classes.adminradioBox}`}>
                                                                {" "}
                                                                {this.slectgendertypeInstructor}
                                                            </Box>
                                                        </Box>
                                                        <Box className={`${classes.radioadmin}`}>
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<CustomRadioSI />}
                                                                label
                                                                disabled={this.state.editFieldA}
                                                            />
                                                            <Box className={`${classes.adminradioBox}`}>
                                                                {this.slectgendertypeRecruiter}
                                                            </Box>
                                                        </Box>
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                        </Box>
                                    </Box>

                                    <Box sx={{ margin: '0' }}>
                                       {this.renderAdminAddressDetails()}
                                    </Box>

                                    <Box sx={{ margin: '0' }}>
                                        <Box className={`${classes.addressinfoContainer}`}>
                                            <Typography className={`title ${classes.localgenralTitleTxt}`}>Locale Information</Typography>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Language
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <StyledDropdown
                                                    key={0}
                                                    disableUnderline
                                                    limitTags={1}
                                                    sx={{ fontSize: "12px" }}
                                                    data-test-id="txtlaguage"
                                                    options={this.languageAdminList}
                                                    getOptionLabel={(option)=>option}
                                                    onChange={(_,value)=>this.textInputadminlanguage.onChange(value)}
                                                    renderInput={(params)=>this.renderInput(params,this.state.languageA,"txtlaguage")}
                                                    getOptionSelected={this.handleOptions}
                                                    disabled={this.state.editFieldA}
                                                    PaperComponent={(props) => {
                                                        return (
                                                            <PaperStyled
                                                                {...props}
                                                            />
                                                        )
                                                    }}
                                                    value={this.state.languageA}
                                                />
                                                {this.state.languageError && (
                                                    <Typography
                                                        style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                                    >
                                                        {" "}
                                                        {this.state.languageError}{" "}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Date Format
                                                    </InputLabel>
                                                </Box>
                                                <FormControl fullWidth>
                                                    <Select
                                                        {...this.txtInputadminDateFormate}
                                                        value={this.state.dateFormateA}
                                                        disableUnderline
                                                        className={classes.inputMainCountrySelect}
                                                        renderValue={this.adminDateFormatPlaceholder()}
                                                        MenuProps={menuProps}
                                                        displayEmpty
                                                        data-test-id="txtdateFormate"
                                                        disabled={this.state.editFieldA}
                                                    >
                                                        {this.dateFormats.map((data) => (
                                                            <MenuItem key={data} value={data}>{data}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Time Format
                                                    </InputLabel>
                                                </Box>
                                                <FormControl fullWidth>
                                                    <Select
                                                        {...this.textInputadminTimeFormat}
                                                        value={this.state.timeFormateA}
                                                        disableUnderline
                                                        className={classes.inputMainCountrySelect}
                                                        renderValue={this.admintimeFormatPlaceholder()}
                                                        MenuProps={menuProps}
                                                        displayEmpty
                                                        data-test-id="txtTimeFormate"
                                                        disabled={this.state.editFieldA}
                                                    >
                                                        {this.timeFormats.map((data) => (
                                                            <MenuItem key={data} value={data}>{data}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Time Zone
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <StyledDropdown
                                                    key={0}
                                                    disableUnderline
                                                    limitTags={1}
                                                    sx={{ fontSize: "12px" }}
                                                    data-test-id="txtTimeZone"
                                                    options={this.timeZoneAdminArray}
                                                    getOptionLabel={(option)=>option}
                                                    onChange={(_,value)=>this.textInputadminTimeZone.onChange(value)}
                                                    renderInput={(params)=>this.renderInput(params,this.state.timeZoneA,"txtTimeZone")}
                                                    getOptionSelected={this.handleOptions}
                                                    disabled={this.state.editFieldA}
                                                    PaperComponent={(props) => {
                                                        return (
                                                            <PaperStyled
                                                                {...props}
                                                            />
                                                        )
                                                    }}
                                                    value={this.state.timeZoneA}
                                                />
                                                {this.state.timeZoneAError && (
                                                    <Typography
                                                        style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                                    >
                                                        {" "}
                                                        {this.state.timeZoneAError}{" "}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>

                                       
                                    </Box>
                                    {
                                        !this.state.editFieldA && (
                                            <Box className={`buttons-container ${classes.saveadminChangesbtn}`}>
                                                <Box>
                                                    <Button id='adminsaveClickBTn' className={`save-button ${classes.saveadminbtnText}`} {...this.adminsaveEditField}>Save</Button>
                                                </Box>
                                                <Box>
                                                    <Button className={`save-and-new-button ${classes.saveandnew}`}  {...this.adminsaveEditField}>Save and New</Button>
                                                </Box>
                                                <Box>
                                                    <Button data-test-id='cancleAdminBtn' className={`cancel-button ${classes.canceladminbtnText}`} {...this.admincancleEditField}>Cancel</Button>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Box>
                            )}
                        </Box>
                        <Box >
                            {this.state.valueA == 1 && (
                                <Box>
                                    <Box className={`${classes.mainadminBottomPart}`}>
                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Company Name
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtCompany" placeholder={"Company Name"} fullWidth={true}
                                                    disableUnderline
                                                    className={this.editNonEditDAdminClass()}
                                                    {...this.txtInputcompanyName}
                                                    value={this.state.companyName}
                                                    disabled
                                                />
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Employee Count
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtInputEmploye" placeholder={"Employee Count"} fullWidth={true}
                                                    {...this.txtInputemployCount}
                                                    value={this.state.employee}
                                                    disableUnderline
                                                    className={`${classes.admininputTxtMain}`}
                                                    disabled={this.state.editFieldA}
                                                    inputProps={{
                                                        maxLength: 6,
                                                    }}
                                                />
                                                {this.state.employeeError && (
                                                    <Typography
                                                        style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                                    >
                                                        {" "}
                                                        {this.state.employeeError}{" "}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Phone
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtPhone" placeholder={"Phone"} fullWidth={true}
                                                    disableUnderline
                                                    className={`${classes.admininputTxtMain}`}
                                                    {...this.txtInputPhoneNumber}
                                                    value={this.state.phone}
                                                    disabled={this.state.editFieldA}
                                                    inputProps={{
                                                        maxLength: 15,
                                                    }}
                                                />
                                                {this.state.companyPhoneError && (
                                                    <Typography
                                                        style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                                    >
                                                        {" "}
                                                        {this.state.companyPhoneError}{" "}
                                                    </Typography>
                                                )}
                                            </Box>

                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                            {this.mobileNumberLabel()}
                                                <Box className={`${classes.mobileInputWrapper}`}>
                                                    <StyledDropdown
                                                        key={0}
                                                        disableUnderline
                                                        className={`dial-select`}
                                                        data-test-id="txtInputCompanyCode"
                                                        sx={{ fontSize: "12px" }}
                                                        limitTags={1}
                                                        options={this.dialCodeList}
                                                        getOptionLabel={(option)=>option}
                                                        onChange={(_,value)=>this.textCompanyDialProps.onChange(value)}
                                                        renderInput={(params)=>this.renderInput(params,this.state.companyDialCode,"txtInputCompanyCode")}
                                                        getOptionSelected={this.handleOptions}
                                                        disabled={this.state.editFieldA}
                                                        PaperComponent={(props) => {
                                                            return (
                                                                <PaperStyled
                                                                    className="dial-paper"
                                                                    {...props}
                                                                />
                                                            )
                                                        }}
                                                        value={this.state.companyDialCode}
                                                    />
                                                    <Input
                                                        disableUnderline
                                                        data-test-id="txtmobileNo"
                                                        value={this.state.companymobile}
                                                        className={`${classes.inputMobile}`}
                                                        disabled={this.state.editFieldA}
                                                        inputProps={{
                                                            maxLength: this.state.countryDialCode=='+91'?10:15,
                                                        }}
                                                        {...this.txtInputcompanyMobileNo}
                                                        placeholder={"12345 67890"}
                                                    />
                                                </Box>
                                                {this.state.companyMobileError && (
                                                    <Typography
                                                        style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                                    >
                                                        {" "}
                                                        {this.state.companyMobileError}{" "}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            { this.state.valueA == 1 && 
                                                <Box className={`${classes.adminFiledGroupBox}`}>
                                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                        <InputLabel className={`${classes.emailadminLabelText}`}>
                                                            Website
                                                        </InputLabel>
                                                    </Box>
                                                    <Input data-test-id="txtWebsiteName" placeholder={"Website"} fullWidth={true}
                                                        disableUnderline
                                                        className={`${classes.admininputTxtMain}`}
                                                        {...this.txtInputcompanyWebsiteName}
                                                        value={this.state.companywebsite}
                                                        disabled={this.state.editFieldA}
                                                        inputProps={{
                                                            maxLength: 256,
                                                        }}
                                                    />
                                                </Box>
                                            }
                                            
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Fax
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                                </Box>
                                                <Input data-test-id="txtInputFaxName" placeholder={"Fax"} fullWidth={true}
                                                    {...this.txtInputfaxNo}
                                                    value={this.state.fax}
                                                    disableUnderline
                                                    className={`${classes.admininputTxtMain}`}
                                                    disabled={this.state.editFieldA}
                                                    inputProps={{
                                                        maxLength: 15,
                                                    }}

                                                />
                                                { this.errorTextShow(this.state.companyFaxError )}
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Financial Year
                                                    </InputLabel>
                                                </Box>
                                                <Input data-test-id="txtInputFinancial" placeholder={"Financial Year"} fullWidth={true}
                                                    disableUnderline
                                                    className={`${classes.admininputTxtMain}`}
                                                    {...this.txtInputadminFinacialYear}
                                                    value={this.state.finacialYear}
                                                    disabled={this.state.editFieldA}
                                                />
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Business Hours
                                                    </InputLabel>
                                                </Box>
                                                <Input data-test-id="txtInputBussines" placeholder={"9:30 am to 6:30 pm"} fullWidth={true}
                                                    disableUnderline
                                                    className={`${classes.admininputTxtMain}`}
                                                    {...this.txtInputadminbusinessHr}
                                                    value={this.state.businesshours}
                                                    disabled={this.state.editFieldA}
                                                />
                                            </Box>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminTextAreaBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Description
                                                    </InputLabel>
                                                </Box>
                                                <TextareaAutosize data-test-id="txtInputdescirtion" placeholder={"Add comments here.."} fullWidth={true}
                                                    disableUnderline
                                                    className={`${classes.admininputTxtMainDescription}`}
                                                    {...this.txtInputadminDescription}
                                                    value={this.state.desciptionadmin}
                                                    disabled={this.state.editFieldA}
                                                    type="date"
                                                    minRows={4}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                 {/* Company Profile Area */}
                                    <Box sx={{ margin: '0' }}>
                                        {this.renderCompanyAddressDetails()}
                                    </Box>

                                    <Box sx={{ margin: '0' }}>
                                        <Box className={`${classes.addressinfoContainer}`}>
                                            <Typography className={`title ${classes.genralTitleTxt}`}>Locale Information</Typography>
                                        </Box>

                                        <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Preferred Currency
                                                    </InputLabel>
                                                </Box>
                                                
                                                <Autocomplete
                                                    key={this.state.clearInput}
                                                    disableUnderline
                                                    className={`${classes.countryLoginInputAdminProfile}`}
                                                    data-test-id="txtInputPreCurrency"
                                                    sx={{ fontSize: "12px" }}
                                                    limitTags={1}
                                                    options={this.currencyFormArry}
                                                    getOptionLabel={this.getCurrencyLabelAProfile}
                                                    fullWidth
                                                    onChange={(_,value)=>this.textCurrencyInputAprofile.onChange(value)}
                                                    renderInput={this.renderCurrencyInputAprofile}
                                                    disabled={this.state.editFieldA}
                                                    PaperComponent={(props) => {
                                                        return (
                                                            <StyledPaperAdminProfile
                                                            {...props}
                                                            />
                                                        )
                                                    }}
                                                    value={this.state.companycurrency}
                                                />
                                            </Box>
                                            <Box className={`${classes.adminFiledGroupBox}`}>
                                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                                        Time Zone
                                                    </InputLabel>
                                                    <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>

                                                </Box>
                                                
                                                <StyledDropdown
                                                    key={0}
                                                    disableUnderline
                                                    limitTags={1}
                                                    sx={{ fontSize: "12px" }}
                                                    data-test-id="txtInputTimeZone"
                                                    options={this.timeZoneAdminArray}
                                                    getOptionLabel={(option)=>option}
                                                    onChange={(_,value)=>this.textInputcompanyTimeZone.onChange(value)}
                                                    renderInput={(params)=>this.renderInput(params,this.state.companytimeZone,"txtInputTimeZone")}
                                                    getOptionSelected={this.handleOptions}
                                                    disabled={this.state.editFieldA}
                                                    PaperComponent={(props) => {
                                                        return (
                                                            <PaperStyled
                                                                {...props}
                                                            />
                                                        )
                                                    }}
                                                    value={this.state.companytimeZone}
                                                />
                                                {this.errorTextShow(this.state.companyTimeZoneError)}
                                            </Box>
                                        </Box>
                                    </Box>
                                    {
                                        !this.state.editFieldA && (
                                            <Box className={`buttons-container ${classes.saveadminChangesbtn}`}>
                                                <Box>
                                                    <Button data-test-id='companysaveClickBTn' className={`save-button ${classes.saveadminbtnText}`} {...this.adminsaveEditField}>Save Changes</Button>
                                                </Box>
                                                <Box>
                                                    <Button data-test-id='companycancleClickBTn' className={`cancel-button ${classes.canceladminbtnText}`} {...this.admincancleEditField}>Cancel</Button>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Box>
                            )}
                        </Box>

                        <Modal
                            open={this.state.openA}
                            {...this.handleAdminClose}
                            data-test-id='successclosemodal'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className={`${classes.modaladminContainer}`}>
                                <Box className={`${classes.closeadminimg}`} data-test-is='successcloseMOdal' {...this.adminhandleCloseClick}>
                                    <img src={closeImg} alt="" style={{ width:"24px" }} />
                                </Box>
                                <Box className={`${classes.successImgStyleadmin}`}>
                                    <img src={SuccessImg} alt="" style={{ width:"48px" }} />
                                </Box>
                                <Box className={`${classes.centeradminTxt}`}>
                                    <Typography className={`${classes.successTxtadmin}`}>Successfully updated</Typography>
                                    <Typography className={`${classes.descriptionTxtadmin}`}>Your changes has been successfully saved!</Typography>
                                </Box>
                                <Box className={`${classes.countinueBtnContainer}`}>
                                    <Button className={`${classes.continueBtnadmin}`} data-test-id='countinueCilckBtn' {...this.admincountinueClick}>Continue</Button>
                                </Box>
                            </Box>
                        </Modal>
                    </Box>
                </Box>
                </Layout>
            </StyledContainer>
            // Customizable Area End
        )
    }
    renderAdminAddressDetails= ()=> {
        const {classes}=this.props
        return ( 
            <>
            <Box className={`${classes.addressCompanyContainer}`}>
                <Typography className={`title ${classes.genralAddressTitleTxt}`}>Address Information</Typography>
            </Box>
            <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                <Box className={`${classes.adminFiledGroupBox}`}>
                    {this.renderLabelName("Country")}
                    <StyledDropdown
                        key={0}
                        disableUnderline
                        data-test-id="txtCountryName2"
                        sx={{ fontSize: "12px" }}
                        limitTags={1}
                        options={this.state.countryArrayCommon}
                        getOptionLabel={(option) => option}
                        onChange={(_, value) => this.textInputadminCountry.onChange(value)}
                        renderInput={(params) => this.renderInput(params, this.state.countryA, "txtCountryName2")}
                        getOptionSelected={this.handleOptions}
                        disabled={this.state.editFieldA}
                        PaperComponent={(props) => {
                            return (
                                <PaperStyled
                                    {...props} />
                            );
                        } }
                        value={this.state.countryA} />
                    {this.state.countryNameAError && (
                        <Typography
                            style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                        >
                            {" "}
                            {this.state.countryNameAError}{" "}
                        </Typography>
                    )}
                </Box>
                <Box className={`${classes.adminFiledGroupBox}`}>
                    {this.renderLabelName("State")}
                    <StyledDropdown
                        key={0}
                        disableUnderline
                        limitTags={1}
                        sx={{ fontSize: "12px" }}
                        data-test-id="txtstateName"
                        options={this.state.stateArrayCommon}
                        getOptionLabel={(option) => option}
                        onChange={(_, value) => this.textStateadminProps.onChange(value)}
                        renderInput={(params) => this.renderInput(params, this.state.stateNameA, "txtstateName")}
                        getOptionSelected={this.handleOptions}
                        disabled={this.state.editFieldA}
                        PaperComponent={(props) => {
                            return (
                                <PaperStyled
                                    {...props} />
                            );
                        } }
                        value={this.state.stateNameA} />
                    {this.state.stateNameAError && (
                        <Typography
                            style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                        >
                            {" "}
                            {this.state.stateNameAError}{" "}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                <Box className={`${classes.adminFiledGroupBox}`}>
                    {this.renderLabelName("City")}
                    <StyledDropdown
                        key={0}
                        disableUnderline
                        data-test-id="txtCityName"
                        sx={{ fontSize: "12px" }}
                        limitTags={1}
                        options={this.state.cityArrayCommon}
                        getOptionLabel={(option) => option}
                        onChange={(_, value) => this.textInputadminCity.onChange(value)}
                        renderInput={(params) => this.renderInput(params, this.state.cityNameA, "txtCityName")}
                        getOptionSelected={this.handleOptions}
                        disabled={this.state.editFieldA}
                        PaperComponent={(props) => {
                            return (
                                <PaperStyled
                                    {...props} />
                            );
                        } }
                        value={this.state.cityNameA} />
                    {this.state.cityNameAError && (
                        <Typography
                            style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                        >
                            {" "}
                            {this.state.cityNameAError}{" "}
                        </Typography>
                    )}
                </Box>
                <Box className={`${classes.adminFiledGroupBox}`}>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <InputLabel className={`${classes.emailadminLabelText}`}>
                            Street
                        </InputLabel>
                    </Box>
                    <Input data-test-id="txtstreetName" placeholder={"Street"} fullWidth={true}
                        disableUnderline
                        className={this.props.classes.admininputTxtMain}
                        {...this.txtInputadminStreetName}
                        value={this.state.streetA}
                        disabled={this.state.editFieldA}
                        inputProps={{
                            maxLength: 256,
                        }} />
                </Box>
            </Box>
            <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                <Box className={`${classes.adminFiledGroupBox}`}>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        <InputLabel className={`${classes.zipadminLabelText}`}>
                            Zip Code
                        </InputLabel>
                    </Box>
                    <Input data-test-id="txtzipcode" placeholder={"Zip Code"} fullWidth={true}
                        disableUnderline
                        className={this.props.classes.admininputTxtMain}
                        {...this.txtInputadminZipCode}
                        value={this.state.zipCodeA}
                        disabled={this.state.editFieldA} />
                </Box>
                <Box className={classes.emptyHalf} />
            </Box>
        </>
        )
    }

    renderCompanyAddressDetails = () => {
        const { classes } =this.props
        return (
            <>
                <Box className={`${classes.addressinfoContainer}`}>
                    <Typography className={`title ${classes.genralTitleTxt}`}>Address Information</Typography>
                </Box>
                <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                    <Box className={`${classes.adminFiledGroupBox}`}>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <InputLabel className={`${classes.emailadminLabelText}`}>
                                Country
                            </InputLabel>
                            <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                        </Box>
                        <StyledDropdown
                            key={0}
                            disableUnderline
                            data-test-id="txtInputCompanyCountryName"
                            sx={{ fontSize: "12px" }}
                            limitTags={1}
                            options={this.state.countryArrayCommon}
                            getOptionLabel={(option)=>option}
                            onChange={(_,value)=>this.textInputCompanyCountry.onChange(value)}
                            renderInput={(params)=>this.renderInput(params,this.state.companyCountry,"txtInputCompanyCountryName")}
                            getOptionSelected={this.handleOptions}
                            disabled={this.state.editFieldA}
                            PaperComponent={(props) => {
                                return (
                                    <PaperStyled
                                        {...props}
                                    />
                                )
                            }}
                            value={this.state.companyCountry}
                        />
                        {this.errorTextShow(this.state.companyCountryError)}
                    </Box>
                    <Box className={`${classes.adminFiledGroupBox}`}>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <InputLabel className={`${classes.emailadminLabelText}`}>
                                State
                            </InputLabel>
                            <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                        </Box>
                        <StyledDropdown
                            key={0}
                            disableUnderline
                            limitTags={1}
                            sx={{ fontSize: "12px" }}
                            data-test-id="txtInputCompanySateName"
                            options={this.state.companyStateArrayCommon}
                            getOptionLabel={(option)=>option}
                            onChange={(_,value)=>this.textStateCompanyProps.onChange(value)}
                            renderInput={(params)=>this.renderInput(params,this.state.companystateName,"txtInputCompanySateName")}
                            getOptionSelected={this.handleOptions}
                            disabled={this.state.editFieldA}
                            PaperComponent={(props) => {
                                return (
                                    <PaperStyled
                                        {...props}
                                    />
                                )
                            }}
                            value={this.state.companystateName}
                        />
                            {this.errorTextShow(this.state.companyStateError)}
                    </Box>
                </Box>

                <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                    <Box className={`${classes.adminFiledGroupBox}`}>
                        {this.renderLabelName("City")}   
                        <StyledDropdown
                            key={0}
                            disableUnderline
                            data-test-id="txtInputCompanyCityName"
                            sx={{ fontSize: "12px" }}
                            limitTags={1}
                            options={this.state.companyCityArrayCommon}
                            getOptionLabel={(option)=>option}
                            onChange={(_,value)=>this.textInputCompanyCity.onChange(value)}
                            renderInput={(params)=>this.renderInput(params,this.state.companycityName,"txtInputCompanyCityName")}
                            getOptionSelected={this.handleOptions}
                            disabled={this.state.editFieldA}
                            PaperComponent={(props) => {
                                return (
                                    <PaperStyled
                                        {...props}
                                    />
                                )
                            }}
                            value={this.state.companycityName}
                        />
                            {this.errorTextShow(this.state.companyCityError)}
                        
                    </Box>
                    <Box className={`${classes.adminFiledGroupBox}`}>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <InputLabel className={`${classes.emailadminLabelText}`}>
                                Street
                            </InputLabel>
                        </Box>
                        <Input data-test-id="txtInputStreetName" placeholder={"Street"} fullWidth={true}
                            disableUnderline
                            className={`${classes.admininputTxtMain}`}
                            {...this.txtInputcompanyStreetName}
                            value={this.state.companystreet}
                            disabled={this.state.editFieldA}
                        />
                    </Box>
                    
                </Box>

                <Box className={`profile-field-main-box ${classes.adminFieldMainBox}`}>
                    <Box className={`${classes.adminFiledGroupBox}`}>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <InputLabel className={`${classes.emailadminLabelText}`}>
                                Zip Code
                            </InputLabel>
                        </Box>
                        <Input data-test-id="txtInputZipCode" placeholder={"Zip Code"} fullWidth={true}
                            disableUnderline
                            className={`${classes.admininputTxtMain}`}
                            {...this.txtInputcompanyZipCode}
                            value={this.state.companyzipCode}
                            disabled={this.state.editFieldA}
                        />
                    </Box>
                    <Box className={classes.emptyHalf}/>
                </Box>
            </>
        )
    }
}


// Customizable Area Start
    
const StyledPaperAdminProfile = styled(Paper)({
    "&": {
        padding:"10px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& ul":{
        padding:"10px 5px"
    },
    "& ul li":{
        borderRadius:"8px",
        fontSize: "14px",
        padding:"9px 20px"
    },
    "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
        backgroundColor:"rgba(241, 245, 249, 1)"
    },
});
export const AdminProfileStyles = (theme: any) => ({
    countryLoginInputAdminProfile: {
        width: '100% !important',
        boxSizing:"border-box",
        border: "1px solid #dfdfdf",
        padding: "5px 7px 5px 10px",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
            paddingRight: "25px",
            fontSize: "16px",
            borderBottom: "none",
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInput-underline::before': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline::after': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: "0px",
        },
        '&. MuiInputBase-fullWidth': {
            width: '95%'
        }
    },
    root:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"5px"
        },
        "& ul li":{
            borderRadius:"8px",
            fontSize: "14px",
            padding:"9px 20px"
        },
        "& ul li:hover, & MuiSelect-select:focus, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)!important"
        },
    },
    BoxadminContainer: {
        display: 'flex',
        gap: '48px',
        background: '#E5E5E5',
        [theme.breakpoints.down("md")]: {
          width: "109%"
        },
        overflowY: "visible"
    },
    homeadmincrumb: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#475569',
        cursor: "pointer",
        '&.MuiLink-underlineHover:hover': {
            textDecoration: 'none',
        }
    },
    profileadminCrumb: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#475569',
        '&.MuiLink-underlineHover:hover': {
            textDecoration: 'none',
        }
    },
    adminOBreadcrumb: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '32px',
        color: '#0F172A',
    },
    centeradminBgColor: {
        background: '#FFFFFF',
        borderRadius: '8px',
        marginBottom: '34px',
        width:"auto",
        [theme.breakpoints.down("md")]: {
            // maxWidth: "91%",
            width: "auto"        },
    },
    adminleftSideContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '34px',
        minWidth:'400px'
    },
    adminbtnEdit: {
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        backgroundColor: 'transparent',
        borderRadius: '8px',
        width: '81px',
        height: '32px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
        },
        '& p': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            color: '#64748B',
            textTransform: 'capitalize',
        },
        [theme.breakpoints.down("md")]: {
            width: "105px"
          },
    },
    genralTitleTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    countryCodeMainBox: {
        width: "auto"
    },
    companyCountryCodeMainBox: {
        width: "auto" 
    },
    localgenralTitleTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    genralAddressTitleTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    infotitleContainer: {
        marginBlock: '32px',
        marginInline: '0',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("md")]: {
            marginBlock: '15px',
          },
    },
    addressinfoContainer: {
        display: 'flex',
        marginRight: '32px',
        marginBottom: '32px',
        justifyContent: 'space-between',
        [theme.breakpoints.down("md")]: {
            marginBottom: '15px',
          },
    },

    addressCompanyContainer: {
        display: 'flex',
        marginRight: '32px',
        marginBottom: '32px',
        justifyContent: 'space-between',
        [theme.breakpoints.down("md")]: {
            marginBottom: '15px',
        },
    },
    adminFieldMainBox: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
    },
    adminFiledGroupBox: {
        paddingBottom: "15px",
        width: "50%",
    },
    adminTextAreaBox: {
        paddingBottom: "15px",
        width: "100%",
    },
    emailadminLabelText: {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    zipadminLabelText: {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    streetadminLabelText: {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    admininputTxtMain: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            maxWidth: "100%",
        },
    },
    admininputdark:{
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        background: '#F1F5F9',
        color:"rgba(100, 116, 139, 1)"
    },
    admininputTxtMainDescription: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        maxWidth: "1160px",
        maxHeight: "118px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
    },
    mainadminBottomPart: {
        margin: '36px 0 0 0',
        [theme.breakpoints.down("md")]: {
            margin: "0"
          },
    },
    requiredRedColor: {
        color: 'red',
    },
    countryplaceholder: {
        color: "#aaa",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        fontFamily: "'Inter', sans-serif",
    },
    inputMainCountrySelect: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "Inter, sans-serif",
        "& .MuiSelect-select:focus":{
            backgroundColor:"transparent"
        }
    },
    inputadmindarkCountrySelect: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "Inter, sans-serif",
        background: '#F1F5F9',
    },
    adminradioBox: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginLeft: "-16px",
        lineHeight: "16px",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        color: '#64748B',
        width: '80px',
    },
    radioadmin: {
        display: 'flex',
        gap: '6px',
    },
    saveadminbtnText: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: "700",
        textTransform: "capitalize",
        borderRadius: "10px",
        width: '144px',
        height: '44px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
    },
    saveadminChangesbtn: {
        margin: '32px',
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down("md")]: {
            alignItems: "center"         },
    },
    canceladminbtnText: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        height: '44px',
        width: "87px",
        fontSize: "16px",
        fontWeight: "700",
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '7px 12px',
        color: "#000000",
        textTransform: "capitalize",
        fontFamily: "Inter, sans-serif",
    },
    modaladminContainer: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '409px',
        height: '280px',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        padding: '16px 17px'
    },
    centeradminTxt: {
        display: 'flex',
        justifyCOntent: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
    },
    successTxtadmin: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
        color: '#0A0528',
    },
    descriptionTxtadmin: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        color: '#64748B',
        marginTop: '12px',
    },
    closeadminimg: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
    },
    successImgStyleadmin: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',
    },
    countinueBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
    },
    continueBtnadmin: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '104px',
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },

    mobileInputWrapper: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #dfdfdf',
        borderRadius: '10px',
        height: '42px'
    },
    inputMainMobileSelect: {
        borderRadius: "10px",
        fontFamily: "'Inter', sans-serif",
        height: '45px',
        paddingBlock: '10px',
        width: 'fit-content',
        paddingLeft: '10px'
    },
    inputMobile: {
        borderRadius: "10px",
        height: "44px",
        width: "85%",
        fontFamily: "'Inter', sans-serif",
        padding: '5px'
    },
    organizationAdmin:{
        height: "44px",
        width: "224px",
        borderRadius:"8px 8px 0px 0px",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
            // maxWidth: "80%",
            width: "auto"
          },
    },
    organizationCompany:{
        height: "44px",
        width: "224px",  
        borderRadius:"8px 8px 0px 0px",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
            // maxWidth: "80%",
            width: "auto"
          },
                           
    },
    saveandnew: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '10px',
        color: "#000000",
        fontWeight: "700",
        textTransform: "capitalize",
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        height:"44px",
        width:"142px"
    },
    emptyHalf:{
        width:"50%"
    },
    searchInputIunder: {
        padding: "0 10px",
        '& .MuiFilledInput-underline:after': {
            borderBottom: '0px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderBottom: '0px',
        },
    },
})

const PaperStyled = styled(Paper)({
    "&": {
        padding:"10px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& ul":{
        padding:"10px 5px"
    },
    "& ul li":{
        borderRadius:"8px",
        fontSize: "14px",
        padding:"9px 20px"
    },
    "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
        backgroundColor:"rgba(241, 245, 249, 1)"
    },
});

export const StyledDropdown = styled(Autocomplete)({
    "&":{
        width: '100% !important',
        boxSizing:"border-box",
        border: "1px solid #dfdfdf",
        padding: "5px 7px 5px 0px",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
    },
    "&.dial-select":{
        width:"150px!important",
        borderRight:0,
        borderTopRightRadius:0,
        borderBottomRightRadius:0
    },
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        paddingRight: "25px",
        fontSize: "16px",
        borderBottom: "none"
    },
    "& .MuiAutocomplete-endAdornment":{
        right:"6px"
    },
    "&>div":{
        paddingLeft:"10px"
    },
    "&.MuiAutocomplete-hasPopupIcon>div.MuiAutocomplete-hasClearIcon":{
        paddingLeft:"0px"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInput-underline::before': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline::after': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: "0px",
    },
    '&. MuiInputBase-fullWidth': {
        width: '95%'
    }
})

//@ts-ignore
export default withStyles(AdminProfileStyles)(AdminProfile);

  // Customizable Area End