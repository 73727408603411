import { Box, Typography, Tooltip, withStyles, styled } from "@material-ui/core";
import React, { Component, ReactNode } from "react";

type IProps = {
    title: string | undefined;
    estimatedPrice: string;
    oneTimeImplementationCost?: string;
};

type IState = {};

class EstimatedPriceBox extends Component<IProps, IState>{

    render(): ReactNode {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const { estimatedPrice, oneTimeImplementationCost } = this.props;
        return (
            <Box sx={webStyles.estimatedWrapper} >
                <StyledBox>
                    <StyledTypographyEstimatedText>
                        Estimate Cost
                    </StyledTypographyEstimatedText>
                    <StyledTypographyEstimatedPrice>
                        {currencyFormatter.format(Number(estimatedPrice))}
                    </StyledTypographyEstimatedPrice>
                    <StyledTooltip title={<Box sx={webStyles.tooltipRow}><Box sx={webStyles.tooltipEntrance}>Disclaimer-</Box><Box sx={webStyles.tooltipContent}>Prices are indicative</Box></Box>} aria-label="add">
                        <Box sx={webStyles.informationBox}>i</Box>
                    </StyledTooltip>
                </StyledBox>
                <Box sx={webStyles.estimatedCostNoteContainer}>
                     { oneTimeImplementationCost && (
                        <>
                            <StyledTypographyLabel style={webStyles.marginOffset}>Note-</StyledTypographyLabel>
                            <StyledEstimatedCost> This cost is excluding a one time implementation cost i.e ${oneTimeImplementationCost} USD</StyledEstimatedCost>
                        </>
                     )}
                </Box>
            </Box>
        )
    }
}

const webStyles = {
    estimatedWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    estimatedContainer: {
        border: "1px solid transparent",
        background: "linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box",
        backgroundColor: "transparent",
        borderRadius: "8px",
        width: "570px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        marginBottom: "10px",
        boxSizing: "border-box"
    },
    informationBox: {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#64748B",
        border: "2px solid #64748B",
        fontSize: "6px",
        fontWeight: 700,
        fontFamily: "'Inter', sans-serif",
    },
    marginOffset: {
        margin: 0
    },
    tooltipRow: {
        display: "flex",
        alignItems: "center"
    },
    tooltipEntrance: {
        color: "#F59E0B",
        fontFamily: "'Inter', sans-serif",
        fontWeight: 500
    },
    tooltipContent: {
        fontFamily: "'Inter', sans-serif",
        fontWeight: 500,
        marginLeft: "10px",
        color: "#0A0528",
    },
    estimatedCostNoteContainer: {
        display: "flex"
    },
    estimatedCostNote: {
        color: "#64748B",
        fontSize: "14px"
    },
    inputStyle: {
        fontSize: "20px!important",
        fontWeight: 700,
        fontFamily: "'Inter', sans-serif!important",
        lineHeight: "28px!important",
        color: "#334155!important",
        outline: "none!important",
        border: 0
    }
}

const StyledTypographyEstimatedText = styled(Typography)({
    "&": {
        fontSize: "18px",
        lineHeight: "26px",
        color: "#334155"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledTypographyEstimatedPrice = styled(Typography)({
    "&": {
        fontSize: "20px!important",
        fontWeight: 700,
        fontFamily: "'Inter', sans-serif!important",
        lineHeight: "28px!important",
        color: "#334155!important",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "11px"
        }
    }
});

const StyledTypographyLabel = styled(Typography)({
    "&": {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledEstimatedCost = styled(Typography)({
    "&": {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "22px",
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const StyledBox = styled(Box)({
    "&": {
        border: "1px solid transparent",
        background: "linear-gradient(90deg, white, white) padding-box, linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%) border-box",
        backgroundColor: "transparent",
        borderRadius: "8px",
        width: "570px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        marginBottom: "10px",
        boxSizing: "border-box"
    },
    "@media(max-width: 960px)": {
        "&": {
            width: "100%",
            height: "100%",
            padding: "5px",
            display: "flex",
            flexWrap: "wrap"
        }
    }
});

const StyledTooltip = withStyles(({
    tooltip: {
        backgroundColor: "transparent",
        marginTop: "0px!important",
        bottom: "50px!important"
    },
}))(Tooltip);

export default EstimatedPriceBox;
