// Customizable Area Start
// @ts-nocheck
import React from 'react';
import { withStyles, Modal, Grid, Box, Button, Input, FormControl, Select, MenuItem, InputLabel, TextareaAutosize, Typography } from "@material-ui/core";
import DocumentstorageController, { Props } from './DocumentstorageController';
import { documentImage, uploadFile, SuccessImg, closeImg, attachmentImage } from './assets';

// Customizable Area End

// Customizable Area Start
// Customizable Area End

export class MarketingRequestForm extends DocumentstorageController {
    constructor(props: Props) {
        super(props);
    }

    errorContent = (value: any) => {
        return <Typography className={`${this.props.classes.errortxt}`}>
                {`${value}`}
                </Typography>
    }

    descriptionBlock = () =>{
        return <Box style={{ marginBottom: '20px', width:'98.97%' }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <InputLabel className={`${this.props.classes.emailadminLabelText}`}>
                Delivery Date
            </InputLabel>
            <Typography component="div" variant="subtitle1" style={{ color: 'red' }}>*</Typography>
        </Box>
        <Input data-test-id="txtInputdeliveryDate" placeholder={"Company Name"} fullWidth={true}
            disableUnderline
            className={`${this.props.classes.inputMain}`}
            {...this.handelDeliverydate}
            value={this.state.selectedDeliverydate}
            type="date"
            inputProps={{
                min: "1900-01-01",
                max: "3000-12-31",
            }}
            onFocus={e=>e.target.showPicker && e.target.showPicker()}
        />
        {this.state.errors.deliveryDate && this.errorContent('Please enter delivery date')}
    </Box>
    }

    render() {
        // Customizable Area Start 
        const { classes } = this.props
        // Customizable Area End

        return (
            // Customizable Area Start 
            <Box style={{padding:'30px'}}>
                <Typography className={`${classes.heading}`} style={{paddingBottom:'20px'}}>Marketing request</Typography>
                <Grid container className={classes.mainmarketRequest}>
                    <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }} style={{ height: '100%'}}>
                        <div
                            onDragLeave={this.handleDragLeave}
                            onDragOver={this.handleDragOver}
                            onDrop={this.handleDrop}
                        >
                            <div className={classes.uploadElContainer}>
                                <label
                                    className={`${classes.dragAndDrop}`}
                                    htmlFor="uploadInput"
                                >
                                    <div className={classes.dragAndGropText}>
                                        <img src={uploadFile} alt="image icon" />

                                        <div className={classes.dragAndDropTitle}>
                                            <div><span className={classes.uploadButton}>Banner Image click to upload </span> or drag & drop PNG and JPG (max. 5MB) Ideal resolution: 1920x1080 px</div>
                                        </div>
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="uploadInput"
                                    hidden
                                    accept=".png,.jpg"
                                    onChange={this.handleFileChange}
                                    onClick={(event)=> { 
                                        event.target.value = null
                                    }}
                                />
                            </div>
                            {this.state.errors.banner && this.errorContent('Please upload banner')}
                            {this.state.fileSizeLimit.length>0 && this.errorContent(this.state.fileSizeLimit)}
                        </div>
                        {this.state.setSelectedFile && (
                                <div className={classes.previewFileMainBox}>
                                    <div style={{ display: 'flex', gap: '15px', alignItems: 'center', width:'85%'}}>
                                        <div>
                                            <img src={documentImage} alt="" />
                                        </div>
                                        <div className={classes.filePreviewDesign}>
                                            <div className={classes.filePreviewName}>{this.state.setSelectedFile.name}</div>
                                            <div className={classes.filePreviewSize}>{(this.state.setSelectedFile.size / 1000).toFixed(1)} KB</div>
                                        </div>
                                    </div>
                                    <div>
                                        <Button data-test-id='removeBanner' {...this.removeBanner} style={{minWidth: '10px'}}><img src={closeImg} alt="close" /></Button>
                                    </div>
                                </div>
                            )}
                        <div
                            onDragLeave={this.handleDragAttachLeave}
                            onDragOver={this.handleAttachDragOver}
                            onDrop={this.handleAttachDrop}
                        >
                            <div className={classes.uploadElContainer}>
                                <label
                                    className={`${classes.dragAndDrop}`}
                                    htmlFor="uploadInput1"
                                >
                                    <div className={classes.dragAndGropText}>
                                        <img src={uploadFile} alt="image icon" />

                                        <div className={classes.dragAndDropTitle}>
                                            <div><span className={classes.uploadButton}>Attachment click to upload </span> or drag and drop PDF, PNG, JPG, DOC or PPT (max. 5MB) </div>
                                        </div>
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="uploadInput1"
                                    hidden
                                    accept=".pdf,.png,.jpg,.doc,.ppt"
                                    onChange={this.handleAttachChange}
                                    onClick={(event)=> { 
                                        event.target.value = null
                                    }}
                                />
                            </div>
                            {this.state.errors.attachment && this.errorContent('Please upload attachment')}
                            {this.state.attachedFileSizeLimit.length>0 && this.errorContent(this.state.attachedFileSizeLimit)}
                        </div>
                        {this.state.setAttachedFile && (
                                <div className={classes.previewFileMainBox}>
                                    <div style={{ display: 'flex',gap: '15px', alignItems: 'center', width:'85%'}}>
                                        <div>
                                            <img src={attachmentImage} alt="" />
                                        </div>
                                        <div className={classes.filePreviewDesign}>
                                            <div className={classes.filePreviewName}>{this.state.setAttachedFile.name}</div>
                                            <div className={classes.filePreviewSize}>{(this.state.setAttachedFile.size / 1000).toFixed(1)} KB</div>
                                        </div>
                                    </div>
                                    <div>
                                        <Button data-test-id='removeAttachment' {...this.removeAttachment} style={{minWidth: '10px'}}><img src={closeImg} alt="close" /></Button>
                                    </div>
                                </div>
                            )
                        }

                    </Grid>
                        
                    <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                    <Box style={{ marginBottom: '20px', width:'98.97%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <InputLabel className={`${classes.emailadminLabelText}`}>
                                    Title
                                </InputLabel>
                                <Typography component="div" variant="subtitle1" style={{ color: 'red' }}>*</Typography>
                            </Box>
                            <Input data-test-id="txtInputTitle" placeholder={"Enter title"} fullWidth={true}
                                disableUnderline
                                className={`${classes.inputMain}`}
                                {...this.handleTitle}
                                value={this.state.title}
                                type="text"
                            />
                            {this.state.errors.title && this.errorContent('Please enter title')}
                        </Box>

                        <Box style={{ marginBottom: '20px', width:'98.97.%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <InputLabel className={`${classes.emailadminLabelText}`}>
                                    Category
                                </InputLabel>
                                <Typography component="div" variant="subtitle1" style={{ color: 'red' }}>*</Typography>
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    onChange={this.textInputCategory}
                                    defaultValue={this.state.category}
                                    value={this.state.category}
                                    disableUnderline
                                    className={`${classes.inputMain}`}
                                    data-test-id="categoryInput"
                                    MenuProps={{
                                        classes:{paper:classes.root},
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }
                                      }}
                                >
                                    {this.state.categoryList.map((data) => (
                                        <MenuItem key={data.id} 
                                        value={data}
                                        >{data.attributes.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {this.state.errors.category && this.errorContent('Please select category')}
                        </Box>

                        <Box style={{ marginBottom: '20px', width:'98.97%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <InputLabel className={`${classes.emailadminLabelText}`}>
                                    Sub-Category
                                </InputLabel>
                                <Typography component="div" variant="subtitle1" style={{ color: 'red' }}>*</Typography>
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    // {...this.textInputSubcategory}
                                    onChange={this.textInputSubcategory}
                                    value={this.state.subCategory}
                                    disableUnderline
                                    className={`${classes.inputMain}`}
                                    data-test-id="subCategoryInput"
                                    MenuProps={{
                                        classes:{paper:classes.root},
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }
                                      }}
                                >
                                    {this.state.subCategoryList?.length > 0 ? this.state.subCategoryList.map((data) => (
                                        <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                                    )): 
                                    <MenuItem key={1} value={null}>none</MenuItem>}

                                </Select>
                            </FormControl>
                            {this.state.errors.subCategory && this.errorContent('Please select sub-category')}
                        </Box>

                        <Box style={{ marginBottom: '20px', width:'98.97%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <InputLabel className={`${classes.emailadminLabelText}`}>
                                    Request Date
                                </InputLabel>
                                <Typography component="div" variant="subtitle1" style={{ color: 'red' }}>*</Typography>
                            </Box>
                            <Input data-test-id="txtInputrequestDate" placeholder={"Company Name"} fullWidth={true}
                                disableUnderline
                                className={`${classes.inputMain}`}
                                {...this.handelRequestedDate}
                                value={this.state.selectedRequestDate}
                                type="date"
                                format="ddd, DD MMM YYYY"
                                inputProps={{
                                    min: "1900-01-01",
                                    max: "3000-12-31",
                                }}
                                onFocus={e => e.target.showPicker && e.target.showPicker()}
                            />
                            {this.state.errors.requestDate && this.errorContent('Please enter request date')}
                        </Box>

                        {this.descriptionBlock()}

                        <Box style={{ marginBottom: '20px', width:'98.97%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <InputLabel className={`${classes.emailadminLabelText}`}>
                                    Description
                                </InputLabel>
                                <Typography component="div" variant="subtitle1" style={{ color: 'red' }}>*</Typography>
                            </Box>
                            <TextareaAutosize
                                placeholder='This can be a multiline placeholder'
                                minRows={5}
                                data-test-id="txtInputdescription"
                                className={`${classes.TextAreainputMain}`}
                                {...this.handelDescription}
                                value={this.state.descriptionText}
                                // maxLength={100}
                            />
                            <Box className={`${classes.descriptionError}`} style={this.state.errors.description ? {justifyContent: "space-between"}:{ justifyContent: "flex-end"}}>
                            {this.state.errors.description && this.errorContent('Please enter description')}
                            {/* <Typography className={`${classes.descriptionLimit}`}>(min 100 Words)</Typography> */}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box className={`${classes.saveCanclebtn}`}>
                    <Box>
                        <Button data-test-id='saveClickBTn' onClick={this.handleSubmit} className={`${classes.savebuttonText}`}>Submit</Button>
                    </Box>
                    <Box>
                        <Button data-test-id='cancleCilckBtn' onClick={this.handleCancel} className={`${classes.cancelbuttonText}`}>Cancel</Button>
                    </Box>
                </Box>
                <Modal
                            open={this.state.openPopup}
                            {...this.handleClosePopupClick}
                            data-test-id='successclosemodal'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className={`${classes.marketModalContainer}`}>
                                <Box className={`${classes.closeimg}`} data-test-is='successcloseMOdal' {...this.adminhandleCloseClick}>
                                    <img src={closeImg} alt="" style={{ width:"24px" }} />
                                </Box>
                                <Box className={`${classes.successImgStyle}`}>
                                    <img src={SuccessImg} alt="" style={{ width:"48px" }} />
                                </Box>
                                <Box className={`${classes.marketCenterTxt}`}>
                                    <Typography className={`${classes.marketSuccessTxt}`}>Successfully created</Typography>
                                    <Typography className={`${classes.marketDescriptionModalTxt}`}>{this.state.messageText}</Typography>
                                </Box>
                                <Box className={`${classes.countinueBtnContainer}`}>
                                    <Button className={`${classes.marketContinueBtn}`} data-test-id='countinueCilckBtn' {...this.handleClosePopup}>Continue</Button>
                                </Box>
                            </Box>
                        </Modal>
            </Box>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export const MarketingRequestStyle = (theme: any) => ({
    
    uploadElContainer: {
        display: "flex",
        alignItems: "flex-start",
        gap: '20px',
        position: "relative",
        height: "91%",
        width:"99.29%",
        marginBottom:'15px',
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        },
    },
    saveCanclebtn: {
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down('sm')]:{
            justifyContent:'center'
        },
    },
    dragAndDrop: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: '20px',
        border: `1px solid #E2E8F0`,
        borderRadius: '8px',
        cursor: "pointer",
        transition: "all 0.2s",
        width: "95.943%",
        height: '332px',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#f6f0ff',
            border: `1px solid #c9a2ff`,
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%'
         },
       }
})

//@ts-ignore
export default withStyles(MarketingRequestStyle)(MarketingRequestForm);
// Customizable Area End