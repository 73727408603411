import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import createRequestMessage from "../../../framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { IAccordionItem } from "./types.web";


export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  token: string;
  termsAndConditionsList: IAccordionItem[],
  headerContent: IAccordionItem[]
}
interface SS {
  id: any;
}

export default class TermsAndConditionsController extends BlockComponent<Props, S, SS> {

  getTermsAndConditionsApiCallId: string | null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
  ];
  
    this.state = {
      token: "",
      termsAndConditionsList: [],
      headerContent:[]
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.getTermsAndConditionsApiCallId = "";
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage),
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage),
        );
        const errorJson = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage),
        );
        if (apiRequestCallId) {
            if (this.getTermsAndConditionsApiCallId) {
                this.getTermsAndConditionsApiCallId = null;
                handleResponseMessage({
                    responseJson,
                    errorJson,
                    onSuccess: () => {
                        this.setState({ termsAndConditionsList:responseJson.data.list, headerContent:responseJson.data.header })
                        console.log(responseJson.data.header)
                    },
                    onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                });
          }
        }
    }
  }

  toggleAccordionStatus = (expanded: boolean, title:string) => {
    this.setState({termsAndConditionsList: this.state.termsAndConditionsList.map(item => {
      return  item.title === title ? {...item,isExpanded: expanded ? title : false} : {...item}
    })})
  };

  getTermsAndConditionsList = () => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getTermsAndConditionsApiCallId = requestMessage.messageId;

    createRequestMessage({
        header,
        requestMessage: requestMessage,
        endPoint: `${configJSON.termsAndConditionsListEndPoint}`,
        method: configJSON.getApiMethod,
    });
  }

  async componentDidMount() {
    let token = await sessionStorage.getItem("token") || "";
    this.setState({
        token: JSON.parse(token).token
    });
    this.getTermsAndConditionsList();
  }
}
