import React, { ReactNode } from 'react';
import Layout from '../../../components/src/Layout.web';
import { MyRequestBreadCrumb } from './constants';
import { Box, Button, CircularProgress, Input, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import MyRequestController, { Props, configJSON } from './MyRequestController.web';
import { imagewithoutApi } from '../../cfpipelinemanagement/src/assets';
import { IMyRequestListAttributeAll } from './types';
import { ArrowBack, Search } from '@material-ui/icons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dateFormatOppAllList, formatString } from '../../cfpipelinemanagement/src/constants';
import { OpportunitiesField } from '../../cfpipelinemanagement/src/Components/OpportunitiesTable.web';

export  class MyRequest extends MyRequestController {
    constructor(props: Props) {
        super(props);
      }
     
    render(): ReactNode {
        const {sortBy, myRequestArray,isLoading ,listSearchValue,listSearchValueError} = this.state
        const notthingToShowOpp = " Nothing to show"
        const noRecordsOpp ="There are no entries created for now"
        const { classes }:any = this.props

        return (
            <Layout
                breadcrumb={MyRequestBreadCrumb}
                navigation={this.props.navigation}
                // title="MY REQUEST"
            >            
            <OpportunitiesField>
                <Box className="table-actions-row">
                    <Typography className="my-request-title">
                        MY REQUEST
                    </Typography>
                    <Box className="mr-list-actions">
                        <Input
                            onChange={(event) => this.handleSearchMr(event.target.value)}
                            onBlur={this.handleBlurEvent}
                            data-test-id="search-input-mr-id"
                            placeholder="Search"
                            fullWidth={true}
                            disableUnderline
                            className={listSearchValueError ? classes.redSearchField : classes.searchField}
                            value={listSearchValue}
                            startAdornment={<InputAdornment position="start">
                                <Search style={StatusFieldStyles.colorGrayMr} />
                            </InputAdornment>}
                        />
                        <Box className={classes.sortByBox}>
                            <InputLabel className={classes.sortByText} htmlFor="txtInputFirstName">Sort By:</InputLabel>
                            <Select
                                fullWidth
                                disableUnderline
                                className={classes.sortByField}
                                inputProps={{
                                    name: 'firstName',
                                    id: 'txtInputFirstName',
                                }}
                                MenuProps={{
                                    classes:{ paper:classes.rootMrMenuItem },
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }
                                }}
                                onChange={(event) => this.handleSortByMr(event)}
                                value={sortBy}
                                data-test-id="sort-mr-id"
                            >
                                <MenuItem value="desc" > Newest to Oldest </MenuItem>
                                <MenuItem value="asc"> Oldest to Newest</MenuItem>

                            </Select>
                        </Box>
                        


                    </Box>
                </Box>
                <>
                {isLoading ? (
                    <Box className="table-loader" >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {myRequestArray && myRequestArray.length > 0  && !isLoading
                            ? 
                            this.renderTableData(myRequestArray)
                            : !isLoading  && (
                                 <Box className="tableRecordsNotMainBox">
                                    <img src={imagewithoutApi} alt="" />
                                    <Typography className="tableRecordsNotTitile" >
                                        {notthingToShowOpp}
                                    </Typography>
                                    <Typography className="tableRecordsNotMesg"  >
                                        {noRecordsOpp}
                                    </Typography>
                                </Box> 
                             ) 
                        } 
                    </>
                  )}  
                </>
            </OpportunitiesField>

            </Layout>
        )
    }

    renderTableData = (myRequestArray:any) => {
        return (
            <>
                <Box className="mr-table-wrapper">
                    <Box className="table-container">
                        <Table className="mr-table">
                            <TableHead>
                                <TableRow className="table-header-row">
                                    <TableCell className="mr-table-cell-my-requestid">
                                        <Typography>Request ID</Typography>
                                    </TableCell>
                                    <TableCell className="mr-table-cell">
                                        <Typography>Created Date</Typography>
                                    </TableCell>
                                    <TableCell className="mr-table-cell">
                                        <Typography>Delivery Date</Typography>
                                    </TableCell>
                                    <TableCell className="mr-table-cell">
                                        <Typography>Status</Typography>
                                    </TableCell>
                                    <TableCell className="mr-table-cell">
                                        <Typography>More Details</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    myRequestArray.map((item: IMyRequestListAttributeAll) => (
                                        <TableRow key={item.attributes.id}   className="table-body-row">
                                            <TableCell>
                                                <Typography data-test-id="viewOppDetailsLink" className="request-id">
                                                   {item.attributes.id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="date-column" > {dateFormatOppAllList(item.attributes.created_at,true)} </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography  className="date-column"> {dateFormatOppAllList(item.attributes.user_delivery_date,true)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ ...StatusFieldStyles[item.attributes.review_status] }}  >{formatString(item.attributes.review_status)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Box className="view-details-button-Box">
                                                    <Button
                                                        variant="outlined"
                                                        className="view-details-button"
                                                        onClick={() => this.handleViewDetailNavigation(item.attributes.id)}
                                                        data-test-id="view-detail-id">
                                                            View Details
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                          
                                        </TableRow>
                                    ))
                                } 
                            </TableBody>
                                {this.renderPagination()}
                        </Table>
                    </Box>
                </Box>
               
            </>
        )
    }

    renderPagination = () => {
        const {pagneNumberValidation,pageNumberSet,currentPage, perPage,myRequestArrayMeta, numberOfButtons ,myRequestArray} = this.state;
        const till = perPage * ( currentPage - 1) + myRequestArray.length
        const currPg = currentPage === 1 ? perPage : till
        const showingTill = myRequestArrayMeta?.total_contents_count < perPage ? myRequestArrayMeta?.total_contents_count : currPg
        const { classes }:any = this.props

        return (
          <TableRow className="pagination-row"  >
          <TableCell colSpan={13} >
            <div className="mr-colSpan-Style" >
              <div  className="page-view-count" >
                <Typography>View</Typography>
                <div >
                    <Select 
                      onChange={(event) => this.handlePaginationMr(event)} 
                      data-test-id="handle-pagination-Id"
                      value={perPage} fullWidth disableUnderline className="inputTxtMainSelect"
                      MenuProps={{
                        classes:{paper:classes.rootMenuItem},
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                    }}
                    >
                      {configJSON.perPageView.map((value:any) => (
                        <MenuItem key={value} value={value}  >
                            {value}
                        </MenuItem>
                      ))}
                    </Select>
                </div>
                <Typography> records per page</Typography>
    
              </div>
              <div className="page-entries" >
                <Typography>
                    Showing {" "}
                    {currentPage === 1 ? '1' : ((currentPage-1)*perPage + 1)}
                    {" "} to {" "}
                    {showingTill}
                    {" "} of {" "} 
                    {myRequestArrayMeta?.total_contents_count}
                    {" "} entries
                </Typography>
              </div>
              <div className="mr-page-view-count-btn"  >
                <Button onClick={()=> {this.handlePageNextPreviousBtnMr("previousPage")}}  data-test-id="pageButtonPreviousID"   variant="outlined" className="paginationNext-btn"  >
                  <ArrowBack className="pagination-ArrowBack"  />
                </Button>
                    <Typography> 
                      <span className="pagination-btn-text" >Page </span> 
                    </Typography>
                        <Input
                            data-test-id="pageNumberId"
                            placeholder={"0"}
                            fullWidth={true}
                            value={pageNumberSet}
                            onChange={this.handlePageNumberInputMr}
                            disableUnderline
                            className={pagneNumberValidation ? "reddrawerinputTxtMain" : "drawerinputTxtMain"}
                        />
                    <Typography>                 
                         <span className="pagination-btn-text">of</span> 
                             {numberOfButtons}
                    </Typography>
                  <Button onClick={()=> {this.handlePageNextPreviousBtnMr("nextPage")}}   data-test-id="pageButtonNextID" variant="outlined" className="paginationNext-btn" 
                ><ArrowForwardIcon  className="pagination-ArrowForwardIcon" />
                </Button>
              </div>
            </div>
          </TableCell>
        </TableRow>
        )
      }
    
}

 const menuItemStyle = () => ({
   rootMenuItem:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"1px"
        },
        "& ul li":{
            fontSize: "14px",
            padding:"5px 14px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)"
        },
    },
    rootMrMenuItem:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"1px"
        },
        "& ul li":{
            fontSize: "14px",
            padding:"5px 14px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)",
            padding:"9px 20px 9px 16px"
        },
        width:"207px",
        height:"82px",
        "& .MuiPaper-root.MuiMenu-paper.MyRequest-rootMrMenuItem-2.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded":{
            opacity: 1,
            transform: 'none',
            minWidth: '188px',
            transition: 'opacity 229ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '120px',
            left: '990px !important',
        }
    },
    searchField: {
        border: "1px solid #dfdfdf",
        borderRadius: "8px",
        padding: "10px 8px 10px  8px",
        height: "44px",
        fontFamily: "'Inter', sans-serif",
        width: "360px"
    },
    redSearchField: {
        border: "1px solid red",
        borderRadius: "8px",
        padding: "10px 8px 10px  8px",
        height: "44px",
        fontFamily: "'Inter', sans-serif",
        width: "360px"
    },
    sortByField: {
        border: "1px solid #dfdfdf",
        borderRadius: '0 8px  8px 0',
        padding: "10px 8px 10px  8px",
        height: "44px",
        fontFamily: "'Inter', sans-serif",
        width: "206"
    },
    redSortByField: {
        border: "1px solid red",
        borderRadius: "8px",
        padding: "10px 8px 10px  8px",
        height: "44px",
        fontFamily: "'Inter', sans-serif",
        width: "300px"
    },

    sortByText:{
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        border: "1px solid #dfdfdf",
        color: 'gray',
        borderRadius: '8px 0 0 8px',
        padding: "10px 16px 10px 16px",
    },
    sortByBox:{
        display: 'flex',
    },

    "@media(max-width: 960px)": {
    },
    "@media (max-width: 600px)": {
        sortByField: {
            border: "1px solid #dfdfdf",
            borderRadius: '0 8px  8px 0',
            padding: "10px 8px 10px  8px",
            height: "44px",
            fontFamily: "'Inter', sans-serif",
            width: "182px"
        },
        sortByText:{
            display: 'flex',
            alignItems: 'center',
            fontSize: '11px',
            border: "1px solid #dfdfdf",
            color: 'gray',
            borderRadius: '8px 0 0 8px',
            padding: "10px 16px 10px 16px",
            // width:"11px"
        },
        searchField: {
            border: "1px solid #dfdfdf",
            borderRadius: "8px",
            padding: "10px 8px 10px  8px",
            height: "44px",
            fontFamily: "'Inter', sans-serif",
            width:"100%"
        },
        redSearchField: {
            border: "1px solid red",
            borderRadius: "8px",
            padding: "10px 8px 10px  8px",
            height: "44px",
            fontFamily: "'Inter', sans-serif",
            width:"100%"
        },
      }
})


export const StatusFieldStyles = {
    colorGrayMr: { color: "gray" },
    // yellow
    "submit_for_review": {
        background: 'rgba(254, 243, 199, 1)',
        borderRadius: '16px',
        color: 'rgba(217, 119, 6, 1)',
        padding: '4px 8px',
        fontSize: '12px',
        display: 'flex',         
        justifyContent: 'center',
        alignItems: 'center',    
        width: '150px',
        height: '26px',
    },
    
    // green
    "approve": {
        background: 'rgba(209, 250, 229, 1)',
        color: 'green',
        borderRadius: '16px',
        padding: '4px 8px',
        fontSize: '12px',
        display: 'flex',         
        justifyContent: 'center',
        alignItems: 'center',   
        width: '122px',
        height: '26px',
    },
    // gray
    "pending": {
        background: 'rgba(241, 245, 249, 1)',
        color: 'rgba(71, 85, 105, 1)',
        borderRadius: '16px',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'center',
        display: 'flex',         
        justifyContent: 'center',
        width: '122px',
        height: '26px',
    },
    //red
    "rejected": {
        background: 'rgba(254, 226, 226, 1)',
        color: 'red',       
        borderRadius: '16px',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'center',
        display: 'flex',         
        justifyContent: 'center',
        width: '122px',
        height: '26px',
    },

    "redErrorSearch":{
        border: "1px solid red",
        borderRadius: "8px",
        padding: "10px 8px 10px  8px",
        height: "44px",
        fontFamily: "'Inter', sans-serif",
        width: "344px"
    }
}



export default withStyles(menuItemStyle)(MyRequest);