export const UpdatePasswordBreadcrumb = {
    currentPage: {
        link: "UpdatePassword",
        text: "Account"
    },
    links: [
        {
            link: "AdminProfile",
            text: "Profile"
        }
    ]
};
export const MyRequestBreadCrumb = {
    currentPage: {
      link: "MyRequests",
      text: "My Request"
    },
    links: [{
      link: "AdminProfile",
      text: "Profile"
    }]
};

export const RequestDetailBreadCrumb = {
  currentPage: {
    link: "RequestDetail",
    text: "Request Detail"
  },
  links: [{
    link: "AdminProfile",
    text: "Profile"
  },
  {
    link: "MyRequests",
    text: "My Request"
  }]
};

export const RequestDetailData = 
{
  "id": "274",
  "type": "content",
  "attributes": {
      "id": 274,
      "headline": "TEST",
      "content": "TEST",
      "image_url": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcGtDIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1b20ea6592fc3a374eb826704c6c7fd192a7e6e5/pexels-pixabay-268533.jpg",
      "attachment_url": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcG9DIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4114d1b0da9b663e413fbf16e038dc7f7104bb95/pexels-pixabay-268533.jpg",
      "status": "publish",
      "review_status": "approve",
      "publish_date": "2023-10-18T00:00:00.000+05:30",
      "user_request_date": "2023-10-13T00:00:00.000+05:30",
      "user_delivery_date": "2023-10-14T00:00:00.000+05:30",
      "created_at": "2023-10-13T16:01:11.814+05:30",
      "updated_at": "2023-10-18T11:00:19.660+05:30",
      "archived": false,
      "category_id": 8,
      "category_name": "Digital Marketing",
      "sub_category_id": 42,
      "sub_category_name": "Email campaign templates"
  }
}



