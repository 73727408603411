// Customizable Area Start
import {
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  Typography,
  styled
} from "@material-ui/core";
import PeriodicReportController, {
  Props,
  configJSON
} from "./PeriodicReportController.web";
import React from "react";
import LayoutWeb from "../../../components/src/Layout.web";
import {
  calendarIcon,
  chevronLeftIcon,
  chevronRightIcon,
  downloadIcon,
  filterDownActiveIcon,
  filterDownIcon,
  filterUpActiveIcon,
  filterUpIcon
} from "./assets";
import { StyledAmountBoxContainer } from "./EarnedCommissionsCard.web";
import StyledMenuComponent from "../../../components/src/StyledMenuComponent.web";
import { StyledTableContainer, StyledTableHead } from "./Statistics.web";
import { StyledTableBody } from "./RegisteredOpportunitiesCard.web";

const periodicReportTable = [
  {
    id: 1,
    date: "2023-01-01",
    salesAmount: "$1,000.00",
    commissionEarned: "$100,00"
  },
  {
    id: 2,
    date: "2023-01-01",
    salesAmount: "$10,000.00",
    commissionEarned: "$1,000.00"
  },
  {
    id: 3,
    date: "2023-01-01",
    salesAmount: "$5,000.00",
    commissionEarned: "$500.00"
  },
  {
    id: 4,
    date: "2023-01-01",
    salesAmount: "$8,000.00",
    commissionEarned: "$800.00"
  },
  {
    id: 5,
    date: "2023-01-01",
    salesAmount: "$16,000.00",
    commissionEarned: "$1,600.00"
  },
  {
    id: 6,
    date: "2023-01-01",
    salesAmount: "$1,000.00",
    commissionEarned: "$100,00"
  },
  {
    id: 7,
    date: "2023-01-01",
    salesAmount: "$10,000.00",
    commissionEarned: "$1,000.00"
  },
  {
    id: 8,
    date: "2023-01-01",
    salesAmount: "$5,000.00",
    commissionEarned: "$500.00"
  },
  {
    id: 9,
    date: "2023-01-01",
    salesAmount: "$8,000.00",
    commissionEarned: "$800.00"
  },
  {
    id: 10,
    date: "2023-01-01",
    salesAmount: "$16,000.00",
    commissionEarned: "$1,600.00"
  },
  {
    id: 11,
    date: "2023-01-01",
    salesAmount: "$1,000.00",
    commissionEarned: "$100,00"
  },
  {
    id: 12,
    date: "2023-01-01",
    salesAmount: "$10,000.00",
    commissionEarned: "$1,000.00"
  },
  {
    id: 13,
    date: "2023-01-01",
    salesAmount: "$5,000.00",
    commissionEarned: "$500.00"
  },
  {
    id: 14,
    date: "2023-01-01",
    salesAmount: "$8,000.00",
    commissionEarned: "$800.00"
  },
  {
    id: 15,
    date: "2023-01-01",
    salesAmount: "$16,000.00",
    commissionEarned: "$1,600.00"
  }
];

// Customizable Area End

export default class PeriodicReport extends PeriodicReportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { filterAnchorEl, dateFilter, sortingDate } = this.state;
    // Customizable Area End

    return (
      // Customizable Area
      <StyledBox>
        <LayoutWeb
          breadcrumb={configJSON.periodicReportBreadcrumb}
          isDefultCard={false}
          navigation={this.props.navigation}
        >
          <Box className="page-wrapper">
            <Box className="header">
              <Typography className="header-title">Periodic Report</Typography>
              <Box className="pagination-wrapper">
                <Typography className="pagination-text">1 - 24 / 24</Typography>
                <Button className="pagination-button">
                  <img src={chevronLeftIcon} alt="pagination-button" />
                </Button>
                <Button className="pagination-button">
                  <img src={chevronRightIcon} alt="pagination-button" />
                </Button>
              </Box>
            </Box>
            <Box className="table-wrapper">
              <StyledTableContainer className="periodic-table-container">
                <Box className="table-filter-container">
                  <Typography className="table-filter-title">
                    Aliyah Lane
                  </Typography>
                  <Box className="filter-buttons-container">
                    <Typography className="filter-text">
                      July 2023 - July 2023
                    </Typography>
                    <Button
                      data-test-id="date-filter-button"
                      onClick={this.handleFilterMenuOpen}
                      className="date-filter-button"
                    >
                      <img src={calendarIcon} alt="calendar-filter" />
                    </Button>
                    <StyledMenuComponent
                      data-test-id="filter-menu"
                      anchorEl={filterAnchorEl}
                      menuWidth="166px"
                      onClose={this.handleFilterMenuClose}
                    >
                      {configJSON.periodicReportFilterOptions.map(
                        (item: string) => (
                          <Box
                            data-test-id="menu-item"
                            onClick={() => this.handleFilter(item)}
                            className={`filter-menu-item ${
                              item === dateFilter ? "selected" : ""
                            } `}
                          >
                            {item}
                          </Box>
                        )
                      )}
                    </StyledMenuComponent>
                    <Button className="download-button">
                      <img src={downloadIcon} alt="download" />
                    </Button>
                  </Box>
                </Box>
                <Table aria-label="Periodic Table">
                  <StyledTableHead className="periodic-head">
                    <TableRow>
                      <TableCell style={{ width: "44px" }} align="center">
                        Sr.
                      </TableCell>
                      <TableCell style={{ width: "150px" }} align="left">
                        <Box className="cell-with-filter">
                          <>Date</>
                          <Box
                            data-test-id="date-sort"
                            onClick={this.handleSorting}
                            className="filter-icons-container"
                          >
                            <img
                              src={
                                sortingDate === "up"
                                  ? filterUpActiveIcon
                                  : filterUpIcon
                              }
                              alt="filter-up"
                            />
                            <img
                              src={
                                sortingDate === "down"
                                  ? filterDownActiveIcon
                                  : filterDownIcon
                              }
                              alt="filter-down"
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell style={{ width: "296px" }} align="left">
                        Sales Amount
                      </TableCell>
                      <TableCell style={{ width: "150px" }} align="center">
                        Commission Earned
                      </TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <StyledTableBody className="periodic-table-body">
                    {periodicReportTable.map(row => (
                      <TableRow key={row.id}>
                        <TableCell
                          style={{ width: "44px" }}
                          align="center"
                          component="th"
                          scope="row"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell style={{ width: "150px" }} align="left">
                          {row.date}
                        </TableCell>
                        <TableCell style={{ width: "296px" }} align="left">
                          {row.salesAmount}
                        </TableCell>
                        <TableCell style={{ width: "150px" }} align="left">
                          {row.commissionEarned}
                        </TableCell>
                      </TableRow>
                    ))}
                  </StyledTableBody>
                </Table>
                <StyledAmountBoxContainer className="amount-box-container">
                  <Box className="amount-box">
                    <Typography className="label">
                      Total Sales Amount
                    </Typography>
                    <Typography className="amount">$40,000.00</Typography>
                  </Box>
                  <Box className="amount-box">
                    <Typography className="label">
                      Total Commission Earned
                    </Typography>
                    <Typography className="amount">$4,000.00</Typography>
                  </Box>
                </StyledAmountBoxContainer>
              </StyledTableContainer>
            </Box>
          </Box>
        </LayoutWeb>
      </StyledBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledBox = styled(Box)({
  "& .right-area": {
    padding: 0
  },
  "& .breadcrumb-container": {
    backgroundColor: "#fff",
    borderBottom: "1px solid #E2E8F0",
    padding: "24px 48px",
    margin: 0,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    height: "92px"
  },
  "& .breadcrumb-container ~ div": {
    marginTop: "24px",
    maxWidth: "calc(100vw - 120px)"
  },
  "& .page-wrapper": {
    padding: "0 80px"
  },
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
    "& .header-title": {
      color: "#0A0528",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "28px",
      textTransform: "uppercase"
    },
    "& .pagination-wrapper": {
      display: "flex",
      alignItems: "center",
      "& .pagination-text": {
        color: "#334155",
        fontSize: "20px",
        lineHeight: "28px",
        marginRight: "16px"
      },
      "& .pagination-button": {
        border: "1px solid #94A3B8",
        borderRadius: "4px",
        width: "32px",
        height: "32px",
        minWidth: "32px"
      },
      "& .pagination-button:first-of-type": {
        marginRight: "8px"
      }
    }
  },
  "& .table-wrapper": {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "32px 0",
    "& .table-filter-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      "& .filter-buttons-container": {
        display: "flex",
        alignItems: "center",
        gap: "10px"
      },
      "& .table-filter-title": {
        color: "#0A0528",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px"
      },
      "& .filter-text": {
        fontSize: "16px",
        lineHeight: "24px",
        color: "#231188"
      },
      "& .date-filter-button, & .download-button": {
        height: "44px",
        width: "44px",
        minWidth: "44px",
        borderRadius: "4px",
        border: "1px solid"
      },
      "& .date-filter-button": {
        borderColor: "#231188"
      },
      "& .download-button": {
        borderColor: "#94A3B8"
      }
    }
  },
  "@media(max-width: 960px)": {
    "& .page-wrapper": {
      padding: "0 10px"
    },
    "& .breadcrumb-container ~ div": {
      marginTop: "18px"
    },
    "& .breadcrumb-container": {
      padding: "12px 24px"
    },
    "& .header": {
      marginBottom: "18px",
      flexDirection: "column",
      gap: "20px",
      alignItems: "flex-start",
      "& .header-title": {
        fontSize: "16px",
        lineHeight: "20px"
      },
      "& .pagination-wrapper": {
        "& .pagination-text": {
          fontSize: "16px",
          lineHeight: "20px",
          marginRight: "12px"
        },
        "& .pagination-button": {
          width: "24px",
          height: "24px",
          minWidth: "24px"
        },
        "& .pagination-button:first-of-type": {
          marginRight: "8px"
        }
      }
    },
    "& .table-wrapper": {
      padding: "16px 0",
      "& .table-filter-container": {
        padding: "10px",
        flexDirection: "column",
        alignItems: "flex-end!important",
        "& .filter-buttons-container": {
          flexWrap: "wrap",
          marginTop: "20px",
          justifyContent: "flex-end"
        },
        "& .table-filter-title": {
          fontSize: "16px",
          lineHeight: "20px",
          flex: "0 0 100%"
        },
        "& .filter-text": {
          fontSize: "12px",
          lineHeight: "18px"
        },
        "& .date-filter-button, & .download-button": {
          height: "32px!important",
          width: "32px!important",
          minWidth: "32px!important"
        }
      }
    },
    "& .amount-box-container": {
      flexWrap: "wrap",
      gap: "10px"
    },
    "& .amount-box": {
      width: "100%"
    },
    "& table": {
      display: "block",
      overflowX: "auto",
      whiteSpace: "nowrap"
    }
  }
});
// Customizable Area End
