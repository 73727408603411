// Customizable Area Start
//@ts-nocheck
import React from "react";
import { Box, Button, InputAdornment, Typography, withStyles, Input, InputLabel, TextareaAutosize, MenuItem, Select, TextField, Modal, Paper, styled } from "@material-ui/core";
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Lock from '@material-ui/icons/Lock';
import { Autocomplete } from "@material-ui/lab";
import CreateOpportunitiesController, { Props } from "./CreateOpportunitiesController";
import Layout from "../../../components/src/Layout.web";
import { SuccessImg, closeImg } from "./assets";
export const configJSON = require("./config");
import getSymbolFromCurrency from 'currency-symbol-map';
import { minDate } from "./constants"

export class CreateOpportunitiesBlock extends CreateOpportunitiesController {
    constructor(props: Props) {
        super(props);
    }
    getCountryLabel = (option: any) => {
        return option.name ?? this.state.countryDeal
    };
    handleOptions = (option, value) => { return option.name === value.name }

    getCityLabel = (option: any) => {
        return option.name ?? this.state.cityDeal
    };

    getCurrencyLabel = (option: any) => {
        return option ?? this.state.inrCurrency
    };
    
    renderCountryInput = (params) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id="inputtxtCountryName"
            sx={{ fontSize: "12px" }}
            {...params}
            placeholder="Country"
            value={this.state.countryDeal}
        />
    )

    renderCityInput = (params) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id="txtInputCityName"
            sx={{ fontSize: "12px" }}
            {...params}
            placeholder="City"
            value={this.state.cityDeal}
        />
    )
    renderCurrencyInput = (params) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id="txtInputCurrency"
            sx={{ fontSize: "12px" }}
            {...params}
            placeholder="Currency"
            value={this.state.inrCurrency}
        />
    )
    handleCountryLabel = (event: any, e: string | null) => {
        this.textCountryPropsDeal((e && e.name) || "")
        this.setState({
            countryIsoCode: e && e.isoCode,
            inrCurrency: (e && e.currency) || '',
            currencySymbol: getSymbolFromCurrency(e && e.currency) || '',
            cityDeal:''
        })
    }

    handleCityLabel = (_, e) => {
        this.txtCityPropsDeal((e && e.name) || "")
    }
    handleCurrencyLabel = (_,value) => {
        this.textCurrencyInput.onChange(value)
    }
    getLeadSourceLabel = (option: any) => {
        return option ?? this.state.leadSource
    };
    handleLeadSourceyLabel = (event: any, e: string | null) => {
        this.handleleadSource (e  || "")
    }
    handleOptionsLeadS = (option, value) => { return option === value }
    renderLeadSourceInput = (params) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id="inputtxtLeadSourceInput"
            sx={{ fontSize: "12px" }}
            {...params}
            placeholder="Lead Source"
            value={this.state.leadSource}
        />
    )

    render() {
        // Customizable Area Start 
        const { classes } = this.props
        let breadcrumbs;
        let breadcrumbsTitle = "Opportunity Information";


        if (this.state.editHandle) {
            breadcrumbs = configJSON.UpdateOty;
        }
        else if (this.state.editField) {
            if (this.state.listType) {
                breadcrumbs = configJSON.DealView;
                breadcrumbsTitle = "Deal Information";
            }
            else {
                breadcrumbs = configJSON.ViewOty;
            }
        }
        else {
            breadcrumbs = configJSON.CreateOpportunityBreadcrumb;

        }
        
        // Customizable Area End

        return (
            // Customizable Area Start 
            <Layout
                navigation={this.props.navigation}
                breadcrumb={breadcrumbs}
                title={breadcrumbsTitle}
            >
                <Box className={`${classes.mainContainer}`}>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Owner
                                    </InputLabel>
                                </Box>
                                <Input data-test-id="txtInputOwner" placeholder={"Owner Name"} fullWidth={true}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.dealOwnerInputName}
                                    value={this.state.dealOwner}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disabled={this.state.editField}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SupervisorAccount className={`${classes.inputAdornmentStyle}`}
                                                aria-label="toggle password visibility" edge="end" >
                                            </SupervisorAccount>
                                        </InputAdornment>
                                    } />
                                <Box>
                                </Box>
                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Closing Date
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input placeholder={"Last name"} fullWidth={true}
                                    type="date"
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.dealClosingDateInputFname}
                                    value={this.state.closingDate}
                                    data-test-id="txtInputClosingDate"
                                    inputProps={{
                                        min: minDate(),
                                        max: "3000-12-31",
                                    }}
                                    disabled={this.state.limitedEditField}
                                    onFocus={e => e.target.showPicker && e.target.showPicker()}
                                />
                                <Box className="errorTextContent">
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.closingDateError}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Opportunity Name
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input data-test-id="txtInputOpportunityName" placeholder={"Opportunity Name"} fullWidth={true}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.txtInputDealName}
                                    value={this.state.dealFormName}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disabled={this.state.editField}
                                />
                                <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.dealOpportunityNameError}</Typography>
                                </Box>
                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Contact Name
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input data-test-id="txtInputContactName" placeholder={"Contact Name"} fullWidth={true}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.txtInputcontactName}
                                    value={this.state.contactDealName}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SupervisorAccount className={`${classes.inputAdornmentStyle}`}
                                                aria-label="toggle password visibility" edge="end" >
                                            </SupervisorAccount>
                                        </InputAdornment>
                                    }
                                    disabled={this.state.editField}
                                />
                                <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.contactDealNameError}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Account Name
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input data-test-id="txtInputAccountName" placeholder={"Account Name"} fullWidth={true}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.accountDealNameText}
                                    value={this.state.accountDealName}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disabled
                                />
                                <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.accountDealNameError}</Typography>
                                </Box>
                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Stage
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Select
                                    fullWidth
                                    disableUnderline
                                    className={classes.admininputTxtStge}
                                    value={this.state.stageName}
                                    {...this.handlestageText}
                                    disabled={this.state.limitedEditField}
                                    inputProps={{
                                        name: 'firstName',
                                        id: 'txtInputFirstName',
                                    }}
                                    data-test-id="txtInputStage"
                                    MenuProps={{
                                        classes:{paper:classes.root},
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }
                                    }}

                                >
                                    {this.stageList.map((data) => (
                                        <MenuItem  key={data.key} value={data.key} data-probability-key={data.probabilityKey}>{data.stage}</MenuItem>
                                    ))}

                                </Select>
                                <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.stageError}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Service Line
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Select
                                    fullWidth
                                    disableUnderline
                                    className={classes.admininputTxtMain}
                                    {...this.handleserviceline}
                                    value={this.state.serviceLine}
                                    disabled={this.state.limitedEditField}
                                    inputProps={{
                                        name: 'firstName',
                                        id: 'txtInputFirstName',
                                    }}
                                    data-test-id="txtInputServiceLine"
                                    MenuProps={{
                                        classes:{paper:classes.root},
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }
                                    }}
                                >
                                    {this.serviceLineList.map((data) => (
                                        <MenuItem key={data} value={data}>{data}</MenuItem>
                                    ))}
                                </Select>
                                <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.serviceLineError}</Typography>
                                </Box>
                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Probability(%)
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input data-test-id="txtInputProbability" placeholder={"Probability"} fullWidth={true}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.txtInpuprobabilityNumber}
                                    disabled
                                    value={this.state.probabilityNumber}
                                />
                                <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.probabilityNumberError}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Lead Source 
                                    </InputLabel>
                                </Box>
                                <Autocomplete
                                    key={this.state.clearInput}
                                    disableUnderline
                                    className={`${classes.countryLoginInput}`}
                                    data-test-id="txtInputLeadSource"
                                    sx={{ fontSize: "12px" }}
                                    limitTags={1}
                                    options={this.leadSourceList}
                                    getOptionLabel={this.getLeadSourceLabel}
                                    fullWidth
                                    onChange={this.handleLeadSourceyLabel}
                                    getOptionSelected={this.handleOptionsLeadS}
                                    renderInput={this.renderLeadSourceInput}
                                    disabled={this.state.editField}
                                    value={this.state.leadSource}
                                />

                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Total Deal Amount
                                    </InputLabel>
                                    <Typography component="div" variant="subtitle1" className={`${classes.requireColor}`}>*</Typography>
                                </Box>
                                <Input data-test-id="txtInputAmount" placeholder={"100.00"} fullWidth={true}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.handleleadAmount}
                                    value={this.state.amountDeal}
                                    disabled={this.state.editField}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <ErrorOutline className={`${classes.inputAdornmentStyle}`}
                                                aria-label="toggle password visibility" edge="end" >
                                            </ErrorOutline>
                                        </InputAdornment>
                                    }
                                    startAdornment={<Typography style={{color: (this.state.amountDeal && !this.state.editField)? '#212121': '#B4B4B4', marginBottom:'1px'}}>{this.state.currencySymbol}</Typography>}
                                />
                                <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.amountDealError}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Type 
                                    </InputLabel>
                                </Box>
                                <Select
                                    fullWidth
                                    disableUnderline
                                    className={classes.admininputTxtMain}
                                    {...this.textTypeInput}
                                    value={this.state.type}
                                    disabled={this.state.editField}
                                    inputProps={{
                                        name: 'firstName',
                                        id: 'txtInputFirstName',
                                    }}
                                    data-test-id="txtInputType"
                                    MenuProps={{
                                        classes:{paper:classes.root},
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }
                                    }}
                                >
                                    {this.dealTypeList.map((data:any) => (
                                        <MenuItem key={data.value} value={data.value}>{data.label}</MenuItem>
                                    ))}
                                </Select>

                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Expected Revenue
                                    </InputLabel>
                                </Box>
                                <Input data-test-id="txtInputExpectedRevenue" placeholder={"1000.00"} fullWidth={true}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disabled
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.textExpectedRevenueInput}
                                    value={this.state.expectedRevenue}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Lock className={`${classes.inputAdornmentStyle}`}
                                                aria-label="toggle password visibility" edge="end" >
                                            </Lock>
                                        </InputAdornment>
                                    }
                                    startAdornment={<Typography style={{color: this.state.expectedRevenue ? '#B4B4B4': '#D6D6D6', marginBottom:'1px'}}>{this.state.currencySymbol}</Typography>}
                                />
                                 <Box>
                                    <Typography className={`${classes.errormassagetext}`}>{this.state.expectedRevenueError}</Typography>
                                </Box>

                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Country
                                    </InputLabel>
                                </Box>
                                <Autocomplete
                                    key={this.state.clearInput}
                                    disableUnderline
                                    className={`${classes.countryLoginInput}`}
                                    data-test-id="txtInputCountry"
                                    sx={{ fontSize: "12px" }}
                                    limitTags={1}
                                    options={this.state.countryArrayCommon}
                                    getOptionLabel={this.getCountryLabel}
                                    fullWidth
                                    onChange={this.handleCountryLabel}
                                    getOptionSelected={this.handleOptions}
                                    renderInput={this.renderCountryInput}
                                    disabled={this.state.editField}
                                    PaperComponent={(props) => {
                                        return (
                                            <StyledPaper
                                              {...props}
                                            />
                                          )
                                    }}
                                    value={this.state.countryDeal}
                                />
                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        City 
                                    </InputLabel>
                                </Box>
                                <Autocomplete
                                    key={this.state.clearInput}
                                    disableUnderline
                                    className={`${classes.countryLoginInput}`}
                                    data-test-id="txtInputCity"
                                    sx={{ fontSize: "12px" }}
                                    limitTags={1}
                                    options={this.state.cityList}
                                    getOptionLabel={this.getCityLabel}
                                    fullWidth
                                    onChange={this.handleCityLabel}
                                    getOptionSelected={this.handleOptions}
                                    renderInput={this.renderCityInput}
                                    disabled={this.state.editField}
                                    PaperComponent={(props) => {
                                        return (
                                            <StyledPaper
                                              {...props}
                                            />
                                          )
                                    }}
                                    value={this.state.cityDeal}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Currency
                                    </InputLabel>
                                </Box>
                                <Autocomplete
                                    key={this.state.clearInput}
                                    disableUnderline
                                    className={`${classes.countryLoginInput}`}
                                    data-test-id="txtInputCurrency"
                                    sx={{ fontSize: "12px" }}
                                    limitTags={1}
                                    options={this.currencyFormArry}
                                    getOptionLabel={this.getCurrencyLabel}
                                    fullWidth
                                    onChange={this.handleCurrencyLabel}
                                    renderInput={this.renderCurrencyInput}
                                    disabled={this.state.editField}
                                    PaperComponent={(props) => {
                                        return (
                                            <StyledPaper
                                              {...props}
                                            />
                                          )
                                    }}
                                    value={this.state.inrCurrency}
                                />
                            </Box>

                            <Box className={`${classes.adminFiledGroupBox}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Website
                                    </InputLabel>
                                </Box>
                                <Input data-test-id="txtInputWebsite" placeholder={"Add your website"} fullWidth={true}
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    disabled={this.state.editField}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.txtInputWebsiteName}
                                    value={this.state.websiteDealName}
                                />

                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.fieldMainBox}`}>
                        <Box className={`${classes.adminFieldMainBox}`}>
                            <Box className={`${classes.adminFiledGroupBoxNotes}`}>
                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <InputLabel className={`${classes.emailadminLabelText}`}>
                                        Notes
                                    </InputLabel>
                                </Box>
                                <TextareaAutosize data-test-id="txtInputNotes" placeholder={"Add comments here.."} fullWidth={true}
                                    disableUnderline
                                    className={`${classes.admininputTxtMain}`}
                                    {...this.txtInputNotesName}
                                    value={this.state.notesDeal}
                                    type="date"
                                    minRows={4}
                                    disabled={this.state.editField}
                                />

                            </Box>
                        </Box>
                    </Box>

                   
                        <Box className={`${classes.buttonFlex}`}>
                            {!this.state.limitedEditField &&
                                <>
                                    <Box>
                                        <Button data-test-id="txtInputSaveuserbtn" id="createNewUserClick" className={`${classes.SaveBtn}`} {...this.createOpportuniesHandle}>Save</Button>
                                    </Box>
                                    <Box>
                                        <Button data-test-id="save-and-new-button" onClick={this.handleSaveAndNew} className={`${classes.SaveNewBtn}`}>Save and New</Button>
                                    </Box>
                                </>
                            } 
                                <Box>
                                    <Button data-test-id="txtInputCancelbtn" className={`${classes.CancelButton}`} onClick={this.handleNavigateOpportunitiesListPage}>Cancel</Button>
                                </Box>
                        </Box>
                   
                    
                </Box >
                <Modal
                    open={this.state.isSuccessModalOpen}
                    onClose={this.handleCloseModal}
                    data-test-id='success-modal'
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={`${classes.modaladminContainer}`}>
                        <Box className={`${classes.closeadminimg}`} data-test-id="close-modal-button" onClick={this.handleCloseModal}>
                            <img src={closeImg} alt="" style={{ width: "24px" }} />
                        </Box>
                        <Box className={`${classes.successImgStyleadmin}`}>
                            <img src={SuccessImg} alt="" style={{ width: "58px" }} />
                        </Box>
                        <Box className={`${classes.centeradminTxt}`}>
                            <Typography className={`${classes.successTxtadmin}`}>Your opportunity has been successfully {this.state.editHandle ? "updated":"created" } </Typography>
                            <Typography className={`${classes.descriptionTxtadmin}`}>Your opportunity id is <Typography className={`${classes.opportunityIdText}`}> {this.state.opportunityId}</Typography></Typography>
                        </Box>
                        <Box className={`${classes.countinueBtnContainer}`}>
                            <Button className={`${classes.continueBtnadmin}`} data-test-id='continue-modal-button' onClick={this.handleContinue}>Continue</Button>
                        </Box>
                    </Box>
                </Modal>
            </Layout>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export const OpportunitiesStyle = () => ({
    root:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"10px"
        },
        "& ul li":{
            borderRadius:"8px",
            fontSize: "14px",
            padding:"9px 20px"
        },
        "& ul li:hover, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)"
        },
    },
    mainContainer: {
        padding: '0px'
    },
    fieldMainBox: {
        padding: '0px'
    },
    admininputTxtMain: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        boxSizing:"border-box",

        " & .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded ":{
            opacity: 1,
            transform: 'none',
            minWidth: '200px',
            transition: 'opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '169px',
            left: '202px',
            transformOrigin: '0px 26px',
            color:"red"
        }   
        
    },
    admininputTxtStge: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        boxSizing:"border-box",
        position:"relative",     
        " & .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded ":{
            opacity: 1,
            transform: 'none',
            minWidth: '200px',
            transition: 'opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '169px',
            left: '202px',
            transformOrigin: '0px 26px',
            color:"red"
        }   
        
    },
    errormassagetext: {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: '400',
        marginTop: '5px',
    },
    adminFieldMainBox: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
    },
    adminFiledGroupBox: {
        paddingBottom: "15px",
        width: "50%",
        '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
            opacity: 1,
            transform: 'none',
            minWidth: '200px',
            transition: 'opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            top: '169px',
            left: '202px',
            transformOrigin: '0px 26px',
            color: 'red',
          },
    },
    adminFiledGroupBoxNotes: {
        paddingBottom: "15px",
        width: "100%",
    },
    emailadminLabelText: {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    requireColor: {
        color: '#DC2626',
    },
    buttonFlex: {
        display: 'flex',
        padding: '0px',
        gap: '16px',
    },
    '@media (max-width: 601px)': {
        adminFieldMainBox: {
            display:'block'
        },
        adminFiledGroupBox: {
            width: '100%'
        },
        buttonFlex: {
            justifyContent: 'center'
        }
    },
    SaveBtn: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: "700",
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: '7px 12px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        width: '71px',
        height: '44px'
    },
    SaveNewBtn: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '10px',
        color: "#000000",
        fontWeight: "700",
        textTransform: "none",
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        height: "44px",
        width: "142px"
    },
    CancelButton: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '7px 12px',
        color: "#000000",
        fontWeight: "700",
        textTransform: "capitalize",
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        height: "44px",
        width: "87px"
    },

    countryLoginInput: {
        width: '100% !important',
        boxSizing:"border-box",
        border: "1px solid #dfdfdf",
        padding: "5px 7px 5px 0px",
        borderRadius: "8px",
        fontFamily: "'Inter', sans-serif",
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
            paddingRight: "25px",
            fontSize: "16px",
            borderBottom: "none",
            paddingLeft: "10px",
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInput-underline::before': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline::after': {
            borderBottom: "0px",
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: "0px",
        },
        '&. MuiInputBase-fullWidth': {
            width: '95%'
        }
    },
    searchInpUnd: {
        padding: "0 5px",
        '& .MuiFilledInput-underline:after': {
            borderBottom: '0px',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderBottom: '0px',
        },
        '&. MuiInputBase-fullWidth': {
            width: '95%'
        }
    },
    inputAdornmentStyle: {
        color: "gray"
    },
    modaladminContainer: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '443px',
        height: '312px',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
    },
    closeadminimg: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
        position:"absolute",
        top:"20px",
        right:"20px"
    },
    successImgStyleadmin: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
    },
    countinueBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
    },
    continueBtnadmin: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '104px',
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },
    centeradminTxt: {
        display: 'flex',
        justifyCOntent: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
    },
    successTxtadmin: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
        color: '#0A0528',
        width:"231px",
        textAlign:"center"
    },
    descriptionTxtadmin: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: "22px",
        color: '#64748B',
        marginTop: '12px',
        display:"flex",
        alignItems:"center"
    },
    opportunityIdText:{
      color:"rgba(0, 0, 0, 1)",
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: "22px",
      marginLeft: "5px"
    },

})

const StyledPaper = styled(Paper)({
    "&": {
        padding:"10px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& ul":{
        padding:"10px 5px"
    },
    "& ul li":{
        borderRadius:"8px",
        fontSize: "14px",
        padding:"9px 20px"
    },
    "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
        backgroundColor:"rgba(241, 245, 249, 1)"
    },
});

//@ts-ignore
export default withStyles(OpportunitiesStyle)(CreateOpportunitiesBlock);
// Customizable Area End