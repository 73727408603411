import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Box, Menu, MenuItem, Tooltip, Typography, withStyles } from "@material-ui/core";

type ClassNameMap<ClassKey extends string = string> = Record<ClassKey, string>;

interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap;
}

interface S {
    sidebarHoverIcon: number | null;
    anchorEl: null | HTMLElement;
    open: boolean;
    useranchorEl: null | HTMLElement;
    salesanchorEl: null | HTMLElement;
    commissionAnchorEl: null | HTMLElement;
    legalAnchorEl: null | HTMLElement;
    projectAnchorEl: null | HTMLElement;
    isCommissionMenuOpen: boolean;
    isProjectMenuOpen: boolean;
    useropen: boolean;
    salesopen: boolean;
    localStorageData: string;
    initial: string;
    isDealRegistraion: boolean;
    dealRegisrationAnchorEl: null | HTMLElement;
}

interface SS {
}

class SideBar extends BlockComponent<Props, S, SS> {

    static instance: SideBar;
    dashboardList: any[];
    dashboardUserList: any[];


    constructor(props: Props) {
        super(props);
        SideBar.instance = this;

        this.state = {
            sidebarHoverIcon: null,
            anchorEl: null,
            open: false,
            useranchorEl: null,
            salesanchorEl: null,
            commissionAnchorEl: null,
            projectAnchorEl: null,
            legalAnchorEl: null,
            isCommissionMenuOpen: false,
            isProjectMenuOpen: false,
            useropen: false,
            salesopen: false,
            localStorageData: '',
            initial: '',
            isDealRegistraion:false,
            dealRegisrationAnchorEl:null
        };

        // Customizable Area Start
        this.dashboardList = [
            {
                icon: require("./Dashboard.svg"),
                hoverIcon: require("./DashboardHover.svg"),
                lable: 'Dashboard',
                routes: 'Dashboard',
                showPopup: false
            },
            {
                icon: require("./DealRegistration.svg"),
                hoverIcon: require("./DealRegistrationHover.svg"),
                lable: 'Deal Registration',
                routes: 'OpportunityRegistration',
                showPopup: true
            },
            {
                icon: require("./Commission.svg"),
                hoverIcon: require("./CommissionHover.svg"),
                lable: 'Commission',
                routes: 'Cfcomissioncalculator2',
                showPopup: true,
            },
            {
                icon: require("./SalesEnablement.svg"),
                hoverIcon: require("./SalesEnablementHover.svg"),
                lable: 'Sales Enablement',
                routes: 'SalesEnablements',
                showPopup: false
            },
            {
                icon: require("./ProjectPrice.svg"),
                hoverIcon: require("./ProjectPriceHover.svg"),
                lable: 'Project Price',
                routes: 'Cfleadmanagementandopportunity',
                showPopup: true
            },
            {
                icon: require("./Users.svg"),
                hoverIcon: require("./UsersHover.svg"),
                lable: 'Users',
                routes: 'Dashboard',
                showPopup: true,
            },
            {
                icon: require("./Legal.svg"),
                hoverIcon: require("./LegalHover.svg"),
                lable: 'Legal',
                routes: 'Dashboard',
                showPopup: true
            },
        ];

        this.dashboardUserList = [
            {
                icon: require("./Dashboard.svg"),
                hoverIcon: require("./DashboardHover.svg"),
                lable: 'Dashboard',
                routes: 'Dashboard',
                showPopup: false
            },
            {
                icon: require("./DealRegistration.svg"),
                hoverIcon: require("./DealRegistrationHover.svg"),
                lable: 'Deal Registration',
                routes: 'OpportunityRegistration',
                showPopup: true
            },
            {
                icon: require("./Commission.svg"),
                hoverIcon: require("./CommissionHover.svg"),
                lable: 'Commission',
                routes: 'Cfcomissioncalculator2',
                showPopup: true,
            },
            {
                icon: require("./SalesEnablement.svg"),
                hoverIcon: require("./SalesEnablementHover.svg"),
                lable: 'Sales Enablement',
                routes: 'SalesEnablements',
                showPopup: false
            },
            {
                icon: require("./ProjectPrice.svg"),
                hoverIcon: require("./ProjectPriceHover.svg"),
                lable: 'Project Price',
                routes: 'Cfleadmanagementandopportunity',
                showPopup: true
            },
            {
                icon: require("./Legal.svg"),
                hoverIcon: require("./LegalHover.svg"),
                lable: 'Legal',
                routes: 'Dashboard',
                showPopup: true
            },
        ];
        // Customizable Area End
    }
    // Customizable Area Start
    handelMouseEnter = (index: number) => {
        this.setState({
            sidebarHoverIcon: index,
        })
    }


    handelMouseLeave = (index: number) => {
        this.setState({
            sidebarHoverIcon: null,
        })
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false,
        })
    }
    profileNavigate = {
        onClick: () => {
            const userData = sessionStorage.getItem('token')
            const parseData = JSON.parse(userData as string)

            if (parseData.account.data.attributes.user_type === "organization_admin") {
                this.props.navigation?.navigate('AdminProfile')
            } else {
                this.props.navigation?.navigate('ProfileBio')
            }

            this.handleClose()
        }
    }
    handelonClick = {
        onClick: (event: React.MouseEvent<HTMLElement>) => {
            this.setState({
                anchorEl: event.currentTarget,
                open: true,
            })
        }
    }

    handelNavigate = (event: React.MouseEvent<HTMLElement>, routeName: string, lableName: string, showPopup: boolean) => {
        if (showPopup) {
            if (lableName == 'Users') {
                this.handeluserClick(event);
            }
            if(lableName === 'Project Price'){
                this.setState({
                    projectAnchorEl: event.currentTarget,
                    isProjectMenuOpen: true
                })
            }
            if (lableName === "Commission") {
                this.setState({
                    commissionAnchorEl: event.currentTarget,
                    isCommissionMenuOpen: true
                })
            }
            if (lableName === "Legal") {
                this.setState({
                    legalAnchorEl: event.currentTarget,
                })
            }
            if (lableName === "Deal Registration") {
                this.setState({
                    dealRegisrationAnchorEl: event.currentTarget,
                    isDealRegistraion: true,
                })
            }
        } else {
            if (lableName === "Sales Enablement") {
                this.setState({
                    salesanchorEl: event.currentTarget,
                    salesopen: true
                })
            }
             else {
                this.props.navigation?.navigate(routeName)
                sessionStorage.setItem("previousRoute", routeName)
            }
        }
    }


    handeluserClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            useranchorEl: event.currentTarget,
            useropen: true,
        })
    }

    handleuserClose = () => {
        this.setState({
            useranchorEl: null,
            useropen: false,
        })
    }


    handelsalesClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            salesanchorEl: event.currentTarget,
            salesopen: true,
        })
    }

    handlesalesClose = () => {
        this.setState({
            salesanchorEl: null,
            salesopen: false,
        })
    }

    handleCommissionMenuClose = () => {
        this.setState({
            commissionAnchorEl: null,
            isCommissionMenuOpen: false,
            projectAnchorEl: null,
            isProjectMenuOpen: false
        })
    }

    handleLegalMenuClose = () => {
        this.setState({
            legalAnchorEl: null,
        })
    }

    userOnboardNavigate = {
        onClick: () => {
            this.props.navigation?.navigate('UserOnBoard')
            this.handleuserClose()
        }
    }
    manageUsertNavigate = {
        onClick: () => {
            this.props.navigation?.navigate('ManageUser')
            this.handleuserClose()
        }
    }

    handleSalesMenu =(value: number)=>{
        sessionStorage.setItem("routeDetails", JSON.stringify({"routeName": "Sales Enablement", "value": value}));
        this.props.navigation?.navigate('SalesEnablements')
        this.setState({salesopen: false})
    }

    handleNavigate = (link: string) => {
        this.props.navigation?.navigate(link)
        this.handleCommissionMenuClose()
    }

    handelNavigateLoginScreen = () => {
        sessionStorage.clear()
        this.props.navigation?.navigate('EmailAccountLoginBlock')
    }

    onNavigate = () => {
        this.handleClose()
        this.props.navigation?.navigate('UpdatePassword');
    }
    onMyRequest = () => {
        this.handleClose()
        this.props.navigation?.navigate('MyRequests');
    }

    handleDealRegistartionNavigate = (link: string) => {
        this.props.navigation?.navigate(link)
        this.handleDealRegistrationMenuClose()
        sessionStorage.setItem("DealRegistration", "deal")
    }

    handleOppRegistartionNavigate = (link: string) => {
        this.props.navigation?.navigate(link)
        this.handleDealRegistrationMenuClose()
        sessionStorage.setItem("DealRegistration",  "opportunity")
    }

    handleDealRegistrationMenuClose = () => {
        this.setState({
            dealRegisrationAnchorEl: null,
            isDealRegistraion: false
        })
    }

    async componentDidMount() {
        const userData = sessionStorage.getItem('token')
        const parseData = JSON.parse(userData as string)
        this.setState({
            localStorageData: parseData.account.data.attributes.user_type,
            initial: parseData.account.data.attributes.email.charAt(0).toUpperCase()
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Box className={`${classes.mainBoxContainer}`}>
                <Box className={`${classes.mainBoxFlexContainer}`}>
                    <Box className={`${classes.imgContainer}`}>
                        <img src={require("./sustainext_Logo.png")} />
                    </Box>
                    {(this.state.localStorageData !== "organization_admin" ? this.dashboardUserList : this.dashboardList)?.map((item, i) => (
                        <>
                            <Box key={item.lable} onMouseEnter={() => this.handelMouseEnter(i)} onMouseLeave={() => this.handelMouseLeave(i)} className={`${classes.mainflexContainer}`} onClick={(e) => {
                                this.handelNavigate(e, item.routes, item.lable, item.showPopup)
                            }}>
                                <Box className={`${classes.iconPointer}`}>
                                <Tooltip title={item.lable} placement="right" className={`${classes.iconToolTip}`}>
                                    <img src={this.state.sidebarHoverIcon == i ? item.hoverIcon : item.icon} alt="item.lable" />
                                </Tooltip>
                                </Box>
                                <Typography className={`${classes.dashboardTxt}`}>{item.lable}</Typography>
                            </Box>
                        </>
                    ))}
                </Box>

                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={this.state.useranchorEl}
                    open={this.state.useropen}
                    onClose={this.handleuserClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    className={`${classes.usermenuItem}`}
                    style={{
                        left: '42px',
                        top: '0',
                    }}
                >
                    <MenuItem {...this.userOnboardNavigate}> Onboard New User</MenuItem>
                    <MenuItem {...this.manageUsertNavigate}> Manage Users</MenuItem>
                </Menu>

                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={this.state.salesanchorEl}
                    open={this.state.salesopen}
                    onClose={this.handlesalesClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    className={`${classes.usermenuItem}`}
                    style={{
                        left: '7px',
                        top: '0',
                    }}
                >
                    <MenuItem onClick={()=>this.handleSalesMenu(6)}>Corporate Presentation</MenuItem>
                    <MenuItem onClick={()=>this.handleSalesMenu(7)}>Sales Presentations</MenuItem>
                    <MenuItem onClick={()=>this.handleSalesMenu(8)}>Digital Marketing</MenuItem>
                    <MenuItem onClick={()=>this.handleSalesMenu(9)}>Resources</MenuItem>
                    <MenuItem onClick={()=>this.handleSalesMenu(10)}>Marketing Request</MenuItem>
                </Menu>

                <Menu
                    anchorEl={this.state.commissionAnchorEl}
                    open={this.state.isCommissionMenuOpen}
                    onClose={this.handleCommissionMenuClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    className={`${classes.usermenuItem}`}
                    style={{
                        left: '23px',
                        top: '0',
                    }}
                >
                    {/* <MenuItem onClick={() => this.handleNavigate("Cfcommissioncalculator2")}> Commission Calculator</MenuItem> */}
                    <MenuItem> Coming Soon </MenuItem>
                </Menu>

                <Menu
                    anchorEl={this.state.projectAnchorEl}
                    open={this.state.isProjectMenuOpen}
                    onClose={this.handleCommissionMenuClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    className={`${classes.usermenuItem}`}
                    style={{
                        left: '22px',
                        top: '0',
                    }}
                >
                    <MenuItem> Coming Soon </MenuItem>
                </Menu>

                <Menu
                    anchorEl={this.state.legalAnchorEl}
                    open={this.state.legalAnchorEl !== null}
                    onClose={this.handleLegalMenuClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    className={`${classes.usermenuItem}`}
                    style={{
                        left: '43px',
                        top: '0',
                    }}
                >
                    <MenuItem onClick={() => this.handleNavigate("PrivacyPolicy")}> Privacy Policy</MenuItem>
                    <MenuItem onClick={() => this.handleNavigate("TermsAndConditions")}> Terms and Conditions</MenuItem>
                </Menu>

                <Box>
                    <Box className={`${classes.profileBgColor}`} {...this.handelonClick}>
                        <Typography className={`${classes.profileImgTxt}`}>{this.state.initial}</Typography>
                    </Box>

                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={this.state.open}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        className={`${classes.menuItem}`}
                        style={{
                            left: '33px',
                            top: '16px',
                        }}
                    >
                        <MenuItem {...this.profileNavigate}> <img src={require("./person_outline.svg")} alt="" /> My Profile</MenuItem>
                        <MenuItem onClick={this.onMyRequest}> <img src={require("./tab.svg")} alt="" />  My Request</MenuItem>
                        <MenuItem onClick={this.onNavigate}> <img src={require("./lock_open.svg")} alt="" /> Update Password</MenuItem>
                        <MenuItem onClick={this.handelNavigateLoginScreen}> <img src={require("./play_for_work.svg")} alt="" /> Log Out</MenuItem>
                    </Menu>

                    <Menu
                        anchorEl={this.state.dealRegisrationAnchorEl}
                        open={this.state.isDealRegistraion}
                        onClose={this.handleDealRegistrationMenuClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                        className={`${classes.usermenuItem}`}
                        style={{
                            left: '8px',
                            top: '0',
                        }}
                    >
                        <MenuItem onClick={() => this.handleOppRegistartionNavigate("OpportunityRegistration")}>Opportunity</MenuItem>
                        <MenuItem onClick={() => this.handleDealRegistartionNavigate("OpportunityRegistration")}> Deal</MenuItem>
                    </Menu>
                </Box>
            </Box>
        )
    }

}

export const SideBarStyles = (theme: any) => ({
    menuItem: {
        '& .MuiPaper-root': {
            background: 'linear-gradient(180deg, #394060 0%, #19203C 100%)',
            width: '241px',
            height: '184px',
            color: '#FFFFFF',
            padding: '12px',
        },
        '& .MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
        },
        '& .MuiListItem-root': {
            gap: '10px',
            '&:hover': {
                backgroundImage: 'linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%)',
                backgroundSize: '100%',
                backgroundRepeat: 'repeat',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            },
        },
    },
    usermenuItem: {
        '& .MuiPaper-root': {
            background: 'linear-gradient(180deg, #394060 0%, #19203C 100%)',
            width: '265px',
            height: 'auto',
            color: '#FFFFFF',
            padding: '12px',
        },
        '& .MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
        },
        '& .MuiListItem-root': {
            gap: '10px',
            '&:hover': {
                backgroundImage: 'linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%)',
                backgroundSize: '100%',
                backgroundRepeat: 'repeat',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            },
        },
    },
    mainBoxContainer: {
        background: '#0A0528',
        width: '120px',
        height: '100vh',
        maxHeight: "1024px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        top: '0',
        minHeight:'100vh',
        
    },
    mainBoxFlexContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px',
    },
    imgContainer: {
        marginTop: '18px',
        '& img': {
            width: '92px',
            cursore: 'pointer',
        },
    },
    mainflexContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursore: 'pointer',
    },
    dashboardTxt: {
        fontFamily: 'Inter !important',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#FFFFFF',
        marginTop: '2px',
        "@media (max-height: 670px)":{
            display:'none'
            }
    },
    iconPointer: {
        cursor: 'pointer',
    },
    profileBgColor: {
        background: '#CBD5E1',
        border: '3px solid #F8FAFC',
        borderRadius: '100px',
        width: '48px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '24px',
        cursor: 'pointer',
    },
    profileImgTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '22px',
    },
})

//@ts-ignore
export default withStyles(SideBarStyles)(SideBar);