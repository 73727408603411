// Customizable Area Start
import { Box, Button, Grid, IconButton, Input, InputAdornment, Menu, MenuItem, Modal, Select, TableFooter, Typography, withStyles } from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AddCircleOutline, MoreVert } from "@material-ui/icons";
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FilterList from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import React from "react";
import Layout from "../../../components/src/Layout.web";
import { dateFormatOppAllList, formatString } from './constants';
import OpportunitiesListingController, { Props } from "./OpportunitiesListingController.web";
import { closeImg, imagewithoutApi, opportunitiesIon} from './assets';
export const configJSON = require("./config");
export const tableStyleCss = require("./Components/tableCssStyle");

import { OpportunitiesField, opportunityFieldStyles, renderStatus } from "./Components/OpportunitiesTable.web";
import { styled} from "@material-ui/core/styles";
import { cheackBox, cheackCheckbox } from '../../email-account-login/src/assets';
import { IOpportunitiesDealListAttributeAll } from "./types";
import CircularProgress from '@material-ui/core/CircularProgress';
import { OpportunitiesFilterBlock } from "./Components/OpportunitiesFilter.web";
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const BpIconList = styled('span')(({ theme }) => ({
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
          `url(${cheackBox})`,
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: 'transparent',
  },
}));

const BpCheckedIconList = styled(BpIconList)({
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
          `url(${cheackCheckbox})`,
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: 'transparent',
  },
});

export class OpportunitiesListBlock extends OpportunitiesListingController {
 
  constructor(props: Props) {
    super(props);
  }
 

  renderSortingIcon(column:any, direction:any) {
    const { sortBy, sortDirection } = this.state;
    const isActive = sortBy === column && sortDirection === direction;
    return (
      <IconButton
        size="small"
        data-test-id="UpDownicon-handleSortClickID"
        onClick={() => this.handleSortClick(column)}
        style={{ color: isActive ? '#000' : '#999', marginBottom: "-11px" ,marginTop: "-12px"}}>
        {direction === 'asc' ? <ArrowDropUp  style={{ fontSize: "22px" }} /> : <ArrowDropDown   style={{ fontSize: "22px" }}  />}
      </IconButton>
    );
  }

  renderFilteredBox =() => {
    const {filterBarData } =this.state

    return (
      <Grid container spacing={1} >
      <Grid item xs="auto" sm={12} md={12} lg={12} className="filterAlertBox" >
       <Box className="filterClearAlertBox">
          <Typography>Clear All Filter</Typography>
          <CloseIcon  onClick={() => this.handleClearfilter("remove_all","null")}  type="button" data-test-id="handleClearAllFilterId" />
        </Box>
      {filterBarData.map((filterLabel, ) => (
        <Box className="filterDateAlertBox">
          <Typography key={filterLabel.key}>{filterLabel?.label}</Typography>
          <CloseIcon onClick={ ()=> this.handleClearfilter(filterLabel?.key,"null")  }  data-test-id="handleClearAllFilterIdSecund"/>
        </Box>
       ))}
      </Grid>
    </Grid>
      
    )
  }
  renderTableData = () => {
    const { listType } = this.state;
    const columnName = listType ? 'opportunity_name' : 'deal_name';

    return (
      <Box className="table-wrapper">
        <Box className="table-wrapper-area" >
          <Box className="table-container">
            <Table className="table">
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell className="table-cell">
                    <Box className="table-header-cell-text">
                      <Typography>
                          { listType ? "Opportunity ID" : "Deal ID" }  
                      </Typography>
                    </Box> 
                  </TableCell>
                  <TableCell className="table-cell">
                    <Box    className="table-header-cell-text">
                      <Typography onClick={ () => {this.handleSortClick(columnName)}}  data-test-id="icon-handleSortClickID"> 
                          { listType ? " Opportunity Name" : "Deal Name" }
                      </Typography>
                        <Box className="tableCellSortIcon" >
                          {this.renderSortingIcon(columnName, 'asc')}
                          {this.renderSortingIcon(columnName, 'desc')}
                        </Box>
                    
                    </Box>
                  </TableCell>
                  { listType &&
                    <TableCell className="table-cell">
                      <Box className="table-header-cell-text">
                          <Typography data-test-id="StatushandleSearchId" onClick={ () => {this.handleSortClick("status")}}> 
                            Status
                          </Typography>
                          <Box className="tableCellSortIcon" >
                            {this.renderSortingIcon('status', 'asc')}
                            {this.renderSortingIcon('status', 'desc')}
                          </Box>
                      </Box>
                    </TableCell>  
                  }
                  <TableCell className="table-cell">
                    <Box className="table-header-cell-text"> Stage </Box>
                  </TableCell>
                  <TableCell className="table-cell">
                    <Box className="table-header-cell-text">Tier to Partner</Box>
                  </TableCell>
                  <TableCell className="table-cell">
                    <Box className="table-header-cell-text">
                        <Typography data-test-id="handleFilterIdECN" onClick={ () => {this.handleSortClick("end_customer_name")}}> 
                          End Customer Account
                        </Typography>
                        <Box className="tableCellSortIcon"  >
                          {this.renderSortingIcon('end_customer_name', 'asc')}
                          {this.renderSortingIcon('end_customer_name', 'desc')}
                        </Box>
                    </Box>
                  </TableCell>
                  <TableCell className="table-cell">
                    <Box className="table-header-cell-text">
                       <Typography data-test-id="handleFilterIdAmount"  onClick={ () => {this.handleSortClick("amount")}}> 
                          Amount
                        </Typography>
                        <Box className="tableCellSortIcon"  >
                          {this.renderSortingIcon('amount', 'asc')}
                          {this.renderSortingIcon('amount', 'desc')}
                        </Box>
                    </Box>
                    </TableCell>
                    <TableCell className="table-cell">
                      <Box id="handleSortDealClickId"  className="table-header-cell-text">
                          <Typography data-test-id="proposedAmountAllOppId"   onClick={ () => {this.handleSortClick("proposed_amount")}}> 
                            Proposed Amount
                          </Typography> 
                          <Box className="tableCellSortIcon" >
                            {this.renderSortingIcon('proposed_amount', 'asc')}
                            {this.renderSortingIcon('proposed_amount', 'desc')}
                          </Box>
                      
                      </Box>
                    </TableCell>
                    <TableCell className="table-cell">
                      <Box id="handleSortDealClickId" className="table-header-cell-text">
                        <Typography data-test-id="expectedRevenueAllOppId"  onClick={ () => {this.handleSortClick("expected_revenue")}}> 
                          Expected Revenue
                        </Typography> 
                        <Box className="tableCellSortIcon" >
                          {this.renderSortingIcon('expected_revenue', 'asc')}
                          {this.renderSortingIcon('expected_revenue', 'desc')}
                        </Box>        
                      </Box>
                    </TableCell>
                    <TableCell className="table-cell">
                      <Box id="handleSortDealClickId"   className="table-header-cell-text">
                        <Typography data-test-id="createdOnIdAllOppId" onClick={ () => {this.handleSortClick("created_date")}}> 
                          Created On
                        </Typography> 
                          <Box className="tableCellSortIcon" >
                            {this.renderSortingIcon('created_date', 'asc')}
                            {this.renderSortingIcon('created_date', 'desc')}
                          </Box>
                      </Box>
                    </TableCell>
                    <TableCell className="table-cell">
                      <Box id="handleSortDealClickId"  className="table-header-cell-text">
                        <Typography data-test-id="CloseOnIdAllOppId" onClick={ () => {this.handleSortClick("close_date")}}> 
                          Close On
                        </Typography> 
                          <Box className="tableCellSortIcon" >
                            {this.renderSortingIcon('close_on', 'asc')}
                            {this.renderSortingIcon('close_on', 'desc')}
                          </Box>
                      </Box>
                    </TableCell>
                    <TableCell className="table-cell">
                      <Box className="table-header-cell-text">City </Box>
                    </TableCell>
                    { listType && 
                       <TableCell className="table-cell table-action"> </TableCell>
                    }
                  </TableRow>
              </TableHead>
              { this.renderTableBody() }
            </Table> 
          </Box>
          <Table className="table-footer">
              <TableFooter>
                  {this.renderPagination()}
              </TableFooter>
            </Table> 
        </Box>
      </Box>
    )
  }

  renderPagination = () => {
    const {pageNumberSet,pagneNumberValidation,currentPage, perPage, opportunityListMeta, numberOfButtons} = this.state;

    const till = perPage * ( currentPage - 1) + opportunityListMeta?.total_opportunity_count_per_page
    const currPg = currentPage === 1 ? perPage : till
    const showingTill = opportunityListMeta?.total_opportunity_count < perPage ? opportunityListMeta?.total_opportunity_count : currPg
      
    return (
      <TableRow className="pagination-row"  >
      <TableCell >
        <div className="colSpan-Style" >
          <div  className="page-view-count" >
            <Typography>View</Typography>
            <div >
                <Select 
                  onChange={(event) => this.handlePagination(event)} data-test-id="handlePaginationId"
                  value={perPage} fullWidth disableUnderline className="inputTxtMainSelect"
                  MenuProps={{
                    classes:{paper:this.props.classes.rootMenuItem},
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    }
                }}
                >
                  {configJSON.perPageView.map((value:any) => (
                    <MenuItem key={value} value={value}  >
                        {value}
                    </MenuItem>
                  ))}
                </Select>
            </div>
            <Typography> records per page</Typography>

          </div>
          <div className="page-entries" >
            <Typography>
              Showing {" "}
              {currentPage === 1 ? '1' : ((currentPage-1)*perPage + 1)}
              {" "} to {" "}
              {showingTill}
              {" "} of {" "} 
              {opportunityListMeta?.total_opportunity_count}
              {" "} entries
            </Typography>
          </div>
          <div className="page-view-count-btn"  >
            <Button onClick={()=> {this.handlePageNextPreviousBtn("previousPage")}} data-test-id="pageButtonPreviousID"   variant="outlined" className="paginationNext-btn"  >
              <ArrowBack className="pagination-ArrowBack"  />
            </Button>
                <Typography> 
                  <span className="pagination-btn-text" >Page </span> 
                    <Input
                      data-test-id="pageNumberId"
                      placeholder={"0"}
                      fullWidth={true}
                      value={pageNumberSet }
                      onChange={this.handlePageNumberInputChange}
                      disableUnderline
                      className={ pagneNumberValidation ? "reddrawerinputTxtMain":"drawerinputTxtMain"} 
                    />
                  <span className="pagination-btn-text">of</span> 
                   {numberOfButtons}
                </Typography>
              <Button onClick={()=> {this.handlePageNextPreviousBtn("nextPage")}}  data-test-id="pageButtonNextID" variant="outlined" className="paginationNext-btn" 
            ><ArrowForwardIcon  className="pagination-ArrowForwardIcon" />
            </Button>
          </div>
        </div>
      </TableCell>
    </TableRow>
    )
  }

  renderTableBody = () => {
    const { opportunityList, listType } = this.state;
    const { classes }: any = this.props

    return (
      <TableBody>
      {
        opportunityList.map((item: IOpportunitiesDealListAttributeAll) => {
          const uniqueKey =listType ? item.attributes.opportunity_id : item.attributes.deal_id
          return (
            <TableRow key={uniqueKey} className="table-body-row">
              <TableCell data-test-id="oppIdLink" onClick={() => this.handleGetOpportunitesRecord(item)}>
                <Typography className="opportunity-id">
                  {listType ?
                    item.attributes.opportunity_id
                    : item.attributes.deal_id
                  }
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className="blue-text">
                  {listType ?
                    item.attributes.opportunity_name
                    : item.attributes.deal_name
                  }
                </Typography>
              </TableCell>
              {listType && (
                <TableCell>
                  <Typography className="status" style={{ ...opportunityFieldStyles[item.attributes.status] }}>
                  { 
                      renderStatus(item.attributes.status,item.attributes.rejection_reason,classes)
                  }
                                                    
                  </Typography>
                </TableCell>
              )}
              <TableCell>
                <Typography style={{ ...opportunityFieldStyles[item.attributes.stage] }}>{formatString(item.attributes.stage)}</Typography>
              </TableCell>
              <TableCell>
                <Typography className="blue-text">{item.attributes.account_name}</Typography>
              </TableCell>
              <TableCell>
                <Typography className="common-text">{item.attributes.contact_name}</Typography>
              </TableCell>
              <TableCell>
                <Typography className="common-text">{item.attributes.currency_symbol||'$'}{item.attributes.amount}</Typography>
              </TableCell>
              <TableCell>
                <Typography className="common-text">
                  {item.attributes.proposed_amount != null && (
                    <Typography className="common-text">
                      {parseInt(item.attributes.proposed_amount) > 0 ? `${item.attributes.currency_symbol||'$'}  ${item.attributes.proposed_amount || " "}` : ""}
                    </Typography>
                  )}
                </Typography>
              </TableCell>
              <TableCell>
              <Typography className="common-text ">{item.attributes.currency_symbol||'$'}{item.attributes.expected_revenue}</Typography>
              </TableCell>
              <TableCell>
                <Typography className="common-text">{dateFormatOppAllList(item.attributes.created_at,false)}</Typography>
              </TableCell>
              <TableCell>
                <Typography className="common-text">{dateFormatOppAllList(item.attributes.closing_date,true)}</Typography>
              </TableCell>
              <TableCell>
                <Typography className="common-text">{item.attributes.city}</Typography>
              </TableCell>
              { listType && (
                  <TableCell className="table-action">
                    {  item.attributes.status === "proposed"  && (
                      <MoreVert data-test-id="handledOpportunityAction" onClick={(event: any) => this.OppDealAction(event, item)} />
                    )} 
                  </TableCell>
              )}
            </TableRow>
          )
        })
      }
      </TableBody>
    )
  }

  render() {
    // Customizable Area Start 
    const { classes }:any = this.props
    const {filteredBoxShow, filterBarData ,isLoadingOppDeal, anchor, toggle, opportunityList, listType  ,opportunityDealIdName } = this.state;

    const breadcrumb = listType  ? configJSON.OpportunitiesList : configJSON.DealList  
    const pageHeading = listType ? "Total Registered Opportunity " :  "Total Registered Deal" 
    
    // Customizable Area End

    return (
      // Customizable Area Start 
      <>
        <Layout
          breadcrumb={breadcrumb}
          navigation={this.props.navigation}
        >

          <OpportunitiesField>
              <Box className="table-actions-row">
              <Typography className="view-all-title">
                <img src={opportunitiesIon} alt="" />
                 { pageHeading } 
              </Typography>
              <Box className="actions">
                  <Input
                    onChange={(event) => this.handleInputChangeOpp(event.target.value)}
                    onBlur={this.handleBlurEvent}
                    data-test-id="search-input"
                    placeholder="Search"
                    fullWidth={true}
                    disableUnderline
                    className={this.state.listSearchValueError ? classes.reddrawerSearchField : classes.drawerSearchField}
                    value={this.state.listSearchValue}
                    startAdornment={<InputAdornment position="start">
                      <Search style={opportunityFieldStyles.colorGray} />
                    </InputAdornment>}
                  />
               
                <Box className="actionchild">
                  {listType &&
                    <Button
                    variant="outlined"
                    className="add-new-button"
                    startIcon={<AddCircleOutline />}
                    onClick={this.handleNavigation}  
                    data-test-id="handleAddNewID"  
                    >
                      Add New
                    </Button>
                    
                    }
                    <Box className="button-container">
                      <Button onClick={this.toggleDrawer(true)} variant="outlined" data-test-id="dealDrawerOpenID"  id="handleFilterBtnId" className="filter-button">
                        <FilterList style={opportunityFieldStyles.colorGray} />
                        {filteredBoxShow && filterBarData.length > 0  && (
                          <span className="red-dot"></span>
                          )}
                      </Button>
                    </Box>
                </Box>
              </Box>
            </Box>
            {isLoadingOppDeal ? (
              <Box className="table-loader" >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {filteredBoxShow && filterBarData.length > 0  && (
                  this.renderFilteredBox()
                )}
                { opportunityList && opportunityList.length > 0
                  ? this.renderTableData()
                  : (
                    <Box className="tableRecordsNotMainBox">
                      <img src={imagewithoutApi} alt="" />
                      <Typography className="tableRecordsNotTitile" >
                        Nothing to show
                      </Typography>
                      <Typography className="tableRecordsNotMesg"  >
                        There are no entries created for now                                </Typography>
                    </Box>

                  )
                }

              </>
            )}
            
            <Drawer
              anchor={anchor}
              open={toggle}
              onClose={this.toggleDrawer(false)}
              PaperProps={{
                style: {
                  background: 'transparent',
                  boxShadow: 'none', 
                  width: "465px",
                },
              }}>

              <Box className={`${classes.drawerMainBox}`}>
                <Box onClick={this.toggleDrawer(false)}  className={`${classes.drawerMainLeftBox}`}>
                   <KeyboardArrowRightIcon  />
                </Box>
                <Box className={`${classes.drawerMainRightBox}`}>
                  <OpportunitiesFilterBlock 
                    toggleFunction={this.toggleDrawer(false)}
                    navigation={undefined} id={""} classes={classes}
                    parentState={this.state}
                    handlefilterApply={this.handleFilterApply}
                    handleFilterFieldCheck={this.handleFilterFieldCheck}
                    handleTextInputChange={this.handleTextInputChange}
                    handleSelectInputChange={this.handleSelectInputChange}
                    handleNumberInputChange={this.handleNumberInputChange}
                    handleExpectedRevanuFilter={this.handleExpectedRevanuFilter}
                    handleClearfilter={this.handleClearfilter}
                    updateTypeFilter={this.updateTypeFilter}
                    handleMulipleSelectInputChange={this.handleMulipleSelectInputChange} 
                    handleCurrencySelect={this.handleCurrencySelect}
                    handleDrawerSarch={this.handleDrawerSarch}
                    />

                    
                </Box>
              </Box>
            </Drawer>
            <Menu
              anchorEl={this.state.dealRegisrationAnchorEl}
              open={this.state.isDealRegistraion}
              onClose={this.handleDealActionMenuClose}
              anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
              }}
              transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
              }}
              className="usermenuItem"
              style={{
                  left: '8px', 
                  top: '0',
              }}
            >
              <MenuItem data-test-id="handleViewDealId" onClick={() => {this.handleProposedAmountModelOpen(); }}  >Submit</MenuItem>
            </Menu>  
            <Modal
              open={this.state.isDeleteModalOpen}
              onClose={this.handleCloseModalOpp}
              data-test-id='success-modal'
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
                <Box  className={`${classes.modaladminContainer}`} >
                    <Box className={`${classes.closeadminimg}`} data-test-id="handleCloseModalOppID"onClick={this.handleCloseModalOpp} >
                        <img src={closeImg} alt="" style={{ width: "24px" }} />
                    </Box>
                    <Box className={`${classes.centeradminTxt}`}>
                         <Box className={`${classes.centeradminTxt}`}>
                            <Typography className={`${classes.successTxtadmin}`}>Are you sure you want to submit  </Typography>
                            <Typography className={`${classes.descriptionTxtadmin}`}>Your {listType ? "opportunity":"Deal" } id  <Typography className={`${classes.opportunityIdText}`}>  {opportunityDealIdName}   </Typography></Typography>
                        </Box>
                    </Box>
                    <Box className={`${classes.countinueBtnContainer}`}>
                        <Button className={`${classes.continueBtnadmin}`} onClick={this.handleCloseModalOpp} data-test-id='handleCloseModalOppID'>No</Button>
                        <Button className={`${classes.continueBtnadmin}`} onClick={this.handleProposedAmountRecords} data-test-id='handleDeleteOppRecordsID'>Yes</Button>
                    </Box>
                </Box>
              </Modal>
          </OpportunitiesField>
        </Layout></>
      // Customizable Area End
    )
  }
}



// Customizable Area Start
export default withStyles(tableStyleCss.stylecss)(OpportunitiesListBlock);
// Customizable Area End