import { Button, Typography, styled } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Component } from "react"

type IProps = {
    label: string;
    labelColor: string;
    backgroundColor: string;
    onClick: () => void;
};
type IState = {};

class FilterTag extends Component<IProps, IState>{

    render() {
        const {label, labelColor, backgroundColor, onClick } = this.props;
        return (
            <StyledContainer style={{backgroundColor}} onClick={onClick}>
                <StyledLabel style={{color:labelColor}}>
                    {label} 
                </StyledLabel>
                <Close htmlColor={labelColor} />
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Button)({
    "&": {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        gap:"4px",
        boxSizing:"border-box",
        padding:"6px 8px 6px 12px",
        height:"32px",
        minWidth:"unset",
        textTransform:"capitalize",
        borderRadius:"4px",
        "& svg":{
            width:"16px",
            height:"16px",
            marginLeft:"4px"
        }
    },
    "@media(max-width: 960px)": {
    }
});

const StyledLabel = styled(Typography)({
    "&": {
        fontSize:"14px",
        lineHeight:"22px"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize:"12px",
            lineHeight:"18px"
        }
    }
});

export default FilterTag;