import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import createRequestMessage from "../../../framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
import { IAccordionItem } from "./types.web";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  privacyPolicyList:IAccordionItem[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class PrivacyPolicyBlockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPrivacyPolicyApiCallId:string | null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage)
        // Customizable Area Start
        // Customizable Area End
    ];
  
    this.state = {
      // Customizable Area Start
      token: "",
      privacyPolicyList:[]
       // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getPrivacyPolicyApiCallId = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage),
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage),
        );
        const errorJson = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage),
        );
        if (apiRequestCallId) {
            if (this.getPrivacyPolicyApiCallId) {
                this.getPrivacyPolicyApiCallId = null;
                handleResponseMessage({
                    responseJson,
                    errorJson,
                    onSuccess: () => {
                        this.setState({ privacyPolicyList:responseJson.data.list })
                    },
                    onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                });
            }
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  toggleAccordionStatus = (expanded: boolean, title:string) => {
    this.setState({privacyPolicyList: this.state.privacyPolicyList.map(item => {
      return  item.title === title ? {...item,isExpanded: expanded ? title : false} : {...item}
    })})
  };

  getPrivacyPolicyList = () => {
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;

    createRequestMessage({
        header,
        requestMessage: requestMessage,
        endPoint: `${configJSON.privacyPolicyListEndPoint}`,
        method: configJSON.getApiMethod,
    });
  }

  async componentDidMount() {
    let token = await sessionStorage.getItem("token") || "";
    this.setState({
        token: JSON.parse(token).token
    });
    this.getPrivacyPolicyList();
  }

  // Customizable Area End
}
