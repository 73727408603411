Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postApiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcommissioncalculator2";
exports.labelBodyText = "cfcommissioncalculator2 Body";
exports.partnerDetailsApiEndPoint = "bx_block_calculation/commission_calculators"
exports.saveClientApiEndPoint = "bx_block_calculation/incomes"
exports.statusListEndPoint = "bx_block_calculation/incomes"

exports.btnExampleTitle = "CLICK ME";
exports.acceptRejectEndPoint= "/bx_block_calculation/incomes/set_commission_status"
exports.PutApiMethod = "PUT";

exports.paginationSelectOptions = [
  { label:"10" , value:"10"},
  { label:"20" , value:"20"},
  { label:"50" , value:"50"},
  { label:"100" , value:"100"},
]

exports.statusList = [
  {
    id:"1",
    name:"Mohanlal Vilasrao Patekar",
    amount:"€10,054.57",
    cycle:"€13,354.57",
    date:"30/05/2023"
  },
  {
    id:"2",
    name:"Jitendrasinh Gumansinh Zala",
    amount:"£10,054.57",
    cycle:"£13,354.57",
    date:"30/05/2023"
  },
  {
    id:"3",
    name:"Moazzam Usmanali  Jah",
    amount:"₹10,054.57",
    cycle:"₹13,354.57",
    date:"30/05/2023"
  },
]

exports.clientNameSortingOptions = [
  "A to Z", "Z to A"
]

exports.dateSortingOptions = [
  "Asc" , "Desc"
]

exports.clientNameSortingOptionsApi = {
  "A to Z" : "asc",
  "Z to A" : "desc"
}

exports.datePickerLocale = {
  sunday: 'Sun',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  ok: 'Apply'
};
// Customizable Area End