import { Box, CircularProgress, Table, TableCell, TableRow, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"
import EmptyData from "../../../components/src/EmptyData.web";
import { StyledTableContainer, StyledTableHead } from "./Statistics.web";
import { StyledTableBody } from "./RegisteredOpportunitiesCard.web";
import SelectWithoutLabelWeb from "../../../components/src/SelectWithoutLabel.web";
import {configJSON} from "../../dashboard/src/DashboardController.web"
import PaginationContainer from "../../../components/src/PaginationContainer.web";
import {RevenueData} from "./types"
import { emptySalesVolumeIcon } from "../../../blocks/dashboard/src/assets";
import { formatMoney } from "./constants";

type IProps = {
    handleGetRevenueByEachUser: (item: string,event:string) => void,
    revenuAllData: RevenueData; 
    handlePerPageSelectionRevenueByEU: (item: string,event:string) => void,
    handlePaginationButtonRevenueByEU:(number:number)=>void,
    shownItems:string;
    pageNumberSet:number;
    currentPage:number;
    isTableLoading:boolean;
};

type IState = {
    isOpen:boolean;
    filter:string;
    paginationSelect: string;
};

class RevenueEarnedByEarchUserTable extends Component<IProps, IState>{
    constructor(props:IProps){
        super(props);
        this.state = {
            isOpen:false,
            filter: "monthly",
            paginationSelect:"5"
        }
    }

    render() {
        const {filter } = this.state;
        const {isTableLoading, handleGetRevenueByEachUser,revenuAllData,shownItems,currentPage ,handlePerPageSelectionRevenueByEU, pageNumberSet,handlePaginationButtonRevenueByEU} = this.props 
        return (
            <StyledContainer>
                <Box className="header">
                <Typography className="header-title">Revenue earned by each user</Typography>
                    <Box className="select-container">
                      <SelectWithoutLabelWeb
                          data-test-id="period-select-box-revenue-by-user"
                          data={configJSON.periodsValue}
                          value={filter}
                          onChange={(text)=> { 
                            handleGetRevenueByEachUser(text,"Select");
                            this.setState({ filter: text });
                          }}
                      />
                    </Box>
                </Box>
                { isTableLoading ? <Box className="loading-container"><CircularProgress /></Box> :
                    revenuAllData?.org_user_accounts?.length > 0 ? 
                    <StyledTableContainer className="earned-commission-table-container">
                        <div style={{ maxHeight: '206px', overflowY: 'auto' }}>
                            <Table aria-label="Pricing Table">
                                <StyledTableHead>
                                    <TableRow>
                                        <TableCell align="left">Sr</TableCell>
                                        <TableCell align="left">Partner Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Earned</TableCell>
                                    </TableRow>
                                </StyledTableHead>
                                <StyledTableBody className="earned-commission-table-body">
                                    {revenuAllData.org_user_accounts.map((row,index) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="left">
                                                {(+shownItems * (currentPage - 1)) + (index + 1)}
                                            </TableCell>
                                            <TableCell align="left" style={{ minWidth: "155px"}} >
                                                <Box>{row.first_name + " " + row.last_name}</Box>
                                            </TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{"$"+ formatMoney(row.earned?? "0" ) }</TableCell>
                                        </TableRow>
                                    ))}
                                </StyledTableBody>
                            </Table>
                        </div>

                        <PaginationContainer
                            data-test-id="pagination-container"
                            shownItems={shownItems}
                            onChange={(value:string)=>handlePerPageSelectionRevenueByEU(value,"")}
                            viewPerPageOptions={configJSON.paginationSelectOptionsCustom}
                            currentPage={currentPage}
                            totalPageNumber={revenuAllData?.total_page}
                            onNext={()=>handlePaginationButtonRevenueByEU(currentPage+1)}
                            onPrev={()=>handlePaginationButtonRevenueByEU(currentPage-1)}
                            onEnter={()=>{}}
                            pageNumberSet={pageNumberSet}
                            isPaginationButton={false}
                            pagneNumberValidation={false}
                        />
                    </StyledTableContainer> :
                    <EmptyData description="You don't have any partners data." image={emptySalesVolumeIcon}/>
                }
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Box)({
    "& .loading-container": {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"30vh"
      },
    "&": {
        border:"1px solid rgba(226, 232, 240, 1)",
        borderRadius:"8px",
        padding:"24px",
        width:"calc(50% - 12px)",
        boxSizing:"border-box",
        height: "100%"
    },
    "& .header":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center"
    },
    "& .header-title":{
        fontSize:"20px",
        lineHeight:"28px",
        color: "#000000"
    },
    "& .header .view-all-button":{
        fontSize:"14px",
        fontWeight:500,
        lineHeight:"20px",
        textTransform:"capitalize",
        color:"#2E17B5"
    },
    "@media(max-width: 1224px)": {
        "&": {
            width: "calc(50% - 24px)"
        }
    },
    "@media(max-width: 960px)": {
        "&": {
            padding:"12px",
        },
        "& .header-title":{
            fontSize:"14px",
            lineHeight:"20px"
        },
        "& .header .view-all-button":{
            fontSize:"12px",
            lineHeight:"16px"
        },
    },
    "@media(max-width: 768px)": {
        "&": {
            width: "100%"
        }
    },
    "& .select-container": {
        width:"115px",
        height:"40px"
      },
});

export default RevenueEarnedByEarchUserTable;