Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const { resourceImage, degitalMarketingImage, salesImage, corporateImage } = require("./assets");
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";
exports.token = sessionStorage.getItem('token');
exports.getCategoryEndPoint = 'bx_block_categories/categories';
exports.getAllContentEndPoint = 'bx_block_content_management';
exports.addMarketingRequest = 'bx_block_content_management/contents';
exports.validationApiContentType = "application/json";
exports.txtInputPlaceholder = "Enter Text";
exports.labelTitleText = "documentstorage";
exports.labelBodyText = "documentstorage Body";
exports.lableDescriptionText = "content"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End