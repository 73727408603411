import React, { ReactNode } from 'react';
import Layout from '../../../components/src/Layout.web'
import { RequestDetailBreadCrumb } from './constants';
import { Box, Button, CircularProgress, Divider, Grid, Typography, styled } from '@material-ui/core';
import MyRequestController, { Props } from './MyRequestController.web';
import { ArrowBack } from '@material-ui/icons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dateFormatOppAllList, formatString } from '../../cfpipelinemanagement/src/constants';
import { OpportunitiesField } from '../../cfpipelinemanagement/src/Components/OpportunitiesTable.web';
import { StatusFieldStyles } from './MyRequest.web';

export default  class RequestDetails extends MyRequestController {
    constructor(props: Props) {
        super(props);
      }
     
    render(): ReactNode {
        const {isLoading } = this.state
     
        return (
            <Layout
                breadcrumb={RequestDetailBreadCrumb}
                navigation={this.props.navigation}
            >            
              
                {isLoading ? (
                    <OpportunitiesField>
                        <Box className="table-loader" >
                            <CircularProgress />
                        </Box>
                    </OpportunitiesField>
                ) : (
                    this.renderDetailBox()
                )}  

            </Layout>
        )
    }

    renderBackArrow = (requestDetailsDataMeta:any)=>{
        return (
            <Button  
                onClick={()=> this.handleGetRequestRecordsByNav(requestDetailsDataMeta?.previous_request)} 
                data-test-id="getRequestRecordsByNavId" 
                variant="outlined" 
                className="paginationNext-btn"
                disabled={!requestDetailsDataMeta?.previous_request} >
                    <ArrowBack className="pagination-ArrowBack" />
            </Button>
        )
        
    }

    renderForwardArrow = (requestDetailsDataMeta:any)=>{
        return (
            <Button 
                onClick={()=> {this.handleGetRequestRecordsByNav(requestDetailsDataMeta?.next_request)}}  
                data-test-id="getRequestRecordsByNavId" 
                variant="outlined" 
                className="paginationNext-btn"
                disabled={!requestDetailsDataMeta?.next_request}   >
                    <ArrowForwardIcon className="pagination-ArrowForwardIcon" />
            </Button>
        )
    }

    renderDetailBox = ()=> {
        const {requestDetailsData,requestDetailsDataMeta } = this.state

        return (
            <>
                <OpportunitiesField>
                    <Box className="mr-table-actions-row">
                        <Typography className="my-request-title">
                                REQUEST DETAIL
                        </Typography>
                        <Box className="mr-actions">
                            {this.renderBackArrow(requestDetailsDataMeta)}
                            {this.renderForwardArrow(requestDetailsDataMeta)}
                        </Box>
                    </Box>
                    <Divider light style={{marginTop:"19px"}} />
                </OpportunitiesField>

                <MrStyledBox>
                    <Box className='box-area'>
                        <Grid container spacing={2} className='container-box'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Request id</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography className='text-value'>{requestDetailsData?.id}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='container-box'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Title</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography className='text-value'>{requestDetailsData?.attributes?.headline}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='container-box'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Created Date</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography className='text-value'>{dateFormatOppAllList(requestDetailsData?.attributes?.created_at, true)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='container-box'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Delivery Date</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography className='text-value'>{dateFormatOppAllList(requestDetailsData?.attributes?.user_delivery_date, true)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='container-box'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Status</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography style={{ ...StatusFieldStyles[requestDetailsData?.attributes?.review_status as keyof typeof StatusFieldStyles] }}>
                                    {formatString(requestDetailsData?.attributes?.review_status)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='container-box'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Category</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography className='text-value'>{requestDetailsData?.attributes?.category_name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='container-box'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Sub Category</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography className='text-value'>{requestDetailsData?.attributes?.sub_category_name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className='container-box fullHeight'>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography className='text-leble'>Description</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Typography className='text-value wrapText' data-test-id="description_data">{requestDetailsData?.attributes?.content}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </MrStyledBox>
            </>
        )
    }
    
}

const MrStyledBox = styled(Box)({
    "& .box-area":{
        marginTop: "50px",
        paddingLeft:"30px",

        "& .container-box":{
            height:"56px"
        },
        "& .fullHeight":{
            height:'auto !important'
        }
    },
    "& .text-leble": {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: '14px',
        textTransform: 'uppercase',
        color: '#64748B',
    },
    "& .text-value": {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: '16px',
        color: '#334155',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    "& .wrapText":{
        wordBreak:'break-word',
        overflow:'none !important',
        whiteSpace:"normal"
    },

    "@media(max-width: 960px)": {
    },
    "@media (max-width: 600px)": {
        "& .box-area":{
            marginTop: "50px",
            paddingLeft:"30px",
    
            "& .container-box":{
                height:"88px ! important"
            }
        },
      }
   
 })
 






