import { Box, Typography, styled } from "@material-ui/core";
import React, { Component } from "react"

type IProps = {
    label:string;
    value:string;
    backgroundColor:string;
    icon:React.ReactChild
};
type IState = {};

class InfoCard extends Component<IProps, IState>{

    render() {
        const { label, value, icon, backgroundColor } = this.props;
        return (
            <StyledContainer style={{backgroundColor}}>
                <Box sx={webStyles.row}>
                    <StyledLabel>
                        {label}
                    </StyledLabel>
                    {icon}
                </Box>
                <StyledValue>
                    {value}
                </StyledValue>
            </StyledContainer>
        )
    }
}

const webStyles = {
    row:{
        display:"flex",
        justifyContent:"space-between"
    }
}

const StyledContainer = styled(Box)({
    "&": {
        height:"132px",
        width:"100%",
        padding:"24px",
        borderRadius:"8px",
        display:"flex",
        flexDirection:"column",
        gap:"8px",
        boxSizing:"border-box"
    },
    "@media(max-width: 960px)": {
        "&": {}
    }
});

const StyledLabel = styled(Typography)({
    "&": {
        fontSize:"20px",
        lineHeight:"28px",
        fontWeight:400,
        color:"rgba(10, 5, 40, 1)"
    },
    "@media(max-width: 960px)": {
        "&": {}
    }
});

const StyledValue = styled(Typography)({
    "&": {
        fontSize:"30px",
        fontWeight:700,
        lineHeight:"40px",
        letterSpacing: "-0.005em",
        color:"rgba(10, 5, 40, 1)"
    },
    "@media(max-width: 960px)": {
        "&": {}
    }
});

export default InfoCard;