import React from "react";
import Layout from "../../../components/src/Layout.web";
import PrivacyPolicyBlockController, { Props, configJSON } from "./PrivacyPolicyController";
import CommonBody from "./CommonBody.web";
import { downloadAction } from "./helper";

export class PrivacyPolicyBlock extends PrivacyPolicyBlockController {
    constructor(props: Props) {
        super(props);
    }
    onDownload = () => {
        this.setState({privacyPolicyList: this.state.privacyPolicyList.map(item=>{
            return {...item, isExpanded:item.title}
        })},()=>{
            downloadAction(configJSON.PrivacyPolicyBreadcrumb.currentPage.text);
        })
    }
    render() {
        return (
            <Layout
                navigation={this.props.navigation}
                breadcrumb={configJSON.PrivacyPolicyBreadcrumb}
                // style={{height:'93.033%'}}
            >
                <CommonBody
                    data-test-id="common-body"
                    title="PRIVACY POLICY"
                    onDownload={this.onDownload}
                    data={this.state.privacyPolicyList}
                    toggleAccordionStatus={this.toggleAccordionStatus}
                />
            </Layout>
        )
    }
}

export default PrivacyPolicyBlock;