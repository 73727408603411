import { Box, Button, Popover, Typography, styled } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import { chevronDown, trash, addNewService, deleteService } from "./assets";
import InputWithLabel from "../../../components/src/InputWithLabel.web";
import SelectWithLabel from "../../../components/src/SelectWithLabel.web";
import { PlatformPackages, serviceTypes } from "./constants";
import { IClient, IRole, ISaveClientBody, IErrorValidation } from "./types";
import RoleSelect from "./RoleSelect.web";
import RoleSelectItem from "./RoleSelectItem.web";

type IProps = {
    handleDeleteClient: (id: string) => void;
    handleUpdateClient: (client: IClient) => void;
    handleSaveClient: (client: ISaveClientBody) => void;
    client: IClient;
    index: number;
    commissionRate: string;
    totalCommission: number;
    isRoleGloble:boolean;
};

type IState = {
    isOpen: boolean;
    name: string;
    startDate: string;
    serviceType: string;
    serviceDataArray: any[];
    serviceErrors: IErrorValidation;
    staffRole: IRole[],
    consultingRole: IRole[];
    platformPackage: string;
    staffQuantity: string;
    consultingQuantity: string;
    dealAmount: string;
    partnerCommission: string;
    roleAnchorEl: HTMLElement | null;
    consultingRoleAnchorEl: HTMLElement | null;
    totalIncome: number;
    validation: "error" | "valid" | "standby";
};

const consultingInitialRole = [
    { title: "Junior Consultant", checked: false, quantity: "1" },
    { title: "Mid level Consultant", checked: false, quantity: "1" },
    { title: "Senior Consultant", checked: false, quantity: "1" },
]

const initialRole = [
    { title: "Analyst", checked: false, quantity: "1" },
    { title: "Associate Consultant", checked: false, quantity: "1" },
    { title: "Consultant", checked: false, quantity: "1" },
]

class ClientItem extends Component<IProps, IState>{
    [x: string]: any;
    constructor(props: IProps) {
        super(props);
        this.state = {
            isOpen: false,
            name: "",
            startDate: "",
            serviceType: "",
            serviceDataArray: [{serviceType:'', dealAmount:'', partnerCommission:'', staffRole:'', staffQuantity:'',consultingRole:'',consultingQuantity:'', platformPackage:'', serviceArray:serviceTypes}],
            serviceErrors: {serviceType: false, dealAmount: false, partnerCommission: false, staffRole: false, staffQuantity: false,consultingRole: false, consultingQuantity:false, platformPackage: false},
            platformPackage: "",
            staffRole: initialRole,
            consultingRole: consultingInitialRole,
            consultingQuantity: '1',
            staffQuantity: "1",
            dealAmount: "",
            partnerCommission: "",
            roleAnchorEl: null,
            consultingRoleAnchorEl: null,
            totalIncome: 0,
            validation: "standby"
        }
    }

    handleTotalData = () => {
        let totalDealAmount = this.state.serviceDataArray.reduce((accumulator, object) => {
            return Number(accumulator) + Number(object.dealAmount);
          }, 0);
          let totalPartnerCommission = this.state.serviceDataArray.reduce((accumulator, object) => {
            return Number(accumulator) + Number(object.partnerCommission);
          }, 0);
          let totalIncomeSum = Number(totalDealAmount) - Number(totalPartnerCommission);
        this.props.handleUpdateClient({ ...this.props.client, dealAmount: totalDealAmount, partnerCommission: totalPartnerCommission.toString(), totalIncome: totalIncomeSum.toString() })
    }

    handleFieldChange = (stateName: string, value: string) => {
            this.setState({
                [stateName]: value
            } as unknown as Pick<IState, keyof IState>);
            this.props.handleUpdateClient({ ...this.props.client, [stateName]: value })
    }

    handleToggleVisibility = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }))
    }

    openRoleMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ roleAnchorEl: event.currentTarget });
    }

    openConsultingRoleMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ consultingRoleAnchorEl: event.currentTarget });
    }

    roleMenuClose = () => {
        this.setState({ roleAnchorEl: null });
    }

    consultingRoleMenuClose = () => {
        this.setState({ consultingRoleAnchorEl: null });
    }

    handleRoleUpdate = (value: IRole) => {
        this.setState(prevState => ({
            staffRole: prevState.staffRole.map(item => {
                return item.title === value.title ? value : item
            })
        }), () => {
            let updatedQuantity = 0;
            this.state.staffRole.forEach(item => {
                if (item.checked) {
                    updatedQuantity = parseInt(item.quantity) + updatedQuantity
                }
            })
            this.setState({ staffQuantity: updatedQuantity > 0 ? updatedQuantity.toString() : "1", totalIncome: (updatedQuantity > 0 ? updatedQuantity : 1) * (parseInt(this.state.dealAmount) - parseInt(this.state.partnerCommission)) })
            this.props.handleUpdateClient({ ...this.props.client, staffRole: this.state.staffRole })  
        })
    }

    handleConsultingRoleUpdate = (value: IRole) => {
        this.setState(prevState => ({
            consultingRole: prevState.consultingRole.map(item => {
                return item.title === value.title ? value : item
            })
        }), () => {
            let updatedQuantity = 0;
            this.state.consultingRole.forEach(item => {
                if (item.checked) {
                    updatedQuantity = parseInt(item.quantity) + updatedQuantity
                }
            })
            this.setState({ consultingQuantity: updatedQuantity > 0 ? updatedQuantity.toString() : "1", totalIncome: (updatedQuantity > 0 ? updatedQuantity : 1) * (parseInt(this.state.dealAmount) - parseInt(this.state.partnerCommission)) })
            this.props.handleUpdateClient({ ...this.props.client, consultingRole: this.state.consultingRole })  
        })
    }

    handleTrueCondition = (firstValue: boolean, secondValue: boolean, element: React.ReactNode) => {
        if (firstValue && secondValue) {
            return element;
        }
    }

    addServiceFields = () => {
        let newServiceTypes = serviceTypes.filter((objFromA) => {
            return !this.state.serviceDataArray.find(function(objFromB: any) {
              return objFromA === objFromB.serviceType
            })
        })
        this.setState({
            serviceDataArray: [...this.state.serviceDataArray, {serviceType:'', dealAmount:'', partnerCommission:'', platformPackage:'', serviceArray: newServiceTypes}],
            serviceErrors: {serviceType: false, dealAmount: false, partnerCommission: false, staffRole: false, staffQuantity: false, consultingRole: false, consultingQuantity:false, platformPackage: false}
        });
    }

    handleElseValidation = (item: any) => {
        if(item.serviceType === 'Platform'){
            if(!item.platformPackage){
                this.setState(prevState => ({
                    serviceErrors: {
                        ...prevState.serviceErrors,
                        platformPackage: true
                    }
                }));
                return false;
            } else {
                this.setState(prevState => ({
                    serviceDataArray: {
                        ...prevState.serviceDataArray,
                    }
                }))
            }
        }
        if(item.serviceType === 'Staff Augmentation'){
            if(this.state.staffRole.filter(item=>item.checked).length === 0){
                this.setState(prevState => ({
                    serviceErrors: {
                        ...prevState.serviceErrors,
                        staffRole: true
                    }
                }));
                return false;
            } else {
                this.setState(prevState => ({
                    serviceDataArray: {
                        ...prevState.serviceDataArray,
                    }
                }))
            }
        }
        if(item.serviceType === 'Consulting'){
            if(this.state.consultingRole.filter(item=>item.checked).length === 0){
                this.setState(prevState => ({
                    serviceErrors: {
                        ...prevState.serviceErrors,
                        consultingRole: true
                    }
                }));
                return false;
            } else {
                this.setState(prevState => ({
                    serviceDataArray: {
                        ...prevState.serviceDataArray,
                    }
                }))
            }
        }
        return true;
    }

    addNewService = () =>{
        const item = this.state.serviceDataArray[this.state.serviceDataArray.length - 1];
            if(!item.dealAmount && !item.serviceType){
                return this.setState(prevState => ({
                    serviceErrors: {
                        ...prevState.serviceErrors,
                        dealAmount: true,
                        serviceType: true,
                        isRoleGloble:true
                    }
                }))
            }
            if(!item.dealAmount){
            return this.setState(prevState => ({
                serviceErrors: {
                    ...prevState.serviceErrors,
                    dealAmount: true
                }
            }))
            }
            if(!item.serviceType){
                return this.setState(prevState => ({
                    serviceErrors: {
                        ...prevState.serviceErrors,
                        serviceType: true
                    }
                }))
            }
            if(this.handleElseValidation(item)){
                return this.addServiceFields();
            }
    }

    handleChange = (eventValue: any, index: any, name: string) => {
        const number = eventValue.replace(/\D/g, '')
        const event =  name === "dealAmount" ?  number : eventValue;
        let onChangeValue = [...this.state.serviceDataArray];
        onChangeValue[index][name] = event;
        const { commissionRate } = this.props;
        let DEAL_AMOUNT = event;
        let PARTNER_COMMISSION:number = 0;
        let TOTAL_INCOME:number = 0;
        if (name === "dealAmount") {
            PARTNER_COMMISSION = Number(parseInt(event) * parseInt(commissionRate) / 100);
            TOTAL_INCOME = (Number(DEAL_AMOUNT) - PARTNER_COMMISSION)
            onChangeValue[index]['partnerCommission'] = PARTNER_COMMISSION.toString();
            this.setState({
                serviceDataArray: [{totalIncome: TOTAL_INCOME }]
              })
              this.handleTotalData()
            }
        else if(name === "serviceType"){
            if(event !== "Platform") {
                this.setState({platformPackage:""})
            }
            this.setState({ serviceType:event, totalIncome: (parseInt(this.state.dealAmount) - (parseInt(this.state.dealAmount) * parseInt(commissionRate) / 100)), partnerCommission: (parseInt(this.state.dealAmount) * parseInt(commissionRate) / 100).toString()})
            this.props.handleUpdateClient({ ...this.props.client, serviceType: onChangeValue })
        }
            
        // else {
        //     this.setState({
        //         [stateName]: value
        //     } as unknown as Pick<IState, keyof IState>);
        //     this.props.handleUpdateClient({ ...client, [stateName]: value })
        // }
        this.setState({serviceDataArray: onChangeValue});
      };
    
    handleDeleteService = (index: any) => {
        const newArray = [...this.state.serviceDataArray];
        newArray.splice(index, 1);
        this.setState({serviceDataArray: newArray}, ()=> this.handleTotalData());
      };

    serviceTypeContainer = () => {
        const { staffRole, roleAnchorEl, consultingRoleAnchorEl, consultingRole } = this.state;
        return <Box className="service-wrapper">
            {this.state.serviceDataArray.map((item, index) => (<>
            {index !==0 && <Box className="divider">
            </Box>}
            <FieldWrapper>
            <SelectWithLabel
                data-test-id="service-type-select-box"
                className="responsive-width"
                containerStyle={{}}
                label="Service Type"
                data={item.serviceArray}
                value={item.serviceType}
                onChange={(value)=> {this.handleChange(value, index, 'serviceType')}}
            />
            {
                this.handleTrueCondition(this.state.serviceErrors.serviceType, !item.serviceType, <StyledValidationTypography>Can't be blank</StyledValidationTypography>)
            }
        </FieldWrapper>
        {
            item.serviceType === "Platform" &&
            <FieldWrapper>
                <SelectWithLabel
                    data-test-id="platform-package-select-box"
                    className="responsive-width"
                    containerStyle={{}}
                    label="Platform Package"
                    data={PlatformPackages}
                    value={item.platformPackage}
                    onChange={(value)=> {this.handleChange(value, index, 'platformPackage')}}
                />
                {
                    this.handleTrueCondition(this.props.isRoleGloble, !item.platformPackage, <StyledValidationTypography>Can't be blank</StyledValidationTypography>)
                }
            </FieldWrapper>
        }
        {
            item.serviceType === "Staff Augmentation" &&
            <FieldWrapper>
                <Box data-test-id="test" sx={webStyles.roleSelectContainer}>
                    <RoleSelect 
                        data-test-id="role-select-menu-button" 
                        onClick={this.openRoleMenu}
                        selectedRole={this.state.staffRole}
                    />
                    <StyledMenu
                        data-test-id="role-menu"
                        anchorEl={roleAnchorEl}
                        open={roleAnchorEl !== null}
                        onClose={this.roleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        style={webStyles.menuPosition}
                        PaperProps={{
                            style: { ...webStyles.menuWidth },
                        }}
                    >
                        {
                            staffRole.map(item => (
                                <RoleSelectItem
                                    data-test-id="role-select-item"
                                    key={item.title}
                                    title={item.title}
                                    quantity={item.quantity}
                                    checked={item.checked}
                                    handleRoleUpdate={this.handleRoleUpdate}
                                />
                            ))
                        }
                    </StyledMenu>
                </Box>
                {
                    this.handleTrueCondition(this.props.isRoleGloble, staffRole.filter(item=>item.checked).length === 0, <StyledValidationTypography>You should choose at least 1 staffRole</StyledValidationTypography>)
                }
            </FieldWrapper>
        }
        {
            item.serviceType === "Staff Augmentation" ?
                <InputWithLabel
                    disabled
                    data-test-id="quantity-input"
                    className="responsive-width"
                    containerStyle={{}}
                    inputType="number"
                    inputValue={this.state.staffQuantity}
                    label="Quantity"
                    onChange={(value)=> {this.handleChange(value, index, 'quantity')}}
                />
                :
                <></>
        }
        {
            item.serviceType === "Consulting" &&
            <FieldWrapper>
                <Box data-test-id="test" sx={webStyles.roleSelectContainer}>
                    <RoleSelect 
                        data-test-id="consulting-role-select-menu-button" 
                        onClick={this.openConsultingRoleMenu} 
                        selectedRole={this.state.consultingRole}
                    />
                    <StyledMenu
                        data-test-id="consultant-role-menu"
                        anchorEl={consultingRoleAnchorEl}
                        open={consultingRoleAnchorEl !== null}
                        onClose={this.consultingRoleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        style={webStyles.menuPosition}
                        PaperProps={{
                            style: { ...webStyles.menuWidth },
                        }}
                    >
                        {
                            consultingRole.map(item => (
                                <RoleSelectItem
                                    data-test-id="consulting-role-select-item"
                                    key={item.title}
                                    title={item.title}
                                    quantity={item.quantity}
                                    checked={item.checked}
                                    handleRoleUpdate={this.handleConsultingRoleUpdate}
                                />
                            ))
                        }
                    </StyledMenu>
                </Box>
                {
                    this.handleTrueCondition(this.props.isRoleGloble, consultingRole.filter(item=>item.checked).length === 0, <StyledValidationTypography>You should choose at least 1 role</StyledValidationTypography>)
                }
            </FieldWrapper>
        }
        {
            (item.serviceType === "Consulting") ?
                <InputWithLabel
                    disabled
                    data-test-id="consulting-quantity-input"
                    className="responsive-width"
                    containerStyle={{}}
                    inputType="number"
                    inputValue={this.state.consultingQuantity}
                    label="Quantity"
                    onChange={(value)=> {this.handleChange(value, index, 'consultingQuantity')}}
                />
                :
                <></>
        }
        <FieldWrapper>
            <InputWithLabel
                data-test-id="deal-amount-input"
                className="responsive-width"
                containerStyle={{}}
                inputType="text"
                inputValue={item.dealAmount}
                label="Deal Amount"
                onChange={(value)=> {this.handleChange(value, index, 'dealAmount')}}
            />
            {
                this.handleTrueCondition(this.state.serviceErrors.dealAmount, !item.dealAmount, <StyledValidationTypography>Can't be blank</StyledValidationTypography>)
            }
        </FieldWrapper>
        <FieldWrapper>
            <InputWithLabel
                data-test-id="partner-commission-input"
                className="responsive-width"
                containerStyle={{}}
                inputType="number"
                inputValue={item.partnerCommission}
                label="Partner Commission"
                disabled
                onChange={(value)=> {this.handleChange(value, index, 'partnerCommission')}}
            />
            {
                this.handleTrueCondition(this.state.serviceErrors.partnerCommission, !item.partnerCommission, <StyledValidationTypography>Can't be blank</StyledValidationTypography>)
            }
        </FieldWrapper>
        <Box className="button-container">
            {(index <= 2 && index === this.state.serviceDataArray.length - 1) && (
            <Button data-test-id="add-service-btn" className="add-service-btn" onClick={()=> this.addNewService()}>
                <img src={addNewService} alt=""/>
                <Typography className="add-service-txt">Add New Service</Typography>
            </Button>
          )}
        {(index !== 0 && index === this.state.serviceDataArray.length - 1) && (
            <Button data-test-id="delete-service-btn" className='delete-service-btn' onClick={()=> this.handleDeleteService(index)}>
                <img src={deleteService} alt=""/>
                <Typography className="delete-service-txt">Delete this Service</Typography>
            </Button>
        )}</Box>
        </>))}
        </Box>
        
    }

    render(): ReactNode {
        const { isOpen, name, startDate } = this.state;
        const { handleDeleteClient, client, index } = this.props;

        return (
            <Box className="accordion-wrapper" data-test-id="accordion-wrapper" sx={webStyles.accordionWrapper}>
                <Box sx={webStyles.accordionHeader}>
                    <Box data-test-id="toggle-accordion-button" onClick={this.handleToggleVisibility} sx={webStyles.accordionTitle}>
                        <img style={isOpen ? webStyles.chevronOpen : webStyles.chevronClose} src={chevronDown} alt="" />
                        <StyledTypographyHeader>Client {index + 1}</StyledTypographyHeader>
                    </Box>
                    <StyledButton data-test-id="remove-client-button" onClick={() => handleDeleteClient(client.id)}>
                        <img src={trash} alt="" />
                        <Box className="remove-text">Remove</Box>
                    </StyledButton>
                </Box>
                {
                    <Box sx={isOpen ? webStyles.noHeight : webStyles.autoHeight}>
                        <BodyTitle>INCOME</BodyTitle>
                        <StyledBox>
                            <FieldWrapper>
                                <InputWithLabel
                                    data-test-id="client-name-input"
                                    className="responsive-width"
                                    containerStyle={{}}
                                    inputType="text"
                                    inputValue={name}
                                    label="Client Name"
                                    onChange={(value) => this.handleFieldChange("name", value)}
                                />
                                {
                                    this.handleTrueCondition(client.validation === "error", !name, <StyledValidationTypography>Can't be blank</StyledValidationTypography>)
                                }
                            </FieldWrapper>
                            <FieldWrapper>
                                <InputWithLabel
                                    data-test-id="start-date-input"
                                    className="responsive-width"
                                    containerStyle={{}}
                                    inputType="date"
                                    inputValue={startDate}
                                    label="Billing Start Date"
                                    onChange={(value) => this.handleFieldChange("startDate", value)}
                                />
                                {
                                    this.handleTrueCondition(client.validation === "error", !startDate, <StyledValidationTypography>Can't be blank</StyledValidationTypography>)
                                }
                            </FieldWrapper>
                            {this.serviceTypeContainer()}
                        </StyledBox>
                    </Box>
                }
            </Box>
        )
    }
}

const webStyles = {
    accordionWrapper: {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "16px 20px",
        transition: ".5s all",
        marginBottom: "20px",
        boxSizing: "border-box"
    },
    accordionHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    accordionTitle: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        flex: 1
    },
    chevronOpen: {},
    chevronClose: {
        transform: "rotate(-90deg)"
    },
    noHeight: {
        height: 0,
        overflow: "hidden"
    },
    autoHeight: {
        height: "auto"
    },
    labelRow: {
        display: "flex",
        alignItems: "baseline"
    },
    roleSelectContainer: {
        position: "relative",
        paddingBottom: "15px",
        flex: "0 0 calc(50% - 10px)"
    },
    fullWidth: {
        paddingBottom: "15px",
        width: "100%",
    },
    halfWidth: {
        paddingBottom: "15px",
        flex: "0 0 calc(50% - 10px)"
    },
    menuPosition: {
        marginTop: "-45px"
    },
    menuWidth: {
        width: "calc((100% - 340px)/2)"
    }
};

export const StyledBox = styled(Box)({
    "&": {
        display: "flex",
        gap: "20px",
        paddingBottom: "15px",
        width: "100%",
        flexWrap: "wrap"
    },
    "& .responsive-width": {
        paddingBottom: "15px",
        flex: "0 0 calc(50% - 10px)"
    },
    "& .service-wrapper":{
        display:'contents',
        width:'100%'
    },
    "& .divider": {
        borderTop: '1px dashed #B1B1B1',
        width:'100%',
        marginBottom:'25px'
    },
    "& .button-container": {
        width:"100%",
        display:'flex',
        justifyContent:'center',
        gap:'20px'
    },
    "& .add-service-btn": {
        border:"1px solid #000000",
        borderRadius:'27px',
        height:'54px',
        width:'268px'
    },
    "& .add-service-txt": {
        color:'#000000',
        textTransform: 'none',
        paddingInline: '10px',
        fontSize: '18.7px'
    },
    "& .delete-service-btn": {
        border:"1px solid #D81D00",
        borderRadius:'27px',
        height:'54px',
        width:'268px'
    },
    "& .delete-service-txt": {
        color:'#FE4023',
        textTransform: 'none',
        paddingInline: '10px',
        fontSize: '18.7px'
    },
    "@media(max-width: 960px)": {
        "&": {
            gap: 0,
            flexDirection: "column"
        },
        "& .responsive-width": {
            paddingBottom: "15px",
            flex: "0 0 100%"
        },
        "& .button-container": {
            width:"100%",
            display:'flex',
            justifyContent:'center',
            gap:'20px',
            flexDirection:"column"
        },
        "& .add-service-btn": {
            border:"1px solid #000000",
            borderRadius:'27px',
            height:'41px',
            width:'100%'
        },
        "& .delete-service-btn": {
            border:"1px solid #D81D00",
            borderRadius:'27px',
            height:'41px',
            width:'100%'
        },
        "& .add-service-txt": {
            color:'#000000',
            textTransform: 'none',
            paddingInline: '10px',
            fontSize: '11.7px'
        },
        "& .delete-service-txt": {
            color:'#FE4023',
            textTransform: 'none',
            paddingInline: '10px',
            fontSize: '11.7px'
        },
    }
});

const StyledTypographyHeader = styled(Typography)({
    "&": {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "uppercase"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "12px"
        }
    }
});

const BodyTitle = styled(Typography)({
    "&": {
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "0em",
        textAlign: "left",
        color: "#0A0528",
        margin: "24px 0"

    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "16px"
        }
    }
});

const StyledButton = styled(Button)({
    "&": {
        border: "1px solid #DC2626",
        borderRadius: "4px",
        width: "113px",
        minWidth:"unset",
        height: "32px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "capitalize",
        color: "#64748B"
    },
    "& img": {
        marginRight: "6px"
    },
    "@media(max-width: 960px)": {
        "&": {
            fontSize: "14px",
            width:"36px",
            display:"flex",
            justifyContent:"center"
        },
        "& .remove-text":{
            display:"none"
        },
        "& img": {
            marginRight:0
        }
    }
});

export const FieldWrapper = styled(Box)({
    "&": {
        flex: "0 0 calc(50% - 10px)",
        width:'50%'
    },
    "@media(max-width: 960px)": {
        "&": {
            width: "100%"
        }
    }
});

const StyledMenu = styled(Popover)({
    "& .MuiPaper-root": {
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        border: "1px solid rgba(226, 232, 240, 1)",
        padding: "20px",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginTop: "50px"
    },
    "& .MuiPaper-root>div:not(:last-child)": {
        marginBottom: "20px"
    }
})

const StyledValidationTypography = styled(Typography)({
    "&": {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: '400',
        marginTop: '-10px',
    },
    "@media(max-width:960px)":{
        marginBottom:"10px"
    },
    "@media(max-width:576px)":{
        fontSize:"10px"
    }
});

export default ClientItem;