// Customizable Area Start
//@ts-nocheck
import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import { Box, Button, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Modal, Paper, Radio, RadioGroup, Select, TextField, Typography, styled, withStyles } from "@material-ui/core";
import Layout from "../../../components/src/Layout.web";
import { SuccessImg, cheadRadio, closeImg, uncheadRadio } from "./assets";
import EducationalUserProfileController, { Props, configJSON } from "./EducationalUserProfileController";
import { StyledDropdown } from "./AdminProfile.web";
// Customizable Area End

// Customizable Area Start
const CustomRadioSI = styled(Radio)({
    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
        color: "transparent",
        backgroundImage: `url(${uncheadRadio})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    "&.Mui-checked .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
        color: "transparent",
        backgroundImage: `url(${cheadRadio})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
        color: "transparent",
        backgroundImage: `url(${cheadRadio})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
});

export const StyledContainer = styled(Box)({
    "@media(max-width: 960px)": {
        "&": {
            gap:"16px!important",
            width:"100%!important"
        },
        "& .right-side-container": {
            gap:"20px"
        },
        "& .header-container, & .tab-container": {
            margin:"16px 16px 0 16px"
        },
        "& .header-container svg": {
            width:"0.8em"
        },
        "& .title": {
            fontSize:"16px"
        },
        "& .fields-container": {
            margin:"20px 16px 0 16px"
        },
        "& .profile-field-main-box": {
            flexDirection:"column",
            gap:0
        },
        "& .profile-field-main-box>div": {
            width:"100%"
        },
        "& .buttons-container": {
            margin:"16px"
        },
        "& .save-button": {
            width:"64px",
            height: "36px",
            fontSize:"8px"
        },
        "& .save-and-new-button": {
            width:"84px",
            height: "36px",
            fontSize:"8px"
        },
        "& .cancel-button": {
            width:"35px",
            height: "36px",
            fontSize:"8px"
        },
        "& label": {
            fontSize:"12px"
        },
        "& input, & .MuiSelect-select, & .MuiSelect-select>div,& #user-type-radio-group label + div, & textarea": {
            fontSize:"14px"
        },
        "& .phone-input": {
            gap:"10px"
        }
    }
});
// Customizable Area End


export class ProfileBio extends EducationalUserProfileController {
    constructor(props: Props) {
        super(props);
    }

    renderCountryCode = (selected: any) => {
        return selected;
    }

    handleAutocompleteOptions = (option, value) => { return option === value }

    renderAutocompleteInput = (params,state,testId) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id={testId}
            sx={{ fontSize: "12px" }}
            {...params}
            value={state}
        />
    )
    handleOptions = (option, value) => { return option === value }

    renderInput = (params,state,testId) => (
        <TextField className={this.props.classes.searchInpUnd}
            data-test-id={testId}
            sx={{ fontSize: "12px" }}
            {...params}
            value={state}
        />
    )
    render() {
        // Customizable Area Start
        const { classes } = this.props
        const menuPropsPBio = {
            classes:{paper:classes.rootPBio},
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            }
        }
        // Customizable Area End

        return (
            // Customizable Area Start
            <StyledContainer className={`${classes.BoxmainContainer}`}>
                <Layout
                title=""
                breadcrumb={configJSON.ProfileBioBreadcrumb}
                navigation={this.props.navigation}
                >
                <Box className={`right-side-container ${classes.leftSideContainer}`}>    
                    <Box className={`${classes.centerBgColor}`}>
                        <Box className={`header-container ${classes.infoheaderContainer}`}>
                            <Typography className={`title ${classes.genralTxt}`}>General Information</Typography>
                            <Button className={`${classes.btnEdittext}`} data-test-id="editCilckBtn" {...this.showEditableForm} variant="contained">
                                <EditIcon style={{ color: "#64748B" }} />
                                <Typography>Edit</Typography>
                            </Button>
                        </Box>
                        <Box className={`fields-container ${classes.mainBottomPart}`}>
                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            First Name
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`} >*</Typography>
                                    </Box>
                                    <Input data-test-id="txtInputFirstName" placeholder={"First Name"} fullWidth={true}
                                        disableUnderline
                                        className={this.editNonEditDieldClass()}
                                        {...this.txtInputFname}
                                        value={this.state.firstNameP}
                                        disabled
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                    />
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Last Name
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtInputLastName" placeholder={"Last name"} fullWidth={true}
                                        {...this.txtInputLname}
                                        value={this.state.lastNameP}
                                        disableUnderline
                                        className={this.editNonEditDieldClass()}
                                        disabled
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Company Name
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtInputCompanyName" placeholder={"Company Name"} fullWidth={true}
                                        disableUnderline
                                        className={this.editNonEditDieldClass()}
                                        {...this.txtInputComapnyName}
                                        value={this.state.company}
                                        disabled
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                    />
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Job Title
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtInputJobTitle" placeholder={"Job Title"} fullWidth={true}
                                        {...this.txtInputjobTitle}
                                        value={this.state.jobTitle}
                                        disableUnderline
                                        className={this.editNonEditDieldClass()}
                                        disabled
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Work Email
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <Input data-test-id="txtInputWorkEmail" placeholder={"Company Name"} fullWidth={true}
                                        disableUnderline
                                        className={this.editNonEditDieldClass()}
                                        {...this.txtInputworkEmail}
                                        value={this.state.emailP}
                                        disabled
                                        inputProps={{
                                            maxLength: 256,
                                          }}
                                    />
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Mobile Number
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>

                                    <Box className={`phone-input ${classes.mobileInputWrapper1}`}>
                                        <StyledDropdown
                                            key={0}
                                            disableUnderline
                                            className={`dial-select`}
                                            data-test-id="txtInputCountryCode"
                                            sx={{ fontSize: "12px" }}
                                            limitTags={1}
                                            options={this.dialCodeList}
                                            getOptionLabel={(option)=>option}
                                            onChange={(_,value)=>this.textCountryDialProps.onChange(value)}
                                            renderInput={(params)=>this.renderAutocompleteInput(params,this.state.countryDialCode,"txtInputCountryCode")}
                                            getOptionSelected={this.handleAutocompleteOptions}
                                            disabled={this.state.editField}
                                            PaperComponent={(props) => {
                                                return (
                                                    <PaperStyled
                                                        className="dial-paper"
                                                        {...props}
                                                    />
                                                )
                                            }}
                                            value={this.state.countryDialCode}
                                        />
                                        <Input data-test-id="txtInputMobileNo" placeholder={"12345 67890"} fullWidth={true}
                                            {...this.txtInputMobileNo}
                                            value={this.state.mobileNumber}
                                            disableUnderline
                                            className={`${classes.inputMobile1}`}
                                            disabled={this.state.editField}
                                            inputProps={{
                                                maxLength: this.state.countryDialCode=='+91'?10:15,
                                            }}
                                        />
                                    </Box>
                                    {this.state.mobileNumberError && (
                                        <Typography
                                            style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                        >Please enter valid mobile number
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Country
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            {...this.textInputprofileCountry}
                                            value={this.state.country}
                                            disableUnderline
                                            className={this.editNonEditDSelectClass()}
                                            renderValue={this.countryprofilePlaceholder()}
                                            displayEmpty
                                            data-test-id="txtInputCountryName"
                                            disabled
                                        >
                                            {this.countryList.map((data) => (
                                                <MenuItem key={data} value={data}>{data}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Date of Birth
                                        </InputLabel>
                                    </Box>
                                    <Input data-test-id="txtInputDateofBirth" placeholder={"Company Name"} fullWidth={true}
                                        disableUnderline
                                        className={`${classes.profileinputTxtMain}`}
                                        {...this.txtInputDateOfBirthName}
                                        value={this.state.birthDate}
                                        disabled={this.state.editField}
                                        type="date"
                                        inputProps={{
                                            min: "1900-01-01",
                                            max: configJSON.maxDate()
                                        }}
                                        onFocus={e => e.target.showPicker && e.target.showPicker()}
                                    />
                                     { this.state.birthDateAError && (
                                        <Typography style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}>
                                            {" "} {this.state.birthDateAError}{" "}
                                        </Typography>
                                    )}
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Gender
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <FormControl>
                                        <RadioGroup
                                            id='user-type-radio-group'
                                            value={this.state.userType}
                                            {...this.selectedType}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <Box className={`${classes.radioMain}`}>
                                                <FormControlLabel
                                                    value="0"
                                                    control={<CustomRadioSI />}
                                                    label
                                                    disabled={this.state.editField}
                                                />{" "}
                                                <Box className={`${classes.radioBox}`}>
                                                    {this.slectgendertypeStudent}
                                                </Box>
                                            </Box>
                                            <Box className={`${classes.radioMain}`}>
                                                <FormControlLabel
                                                    value="1"
                                                    control={<CustomRadioSI />}
                                                    label
                                                    disabled={this.state.editField}
                                                />
                                                <Box className={`${classes.radioBox}`}>
                                                    {" "}
                                                    {this.slectgendertypeInstructor}
                                                </Box>
                                            </Box>
                                            <Box className={`${classes.radioMain}`}>
                                                <FormControlLabel
                                                    value="2"
                                                    control={<CustomRadioSI />}
                                                    label
                                                    disabled={this.state.editField}
                                                />
                                                <Box className={`${classes.radioBox}`}>
                                                    {this.slectgendertypeRecruiter}
                                                </Box>
                                            </Box>
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="fields-container" sx={{ margin: '15px 0px' }}>
                            <Box className={`${classes.addressContainer}`}>
                                <Typography className={`${classes.genralTxt}`}>Address Information</Typography>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Country
                                        </InputLabel>
                                        <Typography component="div" style={{ color: 'red' }} variant="subtitle1" >*</Typography>
                                    </Box>
                                        <StyledDropdown
                                            key={0}
                                            disableUnderline
                                            data-test-id="txtCountryName2"
                                            sx={{ fontSize: "12px" }}
                                            limitTags={1}
                                            options={this.state.countryArrayCommon}
                                            getOptionLabel={(option)=>option}
                                            onChange={(_,value)=>this.textInputadminCountry.onChange(value)}
                                            renderInput={(params)=>this.renderInput(params,this.state.country,"txtCountryName2")}
                                            getOptionSelected={this.handleOptions}
                                            disabled={this.state.editField}
                                            PaperComponent={(props) => {
                                                return (
                                                    <PaperStyled
                                                        {...props}
                                                    />
                                                )
                                            }}
                                            value={this.state.country}
                                        />
                                         {this.state.countryNameAError && (
                                            <Typography style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}  >
                                                {" "} {this.state.countryNameAError}{" "}
                                            </Typography>
                                        )}
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            State
                                        </InputLabel>
                                        <Typography component="div" style={{ color: 'red' }} variant="subtitle1" >*</Typography>
                                    </Box>
                                    <StyledDropdown
                                        key={0}
                                        disableUnderline
                                        limitTags={1}
                                        sx={{ fontSize: "12px" }}
                                        data-test-id="txtstateName"
                                        options={this.state.stateArrayCommon}
                                        getOptionLabel={(option)=>option}
                                        onChange={(_,value)=>this.textStateadminProps.onChange(value)}
                                        renderInput={(params)=>this.renderInput(params,this.state.stateName,"txtstateName")}
                                        getOptionSelected={this.handleOptions}
                                        disabled={this.state.editField}
                                        PaperComponent={(props) => {
                                            return (
                                                <PaperStyled
                                                    {...props}
                                                />
                                            )
                                        }}
                                        value={this.state.stateName}
                                    />
                                    {this.state.stateNameAError && (
                                        <Typography style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }} >
                                            {" "} {this.state.stateNameAError}{" "}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            City
                                        </InputLabel>
                                        <Typography component="div" style={{ color: 'red' }} variant="subtitle1" >*</Typography>
                                    </Box>
                                    <StyledDropdown
                                        key={0}
                                        disableUnderline
                                        data-test-id="txtCityName"
                                        sx={{ fontSize: "12px" }}
                                        limitTags={1}
                                        options={this.state.cityArrayCommon}
                                        getOptionLabel={(option)=>option}
                                        onChange={(_,value)=>this.textInputadminCity.onChange(value)}
                                        renderInput={(params)=>this.renderInput(params,this.state.cityName,"txtCityName")}
                                        getOptionSelected={this.handleOptions}
                                        disabled={this.state.editField}
                                        PaperComponent={(props) => {
                                            return (
                                                <PaperStyled
                                                    {...props}
                                                />
                                            )
                                        }}
                                        value={this.state.cityName}
                                    />
                                    { this.state.cityNameAError && (
                                    <Typography style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}>
                                        {" "} {this.state.cityNameAError}{" "}
                                    </Typography>
                                    )}
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Street
                                        </InputLabel>
                                    </Box>
                                    <Input data-test-id="txtInputstreetName" placeholder={"Street"} fullWidth={true}
                                        disableUnderline
                                        className={`${this.props.classes.inputMainprofileCountrySelect}`}
                                        {...this.txtInputStreetName}
                                        value={this.state.street}
                                        disabled={this.state.editField}
                                    />
                                </Box>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Zip Code
                                        </InputLabel>
                                    </Box>
                                    <Input data-test-id="txtInputzipcode" placeholder={"Zip Code"} fullWidth={true}
                                        disableUnderline
                                        className={`${this.props.classes.inputMainprofileCountrySelect}`}
                                        {...this.txtInputZipCode}
                                        value={this.state.zipCode}
                                        disabled={this.state.editField}
                                    />
                                </Box>
                                <Box className={classes.emptyHalf}/>
                            </Box>
                        </Box>

                        <Box className="fields-container" sx={{ margin: '15px 0px' }}>
                            <Box className={`${classes.addressContainer}`}>
                                <Typography className={`${classes.genralTxt}`}>Locale Information</Typography>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Language
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <StyledDropdown
                                        key={0}
                                        disableUnderline
                                        limitTags={1}
                                        sx={{ fontSize: "12px" }}
                                        data-test-id="txtInputlaguage"
                                        options={this.languageList}
                                        getOptionLabel={(option)=>option}
                                        onChange={(_,value)=>this.textInputprofilelanguage.onChange(value)}
                                        renderInput={(params)=>this.renderAutocompleteInput(params,this.state.language,"txtInputlaguage")}
                                        getOptionSelected={this.handleAutocompleteOptions}
                                        disabled={this.state.editField}
                                        PaperComponent={(props) => {
                                            return (
                                                <PaperStyled
                                                    {...props}
                                                />
                                            )
                                        }}
                                        value={this.state.language}
                                    />
                                     {this.state.languageError && (
                                        <Typography
                                            style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                        >
                                            {" "}
                                            {this.state.languageError}{" "}
                                        </Typography>
                                    )}
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Date Format
                                        </InputLabel>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            {...this.txtInputDateFormate}
                                            value={this.state.dateFormate}
                                            disableUnderline
                                            className={classes.inputMainCountrySelectPBio}
                                            renderValue={this.dateFormatPlaceholder()}
                                            MenuProps={menuPropsPBio}
                                            displayEmpty
                                            data-test-id="txtInputdateFormate"
                                            disabled={this.state.editField}
                                        >
                                            {this.dateFormats.map((data) => (
                                                <MenuItem key={data} value={data}>{data}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>

                            <Box className={`profile-field-main-box ${classes.profileFieldMainBox}`}>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Time Format
                                        </InputLabel>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            {...this.textTimeZoneProps}
                                            value={this.state.timeFormate}
                                            disableUnderline
                                            className={classes.inputMainCountrySelectPBio}
                                            renderValue={this.timeFormatPlaceholder()}
                                            MenuProps={menuPropsPBio}
                                            displayEmpty
                                            data-test-id="txtInputTimeFormate"
                                            disabled={this.state.editField}
                                        >
                                            {this.timeFormats.map((data) => (
                                                <MenuItem key={data} value={data}>{data}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box className={`${classes.profileFiledGroupBox}`}>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                        <InputLabel className={`${classes.emailprofileLabelText}`}>
                                            Time Zone
                                        </InputLabel>
                                        <Typography component="div" variant="subtitle1" className={`${classes.requiredRedColor}`}>*</Typography>
                                    </Box>
                                    <StyledDropdown
                                        key={0}
                                        disableUnderline
                                        limitTags={1}
                                        sx={{ fontSize: "12px" }}
                                        data-test-id="txtInputTimeZone"
                                        options={this.timeZoneArray}
                                        getOptionLabel={(option)=>option}
                                        onChange={(_,value)=>this.textInputprofileTimeZone.onChange(value)}
                                        renderInput={(params)=>this.renderInput(params,this.state.timeZone,"txtInputTimeZone")}
                                        getOptionSelected={this.handleOptions}
                                        disabled={this.state.editField}
                                        PaperComponent={(props) => {
                                            return (
                                                <PaperStyled
                                                    {...props}
                                                />
                                            )
                                        }}
                                        value={this.state.timeZone}
                                    />
                                    {this.state.timeZoneAError && (
                                        <Typography
                                            style={{ color: "#ff6666", fontSize: "12", marginBottom: "5px" }}
                                        >
                                            {" "}
                                            {this.state.timeZoneAError}{" "}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                        </Box>
                        {
                            !this.state.editField && (
                                <Box className={`buttons-container ${classes.saveChangesbtn}`}>
                                    <Box>
                                        <Button data-test-id='saveClickBTn' className={`save-button ${classes.savebtnText}`} {...this.saveEditField}>Save Changes</Button>
                                    </Box>
                                    <Box>
                                        <Button data-test-id='cancleCilckBtn' className={`cancel-button ${classes.cancelbtnText}`} {...this.cancleEditField}>Cancel</Button>
                                    </Box>
                                </Box>
                            )
                        }

                        <Modal
                            open={this.state.open}
                            {...this.handleClose}
                            data-test-id='closemodal'
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box className={`${classes.modalContainer}`}>
                                <Box className={`${classes.closeimg}`} data-test-is='closeMOdalBtn' {...this.handleCloseClick}>
                                    <img src={closeImg} alt="" style={{ width:"24px" }} />
                                </Box>
                                <Box className={`${classes.successImgStyle}`}>
                                     <img src={SuccessImg} alt="" style={{ width:"48px" }} />
                                </Box>
                                <Box className={`${classes.centerTxt}`}>
                                    <Typography className={`${classes.successTxt}`}>Successfully updated</Typography>
                                    <Typography className={`${classes.descriptionTxt}`}>Your changes has been successfully saved!</Typography>
                                </Box>
                                <Box className={`${classes.cpuntinueBtnContainer}`}>
                                    <Button className={`${classes.continueBtn}`} data-test-id='countinueCilckBtn' {...this.countinueClick}>Continue</Button>
                                </Box>
                            </Box>
                        </Modal>
                    </Box>
                </Box>
                </Layout>
            </StyledContainer>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export const ProfileBioStyles = (theme: any) => ({
    rootPBio:{
        marginTop:"6px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
        "& ul":{
            padding:"5px"
        },
        "& ul li":{
            borderRadius:"8px",
            fontSize: "14px",
            padding:"9px 20px"
        },
        "& ul li:hover, & MuiSelect-select:focus, & li.MuiListItem-root.Mui-selected, li.MuiListItem-root.Mui-selected:hover":{
            backgroundColor:"rgba(241, 245, 249, 1)!important"
        },
    },
    inputMainCountrySelectPBio: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "Inter, sans-serif",
        "& .MuiSelect-select:focus":{
            backgroundColor:"transparent"
        }
    },
    BoxmainContainer: {
        display: 'flex',
        gap: '48px',
        background: '#E5E5E5',
        [theme.breakpoints.down("md")]: {
          width: "114%"
        },
        overflowY: "visible"
    },
    countryCodeMainBox: {
        width: "auto"
    },
    centerBgColor: {
        background: '#FFFFFF',
        borderRadius: '8px',
        marginBottom: '34px',
        [theme.breakpoints.down("md")]: {
            width: "auto"   
        },

    },
    leftSideContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '34px',
        [theme.breakpoints.down("md")]: {
            width: "100%"   
        },
    },
    btnEdittext: {
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        backgroundColor: 'transparent',
        borderRadius: '8px',
        width: '81px',
        height: '32px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
        },
        '& p': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            color: '#64748B',
            textTransform: 'capitalize',
        },
    },
    genralTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '28px',
        color: '#0A0528',
        textTransform: 'uppercase',
    },
    infoheaderContainer: {
        marginBottom: '32px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    addressContainer: {
        display: 'flex',
        marginRight: '32px',
        marginBottom: '32px',
        justifyContent: 'space-between',
    },
    profileFieldMainBox: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "20px",
    },
    profileFiledGroupBox: {
        paddingBottom: "15px",
        width: "50%",
    },
    emailprofileLabelText: {
        marginBottom: "5px",
        color: "#334155",
        fontFamily: "'Inter', sans-serif",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "22px",
    },
    profileinputTxtMain: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
    },
    profileinputdark: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        background: '#F1F5F9',
    },
    mainBottomPart: {
        // margin: '36px 32px 0 32px',
    },
    countryprofileplaceholder: {
        color: "#aaa",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        fontFamily: "'Inter', sans-serif",
    },
    inputMainprofileCountrySelect: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "Inter, sans-serif",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
        
    },
    inputMaindarkCountrySelect: {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "44px",
        width: "100%",
        fontFamily: "Inter, sans-serif",
        background: '#F1F5F9',
    },
    radioBox: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginLeft: "-16px",
        lineHeight: "16px",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        color: '#64748B',
        width: '80px',
    },
    radioMain: {
        display: 'flex',
        gap: '6px',
    },
    requiredRedColor: {
        color: 'red',
    },
    savebtnText: {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: "700",
        textTransform: "capitalize",
        borderRadius: "10px",
        width: '144px',
        height: '44px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
    },
    saveChangesbtn: {
        marginBlock: '32px',
        display: 'flex',
        gap: '16px',
    },
    cancelbtnText: {
        background: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid',
        height: '44px',
        width: "87px",
        fontSize: "16px",
        fontWeight: "700",
        borderImageSource: 'linear-gradient(90deg, #364161 0 %, #06081F 100 %)',
        padding: '7px 12px',
        color: "#000000",
        textTransform: "capitalize",
        fontFamily: "Inter, sans-serif",
    },
    modalContainer: {
        background: '#FFFFFF',
        boxShadow: '0px 25px 50px rgba(0, 0, 0, 0.09), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
        borderRadius: '8px',
        width: '409px',
        height: '280px',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        padding: '16px 17px'
    },
    centerTxt: {
        display: 'flex',
        justifyCOntent: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
    },
    successTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '26px',
        color: '#0A0528',
    },
    descriptionTxt: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        color: '#64748B',
        marginTop: '12px',
    },
    closeimg: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
    },
    successImgStyle: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',
    },
    cpuntinueBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '32px',
    },
    continueBtn: {
        background: '#FFFFFF',
        borderRadius: '8px',
        width: '104px',
        height: '44px',
        border: '1px solid',
        borderColor: 'linear-gradient(90deg, #364161 0%, #06081F 100%)',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        color: '#000000',
        textTransform: 'capitalize',
    },
    mobileInputWrapper1: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #dfdfdf',
        borderRadius: '10px',
        height: '42px'
    },
    inputMainMobileSelect1: {
        borderRadius: "10px",
        fontFamily: "'Inter', sans-serif",
        height: '45px',
        paddingBlock: '10px',
        width: 'fit-content',
        paddingLeft: '10px'
    },
    inputMobile1: {
        borderRadius: "10px",
        height: "44px",
        width: "auto",
        fontFamily: "'Inter', sans-serif",
    },
    emptyHalf:{
        width:"50%"
    }
})

const PaperStyled = styled(Paper)({
    "&": {
        padding:"10px",
        borderRadius:"8px",
        boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& ul":{
        padding:"10px 5px"
    },
    "& ul li":{
        borderRadius:"8px",
        fontSize: "14px",
        padding:"9px 20px"
    },
    "& ul li:hover, & .MuiAutocomplete-option[aria-selected='true'], & .MuiAutocomplete-option:hover":{
        backgroundColor:"rgba(241, 245, 249, 1)"
    },
});

//@ts-ignore
export default withStyles(ProfileBioStyles)(ProfileBio);

  // Customizable Area End