import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IOpportunitiesDealListAttributeAll } from "./types";
import { Message } from "../../../framework/src/Message";
import createRequestMessage from "../../../framework/src/create-request-message";
import { handleResponseMessage } from "../../../framework/src/handle-response-message";
export const configJSON = require("./config");
const currencyToSymbolMap = require('currency-symbol-map/map');

// Customizable Area Start
export interface Props {
  navigation: any;
  id: string;
  toggleFunction:any,
  classes:any,
  parentState:any
  handlefilterApply: (path: string) => void;
  handleFilterFieldCheck: (index: number, label: string) => void;
  handleTextInputChange:  (setValueComnFun: any) => (event: any) => void;
  handleSelectInputChange: (setValueComnFun: any,field:string) => (event: any) => void;
  handleMulipleSelectInputChange: (setValueComnFun: any) => (event: any) => void;
  handleNumberInputChange: (setValueComnFun: any,field:string) => (event: any) => void;
  handleExpectedRevanuFilter: (value: string,index:number) => void;
  handleClearfilter: (label: string,removeType:string) => void;
  updateTypeFilter: (value: any,keyState: any) => void
  handleCurrencySelect: (label: string) => void;
  handleDrawerSarch: (label: string) => void;
  // Customizable Area End
}

interface S {
 
    anchor:any
    open:boolean
    ifNoDealRecoreds:boolean
    searchText:string;
    toggle:boolean,
    selectedDate:any
    sortBy: string | null;
    sortDirection: 'asc' | 'desc';
    opportunityList: IOpportunitiesDealListAttributeAll[];
    token: string;
    currentPage: number;
    perPage:number
    listofFilterArray: any[];
    listType: boolean;
    isDealRegistraion: boolean;
    dealRegisrationAnchorEl: null | HTMLElement;
    opportunityId:string;
    isDeleteModalOpen: boolean;
    opportunityDealIdName:string;
    opportunityListMeta:any
    numberOfButtons:any,
    tokenLStorage:any,
    isLoadingOppDeal: boolean
    proposedAmount:number
    isChecked: any[],
    firstNameFilter: string ;
    dealOwnerNameFilter:string;
    inrCurrencyFilter:string;
    dealNameFilter: string ;
    leadSourceFilter: any[],
    stageNameFilter: [],
    probabilityFilter: string;
    typeFilter: [],
    operatorCDateFilter: string;
    numberCDFilter: string;
    durationCdFilter: string; 
    operatorCAateFilter: string;
    numberCAFilter: string;
    durationCAFilter: string;  
    operatorOperatorFilter: string;  
    valueOpratorFilter: string;  
    minRevenueFilter: string;  
    expectedRevanuAmountFilter: string;  
    maxRvenueFilter: string;  
    tokenlocalSr:any;
    LastNameFilter:string ;
    amountFilter:string ;
    filterData:any[]
    filterBarData:any[]
    operatorAMFilter:string;
    checkBoxStore:any[]
    [setValueComnFun: string]: any;
    filteredBoxShow:boolean;
    filterErrorMsg:string;
    ClosingDateArray:any[];
    probabilityArray:any[];
    globleInputFieldValCheck:boolean;
    selectedOptions:any[];
    checkedItems:any;
    amountFilterFrom:string;
    amountFilterTo:string;
    createAtDateFirst:string;
    createAtDateSecund:string;
    ClosingDateFirst:string;
    ClosingDateSecund:string
    expectedRevenueCheck:any;
    currencyFormArry:any[];
    filterPayload:object|any
    pageNumberSet:any;
    pagneNumberValidation:boolean;
    inrCurrencyFilterValue:string;
    listofFilterArrayError:string;
    filterListValue:string;
    listSearchValue:string
    listSearchError:string;
    isAnyCheckBoxChecked: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfpipelinemanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllOpportunityListApiCallId: string | null;
  getAllDealListApiCallId: string | null;
  searchAllOpportunityListApiCallId: string | null;
  searchAllDealListApiCallId: string | null;
  timeout: ReturnType<typeof setTimeout>;
  filterListApiCallId: string | null;
  proposedAmountApiCallId:string | null;
  sortOppListApiCallId:string | null;
  isFilterActivate:boolean;
  isInitialClickApplayfilter: boolean;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
  ];

    this.state = {
      anchor: 'right', 
      open: false,
      ifNoDealRecoreds:false,
      searchText:'',
      toggle:false,
      selectedDate: new Date(),
      sortBy: "created_date",
      sortDirection: 'desc',
      opportunityList: [],
      token: "",
      currentPage: 1,
      perPage:10,
      listofFilterArray:configJSON.listofFilter,
      listType:true,
      isDealRegistraion:false,
      dealRegisrationAnchorEl:null,
      opportunityId:'',
      isDeleteModalOpen: false,
      opportunityDealIdName:'',
      opportunityListMeta:'',
      numberOfButtons:[],
      tokenLStorage:'',
      isLoadingOppDeal: false,
      proposedAmount:0,
      isChecked: new Array(configJSON.listofFilter.length).fill(false),
      firstNameFilter:"",
      dealNameFilter:"",
      dealOwnerNameFilter:"",
      inrCurrencyFilter:"",
      leadSourceFilter:[],
      stageNameFilter: [],
      probabilityFilter: "",
      typeFilter: [],
      LastNameFilter:"",
      amountFilter:"",
      operatorAMFilter:"",
      filterData: [],
      filterBarData:[],
      operatorCDateFilter: "",
      numberCDFilter: "",
      durationCdFilter: "",
      operatorCAateFilter: "",
      numberCAFilter: "",
      durationCAFilter: "",
      operatorOperatorFilter: "",
      valueOpratorFilter: "",
      minRevenueFilter: "",  
      maxRvenueFilter: "",  
      tokenlocalSr: "",
      expectedRevanuAmountFilter:"",
      checkBoxStore:[],
      setValueComnFun:"",
      filteredBoxShow:false,
      filterErrorMsg:'',
      ClosingDateArray:configJSON.ClosingDate,
      probabilityArray:configJSON.ProbabiltiyRange,
      globleInputFieldValCheck:false,
      selectedOptions:[],
      checkedItems:{},
      amountFilterFrom:"",
      amountFilterTo:"",
      createAtDateFirst:"",
      createAtDateSecund:"",
      ClosingDateFirst:"",
      ClosingDateSecund:"",
      expectedRevenueCheck:-1,
      currencyFormArry :Object.entries(currencyToSymbolMap).map(([key])=>{return key;}),
      isFilterActivate:false,
      filterPayload:'',
      pageNumberSet:1,
      pagneNumberValidation:false,
      inrCurrencyFilterValue:'',
      listofFilterArrayError:'',
      filterListValue:'',
      listSearchError:'',
      listSearchValue:'',
      isAnyCheckBoxChecked:false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getAllOpportunityListApiCallId = "";
    this.getAllDealListApiCallId = "";
    this.searchAllOpportunityListApiCallId = "";
    this.searchAllDealListApiCallId = "";
    this.timeout = setTimeout(() => { });
    this.filterListApiCallId = ""
    this.proposedAmountApiCallId="";
    this.sortOppListApiCallId= "";
    this.isFilterActivate=false;
    this.isInitialClickApplayfilter=false
    // Customizable Area End
  }
  
  async receive(_from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage),
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage),
        );
        const errorJson = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage),
        );
        switch (apiRequestCallId) {
            case this.getAllOpportunityListApiCallId: {
                this.getAllOpportunityListApiCallId = null;
                handleResponseMessage({
                    responseJson,
                    errorJson,
                    onSuccess: () => {
                      setTimeout(() => {
                        this.setState({
                            isLoadingOppDeal: false,
                            opportunityList: responseJson.data,
                            opportunityListMeta:responseJson.meta   
                          });
                          this.handleNumberOfButton()
                      }, 2000); 
                    },
                    onFail: () => this.showAlert(`${responseJson.errors[0].message}`, "Please retry!"),
                });
                break;
            }
            case this.getAllDealListApiCallId: {
              this.getAllDealListApiCallId = null;
              handleResponseMessage({
                  responseJson,
                  errorJson,
                  onSuccess: () => {
                    setTimeout(() => {
                      this.setState({
                          isLoadingOppDeal: false,
                          opportunityList: responseJson.data,
                          opportunityListMeta:responseJson.meta   
                        });
                        this.handleNumberOfButton()

                    }, 2000);                   },
                  onFail: () => this.showAlert(`${responseJson?.errors[0]?.message}`, "Please retry!"),
                });
              break;
          }
          case this.searchAllOpportunityListApiCallId: {
            this.searchAllOpportunityListApiCallId = null;
            handleResponseMessage({
                responseJson,
                errorJson,
                onSuccess: () => {
                  this.setState({
                      isLoadingOppDeal: false,
                      opportunityList: responseJson.data,
                      opportunityListMeta:responseJson.meta   
                  });
                  this.handleNumberOfButton()
                },
                onFail: () => this.showAlert(`${responseJson?.errors[0]?.message}`, "Please retry!"),
            });
            break;
        }
        case this.proposedAmountApiCallId: {
          this.proposedAmountApiCallId = null;
          handleResponseMessage({
              responseJson,
              errorJson,
              onSuccess: () => {
                  this.setState({isLoadingOppDeal:false})
                  this.getAllOpportunityList()
              },
              onFail: () => {
                this.setState({isLoadingOppDeal: false})
                this.showAlert(`${responseJson?.errors[0]?.message}`, "Please retry!");
              } 
            });
          break;
      }

         case this.filterListApiCallId: {
          this.filterListApiCallId = null;
          handleResponseMessage({
              responseJson,
              errorJson,
              onSuccess: () => {
                  this.setState({ 
                    isLoadingOppDeal:false ,
                    opportunityList: responseJson.data, 
                    opportunityListMeta:responseJson.meta,  
                  });
                  this.handleDefultPages()
                  this.handleNumberOfButton()
              },
              onFail: () => { 
                this.showAlert(`${responseJson?.meta.message}`, "Please retry!");
                this.setState({isLoadingOppDeal: false})
              }            
          });
          break;
          }
        
      }
    }
}

  handleTableSearch = (event:any) => {
    const searchText = event.target.value;
    this.setState({
        searchText,
    });
};

  toggleDrawer = (open:any) => (event:any) => {
    const { toggle , isChecked ,filterData} = this.state 
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    const ifCheckedAnyTrue = isChecked.some((item:any)=> item === true)
    this.setState({ toggle: open,globleInputFieldValCheck:false });
    
    if (toggle) {
      if (ifCheckedAnyTrue) {
          this.handleFilterApply();
      }
      else if (this.isFilterActivate) {
          this.isFilterActivate = false;
          this.setState({
              filterBarData: filterData,
              globleInputFieldValCheck: false,
          }, () => {
              this.handleDefultPages();
              this.handleGetPreLoadApiCall();
          });
      }
  }
  };


  handleNavigation = () => {
    this.props.navigation.navigate('CreateOpportunities');
  };  

  handleSortClick = (column: any) => {
    const currDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc'
    const newSortDirection = this.state.sortBy === column ? currDirection : 'asc';
    this.setState({
      sortBy: column,
      sortDirection: newSortDirection,
      currentPage: !this.isFilterActivate ? 1 : this.state.currentPage, // Set to first page if sorted
      isLoadingOppDeal: true
    } , ()=> {
      this.getSortedData(column,newSortDirection)
    });
  };

  async componentDidMount() {
    this.handleGetPreLoadApiCall()
    const data:any = sessionStorage.getItem('token')
    let id;
    try {
      id = JSON.parse(data)
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error('Invalid JSON:', error.message);
      } else {
        throw error;
      }
    }
    this.setState({tokenLStorage:id?.token,})
  }

  

  async componentDidUpdate(prevProps:any, prevState:any) {
    if (prevState.isChecked !== this.state.isChecked) {
      const toCheckClearAll = this.state.isChecked.some((item) => item === true);
      this.setState({ isAnyCheckBoxChecked: toCheckClearAll });
    }

  }
  
  getAllOpportunityList = ( ) => {
    this.setState({ isLoadingOppDeal: true})
    const data:any = sessionStorage.getItem('token')
      let id;
      try {
        id = JSON.parse(data)
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.error('Invalid JSON:', error.message);
        } else {
          throw error;
        }
      }
      
    const header = {
        token: id?.token,
    };

    const OppBodyType: { type: string; page: number; per_page: number; filters?: any } = {
      type: "opportunity",
      page: this.state.currentPage,
      per_page: this.state.perPage,
    };
    
    if (this.isFilterActivate) {
      OppBodyType.filters = this.state.filterPayload.filters;
    } 

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
    );
  
    this.getAllOpportunityListApiCallId = requestMessage.messageId;
    const endPointType = configJSON.opportunityListEndPoint

     createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint:endPointType ,
      method: configJSON.postAPiMethodType,
      body: JSON.stringify(OppBodyType)
    });
  }
  getAllDealList = () => {
    this.setState({ isLoadingOppDeal: true})
    const data:any = sessionStorage.getItem('token')
    let id;
    try {
      id = JSON.parse(data)
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error('Invalid JSON:', error.message);
      } else {
        throw error;
      }
    }
    
    const header = {
        token: id?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    const dealBodyType: { type: string; page: number; per_page: number; filters?: any } = {
      type: "deal",
      page: this.state.currentPage,
      per_page: this.state.perPage,
    };
    
    if (this.isFilterActivate) {
      dealBodyType.filters = this.state.filterPayload.filters;
    } 
    this.getAllDealListApiCallId = requestMessage.messageId;
    const endPointType = configJSON.opportunityListEndPoint

    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint:endPointType ,
      method: configJSON.postAPiMethodType,
      body: JSON.stringify(dealBodyType)
    });

  }

  handleBlurEvent = () => {
    this.setState({
       listSearchValueError: "",
    })
    
  }
  handleInputChangeOpp = (value: string) => {
    let filteredText = value.replace(/^\s+/, '').replace(/\s+/g, ' ');
    this.setState({listSearchValue :filteredText ,
       listSearchValueError :filteredText !== value ? true : false,
    })
    
    let condition = filteredText !== value ? true : false
    if ( condition   )  return 

    this.setState({listSearchValue :filteredText ,
      isLoadingOppDeal:true ,
      currentPage:1,pageNumberSet:1,
    })
 

    if (this.timeout ) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      const data:any = sessionStorage.getItem('token')
      let id;
      try {
        id = JSON.parse(data)
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.error('Invalid JSON:', error.message);
        } else {
          throw error;
        }
      }
      
      const header = {
          token: id?.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      let dataType = sessionStorage.getItem("DealRegistration");
      this.searchAllOpportunityListApiCallId = requestMessage.messageId;

      const searchBody: {
        search: object | any;
        type: string | any;
        page: number;
        per_page: number;
        filters ? : any;
    } = {
        search: {
            query: filteredText.trim(),
        },
        type: dataType,
        page: this.state.currentPage,
        per_page: this.state.perPage,
    };

    if (this.isFilterActivate) {
        searchBody.filters = this.state.filterPayload.filters;
    }
      
      createRequestMessage({
          header,
          requestMessage: requestMessage,
          endPoint: configJSON.opportunityListEndPoint,
          method: configJSON.postAPiMethodType,
          body: JSON.stringify(searchBody)
      });
    }, 1000)
  }
 
  handleGetOpportunitesRecord =  (opportunityData: any)=> {
   
    const editViewData ={
        opportunityId :opportunityData.id,
        pageMode:"View",
        status:opportunityData?.attributes?.status,
        type:opportunityData?.type
    } as any
    sessionStorage.setItem('opportunityData', JSON.stringify(editViewData));
    
    let path = "ViewOpportunity" 
    if (opportunityData?.attributes?.status !== "rejected" && opportunityData?.type === "opportunity") {
        path = "EditOpportunity"
    }
    this.props.navigation.navigate(path);

  }

  OppDealAction =(event:any,item:any)=> {
    this.setState({
        isDealRegistraion:true,
        dealRegisrationAnchorEl: event.currentTarget,
        opportunityId:item.id,
        opportunityDealIdName:item.attributes.opportunity_id,
        proposedAmount:item.attributes.proposed_amount 
    })
  }

  handleDealActionMenuClose= ()=> {
    this.setState({
        isDealRegistraion:false,
        dealRegisrationAnchorEl: null
      })
  }

  handleEditOppDealRecord = ()=> {
    this.props.navigation.navigate('EditOpportunity');
    const editViewData ={
        opportunityId :this.state.opportunityId,
        pageMode:"Edit"
    } as any
    sessionStorage.setItem('opportunityData', JSON.stringify(editViewData));
  }

  handleProposedAmountModelOpen = ( ) => {
    this.setState({isDeleteModalOpen:true})
  }

  handleCloseModalOpp = () => {
    this.setState({ isDeleteModalOpen: false })
    this.handleDealActionMenuClose()
  }

  handleProposedAmountRecords = () => {
    const { proposedAmount, opportunityId }=this.state
    this.setState({isLoadingOppDeal: true})
    const data:any = sessionStorage.getItem('token')
      let id;
      try {
        id = JSON.parse(data)
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.error('Invalid JSON:', error.message);
        } else {
          throw error;
        }
      }
      
      const header = {
        token: id?.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
    );
    this.proposedAmountApiCallId = requestMessage.messageId;
    const proposedAmountData ={
      "opportunity": {
        "proposed_amount" : proposedAmount,
        "status" : "accepted"
      }
    }
    createRequestMessage({
      header,
      requestMessage: requestMessage,
      endPoint: `${configJSON.proposedAmountEndPoint}/`+opportunityId+`/set_proposed_amount`,
      method: configJSON.putAPiMethodType,
      body: JSON.stringify(proposedAmountData)
    });
    this.setState({ isDeleteModalOpen: false , })
    this.handleDealActionMenuClose()

  }

  handlePagination = (event:any) => {
      const perpage = event.target.value;
      this.setState({pageNumberSet:1 , perPage: perpage, currentPage:1, isLoadingOppDeal: true }, () => {
        this.handleGetPreLoadApiCall()
      })
  };

  handlePageNextPreviousBtn = (isNext:any) => {
      const { currentPage ,numberOfButtons } = this.state;
      let updatedPage:any
      if (isNext === "nextPage" && currentPage < numberOfButtons  ) {
          updatedPage = currentPage + 1;
      }
      else if (isNext === "previousPage" && currentPage > 1) {
          updatedPage = currentPage - 1;
      }
      if (updatedPage !== undefined) {
          this.setState({ pageNumberSet : updatedPage, currentPage: updatedPage, isLoadingOppDeal: true }, () => {
              let tabValue = sessionStorage.getItem("DealRegistration");
              if (tabValue === "opportunity") {
                  this.getAllOpportunityList();
              }
              else {
                  this.getAllDealList();
              }
          });
      }
  };

  handleNumberOfButton = () => {
    const { opportunityListMeta ,perPage } = this.state;
    const totalRecords = parseInt(opportunityListMeta?.total_opportunity_count)
    const numberOfButtons = Math.ceil(totalRecords / perPage);
    this.setState({ numberOfButtons });
  };

  getSortedData = (sortBy:string,sortOrder:string) => {
      const { tokenLStorage ,listType} = this.state;
      const header = {
          token: tokenLStorage,
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getAllOpportunityListApiCallId = requestMessage.messageId;

      const oppDealType= listType ? "opportunity" : "deal"

      const oppBodyType: {
        sort: object | any;
        type: string | any;
        page: number;
        per_page: number;
        filters ? : any;
    } = {
        sort: {
            column: sortBy,
            order: sortOrder,
        },
        type: oppDealType,
        page: this.state.currentPage,
        per_page: this.state.perPage,
    };
    if (this.isFilterActivate) {
        oppBodyType.filters = this.state.filterPayload.filters;
    }
 
      createRequestMessage({
          header,
          requestMessage: requestMessage,
          endPoint: configJSON.opportunityListEndPoint,
          method: configJSON.postAPiMethodType,
          body: JSON.stringify(oppBodyType)
      });
      this.setState({ isDeleteModalOpen: false, });
  };

  // filter Block

  handleFilterFieldCheck = (index: number, ftrLabel: any) => {
    this.setState((prevState) => {
      const isCheckedCopy = [...prevState.isChecked];
      isCheckedCopy[index] = !isCheckedCopy[index];
      const filterDataCopy = [...prevState.filterData];
      const keyToRemove = ftrLabel.key;
      const existingItemIndex = filterDataCopy.findIndex((item) => item.key === keyToRemove);
  
      if (isCheckedCopy[index]) {
        if (existingItemIndex === -1) {
          const newObj = {
            key: ftrLabel.key,
            label: ftrLabel.label,
          };
          filterDataCopy.push(newObj);
        }
      } else {
        if (existingItemIndex !== -1) {
          filterDataCopy.splice(existingItemIndex, 1);
        }
        this.handleSingleClearfilter(keyToRemove)
      }
  
      return {
        isChecked: isCheckedCopy,
        filterData: filterDataCopy,
        globleInputFieldValCheck:false
      };
    });
  };

  handleSingleClearfilter = (keyToRemove: string) => {
    const clearedState: Record<string, string | number> = {};
  
    switch (keyToRemove) {
      case "account_name":
        clearedState.firstNameFilter = "";
        this.setState({['firstNameFilterError']:'',})
        break;
      case "deal_owner":
        clearedState.dealOwnerNameFilter = "";
        this.setState({['dealOwnerNameFilterError']:'',})
        break;
      case "amount":
        clearedState.operatorAMFilter = "";
        clearedState.amountFilter = "";
        clearedState.amountFilterFrom = "";
        clearedState.amountFilterTo = "";
        this.setState({
          ['amountFilterError']:'',
          ['amountFilterFromError']:'',
          ['amountFilterToError']:'',
        })

        break;
      case "bussiness_type":
        clearedState.typeFilter = "";
        break;
      case "probability":
        clearedState.probabilityFilter = "";
        this.setState({['probabilityFilterError']:'',})
        break;
      case "stage":
        clearedState.stageNameFilter = "";
        break;
      case "lead_source":
        clearedState.leadSourceFilter = "";
        break;
      case "deal_name":
        this.setState({['dealNameFilterError']:'',})
        clearedState.dealNameFilter = "";
        break;
      case "currency":
        clearedState.inrCurrencyFilter = "";
        clearedState.inrCurrencyFilterValue= "";
        break;
      case "closing_date":
        clearedState.operatorCDateFilter = "";
        clearedState.numberCDFilter = "";
        clearedState.durationCdFilter = "";
        clearedState.ClosingDateSecund = "";
        clearedState.ClosingDateFirst = "";
        break;
      case "created_at":
        clearedState.operatorCAateFilter = "";
        clearedState.numberCAFilter = "";
        clearedState.durationCAFilter = "";
        clearedState.createAtDateFirst = "";
        clearedState.createAtDateSecund = "";
        break;
        case "expected_revenue":
        clearedState.minRevenueFilter = "";
        clearedState.maxRvenueFilter = "";
        clearedState.durationCAFilter = "";
        this.setState({
          ['minRevenueFilterError']:'',
          ['maxRvenueFilterError']:'',
          ['durationCAFilterError']:'',
           expectedRevenueCheck:-1

      })
        break;
      default:
        break;
    }
  
    this.setState(clearedState);
  };
  
  
  handleDrawerSarch = (event:any) => {
    const inputValue = event.target.value;
    let filteredText = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
    filteredText = filteredText.replace(/ +/g, ' ');   

    this.setState({ filterListValue: filteredText, listofFilterArrayError: filteredText !== event.target.value ? 'Please enter alphabet only' : '', });
    
    const matchingLabels = configJSON.listofFilter.filter((item:any) => {
        const label = item.label.toLowerCase();
        const input = filteredText.toLowerCase();
        return label.includes(input) || label.startsWith(input);
    });
    if (matchingLabels.length > 0) {
        this.setState({ listofFilterArray: matchingLabels });
    }
  };

  handleSelectInputChange = (setValueComnFun:any,field:string) => (event:any) => {
    const selectedOptions = event.target.value || [];

    this.setState({
        [setValueComnFun]: selectedOptions,
        globleInputFieldValCheck:false
      } ,()=>{
        if(field === "amount"){
          this.setState({
            amountFilter:"",
            amountFilterFrom:"",
            amountFilterTo:"",
            ['amountFilterError']:'',
            ['amountFilterFromError']:'',
            ['amountFilterToError']:'',
          })
        }
        if(field === "closing_date"){
          this.setState({
            numberCDFilter:"",
            durationCdFilter:"",
            ClosingDateFirst:"",
            ClosingDateSecund:"",
            ['numberCDFilterError']:'',
          })
        }
        if(field === "created_at"){
          this.setState({
            numberCAFilter:"",
            durationCAFilter:"",
            createAtDateFirst:"",
            createAtDateSecund:"",
            ['numberCAFilterError']:'',
          })
        }
    });
  };

  handleMulipleSelectInputChange = (setValueComnFun:any) => (event:any) => {
    const selectedOptions = event.target.value || [];

    this.setState({
      [setValueComnFun]: selectedOptions,
    });
  };

  handleCheckboxChange = (value:any) => {
    this.setState((prevState) => ({
      checkedItems: {
        ...prevState.checkedItems,
        [value]: !prevState.checkedItems[value], 
      },
    }));
  };

  handleNumberInputChange = (setValueComnFun:any,field:string) => (event:any) => {
    const filteredText = event.target.value.replace(/\D/g, "");
    this.setState({
        [setValueComnFun]: filteredText,
        [`${setValueComnFun}Error`]: filteredText !== event.target.value ? 'Please enter number Only' : '',

    }
    ,()=>{
        if(field === "expected_revenue"){
          this.setState({
            expectedRevenueCheck:-1
          })
        }
     
    }
    );
  };

  handleTextInputChange = (setValueComnFun:any) => (event:any) => {
    const inputValue = event.target.value;
    let filteredText = inputValue.replace(/[^a-z ]/gi, '').replace(/^ +/, '');              
    filteredText = filteredText.replace(/ +/g, ' ');   

    this.setState({
        [setValueComnFun]: filteredText,
        [`${setValueComnFun}Error`]: filteredText !== event.target.value ? 'Please enter alphabet only' : '',

    });
  };

  handleExpectedRevanuFilter = (obj:any,index:number) => {
    const amountMinMax = obj.value.split('-');
    this.setState({
        minRevenueFilter: amountMinMax[0],
        maxRvenueFilter: amountMinMax[1],
        expectedRevenueCheck:index
     } 
    );
  };

  handleFilterApply = () => {

    const {filterData} = this.state;
    
    if(filterData.length > 0 ) {
      this.handlefilterBodymaker(filterData)
      this.setState({globleInputFieldValCheck:true})

    }
    else {
      this.setState({ 
        toggle:false,  
        filterErrorMsg: "" ,
        filterBarData : filterData,
        globleInputFieldValCheck:false 
    });
        this.isFilterActivate=false
        if(this.state.isAnyCheckBoxChecked){
            this.handleGetPreLoadApiCall();
        }
        if(this.isInitialClickApplayfilter){
          this.handleGetPreLoadApiCall();
        }
    }
  
    //Note:
    //isChecked ,It is hold the checkBox either true or false
    // filterBarData, This is use only show filter selected on table.
    // filterData,This is use for makeing payload only. 
    // data, It is hold the  acutual playload
  };

  handlefilterBodymaker = (filterDataPara: any) => {
    const {
        filterData,
        listType,
        ...otherStateVariables
    } = this.state;

    const oppDealType = listType ? "opportunity" : "deal";

    const data = {
        filters: {},
        page: 1,
        per_page: this.state.perPage,
        type: oppDealType,
    };

    const conditions = {
        "account_name": false,
        "deal_owner": false,
        "amount": false,
        "closing_date": false,
        "created_at": false,
        "currency": false,
        "deal_name": false,
        "lead_source": false,
        "stage": false,
        "expected_revenue": false,
        "probability": false,
        "bussiness_type": false,
    } as any;

    if (Array.isArray(filterDataPara)) {
        filterDataPara.forEach((item: any) => {
            if (item.key in conditions) {
                conditions[item.key] = true;
            }
        });
    }

    const toCheckAllfieldsVal = this.checkConditions(
        conditions,
        data,
        otherStateVariables
    );

    if (!toCheckAllfieldsVal) {
        this.setState({
                filteredBoxShow: true,
                filterBarData: filterData,
                toggle: false,
                filterPayload: data,
                isLoadingOppDeal: true,
            },
            () => {
                this.isFilterActivate = true;
            }
        );

        this.handleApiCallfilter(data);
    }
};

checkConditions = (
    conditions: Record < string, boolean > ,
    data: any,
    otherStateVariables: Record < string, any >
): boolean => {
    let toCheckAllfieldsVal = false;

    Object.entries(conditions).forEach(([key, condition]) => {
        if (condition) {
            const fieldData = this.getFieldData(key, otherStateVariables);

            if (fieldData !== undefined) {
                data.filters[key] = fieldData;
            }
            else {
                toCheckAllfieldsVal = true;
            }
        }
    });

    return toCheckAllfieldsVal;
};

getFieldData = (key: any, stateVariables: any) => {
    const singleOperator = [
        "between",
        "on",
        "before",
        "after",
        "in_the_last",
        "due_in",
    ];
    const inlast_dueIn = ["in_the_last", "due_in"];
    const on_after = ["on", "before", "after"];
    const between = ["between"];
    switch (key) {
        case "account_name":
            return stateVariables.firstNameFilter || undefined;
        case "deal_owner":
            return stateVariables.dealOwnerNameFilter || undefined;
        case "amount":
            return this.getAmountFilterData(stateVariables);
        case "closing_date":
            return this.getClosingDateFilterData(
                stateVariables,
                singleOperator,
                inlast_dueIn,
                on_after,
                between
            );
        case "created_at":
            return this.getCreatedAtFilterData(
                stateVariables,
                singleOperator,
                inlast_dueIn,
                on_after,
                between
            );
        case "expected_revenue":
            return stateVariables.minRevenueFilter && stateVariables.maxRvenueFilter ?
                stateVariables.minRevenueFilter +
                "-" +
                stateVariables.maxRvenueFilter :
                undefined;
        case "currency":
            return stateVariables.inrCurrencyFilter || undefined;
        case "deal_name":
            return stateVariables.dealNameFilter || undefined;
        case "lead_source":
            return stateVariables.leadSourceFilter.length > 0 ?
                stateVariables.leadSourceFilter :
                undefined;
        case "stage":
            return stateVariables.stageNameFilter.length > 0 ?
                stateVariables.stageNameFilter :
                undefined;
        case "probability":
            return stateVariables.probabilityFilter || undefined;
        case "bussiness_type":
            return stateVariables.typeFilter.length > 0 ?
                stateVariables.typeFilter :
                undefined;
        default:
            return undefined;
    }
};

getAmountFilterData = (stateVariables: any) => {
    const {
        operatorAMFilter,
        amountFilter,
        amountFilterFrom,
        amountFilterTo
    } =
    stateVariables;
    if (
        !["between", "not_between", "is_empty", "is_not_empty"].includes(
            operatorAMFilter
        ) &&
        amountFilter
    ) {
        return {
            operator: operatorAMFilter,
            value: amountFilter,
        };
    }
    else if (
        ["between", "not_between"].includes(operatorAMFilter) &&
        amountFilterFrom &&
        amountFilterTo
    ) {
        return {
            operator: operatorAMFilter,
            from: amountFilterFrom,
            to: amountFilterTo,
        };
    }
    else if (
        operatorAMFilter === "is_empty" ||
        operatorAMFilter === "is_not_empty"
    ) {
        return {
            operator: operatorAMFilter,
        };
    }
    else {
        return undefined;
    }
};

getClosingDateFilterData = (
    stateVariables: any,
    singleOperator: any,
    inlast_dueIn: any,
    on_after: any,
    between: any
) => {
    const {
        operatorCDateFilter,
        durationCdFilter,
        numberCDFilter,
        ClosingDateFirst,
        ClosingDateSecund,
    } = stateVariables;
    if (
        inlast_dueIn.includes(operatorCDateFilter) &&
        durationCdFilter &&
        numberCDFilter
    ) {
        return {
            operator: operatorCDateFilter,
            number: numberCDFilter,
            duration: durationCdFilter,
        };
    }
    else if (on_after.includes(operatorCDateFilter) && ClosingDateFirst) {
        return {
            operator: operatorCDateFilter,
            date: ClosingDateFirst,
        };
    }
    else if (
        between.includes(operatorCDateFilter) &&
        ClosingDateSecund &&
        ClosingDateFirst
    ) {
        return {
            operator: operatorCDateFilter,
            from: ClosingDateFirst,
            to: ClosingDateSecund,
        };
    }
    else if (
        !singleOperator.includes(operatorCDateFilter) &&
        operatorCDateFilter
    ) {
        return {
            operator: operatorCDateFilter,
        };
    }
    else {
        return undefined;
    }
};

getCreatedAtFilterData = (
    stateVariables: any,
    singleOperator: any,
    inlast_dueIn: any,
    on_after: any,
    between: any
) => {
    const {
        operatorCAateFilter,
        durationCAFilter,
        numberCAFilter,
        createAtDateFirst,
        createAtDateSecund,
    } = stateVariables;
    if (
        inlast_dueIn.includes(operatorCAateFilter) &&
        durationCAFilter &&
        numberCAFilter
    ) {
        return {
            operator: operatorCAateFilter,
            number: numberCAFilter,
            duration: durationCAFilter,
        };
    }
    else if (on_after.includes(operatorCAateFilter) && createAtDateFirst) {
        return {
            operator: operatorCAateFilter,
            date: createAtDateFirst,
        };
    }
    else if (
        between.includes(operatorCAateFilter) &&
        createAtDateFirst &&
        createAtDateSecund
    ) {
        return {
            operator: operatorCAateFilter,
            from: createAtDateFirst,
            to: createAtDateSecund,
        };
    }
    else if (
        !singleOperator.includes(operatorCAateFilter) &&
        operatorCAateFilter
    ) {
        return {
            operator: operatorCAateFilter,
        };
    }
    else {
        return undefined;
    }
};

  handleApiCallfilter= (data:object)=> {
    this.setState({ isLoadingOppDeal: true})
    const { tokenLStorage } = this.state;
    const header = {
            token: tokenLStorage,
          };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.filterListApiCallId = requestMessage.messageId;

    createRequestMessage({
        header,
        requestMessage: requestMessage,
        endPoint: configJSON.opportunityListEndPoint,
        method: configJSON.postAPiMethodType,
        body: JSON.stringify(data)
    });
    this.isInitialClickApplayfilter =true
  }

  handleClearfilter = (key:string,removeType:string) => {
    const { isChecked } = this.state;
    const updatedIsChecked = isChecked.map(() => false);
    
    const toggleCheck= removeType === "RemoveCheckedOnly" ? true : false 

    if(!toggleCheck){
        this.handleDefultPages()
    }
    this.isFilterActivate =false

    if (key === "remove_all") {
        this.setState({
            isChecked: updatedIsChecked,
            filterData: [],
            filterBarData: [],
            filteredBoxShow: false,
            toggle: toggleCheck,
            typeFilter: [],
            probabilityFilter: "",
            stageNameFilter: [],
            leadSourceFilter: [],
            firstNameFilter: "",
            dealNameFilter: "",
            inrCurrencyFilter: "",
            dealOwnerNameFilter: "",
            amountFilter: "",
            operatorCDateFilter: "",
            numberCDFilter: "",
            durationCdFilter: "",
            operatorCAateFilter: "",
            numberCAFilter: "",
            operatorAMFilter: "",
            durationCAFilter: "",
            minRevenueFilter: "",
            maxRvenueFilter: "",
            amountFilterFrom:"",
            amountFilterTo:"",
            ClosingDateSecund:"",
            ClosingDateFirst:"",
            createAtDateFirst:"",
            createAtDateSecund:"",
            expectedRevenueCheck:-1
        },
        ()=> {
          this.isFilterActivate =false
          if (this.state.isAnyCheckBoxChecked || (key === "remove_all" && !toggleCheck)) {
                this.handleGetPreLoadApiCall();
            }

        });
    }
    else {
      this.handleClearfilterNoRemoveAll(key)
    }
  };

  handleClearfilterNoRemoveAll = (key: string) => {
    // filterBarData is copy of filterData
    const { isChecked, filterBarData, listofFilterArray } = this.state;

    const indexToRemoveBox = filterBarData.findIndex((item: any) => item.key === key);
    const indexToRemoveOnlist = listofFilterArray.findIndex((item: any) => item.key === key);
    
    if (indexToRemoveBox !== -1 || indexToRemoveOnlist) {
        
        const updatedIsChecked = [...isChecked];
        const updatedFilterData = [...filterBarData];
        
        updatedIsChecked[indexToRemoveOnlist] = false;
        updatedFilterData.splice(indexToRemoveBox, 1);
        
        this.setState({
            isChecked: updatedIsChecked,
            filterBarData: updatedFilterData,
            filterData: updatedFilterData
        }, () => {
            if (updatedFilterData.length > 0) {
                this.handlefilterBodymaker(this.state.filterData);
            }
            else {
                this.handleGetPreLoadApiCall();
            }
        });
    }
    this.handleSingleClearfilter(key);
  }

  handleGetPreLoadApiCall = () => {
    let tabValue = sessionStorage.getItem("DealRegistration");
    if (tabValue === "opportunity") {
        this.getAllOpportunityList();
    }
    else {
        this.getAllDealList();
        this.setState({ listType: false });
    }
    this.setState({pagneNumberValidation:false})
    this.isInitialClickApplayfilter=false
  };


  updateTypeFilter = (keyState:string, updatedFilter:any) => {
    this.setState({ [keyState]: updatedFilter });
  };

  handleCurrencySelect = (text:string) => {
    let value=''
    if(text){
       value = text.slice(0, 3);
    }
    this.setState({
      inrCurrencyFilter:value,
      inrCurrencyFilterValue:text

    } );
  }
  handleDefultPages =() => {
    this.setState({
        perPage:10,
        currentPage:1,
        pageNumberSet:1,
        pagneNumberValidation:false
    })
  }

  handlePageNumberInputChange = (event: any) => {
    const { numberOfButtons } = this.state;
    const inputValue = event.target.value.replace(/\D/g, ""); 
    const isWithinRange = inputValue !== null && inputValue > 0 && inputValue <= numberOfButtons;
    this.setState(
      {
        pageNumberSet: inputValue,
        pagneNumberValidation: !isWithinRange, 
      },
      () => {
        this.handleGetPageByInputNumber();
      }
    );
  };
  
  handleGetPageByInputNumber = () => {
    const { numberOfButtons ,pagneNumberValidation } = this.state;
    const inputValue = this.state.pageNumberSet;
    
    setTimeout(() => {
        if ( !pagneNumberValidation &&  
            this.state.pageNumberSet  !== null && 
            this.state.pageNumberSet  > 0 && 
            this.state.pageNumberSet <= numberOfButtons && this.state.pageNumberSet === inputValue ) {
            this.setState(
                {
                currentPage: inputValue,
                
                },
                () => {
                    this.handleGetPreLoadApiCall();
                    // this.setState({currentPage:1})
                }
            );
        } 
    },1500)
  };

  // Customizable Area End
}