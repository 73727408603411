import { Box, isWidthDown, Theme, withWidth } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import SelectWithLabel from "../../../components/src/SelectWithLabel.web";
import { EmployeeHeadcountOptions, PlatformPackagesOptions, PlatformProjectPricingFieldKeysToRemove, RevenueOptions } from "./constants";
import InputWithLabel from "../../../components/src/InputWithLabel.web";
import EstimatedPriceBox from "./EstimatedPriceBox.web";
import SaveExistingDealContainer from "./SaveExistingDealContainer.web";
import { projectPricingContentStyles, StyledBox } from "./StaffingProjectPricing.web";
import { IEstimatedPriceEndPoint, IEstimatedPriceRequestBody, IEstimatedPriceStates, IPlatformPricingFormStates } from "./types";
import PricingTypeContentHeader from "./PricingTypeContentHeader.web";
import AutocompleteWithLabel from "../../../components/src/AutocompleteWithLabel.web";

type IProps = {
    navigation:any;
    title: string | undefined;
    countryList: string[];
    estimatedPrice: string;
    handleGetEstimatedPrice: (body: IEstimatedPriceRequestBody, endPoint: IEstimatedPriceEndPoint) => void;
    handleResetEstimatedPrice: (value: IEstimatedPriceStates) => void;
    width: Parameters<Theme["breakpoints"]["only"]>[0];
};

type IState = {
    isApproved: boolean;
    employeeHeadcount: string;
    revenue: string;
    platformPackages: string;
    platformCountry: string;
    numberOfOperatingLocations: string;
    isAllSelected: boolean;
};

class PlatformProjectPricing extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            isApproved: true,
            employeeHeadcount: "",
            revenue: "",
            platformPackages: "",
            platformCountry: "",
            numberOfOperatingLocations: "",
            isAllSelected: false
        };
    }

    handleResetFields = () => {
        this.setState({ employeeHeadcount: "", revenue: "", platformPackages: "", platformCountry: "", numberOfOperatingLocations: "", isAllSelected: false });
        this.props.handleResetEstimatedPrice("platformEstimatedPrice");

        PlatformProjectPricingFieldKeysToRemove.forEach(k => localStorage.removeItem(k));
    }

    handleFieldChange = (name: IPlatformPricingFormStates, value: string) => {
        this.setState({
            [name]: value
        } as unknown as Pick<IState, keyof IState>, () => {
            const { employeeHeadcount, revenue, platformPackages, platformCountry, numberOfOperatingLocations } = this.state;
            if (employeeHeadcount !== "" && revenue !== "" && platformPackages !== "" && platformCountry !== "" && numberOfOperatingLocations !== "") {
                this.props.handleGetEstimatedPrice({ employee_headcount: employeeHeadcount, revenue, platform_packages: platformPackages, country: platformCountry, operating_locations: numberOfOperatingLocations }, "platform")
                this.setState({ isAllSelected: true })
            }
        });
        localStorage.setItem(name, value)
    }

    handleInputFieldChange = (data: string) => {
        const value = data?.toString().replace(/\D/g, "")
        if (value) {
            this.handleFieldChange("numberOfOperatingLocations", value)
        }
        else {
            this.setState({ numberOfOperatingLocations: value })
            this.props.handleResetEstimatedPrice("platformEstimatedPrice");
            localStorage.removeItem("numberOfOperatingLocations")
        }
    }

    handleRevenueChange = (name: IPlatformPricingFormStates, value: string) => {
        const { platformPackages } = this.state;
        const controlValue = PlatformPackagesOptions[value as keyof typeof PlatformPackagesOptions] || [];
        if (platformPackages === "" || controlValue.includes(platformPackages)) {
            this.handleFieldChange(name, value)
            return
        }
        this.setState({
            [name]: value, platformPackages: ""
        } as unknown as Pick<IState, keyof IState>)
    }

    handleSaveExistingDeal = (value: boolean) => {
        if(value){
            localStorage.setItem("price",this.props.estimatedPrice);
            this.props.navigation.navigate("CreateOpportunitiesFromPriceProject");
            return
        }
        this.setState({ isApproved: false });
    }

    componentDidMount = () => {
        let employeeHeadcount = localStorage.getItem("employeeHeadcount") as string;
        let revenue = localStorage.getItem("revenue") as string;
        let platformPackages = localStorage.getItem("platformPackages") as string;
        let platformCountry = localStorage.getItem("platformCountry") as string;
        let numberOfOperatingLocations = localStorage.getItem("numberOfOperatingLocations") as string;

        if (employeeHeadcount !== "" && employeeHeadcount !== null) {
            this.setState({ employeeHeadcount })
        }
        if (revenue !== "" && revenue !== null) {
            this.setState({ revenue })
        }
        if (platformPackages !== "" && platformPackages !== null) {
            this.setState({ platformPackages })
        }
        if (platformCountry !== "" && platformCountry !== null) {
            this.setState({ platformCountry })
        }
        if (numberOfOperatingLocations !== "" && numberOfOperatingLocations !== null) {
            this.setState({ numberOfOperatingLocations })
        }
    }

    render(): ReactNode {
        const { title, countryList, estimatedPrice } = this.props;
        const { isApproved, employeeHeadcount, revenue, platformPackages, platformCountry, numberOfOperatingLocations } = this.state;
        const isMobileView = isWidthDown("sm", this.props.width, true);

        return (
            <Box sx={projectPricingContentStyles.flexColumn}>
                <PricingTypeContentHeader
                    data-test-id="platform-pricing-type-content-header"
                    title={title}
                    handleReset={this.handleResetFields}
                    handleEnable={employeeHeadcount == "" && revenue == "" && platformPackages == "" && platformCountry == "" && numberOfOperatingLocations == ""}
                />
                <StyledBox>
                    <SelectWithLabel
                        data-test-id="employee-headcount-select-box"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        label="Employee Headcount"
                        data={EmployeeHeadcountOptions}
                        value={employeeHeadcount}
                        onChange={(value) => this.handleFieldChange("employeeHeadcount", value)}
                        isRequired
                    />
                    <SelectWithLabel
                        data-test-id="revenue-select-box"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        label="Revenue (Million USD)"
                        data={RevenueOptions}
                        value={revenue}
                        onChange={(value) => this.handleRevenueChange("revenue", value)}
                        isRequired
                    />
                </StyledBox>
                <StyledBox>
                    <SelectWithLabel
                        data-test-id="platform-packages-select-box"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        label="Platform Packages"
                        data={PlatformPackagesOptions[revenue as keyof typeof PlatformPackagesOptions]}
                        value={platformPackages}
                        onChange={(value) => this.handleFieldChange("platformPackages", value)}
                        isRequired
                    />
                    <AutocompleteWithLabel
                        data-test-id="country-select-box"
                        className="autocomplete"
                        label="Country"
                        data={countryList}
                        value={platformCountry}
                        onChange={(value)=>this.handleFieldChange("platformCountry",value)}
                        isRequired
                    />
                </StyledBox>
                <StyledBox>
                    <InputWithLabel
                        data-test-id="operating-locations-input"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        inputType="text"
                        inputValue={numberOfOperatingLocations}
                        label="No. of Operating Locations"
                        onChange={(value) => this.handleInputFieldChange(value)}
                        isRequired
                    />
                    <Box sx={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth} />
                </StyledBox>
                { platformPackages !== "" &&  platformCountry !== "" &&  estimatedPrice !== "" && numberOfOperatingLocations !== "" && <EstimatedPriceBox
                    title={title}
                    estimatedPrice={estimatedPrice}
                />}
                {
                    platformPackages !== "" && platformCountry !== "" && isApproved && estimatedPrice !== "" && numberOfOperatingLocations !== "" &&
                    <SaveExistingDealContainer
                        data-test-id="save-existing-deal-buttons-container"
                        handleSaveExistingDeal={this.handleSaveExistingDeal}
                    />
                }
            </Box>
        )
    }
}

export default withWidth()(PlatformProjectPricing);
