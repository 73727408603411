import { Accordion, AccordionDetails, AccordionSummary, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IAccordionItem } from "./types.web";

type IProps = {
    item: IAccordionItem;
    handleChange:(expanded: boolean, title:string)=>void;
};
type IState = {
    expanded:string | false;
};

class AccordionItem extends Component<IProps,IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            expanded:false
        };
    }
    render(): React.ReactNode {
        const { item, handleChange } = this.props;
        return(
            <StyledAccordion data-test-id="accordion-toggle" expanded={item.title === item.isExpanded} onChange={(_,expanded)=>handleChange(expanded,item.title)} className="accordion-container">
                <AccordionSummary className="accordion-title" expandIcon={<ExpandMoreIcon />}>
                    <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="description">
                    <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
        )
    }
}

export const StyledAccordion = styled(Accordion)({
    "&.accordion-container-se":{
        "& .MuiAccordionSummary-root":{
            minHeight:"46px !important"
        },
    },
    "&.accordion-container":{
        margin: "0",
        border:"1px solid rgba(203, 213, 225, 1)",
        borderRightWidth:0,
        borderLeftWidth:0,
        borderTopWidth:0,
        boxShadow:"none",
        borderRadius:0,
        "&.MuiAccordion-root.Mui-expanded::before":{
            opacity:0
        },
        "&.MuiAccordion-root::before":{
            opacity:0
        },
        "& .MuiAccordionSummary-root":{
            minHeight:"64px"
        },
        "& .MuiAccordionDetails-root":{
            padding:"32px 20px"
        },
        "& .description":{
            fontFamily: '"Inter"',
            fontSize: "16px",
            fontWeight: 400,
            letterSpacing: "0em",
            textAlign: "left",
            color:"rgba(71, 85, 105, 1)"
        }
    },
    "& .accordion-title":{
        "& p":{
            fontFamily: '"Inter"',
            fontSize:"16px",
            fontWeight:700,
            lineHeight:"22px",
            textTransform:"uppercase",
            color: '#334155'
        },
        "& .MuiAccordionSummary-expandIcon":{
            transform:"rotate(-90deg)"
        },
        "& .MuiAccordionSummary-expandIcon.Mui-expanded":{
            transform:"rotate(0deg)"
        }
    },
    "& .MuiAccordionSummary-root[aria-expanded='true']":{
        borderBottom:"1px solid rgba(203, 213, 225, 1)"
    },
    "@media(max-width: 960px)": {
        "&.accordion-container": {
            "& .MuiAccordionSummary-root":{
                minHeight:"50px!important"
            },
            "& .MuiAccordionDetails-root":{
                padding:"10px 8px!important"
            },
            "& .description":{
                fontSize: "13px!important"
            }
        },
        "& .accordion-title":{
            "& p":{
                fontSize:"12px!important",
                lineHeight:"16px!important"
            },
        },
        "& .MuiAccordionSummary-content":{
            margin: "8px 0"
        },
        "& .MuiAccordionSummary-expandIcon":{
            padding:"6px"
        },
    }
});

export default AccordionItem;