import React, { Component } from "react";
import { InputAdornment, Input, Box, Button, Typography, styled, IconButton, Menu, MenuItem, Modal ,withStyles, Tooltip} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { closeImg, imagewithoutApi, opportunitiesIon } from "../assets";
import FilterList from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import { AddCircleOutline } from "@material-ui/icons";
import { IOpportunityListAttributes } from "../types";
import { stageValues, dateFormatOppAllList, formatString } from "../constants";
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import MoreVert from '@material-ui/icons/MoreVert';
export const tableStyleCss = require("./tableCssStyle");
import CircularProgress from '@material-ui/core/CircularProgress';

type IProps = {
    Opportunites: IOpportunityListAttributes[];
    handleInputChange: (dataType: string, value: string) => void;
    handleDealCommonNavigation: (path: string) => void;
    handleSort:(column:string) => void;
    sortBy:string | null;
    sortDirection:string;
    handleGetOpportunitesRecord: (path: IOpportunityListAttributes) => void;
    isOpportunityAction:boolean,
    OpportunityActionAnchorEl: null| HTMLElement,
    OpportunityAction:(path: string) => void;
    handleDealActionMenuClose:() => void;
    handleProposedAmountModalOpen:() => void;
    handleCloseModalOpp:() => void;
    handleProposedAmount:(id:any,amount:number) => void;
    isDeleteModalOpen: boolean;
    classes:any
    isLoading:boolean,
    listSearchValueErrorOpp:boolean,
    listSearchValueOpp:any,
    handleBlurEventOpp:() => void;
};

type IState = {
    opportunityId:string;
    opportunityIdName:string;
    proposedAmount:number
};

export const renderStatus = (item:any,status:string,classProp:any) => {
    return (
        item === "rejected" && status ? 
        <Tooltip title={status || 'N/A'} arrow  
            classes={{ tooltip: classProp.tooltipTitle , arrow: classProp.arrow }}>
            <div>{formatString(item)}</div>
        </Tooltip> :
            formatString(item)
    
    )
}
export class OpportunitiesTable extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            opportunityId: '',
            opportunityIdName:'',
            proposedAmount:0

        };
    }
    handleSort = (column: string) => {
        this.props.handleSort(column);
    };
    handleNavigation = (path: string) => {
        this.props.handleDealCommonNavigation(path);
        sessionStorage.removeItem('opportunityData');
          
    };
    handleGetOpportunitesRecord =(data:IOpportunityListAttributes)=> {
        this.props.handleGetOpportunitesRecord(data)
        sessionStorage.setItem('opportunityId',data?.id); //temp
    }
    OpportunityAction =(event:any,item:any)=> {
        this.props.OpportunityAction(event)
        this.setState({
            opportunityId:item.id ,
            opportunityIdName:item.attributes.opportunity_id,
            proposedAmount:item.attributes.proposed_amount 

        })
    }
    handleDealActionMenuClose= ()=> {
        this.props.handleDealActionMenuClose()
    }
   
    renderTableData = (Opportunites: any) => {
        const { classes }: any = this.props

        return (
            <>
                <Box className="table-wrapper">
                    <Box className="table-container">
                        <Table className="table">
                            <TableHead>
                                <TableRow className="table-header-row">
                                    <TableCell className="table-cell">
                                     <Box className="table-header-cell-text">
                                        <Typography>Opportunity ID</Typography>
                                    </Box>   
                                    </TableCell>
                                        <TableCell className="table-cell">
                                        <Box id="handleSortClickId" data-test-id="handleTableSearchId"   className="table-header-cell-text">
                                            <Typography onClick={ () => {this.handleSort("opportunity_name")}}  data-test-id="icon-handleSortClickID"> 
                                              Opportunity Name
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortClickId"   className="table-header-cell-text">
                                            <Typography data-test-id="StatushandleSearchIdOP"  onClick={ () => {this.handleSort("status")}}  > 
                                                Status
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box className="table-header-cell-text"> Stage </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box className="table-header-cell-text">Tier to Partner</Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box className="table-header-cell-text">
                                            <Typography data-test-id="handleFilterIdECNOP"  onClick={() => { this.handleSort("end_customer_name") }}>
                                                End Customer Account
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId"   className="table-header-cell-text">
                                            <Typography data-test-id="handleFilterIdAmountOP"   onClick={ () => {this.handleSort("amount")}}> 
                                                 Amount
                                            </Typography>
                                        </Box>                                   
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId" className="table-header-cell-text">
                                            <Typography data-test-id="proposedAmountAllOppIdOP"  onClick={() => { this.handleSort("proposed_amount") }}>
                                                 Proposed Amount
                                            </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId" className="table-header-cell-text">
                                            <Typography data-test-id="expectedRevenueIdOP"  onClick={() => { this.handleSort("expected_revenue") }}>
                                                 Expected Revenue
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId"  className="table-header-cell-text">
                                            <Typography data-test-id="CloseAtOppIdOP" onClick={() => { this.handleSort("created_date") }}>
                                                 Created On
                                            </Typography>
                                        </Box>
                                    </TableCell>

                                    <TableCell className="table-cell">
                                        <Box id="handleSortDealClickId" className="table-header-cell-text">
                                            <Typography  data-test-id="CloseOnOppIdOP" onClick={() => { this.handleSort("close_date") }}>
                                                 Close On
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        <Box className="table-header-cell-text">City </Box>
                                    </TableCell>
                                    <TableCell className="table-cell table-action"> </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    Opportunites.slice(0, 3).map((item: IOpportunityListAttributes) => (
                                        <TableRow key={item.attributes.opportunity_id} className="table-body-row">
                                            <TableCell>
                                                <Typography data-test-id="viewOppDetailsLink" onClick={() => this.handleGetOpportunitesRecord(item)} className="opportunity-id">
                                                    {item.attributes.opportunity_id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography  className="blue-text"> {item.attributes.opportunity_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography  className="status" style={{ ...opportunityFieldStyles[item.attributes.status] }}>
                                                    { 
                                                       renderStatus(item.attributes.status,item.attributes.rejection_reason,classes)
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography style={{ ...opportunityFieldStyles[item.attributes.stage] }}>{stageValues[item.attributes.stage]}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="blue-text">{item.attributes.account_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                               <Typography className="common-text">{item.attributes.contact_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography className="common-text">{item.attributes.currency_symbol||'$'}{item.attributes.amount}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {item.attributes.proposed_amount != null && (
                                                    <Typography className="common-text">
                                                       {parseInt(item.attributes.proposed_amount) > 0 ? `${item.attributes.currency_symbol||'$'}  ${item.attributes.proposed_amount || " "}` : ""}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Typography id="expected_revenueOpp" className="common-text">{item.attributes.currency_symbol||'$'}{item.attributes.expected_revenue}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography id="expected_created_atOpp" className="common-text"> { dateFormatOppAllList(item.attributes.created_at,false) }</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography id="expected_closing_dateOpp"  className="common-text">{ dateFormatOppAllList (item.attributes.closing_date,true)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography  id="closing_date" className="common-text">{item.attributes.city}</Typography>
                                            </TableCell>
                                            <TableCell className="table-action">
                                                { item.attributes.status === "proposed" && (
                                                    <MoreVert data-test-id="handledOpportunityAction" onClick={(event) => this.OpportunityAction(event,item)} />
                                                )}     
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
                <Box onClick={() => this.handleNavigation('OpportunitiesList')} data-test-id="handleNavigationOppID" className="view-more-box">
                    <Typography>View More Opportunities </Typography> <ArrowRightAlt></ArrowRightAlt>
                </Box>
            </>
        )
    }
    render() {
        const {
            Opportunites, 
            handleInputChange,
            handleProposedAmountModalOpen,
            handleCloseModalOpp,
            handleProposedAmount,
            handleBlurEventOpp,
            classes,
            isLoading 
        }  = this.props;
        const notthingToShowOpp = " Nothing to show"
        const noRecordsOpp ="There are no entries created for now"
      
        return (
            <OpportunitiesField>
                <Box className="table-actions-row">
                    <Typography className="view-all-title">
                        <img src={opportunitiesIon} alt="" />
                        View All Opportunities
                    </Typography>
                    <Box className="actions">
                        <Input
                            onChange={(event) => handleInputChange("opportunity", event.target.value)}
                            data-test-id="search-input"
                            placeholder="Search"
                            fullWidth={true}
                            disableUnderline
                            className={this.props.listSearchValueErrorOpp ? classes.reddrawerSearchField : classes.drawerSearchField}
                            startAdornment={<InputAdornment position="start">
                                <Search style={opportunityFieldStyles.colorGray} />
                            </InputAdornment>}
                            onBlur={handleBlurEventOpp}
                            value={this.props.listSearchValueOpp}
                        
                        />
                        <Box className="actionchild">
                            <Button
                                variant="outlined"
                                className="add-new-button"
                                startIcon={<AddCircleOutline />}
                                onClick={() => this.handleNavigation('CreateOpportunities')}
                                data-test-id="handleNavigationAddNewId">
                                Add New
                            </Button>
                        </Box>
                    </Box>
                </Box>

                {isLoading ? (
                    <Box className="table-loader" >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {Opportunites && Opportunites.length > 0 && !isLoading
                            ? this.renderTableData(Opportunites)
                            : !isLoading && (
                                <Box className="tableRecordsNotMainBox">
                                    <img src={imagewithoutApi} alt="" />
                                    <Typography className="tableRecordsNotTitile">
                                        {notthingToShowOpp}
                                    </Typography>
                                    <Typography className="tableRecordsNotMesg">
                                        {noRecordsOpp}
                                    </Typography>
                                </Box>
                        )}
                    </>
                )}
                <Menu
                    anchorEl={this.props.OpportunityActionAnchorEl}
                    open={this.props.isOpportunityAction}
                    onClose={this.handleDealActionMenuClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                    className="usermenuItem"
                    style={{
                        left: '8px', 
                        top: '0',
                    }}
                >
                    <MenuItem data-test-id="handleViewDealId" onClick={() => {handleProposedAmountModalOpen(); }}  >Submit</MenuItem>
                </Menu>  
                <Modal
                open={this.props.isDeleteModalOpen}
                onClose={handleCloseModalOpp}
                data-test-id='success-modal'
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                    <Box  className={`${classes.modaladminContainer}`} >
                        <Box className={`${classes.closeadminimg}`} onClick={handleCloseModalOpp}  data-test-id="handleCloseModalOppID" >
                            <img src={closeImg} alt="" style={{ width: "24px" }} />
                        </Box>
                        <Box className={`${classes.centeradminTxt}`}>
                            <Box className={`${classes.centeradminTxt}`}>
                                <Typography className={`${classes.successTxtadmin}`}>Are you sure you want to submit  </Typography>
                                <Typography className={`${classes.descriptionTxtadmin}`}>Your opportunity id  <Typography className={`${classes.opportunityIdText}`}> {this.state.opportunityIdName}   </Typography></Typography>
                            </Box>
                        </Box>
                        <Box className={`${classes.countinueBtnContainer}`}>
                            <Button className={`${classes.continueBtnadmin}`}  onClick={handleCloseModalOpp}   data-test-id='handleCloseModalOppID'>No</Button>
                            <Button className={`${classes.continueBtnadmin}`} onClick={()=> {handleProposedAmount(this.state.opportunityId,this.state.proposedAmount)}}  data-test-id='handleDeleteOppRecordsID'>Yes</Button>
                        </Box>
                    </Box>
              </Modal>
            </OpportunitiesField>
        )
    }
}

export const opportunityFieldStyles = {
    colorGray: { color: "gray" },
    "accepted": {
        background: 'rgba(209, 250, 229, 1)',
        borderRadius: '16px',
        color: 'green',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'right'
    },
    "rejected": {
        background: 'rgba(254, 226, 226, 1)',
        borderRadius: '16px',
        color: 'red',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'right',
        cursor:"pointer"
    },
   
    "proposed": {
        background: 'rgba(254, 226, 226, 1)',
        borderRadius: '16px',
        color: '#D97706',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'right'
    },
    "pending_review": {
        background: 'rgba(241, 245, 249, 1)',
        borderRadius: '16px',
        color: 'rgba(71, 85, 105, 1)',
        padding: '4px 8px',
        fontSize: '12px',
        alignItems: 'right'
    },
    "agreement_signed": {
        color: "rgba(5, 150, 105, 1)",
        fontSize: "14px"
    },
    "closed_won": {
        color: "rgba(5, 150, 105, 1)",
        fontSize: "14px"
    },
    "closed_lost": {
        color: "rgba(5, 150, 105, 1)",
        fontSize: "14px"
    },
    "did_not_proceed": {
        color: "rgba(220, 38, 38, 1)",
        fontSize: "14px"
    },
    "issue_agreement": {
        color: "rgba(220, 38, 38, 1)",
        fontSize: "14px"
    },
    "proposal_sent": {
        color: "rgba(217, 119, 6, 1)",
        fontSize: "14px"
    },
    "1st_meeting_complete": {
        color: "rgba(217, 119, 6, 1)",
        fontSize: "14px"
    },
    "2nd_meeting_complete": {
        color: "rgba(217, 119, 6, 1)",
        fontSize: "14px"
    }
    ,"closed_future_prospect":{
        color: "rgba(220, 38, 38, 1)",
        fontSize: "14px"
    }
    ,"proposal_accepted":{
        color: "rgb(5, 150, 105)",
        fontSize: "14px"
    }
}

export const OpportunitiesField = styled(Box)({
    "&": {
        width: "100%"
    },
    "& .table-actions-row": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center"
    },
    "& .table-actions-row .actions": {
        display: "flex",
        justifyContent: "space-around",
        gap: "16px",
        alignItems: 'center',
    },
    "& .actions .actionchild": {
        display: "flex",
        gap: "16px",
    },
    "@media(max-width: 798px)": {
        "& .table-actions-row .actions": {
            display: "block",
            width:'100%'
        },
        "& .table-header-row .table-cell:first-child": {
            borderLeft: 0,
            alignItems: "center",
            width: '100%',
            display:'flex',
            height:"100px"
        },
    },
    "& .mr-list-actions":{
        display: "flex",
        justifyContent: "space-around",
        gap: "16px",
        flexDirection: "row"
    },
    "& .view-all-title": {
        fontSize: '18px',
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: '0.00735em',
        paddingBottom: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px"
    },
    "& .actions .search-input": {
        border: "1px solid #dfdfdf",
        borderRadius: "8px",
        padding: "10px 8px 10px  8px",
        height: "44px",
        fontFamily: "'Inter', sans-serif",
        width: "360px"
    },
    "& .add-new-button": {
        background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
        color: "white",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: '10px 16px 10px 16px',
        height: '44px',
        fontSize: '16px',
        fontFamily: "Inter, sans-serif",
        minWidth: "134px"
    },
    "& .table-wrapper": {
        backgroundColor: "#CBD5E1",
        borderRadius: '8px',
        width: "calc(86vw - 100px)"
    },
    "& .mr-table-wrapper": {
        width: "calc(86vw - 120px)"

    },
    "& .table-wrapper-area":{
        height:"77%",
        background:"white"
    },
    "& .table-container": {
        background: 'white',
        overflowX: "auto",
        maxHeight: "881px",
        overflowY: "auto",
        marginTop: "30px",
        border: "1px solid #e6e2e0;",
        borderBottom: "hidden",
        borderCollapse: "separate",
        borderRadius: "8px"

    },
    // "& ::-webkit-scrollbar": {
    //     height: "6px",
    //     width: "100"
    //   },
    //   "& ::-webkit-scrollbar-thumb " :{
    //     background:" #80808038",
    //     borderRadius: "18px"
    //   },
    "& .table": {
        width: "200%",
        borderRadius: '8px',
        borderCollapse: "separate",
        overflowX:'auto'
    },
    "& .table-footer":{
        width: "100%",
        border: '1px solid rgba(203, 213, 225, 1)',
        borderRadius: '0 0 8px 8px',
        marginTop: "-5px",
        borderCollapse: "separate",
        borderTop: "hidden"
    },
    "& .tooltipStyle":{
        backgroundColor: "black",
    },
    "& .table-header-row": {
        background: "rgba(248, 250, 252, 1)",
        height: "20px"
    },
    "& .table-header-row .table-cell": {
        textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        borderLeft: "1px solid rgba(203, 213, 225, 1)"
    },
    "& .table-body-row td": {
        border: 0,
        borderBottom: "1px solid #CBD5E1"
    },
    "& .table-header-row .table-cell:first-child": {
        borderLeft: 0,
        alignItems: "center",
        width: '100%',
        display:'flex'
    },
    "& .table-header-row .table-cell:last-child": {
        borderRight: 0
    },
    "& .table-header-cell-text": {
        display: 'flex',
        justifyContent: 'space-between',
        height:'100%',
        "& .MuiBox-root.tableCellSortIcon ":{
            display: 'flex', 
            flexDirection: 'column' ,
            width: "0px",
            marginTop: "5px",
            marginLeft: "9px"
        },
        "& p":{
            textAlign: "left",
            fontSize: "14px",
            fontFamily: 'Inter',
            fontWeight: 400,
            lineHeight: "22px",
            letterSpacing: '0.00735em',
            color: "rgba(71, 85, 105, 1)",
            cursor:"pointer"
        }
    },
    "& .table-body-row": {
        background: "rgba(255, 255, 255, 1)",
        height: "40px"
    },
    "& .table-body-row .opportunity-id": {
        textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        width: "140px",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline", 
        }
    },
    "& .table-body-row .table-action":{
      width:"0px",
      color: "rgba(71, 85, 105, 1)",
      cursor: "pointer",

    },
    "& usermenuItem": {
        '& .MuiPaper-root': {
            background: 'linear-gradient(180deg, #394060 0%, #19203C 100%)',
            width: '265px',
            height: '112px',
            color: '#FFFFFF',
            padding: '12px',
        },
        '& .MuiMenu-list': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
        },
        '& .MuiListItem-root': {
            gap: '10px',
            '&:hover': {
                backgroundImage: 'linear-gradient(90deg, #00AEEF 0%, #6ADF23 100%)',
                backgroundSize: '100%',
                backgroundRepeat: 'repeat',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            },
        },
    },
    "& .table-body-row .blue-text": {
        color: "rgba(35, 17, 136, 1)",
        fontSize: "14px",
    },
    "& .table-body-row .common-text": {
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 400,
        color: 'rgba(10, 5, 40, 1)'
    },
    "& .table-body-row .common-text-deals": {
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 400,
        color: 'rgba(10, 5, 40, 1)'
    },
    "& .status": {
        textAlign: "center",
        textTransform: "capitalize"
    },
    "& .view-more-box": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: '38px',
        width: "100%",
        "& p":
        {
            borderBottom: "1px solid",
        },
        "&:hover": {
            cursor: "pointer",
        }
    },
    "& .no-data": {
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 400,
        color: 'rgba(10, 5, 40, 1)',
        marginTop: "30px"
    },
    // New added
    "& .pagination-row":{
        borderBottom: "1px solid #CBD5E1" 
    },
    "& .colSpan-Style":{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '30px'
    },
    "& .mr-colSpan-Style":{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '30px'
    },
    "& .page-view-count":{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        color: "rgba(71, 85, 105, 1)",
        fontSize: "12px",
        alignItems: "center",
        gap: "9px",
        "& p":{
            fontSize:"12px"
        }
    },
    "& .inputTxtMain": {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "10px",
        height: "32px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        minWidth: "50px",
    },
    "& .inputTxtMainSelect": {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "4px",
        height: "32px",
        width: "100%",
        fontFamily: "'Inter', sans-serif",
        minWidth: "50px",
        fontSize: "14px",
    },
    "& .inputTxtMenuSelectLabel":{
        "& ul li" :{
            fontSize: "14px",
            height: "32px",
            padding:"4px 16px 0px 15px",
        }
    },
    "& .inputTxtMainSelect-Icon":{
        marginLeft: "-24px",
        cursor:"pointer"
    },
    "& .pagination-ArrowBack":{
        height:"24px",
        width:"24px"
    },
    "& .pagination-ArrowForwardIcon":{
        height:"24px",
        width:"24px"
    },
    "& .page-entries":{
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        "& p":{
            fontSize:"12px"
        }
    },
    "& .page-view-count-btn":{
        display: "flex",
        justifyContent: "lex-end",
        color: "rgba(71, 85, 105, 1)",
        fontSize: "12px",
        alignItems: "center",
        gap: "16px",
        "& p":{
            fontSize:"14px"
        }
    },
    "& .mr-page-view-count-btn":{
        display: "flex",
        justifyContent: "lex-end",
        color: "rgba(71, 85, 105, 1)",
        fontSize: "12px",
        alignItems: "center",
        gap: "7px",
        "& p":{
            fontSize:"14px"
        }
    },
    "& .paginationNext-btn":{
        borderRadius: "8px",
        height:"32px",
        width:"32px",
        minWidth: "0px",
        padding: "8px",
        "& p":{
            fontSize:"12px"
        }
    },
    "& .pagination-btn":{
        borderRadius: "8px",
        height:"32px",
        minWidth:"32px",
        padding: "8px" ,
        "& p":{
            fontSize:"12px"
        },
        marginLeft:"5px"
    },
    "& .pagination-btn-text":{
        marginLeft:"8px",
        marginRight: "10px"
    },
    "& .tableRecordsNotMainBox":{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: "column",
        width: "100%",
        height:"272px",
        paddingTop: "8px",
        border: "1px solid",
        borderColor: "rgba(226, 232, 240, 1)",
        borderRadius: "8px",
        marginTop: "33px"
    },
    "& .tableRecordsNotTitile":{
         // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      // fontWeight: 'bold',
  
      fontWeight: 400,
      textTransform: "capitalize",
      padding: '10px 16px 10px 16px',
      fontSize: '16px',
      fontFamily: "Inter, sans-serif",
      paddingBottom:"0px"
    },
    "& .tableRecordsNotMesg":{
        fontWeight: 400,
        textTransform: "capitalize",
        padding: '10px 16px 10px 16px',
        fontSize: '14px',
        fontFamily: "Inter, sans-serif",
        color:"rgba(71, 85, 105, 1)",
    },
    "& .table-loader":{
        display: 'flex',
        justifyContent: 'center',
        background: 'white',
        // maxHeight: "881px",
        marginTop: "10px",
        // height: "79%",
        alignItems: "center",
        height:"70%"

    },
    "& .filterClearAlertBox": {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(254, 226, 226, 1)',
        color: '#bd0707',
        height: '32px',
        padding: '6px 10px 6px 15px',
        borderRadius: '4px',
        marginLeft: '0px',
        alignItems: "center",
        cursor: "pointer",
        "& .MuiSvgIcon-root": {
            fontSize: "19px",
            marginLeft: "16px"
        },
        "& p": {
            fontSize: "14px"
        }

    },
    "& .filterDateAlertBox": {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(226, 232, 240, 1)',
        color: '#black',
        height: '32px',
        padding: '6px 10px 6px 15px',
        borderRadius: '4px',
        marginLeft: '6px',
        alignItems: "center",
        cursor: "pointer",
        "& .MuiSvgIcon-root": {
            fontSize: "19px",
            paddingLeft:"5px"
        },
        "& p": {
            fontSize: "14px"
        }
    },
    "& .filterAlertBox": {
        display: "flex",
        contentJustuft: "flex-start",
        paddingTop:"2%",
        flexWrap: "wrap",
        gridRowGap: "7px"
    },
 
    
    "& .button-container": {
        position: "relative"
      },
      
      "& .filter-button": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        position: "relative",
        height: 'fit-content',
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid',
        gap: '10px',
        minWidth: "44px"
      },
      "& .red-dot": {
        position: "absolute",
        top: "-3px",
        right: "-3px",
        width: "10px", 
        height: "10px",
        backgroundColor: "red",
        borderRadius: "50%",
      },
      "& .drawerinputTxtMain": {
        border: "1px solid #dfdfdf",
        padding: "10px",
        borderRadius: "8px",
        height: "32px",
        width: "42px",
        fontFamily: "'Inter', sans-serif",
      },
      "& .reddrawerinputTxtMain": {
        border: "1px solid red",
        padding: "10px",
        borderRadius: "8px",
        height: "32px",
        width: "42px",
        fontFamily: "'Inter', sans-serif",
      },
      "& .mr-table":{
        width: "100%",
        borderRadius: '8px',
        borderCollapse: "separate",
      },

      "& .table-header-row .mr-table-cell": {
        textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        borderLeft: "1px solid rgba(203, 213, 225, 1)",
            "& p":{
                fontSize: "14px",
                fontFamily: 'Inter',
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: '0.00735em',
                color: "rgba(71, 85, 105, 1)",
                cursor:"pointer",
                textAlign:"left"
            },
        width:"255px",
        height:"38px"    
    },
    "& .mr-table-cell-my-requestid":{
        textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        borderLeft: "1px solid rgba(203, 213, 225, 1)",
            "& p":{
                fontSize: "14px",
                fontFamily: 'Inter',
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: '0.00735em',
                color: "rgba(71, 85, 105, 1)",
                cursor:"pointer",
                textAlign:"left"
            },
        width:"140px",
        height:"38px"   
    },
    "& .view-details-button": {
        background: "rgba(255, 255, 255, 1)",
        fontWeight: 400,
        textTransform: "capitalize",
        borderRadius: "4px",
        padding: '3px 8px 3px 8px',
        height: '32px',
        fontSize: '12px',
        fontFamily: "Inter, sans-serif",
        width: "98px",
        borderColor: "rgba(54 65 97)",
        border: "1px solid",
        color:"rgba(54, 65, 97, 1)"
    },
    "& .view-details-button-Box":{
        textAlign:"center"
    },
    "& .request-id":{
        textAlign: "left",
        fontSize: "14px",
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: '0.00735em',
        color: "rgba(71, 85, 105, 1)",
        cursor: "pointer",
        
    },
    "& .date-column": {
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Inter',
        lineHeight: '22px',
        color: 'rgba(10, 5, 40)',
    },
    "& .my-request-title":{
        fontSize: '20px',
        color:"#0A0528",
        fontFamily: 'Inter',
        fontWeight: 700,
        lineHeight: "20px",
        letterSpacing: '0.00735em',
        paddingBottom: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px"
    },
    "& .mr-table-actions-row ":{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    "& .mr-actions":{
        gap: "10px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around"
    },
    
    
    "& .view-all-title img": { width: "40px", height: "40px", },
    "@media(max-width: 960px)": {
        "& .view-all-title": {
            fontSize: "14px"
        },
        "& .my-request-title":{
            fontSize: "14px"
        },
        "& .view-all-title img": {
            width: "30px",
            height: "30px"
        },
        "& .table-actions-row": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            flexDirection:"column",
        },
        "& .table-actions-row .actions": {
            justifyContent: "space-between",
            width: "100%",
        }, 
        "& .actions .search-input": {
            width: "60%"
        }
        
    },
    "@media (max-width: 600px)": {
        "& .table-actions-row": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            flexDirection:"column"
        }, 
        "& .actions .search-input": {
            width: "35%"
        },
        "& .table-wrapper": {
            width: "calc(50vw - 5px)"
        },
        "& .mr-list-actions":{
            display: "flex",
            justifyContent: "space-around",
            gap: "16px",
            flexDirection: "column"
        },
        "& .colSpan-Style":{
            display: 'flex',
            height:"100%",
            flexDirection: "column"
        },
        "& .mr-colSpan-Style":{
            display: 'flex',
            height:"100%",
            
        },
        "& .add-new-button": {
            background: "linear-gradient(90deg, #364161 0%, #06081F 100%)",
            color: "white",
            fontWeight: 700,
            textTransform: "capitalize",
            borderRadius: "8px",
            padding: '10px 16px 10px 16px',
            height: '44px',
            fontSize: '12px',
            fontFamily: "Inter, sans-serif",
            width: "134px"
        },
      }
});




export default withStyles(tableStyleCss.stylecss)(OpportunitiesTable);
