import React, { Component, ReactNode } from "react";
import EstimatedPriceBox from "./EstimatedPriceBox.web";
import SaveExistingDealContainer from "./SaveExistingDealContainer.web";
import { Box, isWidthDown, styled, TextField, Theme, withWidth } from "@material-ui/core";
import SelectWithLabel from "../../../components/src/SelectWithLabel.web";
import { StaffingConsultantTypeOptions, StaffingProjectPricingFieldKeysToRemove, WorkingTimeOptions } from "./constants";
import { IEstimatedPriceEndPoint, IEstimatedPriceRequestBody, IEstimatedPriceStates, IStaffingPricingFormStates } from "./types";
import PricingTypeContentHeader from "./PricingTypeContentHeader.web";
import { Autocomplete } from "@material-ui/lab";
import AutocompleteWithLabel from "../../../components/src/AutocompleteWithLabel.web";

type IProps = {
    navigation:any;
    title: string | undefined;
    countryList: string[];
    estimatedPrice: string;
    handleGetEstimatedPrice: (body: IEstimatedPriceRequestBody, endPoint: IEstimatedPriceEndPoint) => void;
    handleResetEstimatedPrice: (value: IEstimatedPriceStates) => void;
    width: Parameters<Theme["breakpoints"]["only"]>[0];
};

type IState = {
    isApproved: boolean;
    staffingConsultantType: string;
    staffingCountry: string;
    workingTime: string;
    isAllSelected: boolean;
};

class StaffingProjectPricing extends Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            isApproved: true,
            staffingConsultantType: "",
            staffingCountry: "",
            workingTime: "",
            isAllSelected: false
        };
    }

    handleResetFields = () => {
        this.setState({ staffingConsultantType: "", staffingCountry: "", workingTime: "", isAllSelected: false });
        this.props.handleResetEstimatedPrice("staffingEstimatedPrice");

        StaffingProjectPricingFieldKeysToRemove.forEach(k => localStorage.removeItem(k));
    }

    handleFieldChange = (name: IStaffingPricingFormStates, value: string) => {
        this.setState({
            [name]: value
        } as unknown as Pick<IState, keyof IState>, () => {
            const { staffingConsultantType, staffingCountry, workingTime } = this.state;
            if (staffingConsultantType && staffingCountry !== "" && workingTime !== "") {
                this.props.handleGetEstimatedPrice({ consultant_type: staffingConsultantType, country: staffingCountry, part_time_full_time: workingTime }, "staffing")
                this.setState({ isAllSelected: true })
            }
        });
        localStorage.setItem(name, value);
    }

    handleSaveExistingDeal = (value: boolean) => {
        if(value){
            localStorage.setItem("price",this.props.estimatedPrice);
            this.props.navigation.navigate("CreateOpportunitiesFromPriceProject");
            return
        }
        this.setState({ isApproved: false });
    }

    componentDidMount = () => {
        let staffingConsultantType = localStorage.getItem("staffingConsultantType") as string;
        let staffingCountry = localStorage.getItem("staffingCountry") as string;
        let workingTime = localStorage.getItem("workingTime") as string;

        if (staffingConsultantType !== "" && staffingConsultantType !== null) {
            this.setState({ staffingConsultantType })
        }
        if (staffingCountry !== "" && staffingCountry !== null) {
            this.setState({ staffingCountry })
        }
        if (workingTime !== "" && workingTime !== null) {
            this.setState({ workingTime })
        }
    }

    render(): ReactNode {

        const { title, countryList, estimatedPrice } = this.props;
        const { isApproved, staffingConsultantType, staffingCountry, workingTime } = this.state;
        const isMobileView = isWidthDown("sm", this.props.width, true);

        return (
            <Box sx={projectPricingContentStyles.flexColumn}>
                <PricingTypeContentHeader
                    data-test-id="staffing-pricing-type-content-header"
                    title={title}
                    handleReset={this.handleResetFields}
                    handleEnable={staffingConsultantType == "" && staffingCountry == "" && workingTime == ""}
                />
                <SelectWithLabel
                    data-test-id="consultant-type-select-box"
                    containerStyle={projectPricingContentStyles.fullWidth}
                    label="Consultant Type"
                    data={StaffingConsultantTypeOptions}
                    value={staffingConsultantType}
                    onChange={(value) => this.handleFieldChange("staffingConsultantType", value)}
                    isRequired
                />
                <StyledBox>
                    <AutocompleteWithLabel
                        data-test-id="country-select-box"
                        className="autocomplete"
                        label="Country"
                        data={countryList}
                        value={staffingCountry}
                        onChange={(value)=>this.handleFieldChange("staffingCountry",value)}
                        isRequired
                    />
                    <SelectWithLabel
                        data-test-id="working-time-select-box"
                        containerStyle={isMobileView ? projectPricingContentStyles.fullWidth : projectPricingContentStyles.halfWidth}
                        label="Part-Time/Full-Time"
                        data={WorkingTimeOptions}
                        value={workingTime}
                        onChange={(value) => this.handleFieldChange("workingTime", value)}
                        isRequired
                    />
                </StyledBox>
                {staffingCountry  !== "" && estimatedPrice !== "" && <EstimatedPriceBox
                    title={title}
                    estimatedPrice={estimatedPrice}
                />}
                {
                   staffingCountry  !== "" &&  isApproved && estimatedPrice !== "" &&
                    <SaveExistingDealContainer
                        data-test-id="save-existing-deal-buttons-container"
                        handleSaveExistingDeal={this.handleSaveExistingDeal}
                    />
                }
            </Box>
        )
    }
}

export const projectPricingContentStyles = {
    flexRow: {
        display: "flex",
        gap: "20px"
    },
    flexDirectionColumn: "column",
    flexDirectionRow: "row",
    fullWidth: {
        paddingBottom: "15px",
        width: "100%",
    },
    halfWidth: {
        paddingBottom: "15px",
        width: "50%"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    }
};

export const StyledBox = styled(Box)({
    "&": {
        display: "flex",
        gap: "20px",
        paddingBottom: "15px",
        width: "100%",
    },
    "& .autocomplete":{
        width:"50%"
    },
    "@media(max-width: 960px)": {
        "&": {
            gap: 0,
            flexDirection: "column"
        },
        "& .autocomplete":{
            width:"100%",
            marginBottom:"15px"
        },
    }
});

export const StyledAutocomplete = styled(Autocomplete)({
    border: "1px solid #dfdfdf",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "'Inter', sans-serif",
    width:"50%",
    height: "44px",
    display:"flex",
    alignItems:"center",
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
        paddingRight: "25px",
        fontSize: "16px",
        borderBottom: "none",
        paddingLeft: "10px",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInput-underline::before': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline::after': {
        borderBottom: "0px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: "0px",
    },
    '&. MuiInputBase-fullWidth': {
        width: '95%'
    },
    "@media(max-width: 960px)": {
        "&": {
            width:"100%",
            height: "40px"
        }
    }
});

export const StyledTextField = styled(TextField)({
    padding: "0 10px",
    fontSize: "12px",
    '& .MuiFilledInput-underline:after': {
        borderBottom: '0px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderBottom: '0px',
    },
});

export default withWidth()(StaffingProjectPricing);
