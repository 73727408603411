export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const viewAllBgImage = require("../assets/viewAll.png")
export const PresentationImage = require("../assets/marketingPresentation.jpg")
export const sealsPresentation = require("../assets/sealsPresentation.png")
export const digitalMarketing = require("../assets/digitalMarketing.png")
export const Resources = require("../assets/Resources.png")
export const resourceImage = require("../assets/1.jpeg")
export const corporateImage = require("../assets/2.jpg")
export const salesImage = require("../assets/3.jpg")
export const degitalMarketingImage = require("../assets/4.jpg")
export const pdfButtonImg = require("../assets/pdfImg.png")
export const uploadFile = require("../assets/UploadDoc.png")
export const documentImage = require("../assets/docImage.svg")
export const attachmentImage = require("../assets/attachment.svg")
export const verticalBar = require("../assets/verticalBar.svg")
export const closeImg = require("../assets/close.svg")
export const SuccessImg = require("../assets/success.svg")
